<template>
  <tr
    class="drop-zone bg-mist py-4 px-3 table-row mt-20 shadow-md w-100 pb-10"
    :draggable="draggable"
    @dragstart="startDrag($event, item)"
    @drop="onDrop($event, item)"
    @dragover.prevent
    @dragenter.prevent
  >
    <!-- Commenting out reordering since it won't be part of MVP -->
    <!--  <td class="p-3 w-1/12" v-if="isBasePackage || !isAdminOrPopsUser"/>
    <td class="p-3 w-1/12"
      v-if="!isBasePackage && isAdminOrPopsUser"
      @mouseenter="handleMouseEnter" 
      @mouseleave="handleMouseLeave"
    >
      <fa
      class="opacity-20 cursor-pointer" 
      icon="grip-lines-vertical"
      />
    </td> -->
    <td class="p-3 w-2/12" draggable="false">{{ item.title }}</td>
    <td class="p-3 w-6/12" draggable="false">{{ item.description }}</td>
    <td class="p-3 w-2/12" draggable="false">
      <Check v-if="item.file_url" class="w-6 h-6 opacity-40" icon="check" />
      <Close v-else class="w-6 h-6 opacity-40" icon="times" />
    </td>
    <td v-if="isAdminOrPopsUser" class="p-1 w-2/12">
      <div class="flex">
        <Edit
          class="w-6 h-6 opacity-40 cursor-pointer"
          @click="openComponentsPage('edit', 'partner_ops', item)"
        />
        <Trash
          v-if="!isBasePackage"
          class="ml-3 w-6 h-6 opacity-40 cursor-pointer"
          icon="trash"
          @click="openDeleteModal(item)"
        />
      </div>
    </td>
    <td v-if="!isAdminOrPopsUser" class="p-3">
      <fa
        class="opacity-40"
        icon="eye"
        @click="openComponentsPage('view', 'partner', item)"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setComponentPageID, setCurrentUserAction } from '@/store/customMail'
import router from '@/router'
import { CUSTOM_MAIL_CONSTS } from '@/consts'

export default defineComponent({
  name: 'PackagesTableRow',
  props: {
    item: { type: Object, required: true },
    isAdminOrPopsUser: { type: Boolean, required: true },
    isBasePackage: { type: Boolean }
  },
  emits: ['openComponentDeleteModal', 'resortTableData'],
  data() {
    return {
      draggable: false
    }
  },
  methods: {
    openComponentsPage(type: string, path: string, item: any) {
      setCurrentUserAction(`component-page-${type}`)
      setComponentPageID(item.id)
      const currentPath = this.$route.path
      router.push({
        path: `${currentPath}/${item.id}`,
        params: {
          ...this.$route.params,
          componentID: item.id
        }
      })
    },
    openDeleteModal(item) {
      item.type = CUSTOM_MAIL_CONSTS.DELETE_MODAL_TYPE_COMPONENT
      this.$emit('openComponentDeleteModal', item)
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('item', JSON.stringify(item))
    },
    onDrop(evt, item) {
      if (this.isBasePackage) return
      const el = evt.dataTransfer.getData('item')
      const itemToSort = {
        source: el,
        target: item.id
      }
      this.$emit('resortTableData', itemToSort)
    },
    handleMouseEnter() {
      this.draggable = true
    },
    handleMouseLeave() {
      this.draggable = false
    }
  }
})
</script>
