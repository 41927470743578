import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Book = _resolveComponent("Book")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    class: "leading-3",
    title: "See related change logs",
    to: _ctx.url
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Book, { class: "w-4 h-4 text-gray-100 hover:text-gray-300 align-text-bottom inline-block ml-1" })
    ]),
    _: 1
  }, 8, ["to"]))
}