<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="text-3xl text-bold justify-center flex">
      Confirm
      <fa class="text-lemonXD ml-1 h-5" icon="exclamation-triangle" />
    </div>
    <div class="flex justify-center text-lg mt-2">
      You are about to &nbsp;
      <b>DELETE</b>
      &nbsp; {{ itemType }} ({{ data.title }})
    </div>
    <div class="flex mt-4 -mb-2">
      <div class="flex-1" />
      <PackageButton :on-click="close">Cancel</PackageButton>
      <PackageButton
        class="ml-2"
        :on-click="confirmDeleteAction"
        variant="solid"
      >
        Delete
      </PackageButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import PackageButton from './PackageButton.vue'

export default defineComponent({
  name: 'PackageDeleteModal',
  components: {
    PackageButton
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isOpen: { type: Boolean, default: false },
    itemType: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  emits: ['close', 'deleteItem'],
  methods: {
    confirmDeleteAction() {
      const item = this.data as any
      item.type = this.itemType
      this.$emit('deleteItem', item)
    },
    close() {
      this.$emit('close')
    }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 5px;
}
</style>
