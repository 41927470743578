import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e74f89a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-column items-baseline justify-items-center p-2 w-100" }
const _hoisted_2 = { class: "grid grid-cols-7 w-100" }
const _hoisted_3 = {
  key: 0,
  class: "w-100 bg-mist"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Edit = _resolveComponent("Edit")!
  const _component_PartnerBidsTableRow = _resolveComponent("PartnerBidsTableRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key.name,
          class: "items-baseline text-sm pl-3 pb-3"
        }, _toDisplayString(key.name), 1))
      }), 128)),
      _createVNode(_component_Edit, {
        class: "h-5 mt-1 mr-2 flex justify-self-end col-span-4 self-baseline text-gray-100",
        onClick: _ctx.openPartnerEditModal
      }, null, 8, ["onClick"])
    ]),
    (_ctx.bidsByPartner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bidsByPartner.bidsArray, (bid) => {
            return (_openBlock(), _createElementBlock("div", {
              key: bid.partner_id
            }, [
              _createVNode(_component_PartnerBidsTableRow, {
                class: "items-baseline grid grid-cols-7",
                bid: bid,
                "all-bidding-partners": _ctx.allBiddingPartners,
                "is-admin-or-pops-user": _ctx.isAdminOrPopsUser,
                onOpenPartnerEditModal: _ctx.openPartnerEditModal
              }, null, 8, ["bid", "all-bidding-partners", "is-admin-or-pops-user", "onOpenPartnerEditModal"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}