<template title="Send Password Reset">
  <H2>Forgot Password</H2>
  <br />
  <form @submit.prevent="forgotPassword">
    <TextInput
      id="email"
      v-model="email"
      label="Enter your email to reset your password"
      placeholder="john@doe.com"
    />
    <Alert v-show="errorMessage" variant="error">
      {{ errorMessage }}
    </Alert>
    <Alert v-show="successEmail" variant="success">
      Password reset link sent to {{ successEmail }}
    </Alert>
    <br />
    <LobButton @click="submit">Send Reset Link</LobButton>
  </form>
</template>

<script lang="ts">
import { sendResetPasswordLink } from '@/store/session'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SendPasswordReset',
  data() {
    return {
      // Input from the user
      email: null as string | null,
      // Are we waiting for a response?
      pending: false,
      // Set to a string on failure.
      errorMessage: null as string | null,
      // So that changing the email does not change the success message.
      successEmail: null as string | null
    }
  },
  methods: {
    async submit() {
      if (this.email === null) {
        this.errorMessage = 'Please enter your email'
        return
      }
      // Clear messages.
      this.errorMessage = null
      this.successEmail = null
      // We are about to wait for a result.
      this.pending = true
      try {
        // Send the API request.
        await sendResetPasswordLink({ email: this.email })
        // If no error is thrown, show a success message.
        this.successEmail = this.email
      } catch (e) {
        // Otherwise, format a friendly error message.
        const eResp = e.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
      // We are no longer waiting for a result.
      this.pending = false
    }
  }
})
</script>
