<script lang="ts">
import { MATERIEL_INVENTORY_STATUS_OPTIONS } from '@/consts'
import { defineComponent, ref } from 'vue'
import InventoryUpdateModal from './InventoryUpdateModal.vue'

export default defineComponent({
  name: 'InventoryCardEip',
  components: { InventoryUpdateModal },
  props: {
    label: { type: String, required: true },
    value: { type: [Number, String], required: true },
    canEdit: { type: Boolean, default: true },
    editProperty: { type: String, required: false, default: null },
    editMaterielInventoryId: { type: String, required: false, default: null },
    updateError: { type: String, default: null }
  },
  emits: ['close', 'updated'],
  setup(props) {
    const editStatus = props.label === 'status'
    const editValue = ref(props.value)
    const inEditing = ref(false)
    const inventoryUpdateModalVisible = ref(false)
    const statusOptions = MATERIEL_INVENTORY_STATUS_OPTIONS

    const onReset = () => {
      editValue.value = props.value
      onClose()
    }

    const onClose = () => {
      inventoryUpdateModalVisible.value = false
      inEditing.value = false
    }

    const edit = () => {
      if (editValue.value === props.value) {
        inEditing.value = false
        return
      }
      inventoryUpdateModalVisible.value = true
    }

    return {
      edit,
      editStatus,
      editValue,
      inEditing,
      inventoryUpdateModalVisible,
      onClose,
      onReset,
      statusOptions
    }
  }
})
</script>

<template>
  <div class="bg-white-100 rounded py-1 px-2 flex items-center mb-1">
    <div class="uppercase text-gray-500 text-xs w-24 flex-shrink-0">
      {{ label }}
    </div>
    <div
      v-if="!inEditing"
      :class="`text-xs font-bold text-left text-gray-700 w-full ${
        !canEdit ? 'mr-3' : ''
      }`"
    >
      <slot></slot>
      {{ editValue }}
    </div>

    <div
      v-if="inEditing"
      :class="`flex text-xs font-bold text-left text-gray-700 w-full ${
        !canEdit ? 'mr-3' : ''
      }`"
    >
      <Dropdown
        v-if="editStatus"
        id="status"
        v-model="editValue"
        class="w-40"
        label=""
        :options="statusOptions"
        size="small"
      />
      <TextInput
        v-else
        :id="editMaterielInventoryId"
        v-model.number="editValue"
        size="small"
        class="w-16 !p-0"
        input-class="h-4 !p-0 !m-0 text-xs no-p text-center"
        type="number"
        label="edit"
        sr-only-label
        @keyup.enter="edit"
      />
    </div>

    <lob-button
      v-show="canEdit && !inEditing"
      variant="none"
      size="small"
      :class="`p-1 border-0`"
      data-testid="edit-button"
      @click="inEditing = true"
    >
      <Edit class="w-4 text-gray-100"></Edit>
    </lob-button>

    <lob-button
      v-show="canEdit && inEditing"
      variant="none"
      size="small"
      :class="`p-1 border-0 mr-1`"
      data-testid="save-button"
      @click="edit"
    >
      <Save class="w-4 text-gray-100"></Save>
    </lob-button>
    <lob-button
      v-show="canEdit && inEditing"
      variant="none"
      size="small"
      :class="`p-1 border-0`"
      @click="onReset"
    >
      <Close class="w-4 text-gray-100"></Close>
    </lob-button>
  </div>
  <InventoryUpdateModal
    v-if="canEdit && inventoryUpdateModalVisible"
    :visible="inventoryUpdateModalVisible"
    :update-materiel-inventory-id="editMaterielInventoryId"
    :update-message="`Updating ${label} from ${value} to ${editValue}`"
    :update-property="editProperty"
    :update-value="editValue"
    @close="onClose"
    @reset="onReset"
    @updated="$emit('updated', $event)"
  />
</template>
<style lang="scss">
.no-p {
  // Hack the inputbox to make it as small as possible
  padding: 1px !important;
}
</style>
