<template>
  <div
    ref="dropZone"
    class="file-picker-root"
    :class="{ 'file-picker-root': true, disabled: disabled }"
    @dragenter="handleDragEnter($event)"
    @dragleave="handleDragLeave($event)"
    @dragover="handleDragOver($event)"
    @drop="handleDrop($event)"
  >
    <div />
    <div>
      <P v-if="disabled">
        {{ disabledMessage }}
      </P>
      <P v-else-if="waitingForDrop">Drop to upload</P>
      <P v-else>
        Drag and drop or
        <a @click="openFileDialog">click here</a>
        to upload files
      </P>
      <!-- This technique is necessary to maintain compatibility with older browsers. -->
      <div style="overflow: hidden; width: 0; height: 0">
        <input
          ref="hiddenFileInput"
          type="file"
          data-testId="file-input"
          multiple
          @change="handleFileChange"
        />
      </div>
    </div>
    <div />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FilePicker',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    disabledMessage: {
      type: String,
      default: 'Loading...'
    }
  },
  emits: ['filesPicked'],
  setup() {
    const dropZone = ref<HTMLElement | null>(null)
    const hiddenFileInput = ref<HTMLElement | null>(null)
    return {
      dropZone,
      hiddenFileInput
    }
  },
  data() {
    return {
      // True when the user has dragged a file over the drop zone without dropping it
      waitingForDrop: false,
      // The current element that something is being dragged over.
      currentlyOver: null as any
    }
  },
  methods: {
    openFileDialog() {
      this.hiddenFileInput?.click()
    },
    handleFileChange(e: Event) {
      const t = e.target as any
      if (t.files) {
        // We need to copy this otherwise it doesn't work for
        // mysterious (probably objects=references related) reasons
        const asList: any[] = []
        for (const file of t.files) {
          asList.push(file)
        }
        this.handleFiles(asList)
        t.value = null
      }
    },
    handleDragEnter(e: DragEvent) {
      this.waitingForDrop = true
      this.currentlyOver = e.target
    },
    handleDragLeave(e: DragEvent) {
      if (this.currentlyOver === e.target && e.target === this.dropZone) {
        this.waitingForDrop = false
      }
    },
    handleDragOver(e: Event) {
      // Prevents the browser from opening a dropped file.
      e.preventDefault()
    },
    handleDrop(e: DragEvent) {
      this.waitingForDrop = false
      e.preventDefault()
      const files: Array<File> = []
      // Collect files from the drop event, using one of two possibly available APIs.
      // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
      if (e.dataTransfer?.items) {
        for (const item of e.dataTransfer.items) {
          if (item.kind === 'file') {
            const file = item.getAsFile()
            if (file) files.push(file)
          }
        }
      } else if (e.dataTransfer?.files) {
        for (const file of e.dataTransfer.files) files.push(file)
      }
      // Then send them to the handler.
      this.handleFiles(files)
    },
    handleFiles(files) {
      this.$emit('filesPicked', files)
    }
  }
})
</script>

<style scoped lang="scss">
.file-picker-root {
  width: 100%;
  height: 100%;
  background: $gray-fog;
  border: 2px dashed $shadow-50;
  border-radius: 0.5rem;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 3rem 0;
  justify-content: center;
  align-content: center;
}
a:hover {
  color: $cobalt;
  cursor: pointer;
}
a,
a:focus,
a:active {
  color: $cerulean;
  cursor: pointer;
}
</style>
