<template>
  <P class="form-factor-title">({{ formFactorTitle }} resource)</P>
  <div v-for="field of formFactorFields" :key="field.id">
    <P>
      <span class="label">{{ field.name }}:</span>
      {{ getFieldText(field.id) }}
    </P>
  </div>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'
import { getFieldInfos, FieldInfo, snakeCaseToHumanReadable } from './util'

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    value: {
      validator: () => true,
      required: true
    } as Prop<Record<string, unknown>>
  },
  computed: {
    formFactorTitle(): string {
      return snakeCaseToHumanReadable((this.value?.form_factor || '') as string)
    },
    formFactorFields(): Array<FieldInfo> {
      if (this.schema === undefined) throw new Error('schema is required')
      const desiredFormFactor = this.value?.form_factor
      const ff = this.schema.form_factors.find(
        (x) => x.id === desiredFormFactor
      )
      if (ff === undefined)
        throw new Error(`illegal form factor id ${desiredFormFactor}`)
      return getFieldInfos(ff)
    }
  },
  methods: {
    getFieldText(fieldName) {
      if (this.value === undefined) throw new Error('value is required')
      const value = this.value[fieldName]
      if (typeof value === typeof '') {
        return value
      } else {
        return JSON.stringify(value)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.form-factor-title {
  text-transform: uppercase;
  color: $cobalt;
}
.label {
  opacity: 0.5;
}
</style>
