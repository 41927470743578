import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f027f6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  class: "w-3/12 h-3/12"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 3,
  class: "w-3/12 h-3/12 ml-1"
}
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.cellData && _ctx.cellData.linkTo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.getSmallestImageURL(),
          class: "w-",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openInNewTab()))
        }, null, 8, _hoisted_1))
      : (_ctx.cellData && _ctx.cellData.actionRequired && _ctx.cellData.actionCompleted)
        ? (_openBlock(), _createBlock(_component_fa, {
            key: 1,
            class: "text-success mt-1",
            icon: "check"
          }))
        : (_ctx.cellData && _ctx.cellData.isInput)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", {
                for: `${_ctx.tableID}-${_ctx.rowData.index}`,
                class: "custom-file-input-label"
              }, [
                _createVNode(_component_fa, {
                  class: "tertiary-bluebird cursor-pointer",
                  icon: _ctx.cellData.icon
                }, null, 8, ["icon"])
              ], 8, _hoisted_3),
              (_ctx.cellInfo.inputEnabled)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 0,
                    id: `${_ctx.tableID}-${_ctx.rowData.index}`,
                    ref: `${_ctx.tableID}-${_ctx.rowData.index}`,
                    type: "file",
                    class: "hidden",
                    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args)))
                  }, null, 40, _hoisted_4))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
    (_ctx.cellData && _ctx.cellData.isRefresh)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("label", {
            for: `${_ctx.tableID}-${_ctx.rowData.index}-refresh`,
            class: "custom-file-input-label"
          }, [
            _createVNode(_component_fa, {
              class: "tertiary-bluebird cursor-pointer",
              icon: _ctx.cellData.icon
            }, null, 8, ["icon"])
          ], 8, _hoisted_6),
          (_ctx.cellInfo.inputEnabled)
            ? (_openBlock(), _createElementBlock("input", {
                key: 0,
                id: `${_ctx.tableID}-${_ctx.rowData.index}-refresh`,
                ref: `${_ctx.tableID}-${_ctx.rowData.index}-refresh`,
                type: "file",
                class: "hidden",
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args)))
              }, null, 40, _hoisted_7))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}