import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnvelopeIcon = _resolveComponent("EnvelopeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1], (index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (_ctx.thumbnails?.[index] && !_ctx.thumbHidden)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "thumb",
                src: _ctx.thumbnails[index].small,
                onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideThumb && _ctx.hideThumb(...args)))
              }, null, 40, _hoisted_3),
              _createElementVNode("img", {
                class: _normalizeClass(_ctx.imgClass),
                src: _ctx.thumbnails[index].large
              }, null, 10, _hoisted_4)
            ]))
          : (_openBlock(), _createBlock(_component_EnvelopeIcon, {
              key: 1,
              variant: _ctx.iconVariants[index]
            }, null, 8, ["variant"]))
      ], 64))
    }), 64))
  ]))
}