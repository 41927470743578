<template>
  <svg
    :width="currentSize"
    :height="currentSize"
    :viewBox="`0 0 ${currentSize} ${currentSize}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path :d="path" fill="currentColor" />
  </svg>
</template>

<script>
const iconInfo = {
  xxl: {
    size: 24,
    path: 'M8.203 8.203c.44-.436 1.153-.436 1.552 0l2.203 2.208 2.245-2.208c.44-.436 1.153-.436 1.552 0 .478.44.478 1.153 0 1.552l-2.166 2.203 2.166 2.245c.478.44.478 1.153 0 1.552-.399.478-1.111.478-1.552 0l-2.245-2.166-2.203 2.166c-.399.478-1.111.478-1.552 0-.436-.399-.436-1.111 0-1.552l2.208-2.245-2.208-2.203c-.436-.399-.436-1.111 0-1.552zM24 12c0 6.628-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0s12 5.372 12 12zM12 2.25A9.749 9.749 0 0 0 2.25 12 9.749 9.749 0 0 0 12 21.75 9.749 9.749 0 0 0 21.75 12 9.749 9.749 0 0 0 12 2.25z'
  },
  xl: {
    size: 20,
    path: 'M6.836 6.836c.367-.363.96-.363 1.293 0l1.836 1.84 1.87-1.84c.368-.363.962-.363 1.294 0 .398.367.398.96 0 1.293l-1.805 1.836 1.805 1.87c.398.368.398.962 0 1.294-.332.398-.926.398-1.293 0l-1.871-1.805-1.836 1.805c-.332.398-.926.398-1.293 0-.363-.332-.363-.926 0-1.293l1.84-1.871-1.84-1.836c-.363-.332-.363-.926 0-1.293zM20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zM10 1.875A8.124 8.124 0 0 0 1.875 10 8.124 8.124 0 0 0 10 18.125 8.124 8.124 0 0 0 18.125 10 8.124 8.124 0 0 0 10 1.875z'
  },
  l: {
    size: 18,
    path: 'M6.152 6.152c.33-.327.865-.327 1.164 0l1.652 1.656 1.684-1.656c.33-.327.865-.327 1.164 0 .359.33.359.865 0 1.164l-1.624 1.652 1.624 1.684c.359.33.359.865 0 1.164-.299.359-.833.359-1.164 0l-1.684-1.624-1.652 1.624c-.299.359-.833.359-1.164 0-.327-.299-.327-.833 0-1.164l1.656-1.684-1.656-1.652c-.327-.299-.327-.833 0-1.164zM18 9a9 9 0 0 1-9 9 9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9zM9 1.687A7.311 7.311 0 0 0 1.687 9 7.311 7.311 0 0 0 9 16.313 7.311 7.311 0 0 0 16.313 9 7.311 7.311 0 0 0 9 1.687z'
  },
  m: {
    size: 16,
    path: 'M5.469 5.469c.293-.29.768-.29 1.034 0L7.972 6.94l1.497-1.472c.293-.29.769-.29 1.034 0 .319.293.319.768 0 1.034L9.06 7.972l1.444 1.497c.319.293.319.769 0 1.034-.265.319-.74.319-1.034 0L7.972 9.06l-1.469 1.444c-.266.319-.74.319-1.034 0-.29-.265-.29-.74 0-1.034L6.94 7.972 5.469 6.503c-.29-.266-.29-.74 0-1.034zM16 8a8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8 8 8 0 0 1 8 8zM8 1.5C4.41 1.5 1.5 4.41 1.5 8s2.91 6.5 6.5 6.5 6.5-2.91 6.5-6.5S11.59 1.5 8 1.5z'
  },
  s: {
    size: 14,
    path: 'M4.785 4.785c.257-.254.673-.254.905 0l1.285 1.288 1.31-1.288c.257-.254.673-.254.905 0 .28.257.28.673 0 .905L7.927 6.975l1.263 1.31c.28.257.28.673 0 .905-.232.28-.648.28-.905 0l-1.31-1.263L5.69 9.19c-.232.28-.648.28-.905 0-.254-.232-.254-.648 0-.905l1.288-1.31L4.785 5.69c-.254-.232-.254-.648 0-.905zM14 7a7 7 0 0 1-7 7 7 7 0 0 1-7-7 7 7 0 0 1 7-7 7 7 0 0 1 7 7zM7 1.312A5.687 5.687 0 0 0 1.312 7 5.687 5.687 0 0 0 7 12.688 5.687 5.687 0 0 0 12.688 7 5.687 5.687 0 0 0 7 1.312z'
  }
}

export default {
  name: 'CircleXmark',
  props: {
    size: {
      type: String,
      default: 'm',
      validator: function (value) {
        return ['xxl', 'xl', 'l', 'm', 's'].includes(value)
      }
    }
  },
  computed: {
    currentSize() {
      return iconInfo[this.size].size
    },
    path() {
      return iconInfo[this.size].path
    }
  }
}
</script>
