/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: use types
// import { Permutation } from '@/store/digitalProofing'
// return permutations.map((p) => {
//   const { title, id, items}
//   const type = {
//     title,
//     id,
//     items,
//     displayTitle
//   }
//   return type
// })
// return []
// export interface Type {
//   title: string,
//   id: string,
//   items: Array<Permutation>
// }

// TODO: extract out factories and explore using https://github.com/thoughtbot/fishery instead
export const permutationFactory = (opts) => {
  return {
    date_created: 'string',
    date_modified: 'string',
    id: opts?.id || '',
    partner: 'partner',
    permutation: opts?.permutation || 'permutation',
    resource: opts?.resource || 'resource',
    steps_completed: ['Array<string>'],
    urls: ['Array<string>'],
    pending: false
  }
}

export const typeFactory = (opts) => ({
  title: opts?.title || 'title',
  id: opts?.id || 'id',
  items: opts?.items || [],
  capacityPending: false
})

const titleCase = (title) => {
  const words = title.split('_')
  const titledWords = words.map((w) => {
    return (w.charAt(0).toUpperCase() + w.slice(1)).replace(/^us$/i, 'US')
  })
  return titledWords.join(' ')
}

export const permWithDisplayName = (permutation) => {
  const decoratedPermutation = Object.assign(permutation)
  decoratedPermutation.displayName = titleCase(
    decoratedPermutation.permutation
  ).replace(/(\d+)x(\d+)/g, '$1" x $2"')
  return decoratedPermutation
}

export const permutationsToTypes = (permutations) => {
  if (!permutations) return []
  const resourcesSeen = []
  const reducer = (typeArray, permutation) => {
    permutation.pending = false
    if (resourcesSeen.includes(permutation.resource)) {
      const foundType = typeArray.find((t) => {
        return t.title === permutation.resource
      })
      foundType.items.push(permWithDisplayName(permutation))
    } else {
      typeArray.push({
        title: permutation.resource,
        id: permutation.resource,
        items: [permWithDisplayName(permutation)],
        displayTitle: titleCase(permutation.resource),
        capacityPending: false
      })
      resourcesSeen.push(permutation.resource)
    }
    return typeArray
  }
  const types = permutations.reduce(reducer, [])
  return types
}
