<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="checkbox-field-editor-root mb-3">
    <FieldHeader
      :schema="schema"
      :field="field"
      :test-set="testSet"
      supports-separation
      @update:testSet="$emit('update:testSet', $event)"
    />
    <div class="content">
      <span v-for="value of field.type" :key="value.value" class="item">
        <Checkbox
          :model-value="hasValue(value.value)"
          :name="value.name"
          :label="value.name"
          @update:modelValue="handleFieldChange(value.value, $event)"
        />
        <FormFactorNote
          v-if="!arraysEqual(value.form_factors, field.form_factors)"
          :schema="schema"
          :form-factors="value.form_factors"
        />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Prop } from '@vue/runtime-core'
import FieldHeader from './FieldHeader.vue'
import FormFactorNote from '../FormFactorNote.vue'
import { arraysEqual } from '@/utils/arrays'
import { TestSet } from '@/store/testSets'
import { Schema, SchemaField } from '@/store/testSets/schema'
import { getShowBetaFeatureFlag } from '@/store/launchDarkly'

export default defineComponent({
  name: 'CheckboxMVE',
  components: { FormFactorNote, FieldHeader },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    } as Prop<SchemaField>,
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    ffCurrentValues: {
      type: Map,
      default: () => {
        return {}
      }
    }
  },
  emits: ['update:testSet', 'update:formFactor'],
  data() {
    return {
      showBetaFeatureFlag: getShowBetaFeatureFlag()
    }
  },
  methods: {
    hasValue(value) {
      if (this.showBetaFeatureFlag) {
        if (
          !this.field ||
          !this.testSet ||
          !this.ffCurrentValues?.has(this.field.id)
        ) {
          return false
        }
        const currentValues = this.ffCurrentValues.get(
          this.field.id
        ) as Array<string>
        return currentValues.includes(value)
      } else {
        if (!this.field || !this.testSet) {
          return false
        }
        return this.testSet.fields[this.field.id]?.some((x) => x === value)
      }
    },
    handleFieldChange(value, put) {
      if (!this.field || !this.testSet) {
        return
      }

      const old = this.testSet.fields[this.field.id] || []
      const newValues = put
        ? old.concat([value])
        : this.deleteOldValue(old, old.indexOf(value))
      // should also check if page is on feature flow
      if (this.showBetaFeatureFlag) {
        this.$emit('update:formFactor', {
          id: this.field.id,
          value,
          put,
          type: 'checkbox'
        })
      } else {
        this.$emit('update:testSet', {
          ...this.testSet,
          fields: {
            ...this.testSet.fields,
            [this.field.id]: newValues
          }
        })
      }
    },
    deleteOldValue(arr, index) {
      arr.splice(index, 1)
      return arr
    },
    arraysEqual
  }
})
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
}
.item {
  display: flex;
  margin-right: 1rem;
}
.item > :first-child {
  // And again, bootstrap tries to important a basic layout rule and we need to tell it to calm down.
  margin-right: 0 !important;
}
</style>
