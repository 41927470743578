<template>
  <form>
    <div class="row mt-4 contentSection">
      <div class="col-3">
        <p class="sectionTitle">Timezone</p>
      </div>
      <div class="col-9">
        <Dropdown
          id="timezone"
          v-model="zone"
          :options="timezones"
          label="Select Timezone"
          placeholder="Select a Timezone"
          class="combobox"
        />
        <Alert v-if="errorMessage" :variant="errorType" class="mt-2">
          {{ errorMessage }}
        </Alert>

        <LobButton
          title="Save data"
          class="mt-2"
          data-testid="timezoneBtn"
          :disabled="pending"
          @click.prevent="timezoneSubmit"
        >
          Save Data
        </LobButton>
      </div>
    </div>
  </form>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import * as session from '@/store/session'
import * as timezones from './timezones.json'
import { getCurrentPartner, getCurrentPartnerId } from '@/store/partners'
export default defineComponent({
  name: 'TimezoneForm',
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errorMessage: null as null | string,
      errorType: 'error',
      pending: false,
      timezones: timezones.zones,
      setZone: '',
      setCutoff: undefined
    }
  },
  computed: {
    zone: {
      get() {
        const partner = getCurrentPartner()
        const tz = partner
          ? timezones.zones.find((t) => t.value === partner.timezone)
          : null
        return tz ?? { value: '', text: '', pstNoonEquivalent: undefined }
      },
      set(newTimezone) {
        this.setZone = newTimezone.value
        this.setCutoff = newTimezone.pstNoonEquivalent
      }
    }
  },
  methods: {
    async timezoneSubmit() {
      if (this.setZone === '') {
        return
      }
      const partnerId = getCurrentPartnerId()
      if (partnerId === null) return
      this.pending = true
      this.errorMessage = null
      try {
        // Try to update cutoff
        await session.updatePartner({
          partner: partnerId,
          data: {
            timezone: this.setZone,
            local_cutoff: this.setCutoff
          }
        })
        this.errorMessage = 'Save Successful'
        this.errorType = 'success'
        this.setZone = ''
      } catch (e) {
        // Format an error message if unsuccessful.
        const eResp = e.response
        if (!eResp) {
          this.errorMessage = 'An unknown server error has occurred'
        } else {
          this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
        }
      }
      this.pending = false
    }
  }
})
</script>
