<script lang="ts">
import { createPartner } from '@/store/partners'
import { defineComponent, ref, watch } from 'vue'
import { zones } from '../../utils/timezones.json'

const PLATFORMS = [
  {
    label: 'windows',
    value: 'windows'
  },
  {
    label: 'linux',
    value: 'linux'
  }
]

export default defineComponent({
  name: 'InvitePartnerModal',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  emits: ['close', 'saved'],
  setup(props, { emit }) {
    const displayName = ref('')
    const displayNameError = ref<string | null>(null)
    const id = ref('')
    const idError = ref<string | null>(null)
    const timezone = ref(zones[0])
    const platform = ref(PLATFORMS[0])
    const error = ref<string | null>('')
    const submit = async () => {
      error.value = null
      try {
        if (displayName.value === '') {
          displayNameError.value = `Display name is required `
        }

        if (id.value === '') {
          idError.value = `ID is required `
        }

        if (displayNameError.value === null && idError.value === null) {
          await createPartner({
            display_name: displayName.value,
            id: id.value,
            timezone: timezone.value.value
          })
          emit('saved')
        }
      } catch (e) {
        error.value = e.message
      }
    }

    watch(
      () => displayName.value,
      () => {
        displayNameError.value = null
      }
    )
    watch(
      () => id.value,
      () => {
        idError.value = null
      }
    )

    return {
      displayName,
      id,
      zones,
      timezone,
      error,
      displayNameError,
      idError,
      submit,
      PLATFORMS,
      platform
    }
  }
})
</script>

<template>
  <Modal v-if="isOpen" :visible="isOpen" width="500px" @close="$emit('close')">
    <template #header>
      <h4>Create partner</h4>
    </template>
    <div>
      <TextInput
        id="display_name"
        v-model="displayName"
        label="Display Name"
        class="mb-2"
        data-testid="display-name"
      />
      <alert v-if="displayNameError" variant="error" class="mb-2">
        {{ displayNameError }}
      </alert>
      <TextInput id="id" v-model="id" label="ID" class="mb-2" />
      <alert v-if="idError" variant="error" class="mb-2">{{ idError }}</alert>
      <Dropdown
        v-if="zones.length > 0"
        id="timezone"
        v-model="timezone"
        :options="zones"
        label="Select Timezone"
        placeholder="Select a Timezone"
        class="combobox mb-2"
      />
      <!-- <Dropdown
        id="platform"
        v-model="platform"
        :options="PLATFORMS"
        label="Select platform"
        placeholder="Select a Platform"
        class="combobox"
      /> -->

      <alert v-if="error" variant="error" class="mt-4">{{ error }}</alert>
    </div>

    <template #footer>
      <div class="flex self-end">
        <LobButton variant="secondary" size="small" @click="$emit('close')">
          Close
        </LobButton>
        <LobButton
          variant="primary"
          class="ml-2"
          size="small"
          data-testid="submit"
          @click.stop="submit"
        >
          Confirm
        </LobButton>
      </div>
    </template>
  </Modal>
</template>
