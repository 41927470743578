<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomeHeader',
  props: {
    partnerDisplayName: {
      type: String,
      default: ''
    }
  }
})
</script>

<template>
  <header>
    <h1 class="text-gray-300 text-4xl font-thin">Welcome back</h1>
    <h2 class="text-gray-500 text-base font-normal mt-2">
      {{ partnerDisplayName }} Partner Dashboard
    </h2>
  </header>
</template>
