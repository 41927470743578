<template>
  <Badge :variant="cellVariant">
    {{ cellData }}
  </Badge>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

/* 
  tableRowObject for BadgeCell: 
  {
    fieldName,
    type,
    calculateVariant(status)
  }
*/

export default defineComponent({
  name: 'BadgeCell',
  props: {
    cellInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cellData: {
      type: String,
      default: () => {
        return ''
      },
      required: true
    }
  },
  computed: {
    cellVariant() {
      if (this.cellInfo && this.cellData) {
        return this.cellInfo.calculateVariant(this.cellData)
      } else {
        return 'info'
      }
    }
  }
})
</script>
