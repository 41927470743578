import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Check = _resolveComponent("Check")!
  const _component_Close = _resolveComponent("Close")!

  return (_ctx.cellData)
    ? (_openBlock(), _createBlock(_component_Check, {
        key: 0,
        class: "w-6 h-6 opacity-40",
        icon: "check"
      }))
    : (_openBlock(), _createBlock(_component_Close, {
        key: 1,
        class: "w-6 h-6 opacity-40",
        icon: "times"
      }))
}