import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-283e8049"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "bottomThreeRow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_FieldSetRow = _resolveComponent("FieldSetRow")!
  const _component_P = _resolveComponent("P")!

  return (_openBlock(), _createBlock(_component_P, null, {
    default: _withCtx(() => [
      _createVNode(_component_FieldSetRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_TextInput, {
            id: "name",
            label: "Name",
            "model-value": _ctx.getField('name'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setField('name', $event)))
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_FieldSetRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_TextInput, {
            id: "line1",
            label: "Line 1",
            "model-value": _ctx.getField('address_line1'),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.setField('address_line1', $event)))
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_FieldSetRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_TextInput, {
            id: "line2",
            label: "Line 2",
            "model-value": _ctx.getField('address_line2'),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.setField('address_line2', $event)))
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_FieldSetRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_TextInput, {
            id: "city",
            label: "City",
            "model-value": _ctx.getField('address_city'),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.setField('address_city', $event)))
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_FieldSetRow, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TextInput, {
              id: "state",
              label: "State/Province",
              "model-value": _ctx.getField('address_state'),
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.setField('address_state', $event)))
            }, null, 8, ["model-value"]),
            _createVNode(_component_TextInput, {
              id: "zip",
              label: "Zip Code",
              "model-value": _ctx.getField('address_zip'),
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.setField('address_zip', $event)))
            }, null, 8, ["model-value"]),
            _createVNode(_component_TextInput, {
              id: "country",
              label: "Country Code",
              "model-value": _ctx.getField('address_country'),
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.setField('address_country', $event)))
            }, null, 8, ["model-value"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}