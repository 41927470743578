<template>
  <tr :class="rowContainerStyle">
    <td
      v-for="cell in rowObject"
      :key="cell.key"
      :class="cellContainerStyle(cell)"
    >
      <div v-if="cell.children" class="flex">
        <!-- used if there should be more than one component in a cell,
             components will be closer and joined into a single cell
        -->
        <TableCell
          v-for="childCell of cell.children"
          :key="childCell.key"
          :table-i-d="tableID"
          :cell-data="data[childCell.fieldName]"
          :cell-info="childCell"
          :row-data="data"
          class="mx-1"
          @handleValueChange="handleValueChange"
        />
      </div>
      <TableCell
        v-else
        :table-i-d="tableID"
        :cell-data="data[cell.fieldName]"
        :cell-info="cell"
        :row-data="data"
        @handleValueChange="handleValueChange"
      />
    </td>
    <td v-if="expandable">
      <fa
        v-if="data.expanded"
        class="cursor-pointer"
        icon="chevron-up"
        @click="toggleExpandedRow"
      />
      <fa
        v-else
        class="cursor-pointer"
        icon="chevron-down"
        @click="toggleExpandedRow"
      />
    </td>
  </tr>
  <tr :class="expandableContentStyle">
    <td colspan="12">
      <slot name="expandedContent"></slot>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TableCell from './TableCell.vue'

export default defineComponent({
  name: 'Table',
  components: {
    TableCell
  },
  props: {
    tableID: {
      type: String,
      default: () => {
        return ''
      }
    },
    rowObject: {
      // info about row cells, i.e. type of cell, which field to look for in data, etc.
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      // API data for the row
      type: Object,
      default: () => {
        return {}
      }
    },
    expandable: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    variant: {
      type: String,
      default: () => {
        return 'primary'
      }
    },
    secondaryRowColor: {
      type: String,
      default: () => {
        return ''
      }
    },
    compact: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    withBorder: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  emits: ['handleValueChange', 'openCloneModal', 'updateRow'],
  computed: {
    rowContainerStyle() {
      let baseClass = 'table-row w-100 border-b border-gray-100'
      if (this.data.expanded) baseClass = baseClass.concat(' bg-blueGray')
      if (this.secondaryRowColor)
        baseClass = baseClass.concat(` ${this.secondaryRowColor}`)
      return baseClass
    },
    expandableContentStyle() {
      return this.data.expanded
        ? 'bg-blueGray py-4 px-3 table-row-100 border-b border-gray-100'
        : 'hidden'
    }
  },
  methods: {
    cellContainerStyle(cell) {
      let baseClass = this.compact ? '' : 'p-3'
      if (this.variant === 'secondary') {
        baseClass = baseClass.replace('p-3', 'py-2 px-3')
      }
      if (cell.width) {
        baseClass = baseClass.concat(` ${cell.width}`)
      }
      if (this.withBorder) {
        baseClass = baseClass.concat('border')
      }
      return baseClass
    },
    openCloneModal(item: any) {
      this.$emit('openCloneModal', item)
    },
    toggleExpandedRow() {
      this.$emit('updateRow', { ...this.data, expanded: !this.data.expanded })
    },
    handleValueChange(e) {
      this.$emit('handleValueChange', {
        value: e,
        rowData: this.data,
        rowObject: this.rowObject
      })
    }
  }
})
</script>
