/* eslint-disable camelcase */
import { sendPapiRequest } from '@/store/requests'
import { AxiosError } from 'axios'
import { reactive, Ref, ref } from 'vue'

export type Changelog = {
  id: string
  materiel_id: string
  materiel_order_id: string
  num_remaining: string
  change_type: string
  note: string
  created_at: string
  user_id: string
  user_email: string
  materiel_inventory_id: string
  partner_id: string
}

type UseChangelogsReturnType = {
  fetchChangelogs: (
    accountCrid: string,
    resource: string,
    query: Record<string, unknown>
  ) => Promise<void>
  isLoading: Ref<boolean>
  errorMessage: Ref<string>
  page: Ref<number>
  changelogs: { data: Changelog[]; count: number }
}

export default (pageSize: number): UseChangelogsReturnType => {
  const isLoading = ref(false)
  const errorMessage = ref('')
  const page = ref(1)
  const changelogs = reactive<{ data: Changelog[]; count: number }>({
    data: [],
    count: 0
  })

  const fetchChangelogs = async (
    accountCrid: string,
    resource: string,
    query: Record<string, unknown>
  ) => {
    try {
      isLoading.value = true
      const {
        data: { data, count }
      } = await sendPapiRequest({
        path: `/inventory/accounts/${accountCrid}/changelogs`,
        method: 'GET',
        query: {
          type: resource,
          limit: pageSize,
          offset: (Number(page.value) - 1) * pageSize,
          ...query
        }
      })
      changelogs.data = data
      changelogs.count = count
      errorMessage.value = ''
    } catch (e) {
      errorMessage.value =
        (e as Error).message ||
        (e as AxiosError)?.response?.data?.error?.messsage
    } finally {
      isLoading.value = false
    }
  }

  return { fetchChangelogs, isLoading, page, changelogs, errorMessage }
}
