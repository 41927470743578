<template>
  <div
    class="bg-mist grid grid-cols-7 justify-items-center justify-items-center shadow-md shadow-black px-2"
  >
    <div class="w-100 p-3">
      {{ `Location ${partnerFacility.zipcode}` }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PartnersInnerTableRow',
  props: {
    partnerFacility: {
      type: Object,
      required: true
    }
  }
})
</script>
