import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_LobButton = _resolveComponent("LobButton")!

  return (_openBlock(), _createBlock(_component_LobButton, {
    variant: _ctx.cellInfo.variant,
    size: "small",
    class: "text-sm",
    onClick: _cache[0] || (_cache[0] = ($event) => _ctx.cellInfo.onClick($event, _ctx.rowData))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.cellInfo.buttonText) + " " + "   ", 1),
      (_ctx.cellInfo.icon)
        ? (_openBlock(), _createBlock(_component_fa, {
            key: 0,
            icon: _ctx.cellInfo.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["variant"]))
}