import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkbox-field-editor-root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListMVE = _resolveComponent("ListMVE")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListMVE, {
      schema: _ctx.schema,
      field: _ctx.field,
      "test-set": _ctx.testSet,
      "add-handlers": _ctx.addHandlers,
      "display-component": _ctx.staticData.AddressDisplay,
      "editor-component": _ctx.staticData.AddressEditor,
      "onUpdate:testSet": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:testSet', $event)))
    }, null, 8, ["schema", "field", "test-set", "add-handlers", "display-component", "editor-component"])
  ]))
}