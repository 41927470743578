<script lang="ts">
import { getUser, isAdminUser } from '@/store/session'
import router from '@/router'

import { defineComponent, computed } from 'vue'
export default defineComponent({
  name: 'Home',

  setup() {
    const isAdmin = computed(isAdminUser)
    const id = getUser()?.partner
    if (isAdmin.value) {
      router.push(`/partner_ops/partners/`)
    } else {
      router.push(`/partners/${id}`)
    }
  },
  render() {
    return ''
  }
})
</script>
