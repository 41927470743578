<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h2 :mode="configureMode">
        {{ modalTitle }}
      </h2>
      <Tooltip v-if="isCreating" position="bottom">
        <template #trigger>
          <Info class="ml-1 w-5 h-5" />
        </template>
        <template #content>
          <div class="w-32">
            {{ newBidTooltip }}
          </div>
        </template>
      </Tooltip>
    </div>
    <div>
      <div v-if="isCreating">
        <PackageDropdown
          v-show="isAdminOrPopsUser"
          id="bid-partner-id"
          v-model:value="selectedPartner"
          class="w-50 pb-2 h-200"
          label="Partner ID"
          placeholder="Select partner"
          required
          :options="partnerOptions"
        />
      </div>
      <div>
        <div class="flex flex-row justify-between">
          <h5>Partner Bid List</h5>
          <LobButton variant="secondary" @click="addNewQuantity">
            Add Quantity
          </LobButton>
        </div>
        <div
          v-for="(bid, index) in bids"
          :key="bid.id"
          class="flex flex-row mt-2"
        >
          <PackageTextInput
            class="ml-2"
            :value="bid.order_quantity.toString()"
            label="Quantity:"
            placeholder="Quantity"
            @update:value="(e) => updateBidValues(e, 'order_quantity', index)"
          />
          <PackageTextInput
            class="ml-2"
            :value="bid.total_us_dollars.toString()"
            label="Total Price (USD):"
            placeholder="Total Price (USD)"
            @update:value="(e) => updateBidValues(e, 'total_us_dollars', index)"
          />
          <PackageTextInput
            class="ml-2"
            :value="bid.lead_time_days.toString()"
            label="Lead Time (Days):"
            placeholder="Lead Time (Days)"
            @update:value="(e) => updateBidValues(e, 'lead_time_days', index)"
          />
          <Trash
            class="w-6 m-2 pt-4 text-gray-100 self-center cursor-pointer"
            @click="deleteQuantity(index)"
          />
        </div>
      </div>
    </div>
    <div class="flex items-end -mb-10 mt-5">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton
        class="mr-3"
        :disabled="createDisabled"
        @click="moveToBidsPage"
      >
        Submit
      </LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageTextInput from './PackageTextInput.vue'
import {
  setCurrentUserAction,
  createNewBids,
  editBidDetails,
  BidData,
  PartnerPackageBids
} from '@/store/customMail'
import PackageDropdown from './PackageDropdown.vue'
import { getAllPartners } from '@/store/partners'

export default defineComponent({
  name: 'BidModal',
  components: {
    PackageTextInput,
    PackageDropdown
  },
  props: {
    mode: { type: String, required: true },
    data: { type: Object, required: true },
    isOpen: { type: Boolean, required: true },
    isAdminOrPopsUser: { type: Boolean, required: true },
    productId: { type: String, required: true },
    currentPartner: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['close', 'saved', 'updateStatus', 'updateBidStatus'],
  data() {
    return {
      selectedPartner: { label: '', value: '' },
      bids: [] as BidData[],
      deletedQuantities: [] as string[],
      modalTitle: '',
      modalButtonAction: '',
      options: [],
      partnersWithBids: [],
      newBidTooltip: CUSTOM_MAIL_CONSTS.NEW_BID_TOOLTIP
    }
  },
  computed: {
    createDisabled() {
      if (
        this.selectedPartner.value === '' &&
        this.mode === CUSTOM_MAIL_CONSTS.BID_MODAL_CREATE_MODE
      )
        return true
      for (let i = 0; i < this.bids.length; i++) {
        if (this.bids[i].order_quantity === 0) return true
      }
      return false
    },
    partnerOptions(): any {
      const partners = getAllPartners()
      if (partners === null) return null
      const mappedPartners = partners.map((partner) => ({
        label: `${partner.display_name} (${partner.id})`,
        value: partner.id
      }))
      mappedPartners.sort((a, b) => {
        const al = a.label.toUpperCase()
        const bl = b.label.toUpperCase()
        if (al < bl) {
          return -1
        } else if (al > bl) {
          return 1
        } else {
          return 0
        }
      })
      return mappedPartners
    },
    isCreating() {
      return this.mode === CUSTOM_MAIL_CONSTS.BID_MODAL_CREATE_MODE
    }
  },
  watch: {
    mode: function (newVal) {
      this.configureMode(newVal)
    },
    partnersWithBids() {
      const data = this.partnerOptions()
      const mappedPartnersWithBids = data.map(
        (partnerWithBids) => partnerWithBids.id
      )
      mappedPartnersWithBids.sort((a, b) => {
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        } else {
          return 0
        }
      })
      this.partnersWithBids = mappedPartnersWithBids
    },
    currentPartner(newVal) {
      // if a partner is present, we're in editing mode and a quantity array already exists
      // else we're in create mode where only adding is allowed
      if (newVal.partner) {
        // for some reason, this is the only way to deep copy the array and not reference the
        // original array (otherwise the table view will update with new values when using the modal)
        this.bids = JSON.parse(JSON.stringify(newVal.partner.bidsArray))
      } else {
        this.bids = []
      }
    }
  },
  methods: {
    close() {
      this.$emit('close', {})
    },
    updateBidStatus() {
      let bidStatus = ''
      const bids = toRaw(this.bids) as BidData[]
      bids.forEach((bid) => {
        if (!bid.total_us_dollars || !bid.lead_time_days) {
          bidStatus = 'pending'
        } else {
          bidStatus = 'submitted'
        }
      })

      const partnerPackageData = {
        partner_id: this.bids[0].partner_id,
        packageID: this.productId,
        bidsArray: bids,
        status: bidStatus
      } as PartnerPackageBids

      this.$emit('updateStatus', this.bids[0].partner_id, partnerPackageData)
    },
    async moveToBidsPage() {
      const data: any = {
        productID: this.productId,
        partnerID: this.selectedPartner.value,
        bids: toRaw(this.bids) as Array<BidData>
      }

      if (this.mode === CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_POPS_MODE) {
        // this is an edit for admin or pops user, NOT partner user
        data.partnerID = this.currentPartner?.partner.partner_id
        data.deletedQuantities = this.deletedQuantities
        await editBidDetails(data)

        this.updateBidStatus()
        this.$emit('close', { reloadData: true })
      } else if (this.mode === CUSTOM_MAIL_CONSTS.BID_MODAL_CREATE_MODE) {
        await createNewBids(data)
        setCurrentUserAction(`bids-create`)
        this.bids.push({
          order_quantity: parseInt(data.bids.order_quantity),
          product_id: this.productId,
          partner_id: this.selectedPartner.value,
          total_us_dollars: parseFloat(data.bids.total_us_dollars),
          lead_time_days: parseInt(data.bids.lead_time_days)
        } as BidData)
        this.updateBidStatus()
        this.$emit('close', { reloadData: true })
      }
    },
    configureMode(mode: string) {
      if (mode === CUSTOM_MAIL_CONSTS.BID_MODAL_CREATE_MODE) {
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.BID_MODAL_CREATE_POPS_BUTTON
        this.modalTitle = CUSTOM_MAIL_CONSTS.BID_MODAL_CREATE_POPS_TITLE
      } else if (mode === CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_POPS_MODE) {
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_BUTTON
        this.modalTitle = CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_POPS_TITLE
      }
    },
    addNewQuantity() {
      const partnerID = toRaw(this.selectedPartner.value)
      this.bids.push({
        order_quantity: 0,
        product_id: this.productId,
        partner_id: partnerID,
        total_us_dollars: 0.0,
        lead_time_days: 0
      } as BidData)
    },
    saved() {
      this.$emit('saved', false)
    },
    updateBidValues(value: string, field: string, index: number) {
      this.bids[index][field] =
        field === 'total_us_dollars' ? parseFloat(value) : parseInt(value)
    },
    deleteQuantity(index) {
      if (this.bids[index].id) {
        this.deletedQuantities.push(this.bids[index].id)
      }
      this.bids.splice(index, 1)
    }
  }
})
</script>
