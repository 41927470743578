<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { formatDate } from '@/utils/formatters'
import { InventoryItem } from './View.vue'

export default defineComponent({
  name: 'TableRow',
  props: {
    item: {
      type: Object as PropType<InventoryItem>,
      required: true
    },
    resourceType: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      cellClasses: 'border-0 py-3 align-middle',
      formatDate,
      SUB_CLASSES: {
        enterprise: 'bg-mint-500',
        startup: 'bg-tertiary-caramel'
      }
    }
  }
})
</script>

<template title="Invite Partner User">
  <tr
    :class="[
      'table-row',
      'relative',
      'font-light',
      'text-sm',
      'even:bg-white-300'
    ]"
  >
    <td :class="cellClasses">
      <RouterLink
        class="text-black"
        :to="`/partner_ops/accounts/${item.account_id}/${resourceType}`"
      >
        <strong>{{ item.company }}</strong>
      </RouterLink>
    </td>
    <td :class="cellClasses">
      <RouterLink
        class="text-black"
        :to="`/partner_ops/accounts/${item.account_id}/${resourceType}`"
      >
        {{ item.account_id }}
      </RouterLink>
    </td>
    <td :class="cellClasses">
      <span
        :class="`
          rounded-full
          py-1
          px-3
          text-uppercase text-white
          ${SUB_CLASSES[item.subscription] ?? 'bg-gray-300'}
        `"
      >
        {{ item.subscription }}
      </span>
    </td>
    <td :class="cellClasses">
      <RouterLink
        :to="`/partner_ops/accounts/${item.account_id}/${resourceType}?resource_id=${item.id}`"
      >
        {{ item.id }}
      </RouterLink>
    </td>
    <td :class="cellClasses">{{ item.total_num_remaining }}</td>
    <td :class="cellClasses">
      {{ item.total_partners }}
    </td>
    <td :class="cellClasses">{{ formatDate(item.date_created) }}</td>
  </tr>
</template>
