import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 xl:grid-cols-5 gap-4 items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Search = _resolveComponent("Search")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_LobButton = _resolveComponent("LobButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 0,
          id: "partners-dropdown",
          "model-value": _ctx.selectedPartnerObj,
          options: _ctx.partnerOptions,
          label: "Partner",
          placeholder: "Pick a partner",
          "data-testid": "dropdown",
          "container-class": "h-18",
          "onUpdate:modelValue": _ctx.changePartner
        }, null, 8, ["model-value", "options", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TextInput, {
      id: "search-term",
      modelValue: _ctx.text,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
      label: "Search terms"
    }, {
      iconLeft: _withCtx(() => [
        _createVNode(_component_Search, { class: "w-5 h-5 text-gray-700" })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_date_input, {
      id: "startDate",
      modelValue: _ctx.startDate,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.startDate) = $event)),
      open: _ctx.startDateOpen,
      "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.startDateOpen) = $event)),
      label: "Start date"
    }, null, 8, ["modelValue", "open"]),
    _createVNode(_component_date_input, {
      id: "endDate",
      modelValue: _ctx.endDate,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.endDate) = $event)),
      open: _ctx.endDateOpen,
      "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.endDateOpen) = $event)),
      label: "End date",
      min: _ctx.startDate
    }, null, 8, ["modelValue", "open", "min"]),
    _createVNode(_component_LobButton, {
      onClick: _withModifiers(_ctx.search, ["stop"])
    }, {
      default: _withCtx(() => [
        _createTextVNode("search")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}