<template>
  <LoadingIndicator>
    <div v-if="!isLoading" class="p-5">
      <TestingHeader />
      <Card class="min-w-min w-100">
        <div class="flex flex-row text-xl">
          <div class="flex-1" />
          <LobButton size="small" class="text-sm" @click="openNewTestCaseModal">
            New Test Case
          </LobButton>
        </div>
        <LoadingIndicator>
          <Table
            v-if="!tableLoading"
            :headers="tableHeaders"
            :row-object="tableRowObject"
            :data="tableData"
            :row-expandable="true"
            :pagination="true"
            :data-length="tableLength"
            :current-page="page"
            @updatePage="updatePage"
            @updateSortedHeaders="updateSortedHeaders"
            @updateTableData="updateTableData"
            @handleValueChange="handleValueChange"
          >
            <template #expandedContent="expandedProps">
              <div class="p-3">
                <Table
                  :id="`testCasesInnerTable-${expandedProps.index}`"
                  variant="secondary"
                  :headers="innerTableHeaders"
                  :row-object="innerTableRowObjects"
                  :data="tableData[expandedProps.index].innerTableData"
                  secondary-row-color="bg-offWhite"
                  @updateSortedHeaders="updateSortedHeaders"
                  @updateTableData="updateTableData"
                  @handleValueChange="handleValueChange"
                />
              </div>
            </template>
          </Table>
        </LoadingIndicator>
      </Card>
      <TestCaseModal :is-open="openNewTestCase" @close="closeNewTestCase" />
      <TestCaseEditModal
        :is-open="openEditTestCase"
        :edit-test-data="editModalData"
        @close="closeTestEditCase"
      />
    </div>
  </LoadingIndicator>
</template>

<script lang="ts">
import {
  dateFormatter,
  loadMoreTestResourcesByUrl,
  getTestCases,
  PaginationLinks,
  TestCaseList,
  TestCase
} from '@/store/customMail'
import { defineComponent } from '@vue/runtime-core'
import Table from '../../../../components/common/table/Table.vue'
import TestCaseModal from './Modals/TestCaseModal.vue'
import TestCaseEditModal from './Modals/TestCaseEditModal.vue'
import TestingHeader from './TestingHeader.vue'
import { formatDate } from '@/utils/formatters'

export default defineComponent({
  name: 'TestCases',
  components: {
    Table,
    TestingHeader,
    TestCaseModal,
    TestCaseEditModal
  },
  data() {
    return {
      isLoading: false,
      tableLoading: false,
      openNewTestCase: false,
      openEditTestCase: false,
      editModalData: {},
      tableHeaders: [
        {
          name: 'ID',
          id: 'id',
          span: 1,
          sortable: true,
          sortStatus: 'none',
          width: 'w-2/12'
        },
        {
          name: 'Rendered',
          id: 'components_rendered',
          span: 1,
          sortable: false,
          sortStatus: 'none',
          width: 'w-2/12'
        },
        {
          name: 'Name',
          id: 'name',
          span: 1,
          sortable: true,
          sortStatus: 'none',
          width: 'w-1/12'
        },
        {
          name: 'Description',
          id: 'description',
          span: 1,
          sortable: false,
          sortStatus: 'none',
          width: 'w-3/12'
        },
        {
          name: 'Product',
          id: 'product_id',
          span: 1,
          sortable: false,
          sortStatus: 'none',
          width: 'w-1/12'
        },
        {
          name: 'Date Modified',
          id: 'Date Modified',
          span: 1,
          sortable: false,
          sortStatus: 'none',
          width: 'w-2/12'
        },
        { name: '', span: 1, sortStatus: 'none', width: 'w-1/12' }
      ],
      tableRowObject: [
        {
          fieldName: 'id',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'allComponentsRendered',
          type: 'boolean',
          width: 'w-2/12'
        },
        {
          fieldName: 'name',
          type: 'string',
          width: 'w-1/12'
        },
        {
          fieldName: 'description',
          type: 'string',
          width: 'w-3/12'
        },
        {
          fieldName: 'product_id',
          type: 'string',
          width: 'w-1/12'
        },
        {
          fieldName: 'date_modified',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: '',
          type: 'button',
          buttonText: 'Edit',
          variant: 'secondary',
          onClick: this.openTestCaseEditModal,
          width: 'w-1/12'
        }
      ],
      innerTableHeaders: [
        { name: 'Component', width: 'w-1/12' },
        { name: 'Description', width: 'w-3/12' },
        { name: 'Status', width: 'w-1/12' },
        { name: 'Rendering Details', width: 'w-3/12' },
        { name: 'File Name', width: 'w-2/12' },
        { name: 'Thumbnail', width: 'w-2/12' }
      ],
      innerTableRowObjects: [
        {
          fieldName: 'id',
          type: 'string',
          width: 'w-1/12'
        },
        {
          fieldName: 'description',
          type: 'string',
          width: 'w-3/12'
        },
        {
          fieldName: 'status',
          type: 'badge',
          calculateVariant: (fieldStatus) => {
            if (fieldStatus === 'success') {
              return 'success'
            } else if (fieldStatus === 'error') {
              return 'error'
            } else {
              return 'info'
            }
          },
          width: 'w-1/12'
        },
        {
          fieldName: 'rendering_details',
          type: 'string',
          width: 'w-3/12'
        },
        {
          fieldName: 'file_name',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'thumbnails',
          type: 'image',
          width: 'w-2/12'
        }
      ],
      tableData: [] as any[],
      tableLength: 0,
      links: {} as PaginationLinks,
      sortBy: 'date_modified',
      orderBy: 'DESC',
      page: 1,
      getTestCases: null as any,
      rowsCurrentlyOpened: [] as string[],
      currentUrl: undefined as any
    }
  },
  async mounted() {
    await this.loadTestCases(undefined, true)
    this.getTestCases = setInterval(async () => {
      await this.loadTestCases(this.currentUrl, false)
    }, 10000)
  },
  methods: {
    async loadTestCases(url?: string, initialize?: boolean) {
      if (initialize) this.tableLoading = true
      let testCases: TestCaseList
      if (url) {
        const strippedURL = url.substring(url.indexOf('/partner_testing'))
        testCases = await loadMoreTestResourcesByUrl({
          url: strippedURL,
          sort_by: this.sortBy
        })
      } else {
        testCases = await getTestCases({
          sortBy: this.sortBy,
          order: this.orderBy,
          page: this.page
        })
      }
      this.tableLength = testCases.total_count
      this.links = testCases.links
      this.tableData = await Promise.all(
        testCases.data.map(async (testCase: TestCase) => {
          return {
            ...testCase,
            date_created: formatDate(testCase.date_created, dateFormatter),
            date_modified: formatDate(testCase.date_modified, dateFormatter),
            expanded: this.rowsCurrentlyOpened.includes(testCase.id),
            innerTableData: testCase.components.map((comp) => {
              const isOverloaded = comp.overloaded_file_url
              const hasRenderingDetails =
                comp.rendering_details &&
                comp.rendering_details.rendering_status &&
                comp.rendering_details.rendering_status.remediation
              return {
                ...comp,
                status: isOverloaded
                  ? comp.rendering_details.status
                  : 'Original',
                file_name: isOverloaded ? comp.rendering_details.file_name : '',
                rendering_details: hasRenderingDetails
                  ? comp.rendering_details.rendering_status.remediation
                  : '',
                thumbnails: {
                  ...comp.thumbnails,
                  linkTo: comp.overloaded_file_url,
                  preview: true,
                  icon: 'eye-slash'
                },
                isOverloaded
              }
            })
          }
        })
      )
      this.tableLoading = false
    },
    updateSortedHeaders(headers: any[]) {
      const sortedHeader = headers.find(
        (header) => header.sortStatus !== 'none'
      )
      // if there are no sorted headers, sort on id by default
      // may change to date_modified in the future
      if (sortedHeader) {
        this.updateColumnOrder(sortedHeader.id, sortedHeader.sortStatus, 1)
      } else {
        this.updateColumnOrder('id', 'DESC', 1)
      }
      this.tableHeaders = headers
      this.loadTestCases()
    },
    async updateTableData(data) {
      this.tableData = data.rows
      // keep track of opened rows for data refreshing
      if (data.updatedRow.expanded) {
        this.rowsCurrentlyOpened.push(data.updatedRow.id)
      } else {
        const removedIndex = this.rowsCurrentlyOpened.indexOf(
          data.updatedRow.id
        )
        this.rowsCurrentlyOpened.splice(removedIndex, 1)
      }
    },
    async closeNewTestCase(options) {
      this.openNewTestCase = false
      if (options && options.refreshData) this.loadTestCases()
    },
    async closeTestEditCase(options) {
      this.openEditTestCase = false
      this.editModalData = {}
      if (options && options.refreshData) this.loadTestCases()
    },
    async updatePage(newPage) {
      const pageDelta = newPage - this.page
      if (pageDelta === 1) {
        this.currentUrl = this.links.next
      } else if (pageDelta === -1) {
        this.currentUrl = this.links.previous
      } else if (pageDelta > 1) {
        this.currentUrl = this.links.last
      } else if (pageDelta < -1) {
        this.currentUrl = this.links.first
      }
      this.loadTestCases(this.currentUrl, false)
      // clear opened rows when loading new page data
      this.rowsCurrentlyOpened = []
      this.page = newPage
    },
    updateColumnOrder(sortBy: string, orderBy: string, page: number) {
      this.sortBy = sortBy
      this.orderBy = orderBy
      this.page = page
    },
    openNewTestCaseModal() {
      this.openNewTestCase = true
    },
    openTestCaseEditModal(_, rowData) {
      this.editModalData = rowData
      this.openEditTestCase = true
    }
  }
})
</script>

<style lang="scss" scoped></style>
