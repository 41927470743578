import { sendRoutingServiceRequest } from '../requests'
import { defineVuexModule } from '../util'

const initialState = {}

const storeModule = defineVuexModule('routingService', initialState)

export const getQueues = storeModule.dispatch(async () => {
  const response = await sendRoutingServiceRequest({
    method: 'GET',
    path: `/redrive/list`
  })
  return response.data
}, 'getQueues')

export const redriveQueue = storeModule.dispatch(
  async (
    _,
    params: {
      flushType: string
      flushKey: string
      prioritized: boolean
    }
  ) => {
    const response = await sendRoutingServiceRequest({
      method: 'POST',
      path: `/redrive`,
      data: {
        flush_type: params.flushType,
        flush_key: params.flushKey,
        prioritized: params.prioritized
      }
    })
    return response.data
  },
  'redriveQueues'
)

export const getJobs = storeModule.dispatch(async () => {
  const response = await sendRoutingServiceRequest({
    method: 'GET',
    path: `/redrive/status`
  })
  return response.data
}, 'getJobs')
