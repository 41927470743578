import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'

export default [
  {
    path: '/500',
    component: View,
    props: {
      type: '500'
    }
  },
  {
    name: 'Not Found',
    path: '/:pathMatch(.*)*',
    component: View,
    props: {
      type: '404'
    }
  }
] as Array<RouteRecordRaw>
