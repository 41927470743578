<script lang="ts">
import { defineComponent, computed } from '@vue/runtime-core'
import { ref, reactive } from '@vue/reactivity'
import { useUsers, User, UserFilter, PAGE_SIZE } from './useUsers'
import { usePartners } from '@/composables/usePartners'
import { DropdownItem } from '@/consts'

import Filter from './Filter.vue'
import ConfirmPartnerChange from './ConfirmPartnerChange.vue'
import ConfirmRoleChange from './ConfirmRoleChange.vue'
import ConfirmDeleteUser from './ConfirmDeleteUser.vue'
import { sendPapiRequest } from '@/store/requests'
import { captureException } from '@/plugins/sentry'
import { setUserMasquerade } from '@/store/session'
import router from '@/router'

export type UmUser = Omit<User, 'partner' | 'role'> & {
  partner: DropdownItem
  partnerId: string
  role: DropdownItem
}

const roleOptions: DropdownItem[] = [
  { id: 'lob-admin', label: 'Admin' },
  { id: 'partner', label: 'Partner' }
]

type ModalProps = {
  visible: boolean
  user?: UmUser
}

type ChangeModalProps = ModalProps & {
  old?: DropdownItem
  new?: DropdownItem
}

export default defineComponent({
  components: {
    Filter,
    ConfirmPartnerChange,
    ConfirmRoleChange,
    ConfirmDeleteUser
  },
  setup() {
    const filter = reactive<UserFilter>({
      partner: null,
      searchTerm: ''
    })
    const partnerChangeModal = reactive<ChangeModalProps>({
      visible: false
    })
    const roleChangeModal = reactive<ChangeModalProps>({ visible: false })
    const deleteUserModal = reactive<ModalProps>({ visible: false })
    const userOpError = ref<string>('')

    const partnerOptions = usePartners()
    const {
      isReady,
      isLoading,
      errorMessage,
      users: apiUsers,
      totalCount,
      loadUsers,
      reloadUsers
    } = useUsers({ loadMore: true })

    const users = computed<UmUser[]>(() => {
      return apiUsers.value.map((u) => ({
        ...u,
        partner: partnerOptions.value.find((r) => r.id === u.partner) || {
          id: null,
          label: ''
        },
        role: roleOptions.find((r) => r.id === u.role) || {
          id: null,
          label: ''
        },
        partnerId: u.partner
      }))
    })

    const onSearch = (userFilter: UserFilter) => {
      Object.assign(filter, userFilter)
      reloadUsers(userFilter)
    }

    const loadMore = () => loadUsers(filter, PAGE_SIZE)

    const updateUserPartner = async (userId: string, newPartner: string) => {
      try {
        userOpError.value = ''
        await sendPapiRequest({
          method: 'POST',
          path: `/users/${userId}`,
          data: {
            partner: newPartner
          }
        })
      } catch (err) {
        userOpError.value = 'There was an error changing the partner'
        captureException(err as Error)
      } finally {
        partnerChangeModal.visible = false
        reloadUsers(filter)
      }
    }

    const updateUserRole = async (userId: string, newRole: string) => {
      try {
        userOpError.value = ''
        await sendPapiRequest({
          method: 'POST',
          path: `/users/${userId}`,
          data: {
            role: newRole
          }
        })
      } catch (err) {
        userOpError.value = 'There was an error changing the role'
        captureException(err as Error)
      } finally {
        roleChangeModal.visible = false
        reloadUsers(filter)
      }
    }

    const deleteUser = async (userId: string) => {
      try {
        userOpError.value = ''
        await sendPapiRequest({
          method: 'DELETE',
          path: `/users/${userId}`
        })
      } catch (err) {
        userOpError.value = 'There was an error deleting the user'
        captureException(err as Error)
      } finally {
        deleteUserModal.visible = false
        reloadUsers(filter)
      }
    }

    const setMasquerade = (user: User) => {
      setUserMasquerade(user)
      router.push('/docs/checks')
    }

    return {
      partnerOptions,
      roleOptions,
      filter,
      isReady,
      isLoading,
      errorMessage,
      users,
      totalCount,
      loadUsers,
      reloadUsers,
      onSearch,
      loadMore,
      partnerChangeModal,
      roleChangeModal,
      deleteUserModal,
      updateUserPartner,
      updateUserRole,
      deleteUser,
      setMasquerade,
      userOpError
    }
  }
})
</script>

<template>
  <div class="content p-5">
    <ConfirmPartnerChange
      :is-visible="partnerChangeModal.visible"
      :user="partnerChangeModal.user"
      :old="partnerChangeModal.old"
      :new="partnerChangeModal.new"
      @cancel="
        () => {
          partnerChangeModal.visible = false
          reloadUsers(filter)
        }
      "
      @confirm="updateUserPartner"
    />
    <ConfirmRoleChange
      :is-visible="roleChangeModal.visible"
      :user="roleChangeModal.user"
      :old="roleChangeModal.old"
      :new="roleChangeModal.new"
      @cancel="
        () => {
          roleChangeModal.visible = false
          reloadUsers(filter)
        }
      "
      @confirm="updateUserRole"
    />
    <ConfirmDeleteUser
      :is-visible="deleteUserModal.visible"
      :user="deleteUserModal.user"
      @cancel="deleteUserModal.visible = false"
      @confirm="deleteUser"
    />
    <header class="mb-4">
      <h2>User Management</h2>
      <hr class="border my-6" />
      <Filter :partner-options="partnerOptions" @search="onSearch" />
    </header>

    <Alert
      v-if="errorMessage"
      data-testid="error-message"
      class="my-3"
      variant="error"
    >
      {{ errorMessage }}
    </Alert>
    <Alert
      v-if="userOpError"
      data-testid="error-message"
      class="my-3"
      variant="error"
    >
      {{ userOpError }}
    </Alert>

    <LoadingIndicator>
      <div v-if="isReady">
        <p v-if="!filter.searchTerm">
          Search results: {{ users.length }} / {{ totalCount }}
        </p>
        <p v-else>
          Search results for {{ filter.searchTerm }}: {{ users.length }} /
          {{ totalCount }}
        </p>
        <table class="table" data-testid="table">
          <thead>
            <tr class="table-row font-light text-sm bg-white-300">
              <th class="border-t-1 w-1/4">Print Partner</th>
              <th class="border-t-1 w-60">User ID</th>
              <th class="border-t-1 w-64">User Email</th>
              <th class="border-t-1 w-48">Role</th>
              <th class="border-t-1 w-48">Masquerade</th>
              <th class="border-t-1 w-auto">&nbsp;</th>
            </tr>
          </thead>

          <tbody data-testid="table-body">
            <tr
              v-for="user in users"
              :key="user.id"
              :data-testid="`table-row-${user.id}`"
              class="table-row font-light text-sm"
            >
              <td>
                <Dropdown
                  :model-value="user.partner"
                  :options="partnerOptions"
                  label="partner"
                  size="small"
                  class="align-middle w-4/5"
                  placeholder="[choose a print partner]"
                  sr-only-label
                  data-testid="dd-partner"
                  @update:modelValue="
                    Object.assign(partnerChangeModal, {
                      user,
                      old: user.partner,
                      new: $event,
                      visible: true
                    })
                  "
                />
                <p v-if="!user.partner.id">({{ user.partnerId }})</p>
              </td>
              <td class="align-middle">{{ user.id }}</td>
              <td class="align-middle">{{ user.email }}</td>
              <td class="align-middle">
                <Dropdown
                  :model-value="user.role"
                  :options="roleOptions"
                  label="role"
                  sr-only-label
                  size="small"
                  class="w-32"
                  data-testid="dd-role"
                  @update:modelValue="
                    Object.assign(roleChangeModal, {
                      user,
                      old: user.role,
                      new: $event,
                      visible: true
                    })
                  "
                />
              </td>
              <td class="align-middle">
                <LobButton
                  size="small"
                  variant="secondary"
                  @click="setMasquerade(user)"
                >
                  Masquerade
                </LobButton>
              </td>
              <td class="align-middle text-right pr-5">
                <LobButton
                  variant="tertiary"
                  size="small"
                  class="!text-error"
                  @click="
                    Object.assign(deleteUserModal, {
                      user,
                      visible: true
                    })
                  "
                >
                  Delete
                </LobButton>
              </td>
            </tr>
            <tr
              v-if="!isLoading && !users.length"
              :class="[
                'table-row',
                'cursor-pointer',
                'relative',
                'font-light',
                'text-sm'
              ]"
            >
              <td class="align-middle" colspan="6">
                <alert variant="warning">No users available</alert>
              </td>
            </tr>
          </tbody>
        </table>
        <LoadingIndicator>
          <div v-if="!isLoading" class="flex justify-center">
            <LobButton
              v-if="users.length < totalCount"
              variant="link"
              @click="loadMore"
            >
              load more
            </LobButton>
          </div>
        </LoadingIndicator>
      </div>
    </LoadingIndicator>
  </div>
</template>
