import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'
import { ShowChildRoutes } from '@/components/global'
import ProductsListPage from '../productsList'

export default {
  path: ':partnerId/facilities_list',
  component: ShowChildRoutes,
  props: true,
  children: [
    {
      path: '',
      component: View
    },
    ProductsListPage
  ],
  meta: {
    displayName: 'Partner Facilities'
  }
} as RouteRecordRaw
