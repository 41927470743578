<template title="Invite New Partner User">
  <CenteredBox>
    <div v-if="!partnerOptions">
      <H2>Invite New Partner User</H2>
      <H2>Loading...</H2>
    </div>
    <div v-else>
      <H2>Invite New Partner User</H2>
      <br />
      <form @submit.prevent="submit">
        <Dropdown
          id="partner"
          v-model="partner"
          :options="partnerOptions"
          label="Partner"
          placeholder="Select A Partner"
          class="combobox"
          required
          :disabled="pending"
        />
        <TextInput
          id="email"
          v-model="email"
          label="Recipient Email"
          type="email"
          placeholder="john@doe.com"
          required
          :disabled="pending"
        />
        <Alert v-if="successMessage" variant="success">
          {{ successMessage }}
        </Alert>
        <Alert v-if="errorMessage" variant="error">
          {{ errorMessage }}
        </Alert>
        <div>
          <br />
          Invite expired? Click
          <router-link to="/partner_ops/resend_invite">here</router-link>
          to resend an invitation.
        </div>
        <br />
        <LobButton :disabled="pending">
          {{ pending ? 'Sending...' : 'Send Invite' }}
        </LobButton>
      </form>
    </div>
  </CenteredBox>
</template>

<script lang="ts">
import { createPartnerUser, getAllPartners } from '@/store/partners'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InvitePartnerUser',
  data() {
    return {
      email: null as string | null,
      partner: { value: '' },
      pending: false,
      errorMessage: null as string | null,
      successMessage: null as string | null
    }
  },
  computed: {
    // This is to convert $allPartners to a form that Dropdown can use.
    partnerOptions(): any {
      const partners = getAllPartners()
      if (partners === null) return null
      const mappedPartners = partners.map((partner) => ({
        label: `${partner.display_name} (${partner.id})`,
        value: partner.id
      }))
      mappedPartners.sort((a, b) => {
        const al = a.label.toUpperCase()
        const bl = b.label.toUpperCase()
        if (al < bl) {
          return -1
        } else if (al > bl) {
          return 1
        } else {
          return 0
        }
      })
      return mappedPartners
    }
  },
  methods: {
    async submit() {
      if (this.partner === null) {
        this.errorMessage = 'Please select a partner'
        return
      }
      if (this.email === null) {
        this.errorMessage = 'Please input an email'
        return
      }
      this.pending = true
      this.errorMessage = null
      this.successMessage = null
      try {
        await createPartnerUser({
          email: this.email,
          partner: this.partner.value
        })
        this.successMessage = 'Success!'
      } catch (e) {
        const eResp = e.response
        if (
          eResp?.data?.error?.code === 'this_email_has_already_been_registered'
        ) {
          // For whatever reason this is set to return a 500.
          this.errorMessage = 'This email has already been registered.'
        } else {
          this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
        }
      }
      this.pending = false
    }
  }
})
</script>

<style lang="scss" scoped></style>
