<template>
  <div>
    <div
      v-for="partner in partners"
      :key="partner.id"
      class="mb-1 shadow-md shadow-black"
    >
      <PartnersTableRow
        :partner="partner"
        :partner-facilities="partnerFacilities[partner.id] || []"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import PartnersTableRow from './PartnersTableRow.vue'
import { Partner } from '../../../../store/partners'

export default defineComponent({
  name: 'PartnersTable',
  components: {
    PartnersTableRow
  },
  props: {
    partners: {
      type: Array as PropType<Partner[]>,
      required: true
    },
    partnerFacilities: {
      type: Object,
      required: true
    }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
