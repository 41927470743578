<script lang="ts">
/* eslint-disable camelcase */
import { sendPapiRequest } from '@/store/requests'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import SearchForm from './SearchForm.vue'
import TableRow from './TableRow.vue'
import PaginationNext from '@/components/global/PaginationNext.vue'
import router from '@/router'

export type InventoryItem = {
  company: string
  account_id: string
  subscription: string
  id: string
  total_num_remaining: number
  total_partners: number
  date_created: string
}

export type SearchQueryType = {
  by: string
  query: string
}

const filterByOpts = [
  { value: 'envelopes', label: 'Envelope' },
  { value: 'cards', label: 'Card' },
  { value: 'buckslips', label: 'Buckslip' }
]

const columns = [
  'Company',
  'Account ID',
  'Subscription',
  'ID',
  'Remaining Inventory',
  'Vendors Distributed',
  'Date Created'
]

export default defineComponent({
  name: 'Inventory',
  components: { SearchForm, TableRow, PaginationNext },
  setup() {
    const isLoading = ref(false)
    const items = ref<InventoryItem[]>([])
    const errorMessage = ref('')
    const pagination = reactive({ active: '', next: '', previous: '' })
    const filterBy = ref(filterByOpts[0])
    const currentSearch = reactive<SearchQueryType>({
      by: '',
      query: ''
    })

    const fetchData = async () => {
      try {
        isLoading.value = true
        errorMessage.value = ''

        const query = Object.entries({
          type: filterBy.value.value,
          after: pagination.active,
          [currentSearch.by]: currentSearch.query
        }).reduce((acc, [key, value]) => {
          if (value?.length > 0) acc[key] = value
          return acc
        }, {})

        const {
          data: { data, before: pageBefore, after: pageAfter }
        } = await sendPapiRequest({
          path: '/inventory',
          method: 'GET',
          query
        })
        pagination.next = pageAfter
        pagination.previous = pageBefore
        items.value = data
      } catch (e) {
        errorMessage.value = e?.response?.data?.error?.message
      } finally {
        isLoading.value = false
      }
    }

    onMounted(fetchData)

    const onPageChange = (paginationKey: string) => {
      pagination.active = paginationKey
      fetchData()
    }

    const onSearch = (searchQuery: SearchQueryType) => {
      currentSearch.by = searchQuery.by
      currentSearch.query = searchQuery.query
      // Return to first page and refresh data
      onPageChange('')
    }

    const onUpdateFilter = (evt) => {
      filterBy.value = evt
    }

    const onFilterChange = (e) => {
      filterBy.value = e
      // Return to first page and refresh data
      onPageChange('')
    }

    const moveToSpoilagePage = () => {
      router.push('/partner_ops/accounts/inventory/global/spoilage')
    }

    return {
      currentSearch,
      columns,
      errorMessage,
      filterBy,
      filterByOpts,
      isLoading,
      items,
      onPageChange,
      onFilterChange,
      onSearch,
      pagination,
      onUpdateFilter,
      moveToSpoilagePage
    }
  }
})
</script>

<template title="Invite Partner User">
  <div class="content p-5">
    <header class="mb-4">
      <div class="flex">
        <h2 class="mb-5">Inventory</h2>
        <div class="flex-1" />
        <LobButton
          size="small"
          variant="secondary"
          class="w-48 h-12"
          @click="moveToSpoilagePage"
        >
          Manage Spoilage
        </LobButton>
      </div>
      <SearchForm
        :is-loading="isLoading"
        @search="onSearch"
        @update:filter="onUpdateFilter"
      />
    </header>

    <div data-testid="filterByWrapper" class="w-64 mb-4">
      <Dropdown
        id="filterBy"
        data-testid="filterBy"
        :model-value="filterBy"
        label="Filter By"
        :options="filterByOpts"
        :required="false"
        @update:modelValue="onFilterChange"
      />
    </div>

    <Alert v-if="errorMessage?.length" variant="error">
      {{ errorMessage }}
    </Alert>
    <div
      v-if="currentSearch.query.length > 0 && !isLoading"
      class="mb-2 text-lg font-normal"
    >
      <span>Search results&nbsp;</span>
      <span class="text-gray-300">for '{{ currentSearch.query }}'</span>
    </div>

    <LoadingIndicator>
      <table v-if="!isLoading" class="table">
        <thead>
          <tr>
            <th
              v-for="key in columns"
              :key="key"
              class="!border-t-0 text-gray-900 font-normal text-base"
            >
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRow
            v-for="item in items"
            :key="item.id"
            :item="item"
            :resource-type="filterBy.value"
          />
          <tr v-if="items?.length === 0">
            <td :colSpan="columns.length" class="text-center text-lg">
              No data found
            </td>
          </tr>
        </tbody>
      </table>
    </LoadingIndicator>

    <PaginationNext
      :previous-key="pagination.previous"
      :next-key="pagination.next"
      @change="onPageChange"
    />
  </div>
</template>
