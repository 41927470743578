<script lang="ts">
import { defineComponent } from 'vue'
import { RESOURCES } from '@/consts'

export default defineComponent({
  name: 'DeleteCapacityModal',
  props: {
    partner: { type: String, required: true },
    partnerDisplayName: { type: String, required: true },
    resource: { type: String, required: true },
    totalCapacityID: { type: String, required: true },
    isModalVisible: { type: Boolean, default: false }
  },
  emits: ['close', 'deletePartnerCapacity'],
  setup(props, { emit }) {
    const deleteCapacity = () => {
      emit('deletePartnerCapacity', {
        partner: props.partner,
        totalCapacityID: props.totalCapacityID,
        resource: props.resource
      })
    }
    const closeModal = () => {
      emit('close')
    }

    return {
      RESOURCES,
      closeModal,
      deleteCapacity
    }
  }
})
</script>

<template>
  <Modal
    :visible="isModalVisible"
    header="Delete Test Set"
    close-button-aria-label="Close Modal"
    @close="closeModal"
  >
    Are you sure you would like to delete {{ partnerDisplayName }}'s
    {{ resource }} capacities?
    <template #footer>
      <div class="flex self-end">
        <LobButton variant="secondary" @click="closeModal">Go back</LobButton>
        <LobButton
          variant="error"
          class="ml-2"
          @click="deleteCapacity(testSetToDelete)"
        >
          Delete
        </LobButton>
      </div>
    </template>
  </Modal>
</template>
