<template>
  <div class="flex flex-column">
    <div
      class="bg-mist shadow-md shadow-black rounded w-100 grid grid-cols-6 items-baseline justify-center p-2"
    >
      <div class="pl-2 col-span-5 content-baseline">
        {{ product.name }}
      </div>
      <div class="flex justify-end pr-3">
        <button @click="toggleDetails()">
          <fa
            class="text-slate-300"
            :icon="isDetailOpen ? 'chevron-up' : 'chevron-down'"
          />
        </button>
      </div>
    </div>
    <div
      v-if="isDetailOpen"
      class="flex flex-column w-100 items-baseline justify-items-center text-sm p-2 rounded shadow-sm shadow-black"
    >
      <div class="flex flex-row text-xl p-2 pt-4 pb-4">
        Abilities
        <Tooltip position="right">
          <template #trigger>
            <Info class="ml-1 w-5 h-5" />
          </template>
          <template #content>
            <div class="w-max">
              Previously called expressions within capability rules, these are
              characteristics related to a mail type.
            </div>
          </template>
        </Tooltip>
        <div class="flex-1" />
      </div>
      <div
        class="bg-mist shadow-md shadow-black rounded w-100 grid grid-cols-6 items-baseline justify-center p-2 mt-2 mb-2"
      >
        <div class="pl-2 col-span-5 content-baseline font-bold">Available</div>
        <div class="flex justify-end pr-3">
          <button @click="toggleAvailableAbilities()">
            <fa
              class="text-slate-300"
              :icon="isAvailableAbilitiesOpen ? 'chevron-up' : 'chevron-down'"
            />
          </button>
        </div>
      </div>
      <div v-if="isAvailableAbilitiesOpen" class="w-100 p-3">
        <div class="grid grid-cols-6 w-100 p-2">
          <div class="items-baseline font-bold">Name</div>
          <div class="items-baseline font-bold">Order Field Name</div>
          <div class="items-baseline font-bold">Operator</div>
          <div class="items-baseline font-bold">Order Field Value</div>
          <div class="items-baseline font-bold">Active</div>
        </div>
        <div
          v-for="availableAbility in availableAbilities"
          :key="availableAbility.id"
          class="flex flex-column w-100 pt-1 pb-1"
        >
          <ProductsInnerTableRowAvailable
            class="flex items-baseline flex-row"
            :partner-id="partnerId"
            :ability="availableAbility"
            :refresh-data="refreshData"
          />
        </div>
      </div>
      <div
        class="bg-mist shadow-md shadow-black rounded w-100 grid grid-cols-6 items-baseline justify-center p-2 mt-2 mb-2"
      >
        <div class="pl-2 col-span-5 content-baseline font-bold">
          Unavailable
        </div>
        <div class="flex justify-end pr-3">
          <button @click="toggleNotAvailableAbilities()">
            <fa
              class="text-slate-300"
              :icon="isUnavailableAbilitiesOpen ? 'chevron-up' : 'chevron-down'"
            />
          </button>
        </div>
      </div>
      <div v-if="isUnavailableAbilitiesOpen" class="w-100 p-3">
        <div class="grid grid-cols-6 w-100 p-2">
          <div class="items-baseline font-bold">Name</div>
          <div class="items-baseline font-bold">Order Field Name</div>
          <div class="items-baseline font-bold">Operator</div>
          <div class="items-baseline font-bold">Order Field Value</div>
        </div>
        <div
          v-for="unavailableAbility in unavailableAbilities"
          :key="unavailableAbility.id"
          class="flex flex-column w-100 pt-1 pb-1"
        >
          <ProductsInnerTableRowUnavailable
            class="flex items-baseline flex-row"
            :partner-id="partnerId"
            :partner-facility-id="partnerFacilityId"
            :ability="unavailableAbility"
            :refresh-data="refreshData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Ability, PartnerFacilityAbility } from '@/store/partners'
import ProductsInnerTableRowAvailable from './ProductsInnerTableRowAvailable.vue'
import ProductsInnerTableRowUnavailable from './ProductsInnerTableRowUnavailable.vue'

export default defineComponent({
  name: 'ProductsTableRow',
  components: {
    ProductsInnerTableRowAvailable,
    ProductsInnerTableRowUnavailable
  },
  props: {
    partnerId: {
      type: String,
      required: true
    },
    partnerFacilityId: {
      type: String,
      required: true
    },
    product: {
      type: Object as PropType<PartnerFacilityAbility>,
      required: true
    },
    availableAbilities: {
      type: Array as PropType<PartnerFacilityAbility[]>,
      required: true
    },
    unavailableAbilities: {
      type: Array as PropType<Ability[]>,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isDetailOpen: true,
      isAvailableAbilitiesOpen: true,
      isUnavailableAbilitiesOpen: false
    }
  },
  methods: {
    toggleDetails() {
      this.isDetailOpen = !this.isDetailOpen
    },
    toggleAvailableAbilities() {
      this.isAvailableAbilitiesOpen = !this.isAvailableAbilitiesOpen
    },
    toggleNotAvailableAbilities() {
      this.isUnavailableAbilitiesOpen = !this.isUnavailableAbilitiesOpen
    }
  }
})
</script>
