import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex self-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LobButton = _resolveComponent("LobButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.isModalVisible,
    header: "Delete Test Set",
    "close-button-aria-label": "Close Modal",
    onClose: _ctx.closeModal
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_LobButton, {
          variant: "secondary",
          onClick: _ctx.closeModal
        }, {
          default: _withCtx(() => [
            _createTextVNode("Go back")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_LobButton, {
          variant: "error",
          class: "ml-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteCapacity(_ctx.testSetToDelete)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Delete ")
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createTextVNode(" Are you sure you would like to delete " + _toDisplayString(_ctx.partnerDisplayName) + "'s " + _toDisplayString(_ctx.resource) + " capacities? ", 1)
    ]),
    _: 1
  }, 8, ["visible", "onClose"]))
}