<template>
  <div class="content p-5">
    <h2>Print Capabilities</h2>
    <p class="subtitle mt-3">
      Select the form factors you are able to produce and enter daily capacity
      you can take.
    </p>
    <div class="row mt-5 contentSection">
      <div class="col-12 quote">
        "The way to get started is to quit talking and begin doing." -Walt
        Disney
      </div>
    </div>
    <Alert v-if="alertMessage" :variant="alertType" class="mt-2">
      {{ alertMessage }}
    </Alert>
    <template v-if="types.length === 0">
      <H2>Loading...</H2>
    </template>
    <div v-else class="row">
      <div class="col-12 pt-3">
        <template v-for="type in types" :key="type.title">
          <p class="mb-3 typeContainer" data-testid="title">
            <span class="title">
              {{ type.displayTitle }}
            </span>
            <TextInput
              :id="capacitiesId(type.title)"
              v-model="capacities[type.title]"
              class="capacitiesInput"
              label=""
              type="number"
              :placeholder="type.title"
              :disabled="type.capacityPending"
            />
            <LobButton
              class="ml-3"
              :disabled="type.capacityPending"
              @click.prevent="setCapacity(type)"
            >
              {{ type.capacityPending ? 'Saving...' : 'Save Capacity' }}
            </LobButton>

            <a
              class="expandChevron"
              data-toggle="collapse"
              :href="idify(type.id)"
              role="button"
              aria-expanded="false"
              :aria-controls="type.id"
            >
              <fa icon="chevron-down" />
            </a>
          </p>
          <ul :id="type.id" class="collapse">
            <li v-for="item in type.items" :key="item">
              <input
                :id="checkboxId(type.title, item.permutation)"
                type="checkbox"
                :name="checkboxId(type.title, item.permutation)"
                :value="checkboxId(type.title, item.permutation)"
                :checked="item.id !== ''"
                :disabled="item.pending"
                @change.prevent="permutationToggled($event, item)"
              />
              <label
                class="ml-2"
                :for="checkboxId(type.title, item.permutation)"
              >
                {{ item.displayName }}
              </label>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import * as proofing from '@/store/digitalProofing'
import * as partners from '@/store/partners'
import { permutationsToTypes } from '@/components/Onboarding/utils'

interface Type {
  id: string
  title: string
  items: Array<any>
}

export default defineComponent({
  name: 'PrintCapabilities',
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      alertMessage: null as string | null,
      alertType: 'error',
      loading: true,
      capacities: {
        postcard: null as number | null,
        self_mailer: null as number | null,
        check: null as number | null,
        letter: null as number | null
      }
    }
  },
  computed: {
    types(): Array<Type> {
      const permutations = proofing.getCurrentPartnerPermutations()
      const types = permutationsToTypes(permutations)
      return types
    }
  },
  // watchers. so watch for permutations to be refreshed, then set the types
  async mounted() {
    const partnerId = partners.getCurrentPartnerId()
    if (partnerId === null) return
    const capacities = await partners.getPartnerCapacity({
      partner: partnerId
    })
    this.capacities.postcard = capacities.postcards?.postcards_per_day || null
    this.capacities.check = capacities.checks?.sheets_per_day || null
    this.capacities.letter = capacities.letters?.sheets_per_day || null
    this.capacities.self_mailer =
      capacities.self_mailers?.self_mailers_per_day || null
  },
  methods: {
    idify(value) {
      return `#${value}`
    },
    checkboxId(type, permutation) {
      return `${type}${permutation}`.replace(/ /g, '')
    },
    capacitiesId(type) {
      return `${type}-capacities`
    },
    permutationToggled(evt, permutation) {
      permutation.pending = true
      proofing.setPermutationStatus({
        permutation,
        isActive: evt.target.checked
      })
    },
    async setCapacity(type) {
      const partnerId = partners.getCurrentPartnerId()
      if (partnerId === null) return
      type.capacityPending = true

      try {
        await partners.setPartnerCapacity({
          partner: partnerId,
          data: {
            type: `${type.title}s`,
            quantity: this.capacities[type.title]
          }
        })
        this.alertMessage = 'Save Successful'
        this.alertType = 'success'
        type.capacityPending = false
      } catch (e) {
        // Format an error message if unsuccessful.
        const eResp = e.response
        this.alertMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
        type.capacityPending = false
      }
    }
  }
})
</script>

<style scoped lang="scss">
.quote {
  padding: 20px;
  text-align: center;
  background: #dbf0f9;
  border-radius: 10px;
}
thead {
  color: $shadow-50;
}
.typeContainer {
  border-top: 1px solid #c5d6e6;
  color: $gray;
  display: grid;
  align-items: center;
  grid-template-columns: 100px 200px 170px auto;
  height: 100px;
}
.title {
  font-size: 20px;
  font-weight: 200;
}
.expandChevron {
  display: flex;
  justify-content: right;
  margin-left: 20px;
}
.capacitiesInput {
  margin-top: -8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
