import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Search = _resolveComponent("Search")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_LobButton = _resolveComponent("LobButton")!

  return (_openBlock(), _createElementBlock("form", {
    "data-testid": "searchForm",
    class: "p-4 rounded-lg shadow-sm border border-light flex align-items-center gap-8",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_Dropdown, {
      id: "searchBy",
      modelValue: _ctx.searchQuery.by,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery.by) = $event)),
      label: "",
      "data-testid": "searchByDropdown",
      class: "w-64 m-0",
      options: _ctx.searchByOpts,
      required: false
    }, null, 8, ["modelValue", "options"]),
    _createVNode(_component_TextInput, {
      id: "searchInput",
      "model-value": _ctx.searchQuery.query,
      label: "",
      "data-testid": "searchInput",
      placeholder: _ctx.placeholder,
      class: "flex-1",
      name: "search",
      "onUpdate:modelValue": _ctx.setSelect
    }, {
      iconLeft: _withCtx(() => [
        _createVNode(_component_Search, { class: "w-6 h-6 mr-2" })
      ]),
      _: 1
    }, 8, ["model-value", "placeholder", "onUpdate:modelValue"]),
    _createVNode(_component_LobButton, {
      disabled: _ctx.isLoading,
      "data-testid": "searchButton",
      class: "w-64",
      onClick: _cache[1] || (_cache[1] = 
        () =>
          _ctx.$emit('search', {
            query: _ctx.searchQuery.query,
            by: _ctx.searchQuery.by.value
          })
      )
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Search ")
      ]),
      _: 1
    }, 8, ["disabled"])
  ], 32))
}