import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bg-mist py-4 px-3 table-row mt-20 shadow-md w-100 pb-10" }
const _hoisted_2 = { class: "p-3" }
const _hoisted_3 = {
  key: 0,
  class: "p-3"
}
const _hoisted_4 = {
  key: 1,
  class: "p-3"
}
const _hoisted_5 = { class: "p-3" }
const _hoisted_6 = {
  class: "p-3",
  colspan: "2"
}
const _hoisted_7 = { class: "p-3 capitalize flex items-center" }
const _hoisted_8 = { class: "p-3" }
const _hoisted_9 = { class: "p-3" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = {
  key: 2,
  class: "p-3"
}
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = {
  key: 3,
  class: "p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_fa = _resolveComponent("fa")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Copy = _resolveComponent("Copy")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.item.id), 1),
    (_ctx.isAdminOrPopsUser)
      ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(_ctx.item.account_id), 1))
      : _createCommentVNode("", true),
    (_ctx.isAdminOrPopsUser)
      ? (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(_ctx.item.customerName ?? 'N/A'), 1))
      : _createCommentVNode("", true),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.item.title), 1),
    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.item.description), 1),
    _createElementVNode("td", _hoisted_7, [
      (_ctx.isStatusEnabled(_ctx.item))
        ? (_openBlock(), _createBlock(_component_ToggleButton, {
            key: 0,
            class: "mt-2",
            label: "",
            disabled: _ctx.hasNoBids(_ctx.item),
            name: _ctx.item.id,
            "model-value": _ctx.formateToggleValue(_ctx.item),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePackageStatus(_ctx.item)))
          }, null, 8, ["disabled", "name", "model-value"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Badge, {
        variant: _ctx.configureStatus(_ctx.item.status)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.formatStatus(_ctx.item.status)), 1)
        ]),
        _: 1
      }, 8, ["variant"])
    ]),
    _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.formatDate(_ctx.item.date_modified)), 1),
    _createElementVNode("td", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_Tooltip, { position: "top" }, {
          trigger: _withCtx(() => [
            _createVNode(_component_fa, {
              class: _normalizeClass(["tertiary-bluebird cursor-pointer ml-1 w-5 h-5", { 'cursor-not-allowed': _ctx.printManifestDownloadError }]),
              icon: "fa-file-lines",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadManifest()))
            }, null, 8, ["class"])
          ]),
          content: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.printManifestTooltipText), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Tooltip, { position: "top" }, {
          trigger: _withCtx(() => [
            _createVNode(_component_fa, {
              class: "tertiary-bluebird cursor-not-allowed ml-1 w-5 h-5",
              icon: "fa-image"
            })
          ]),
          content: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.creativeDownloadTooltipText), 1)
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.isAdminOrPopsUser)
      ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Edit, {
              class: "mr-2 opacity-40 w-6 h-6 cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openPackagePage('edit', _ctx.item)))
            }),
            _createVNode(_component_Copy, {
              class: "opacity-40 w-6 h-6 cursor-pointer",
              icon: "clone",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openCloneModal(_ctx.item)))
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isAdminOrPopsUser)
      ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
          _createVNode(_component_fa, {
            class: "opacity-40",
            icon: "eye",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openPackagePage('view', _ctx.item)))
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}