import { set } from 'date-fns'
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'

// This function exists only to allow more easy mocking
// of time. Directly mocking Date caused rendering issues
// in Vue. There may be a better way of doing this, but
// this works for now. - kevin.fitzpatrick@lob.com
const DateObj = function date(...args: Parameters<DateConstructor>): Date {
  return new Date(...args)
}

export { DateObj }

const LOS_ANGELES_TIMEZONE = 'America/Los_Angeles'

export function setTimeInTimezone(
  date: Date,
  hours: number,
  minutes: number,
  seconds: number,
  ms: number,
  timezone: string
): Date {
  return zonedTimeToUtc(
    set(date, {
      hours,
      minutes,
      seconds,
      milliseconds: ms
    }),
    timezone
  )
}

export function format(
  date: Date,
  formatStr = 'yyyy-MM-dd HH:mm:ssxxx'
): string {
  return formatInTimeZone(date, LOS_ANGELES_TIMEZONE, formatStr)
}
