import config from '../../../config'
import { LDClient, initialize } from 'launchdarkly-js-client-sdk'
import { getUser, User } from '../session'
import { defineVuexModule } from '../util'
import { registerWatcher } from '../watcherRegistry'

type UserFlags =
  | 'is-privileged-user'
  | 'partners_portal_abilities_ui'
  | 'gitbook_docs'
type PartnerFlags = 'show-digital-proofing' | 'show-beta-features'
type Flag = UserFlags & PartnerFlags

export type Flags = Record<UserFlags & PartnerFlags, boolean>

const defaultFlags: Flags = {
  'edit-inventory': false,
  'is-privileged-user': false,
  'show-digital-proofing': false,
  'show-beta-features': false,
  gitbook_docs: false,
  partners_portal_abilities_ui: false
}

interface State {
  client: LDClient
  flags: Flags
}

const initialState: State = {
  client: initialize(config.LD_CLIENT_ID, { key: 'guest' }),
  flags: Object.assign({}, defaultFlags)
}

const storeModule = defineVuexModule('launchDarkly', initialState)

// GETTERS (exported functions that provide pieces of data from the state)

export const getLaunchDarklyFlags = storeModule.read((state) => {
  return state.flags
}, 'getLaunchDarklyFlags')

export const getEditInventoryFlag = storeModule.read<boolean>(
  (state) => state.flags['edit-inventory'],
  'getEditInventoryFlag' // Controls edit buttons in inventory page
)

export const getShowBetaFeatureFlag = storeModule.read<boolean>(
  (state) => state.flags['show-beta-features'],
  'getShowBetaFeatureFlag' // Controls the name used in the dev tools
)

export const getIsPrivilegedUserFlag = storeModule.read<boolean>(
  (state) => state.flags['is-privileged-user'],
  'getIsPrivilegedUserFlag' // Controls the name used in the dev tools
)

export const getShowAbilitiesUIFlag = storeModule.read<boolean>(
  // eslint-disable-next-line dot-notation
  (state) => state.flags['partners_portal_abilities_ui'],
  'getShowAbilitiesUIFlag'
)

export const getShowGitbookFlag = storeModule.read<boolean>(
  // eslint-disable-next-line dot-notation
  (state) => state.flags['gitbook_docs'],
  'getShowGitbookFlag'
)

// MUTATORS (synchronous functions that change the state, each call to a mutator
// is logged in the Vue development tools)

const setFlag = storeModule.commit(
  (
    state,
    params: {
      flagName: Flag
      value: boolean
    }
  ) => {
    state.flags[params.flagName as string] = params.value
  },
  'setFlag'
)

export const changeUser = storeModule.commit((state, newUser: User) => {
  state.client.identify({ key: `user.${newUser.id}` }).then((user) => {
    let userData
    if (user['is-privileged-user']) {
      userData = {
        key: `user.${newUser.id}`
      }
    } else {
      userData = {
        key: `partner.${newUser.partner}`
      }
    }
    state.client = initialize(config.LD_CLIENT_ID, userData)
    updateAllFlags()
  })
}, 'changeUser')

// ACTIONS (asynchronous functions which can call other actions as well as
// getters and setters, also logged in the Vue development tools)
const updateAllFlags = storeModule.dispatch(async (ctx) => {
  await ctx.state.client.waitForInitialization()
  for (const pair of Object.entries(defaultFlags)) {
    const [key, defaultValue] = pair
    const value = ctx.state.client.variation(key, defaultValue)
    setFlag({ flagName: key as keyof Flags, value })
  }
}, 'updateAllFlags')

// WATCHERS (functions that are called when a value in the store changes,
// possibly one from a different module.)

registerWatcher(
  () => getUser(),
  (user) => {
    if (user !== null) {
      changeUser(user)
    }
  }
)
