<template>
  <router-view />
</template>

<script lang="ts">
import router from '@/router'
import { getCurrentPartnerId, setCurrentPartnerId } from '@/store/partners'
import { getUser, isUserMasquerading } from '@/store/session'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Partner',
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  computed: {
    redirect() {
      if (this.partnerId === 'current') {
        const id = getCurrentPartnerId()
        if (id !== null) {
          return { from: 'current', to: id }
        }
      } else if (this.partnerId === 'me') {
        const id = getUser()?.partner
        if (id !== undefined) {
          return { from: 'me', to: id }
        }
      } else if (isUserMasquerading()) {
        const id = getCurrentPartnerId()
        if (id !== null) {
          return { from: this.partnerId, to: id }
        }
      }
      return null
    }
  },
  watch: {
    redirect: {
      immediate: true,
      handler(fromto) {
        if (fromto !== null) {
          this.replaceIdInRoute(fromto.from, fromto.to)
        }
      }
    },
    partnerId: {
      immediate: true,
      handler(partnerId) {
        if (
          partnerId !== 'me' &&
          partnerId !== 'current' &&
          !isUserMasquerading()
        ) {
          setCurrentPartnerId(partnerId)
        }
      }
    }
  },
  methods: {
    replaceIdInRoute(oldId: string, newId: string) {
      const actualRoute = router.currentRoute.value
      actualRoute.path = actualRoute.path.replace(`/${oldId}/`, `/${newId}/`)
      router.replace(actualRoute)
    }
  }
})
</script>

<style lang="scss" scoped></style>
