<template>
  <div class="p-8 mt-4">
    <h4>SLA Miss/Quality Reasons</h4>
    <br />
    <hr color="#EBF0F6" />
    <br />
    <div
      class="bg-white-300 rounded-tl-sm rounded-tr-sm pl-4 text-black h-12 text-l flex items-center"
    >
      Report Submitted
      <div class="ml-auto flex h-6 align-middle">
        <Checkmark class="mr-2 text-success" />
      </div>
    </div>
    <Alert
      v-if="$route.params.status === 'success'"
      variant="success"
      class="flex align-middle mt-6"
    >
      {{ successMessage }}
    </Alert>
    <div class="flex place-content-end pt-4">
      <LobButton @click="redirectToSLAForm()">Create New Report</LobButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SLAStatus',
  props: {
    isSuccess: {
      type: [Boolean],
      default: false
    },
    successMessage: {
      type: [String],
      default: 'Thank you. Your form submission was successful!'
    }
  },
  methods: {
    redirectToSLAForm() {
      this.$router.push('/partner_ops/sla-miss-quality')
    }
  }
})
</script>

<style lang="scss" scoped></style>
