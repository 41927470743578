<script lang="ts">
/* eslint-disable camelcase */
import { defineComponent, onMounted } from '@vue/runtime-core'
import { ref } from '@vue/reactivity'
import { formatNumber } from '@/utils/formatters'
import { useTests, Test, SelectedPartner, PAGE_SIZE } from './useTests'
import { usePartners } from '@/composables/usePartners'
import { useDownloadCSV } from '../submit-report/useDownloadCSV'
import TestCasesTable from './TestCasesTable.vue'
import InfoRow from './InfoRow.vue'
import ActionButton from './ActionButton.vue'
import ResultsModal from './ResultsModal.vue'

export default defineComponent({
  components: { ActionButton, TestCasesTable, InfoRow, ResultsModal },
  setup() {
    const expandedRow = ref<Test | null>(null)
    const selectedReportModal = ref<string | null>(null)
    const isReportModalOpened = ref<boolean>(false)
    const selectedPartner = ref<SelectedPartner>({ id: null, label: null })
    const currentPage = ref<number>(1)
    const {
      loadTests,
      tests,
      testsTotalCount,
      descriptionMap,
      errorMessage,
      isLoading,
      isAdmin
    } = useTests(selectedPartner)
    const partners = usePartners()
    const { downloadCSV } = useDownloadCSV()

    const handleExpand = (row: Test) => {
      expandedRow.value = expandedRow.value !== row ? row : null
    }

    const getVariant = (state: string): string =>
      ({
        failed: 'error',
        ready: 'success'
      }[state] ?? 'info')

    const changePage = (val) => {
      currentPage.value = val.page
      loadTests(selectedPartner.value?.id, (currentPage.value - 1) * PAGE_SIZE)
    }

    const changePartner = (partner) => {
      currentPage.value = 1
      selectedPartner.value = partner
      loadTests(selectedPartner.value?.id, (currentPage.value - 1) * PAGE_SIZE)
    }

    onMounted(() => {
      loadTests(selectedPartner.value?.id, 0)
    })
    const getPartnerName = (id: string): string =>
      partners.value.find((p) => p.id === id)?.label || id

    const isCSVButtonVisible = (row: Test) =>
      isAdmin.value && row.status === 'completed'

    const onShowReportModal = (testId: string) => {
      selectedReportModal.value = testId
      isReportModalOpened.value = true
    }

    const onCloseModal = () => {
      selectedReportModal.value = null
      isReportModalOpened.value = false
    }

    return {
      changePage,
      changePartner,
      currentPage,
      descriptionMap,
      errorMessage,
      expandedRow,
      formatNumber,
      getPartnerName,
      getVariant,
      handleExpand,
      handleDownloadCSV: downloadCSV,
      isCSVButtonVisible,
      isAdmin,
      isLoading,
      PAGE_SIZE,
      partners,
      selectedPartner,
      tests,
      testsTotalCount,
      selectedReportModal,
      isReportModalOpened,
      onShowReportModal,
      onCloseModal
    }
  }
})
</script>

<template>
  <div class="content p-5">
    <header class="mb-4">
      <h2>End to End Testing</h2>
      <P>Schedule and create end to end tests</P>
      <div
        :class="[
          'flex items-center',
          isAdmin ? 'justify-between' : ' justify-end'
        ]"
      >
        <Dropdown
          v-if="isAdmin"
          id="partners-dropdown"
          :model-value="selectedPartner"
          :options="partners"
          label="partner"
          sr-only-label
          size="small"
          class="w-48"
          placeholder="Pick a partner"
          data-testid="dropdown"
          @update:modelValue="changePartner"
        />
        <RouterLink to="end-to-end-testing/schedule" append>
          <LobButton variant="secondary">
            CREATE NEW TEST
            <Plus class="w-5 h-5 ml-4" />
          </LobButton>
        </RouterLink>
      </div>
    </header>

    <Alert
      v-if="errorMessage"
      data-testid="error-message"
      class="my-3"
      variant="error"
    >
      {{ errorMessage }}
    </Alert>

    <ResultsModal
      v-if="!!selectedReportModal"
      :test-id="selectedReportModal"
      :is-modal-visible="isReportModalOpened"
      @close-modal="onCloseModal"
    />

    <LoadingIndicator>
      <div v-if="!isLoading" class="pr-10">
        <table class="table" data-testid="table">
          <thead>
            <tr>
              <th
                v-for="key in [
                  'Status',
                  'Partner',
                  'Resource',
                  'Test Type',
                  'Qty.',
                  'Tests'
                ]"
                :key="key"
                class="border-t-0 text-gray-900 font-light text-base"
              >
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody data-testid="table-body">
            <template v-for="test in tests" :key="test.id">
              <tr
                :data-testid="`table-row-${test.id}`"
                :class="[
                  'table-row',
                  'cursor-pointer',
                  'relative',
                  'font-light',
                  'text-sm',
                  expandedRow === test ? 'bg-white-300' : 'hover:bg-white-300'
                ]"
                @click="handleExpand(test)"
              >
                <td class="align-middle">
                  <Badge :variant="getVariant(test.status)">
                    <span class="font-medium capitalize">
                      {{ test.status }}
                    </span>
                  </Badge>
                </td>
                <td class="align-middle">{{ getPartnerName(test.partner) }}</td>
                <td class="align-middle">{{ test.type }}</td>
                <td class="align-middle">{{ test.resource }}</td>
                <td class="align-middle">
                  {{ formatNumber(test.qty) }}
                </td>
                <td class="align-middle w-60">
                  <div class="flex gap-2">
                    <ActionButton
                      :test="test"
                      @show-report="onShowReportModal"
                    />
                    <LobButton
                      v-if="isCSVButtonVisible(test)"
                      class="csv-button text-xs"
                      variant="tertiary"
                      size="small"
                      data-testid="csv-button"
                      @click.stop="handleDownloadCSV(test.id)"
                    >
                      CSV
                      <Download class="w-3 h-3 ml-1" />
                    </LobButton>
                  </div>
                  <div
                    :class="[
                      '-right-10 absolute top-5',
                      'transform -translate-y-1/2',
                      expandedRow === test ? 'text-gray-100' : 'text-gray-300'
                    ]"
                  >
                    <ChevronUp v-if="expandedRow === test" class="w-9 h-6" />
                    <ChevronDown v-else class="w-9 h-6" />
                  </div>
                </td>
              </tr>
              <tr v-if="expandedRow === test" class="bg-white-300">
                <td colspan="7" class="border-t-0">
                  <InfoRow data-testid="info-row" :test="test" />
                  <TestCasesTable
                    data-testid="test-cases-table"
                    :test-cases="test.test_cases"
                    :description-map="descriptionMap"
                  />
                </td>
              </tr>
            </template>
            <template v-if="!tests.length">
              <tr
                :class="[
                  'table-row',
                  'cursor-pointer',
                  'relative',
                  'font-light',
                  'text-sm'
                ]"
              >
                <td class="align-middle" colspan="6">
                  <Alert variant="warning">
                    No test available for this filters
                  </Alert>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <Pagination
          data-testid="pagination"
          :collection="tests"
          :page="currentPage"
          :limit="PAGE_SIZE"
          :total="testsTotalCount"
          @change="changePage"
        />
      </div>
    </LoadingIndicator>
  </div>
</template>
<style scoped>
.csv-button {
  padding: 0.5rem 0.25rem !important;
}
</style>
