<template>
  <main class="sessionless-container">
    <PartnersLogo class="logo" />
    <CenteredBox>
      <router-view />
    </CenteredBox>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Sessionless'
})
</script>

<style lang="scss" scoped>
.sessionless-container {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}
.logo {
  margin: 0 auto;
  padding-top: 1.5rem;
}
</style>
