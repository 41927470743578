<template>
  <P>ERROR: No single-field editor for type: {{ type }}</P>
</template>

<script lang="ts">
import { FieldType, Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  name: 'UnimplementedSVE',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    type: {
      validator: () => true,
      required: true
    } as Prop<FieldType>,
    modelValue: {
      validator: () => true,
      required: true
    } as Prop<unknown>
  },
  emits: ['update:modelValue']
})
</script>
