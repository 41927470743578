import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded bg-white-100 border border-gray-300 p-2 flex justify-between items-center" }
const _hoisted_2 = { class: "text-xs text-gray-300 uppercase w-14 font-bold leading-none w-32 mr-3" }
const _hoisted_3 = { class: "text-center flex flex-col items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Check = _resolveComponent("Check")!
  const _component_Close = _resolveComponent("Close")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.value)
        ? (_openBlock(), _createBlock(_component_Check, {
            key: 0,
            class: "text-primary-500 w-6"
          }))
        : _createCommentVNode("", true),
      (!_ctx.value)
        ? (_openBlock(), _createBlock(_component_Close, {
            key: 1,
            class: "text-primary-500 w-6"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}