import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rouded bg-white-200 border border-gray-100 rounded w-full flex flex-wrap items-center justify-between py-3" }
const _hoisted_2 = { class: "mx-3" }
const _hoisted_3 = { class: "text-gray-500 text-sm" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "mx-3 text-primary-500 text-4xl font-thin text-right" }
const _hoisted_6 = { class: "text-gray-300 text-xs font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.subtitle), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", null, _toDisplayString(_ctx.value), 1),
      _createElementVNode("div", _hoisted_6, " of " + _toDisplayString(_ctx.total) + " " + _toDisplayString(_ctx.RESOURCES[_ctx.resource].unit.toLowerCase()) + " remaining ", 1)
    ])
  ]))
}