<template>
  <div id="wizardBox">
    <div class="self-center bg-blue">
      <h5 id="wizardTitle">{{ value }}</h5>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, Prop } from '@vue/runtime-core'
import { TestSet } from '@/store/testSets'
import { Schema } from '@/store/testSets/schema'

export default defineComponent({
  name: 'WizardBox',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>
  }
})
</script>
