<template>
  <div class="content p-5">
    <PortalBreadCrumb />
    <h2 class="text-3xl mb-8 font-bold">
      {{ `${partner.display_name} Facilities` }}
    </h2>
    <Card>
      <div class="flex flex-row text-xl mb-4">Facility Options</div>
      <PartnerFacilitiesTable :partner-id="partnerId" />
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import PartnerFacilitiesTable from './PartnerFacilitiesTable.vue'
import { Partner, getAllPartners } from '@/store/partners'
import PortalBreadCrumb from '../../../../../components/common/PortalBreadcrumb.vue'

export default defineComponent({
  name: 'PartnerFacilitiesList',
  components: {
    PartnerFacilitiesTable,
    PortalBreadCrumb
  },
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      partner: null as Partner | null
    }
  },
  created() {
    const allPartners = getAllPartners()
    const [partner] = allPartners.filter((p) => p.id === this.partnerId)

    this.partner = partner
  }
})
</script>
<style lang="scss" scoped></style>
