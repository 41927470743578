<script lang="ts">
import { defineComponent, reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
import { isAdminUser } from '@/store/session'
import { useDownloadCSV } from './useDownloadCSV'
import { useSendReport, ReportValues } from './useSendReport'

export default defineComponent({
  setup() {
    const {
      params: { testId }
    } = useRoute()
    const isAdmin = computed(isAdminUser)
    const { handleReportSubmit, isSubmitting, error } = useSendReport()
    const formValues = reactive<ReportValues>({
      downloadTime: '',
      bccTime: '',
      gmcTime: '',
      filesReceived: '',
      filesIngested: '',
      correctDirectory: ''
    })
    const mailLink = `mailto:eng-partners-request@lob.com?cc=eng-partners@lob.com&subject=${encodeURI(
      `E2E Tests issue - ${testId}`
    )}`

    const { downloadCSV, error: csvError } = useDownloadCSV()

    return {
      error,
      handleFormSubmit: () => handleReportSubmit(testId as string, formValues),
      handleDownloadCSV: () => downloadCSV(testId as string),
      csvError,
      formValues,
      mailLink,
      isSubmitting,
      isAdmin
    }
  }
})
</script>

<template>
  <div class="content p-6">
    <header class="pb-1">
      <h2>End to End Testing</h2>
      <P>Schedule and create end to end tests</P>
    </header>

    <P class="text-lg font-light my-5">
      Testing has been done. Please fill out below following parameters/results.
    </P>

    <form @submit.prevent="handleFormSubmit">
      <div class="grid grid-flow-col auto-cols-fr gap-8 max-w-4xl">
        <TextInput
          id="downloadTime"
          v-model="formValues.downloadTime"
          label="Time to download artwork (hours)"
          type="number"
          placeholder="Value"
          :min="0"
          required
          data-testid="download-time-input"
        />
        <TextInput
          id="bccTime"
          v-model="formValues.bccTime"
          label="Time for BCC (hours)"
          type="number"
          placeholder="Value"
          :min="0"
        />
        <TextInput
          id="gmcTime"
          v-model="formValues.gmcTime"
          label="GMC impose time"
          placeholder="Value"
          type="number"
          :min="0"
        />
      </div>

      <div class="mt-4" data-testid="files-received">
        <RadioGroup
          legend="Have you received files on your agent correctly?"
          required
        >
          <RadioButton
            id="filesReceived"
            v-model="formValues.filesReceived"
            name="filesReceived"
            value="true"
            label="Yes"
            required
          />
          <RadioButton
            id="filesNotReceived"
            v-model="formValues.filesReceived"
            name="filesReceived"
            value="false"
            label="No"
            required
          />
        </RadioGroup>
      </div>

      <div class="mt-4" data-testid="files-ingested">
        <RadioGroup legend="Did all your files ingest correctly?">
          <RadioButton
            id="filesIngested"
            v-model="formValues.filesIngested"
            name="filesIngested"
            value="true"
            label="Yes"
            required
          />
          <RadioButton
            id="filesNotIngested"
            v-model="formValues.filesIngested"
            name="filesIngested"
            value="false"
            label="No"
            required
          />
        </RadioGroup>
      </div>

      <div class="my-4" data-testid="correct-directory">
        <RadioGroup legend="Did all go to correct agent directory?">
          <RadioButton
            id="correctDirectory"
            v-model="formValues.correctDirectory"
            name="correctDir"
            value="true"
            label="Yes"
            required
          />
          <RadioButton
            id="incorrectDirectory"
            v-model="formValues.correctDirectory"
            name="correctDir"
            value="false"
            label="No"
            required
          />
        </RadioGroup>
      </div>

      <Alert v-if="error" variant="error" data-testid="error">
        {{ error }}
      </Alert>

      <div class="flex gap-5 mt-4">
        <LobButton :disabled="isSubmitting" data-testid="send-report">
          Send testing report
        </LobButton>
        <a :href="mailLink" data-testid="report-issues">
          <LobButton variant="tertiary" type="button">
            <Warning class="w-4 h-4 mr-2" />
            Report problems with testing
          </LobButton>
        </a>
      </div>
    </form>

    <div v-if="isAdmin">
      <P class="mb-2 mt-5 font-light">
        You can download a data file of the resource IDs that were generated as
        a part of this test
      </P>

      <Alert v-if="csvError" variant="error" data-testid="csvError">
        {{ csvError }}
      </Alert>

      <LobButton
        class="mt-2"
        variant="tertiary"
        size="small"
        @click="handleDownloadCSV"
      >
        CSV
        <Download class="w-4 h-4 ml-2" />
      </LobButton>
    </div>
  </div>
</template>
