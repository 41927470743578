<template>
  <div id="container" class="relative">
    <div
      id="searchBar"
      class="border-2 rounded border-grayDove h-10 flex align-middle"
    >
      <Search v-if="!resultChosen" class="w-5 h-5 mx-1.5 self-center" />
      <fa
        v-if="resultChosen"
        icon="times"
        class="w-4 h-4 mx-1.5 self-center cursor-pointer"
        @click="handleReselect"
      />
      <input
        id="myInput"
        :disabled="resultChosen"
        :value="searchTerm"
        class="h-full w-full disabled:bg-offWhite bg-mi p-1.5 outline-none"
        type="text"
        placeholder="Search.."
        @input="handleSearch"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </div>
    <Card
      v-if="isSearching && searchTerm.length > 0"
      id="results"
      class="p-0 mb-2 bg-white absolute w-full z-40"
    >
      <div
        v-if="isSearching && searchTerm.length > 0 && awaitingSearch"
        id="loading"
        class="self-center italic p-2"
      >
        Loading Results...
      </div>
      <div
        v-else-if="searchResults.length === 0"
        id="noResults"
        class="self-center font-bold p-2"
      >
        No Results.
      </div>
      <div v-else id="results">
        <div
          v-for="(result, index) in searchResults"
          :key="`search-${index}`"
          class="hover:bg-skyBlue cursor-pointer p-2"
          @click="handleSelect(result)"
        >
          <slot :result="result" />
        </div>
      </div>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'SearchAndSelect',
  props: {
    searchFunction: { type: Function, required: true },
    selectedItemDisplayProperty: { type: String, required: true },
    selectedItem: {
      type: Object,
      default: () => {
        return {
          label: '',
          value: ''
        }
      }
    }
  },
  emits: ['handleSearch', 'onSelect', 'onDeselect'],
  data() {
    return {
      searchTerm: '',
      isSearching: false,
      awaitingSearch: false,
      searchCheckTimer: undefined as any,
      searchResults: [],
      selectedResult: {},
      resultChosen: false
    }
  },
  watch: {
    selectedItem: function (newVal) {
      this.selectedResult = newVal
      this.searchTerm =
        this.selectedResult[this.selectedItemDisplayProperty!] || ''
      this.resultChosen = this.searchTerm.length > 0
    }
  },
  mounted() {
    if (this.selectedItem) {
      this.selectedResult = this.selectedItem
      this.searchTerm =
        this.selectedResult[this.selectedItemDisplayProperty!] || ''
      this.resultChosen = this.searchTerm.length > 0
    }
  },
  methods: {
    handleFocus() {
      this.isSearching = true
    },
    handleBlur() {
      setTimeout(() => {
        this.isSearching = false
      }, 300)
    },
    handleSearch(e) {
      this.searchTerm = e.target.value
      this.awaitingSearch = true
      clearTimeout(this.searchCheckTimer)
      this.searchCheckTimer = setTimeout(() => {
        this.search(this.searchTerm)
      }, 300)
    },
    search(val) {
      this.searchResults = []
      this.searchFunction!(val)
        .then((results) => {
          this.searchResults = results
        })
        .finally(() => {
          this.awaitingSearch = false
        })
    },
    clearSearch() {
      this.searchTerm = ''
      this.searchResults = []
    },
    handleSelect(result) {
      this.selectedResult = result
      this.searchTerm = this.selectedResult[this.selectedItemDisplayProperty!]
      this.resultChosen = true
      this.$emit('onSelect', result)
    },
    handleReselect() {
      this.resultChosen = false
      this.$emit('onDeselect')
      this.clearSearch()
    }
  }
})
</script>
