<template>
  <Caption>
    Size: {{ width }}" w by {{ height }}" h
    <br />
    Placement: {{ rightMargin }}" from the right edge and {{ bottomMargin }}"
    from the bottom edge of the postcard's
    <i>size</i>
    .
    <br />
    (
    <i>size</i>
    meaning final size, not proof size.)
  </Caption>
  <br />
  <InlineFigureBox>
    <div id="diagram">
      <div />
      <div id="width">{{ width }}"</div>
      <div />
      <div id="height">{{ height }}"</div>
      <div id="address-block-graphic">
        <span id="horizontal-gap" class="floating-measurement">
          {{ rightMargin }}"
        </span>
        <span id="vertical-gap" class="floating-measurement">
          {{ bottomMargin }}"
        </span>
        <img src="@/assets/images/Guidelines/address-block.svg" />
      </div>
      <div id="horizontal-arrow-to-edge">
        <img src="@/assets/images/Guidelines/horizontal-arrow.svg" />
      </div>
      <div />
      <div id="vertical-arrow-to-edge">
        <img src="@/assets/images/Guidelines/vertical-arrow.svg" />
      </div>
      <div />
    </div>
  </InlineFigureBox>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    rightMargin: {
      type: String,
      required: true
    },
    bottomMargin: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped lang="scss">
#diagram {
  width: max-content;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: min-content auto auto;
  border-right: 1px solid rgba(#000, 0.1);
  border-bottom: 1px solid rgba(#000, 0.1);
}
#width,
#height {
  color: #f76e40;
}
#width,
#height,
.floating-measurement {
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}
#width,
#height,
#horizontal-arrow-to-edge,
#vertical-arrow-to-edge {
  margin: auto;
}
#address-block-graphic {
  position: relative;
}
.floating-measurement {
  color: #8e72d4;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.floating-measurement#horizontal-gap {
  top: unset;
  text-align: center;
}
.floating-measurement#vertical-gap {
  left: unset;
  display: inline-flex;
  align-items: center;
}
#illustration {
  display: grid;
  grid-template-rows: max-content 2rem;
  grid-template-columns: max-content 2rem;
}
</style>
