import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-8 max-w-lg" }
const _hoisted_2 = { class: "flex flex-column mb-12 gap-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_H1 = _resolveComponent("H1")!
  const _component_P = _resolveComponent("P")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_ChecklistWithOther = _resolveComponent("ChecklistWithOther")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Caption = _resolveComponent("Caption")!
  const _component_MultiFileUpload = _resolveComponent("MultiFileUpload")!
  const _component_LobButton = _resolveComponent("LobButton")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_H1, null, {
        default: _withCtx(() => [
          _createTextVNode("Failed PDF Notification Form")
        ]),
        _: 1
      }),
      _createVNode(_component_P, null, {
        default: _withCtx(() => [
          _createTextVNode(" Form for print partners to easily share out IDs and reasons for rejected mailpieces ")
        ]),
        _: 1
      }),
      _createVNode(_component_TextInput, {
        id: "companyName",
        modelValue: _ctx.companyName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyName) = $event)),
        label: "Print Partner Company Name"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_ChecklistWithOther, {
        modelValue: _ctx.managerName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.managerName) = $event)),
        name: "Partner Manager Name",
        options: _ctx.managers
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_ChecklistWithOther, {
        modelValue: _ctx.formFactor,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formFactor) = $event)),
        name: "Rejected Form Factor",
        options: _ctx.formFactors
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_ChecklistWithOther, {
        modelValue: _ctx.location,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.location) = $event)),
        name: "Location of Rejected Form Factor",
        options: _ctx.locations
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_ChecklistWithOther, {
        modelValue: _ctx.reason,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reason) = $event)),
        name: "Reason for Rejecting Mail Pieces",
        options: _ctx.reasons
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_Textarea, {
        id: "explanation",
        modelValue: _ctx.explanation,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.explanation) = $event)),
        "input-class": "min-w-full",
        label: "Explanation of File Rejection"
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", null, [
        _createVNode(_component_Caption, null, {
          default: _withCtx(() => [
            _createTextVNode(" Upload CSVs of Impacted IDs (do not include headers or column titles) ")
          ]),
          _: 1
        }),
        _createVNode(_component_MultiFileUpload, {
          modelValue: _ctx.csvs,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.csvs) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_Caption, null, {
          default: _withCtx(() => [
            _createTextVNode("Upload Relevant Screenshots (if appropriate)")
          ]),
          _: 1
        }),
        _createVNode(_component_MultiFileUpload, {
          modelValue: _ctx.screenshots,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.screenshots) = $event))
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.submitting)
        ? (_openBlock(), _createBlock(_component_LobButton, {
            key: 0,
            disabled: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode("Submitting...")
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_LobButton, {
            key: 1,
            onClick: _withModifiers(_ctx.submit, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Submit")
            ]),
            _: 1
          }, 8, ["onClick"]))
    ])
  ]))
}