import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-column" }
const _hoisted_2 = { class: "bg-mist shadow-md shadow-black rounded w-100 grid grid-cols-6 items-baseline justify-center p-2" }
const _hoisted_3 = { class: "pl-3 col-span-4 content-baseline" }
const _hoisted_4 = { class: "capitalize flex flex-row items-baseline" }
const _hoisted_5 = { class: "flex flex-row items-baseline justify-between" }
const _hoisted_6 = { class: "pl-3 pr-3" }
const _hoisted_7 = { class: "pl-3 pr-3" }
const _hoisted_8 = {
  key: 0,
  class: "flex flex-column w-100 items-baseline justify-items-center text-sm pt-3 pl-2 pb-3 rounded shadow-sm shadow-black"
}
const _hoisted_9 = { class: "grid grid-cols-7 w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggle_button = _resolveComponent("toggle-button")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_fa = _resolveComponent("fa")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_ManageBidsInnerTable = _resolveComponent("ManageBidsInnerTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.partner.partner_id), 1),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isAdminOrPopsUser)
          ? (_openBlock(), _createBlock(_component_toggle_button, {
              key: 0,
              modelValue: _ctx.toggleStatus,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.toggleStatus) = $event)),
              "selected-div-id": _ctx.partner,
              name: "",
              label: "",
              disabled: _ctx.toggleDisabled,
              onClick: _ctx.updateStatusToggle
            }, null, 8, ["modelValue", "selected-div-id", "disabled", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Badge, {
          class: "justify-content-center p-1 text-sm",
          variant: _ctx.configureStatusVariant(_ctx.partner.status)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.partner.status), 1)
          ]),
          _: 1
        }, 8, ["variant"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.partner.date), 1),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openBidDetails()))
          }, [
            _createVNode(_component_fa, {
              class: "text-slate-300",
              icon: _ctx.direction
            }, null, 8, ["icon"])
          ])
        ])
      ])
    ]),
    (_ctx.toggleShowInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: key.name,
                class: "items-baseline pl-4 pb-3 pt-2"
              }, _toDisplayString(key.name), 1))
            }), 128)),
            _createVNode(_component_Edit, {
              class: "h-5 mr-4 mt-2 flex justify-self-end self-baseline text-gray-100",
              onClick: _ctx.openAdminEditModal
            }, null, 8, ["onClick"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bidsOfPartner.bidsArray, (bid) => {
            return (_openBlock(), _createElementBlock("div", {
              key: bid.id,
              class: "flex flex-column w-100 pl-2 pr-4 pb-1"
            }, [
              _createVNode(_component_ManageBidsInnerTable, {
                class: "flex items-baseline flex-row",
                bid: bid,
                partner: _ctx.partner,
                "is-admin-or-pops-user": false
              }, null, 8, ["bid", "partner"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}