<template>
  <LoadingIndicator>
    <job-results-modal
      v-if="showModal"
      :job="selectedJob"
      @close="closeModal"
    />
    <div v-if="jobsLoaded" class="pr-10">
      <table class="table" data-testid="table">
        <thead>
          <tr>
            <th
              v-for="key in [
                { title: 'Status' },
                { title: 'Job ID' },
                { title: 'Flush Type' },
                { title: 'Flush Key' },
                { title: 'Created At' },
                { title: 'Update At' },
                { title: 'Count Processed' },
                {
                  title: 'Deduplicated Count',
                  tooltip: `Some redrive queues contain duplicate order IDs.
                    This field shows the true count of orders that will be redriven after deduplication.`
                },
                { title: 'Total Count' }
              ]"
              :key="key"
              class="border-t-0 text-gray-900 font-light text-base"
            >
              {{ key.title }}
              <Tooltip v-if="key.tooltip" position="bottom">
                <template #trigger>
                  <Info class="ml-1 w-5 h-5" />
                </template>
                <template #content>
                  <div class="w-min">{{ key.tooltip }}</div>
                </template>
              </Tooltip>
            </th>
          </tr>
        </thead>
        <tbody data-testid="table-body">
          <template v-for="job in jobs" :key="job.id">
            <tr
              :data-jobid="`table-row-${job.id}`"
              :class="[
                'table-row',
                'cursor-pointer',
                'relative',
                'font-light',
                'text-sm'
              ]"
            >
              <td class="align-middle">
                <Badge :variant="statusToVariant(job.status)">
                  <span class="font-medium capitalize">
                    {{ job.status }}
                  </span>
                </Badge>
              </td>
              <td class="align-middle">{{ job.id }}</td>
              <td class="align-middle">{{ job.flush_type }}</td>
              <td class="align-middle">{{ job.flush_key }}</td>
              <td class="align-middle">{{ job.created_at }}</td>
              <td class="align-middle">{{ job.updated_at }}</td>
              <td class="align-middle">
                {{ job.count_processed.toLocaleString() }}
              </td>
              <td class="align-middle">
                {{ job.deduplicated_count.toLocaleString() }}
              </td>
              <td class="align-middle">
                {{ job.total_count.toLocaleString() }}
              </td>
              <td class="align-middle w-60">
                <LobButton
                  v-if="resultsVisible(job)"
                  class="py-2 px-1 text-xs"
                  variant="tertiary"
                  size="small"
                  data-testid="csv-button"
                  @click.stop="openModal(job)"
                >
                  Results CSV
                </LobButton>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <!-- <Pagination
        data-testid="pagination"
        :collection="tests"
        :page="currentPage"
        :limit="PAGE_SIZE"
        :total="testsTotalCount"
        @change="changePage"
      /> -->
    </div>
  </LoadingIndicator>
</template>

<script lang="ts">
/* eslint-disable camelcase */
import { PropType } from 'vue'
import { Job } from '@/store/jobs'
import { defineComponent } from '@vue/runtime-core'
import JobResultsModal from './JobResultsModal.vue'

export default defineComponent({
  name: 'JobsTable',
  components: { JobResultsModal },
  props: {
    jobs: {
      type: Array,
      default: Array as PropType<Job[]>
    },
    jobsLoaded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['loadJobs'],
  data() {
    return {
      showModal: false,
      selectedJob: null,
      getJobsTimer: null as any
    }
  },
  async mounted() {
    this.getJobsTimer = setInterval(() => {
      this.$emit('loadJobs', false)
    }, 3000)
  },
  unmounted() {
    clearInterval(this.getJobsTimer)
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.selectedJob = null
    },
    openModal(job) {
      this.selectedJob = job
      this.showModal = true
    },
    statusToVariant(jobStatus) {
      switch (jobStatus) {
        case 'complete':
        case 'completed':
          return 'success'
        case 'failed':
          return 'error'
        default:
          return 'info'
      }
    },
    resultsVisible(job) {
      return job.status === 'complete' && job.type !== 'flush'
    }
  }
})
</script>
