<template>
  <div class="content p-5">
    <h2>Throughput Testing</h2>
    <p class="subtitle mt-3">
      Please select a date and time to go through the Throughput Testing process
      with us.
    </p>
    <div
      class="calendly-inline-widget"
      data-url="https://calendly.com/partner-network-management/agent-thoughtput-test"
      style="min-width: 320px; height: 630px"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ThroughputTesting',
  mounted() {
    const existingTag = document.getElementById('calendly')
    if (existingTag) {
      existingTag.remove()
    }
    const calendly = document.createElement('script')
    calendly.type = 'text/javascript'
    calendly.src = 'https://assets.calendly.com/assets/external/widget.js'
    calendly.id = 'calendly'
    calendly.async = true
    document.body.appendChild(calendly)
  }
})
</script>
