import { createRouter, createWebHistory } from 'vue-router'
import pages from './pages'
import { $segment } from '@/plugins/segment'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash
      }
    } else {
      return {
        top: 0
      }
    }
  },
  routes: pages
})

router.afterEach((to) => {
  $segment.page(undefined, to.fullPath)
})

export default router
