<template>
  <FieldSetRow>
    <TextInput
      id="name"
      label="Date (Leave empty to omit)"
      :model-value="textValue"
      @update:modelValue="updateValue($event)"
    />
  </FieldSetRow>
  <FieldSetRow>
    Allowed formats:
    <code class="border-1 bg-white border-gray-100 p-1 rounded text-gray-700">
      yyyy-mm-dd
    </code>
    &nbsp;
    <code class="border-1 bg-white border-gray-100 p-1 rounded text-gray-700">
      yyyy-mm-ddThh:mm:ss
    </code>
  </FieldSetRow>
  <FieldSetRow>
    <Alert v-if="syntaxError" variant="error">
      {{ syntaxError }}
    </Alert>
  </FieldSetRow>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    modelValue: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      textValue: '',
      syntaxError: null as string | null
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.syntaxError = null
        if (this.modelValue === null) {
          this.textValue = ''
          return
        }
        try {
          const thisValue = new Date(this.textValue).toISOString()
          if (thisValue === this.modelValue) {
            return
          }
        } catch (e) {
          this.textValue = new Date(this.modelValue).toISOString()
        }
      },
      immediate: true
    }
  },
  methods: {
    updateValue(newText: string) {
      this.textValue = newText
      if (newText === '') {
        this.$emit('update:modelValue', null)
        return
      }
      try {
        const value = new Date(this.textValue).toISOString()
        this.$emit('update:modelValue', value)
        this.syntaxError = null
      } catch (e) {
        this.syntaxError = `${e}`
      }
    }
  }
})
</script>
