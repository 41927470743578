<template>
  <div id="guidelines-content">
    <H2>1. Size</H2>
    <P>
      Because postcards support a full bleed effect, each dimension of the
      printed postcard must be printed at a size 0.25" larger than the final
      product dimension will be, and then trimmed down to the intended size.
    </P>
    <P>
      This ensures that the partner knows to print the postcard at a slightly
      larger size, and then trim to achieve the 6x11 size.
    </P>

    <H2>2. Address Block</H2>
    <P>
      For postcards and self-mailers, we always require that the address block
      be printed on the final product, even if the background area where the
      address block will go is also white.
    </P>
    <H3>Postcard 4x6</H3>
    <PostcardAddressBlock
      width="3.2835"
      height="2.375"
      right-margin="0.275"
      bottom-margin="0.25"
    />
    <H3>Postcard 6x9</H3>
    <PostcardAddressBlock
      width="4"
      height="2.375"
      right-margin="0.275"
      bottom-margin="0.25"
    />
    <H3>Postcard 6x11</H3>
    <PostcardAddressBlock
      width="4"
      height="2.375"
      right-margin="0.275"
      bottom-margin="0.25"
    />

    <H2>3. Postage Indica</H2>
    <P>Postage indica has to be present and correct. Postage types:</P>
    <ul class="indented list-disc">
      <li>Standard</li>
      <li>First Class</li>
    </ul>
    <P>
      Lob's Indicia Permit Number is 634. A correct Indicia looks like the
      following:
    </P>
    <InlineFigureBox>
      <img src="@/assets/images/Guidelines/indica-example.svg" />
    </InlineFigureBox>

    <H2>4. JSON</H2>
    <P>The following are the fields that must be checked:</P>
    <div>
      <code>to</code>
      <br />
      <code>from</code>
      <br />
      <code>color</code>
      <br />
      <div>
        <P>
          Can be either
          <code>true</code>
          or
          <code>false</code>
          .
          <br />
          <!-- This is what it says in Figma, but I don't know if that was a typo. -->
          If
          <code>true</code>
          , the digital proof must be rendered in full color.
          <br />
          If
          <code>false</code>
          , the digital proof must be rendered in full color.
          <br />
        </P>
      </div>
      <code>address_placement</code>
      <br />
      <code>return_envelope</code>
      <br />
      <code>perforated_page</code>
      <br />
      <code>perforated_sheet</code>
      <br />
      <code>custom_envelope</code>
      <br />
      <code>mail_type</code>
      <br />
      <code>pages</code>
      <br />
      <code>sheets</code>
      <br />
      <code>merge_variables</code>
      <br />
      <code>imb_code</code>
      <br />
      <code>bleed</code>
      <br />
      <code>fold</code>
      <br />
    </div>

    <H2>5. Address Formation</H2>
    <P>
      Recipient addresses will always be in the following formation:
      <div>
        <code>to.name</code>
        (if not null)
        <br />
        <code>to.company</code>
        (if not null)
        <br />
        <code>to.address_line1</code>
        <code>to.address_line2</code>
        <br />
        <code>to.address_city</code>
        <code>to.address_state</code>
        <code>to.address_country</code>
        <code>to.address_zip</code>
        <br />
      </div>
      For postcards, only the recipient address is required. The return address
      is optional and depends on the JSON.
    </P>

    <H2>6. IMb</H2>
    <P>
      The location and size of IMbs must follow fairly strict rules in order to
      be accepted by USPS as valid and scannable. If an IMb barcode is every
      considered invalid or unscannable, then USPS will likely reach out to the
      print partner from which they notice the issue and alert them they are in
      violation. Furthermore, there is the strong possibility that the mail
      piece will not make it to the end recipient. So it is of the utmost
      importance that the IMb information, and to some extent the written
      address information, is in the correct place.
    </P>
    <img src="@/assets/images/Guidelines/imb-layout.svg" />
    <P>IMb has to be scannable and correct</P>
    <P>
      The IMb provided by the Partner must be scannable, the scan must return a
      valid response, and the response must be correct / aligned with the
      details in the JSON test file.
    </P>
    <P>
      To test for the scannability of an IMb, take a screenshot of the IMb code
      on the digital proof, and then upload the screenshot to the following
      website:
      <a
        href="https://www.solvoj.com/imb-reader/?lock=tmpj0bee9fh.lock#form-section"
      >
        https://www.solvoj.com/imb-reader/?lock=tmpj0bee9fh.lock#form-section
      </a>
    </P>
    <P>Below is a screenshot of an example response:</P>
    <img src="@/assets/images/Guidelines/imb-validation-example.svg" />
    <P>
      The following are Service Type IDs (STID)s that we would expect to see,
      depending on the specifics of the associated JSON object.
      <ul>
        <li>
          <code>270</code>
          First class mail that qualifies for Full-Service with IMb tracing
        </li>
        <li>
          <code>271</code>
          271 Standard mail that qualifies for Full-Service with IMb tracing
        </li>
        <li>
          <code>310</code>
          310 First class mail that doesn’t qualify for Full-Service, but still
          has IMb tracing
        </li>
        <li>
          <code>311</code>
          311 Standard mail that doesn’t qualify for Full-Service, but still has
          IMb tracing
        </li>
      </ul>
    </P>
    <P>
      Any STIDs besides the ones above should be flagged to the Partner. All of
      the above STIDs include IMb tracing, which is a requirement for Lob.
    </P>

    <H2>7. Example JSON</H2>
    <pre v-highlightjs="exampleJson"><code /></pre>
  </div>
</template>

<script lang="ts">
import PostcardAddressBlock from './PostcardAddressBlock.vue'

import { defineComponent } from 'vue'

const exampleJson = `{
  "id": "psc_3d8db78323994b36",
  "to": {
    "name": "HARRY ZHANG",
    "company": null,
    "address_line1": "185 BERRY ST STE 6100",
    "address_line2": null,
    "address_city": "SAN FRANCISCO",
    "address_state": "CA",
    "address_zip": "94107-1741",
    "address_country": "UNITED STATES"
  },
  "from": {
    "name": "LEORE AVIDAR",
    "company": null,
    "address_line1": "185 BERRY ST STE 6100",
    "address_line2": null,
    "address_city": "SAN FRANCISCO",
    "address_state": "CA",
    "address_zip": "94107-1741",
    "address_country": "UNITED STATES"
  },
  "press_proof": "https://s3.us-west-2.amazonaws.com/
      content.lob.com/psc_3d8db78323994b36_raw.pdf
      ?AWSAccessKeyId=AKIAIILJUBJGGIBQDPQQ
      &Expires=1517780108
      &Signature=zuGbkjeWiSzFVXYHL1EnDLVZkMs%3D",
  "imb_code": "897714123456789",
  "size": "4x6",
  "mail_type": "usps_first_class",
  "mail_sort": null,
  "mail_date": null,
  "priority": 1,
  "object": "postcard"
}`

export default defineComponent({
  name: 'Guidelines',
  components: { PostcardAddressBlock },
  data: function () {
    return { exampleJson }
  }
})
</script>

<style scoped lang="scss">
#guidelines-content {
  padding: 0 2rem;
  max-width: 50rem;
}
.indented {
  padding-left: 3rem;
}
.list-disc {
  list-style-type: disc;
}
code {
  // display: inline-block;
  background: rgba(#000, 0.05);
  border-radius: 0.25rem;
  padding: 0.1rem 0.3rem;
  margin: 0.2rem 0;
}
</style>
