import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded bg-white-100 border border-gray-300 p-2 flex justify-between items-center" }
const _hoisted_2 = { class: "text-xs text-gray-300 uppercase w-14 font-bold leading-none w-32 mr-3" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "text-5xl font-light text-primary-500" }
const _hoisted_5 = { class: "text-xs font-bold text-gray-500 uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.value), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.subLabel), 1)
    ])
  ]))
}