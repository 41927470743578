<template>
  <router-link to="/">
    <img
      src="@/assets/images/lob-partners-logo.svg"
      :class="['home-logo', { small: small }]"
      alt="Lob Partners Logo"
    />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PartnersLogo',
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
</script>

<style scoped lang="scss">
.home-logo {
  height: 6rem;
}

.home-logo.small {
  height: 3rem;
}
</style>
