<template>
  <div>
    <Caption>{{ name }}</Caption>
    <Checkbox
      v-for="(option, index) of options"
      :key="option"
      v-model="checked[index]"
      :label="option"
      :name="option"
      :value="option"
    />
    <Checkbox v-model="useOther" label="Other:" />
    <TextInput id="other" v-model="other" label="" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      checked: {},
      useOther: false,
      other: ''
    }
  },
  computed: {
    newModelValue() {
      const newValue = [] as Array<unknown>
      for (const key in this.checked) {
        if (this.checked[key]) {
          newValue.push(this.options[key])
        }
      }
      if (this.useOther) {
        newValue.push(`${this.other}`)
      }
      return newValue
    }
  },
  watch: {
    modelValue(oldValue: Array<unknown>) {
      // Apparently this is JavaScript for "check that the arrays are equal",
      // becaause for frustrating reasons "x === y" does not mean that.
      if (oldValue.every((v, i) => this.newModelValue[i] === v)) {
        // This prevents infinite recursion because every time we make a new
        // object, Vue thinks it is different from the previous object even if
        // it has the same fields with the same values.
        return
      }
      this.checked = {}
      for (const index in this.options) {
        this.checked[index] = oldValue.some((x) => x === this.options[index])
      }
      this.useOther = oldValue.some((x) => x === this.other)
    },
    newModelValue(newValue) {
      this.$emit('update:modelValue', newValue)
    }
  }
})
</script>
