import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-mist grid grid-cols-6 justify-items-center shadow-md shadow-black" }
const _hoisted_2 = { class: "w-100 p-2" }
const _hoisted_3 = { class: "w-100 p-2" }
const _hoisted_4 = { class: "w-100 p-2" }
const _hoisted_5 = { class: "w-100 p-2" }
const _hoisted_6 = { class: "w-100 p-2" }
const _hoisted_7 = { class: "w-100 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggle_button = _resolveComponent("toggle-button")!
  const _component_PackageButton = _resolveComponent("PackageButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.ability.name), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.ability.order_field_name), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.ability.operator), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(JSON.stringify(_ctx.ability.order_field_value)), 1),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_toggle_button, {
        modelValue: _ctx.active,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
        class: "cursor-pointer",
        name: _ctx.ability.name,
        label: '',
        onClick: _ctx.toggleActive
      }, null, 8, ["modelValue", "name", "onClick"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_PackageButton, { "on-click": _ctx.removeAbility }, {
        default: _withCtx(() => [
          _createTextVNode("Remove Ability")
        ]),
        _: 1
      }, 8, ["on-click"])
    ])
  ]))
}