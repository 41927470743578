import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f298b716"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-field-editor-root mb-3" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldHeader = _resolveComponent("FieldHeader")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_FormFactorNote = _resolveComponent("FormFactorNote")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FieldHeader, {
      schema: _ctx.schema,
      field: _ctx.field,
      "test-set": _ctx.testSet,
      "supports-separation": "",
      "onUpdate:testSet": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:testSet', $event)))
    }, null, 8, ["schema", "field", "test-set"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.type, (value) => {
        return (_openBlock(), _createElementBlock("span", {
          key: value.value,
          class: "item"
        }, [
          _createVNode(_component_Checkbox, {
            "model-value": _ctx.hasValue(value.value),
            name: value.name,
            label: value.name,
            "onUpdate:modelValue": ($event: any) => (_ctx.handleFieldChange(value.value, $event))
          }, null, 8, ["model-value", "name", "label", "onUpdate:modelValue"]),
          (!_ctx.arraysEqual(value.form_factors, _ctx.field.form_factors))
            ? (_openBlock(), _createBlock(_component_FormFactorNote, {
                key: 0,
                schema: _ctx.schema,
                "form-factors": value.form_factors
              }, null, 8, ["schema", "form-factors"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}