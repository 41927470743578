import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50d72b50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 0,
  class: "table-body"
}
const _hoisted_3 = { class: "w-2/12 text-right" }
const _hoisted_4 = { class: "w-10/12 pl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (entry) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: entry.displayName,
              class: "m-0"
            }, [
              _createElementVNode("td", _hoisted_3, _toDisplayString(entry.displayName) + ":", 1),
              _createElementVNode("td", _hoisted_4, _toDisplayString(entry.value), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}