/* eslint-disable camelcase */
export type Thumbnails = Array<{
  small?: string
  medium?: string
  large?: string
}>

export type Envelope = {
  thumbnails?: Thumbnails
  id: string
  design_type: string
  size: string
  available_inventory: number
  pending_inventory: number
  countries: string[]
  description: string
  auto_reorder: boolean
  date_created: string
}

export type Card = {
  thumbnails?: Thumbnails
  id: string
  size: string
  mode: string
  description: string
  auto_reorder: boolean
  date_created: string
}

export type Buckslip = {
  thumbnails?: Thumbnails
  id: string
  design_type: string
  size: string
  available_inventory: number
  pending_inventory: number
  countries: string[]
  description: string
  auto_reorder: boolean
  date_created: string
}

export interface MaterielInventory {
  id: string
  expiration_updated?: boolean
  delivery_date?: string
  initial_count: number
  partner_id: string
  partner_name: string
  num_remaining: number
  status: string
  total_cost: number
  unit_cost?: number
}

export type CardOrderStatus =
  | 'pending'
  | 'printing'
  | 'available'
  | 'cancelled'
  | 'depleted'

export type OrderType = 'cards' | 'envelopes' | 'buckslips'

export const ORDER_TYPE = {
  CARDS: 'cards',
  ENVELOPES: 'envelopes',
  BUCKSLIPS: 'buckslips'
} as const

export interface CardOrder {
  id: string
  card_id: string
  status: CardOrderStatus
  date_created: string
  availability_date: string
  quantity_ordered: number
  statement_id?: string
  expected_availability_date: string
  expiry_date?: string
  units_left_to_allocate: number
  total_cost?: number
  materiel_inventories: Array<MaterielInventory>
}

export interface EnvelopeOrder {
  id: string
  envelope_id: string
  status: CardOrderStatus
  quantity_ordered: number
  unit_cost: number
  total_cost: number
  date_created: string
  availability_date: string

  statement_id?: string
  expected_availability_date: string
  expiry_date?: string
  statement_unit_cost: number
  statement_total_cost: number

  units_left_to_allocate: number
  materiel_inventories: Array<MaterielInventory>
}

export interface BuckslipOrder {
  id: string
  buckslip_id: string
  status: CardOrderStatus
  quantity_ordered: number
  unit_cost: number
  total_cost: number
  date_created: string
  availability_date: string

  statement_id?: string
  expected_availability_date: string
  expiry_date?: string
  statement_unit_cost: number
  statement_total_cost: number

  units_left_to_allocate: number
  materiel_inventories: Array<MaterielInventory>
}
