<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import PartnerDropdown from '@/components/global/PartnerDropdown.vue'
import { sendPapiRequest } from '@/store/requests'
import { AxiosError } from 'axios'

interface FormFields {
  partner:
    | {
        label: string
        value: string
      }
    | undefined
  subject: string
  body: string
}

interface ValidationvalidationErrors {
  subject: string | undefined
  body: string | undefined
}

const MINIMAL_MESSAGE_LENGTH = 10
const MINIMAL_SUBJECT_LENGTH = 5

export default defineComponent({
  name: 'SendMail',
  components: {
    PartnerDropdown
  },
  setup() {
    const currentView = ref('compose')
    const fields = reactive<FormFields>({
      partner: undefined,
      subject: '',
      body: ''
    })
    const loading = ref(false)
    const error = ref<string | null>(null)
    const sent = ref(false)
    const validationErrors = reactive<ValidationvalidationErrors>({
      subject: undefined,
      body: undefined
    })

    const validate = (): boolean => {
      if (!fields.subject || fields.subject.length < MINIMAL_SUBJECT_LENGTH) {
        validationErrors.subject = `Subject is required, and should be at least ${MINIMAL_SUBJECT_LENGTH} characters long`
      }
      if (!fields.body || fields.body.length < MINIMAL_MESSAGE_LENGTH) {
        validationErrors.body = `Message is required, and should be at least ${MINIMAL_MESSAGE_LENGTH} characters long`
      }
      return !Object.keys(validationErrors).some(
        (key) => validationErrors?.[key] !== undefined
      )
    }

    const submit = async () => {
      error.value = null

      const isValid = validate()
      if (!isValid) {
        return
      }

      loading.value = true

      try {
        await sendPapiRequest({
          method: 'POST',
          path: `/partners/send-emails`,
          data: { ...fields, partner_id: fields.partner?.value || undefined }
        })
        sent.value = true
        fields.subject = ''
        fields.body = ''
      } catch (e) {
        // Format an error message if unsuccessful.
        const eResp = (e as AxiosError).response
        error.value = `${eResp?.status} : ${eResp?.data?.error?.message}`
      } finally {
        loading.value = false
      }
    }

    return {
      fields,
      loading,
      validationErrors,
      submit,
      currentView,
      error,
      sent
    }
  }
})
</script>

<template title="Invite Partner User">
  <div v-if="currentView == 'compose'" class="content p-5">
    <header class="mb-4">
      <h2 class="mb-5">Send Email to partner</h2>
    </header>

    <alert v-if="error" variant="error" class="">
      Error sending email: {{ error }}
    </alert>

    <alert v-if="sent" variant="success" class="">
      Email sent successfully!
    </alert>

    <div class="row mt-5 contentSection">
      <div class="col-3">
        <p class="sectionTitle">Partner</p>
      </div>
      <div class="col-9">
        <PartnerDropdown
          id="partner"
          v-model="fields.partner"
          label="Partner"
          placeholder="Select Partner"
          size="default"
        />
      </div>
    </div>

    <div class="row mt-5 contentSection">
      <div class="col-3">
        <p class="sectionTitle">Message *</p>
      </div>
      <div class="col-9">
        <TextInput
          id="subject"
          v-model="fields.subject"
          label="Subject"
          :required="true"
          :error="!!validationErrors.subject"
          @focusin="validationErrors.subject = undefined"
        />
        <alert v-if="validationErrors?.subject" variant="error" class="mt-1">
          {{ validationErrors.subject }}
        </alert>
        <div data-testid="input-container" class="mt-4">
          <Textarea
            id="body"
            v-model="fields.body"
            name="body"
            data-testId="body"
            class="w-full"
            label="Body"
            placeholder="Insert here the body of your message"
            :required="true"
            :error="!!validationErrors.body"
            input-class="min-w-full"
            @focusin="validationErrors.body = undefined"
          />
        </div>
        <alert v-if="validationErrors?.body" variant="error" class="mt-1">
          {{ validationErrors.body }}
        </alert>
      </div>
    </div>

    <div class="row contentSection mt-3">
      <div class="col-3"></div>
      <div class="col-9">
        <lob-button v-if="!loading" @click.stop="submit">Submit</lob-button>
        <lob-button v-if="loading" :disabled="true">Loading</lob-button>
      </div>
    </div>
  </div>
</template>
