// import { AxiosResponse } from 'axios'
import { sendFFBRequest } from '../requests'
import { defineVuexModule } from '../util'

interface State {
  errorMessage: string
}

const initialState: State = {
  errorMessage: ''
}

export type Spoilage = {
  id?: string
  min_volume: number | string
  max_volume: number | string
  spoilage_rate: number | string
  component_type: string
  deleted?: boolean
}

export type CreateUpdateSpoilagePayload = {
  id?: string
  min_volume: number | string
  max_volume: number | string
  spoilage_rate: number | string
  component_type: string
  customer_account_id?: string | null
}

export type GetSpoilageQuery = {
  account_id?: string | null
}

const storeModule = defineVuexModule('index', initialState)

export const updateSpoilageRate = storeModule.dispatch(
  async (
    ctx,
    params: {
      spoilageRate: Spoilage
      type: string
      accountID: string | null
    }
  ) => {
    let response
    const rate = params.spoilageRate
    const data = {
      min_volume: rate.min_volume,
      max_volume: rate.max_volume,
      component_type: params.type,
      spoilage_rate: rate.spoilage_rate
    } as CreateUpdateSpoilagePayload

    if (params.accountID) {
      data.customer_account_id = params.accountID
    }

    if (rate.deleted && rate.id !== '') {
      // if rate previously existed and is to be deleted
      data.id = rate.id
      response = await sendFFBRequest({
        method: 'DELETE',
        path: '/inventories/spoilage-rates',
        data
      })
    } else if (rate.id) {
      // if id already exists, we know it's an update
      data.id = rate.id
      response = await sendFFBRequest({
        method: 'PATCH',
        path: '/inventories/spoilage-rates',
        data
      })
    } else {
      response = await sendFFBRequest({
        method: 'POST',
        path: '/inventories/spoilage-rates',
        data
      })
    }
    return response
  },
  'updateSpoilageRate'
)

export const getSpoilageRates = storeModule.dispatch(
  async (
    ctx,
    params: {
      accountID?: string
    }
  ) => {
    const query = {} as GetSpoilageQuery
    if (params.accountID !== 'global') {
      query.account_id = params.accountID
    }
    const response = await sendFFBRequest({
      method: 'GET',
      path: '/inventories/spoilage-rates',
      query
    })
    return response.data
  },
  'getSpoilageRates'
)
