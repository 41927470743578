<template>
  <Modal :visible="submitModalOpen" width="600px" @close="closeModal">
    <template #header>
      <h4>Submit {{ totalOrders }} Orders</h4>
    </template>
    <div class="-mb-5 -mt-3">
      These {{ totalOrders }} orders will be flushed. This action cannot be
      undone.
      <div class="flex mt-4">
        <Checkbox
          v-model="prioritized"
          name="prioritized"
          label="Prioritize Flush"
          class="mt-2"
        />
        <div class="flex-1" />
        <LobButton
          data-testId="submit-button"
          variant="secondary"
          size="small"
          @click="closeModal"
        >
          Go Back
        </LobButton>
        <LobButton
          data-testId="submit-button"
          variant="primary"
          size="small"
          class="ml-3"
          @click="handleRedriveQueues"
        >
          Submit
        </LobButton>
      </div>
      <Alert v-if="redriveError" variant="error" class="mt-3">
        {{ redriveErrorMessage }}
      </Alert>
    </div>
  </Modal>
  <div v-if="!isLoading">
    <Alert v-if="redriveMessage" variant="success" class="w-7/12">
      {{ redriveMessage }}
    </Alert>
    <Alert
      v-if="redrivePartialSuccessFails.length"
      variant="warning"
      class="w-7/12"
    >
      <table>
        <tr>
          <th>Partial Success, these failed:</th>
        </tr>
        <tr>
          <dl>
            <template v-for="f in redrivePartialSuccessFails" :key="f.key">
              <dt>{{ f.key }}</dt>
              <dd>- {{ f.reason }}</dd>
            </template>
          </dl>
        </tr>
      </table>
    </Alert>
    <Dropdown
      v-for="dropdown in dropdowns"
      :id="dropdown.id"
      :key="dropdown.id"
      :label="dropdown.label"
      :placeholder="dropdown.placeholder"
      :options="dropdown.options"
      :model-value="query[dropdown.queryKey]"
      :required="true"
      size="small"
      class="mt-3"
      @update:modelValue="(e) => updateQuery(e, dropdown.queryKey, include)"
    />
    <Dropdown
      v-if="query.form_factor.value && query.form_factor.value != checks"
      :id="sizeDropdown.id"
      :label="sizeDropdown.label"
      :placeholder="sizeDropdown.placeholder"
      :options="sizeOptions"
      :model-value="query[sizeDropdown.queryKey]"
      size="small"
      class="mt-3"
      @update:modelValue="(e) => updateQuery(e, sizeDropdown.queryKey, include)"
    />
    <div
      v-for="textInput in inputs"
      :id="textInput.id"
      :key="textInput.id"
      class="text-gray-300 type-small-700"
    >
      <div class="flex">
        <TextInput
          :label="textInput.label"
          :placeholder="textInput.includePlaceholder"
          :model-value="query[textInput.queryKey]"
          size="small"
          class="mt-1 flex-1"
          @update:modelValue="
            (e) => updateQuery(e, textInput.queryKey, include)
          "
        />
        <TextInput
          label=" "
          :placeholder="textInput.excludePlaceholder"
          :model-value="query[textInput.queryKey]"
          size="small"
          class="mt-6 ml-2 flex-1"
          @update:modelValue="
            (e) => updateQuery(e, textInput.queryKey, !include)
          "
        />
      </div>
    </div>
    <div class="flex justify-end mt-5">
      <LobButton
        data-testId="submit-button"
        variant="secondary"
        size="small"
        @click="openConfirmModal"
      >
        Submit All Orders
      </LobButton>
      <LobButton
        data-testId="search-button"
        variant="primary"
        size="small"
        class="ml-3"
        @click="handleSearch"
      >
        Search Orders
      </LobButton>
    </div>
  </div>
  <Alert v-if="searchError" variant="error" class="mt-3">
    {{ searchErrorMessage }}
  </Alert>
  <div v-if="showSearchResults" class="mt-10">
    <h2 class="text-center">Search Results</h2>
    <div id="tableContainer" class="flex bg-offWhite p-10 rounded-md">
      <div
        v-for="column in searchTableColumns"
        :key="column.title"
        :class="column.class"
      >
        <div
          id="header"
          :class="
            column.right ? column.headerClass : 'border-b-2 border-grayDove'
          "
        >
          <div class="flex-1" />
          {{ column.title }}
        </div>
        <div
          id="value"
          :class="column.right ? column.valueClass : 'font-bold text-lg mt-2'"
        >
          <div class="flex-1" />
          {{ column.value }}
        </div>
      </div>
    </div>
    <div id="orderTableContainer" class="bg-offWhite px-10 rounded-md">
      <div id="header" class="flex border-b-2 border-grayDove">
        <div class="w-20">Selected</div>
        <div class="w-8/12">ID</div>
        <div class="w-2/12">Orders</div>
        <div class="w-1/12 flex">
          Finished
          <Tooltip position="right">
            <template #trigger>
              <Info class="ml-1 w-5 h-5" />
            </template>
            <template #content>
              <div class="w-32">
                Orders that have been manually enqueued or finished elsewhere.
              </div>
            </template>
          </Tooltip>
        </div>
      </div>
      <div id="selectAllRow" class="flex mt-2">
        <Checkbox
          v-model="selectAll"
          label="Select All"
          name="selectAll"
          :value="selectAll"
          @change="handleSelectAllToggle"
        />
      </div>
      <div
        v-for="queue in paginatedQueuesToRedrive"
        id="row"
        :key="queue.id"
        class="flex mt-2"
      >
        <div class="w-20">
          <div class="ml-3 -mt-0.5">
            <Checkbox
              v-model="queue.selected"
              label=""
              :name="queue.id"
              :value="queue.selected"
              @change="handleSelectedChange(queue)"
            />
          </div>
        </div>
        <div class="w-8/12 break-all pr-3">{{ queue.id }}</div>
        <div class="w-2/12">{{ queue.value.total_count }}</div>
        <div class="w-1/12">{{ queue.value.manually_enqueued }}</div>
      </div>
      <Pagination
        class="mt-3"
        :collection="queuesToRedrive"
        :page="currentPage"
        :limit="pageLimit"
        :total="queuesToRedrive.length"
        @change="changePage"
      />
    </div>
    <div class="flex justify-end mt-3">
      <LobButton
        data-testId="submit-search-button"
        variant="primary"
        size="small"
        class="ml-3"
        @click="openConfirmModal"
      >
        Submit Orders
      </LobButton>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable camelcase */
import { defineComponent } from '@vue/runtime-core'
import {
  BULK_JOBS,
  LETTER_SIZES,
  POSTCARD_SIZES,
  RESOURCES,
  SELF_MAILER_SIZES
} from '@/consts'
import { getQueues, redriveQueue } from '../../../../store/routingService'

export default defineComponent({
  name: 'FlushView',
  props: {},
  emits: [],
  data() {
    return {
      dropdowns: BULK_JOBS.FLUSH_DROPDOWNS,
      inputs: BULK_JOBS.FLUSH_INPUTS,
      sizeDropdown: BULK_JOBS.SIZE_DROPDOWN,
      checks: RESOURCES.checks.id,
      include: true,
      redriveMessage: '',
      redrivePartialSuccessFails: [] as {
        key: string | undefined
        reason: string | number | undefined
      }[],
      selectAll: true,
      currentPage: 1,
      query: {
        status: {},
        form_factor: {},
        size: {},
        included_account_ids: '',
        included_envelope_ids: '',
        included_inventory_ids: '',
        included_billing_group_ids: '',
        excluded_account_ids: '',
        excluded_envelope_ids: '',
        excluded_inventory_ids: '',
        excluded_billing_group_ids: ''
      },
      keyToTableIndex: {
        postcard: 0,
        self_mailer: 1,
        letter: 2,
        check: 3,
        snap_pack: 4,
        booklet: 5,
        total: 6
      },
      searchTableColumns: [
        {
          title: 'Postcards',
          class: 'w-2/12',
          value: 0
        },
        {
          title: 'Self-Mailers',
          class: 'w-2/12',
          value: 0
        },
        {
          title: 'Letters',
          class: 'w-2/12',
          value: 0
        },
        {
          title: 'Checks',
          class: 'w-1/12',
          value: 0
        },
        {
          title: 'Snap Packs',
          class: 'w-2/12',
          value: 0
        },
        {
          title: 'Booklets',
          class: 'w-1/12',
          value: 0
        },
        {
          title: 'Total',
          class: 'w-2/12 justify-end',
          headerClass: 'border-b-2 border-grayDove flex',
          valueClass: 'font-bold text-lg mt-2 flex',
          value: 0,
          right: true
        }
      ],
      totalOrders: 0,
      isLoading: true,
      prioritized: false,
      submitModalOpen: false,
      showSearchResults: false,
      queuesToRedrive: [] as any[],
      paginatedQueuesToRedrive: [] as any[],
      pageLimit: 10,
      searchError: false,
      searchErrorMessage: '',
      redriveError: false,
      redriveErrorMessage: ''
    }
  },
  computed: {
    sizeOptions() {
      const currentFormFactor = this.query.form_factor as any
      const factorValue = currentFormFactor.value ?? ''
      switch (factorValue) {
        case RESOURCES.letters.routingQueueUnit:
          return [...LETTER_SIZES]
        case RESOURCES.postcards.routingQueueUnit:
          return [...POSTCARD_SIZES]
        case RESOURCES.self_mailers.routingQueueUnit:
          return [...SELF_MAILER_SIZES]
      }
      return []
    }
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    updateQuery(e: any, queryKey: any, include: boolean) {
      if (include) this.query[`include_${queryKey}`] = e
      else this.query[`exclude_${queryKey}`] = e
    },
    closeModal() {
      this.submitModalOpen = false
      this.redriveError = false
    },
    async getRedriveQueues() {
      const resp = await getQueues()
      const queues = resp.data.map((queue) => {
        const id = Object.keys(queue)[0]
        return {
          id,
          value: queue[id],
          selected: true
        }
      })
      const filters = this.createFilters()
      return {
        queues,
        filters
      }
    },
    async openConfirmModal() {
      if (!this.showSearchResults) {
        this.totalOrders = 0
        // if no search has been done and this is a global redrive
        const { queues, filters } = await this.getRedriveQueues()
        this.queuesToRedrive = queues.reduce((queuesList: any[], queue) => {
          const key = queue.id
          const shouldWeKeepQueue = this.keepQueueKey(key, filters)
          if (shouldWeKeepQueue) {
            this.totalOrders += queue.value.total_count
            queuesList.push(queue)
          }
          return queuesList
        }, [])
      }
      this.submitModalOpen = true
    },
    handleSelectAllToggle(selectAllEvent) {
      // target.value is returned as string
      this.clearSearchValues()
      this.queuesToRedrive = this.queuesToRedrive.map((queue) => {
        queue.selected = selectAllEvent.target.value === 'true'
        const deltaChange = queue.selected ? queue.value.total_count : 0
        this.updateSearchTableColumn(queue.id, deltaChange)
        return queue
      })
    },
    changePage(e) {
      const page = e.page
      const offset = (page - 1) * this.pageLimit
      this.paginatedQueuesToRedrive = this.queuesToRedrive.slice(
        offset,
        offset + this.pageLimit
      )
      this.currentPage = page
    },
    updateSearchTableColumn(key, deltaChange) {
      const formFactor = this.grabFormFactor(key)
      this.totalOrders += deltaChange
      this.searchTableColumns[this.keyToTableIndex.total].value += deltaChange
      this.searchTableColumns[this.keyToTableIndex[formFactor]].value +=
        deltaChange
    },
    handleSelectedChange(queue) {
      const deltaChange = queue.selected
        ? queue.value.total_count
        : queue.value.total_count * -1
      this.updateSearchTableColumn(queue.id, deltaChange)
      // if there's an element that's unchecked, select all will be unchecked
      if (this.queuesToRedrive.find((el) => !el.selected))
        this.selectAll = false
      else this.selectAll = true
    },
    async handleRedriveQueues() {
      const calls = this.queuesToRedrive
        .map((queue) => {
          if (queue.selected) {
            // split key into array, pop the first index (always flush type), join remaining with :
            const splitKey = queue.id.split(':') as string[]
            return {
              flushType: splitKey.shift() as string,
              flushKey: splitKey.join(':'),
              prioritized: this.prioritized
            }
          }
        })
        .filter((q) => q) // filter any undefined

      let res = [] as PromiseSettledResult<any>[]
      try {
        res = await Promise.allSettled(
          calls.map((c) => {
            return redriveQueue(c as any)
          })
        )
        if (
          res.filter((r) => r.status === 'rejected').length === calls.length
        ) {
          throw new Error('All redrive job requests failed')
        }
      } catch (e) {
        this.redriveError = true
        this.redriveErrorMessage = e as string
        return
      }

      this.redrivePartialSuccessFails = []
      for (let i = 0; i < res.length; i++) {
        if (res[i].status === 'rejected') {
          let reason = (res[i] as PromiseRejectedResult).reason.response?.data
            ?.error?.message
          if (!reason)
            reason = (res[i] as PromiseRejectedResult).reason.response?.status
          this.redrivePartialSuccessFails.push({
            key: calls?.[i]?.flushKey,
            reason
          })
        }
      }

      this.redriveMessage = BULK_JOBS.REDRIVE_SUCCESS_MESSAGE
      this.submitModalOpen = false
      this.showSearchResults = false
    },
    async handleSearch() {
      try {
        const { queues, filters } = await this.getRedriveQueues()
        this.clearSearchValues()

        this.queuesToRedrive = queues.filter((queue) => {
          const key = queue.id
          const shouldWeKeepQueue = this.keepQueueKey(key, filters)
          // if we shouldn't keep the queue, then return a false so it's filtered out
          if (!shouldWeKeepQueue) return false
          // otherwise keep going and add queue to the final results list
          const formFactor = this.grabFormFactor(key)
          if (formFactor.length !== 0)
            this.searchTableColumns[this.keyToTableIndex[formFactor]].value +=
              queue.value.total_count
          this.searchTableColumns[this.keyToTableIndex.total].value +=
            queue.value.total_count
          this.totalOrders += queue.value.total_count
          return true
        }) as any[]
        this.paginatedQueuesToRedrive = this.queuesToRedrive.slice(
          0,
          this.pageLimit
        )
        this.showSearchResults = true
        this.selectAll = true
      } catch (e) {
        this.searchError = true
        this.searchErrorMessage = e as string
      }
    },
    clearSearchValues() {
      this.searchTableColumns.forEach((el) => {
        el.value = 0
      })
      this.totalOrders = 0
    },
    grabFormFactor(key: string) {
      const formFactor = BULK_JOBS.FORM_FACTOR_OPTIONS.filter((ff) => {
        return key.includes(ff)
      })
      return formFactor[0]
    },
    createFilters(): Record<string, string[]> {
      const query = JSON.parse(JSON.stringify(this.query))
      for (const filter in query) {
        if (typeof query[filter] === 'object') {
          const objFilter = query[filter]
          if (Object.keys(objFilter).length === 0) {
            // if filter field is empty, include all possible queues for this filter
            if (filter === 'size') {
              // size may not be included on a key at all
              Reflect.deleteProperty(query, filter)
            } else {
              query[filter] = this.dropdowns
                .find((el) => el.queryKey === filter)
                ?.options.map((el) => el.value)
            }
          } else {
            // if filter field isn't empty, grab the string value to filter on
            query[filter] = [objFilter.value]
          }
        } else {
          const strFilter = query[filter] as string
          if (strFilter.length === 0) {
            // if input is empty, delete it from list of filters
            Reflect.deleteProperty(query, filter)
          } else {
            // else make it array to query with
            query[filter] = [query[filter]]
          }
        }
      }
      return query
    },
    /**
     * keepQueueKey takes a redrive queue key, and a filter object.  For each filter
     * it will look to see if the given key should be kept or not
     * @param {string} queueKey the redrive queue key to test against
     * @param {Record<string, string[]>} filters the supplied filters from the UI
     * @returns {boolean}
     */
    keepQueueKey(queueKey: string, filters: Record<string, string[]>): boolean {
      for (const filter in filters) {
        // if filter is a dropdown, use those options. If filter has multiple ids,
        // split ids up into an array of strings for filtering
        const separatedFilters =
          filters[filter].length > 1
            ? filters[filter]
            : filters[filter][0].split(',').map(function (item) {
                return item.trim()
              })

        // if filter is an exclusion filter, check to see if id is in key. If it is, exclude it.
        if (filter.includes('exclude')) {
          if (separatedFilters.some((el) => queueKey.includes(el))) return false
          // if filter is an inclusion filter, check to see if id is not in key. If it's not, exclude it.
        } else if (!separatedFilters.some((el) => queueKey.includes(el)))
          return false
      }
      // if we've gotten here we want to keep the key, so say "yes" to "keep the queue key?"
      return true
    }
  }
})
</script>
