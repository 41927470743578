<template>
  <!-- <SidebarContainer> -->
  <div class="digital-proofing-root">
    <div class="digital-proofing-header">
      <Badge :variant="badgeVariant">{{ badgeText }}</Badge>
      <H2>
        <span v-if="permutationData != null">
          {{ permutationData?.resource }} /
          {{ permutationData?.permutation.replaceAll('_', ' ') }}
        </span>
        <span v-else>Loading...</span>
      </H2>
      <span v-if="userIsAdmin">
        <LobButton v-if="adminActionPending" disabled>Loading...</LobButton>
        <LobButton v-else-if="!approved" @click="approveProof()">
          Approve
        </LobButton>
        <LobButton v-else-if="approved" @click="rejectProof()">
          Reject
        </LobButton>
      </span>
    </div>
    <P v-if="permutationData === null">Loading...</P>
    <P v-else-if="uploadedLinks.length === 0">No files uploaded</P>
    <a
      v-for="link in uploadedLinks"
      :key="link.url"
      class="file-card"
      :href="link.url"
    >
      <fa icon="image" />
      <P>{{ link.filename }}</P>
      <fa icon="chevron-right" />
    </a>
    <div v-if="!userIsAdmin">
      <H3>Upload Resources</H3>
      <LobFileUpload :do-try-upload="tryUploadFile" :max-size="maxFileSize" />
    </div>
  </div>

  <!-- Will un-comment once Contentful is integrated to fetch requirements from -->
  <!-- <template #sidebar>
      <PostcardGuidelinesSidebar
        title="4 x 6 Postcard"
        width="4.25"
        height="6.25"
        address-block-width="3.2835"
        address-block-height="2.375"
      />
    </template>
  </SidebarContainer> -->
</template>

<script lang="ts">
import {
  getCurrentPartnerPermutation,
  Permutation,
  updateStepsCompleted,
  uploadProof
} from '@/store/digitalProofing'
import { isAdminUser } from '@/store/session'
import { defineComponent, ref } from 'vue'
// Will un-comment once Contentful is integrated to fetch requirements from
// import SidebarContainer from '@/components/DigitalProofing/SidebarContainer.vue'
// import PostcardGuidelinesSidebar from '@/components/DigitalProofing/PostcardGuidelinesSidebar.vue'

function filenameFromUrl(url: string): string {
  // Strip anchor and/or query
  const basePath = url.split('#')[0].split('?')[0]
  // Extract last path element
  return basePath.split('/').pop() || 'unreachable'
}

const APPROVED = 'DIGITAL_PROOF_APPROVED'

export default defineComponent({
  name: 'Permutation',
  // components: {
  //   SidebarContainer,
  //   PostcardGuidelinesSidebar
  // },
  props: {
    permutationId: {
      type: String,
      required: true
    }
  },
  setup() {
    const file = ref<HTMLInputElement | null>(null)
    return {
      file
    }
  },
  data() {
    return {
      adminActionPending: false,
      uploadPending: false,
      uploadSuccessful: false,
      uploadFailureMessage: null as string | null,
      maxFileSize: 30000000
    }
  },
  computed: {
    approved(): boolean {
      const completedSteps = this.permutationData?.steps_completed
      return (completedSteps || []).some((step) => step === APPROVED)
    },
    badgeData() {
      if (this.permutationData === null) {
        return { variant: 'info', text: 'Loading...' }
      } else if (this.approved) {
        return { variant: 'success', text: 'Approved' }
      } else {
        return { variant: 'warning', text: 'Awaiting Approval' }
      }
    },
    badgeVariant(): string {
      return this.badgeData.variant
    },
    badgeText(): string {
      return this.badgeData.text
    },
    uploadedLinks(): Array<{ url: string; filename: string }> {
      const files = this.permutationData?.urls || []
      return files.map((file) => {
        const url = file.url
        return { url, filename: filenameFromUrl(file.url) }
      })
    },
    permutationData(): Permutation | null {
      return getCurrentPartnerPermutation(this.permutationId)
    },
    userIsAdmin(): boolean {
      return isAdminUser()
    }
  },
  methods: {
    async tryUploadFile(file: File) {
      await uploadProof({
        file,
        permutationId: this.permutationId
      })
    },
    async approveProof() {
      if (this.permutationData === null) return
      this.adminActionPending = true
      await updateStepsCompleted({
        permutationId: this.permutationId,
        stepsCompleted: [...this.permutationData.steps_completed, APPROVED]
      })
      this.adminActionPending = false
    },
    async rejectProof() {
      if (this.permutationData === null) return
      this.adminActionPending = true
      // removes digiProofApprovedStatus from array
      const updatedSteps = [...this.permutationData.steps_completed].filter(
        ($) => $ !== APPROVED
      )
      await updateStepsCompleted({
        permutationId: this.permutationId,
        stepsCompleted: updatedSteps
      })
      this.adminActionPending = false
    }
  }
})
</script>

<style scoped lang="scss">
.digital-proofing-root {
  padding: 1rem 2rem;
}
.digital-proofing-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1rem;
}
.digital-proofing-header > * {
  margin: 0;
  text-transform: capitalize;
}
.file-card {
  display: grid;
  grid-template-columns: auto 1fr auto;
  border-radius: 0.5rem;
  border: 1px solid $gray-fog;
  margin: 1rem 0;
  padding: 0.5rem 1.5rem;
  column-gap: 1rem;
}
.file-card > * {
  margin: auto 0;
}
</style>
