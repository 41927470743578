<template>
  <div id="form" class="grid grid-cols-2 pl-4 pr-4">
    <Dropdown
      id="missCategory"
      v-model="selectedCat"
      class="ml-1 mb-3 mt-2 pr-4"
      :options="missCats"
      label="Miss Category:"
      placeholder="Select..."
      @update:modelValue="handleMissCategory"
    />
    <Dropdown
      v-if="missSubcats.length !== 0"
      id="missSubCategory"
      v-model="selectedSubCat"
      class="ml-1 mb-3 mt-2 pr-2"
      :options="missSubcats"
      label="Miss SubCategory:"
      placeholder="Select..."
      @update:modelValue="handleMissSubCategory"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { SLA_CONSTS } from '@/consts'

export default defineComponent({
  name: 'MissFormField',
  props: {
    missCategory: {
      type: [String],
      default: ''
    },
    missSubCategory: {
      type: [String],
      default: ''
    },
    handleDropdownValue: {
      type: [Function],
      default: () => null
    }
  },
  emits: ['update:missCategory', 'update:missSubCategory'],
  data() {
    return {
      missCats: SLA_CONSTS.missCategories,
      missSubcats: [],
      selectedCat: '',
      selectedSubCat: ''
    }
  },
  methods: {
    handleMissCategory(e: any) {
      const category = this.optionToSnakeCase(e)
      this.missSubcats = e === 'N/A' ? [] : SLA_CONSTS[`miss_${category}`]
      this.$emit('update:missCategory', category)

      // if selected option is 'N/A', also make subcat = 'N/A' so isComplete = true on slaMissQuality component
      if (e === 'N/A') {
        this.handleDropdownValue({
          miss_category: category,
          miss_sub_category: 'N/A',
          quality_category: '',
          quality_sub_category: '',
          is_miss: true,
          has_quality_issue: false
        })
      } else {
        this.handleDropdownValue({
          miss_category: category,
          miss_sub_category: '',
          quality_category: '',
          quality_sub_category: '',
          is_miss: true,
          has_quality_issue: false
        })
      }
    },
    handleMissSubCategory(e: any) {
      const subcategory = this.optionToSnakeCase(e)
      this.$emit('update:missSubCategory', subcategory)

      this.handleDropdownValue({
        miss_category: this.selectedCat,
        miss_sub_category: subcategory,
        quality_category: '',
        quality_sub_category: '',
        is_miss: true,
        has_quality_issue: false
      })
    },
    optionToSnakeCase(s: string) {
      return s
        .toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll('-', '_')
        .replaceAll('/', '_')
    }
  }
})
</script>

<style lang="scss" scoped></style>
