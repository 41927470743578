<template>
  <div class="content p-5">
    <h2>Company Info</h2>
    <p class="mt-3">Please enter this information about your agency.</p>
    <TimezoneForm :partner-id="partnerId" />
    <HolidayForm :partner-id="partnerId" />
    <CridForm :partner-id="partnerId" />
    <Zipcode :partner-id="partnerId" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import TimezoneForm from '@/components/Onboarding/CompanyInfo/TimezoneForm.vue'
import HolidayForm from '@/components/Onboarding/CompanyInfo/HolidayForm.vue'
import CridForm from '@/components/Onboarding/CompanyInfo/CridForm.vue'
import Zipcode from '@/components/Onboarding/CompanyInfo/Zipcode.vue'

type Crid = {
  value: string | null
  errorMessage: string | null
  errorType: string
  pending: boolean
}

export default defineComponent({
  name: 'CompanyInfo',
  components: {
    TimezoneForm,
    CridForm,
    HolidayForm,
    Zipcode
  },
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      crid: {
        value: '',
        errorMessage: null,
        errorType: 'error',
        pending: false
      } as Crid
    }
  },
  methods: {
    holidayId(holiday) {
      return holiday.replace(/ /g, '_').toLowerCase()
    }
  }
})
</script>

<style scoped lang="scss">
.holidays {
  font-size: 12px;
}
.inlineButton {
  margin-top: 30px;
}
</style>
