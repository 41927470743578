<template>
  <div>
    <Caption>Schedule time</Caption>
    <div id="dateTime">
      <TextInput
        id="testTime"
        v-model="testTime"
        data-testid="testTime"
        type="time"
        label="Schedule time"
        :sr-only-label="true"
      />

      <Caption id="dateTimeCaption">PT on</Caption>

      <DateInput
        id="testDate"
        v-model="testDate"
        v-model:open="testDateOpen"
        label="Test date"
        :sr-only-label="true"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { set as setDate, getHours, getMinutes } from 'date-fns'
export default defineComponent({
  name: 'EndToEndTesting',
  props: {
    modelValue: {
      type: Date,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      testDateOpen: false
    }
  },
  computed: {
    testDate: {
      get() {
        return this.modelValue
      },
      set(newDate) {
        const combined = this.combineDateTime(this.testTime, newDate)
        this.$emit('update:modelValue', combined)
      }
    },
    testTime: {
      get() {
        return `${String(getHours(this.modelValue)).padStart(2, 0)}:${String(
          getMinutes(this.modelValue)
        ).padStart(2, 0)}`
      },
      set(timeString) {
        const combined = this.combineDateTime(timeString, this.testDate)
        this.$emit('update:modelValue', combined)
      }
    }
  },
  methods: {
    combineDateTime(timeString, date) {
      const [hr, min] = timeString.split(':')
      const combined = setDate(date, { hours: hr, minutes: min })
      return combined
    }
  }
})
</script>

<style lang="scss">
#dateTime {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-end;
  column-gap: 1em;

  #dateTimeCaption {
    line-height: 3em;
  }
}
</style>
