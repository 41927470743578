<template>
  <LoadingIndicator>
    <div v-if="!isLoading" class="content p-5">
      <PortalBreadCrumb />
      <div class="flex">
        <div class="text-3xl mb-20 ml-10 font-bold">Global Attributes</div>
      </div>
      <Card>
        <div class="flex">
          <div class="text-2xl mb-10">Attributes List</div>
          <Tooltip position="right">
            <template #trigger>
              <Info class="ml-1 w-5 h-5" />
            </template>
            <template #content>
              {{ attributesListTooltip }}
            </template>
          </Tooltip>
          <div class="flex-1" />
          <PackageButton
            class="h-12"
            @click="openAttributeModal('create_attribute', null)"
          >
            New Attribute
          </PackageButton>
        </div>
        <div>
          <div class="grid grid-cols-6 p-2">
            <div class="items-baseline pl-3 pb-2 col-span-4">Name</div>
          </div>
        </div>
        <div
          v-for="(attribute, index) in attributeFields"
          :key="attribute.id"
          class="mb-1 shadow-md shadow-black w-full"
        >
          <div
            class="bg-mist py-4 px-4 shadow-md w-full pb-10 flex cursor-pointer rounded-md"
            @click="toggleAttribute(index)"
          >
            {{ attribute.name }}
            <div class="flex-1" />
            <ChevronUp v-if="attribute.isExpanded" class="w-5" />
            <ChevronDown v-else class="w-5" />
          </div>
          <div v-if="attribute.isExpanded" class="flex">
            <InfoPanel :data="attribute.infoPanel" class="m-4" />
            <Edit
              class="w-6 self-start m-4 cursor-pointer opacity-40"
              @click="openAttributeModal('edit_attribute', attribute)"
            />
          </div>
          <div v-if="attribute.isExpanded" class="h-2" />
        </div>
      </Card>
    </div>
    <AttributeModal
      :data="attributeData"
      :mode="attributeModalMode"
      :is-open="attributeModalIsOpen"
      @close="closeAttributeModal"
    />
  </LoadingIndicator>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { isAdminOrPopsUser } from '@/store/session'
import {
  getCurrentUserAction,
  getComponentPageID,
  getAttributes,
  attributeHasApprovedProduct
} from '@/store/customMail'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import AttributeModal from '../common/AttributeModal.vue'
import PackageButton from '../common/PackageButton.vue'
import InfoPanel from '../common/InfoPanel.vue'
import PortalBreadCrumb from '../../../../../components/common/PortalBreadcrumb.vue'

export default defineComponent({
  name: 'ComponentPage',
  components: {
    AttributeModal,
    PackageButton,
    InfoPanel,
    PortalBreadCrumb
  },
  data() {
    return {
      attributeModalIsOpen: false,
      attributeData: {} as any,
      isLoading: true,
      attributeFields: [] as any[],
      attributeModalMode: '',
      attributesListTooltip: CUSTOM_MAIL_CONSTS.ATTRIBUTES_LIST_TOOLTIP
    }
  },
  computed: {
    componentID() {
      return getComponentPageID() as string
    },
    isAdminOrPopsUser() {
      return isAdminOrPopsUser()
    },
    mode() {
      if (!getCurrentUserAction()) return 'Unauthorized'
      return getCurrentUserAction()?.split('-')[2]
    }
  },
  async mounted() {
    const attributeResp = await getAttributes()
    this.attributeFields = attributeResp.data
    this.formatAttributes(this.attributeFields)
    this.isLoading = false
  },
  methods: {
    async openAttributeModal(mode: string, attribute: any) {
      const hasApprovedProductResp = await attributeHasApprovedProduct({
        fieldID: attribute.id
      })
      attribute.hasApprovedProduct = hasApprovedProductResp.has_approved_product
      this.attributeData = attribute ?? {}
      if (mode === CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_EDIT_MODE) {
        this.attributeData.data_type =
          CUSTOM_MAIL_CONSTS.ATTRIBUTE_DATA_TYPE_VALUES.find(
            (el) => el.value === attribute.data_type
          )
        this.attributeData.field_type =
          CUSTOM_MAIL_CONSTS.ATTRIBUTE_FIELD_TYPE_VALUES.find(
            (el) => el.value === attribute.field_type
          )
      }
      this.attributeData.componentID = this.componentID
      this.attributeModalMode = mode
      this.attributeModalIsOpen = true
    },
    async closeAttributeModal(data: any) {
      this.attributeModalIsOpen = false
      if (data.refreshComponent) {
        const attributeResp = await getAttributes()
        this.attributeFields = attributeResp.data
        this.formatAttributes(this.attributeFields)
      }
    },
    formatAttributes(attributeFields: Array<any>) {
      const infoPanel = CUSTOM_MAIL_CONSTS.ATTRIBUTE_FIELD_INFO_PANEL
      this.attributeFields = attributeFields.map((attribute) => {
        attribute.infoPanel = {}
        attribute.isExpanded = false
        for (const key in infoPanel) {
          attribute.infoPanel[key] = {
            displayName: infoPanel[key].displayName,
            value: ''
          }
          attribute.infoPanel[key].value = attribute[key] ?? 'No Value'
        }
        return attribute
      })
    },
    toggleAttribute(index: number) {
      this.attributeFields[index].isExpanded =
        !this.attributeFields[index].isExpanded
    }
  }
})
</script>

<style lang="scss" scoped></style>
