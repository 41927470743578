<script lang="ts">
/* eslint-disable camelcase */
import { defineComponent, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { formatDate } from '@/utils/formatters'
import { isEmpty } from '@/utils/objects'
import InventoryHeader from '../common/InventoryHeader.vue'
import useChangelogs from '@/composables/useChangelogs'

const PAGE_SIZE = 20

export default defineComponent({
  name: 'InventoryChangelogs',
  components: { InventoryHeader },
  setup() {
    const route = useRoute()
    const { errorMessage, fetchChangelogs, isLoading, changelogs, page } =
      useChangelogs(PAGE_SIZE)

    const onChangePage = (p: { page: number }) => {
      page.value = p.page

      fetchChangelogs(
        route.params?.crid as string,
        route.params?.resource as string,
        route.query
      )
    }

    onMounted(() => {
      watch(
        route,
        ({ params, query }) => {
          if (!params?.crid) return
          fetchChangelogs(
            params.crid as string,
            params.resource as string,
            query
          )
        },
        {
          immediate: true
        }
      )
    })

    return {
      errorMessage,
      changelogs,
      columns: [
        'User ID',
        'Partner ID',
        'Materiel ID',
        'Materiel Order ID',
        'Change Type',
        'Delta',
        'Note',
        'Created At'
      ],
      isLoading,
      isEmpty,
      formatDate,
      onChangePage,
      page,
      PAGE_SIZE,
      route
    }
  }
})
</script>

<template>
  <div class="content p-5">
    <InventoryHeader subject="Change Log" />

    <Alert v-if="errorMessage?.length" variant="error">
      {{ errorMessage }}
    </Alert>

    <div
      v-if="!isEmpty(route.query) && !isLoading"
      class="text-lg font-normal mt-4"
    >
      Showing {{ changelogs.count }} results where
      <span
        v-for="(value, key) in route.query"
        :key="key"
        class="text-gray-300"
      >
        {{ key }}
        =
        <span>"{{ value }}"</span>
      </span>
    </div>

    <div class="relative overflow-x-auto pt-4">
      <LoadingIndicator>
        <table v-if="!isLoading" class="table">
          <thead>
            <tr>
              <th
                v-for="key in columns"
                :key="key"
                class="!border-t-0 text-gray-900 font-normal text-base"
              >
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in changelogs.data" :key="item.created_at">
              <td>
                {{ item.user_id }}
                <div class="text-sm text-gray-300">{{ item.user_email }}</div>
              </td>
              <td>{{ item.partner_id }}</td>
              <td>
                <Tooltip position="top">
                  <template #trigger>
                    <RouterLink :to="`./?resource_id=${item.materiel_id}`">
                      {{ item.materiel_id }}
                    </RouterLink>
                  </template>
                  <template #content>
                    <div>
                      Materiel Inventory ID: {{ item.materiel_inventory_id }}
                    </div>
                  </template>
                </Tooltip>
              </td>
              <td>
                {{ item.materiel_order_id }}
              </td>
              <td>{{ item.change_type }}</td>
              <td>{{ item.num_remaining }}</td>
              <td>{{ item.note }}</td>
              <td>
                {{
                  formatDate(item.created_at, {
                    timeZone: 'America/Los_Angeles',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  })
                }}
              </td>
            </tr>

            <tr v-if="changelogs.count === 0 || changelogs.data?.length === 0">
              <td :colSpan="columns.length" class="text-lg text-center">
                No data available
              </td>
            </tr>
          </tbody>
        </table>
      </LoadingIndicator>
      <Pagination
        data-testid="pagination"
        :collection="changelogs.data"
        :page="page"
        :limit="PAGE_SIZE"
        :total="changelogs.count"
        @change="onChangePage"
      />
    </div>
  </div>
</template>
