<template>
  <div>
    <div>
      <div class="grid grid-cols-6 p-2">
        <div class="items-baseline pl-3 pb-2 col-span-4">Partner ID</div>
        <div class="items-baseline pl-3 pb-2">Status</div>
        <div class="items-baseline pl-3 pb-2">Date</div>
      </div>
    </div>
    <div
      v-for="partner in bidsByPartner"
      :key="partner.partner_id"
      class="mb-1 shadow-md shadow-black"
    >
      <ManageBidsTableRow
        :partner="partner"
        :bids-of-partner="bidsByPartner[partner.partner_id]"
        :is-admin-or-pops-user="isAdminOrPopsUser"
        @openAdminEditModal="openAdminEditModal"
        @update-status="updateStatus"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ManageBidsTableRow from './ManageBidsTableRow.vue'

export default defineComponent({
  name: 'ManageBidsTable',
  components: {
    ManageBidsTableRow
  },
  props: {
    isAdminOrPopsUser: {
      type: Boolean,
      required: true
    },
    bidsByPartner: {
      type: Object,
      required: true
    },
    allBiddingPartners: {
      type: Array,
      required: true
    }
  },
  emits: ['openAdminEditModal', 'updateStatus'],
  computed: {
    tableHeaders(): Array<any> {
      return this.isAdminOrPopsUser
        ? [
            { name: 'Partner ID', span: 1 },
            { name: '', span: 1 },
            { name: '', span: 1 },
            { name: '', span: 1 },
            { name: 'Status', span: 1 },
            { name: 'Date', span: 1 }
          ]
        : [
            { name: 'Quantity', span: 1 },
            { name: 'Total Price (USD)', span: 1 },
            { name: 'Lead Time (Days)', span: 1 }
          ]
    }
  },
  methods: {
    openAdminEditModal(partner) {
      this.$emit('openAdminEditModal', partner)
    },
    updateStatus(partnerId, packageData) {
      this.$emit('updateStatus', partnerId, packageData)
    }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
