<template>
  <div class="row mt-5 contentSection">
    <div class="col-3">
      <p class="sectionTitle mb-2">
        {{ label }}
      </p>
      <p v-if="description" class="text-sm text-gray-200">
        {{ description }}
      </p>
    </div>
    <div class="col-9">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FieldSet',
  props: {
    label: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  }
})
</script>
