class AxiosCustomError extends Error {
  response: any | undefined
}

export const MASQUERADE_ATTEMPT_ERROR = (): AxiosCustomError => {
  const err = new AxiosCustomError()
  err.response = {
    status: 403,
    data: {
      error: {
        message:
          'Masquerade mode is read only. Please log out of masqeurade to make changes to your own partner.'
      }
    }
  }
  return err
}

export const UNAUTHORIZED_LOB_API_V2_ERROR = (): AxiosCustomError => {
  const err = new AxiosCustomError()
  err.response = {
    status: 403,
    data: {
      error: {
        message: 'You are not authorized to make this request.'
      }
    }
  }
  return err
}
