<template>
  <div id="box">
    <slot />
  </div>
</template>

<style scoped lang="css">
#box {
  margin: 2rem auto;

  width: min(30rem, 90%);
  padding: 2rem;
  border-radius: 1rem;

  background-color: white;
  box-shadow: 0 5px 14px rgba(44, 67, 81, 0.13), 0 0 4px rgba(44, 67, 81, 0.02);
}

#box > :first-child {
  margin-top: 0;
}
#box > :first-child > :first-child {
  margin-top: 0;
}

#box > :last-child {
  margin-bottom: 0;
}
#box > :last-child > :last-child {
  margin-bottom: 0;
}
</style>
