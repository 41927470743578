<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h4>Create New Test Case</h4>
    </div>
    <PackageTextInput
      id="test-case-name"
      v-model:value="name"
      label="Name: "
      size="small"
      placeholder="Input Name..."
    />
    <PackageTextInput
      id="description"
      v-model:value="testCaseDescription"
      label="Description:"
      input-class="w-full"
      placeholder="Add a description..."
    />
    <SearchAndSelect
      label="Product ID:"
      class="mb-2"
      :search-function="handleSelectSearch"
      :selected-item-display-property="'label'"
      :search-delay="600"
      @onSelect="handleSelect"
      @onDeselect="handleDeselect"
    >
      <template #default="{ result }">
        <td>
          {{ result.label }}
        </td>
      </template>
    </SearchAndSelect>
    <div v-if="selectedProduct">
      Associated Components:
      <Table
        id="newTestCaseModal"
        sortable="false"
        :headers="componentTableHeaders"
        :row-object="componentRowObject"
        :data="componentsFromProductList"
        :secondary-row-color="'bg-offWhite'"
        @handleValueChange="handleValueChange"
      />
    </div>

    <!-- <div>
      <label>
        Upload a file:
      </label>
      <LobFileUpload
        :do-try-upload="(f) => tryUploadFile(f)"
        @uploadSuccess="onUploadSuccess($event)"
      />
    </div> -->

    <div class="flex items-end -mb-10 mt-3">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton @click="createNewTestCase">Create</LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SearchAndSelect from '@/components/common/SearchAndSelect.vue'
import {
  getPackageComponents,
  getMorePackages,
  createNewTestCase,
  SearchLabel
} from '@/store/customMail'
import PackageTextInput from '../../customMail/common/PackageTextInput.vue'
import Table from '@/components/common/table/Table.vue'

export default defineComponent({
  name: 'TestCaseModal',
  components: {
    SearchAndSelect,
    PackageTextInput,
    Table
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['close', 'attemptUpload', 'update:modelValue'],
  data() {
    return {
      name: '',
      testCaseDescription: '',
      selectedProduct: null as any,
      productList: [] as SearchLabel[],
      componentsFromProductList: [] as any[],
      files: [] as any[],
      componentType: { label: '', value: '' },
      showComponentTable: false,
      componentTableHeaders: [
        { name: 'ID', span: 1, width: '3/12' },
        { name: 'Description', span: 1, width: '5/12' },
        { name: 'Required', width: '2/12' },
        { name: 'Creative', span: 1, width: '2/12' }
      ],
      componentRowObject: [
        {
          fieldName: 'component_id',
          type: 'string'
        },
        {
          fieldName: 'description',
          type: 'string'
        },
        {
          fieldName: 'isJustInTime',
          type: 'boolean'
        },
        {
          children: [
            {
              fieldName: 'file',
              type: 'image',
              inputEnabled: true
            },
            {
              fieldName: 'reupload',
              type: 'image',
              inputEnabled: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    close() {
      this.selectedProduct = null
      this.$emit('close', {})
    },
    async handleSelectSearch(term) {
      const products = await getMorePackages({
        url: `/products?limit=100&search=${term}`
      })
      this.productList = products.data.map((product) => {
        return {
          label: `${product.id}: ${product.title}`,
          value: product.id
        }
      })
      return this.productList
    },
    async handleSelect(result) {
      this.selectedProduct = result
      const productID = result.value
      const associatedComponents = await getPackageComponents({
        packageID: productID
      })
      this.componentsFromProductList = await Promise.all(
        associatedComponents.data.map(async (component): Promise<any> => {
          // make just_in_time components required
          const associatedProduct = component.products.find(
            (product) => product.product_id === productID
          )
          const isJustInTime =
            associatedProduct &&
            associatedProduct.inventory_type === 'just_in_time'
          return {
            component_id: component.id,
            title: component.title,
            description: component.description,
            file: {
              linkTo: undefined,
              icon: 'upload',
              actionRequired: true,
              actionCompleted: false,
              isInput: true
            },
            reupload: {
              icon: 'refresh',
              isRefresh: false
            },
            overwrite: false,
            isJustInTime
          }
        })
      )
      this.files = new Array(this.componentsFromProductList.length).fill(null)
      this.showComponentTable = true
    },
    async tryUploadFile(file: File) {
      this.$emit('attemptUpload', file)
    },
    onUploadSuccess(uploaded: any) {
      this.$emit('update:modelValue', uploaded.url)
    },
    handleDeselect() {
      this.selectedProduct = null
    },
    async createNewTestCase() {
      // stringify components to send in form data
      const formattedComponents = JSON.stringify(
        this.componentsFromProductList.map((comp) => {
          return {
            component_id: comp.component_id,
            overwrite: comp.overwrite
          }
        })
      )
      await createNewTestCase({
        name: this.name,
        description: this.testCaseDescription,
        product_id: this.selectedProduct!.value,
        components: formattedComponents,
        files: this.files
      })
      this.$emit('close', { refreshData: true })
    },
    handleValueChange(e) {
      this.files[e.rowData.index] = e.value
      this.componentsFromProductList[e.rowData.index].overwrite = true
      this.componentsFromProductList[e.rowData.index].file.actionCompleted =
        true
      this.componentsFromProductList[e.rowData.index].reupload.isRefresh = true
    }
  }
})
</script>
