<template>
  <div class="p-8">
    <ul>
      <li>
        <fa icon="chevron-right" class="mr-2" />
        <router-link to="forms/rejectedPdf">Rejected PDF</router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormsList'
})
</script>
