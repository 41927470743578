import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editorComponent), {
    schema: _ctx.schema,
    type: _ctx.type,
    "model-value": _ctx.modelValue,
    "form-factor": _ctx.formFactor,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, null, 8, ["schema", "type", "model-value", "form-factor"]))
}