<template>
  <div class="content p-5">
    <P v-if="currentTestSet === null" data-testid="loading">
      <loading-indicator></loading-indicator>
    </P>

    <template v-else>
      <div class="w-full mb-1">
        <h3>Create New Test Set</h3>
      </div>
      <div
        class="rounded-tl-sm rounded-tr-sm text-black h-12 text-l flex items-center"
      >
        Customize your test sets
      </div>
      <br />
      <Stepper class="relative">
        <StepperItem
          position="first"
          :active="true"
          :finished="true"
          class="progressBar"
        >
          <div class="text-center">1) General Info</div>
        </StepperItem>
        <StepperItem
          position="middle"
          :active="true"
          :finished="true"
          class="progressBar"
        >
          <div class="text-center">2) Form Factor Info</div>
        </StepperItem>
        <StepperItem
          position="last"
          :active="true"
          :finished="false"
          class="progressBar"
        >
          <div class="text-center">3) Confirm</div>
        </StepperItem>
      </Stepper>
      <div
        class="bg-white-300 rounded-tl-sm rounded-tr-sm pl-6 text-black h-12 text-l flex items-center"
      >
        CREATE TEST SET
      </div>
      <div class="bg-white-200">
        <div class="pl-20 pr-20 pt-10 pb-10 font-bold">
          You are creating {{ calculatedTestObjects }} Test Objects for this
          test set.
        </div>
        <div class="pl-20 pr-20 pb-10 item">
          <button
            class="mt-2 mb-8 text-sm underline"
            @click="isModalVisible = true"
          >
            How is this number calculated?
          </button>
          <Modal
            width="500px"
            :visible="isModalVisible"
            @close="isModalVisible = false"
          >
            <template #header>
              <h4>How is this number calculated?</h4>
            </template>
            <div class="p-0 m-0">
              Every option selected is part of a new variant test object that
              becomes part of the greater test set collection. For example, if
              you choose one sender and two recipients, there will be two test
              objects(because there will be sender A x recipient A and also
              sender A x recipient B).
            </div>
          </Modal>
        </div>
      </div>
      <div class="flex justify-between mt-2">
        <LobButton
          variant="secondary"
          class="flex justify-end"
          @click="cancel()"
        >
          Cancel
        </LobButton>
        <div class="flex justify-end">
          <LobButton
            variant="secondary"
            class="mr-2"
            @click="switchPage('back')"
          >
            <ArrowLeft class="w-5 h-5 text-primary mr-1" />
            BACK
          </LobButton>
          <LobButton @click="create">CREATE</LobButton>
        </div>
      </div>
      <Alert v-if="isError" variant="error" class="mt-2 col-9 item">
        {{ errorMessage }}
      </Alert>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, Prop } from '@vue/runtime-core'
import router from '@/router'
import { createTestSet, TestSet } from '@/store/testSets'
import { TEST_SET_WIZARD_PAGE } from '@/consts'
// import error from '@/pages/sessionless/error'

export default defineComponent({
  name: 'CreateConfirmation',
  props: {
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>
  },
  emits: ['updateCurrentPage'],
  data() {
    return {
      currentTestSet: null as TestSet | null,
      calculatedTestObjects: 0,
      currentPage: TEST_SET_WIZARD_PAGE.CREATE_CONFIRMATION,
      savePending: false,
      isModalVisible: false,
      isError: false,
      errorMessage: null as any,
      pageUnloadListener: (e) => {
        if (this.hasChanges) {
          const message =
            'You have unsaved changes. Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      }
    }
  },
  mounted() {
    this.currentTestSet = this.testSet as TestSet
    for (const field of this.currentTestSet.fields.form_factor as any) {
      this.calculatedTestObjects += this.calculateFormPermutations(
        this.currentTestSet.fields[field][0]
      )
    }
    this.calculatedTestObjects *=
      this.currentTestSet.fields.to.length *
      this.currentTestSet.fields.from.length
    window.addEventListener('beforeunload', this.pageUnloadListener)
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.pageUnloadListener)
  },
  methods: {
    async create() {
      try {
        if (this.currentTestSet !== null) {
          this.savePending = true
          await createTestSet(this.currentTestSet)
          router.push('/partner_ops/test-sets')
        }
      } catch (error: any) {
        this.isError = true
        // Format an error message if unsuccessful.
        const eResp = error.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
    },
    switchPage(direction) {
      if (direction === 'back') {
        this.$emit(
          'updateCurrentPage',
          TEST_SET_WIZARD_PAGE.FORM_FACTOR_SELECTION
        )
      }
    },
    cancel() {
      if (this.currentTestSet !== null) {
        this.currentTestSet = null as TestSet | null
        router.push('/partner_ops/test-sets')
      }
    },
    calculateFormPermutations(factor) {
      let objectNumber = 1
      for (const field of Object.keys(factor)) {
        const fieldValuesLength = factor[field].length
        if (fieldValuesLength !== 0) objectNumber *= fieldValuesLength
      }
      return objectNumber
    }
  }
})
</script>

<style lang="scss" scoped>
.content {
  width: 1000px;
}
.progressBar {
  width: 33%;
  min-width: 100px;
}
</style>
