import { ref, Ref } from 'vue'

import { captureException } from '@/plugins/sentry'
import { sendPapiRequest } from '@/store/requests'
import { downloadString } from '@/utils/browser'

export const useDownloadCSV = (): {
  error: Ref<string>
  downloadCSV: (testId: string) => void
} => {
  const error = ref('')
  const downloadCSV = async (testId: string) => {
    try {
      error.value = ''
      const { data } = await sendPapiRequest({
        path: `/tests/${testId}/orders/export`,
        method: 'GET'
      })
      downloadString(
        encodeURI(data.replaceAll('\\u0026', '&')),
        `export-orders-${testId}.csv`,
        false,
        'text/csv'
      )
    } catch (err) {
      captureException(err)

      error.value =
        err?.response?.data?.error?.code ===
        'get_orders_test_id_export_404_export_orders_not_found'
          ? 'There are no resources for given test'
          : 'Failed to generate CSV'
    }
  }

  return { error, downloadCSV }
}
