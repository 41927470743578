<script lang="ts">
import { Partner } from '@/store/partners'
import { defineComponent, PropType } from 'vue'
import GrayCard from '@/components/common/GrayCard.vue'
import { capitalize } from '@/utils/formatters'
import HomeAssistance from '@/components/Home/Assistance.vue'

export default defineComponent({
  name: 'HomeAside',
  components: {
    GrayCard,
    HomeAssistance
  },
  props: {
    partner: {
      type: Object as PropType<Partner>,
      required: true
    }
  },
  setup() {
    return { capitalize }
  }
})
</script>

<template>
  <GrayCard title="Company info" :value="partner.display_name" />
  <GrayCard title="CRID" :value="partner.crid" />
  <GrayCard title="Timezone" :value="partner.timezone" />
  <GrayCard title="Zip Code" :value="partner.zipcode" />
  <GrayCard title="Holidays">
    <ul>
      <li v-for="holiday in partner.holidays" :key="holiday.name">
        {{ capitalize(holiday.name) }}
      </li>
    </ul>
  </GrayCard>
  <HomeAssistance />
</template>
