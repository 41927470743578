<template>
  <div>
    <LoadingIndicator>
      <div v-if="!isLoading">
        <div v-if="errorMessage !== null">
          <Alert variant="error">{{ errorMessage }}</Alert>
        </div>
        <div v-else>
          <div
            v-for="product in productAbilities"
            :key="product.id"
            class="mb-1 shadow-md shadow-black"
          >
            <ProductsTableRow
              :partner-id="partnerId"
              :partner-facility-id="partnerFacilityId"
              :product="product"
              :available-abilities="
                abilitiesByProduct[product.order_field_value]?.available || []
              "
              :unavailable-abilities="
                abilitiesByProduct[product.order_field_value]?.unavailable || []
              "
              :refresh-data="refreshData"
            />
          </div>
        </div>
      </div>
    </LoadingIndicator>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  Ability,
  PartnerFacilityAbility,
  getPartnerFacilityAbilities,
  getAllAbilities,
  refreshAllAbilities
} from '@/store/partners'
import ProductsTableRow from './ProductsTableRow.vue'

interface AbilitiesByProduct {
  [productName: string]: {
    available: PartnerFacilityAbility[]
    unavailable: Ability[]
  }
}

export default defineComponent({
  name: 'ProductsTable',
  components: {
    ProductsTableRow
  },
  props: {
    partnerId: {
      type: String,
      required: true
    },
    partnerFacilityId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      productAbilities: [] as PartnerFacilityAbility[],
      abilitiesByProduct: {} as AbilitiesByProduct,
      isLoading: false,
      errorMessage: null as string | null
    }
  },
  async mounted() {
    this.isLoading = true

    try {
      await this.refreshData()
    } catch (e) {
      this.errorMessage = String(e)
    }

    this.isLoading = false
  },
  methods: {
    async refreshData() {
      await refreshAllAbilities()
      const allAbilities = getAllAbilities()

      const productAbilities = [] as PartnerFacilityAbility[]
      const abilitiesByProduct = {} as AbilitiesByProduct

      // availableAbilities are abilities currently possessed by the partner facility (whether active or not)
      const availableAbilities = await getPartnerFacilityAbilities({
        partnerId: this.partnerId,
        partnerFacilityId: this.partnerFacilityId
      })
      availableAbilities.forEach((availableAbility) => {
        if (availableAbility.order_field_name === 'object') {
          productAbilities.push(availableAbility)
          abilitiesByProduct[availableAbility.order_field_value] = {
            available: [],
            unavailable: []
          }
        }
      })

      // sort each non-product ability into a product (can be multiple) and available/not_available
      for (let i = 0; i < allAbilities.length; i += 1) {
        const ability = allAbilities[i]
        if (ability.order_field_name === 'object') {
          continue
        }

        for (let j = 0; j < productAbilities.length; j += 1) {
          const productAbility = productAbilities[j]
          // the ability must have the matching product tag to be included in its inner table
          if (!ability.tags?.includes(productAbility.order_field_value)) {
            continue
          }

          const [matchingPartnerFacilityAbility] = availableAbilities.filter(
            ({ id }) => id === ability.id
          )
          if (matchingPartnerFacilityAbility) {
            abilitiesByProduct[productAbility.order_field_value].available.push(
              matchingPartnerFacilityAbility
            )
          } else {
            abilitiesByProduct[
              productAbility.order_field_value
            ].unavailable.push(ability)
          }
        }
      }

      // sort each array of abilities by order_field_name then order_field_value
      for (const product in abilitiesByProduct) {
        abilitiesByProduct[product].available.sort(
          (a, b) =>
            a.order_field_name.localeCompare(b.order_field_name) ||
            JSON.stringify(a.order_field_value).localeCompare(
              JSON.stringify(b.order_field_value)
            )
        )
        abilitiesByProduct[product].unavailable.sort(
          (a, b) =>
            a.order_field_name.localeCompare(b.order_field_name) ||
            JSON.stringify(a.order_field_value).localeCompare(
              JSON.stringify(b.order_field_value)
            )
        )
      }

      this.productAbilities = productAbilities
      this.abilitiesByProduct = abilitiesByProduct
    }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
