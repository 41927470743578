import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 pl-4 pr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      id: "qualCategory",
      modelValue: _ctx.selectedCat,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCat) = $event)),
        _ctx.handleQualityCategory
      ],
      class: "ml-1 mb-3 mt-2 pr-4",
      options: _ctx.qualCats,
      label: "Quality Category:",
      placeholder: "Select..."
    }, null, 8, ["modelValue", "options", "onUpdate:modelValue"]),
    (_ctx.qualSubcats.length !== 0)
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 0,
          id: "qualSubcategory",
          modelValue: _ctx.selectedSubCat,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSubCat) = $event)),
            _ctx.handleQualitySubCategory
          ],
          class: "ml-1 mb-3 mt-2",
          options: _ctx.qualSubcats,
          label: "Quality Subcategory:",
          placeholder: "Select..."
        }, null, 8, ["modelValue", "options", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ]))
}