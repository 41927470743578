<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="content p-5">
    <h2>Test Sets</h2>
    <p class="mt-3">Please enter this information about your agency.</p>

    <P v-if="currentTestSet === null || schema === null" data-testid="loading">
      <loading-indicator></loading-indicator>
    </P>

    <template v-else>
      <FieldSet label="General information">
        <FieldSetRow>
          <TextInput id="name" v-model="currentTestSet.name" label="Name" />
          <Alert v-if="nameCollision" variant="error" class="mt-1">
            A test set with that name already exists.
          </Alert>
        </FieldSetRow>
        <FieldSetRow class="mt-2">
          <Textarea
            id="description"
            v-model="currentTestSet.description"
            label="Description"
            input-class="w-full"
          />
        </FieldSetRow>
      </FieldSet>
      <FieldSet
        label="Test"
        description="Individual objects are created for every possible combination of values
        you pick. For example, if you speficy 5 from addresses, 2 sidedness
        values, and 1 mail type, then 5*2*1=10 objects will be created,
        collected into a test case with the name you provide."
      >
        <MultiValueEditor
          v-for="field of relevantFields"
          :key="field.id"
          v-model:testSet="currentTestSet"
          :schema="schema"
          :field="field"
        />
      </FieldSet>
      <div class="sticky bottom-0 py-2 pointer-events-none">
        <LobButton
          :disabled="!hasChanges || nameCollision || savePending"
          class="pointer-events-auto"
          @click="create"
        >
          {{ savePending ? 'Creating' : 'Create' }}
        </LobButton>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import MultiValueEditor from '@/components/TestSetEditor/multiValueEditors/MultiValueEditor.vue'
import router from '@/router'
import {
  createTestSet,
  getBaseTestSet,
  getSchema,
  getTestSets,
  TestSet
} from '@/store/testSets'
import { backfillTestSet, SchemaField } from '@/store/testSets/schema'
import { defineComponent } from '@vue/runtime-core'
import { deepCopy } from '@/utils/objects'

export default defineComponent({
  name: 'TestSetEditor',
  components: { MultiValueEditor },
  data() {
    return {
      currentTestSet: null as TestSet | null,
      savePending: false,
      pageUnloadListener: (e) => {
        if (this.hasChanges) {
          const message =
            'You have unsaved changes. Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      }
    }
  },
  computed: {
    // Unfortunately just doing schema: getSchema makes it unmockable in tests
    schema: () => getSchema(),
    baseTestSet: () => getBaseTestSet(),
    relevantFields(): Array<SchemaField> {
      const schema = this.schema
      const fields = schema?.fields || []
      const formFactors = this.currentTestSet?.fields?.form_factor || []
      return fields.filter(
        (f) =>
          f.id === 'form_factor' ||
          f.form_factors.some((ff) => formFactors.indexOf(ff) !== -1)
      )
    },
    nameCollision(): boolean {
      return getTestSets().some(
        (s) => s.name.toLowerCase() === this.currentTestSet?.name?.toLowerCase()
      )
    },
    hasChanges(): boolean {
      if (this.schema === null) return false
      return (
        JSON.stringify(this.currentTestSet) !==
        JSON.stringify(backfillTestSet(this.schema, this.baseTestSet))
      )
    }
  },
  watch: {
    baseTestSet: {
      handler() {
        this.onBaseDataChange()
      },
      immediate: true
    },
    schema: {
      handler() {
        this.onBaseDataChange()
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.pageUnloadListener)
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.pageUnloadListener)
  },
  methods: {
    async create() {
      if (this.currentTestSet !== null) {
        this.savePending = true
        await createTestSet(this.currentTestSet)
        router.push('/partner_ops/test-sets')
      }
    },
    onBaseDataChange() {
      if (this.schema === null) return
      this.currentTestSet = backfillTestSet(
        this.schema,
        deepCopy(this.baseTestSet)
      )
    }
  }
})
</script>

<style lang="scss" scoped></style>
