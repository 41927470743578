<template>
  <TextInput
    id="number"
    label="Number"
    :model-value="textValue"
    @update:modelValue="updateValue($event)"
  />
  <Alert v-if="syntaxError" variant="error">
    {{ syntaxError }}
  </Alert>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  name: 'FloatSVE',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    type: {
      type: String,
      required: true
    } as Prop<'float'>,
    modelValue: {
      type: Number,
      required: true
    } as Prop<number>,
    formFactor: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      textValue: '',
      syntaxError: null as string | null
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.textValue = `${this.modelValue}`
        this.syntaxError = null
      },
      immediate: true
    }
  },
  methods: {
    updateValue(newText: string) {
      this.textValue = newText
      const parsed = parseFloat(newText)
      if (isNaN(parsed)) {
        this.syntaxError = 'That is not a valid number'
      } else {
        this.$emit('update:modelValue', parsed)
        this.syntaxError = null
      }
    }
  }
})
</script>
