<template>
  <div class="content p-5">
    <PortalBreadCrumb />
    <PackageList />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import PackageList from './packageList/View.vue'
import PortalBreadCrumb from '../../../../components/common/PortalBreadcrumb.vue'
import { isAdminOrPopsUser } from '@/store/session'
import { setBasePath } from '@/store/customMail'
import { getCurrentPartnerId } from '@/store/partners'

export default defineComponent({
  name: 'Products',
  components: {
    PackageList,
    PortalBreadCrumb
  },
  computed: {
    isAdminOrPopsUser() {
      return isAdminOrPopsUser()
    }
  },
  mounted() {
    if (this.isAdminOrPopsUser) {
      setBasePath('partner_ops/custom-mail')
    } else {
      const currentPartner = getCurrentPartnerId()
      setBasePath(`partners/${currentPartner}/custom-mail`)
    }
  }
})
</script>

<style lang="scss" scoped></style>
