<template>
  <nav>
    <ol class="breadcrumb">
      <li
        v-for="(crumb, ci) in crumbs"
        :key="ci"
        :data-testid="crumb.name"
        class="breadcrumb-item align-items-center"
      >
        <button
          class="btn btn-link"
          :data-testid="`${crumb.name} - btn`"
          :class="{ disabled: isLast(ci) }"
          @click="selected(crumb)"
        >
          {{ crumb.name }}
        </button>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      crumbs: []
    }
  },
  watch: {
    $route() {
      this.configureBreadCrumbs()
    }
  },
  mounted() {
    this.configureBreadCrumbs()
  },
  methods: {
    configureBreadCrumbs() {
      this.crumbs = []
      const matchedRoutes = this.$route.matched
      const startingCrumb = matchedRoutes.findIndex(
        (el) => el.meta.isStartingCrumb
      )
      for (
        let i = startingCrumb === -1 ? 0 : startingCrumb;
        i < matchedRoutes.length;
        i++
      ) {
        const routeMeta = matchedRoutes[i].meta
        if (routeMeta.displayName) {
          const displayName =
            typeof routeMeta.displayName === 'function'
              ? routeMeta.displayName()
              : routeMeta.displayName
          const formattedName = routeMeta.useParam
            ? `${displayName} (${this.truncate(
                this.$route.params[routeMeta.useParam],
                15
              )})`
            : displayName
          this.crumbs.push({
            name: formattedName,
            route: matchedRoutes[i].path,
            params: this.$route.params
          })
        }
      }
    },
    isLast(index) {
      return index === this.crumbs.length - 1
    },
    selected(crumb) {
      let route = crumb.route
      for (const key in this.$route.params) {
        route = route.replace(`:${key}`, this.$route.params[key])
      }
      this.$router.push({
        path: route,
        params: crumb.params
      })
    },
    truncate(str, n) {
      return str.length > n ? `${str.slice(0, n - 1)}...` : str
    }
  }
}
/*
  This component is reliant on the matched routes in $route (this.$route.matched),
  so you will need to add your route (usually index.ts) as a child to parent routes. 
  As an example, look at src/pages/main/partnerOps/customMail/index.ts and follow the 
  child paths/routes.
  
  TO USE: 
  1. In your relavant index.ts, add a meta field object to your exported RouteRecordRaw
  2. The meta field should contain at least some of the following keys:
    a.  isStartingCrumb (Bool): This view will be the first in your breadcrumb route. There
        should only be ONE of this key for your whole breadcrumb route
    b.  displayName (string/function - REQUIRED): The displayed string in the breadcrumb route. 
        Note: This key can be either a function (for conditional display names) or a string -
        (function example: src/pages/main/partnerOps/customMail/packagePage/index.ts)
    c.  useParam (string): param ID to be used as additional info in the displayName - i.e. if
        the param is an id and this key is included, the resulting displayName would be: 
        'displayName (id)'
  3.  Import this component into your own component view, if you have multiple indepedent views for your
      breadcrumb route, you will need add this component to each view.
*/
</script>

<style scoped>
.breadcrumb {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.37rem;
}
</style>
