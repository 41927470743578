<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import MetadataCell from './MetadataCell.vue'
import { formatDate, underscoreToSpace } from '@/utils/formatters'
import { AuditLogSearchResult } from './useAuditLogs'

export default defineComponent({
  name: 'AuditLogTableRow',
  components: { MetadataCell },
  props: {
    item: { type: Object as PropType<AuditLogSearchResult>, required: true }
  },
  setup() {
    const opened = ref<boolean>(false)
    const toggle = () => {
      opened.value = !opened.value
    }
    return { opened, toggle, formatDate, underscoreToSpace }
  }
})
</script>

<template>
  <tr
    class="bg-white py-4 px-3 table-row border-b border-gray-100"
    data-testId="audit-log-table-row"
    @click="toggle"
  >
    <td class="p-3">{{ item.userId }}</td>
    <td class="p-3">{{ item.partnerName }}</td>
    <td class="p-3 uppercase">{{ underscoreToSpace(item.eventType) }}</td>
    <td class="p-3">
      <DateTime :date="item.dateCreated" />
    </td>
    <td class="p-3 w-5">
      <ChevronDown v-if="!opened" class="w-5" />
      <ChevronUp v-else class="w-5" />
    </td>
  </tr>
  <tr
    v-if="opened"
    class="bg-white-300 py-4 px-3"
    data-testId="audit-log-table-row-details"
  >
    <td class="p-3" colspan="5">
      <div v-if="item.oldValue?.value" class="flex">
        <span class="mr-2">Old values:</span>
        <MetadataCell :data="item.oldValue?.value" />
      </div>
      <div v-if="item.newValue?.value" class="flex">
        <span class="mr-2">New values:</span>
        <MetadataCell :data="item.newValue?.value" />
      </div>
      <div v-if="item.eventMeta" class="flex">
        <span class="mr-2">Metadata:</span>
        <MetadataCell :data="item.eventMeta" />
      </div>
      <div
        v-if="!item.eventMeta && !item.oldValue && !item.newValue"
        class="text-center"
      >
        No data available for this event
      </div>
    </td>
  </tr>
</template>
