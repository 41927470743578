import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'

export default {
  path: 'reports/:resource',
  props: true,
  component: View,
  children: [
    {
      path: '/reports/:resource',
      redirect: (from) => {
        return `/partners/current/reports/${from.params.resource}`
      }
    }
  ]
} as RouteRecordRaw
