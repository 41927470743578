<template>
  <table class="w-full">
    <tbody v-if="data" class="table-body">
      <tr v-for="entry in data" :key="entry.displayName" class="m-0">
        <td class="w-2/12 text-right">{{ entry.displayName }}:</td>
        <td class="w-10/12 pl-3">{{ entry.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'InfoPanel',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 5px;
}
</style>
