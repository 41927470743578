import { getAllPartners, Partner } from '@/store/partners'
import { computed, ComputedRef } from 'vue'

type FormattedPartners = Array<{
  id: string | null
  label: string
}>

export const formatPartners = (p: Partner[] | null): FormattedPartners => {
  return [
    { id: null, label: 'All partners' },
    // eslint-disable-next-line prettier/prettier
    ...(p?.map((e) => ({ id: e.id, label: e.display_name })) || [])
  ]
}

type UsePartnersReturnType = ComputedRef<FormattedPartners>

export function usePartners(): UsePartnersReturnType {
  return computed(() => {
    const p = getAllPartners()
    return formatPartners(p)
  })
}
