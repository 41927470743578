<script lang="ts">
export default {
  name: 'EnvelopeIcon',
  props: {
    variant: {
      type: String,
      default: 'front'
    }
  }
}
</script>

<template>
  <svg
    v-if="variant === 'front'"
    width="44"
    height="20"
    viewBox="0 0 44 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="43.5"
      height="19.5"
      fill="white"
      stroke="#9C9C9C"
      stroke-width="0.5"
    />
    <rect
      x="38.25"
      y="2.25"
      width="3.5"
      height="3.5"
      fill="white"
      stroke="#9C9C9C"
      stroke-width="0.5"
    />
    <rect
      x="23.25"
      y="9.25"
      width="18.5"
      height="6.5"
      fill="white"
      stroke="#9C9C9C"
      stroke-width="0.5"
    />
  </svg>

  <svg
    v-if="variant === 'back'"
    width="44"
    height="20"
    viewBox="0 0 44 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="43.5"
      height="19.5"
      fill="white"
      stroke="#9C9C9C"
      stroke-width="0.5"
    />
    <rect
      x="21.6913"
      y="13.2384"
      width="22.6492"
      height="0.5"
      rx="0.25"
      transform="rotate(-30 21.6913 13.2384)"
      fill="#9C9C9C"
    />
    <rect
      x="22.0679"
      y="13.6663"
      width="22.7617"
      height="0.5"
      rx="0.25"
      transform="rotate(-150.188 22.0679 13.6663)"
      fill="#9C9C9C"
    />
  </svg>
</template>
