import { RouteRecordRaw } from 'vue-router'
import error from './error'
import login from './login'
import resetPassword from './resetPassword'
import sendPasswordReset from './sendPasswordReset'
import View from './View.vue'

export default {
  path: '/sessionless',
  component: View,
  children: [login, resetPassword, sendPasswordReset, ...error]
} as RouteRecordRaw
