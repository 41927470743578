import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  id: "container",
  class: "relative"
}
const _hoisted_2 = {
  id: "searchBar",
  class: "border-2 rounded border-grayDove"
}
const _hoisted_3 = { class: "flex align-middle" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  key: 0,
  id: "loading",
  class: "self-center italic p-2"
}
const _hoisted_7 = {
  key: 1,
  id: "noResults",
  class: "self-center font-bold p-2"
}
const _hoisted_8 = {
  key: 2,
  id: "results"
}
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_fa = _resolveComponent("fa")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Search, { class: "w-5 h-5 mx-1.5 self-center" }),
        _createElementVNode("input", {
          id: "myInput",
          value: _ctx.searchTerm,
          class: "h-full w-full disabled:bg-offWhite bg-mi p-1.5 outline-none",
          type: "text",
          placeholder: "Search..",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSearch && _ctx.handleSearch(...args))),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
        }, null, 40, _hoisted_4)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedResults, (option, index) => {
        return (_openBlock(), _createBlock(_component_Badge, {
          key: option.label || option,
          class: "ml-1.5 my-1.5"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createTextVNode(_toDisplayString(option.label || option) + " ", 1),
              _createVNode(_component_fa, {
                class: "cursor-pointer ml-2 -mr-1",
                icon: "times",
                onClick: ($event: any) => (_ctx.handleOptionDeselect(index))
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    (_ctx.isSearching && _ctx.searchTerm.length > 0)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 0,
          id: "results",
          class: "p-0 mb-2 bg-white absolute w-full z-40"
        }, {
          default: _withCtx(() => [
            (_ctx.isSearching && _ctx.searchTerm.length > 0 && _ctx.awaitingSearch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Loading Results... "))
              : (_ctx.searchResults.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, " No Results. "))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (result, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `search-${index}`,
                        class: "hover:bg-skyBlue cursor-pointer p-2",
                        onClick: ($event: any) => (_ctx.handleSelect(result))
                      }, [
                        _renderSlot(_ctx.$slots, "default", { result: result })
                      ], 8, _hoisted_9))
                    }), 128))
                  ]))
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ]))
}