<script lang="ts">
import { defineComponent, PropType } from 'vue'
import TableRow from './TableRow.vue'
import { AuditLogSearchResult } from './useAuditLogs'

export default defineComponent({
  name: 'AuditLogTable',
  components: {
    TableRow
  },
  props: {
    data: {
      type: Array as PropType<Array<AuditLogSearchResult>>,
      default() {
        return []
      }
    }
  }
})
</script>

<template>
  <table class="w-100 mb-3">
    <thead>
      <tr>
        <th
          v-for="key in ['User ID', 'Partner', 'Event', 'Timestamp', '']"
          :key="key"
          class="border-t-0 text-gray-900 font-light text-base p-3"
        >
          {{ key }}
        </th>
      </tr>
    </thead>
    <tbody v-if="data.length" class="table-body">
      <TableRow v-for="item in data" :key="item.id" :item="item" />
    </tbody>
    <tbody v-else class="table-body">
      <tr>
        <td colspan="5">
          <alert variant="info">No audit logs found</alert>
        </td>
      </tr>
    </tbody>
  </table>
</template>
