<template>
  <div>
    <LoadingIndicator>
      <div v-if="!isLoading">
        <div v-if="errorMessage !== null">
          <Alert variant="error">{{ errorMessage }}</Alert>
        </div>
        <div v-else>
          <div
            v-for="partnerFacility in partnerFacilities"
            :key="partnerFacility.id"
            class="mb-1 shadow-md shadow-black"
          >
            <PartnerFacilitiesTableRow
              :partner-facility="partnerFacility"
              :products="
                productAbilitiesByPartnerFacilityId[partnerFacility.id] || []
              "
            />
          </div>
        </div>
      </div>
    </LoadingIndicator>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  getPartnerFacilities,
  getPartnerFacilityAbilities,
  PartnerFacility,
  PartnerFacilityAbility
} from '@/store/partners'
import PartnerFacilitiesTableRow from './PartnerFacilitiesTableRow.vue'

export default defineComponent({
  name: 'PartnerFacilitiesTable',
  components: {
    PartnerFacilitiesTableRow
  },
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      partnerFacilities: [] as Array<PartnerFacility>,
      productAbilitiesByPartnerFacilityId: {} as Record<
        string,
        Array<PartnerFacilityAbility>
      >,
      isLoading: false,
      errorMessage: null as string | null
    }
  },
  async mounted() {
    this.isLoading = true

    try {
      this.partnerFacilities = await getPartnerFacilities({
        partnerId: this.partnerId
      })
      this.productAbilitiesByPartnerFacilityId = {}

      // "products" in the context of this UI are simply a filtered subset of abilities that refer to form factor type (i.e., where ability.order_field_name === 'object')
      await Promise.all(
        this.partnerFacilities.map(async (facility) => {
          const abilities = await getPartnerFacilityAbilities({
            partnerId: this.partnerId,
            partnerFacilityId: facility.id
          })
          const productAbilities = abilities.filter(
            (ability) => ability.order_field_name === 'object'
          )
          this.productAbilitiesByPartnerFacilityId[facility.id] =
            productAbilities
        })
      )
    } catch (e) {
      this.errorMessage = String(e)
    }

    this.isLoading = false
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
