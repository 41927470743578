/* eslint-disable camelcase */
import { computed, watch } from '@vue/runtime-core'
import { Ref, ref } from '@vue/reactivity'
import { sendPapiRequest } from '@/store/requests'
import { getCurrentPartnerId } from '@/store/partners'
import { getUser, isAdminUser } from '@/store/session'
import { captureException } from '@/plugins/sentry'

export const PAGE_SIZE = 10

export type TestCase = {
  case_id: string
  test_id: string
  date_created: string
  count: number
}

export type Test = {
  id: string
  date_scheduled: string
  date_created: string
  report_sent: boolean
  partner: string
  resource: string
  status: string
  type: string
  user_email: string
  test_cases: TestCase[]
  qty: number
}

export type Case = { id: string; description: string }

export interface SelectedPartner {
  id: string | null
  label: string | null
}

type UseTestsReturnType = {
  isReady: Ref<boolean>
  isLoading: Ref<boolean>
  errorMessage: Ref<string>
  isAdmin: Ref<boolean>
  tests: Ref<Test[]>
  testsCount: Ref<number>
  testsTotalCount: Ref<number>
  descriptionMap: Ref<Record<string, string>>
  loadTests: (partner: string | null, offset: number) => void
}

const fetchTests = (partnerId: string | null, offset = 0) =>
  sendPapiRequest({
    method: 'GET',
    path: '/tests',
    query: {
      limit: PAGE_SIZE,
      offset,
      partner_id: partnerId
    }
  })

const fetchCases = () =>
  sendPapiRequest({
    method: 'GET',
    path: '/tests/cases'
  })

const createDescriptionMap = (cases: Case[]) =>
  cases.reduce((acc, item) => {
    acc[item.id] = item.description
    return acc
  }, {})

export function useTests(
  selectedPartner: Ref<SelectedPartner>
): UseTestsReturnType {
  const isReady = ref<boolean>(false)
  const isLoading = ref<boolean>(true)
  const errorMessage = ref('')
  const tests = ref<Test[]>([])
  const testsCount = ref<number>(0)
  const testsTotalCount = ref<number>(0)
  const descriptionMap = ref<Record<string, string>>({})
  const user = computed(getUser)
  const isAdmin = computed(isAdminUser)
  const currentPartnerId = computed(getCurrentPartnerId)

  const loadTests = async (partner: string | null = null, offset = 0) => {
    try {
      isLoading.value = true
      errorMessage.value = ''

      const [testsRes, casesRes] = await Promise.all([
        // Admin can see any tests and filter, partner only their
        fetchTests(isAdmin.value ? partner : currentPartnerId.value, offset),
        fetchCases()
      ])
      descriptionMap.value = createDescriptionMap(casesRes.data.Payload.data)
      tests.value = testsRes.data.Payload.data.map((t) => ({
        ...t,
        qty: t.test_cases.reduce(
          (total: number, tc: TestCase) => total + tc.count,
          0
        )
      }))
      testsCount.value = testsRes.data.Payload.count
      testsTotalCount.value = testsRes.data.Payload.total_count
    } catch (err) {
      captureException(err as Error)
      errorMessage.value = 'Data failed to load'
    } finally {
      isLoading.value = false
    }
  }

  watch(
    user,
    async () => {
      if (user.value && !isReady.value) {
        loadTests(selectedPartner?.value?.id)
        isReady.value = true
      }
    },
    { immediate: true }
  )

  return {
    isReady,
    isLoading,
    errorMessage,
    tests,
    testsCount,
    testsTotalCount,
    descriptionMap,
    isAdmin,
    loadTests
  }
}
