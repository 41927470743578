<script lang="ts">
import { defineComponent, Prop } from 'vue'
import { UmUser } from './View.vue'

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    } as Prop<UmUser>
  },
  emits: ['cancel', 'confirm'],
  methods: {
    onConfirm() {
      if (!this.user || !this.user.id) {
        this.$emit('cancel')
        return
      }

      this.$emit('confirm', this.user.id)
    }
  }
})
</script>

<template>
  <Modal width="600px" :visible="isVisible" @close="$emit('cancel')">
    <template #header>
      <div class="w-full text-center">
        <h5>Confirm User Delete</h5>
      </div>
    </template>

    <div>
      <p class="text-base font-medium">
        Are you sure you would like to delete this user?
      </p>
      <ul class="mt-5">
        <li class="py-1">
          User ID:
          <label class="font-bold">{{ user?.id }}</label>
        </li>
        <li class="py-1">
          Print Partner:
          <label class="font-bold">{{ user?.partner.label }}</label>
        </li>
        <li class="py-1">
          Role:
          <label class="font-bold">{{ user?.role.label }}</label>
        </li>
        <li class="py-1">
          Email Address:
          <label class="font-bold">{{ user?.email }}</label>
        </li>
      </ul>
    </div>

    <template #footer>
      <div class="flex self-end">
        <LobButton variant="secondary" @click="$emit('cancel')">
          Cancel
        </LobButton>
        <LobButton variant="primary" class="ml-2" @click="onConfirm">
          Confirm &amp; Delete user
        </LobButton>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
ul li {
  padding: 4px 0;
}
</style>
