<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaginationNext',
  props: {
    previousKey: {
      type: String,
      default: ''
    },
    nextKey: {
      type: String,
      default: ''
    }
  },
  emits: ['change'],
  setup() {
    return {
      buttonClass: `
        border-none
        bg-transparent
        my-0
        mx-3
        relative
        hover:text-primary-300
        `
    }
  }
})
</script>

<template>
  <div class="flex justify-end items-center">
    <button
      data-testid="paginationPrev"
      :disabled="previousKey?.length === 0"
      :class="[
        buttonClass,
        previousKey?.length === 0 && 'text-gray-100 hover:text-gray-100'
      ]"
      @click.prevent="$emit('change', previousKey)"
    >
      <span class="sr-only">Go to previous page</span>
      <ChevronLeft class="fill-current w-6 h-6" />
    </button>
    <button
      data-testid="paginationNext"
      :disabled="nextKey?.length === 0"
      :class="[
        buttonClass,
        nextKey?.length === 0 && 'text-gray-100 hover:text-gray-100'
      ]"
      @click.prevent="$emit('change', nextKey)"
    >
      <span class="sr-only">Go to next page</span>
      <ChevronRight class="fill-current w-6 h-6" />
    </button>
  </div>
</template>
