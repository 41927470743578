<template>
  <P v-if="value.name">
    {{ value.name }}
  </P>
  <P v-else class="dim">Empty Name</P>

  <P v-if="value.address_line1">
    {{ value.address_line1 }}
  </P>
  <P v-else class="dim">0000 Empty Street</P>

  <P v-if="value.address_line2">
    {{ value.address_line2 }}
  </P>

  <P>
    <span v-if="value.address_city">{{ value.address_city }}</span>
    <span v-else class="dim">Nowheresville</span>
    &nbsp;
    <span v-if="value.address_state">{{ value.address_state }}</span>
    <span v-else class="dim">ST</span>
    &nbsp;
    <span v-if="value.address_zip">{{ value.address_zip }}</span>
    <span v-else class="dim">0000</span>
  </P>

  <P>{{ value.address_country }}</P>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    value: {
      type: Object,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.dim {
  opacity: 0.5;
}
</style>
