<template>
  <div v-if="userAvailable" id="main-page-root">
    <PortalMainNavigation id="sidebar" />
    <main id="content">
      <router-view />
    </main>
  </div>
</template>

<script lang="ts">
import router from '@/router'
import { isLoggedIn, getUser } from '@/store/session'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MainPage',
  computed: {
    isLoggedIn() {
      return isLoggedIn()
    },
    userAvailable() {
      return getUser() !== null
    }
  },
  watch: {
    isLoggedIn() {
      this.bumpToLoginIfLoggedOut()
    }
  },
  mounted() {
    this.bumpToLoginIfLoggedOut()
  },
  methods: {
    bumpToLoginIfLoggedOut() {
      if (!isLoggedIn()) {
        this.bumpToLogin()
      }
    },
    bumpToLogin() {
      router.push(this.getLoginRoute())
    },
    getLoginRoute() {
      const continu = this.getCurrentRouteAsString()
      return {
        name: 'Login',
        params: { continue: continu }
      }
    },
    getCurrentRouteAsString() {
      return router.currentRoute.value.fullPath
    }
  }
})
</script>

<style lang="scss" scoped>
#main-page-root {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
  overflow: hidden;
}

#content {
  height: 100vh;
  overflow-y: auto;
}
</style>
