<template>
  <div class="flex flex-column">
    <div
      class="bg-mist shadow-md shadow-black rounded w-100 grid grid-cols-6 items-baseline justify-center p-2"
    >
      <div class="pl-2 col-span-5 content-baseline">
        {{ abilityExclusion.description }}
      </div>
      <div class="flex justify-end pr-3">
        <button @click="toggleDetails()">
          <fa
            class="text-slate-300"
            :icon="isDetailOpen ? 'chevron-up' : 'chevron-down'"
          />
        </button>
      </div>
    </div>
    <div
      v-if="isDetailOpen"
      class="flex flex-column w-100 items-baseline justify-items-center text-sm p-2 rounded shadow-sm shadow-black"
    >
      <div class="grid grid-cols-6 w-100 p-2">
        <div class="items-baseline font-bold">Name</div>
        <div class="items-baseline font-bold">Order Field Name</div>
        <div class="items-baseline font-bold">Operator</div>
        <div class="items-baseline font-bold">Order Field Value</div>
      </div>
      <div
        v-for="abilityId in abilityExclusion.ability_ids"
        :key="abilityId"
        class="flex flex-column w-100 pt-1 pb-1"
      >
        <AbilityExclusionsInnerTableRow
          class="flex items-baseline flex-row"
          :ability-id="abilityId"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AbilityExclusion } from '@/store/partners'
import AbilityExclusionsInnerTableRow from './AbilityExclusionsInnerTableRow.vue'

export default defineComponent({
  name: 'AbilityExclusionsTableRow',
  components: {
    AbilityExclusionsInnerTableRow
  },
  props: {
    abilityExclusion: {
      type: Object as PropType<AbilityExclusion>,
      required: true
    }
  },
  data() {
    return {
      isDetailOpen: false
    }
  },
  methods: {
    toggleDetails() {
      this.isDetailOpen = !this.isDetailOpen
    }
  }
})
</script>
