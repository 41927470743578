<template>
  <div class="content p-5">
    <div class="intro">
      <h2>SFTP Setup</h2>
      <p class="mt-3">Please enter information about your SFTP server</p>
    </div>

    <div class="row mt-5 contentSection">
      <div class="col-3">
        <p class="sectionTitle">SFTP Server Information</p>
      </div>
      <div class="col-9">
        <form>
          <div class="row">
            <div class="col-9">
              <TextInput
                id="sftp_host"
                v-model="fields.sftp_host"
                label="Host"
                placeholder="uploads.agencyname.com"
                :required="true"
              />
            </div>
            <div class="col-3">
              <TextInput
                id="sftp_port"
                v-model="fields.sftp_port"
                label="Port"
                placeholder="22"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12">
              <Dropdown
                id="report_type"
                :model-value="currentReportType"
                :options="reportTypes"
                label="Select report type"
                class="combobox"
                data-testid="reportTypeDropdown"
                @update:modelValue="handleReportTypeChange"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12">
              <Dropdown
                id="report_directory"
                v-model="fields.report_directory"
                :options="directoryOptions"
                label="Select report directory"
              />
              <span
                v-if="
                  fields.report_directory &&
                  !directoryOptions.includes(fields.report_directory)
                "
                class="text-xs"
              >
                You have previously selected an unsupported directory:
                <em>{{ fields.report_directory }}</em>
                . Please update using the dropdown above.
              </span>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-6">
              <TextInput
                id="sftp_username"
                v-model="fields.sftp_username"
                label="Username"
                placeholder="Enter Username"
              />
            </div>
            <div class="col-6">
              <TextInput
                id="sftp_password"
                v-model="fields.sftp_password"
                label="Password"
                placeholder="Enter Password"
                type="password"
              />
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-12">
              <Textarea
                id="sftp_private_key"
                v-model="fields.sftp_private_key"
                label="Private key"
                placeholder="Enter private key"
              />
            </div>
          </div>

          <div class="row pt-3">
            <div class="col-12">
              <Alert
                v-if="notification.message"
                data-testid="notification"
                :variant="notification.type"
                class="mt-2"
              >
                {{ notification.message }}
              </Alert>
              <LobButton
                class="mt-4"
                data-testid="submitBtn"
                :disabled="pending"
                @click.prevent="handleSubmit"
              >
                {{ isUpdate ? 'Update Data' : 'Save Data' }}
              </LobButton>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-5 contentSection">
      <div class="col-3">
        <p class="sectionTitle">IP addresses to include in your allowlist</p>
      </div>
      <div class="col-9">
        <p>Our VPN</p>
        <pre class="prettyprint"><code>{{ vpnIP }}</code></pre>
        <p>Current IPs</p>
        <pre class="prettyprint"><code>{{ currentIP }}</code></pre>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getCurrentPartnerId } from '@/store/partners'
import * as sftp from '@/store/sftp'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SFTPSetup',
  data() {
    return {
      reportTypes: sftp.reportTypes,
      fields: {
        sftp_host: '',
        sftp_port: '',
        sftp_username: '',
        sftp_password: '',
        sftp_private_key: '',
        report_directory: ''
      },
      vpnIP: `
      44.236.37.154
      52.27.222.125
      54.244.167.122
      54.203.185.197
      `,
      currentIP: `
      44.228.253.191
      52.24.117.32
      44.226.115.242
      34.211.80.228
      52.25.101.174
      44.228.255.148
      `,
      directoryOptions: [
        '/Reporting/DailyCompletedReports/',
        '/Reporting/DailyReceivedReports/'
      ]
    }
  },
  computed: {
    currentReportType() {
      return sftp.getCurrentReportType()
    },
    isUpdate() {
      return Boolean(sftp.getCurrentSettings()?.id)
    },
    pending() {
      return sftp.getPendingState()
    },
    notification() {
      return sftp.getNotification()
    }
  },
  async mounted() {
    await sftp.fetchSFTPReports()
    const config = sftp.getCurrentSettings()
    this.assignSettingsToFields(config)
  },
  methods: {
    assignSettingsToFields(config) {
      this.fields = {
        sftp_host: config?.sftp_host ?? '',
        sftp_port: config?.sftp_port ?? '',
        sftp_username: config?.sftp_username ?? '',
        sftp_password: config?.sftp_password ?? '',
        sftp_private_key: config?.sftp_private_key ?? '',
        report_directory: config?.report_directory ?? ''
      }
    },
    handleSubmit() {
      const partnerId = getCurrentPartnerId()
      if (partnerId === null) return

      const data = {
        ...this.fields,
        sftp_port: Number(this.fields.sftp_port),
        reporter_id: partnerId,
        report_type: this.currentReportType
      }

      if (this.isUpdate) {
        sftp.updateSFTPReport({
          id: sftp.getCurrentSettings()?.id as string,
          data
        })
      } else sftp.createSFTPReport({ data })
    },
    handleReportTypeChange($event) {
      sftp.setCurrentReportType($event)
      this.assignSettingsToFields(sftp.getCurrentSettings())
    }
  }
})
</script>

<style scoped lang="scss">
.prettyprint {
  background-color: $light-bluish-gray-active;
}
</style>
