<template>
  <div
    class="bg-mist grid grid-cols-6 justify-items-center shadow-md shadow-black"
  >
    <div class="w-100 p-2">
      {{ ability.name }}
    </div>
    <div class="w-100 p-2">
      {{ ability.order_field_name }}
    </div>
    <div class="w-100 p-2">
      {{ ability.operator }}
    </div>
    <div class="w-100 p-2">
      {{ JSON.stringify(ability.order_field_value) }}
    </div>
    <div class="w-100 p-2">
      <toggle-button
        v-model="active"
        class="cursor-pointer"
        :name="ability.name"
        :label="''"
        @click="toggleActive"
      />
    </div>
    <div class="w-100 p-2">
      <PackageButton :on-click="removeAbility">Remove Ability</PackageButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  PartnerFacilityAbility,
  setPartnerFacilityAbility,
  removePartnerFacilityAbility
} from '@/store/partners'
import PackageButton from '../../customMail/common/PackageButton.vue'

export default defineComponent({
  name: 'ProductsInnerTableRowAvailable',
  components: {
    PackageButton
  },
  props: {
    partnerId: {
      type: String,
      required: true
    },
    ability: {
      type: Object as PropType<PartnerFacilityAbility>,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      active: this.ability.active
    }
  },
  methods: {
    async toggleActive() {
      await setPartnerFacilityAbility({
        partnerId: this.partnerId,
        partnerFacilityId: this.ability.partner_facility_id,
        abilityId: this.ability.id,
        active: !this.active
      })

      await this.refreshData()
    },
    async removeAbility() {
      await removePartnerFacilityAbility({
        partnerId: this.partnerId,
        partnerFacilityId: this.ability.partner_facility_id,
        abilityId: this.ability.id
      })

      this.refreshData()
    }
  }
})
</script>
