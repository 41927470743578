import { TestSet } from '.'
import { DEFAULT_BANK_ACCOUNT } from '@/consts'

export type EnumeratedFieldType = Array<{
  // eslint-disable-next-line camelcase
  form_factors: string[]
  name: string
  value: unknown
}>

export type FieldType =
  | 'form_factor'
  | 'address'
  | 'artwork'
  | 'json'
  | 'resource'
  | 'float'
  | 'string'
  | EnumeratedFieldType

export interface SchemaFormFactor {
  id: string
  name: string
  // eslint-disable-next-line camelcase
  resource_fields: Record<string, FieldType>
}

export interface SchemaField {
  // eslint-disable-next-line camelcase
  form_factors: string[]
  name: string
  id: string
  type: FieldType
}

export interface Schema {
  // eslint-disable-next-line camelcase
  form_factors: Array<SchemaFormFactor>
  fields: Array<SchemaField>
}

// adds fields to a test set that are specified in the schema but not in the test set.
export const backfillTestSet = (schema: Schema, ts: TestSet): TestSet => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultFields: Record<string, Array<any>> = {}
  for (const field of schema.fields) {
    defaultFields[field.id] = []
  }
  return {
    ...ts,
    fields: {
      ...defaultFields,
      ...ts.fields
    }
  }
}

export function defaultValueOfType(type: FieldType, schema: Schema): unknown {
  if (type === 'form_factor') {
    return schema.form_factors[0].id
  } else if (type === 'address') {
    throw new Error('default value for address type is unimplemented')
  } else if (type === 'artwork') {
    return '<html></html>'
  } else if (type === 'json') {
    return {}
  } else if (type === 'resource') {
    throw new Error('default value for resource type is unimplemented')
  } else if (type === 'float') {
    return 0.0
  } else if (type === 'string') {
    return ''
  } else if (Array.isArray(type)) {
    return type[0].name
  } else {
    throw new Error(
      `missing an implementation providing a default value of type ${type}`
    )
  }
}

// eslint-disable-next-line
export function loadDefaultTestSet(factor: any) {
  const factors = factor.factors
  const valuesMap = factor.currentValues
  for (let i = 0; i < factors.length; i++) {
    const field = factors[i]
    if (Array.isArray(field.type)) {
      valuesMap.set(field.id, [field.type[0].value])
    } else if (field.id === 'bank_account') {
      valuesMap.set(field.id, [DEFAULT_BANK_ACCOUNT])
    } else if (field.type !== 'resource') {
      valuesMap.set(field.id, [null])
    }
  }
  return valuesMap
}
