<template>
  <div
    class="bg-mist grid grid-cols-7 justify-items-center justify-items-center shadow-md shadow-black pl-2 pr-2 ml-1"
  >
    <div class="w-100 p-3">
      {{ bid.order_quantity }}
    </div>
    <div class="w-100 p-3">
      {{ bid.total_us_dollars > 0 ? bid.total_us_dollars : '' }}
    </div>
    <div class="w-100 p-3">
      {{ bid.lead_time_days ?? '' }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManageBidsInnerTableRow',
  props: {
    partner: {
      type: Object,
      required: true
    },
    isAdminOrPopsUser: {
      type: Boolean,
      required: true
    },
    bid: {
      type: Object,
      required: true
    }
  }
})
</script>
