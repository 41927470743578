<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h2 :mode="configureMode">
        {{ modalTitle }}
      </h2>
      <Info class="ml-1 w-5 h-5" />
    </div>
    <div class="flex flex-row justify-between pr-3 pb-3 pt-3">
      <h5>Bid List</h5>
    </div>
    <div class="grid grid-cols-3 p-3">
      <div>Quantity</div>
      <div>Total Price (USD)</div>
      <div>Lead Time (Days)</div>
    </div>
    <div v-for="(bid, index) in bids" :key="bid.id" class="flex flex-row">
      <div
        class="bg-mist grid grid-cols-3 justify-items-center justify-items-center shadow-md shadow-black pl-2 pr-2 ml-1 mb-1"
      >
        <div class="w-100 p-4">
          {{ bid.order_quantity }}
        </div>
        <PackageTextInput
          class="rounded-md pr-2 pl-1 pt-2 pb-2"
          :value="bid.total_us_dollars?.toString() || ''"
          label=""
          placeholder="Total Price"
          @update:value="(e) => updateBidTotalPrice(e, index)"
        />
        <PackageTextInput
          class="rounded-md pr-2 pl-1 pt-2 pb-2 border-1"
          :value="bid.lead_time_days?.toString() || ''"
          label=""
          placeholder="Lead Time"
          @update:value="(e) => updateBidLeadTime(e, index)"
        />
      </div>
    </div>
    <div class="flex items-end -mb-10 mt-5">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton
        class="mr-3"
        :disabled="createDisabled"
        @click="moveToBidsPage"
      >
        Submit
      </LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageTextInput from './PackageTextInput.vue'
import { BidData, editBidDetails } from '@/store/customMail'

export default defineComponent({
  name: 'PartnerBidModal',
  components: {
    PackageTextInput
  },
  props: {
    partnerBids: {
      type: Array as PropType<BidData[]>,
      default: () => []
    },
    mode: { type: String, required: true },
    data: { type: Object, required: true },
    isOpen: { type: Boolean, required: true },
    isAdminOrPopsUser: { type: Boolean, required: true },
    productId: { type: String, required: true },
    currentPartner: { type: Object, required: true }
  },
  emits: ['close', 'saved', 'updateStatus'],
  data() {
    return {
      bids: JSON.parse(JSON.stringify(this.partnerBids)),
      deletedQuantities: [] as string[],
      modalTitle: '',
      modalButtonAction: '',
      options: [],
      partnersWithBids: [],
      orderQuantity: '',
      totalUSD: null,
      leadTime: null,
      leadTimeNotInt: false
    }
  },
  computed: {
    createDisabled() {
      for (let i = 0; i < this.bids.length; i++) {
        if (
          this.bids[i]?.total_us_dollars === 0 ||
          this.bids[i]?.lead_time_days === 0
        )
          return true
      }
      if (this.leadTimeNotInt) {
        return true
      }
      return false
    }
  },
  watch: {
    mode: function (newVal) {
      this.configureMode(newVal)
    },
    currentPartner(newVal) {
      // if a partner is present, we're in editing mode and a quantity array already exists
      if (newVal) {
        newVal.bidsArray.forEach((bid) => {
          this.orderQuantity = bid.order_quantity.toString()
          this.totalUSD = bid.total_us_dollars
            ? bid.total_us_dollars.toString()
            : null
          this.leadTime = bid.lead_time_days
            ? bid.lead_time_days.toString()
            : null
        })
        // for some reason, this is the only way to deep copy the array and not reference the
        // original array (otherwise the table view will update with new values when using the modal)
        this.bids = JSON.parse(JSON.stringify(newVal.bidsArray))
      }
    }
  },
  methods: {
    close() {
      this.$emit('close', {})
    },
    areBidsComplete() {
      let isCompleted = true
      let bidStatus = ''
      const bids = this.partnerBids
      bids.forEach((bid) => {
        if (!bid.total_us_dollars || !bid.lead_time_days) {
          isCompleted = false
          bidStatus = 'pending'
        } else {
          bidStatus = 'submitted'
        }
      })
      const partnerPackageData = {
        partnerID: this.currentPartner.partner_id,
        packageID: this.productId,
        partners: this.partnerBids,
        status: bidStatus
      }

      if (isCompleted) {
        this.$emit('updateStatus', this.currentPartner, partnerPackageData)
      }
    },
    async moveToBidsPage() {
      const data: any = {
        productID: this.productId,
        partnerID: this.currentPartner?.partnerID,
        deletedQuantities: []
      }
      this.bids = this.bids.map((bid: any) => {
        bid.lead_time_days =
          bid.lead_time_days.length === 0 ? null : parseInt(bid.lead_time_days)
        bid.total_us_dollars =
          bid.total_us_dollars.length === 0
            ? null
            : parseFloat(bid.total_us_dollars)
        bid.order_quantity = parseInt(bid.order_quantity)
        return bid
      })
      data.bids = this.bids

      await editBidDetails(data)

      this.areBidsComplete()

      this.$emit('close', { reloadData: true })
    },
    configureMode(mode: string) {
      if (mode === CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_MODE) {
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_BUTTON
        this.modalTitle = CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_TITLE
      }
    },
    saved() {
      this.$emit('saved', false)
    },
    updateBidTotalPrice(total_us_dollars, index) {
      this.bids[index].total_us_dollars = total_us_dollars
        ? parseFloat(total_us_dollars)
        : undefined
    },
    updateBidLeadTime(lead_time_days, index) {
      // make sure input only contains whole numbers
      this.leadTimeNotInt = !/^-?\d+$/.test(lead_time_days)
      this.bids[index].lead_time_days = lead_time_days
        ? parseInt(lead_time_days)
        : undefined
    }
  }
})
</script>
