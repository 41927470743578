import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "container",
  class: "relative"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  id: "searchBar",
  class: "border-2 rounded border-grayDove h-10 flex align-middle"
}
const _hoisted_4 = ["disabled", "value"]
const _hoisted_5 = {
  key: 0,
  id: "loading",
  class: "self-center italic p-2"
}
const _hoisted_6 = {
  key: 1,
  id: "noResults",
  class: "self-center font-bold p-2"
}
const _hoisted_7 = {
  key: 2,
  id: "results"
}
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_fa = _resolveComponent("fa")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label && _ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.resultChosen)
        ? (_openBlock(), _createBlock(_component_Search, {
            key: 0,
            class: "w-5 h-5 mx-1.5 self-center"
          }))
        : _createCommentVNode("", true),
      (_ctx.resultChosen)
        ? (_openBlock(), _createBlock(_component_fa, {
            key: 1,
            icon: "times",
            class: "w-4 h-4 mx-1.5 self-center cursor-pointer",
            onClick: _ctx.handleReselect
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        id: "myInput",
        disabled: _ctx.resultChosen,
        value: _ctx.searchTerm,
        class: "h-full w-full disabled:bg-offWhite bg-mi p-1.5 outline-none",
        type: "text",
        placeholder: "Search..",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSearch && _ctx.handleSearch(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
      }, null, 40, _hoisted_4)
    ]),
    (_ctx.isSearching && _ctx.searchTerm.length > 0)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 1,
          id: "results",
          class: "p-0 mb-2 bg-white absolute w-full z-40"
        }, {
          default: _withCtx(() => [
            (_ctx.isSearching && _ctx.searchTerm.length > 0 && _ctx.awaitingSearch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Loading Results... "))
              : (_ctx.searchResults.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, " No Results. "))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (result, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `search-${index}`,
                        class: "hover:bg-skyBlue cursor-pointer p-2",
                        onClick: ($event: any) => (_ctx.handleSelect(result))
                      }, [
                        _renderSlot(_ctx.$slots, "default", { result: result })
                      ], 8, _hoisted_8))
                    }), 128))
                  ]))
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ]))
}