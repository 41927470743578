<template>
  <P v-if="value">
    {{ value }}
  </P>
  <P v-else>Omit</P>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  name: 'MaybeDateDisplay',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    value: {
      type: String,
      required: false,
      default: null
    }
  }
})
</script>
