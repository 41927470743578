<template>
  <div
    :class="`border border-gray-200 border-solid rounded-sm overflow-hidden ${
      this.class
    } ${shadow ? 'shadow-sm' : ''}`"
  >
    <button
      class="bg-white-300 px-2 py-2 flex items-center w-full overflow-hidden hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-100 focus:border-transparent"
      data-testid="header"
      @click="toggle"
    >
      <div class="w-full text-left">
        <span class="font-bold mr-1">{{ title }}</span>
        <span>{{ subtitle }}</span>
      </div>
      <chevron-up v-if="isOpen" class="w-7 text-gray-500" />
      <chevron-down v-if="!isOpen" class="w-7 text-gray-500" />
    </button>
    <div v-if="isOpen" class="px-3 py-2">
      <div :class="`${hasAside ? 'grid grid-cols-3 gap-8' : ''} p-2`">
        <div class="col-span-2 flex flex-col">
          <div class="flex-1"><slot></slot></div>
          <div class=""><slot name="footer"></slot></div>
        </div>
        <div v-if="hasAside"><slot name="side"></slot></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pane',
  props: {
    forceOpen: {
      type: Boolean,
      default: false
    },
    class: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    shadow: {
      type: Boolean,
      default: true
    },
    hasAside: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: this.forceOpen
    }
  },
  watch: {
    forceOpen(forceOpen) {
      this.isOpen = forceOpen
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style></style>
