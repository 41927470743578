import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Caption = _resolveComponent("Caption")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Caption, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.name), 1)
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createBlock(_component_Checkbox, {
        key: option,
        modelValue: _ctx.checked[index],
        "onUpdate:modelValue": ($event: any) => ((_ctx.checked[index]) = $event),
        label: option,
        name: option,
        value: option
      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "value"]))
    }), 128)),
    _createVNode(_component_Checkbox, {
      modelValue: _ctx.useOther,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.useOther) = $event)),
      label: "Other:"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_TextInput, {
      id: "other",
      modelValue: _ctx.other,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.other) = $event)),
      label: ""
    }, null, 8, ["modelValue"])
  ]))
}