export function downloadURI(uri: string, filename: string): void {
  const link = document.createElement('a')
  link.download = filename
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadString(
  text: string,
  filename: string,
  stringify = false,
  contentType = 'text/plain'
): void {
  const link = document.createElement('a')

  link.href = `data:${contentType};charset=utf-8,${
    stringify ? encodeURIComponent(JSON.stringify(text, null, 2)) : text
  }`
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function guessUserAgentOS(): string {
  if (navigator.userAgent.includes('Linux')) {
    return 'linux'
  }
  // Ignoring mac because buzz does not support it
  return 'windows'
}
