<template>
  <TextInput
    id="name"
    label="Text, true, or false"
    :model-value="textValue"
    @update:modelValue="updateValue($event)"
  />
  <P>Use false to indicate an omission</P>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    modelValue: {
      type: String,
      required: false,
      default: 'false'
    }
  },
  emits: ['update:modelValue'],
  computed: {
    textValue(): string {
      return `${this.modelValue}`
    }
  },
  methods: {
    updateValue(newText: string) {
      if (newText.toLowerCase() === 'false') {
        this.$emit('update:modelValue', false)
      } else if (newText.toLowerCase() === 'true') {
        this.$emit('update:modelValue', true)
      } else {
        this.$emit('update:modelValue', newText)
      }
    }
  }
})
</script>
