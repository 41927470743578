import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4af529da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "digital-proofing-root" }
const _hoisted_2 = { class: "digital-proofing-header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_H2 = _resolveComponent("H2")!
  const _component_LobButton = _resolveComponent("LobButton")!
  const _component_P = _resolveComponent("P")!
  const _component_fa = _resolveComponent("fa")!
  const _component_H3 = _resolveComponent("H3")!
  const _component_LobFileUpload = _resolveComponent("LobFileUpload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Badge, { variant: _ctx.badgeVariant }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.badgeText), 1)
        ]),
        _: 1
      }, 8, ["variant"]),
      _createVNode(_component_H2, null, {
        default: _withCtx(() => [
          (_ctx.permutationData != null)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.permutationData?.resource) + " / " + _toDisplayString(_ctx.permutationData?.permutation.replaceAll('_', ' ')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, "Loading..."))
        ]),
        _: 1
      }),
      (_ctx.userIsAdmin)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            (_ctx.adminActionPending)
              ? (_openBlock(), _createBlock(_component_LobButton, {
                  key: 0,
                  disabled: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Loading...")
                  ]),
                  _: 1
                }))
              : (!_ctx.approved)
                ? (_openBlock(), _createBlock(_component_LobButton, {
                    key: 1,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.approveProof()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Approve ")
                    ]),
                    _: 1
                  }))
                : (_ctx.approved)
                  ? (_openBlock(), _createBlock(_component_LobButton, {
                      key: 2,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rejectProof()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Reject ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.permutationData === null)
      ? (_openBlock(), _createBlock(_component_P, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode("Loading...")
          ]),
          _: 1
        }))
      : (_ctx.uploadedLinks.length === 0)
        ? (_openBlock(), _createBlock(_component_P, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode("No files uploaded")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadedLinks, (link) => {
      return (_openBlock(), _createElementBlock("a", {
        key: link.url,
        class: "file-card",
        href: link.url
      }, [
        _createVNode(_component_fa, { icon: "image" }),
        _createVNode(_component_P, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(link.filename), 1)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_fa, { icon: "chevron-right" })
      ], 8, _hoisted_6))
    }), 128)),
    (!_ctx.userIsAdmin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_H3, null, {
            default: _withCtx(() => [
              _createTextVNode("Upload Resources")
            ]),
            _: 1
          }),
          _createVNode(_component_LobFileUpload, {
            "do-try-upload": _ctx.tryUploadFile,
            "max-size": _ctx.maxFileSize
          }, null, 8, ["do-try-upload", "max-size"])
        ]))
      : _createCommentVNode("", true)
  ]))
}