import {
  CardOrder,
  EnvelopeOrder,
  OrderType,
  MaterielInventory,
  ORDER_TYPE,
  BuckslipOrder
} from './types'
import { AxiosError } from 'axios'

import { Ref, ref } from 'vue'
import { sendPapiRequest } from '@/store/requests'
import {
  MATERIEL_INVENTORY_CHANGE_TYPES,
  MATERIEL_INVENTORY_STATUS
} from '@/consts'

export type AddMaterielInventoryPayload = Partial<MaterielInventory>[]

export interface UpdateMaterielInventoryPayload {
  editProperty: string
  editMaterielInventoryId: string
  note: string
  type?: string
  value: number | string
}

export interface RemoveMaterielInventoryPayload {
  id: string
}

/* eslint camelcase: "off" */
export interface TransferMaterielInventoryPayload {
  materielInventoryId: string
  partners: { partner_id: string; initial_count: number }[]
}

export interface ExtendOrderExpirationPayload {
  materielOrderId: string
}

export interface UseMaterielInventoryResponse {
  error: Ref<string | null>
  loading: Ref<boolean>
  order: Ref<CardOrder | EnvelopeOrder | BuckslipOrder>
  add(payload: AddMaterielInventoryPayload): void
  update(payload: UpdateMaterielInventoryPayload): void
  remove(payload: RemoveMaterielInventoryPayload): void
  transfer(payload: TransferMaterielInventoryPayload): void
  extendExpiration(payload: ExtendOrderExpirationPayload): void
}

export function useMaterielInventory(
  initialOrder: CardOrder | EnvelopeOrder | BuckslipOrder,
  resourceId: string,
  type: OrderType
): UseMaterielInventoryResponse {
  const basePath = '/materiel_inventories'
  const error = ref<string>('')
  const loading = ref(false)
  const order = ref<CardOrder | EnvelopeOrder | BuckslipOrder>(initialOrder)
  let materielType
  if (type === ORDER_TYPE.BUCKSLIPS) {
    materielType = 'buckslip'
  } else if (type === ORDER_TYPE.CARDS) {
    materielType = 'card'
  } else if (resourceId.startsWith('renv_')) {
    materielType = 'return_envelope'
  } else {
    materielType = 'custom_envelope'
  }

  const add = async (payload: AddMaterielInventoryPayload) => {
    loading.value = true
    try {
      await sendPapiRequest({
        path: basePath,
        method: 'POST',
        data: {
          materiel_id: resourceId,
          materiel_type: materielType,
          materiel_order_id: order.value.id,
          status: MATERIEL_INVENTORY_STATUS.PRINTING, // ? not sure if this is the right status
          partners: payload
        }
      })

      await refetchOrder()
    } catch (e) {
      handleError(e as AxiosError)
    } finally {
      loading.value = false
    }
  }

  const handleError = (e: AxiosError) => {
    const eResp = e.response
    error.value = `${eResp?.status} : ${eResp?.data?.error?.message}`
  }

  const refetchOrder = async () => {
    const data = await sendPapiRequest({
      path: `/inventory/${type}/${resourceId}/orders/${initialOrder.id}`,
      method: 'GET'
    })
    order.value = data.data
  }

  const remove = async (payload: RemoveMaterielInventoryPayload) => {
    loading.value = true
    try {
      // Optimistic update
      order.value.materiel_inventories =
        order.value.materiel_inventories.filter((mi) => mi.id !== payload.id)

      await sendPapiRequest({
        path: `${basePath}/${payload.id}`,
        method: 'DELETE'
      })

      await refetchOrder()
    } catch (e) {
      handleError(e as AxiosError)
    } finally {
      loading.value = false
    }
  }

  const transfer = async (payload: TransferMaterielInventoryPayload) => {
    loading.value = true
    try {
      await sendPapiRequest({
        path: `${basePath}/transfer/${payload.materielInventoryId}`,
        method: 'POST',
        data: { partners: payload.partners }
      })

      await refetchOrder()
    } catch (e) {
      handleError(e as AxiosError)
    } finally {
      loading.value = false
    }
  }

  const update = async (payload: UpdateMaterielInventoryPayload) => {
    loading.value = true
    try {
      await sendPapiRequest({
        path: `${basePath}/${payload.editMaterielInventoryId}`,
        method: 'PATCH',
        data: {
          [payload.editProperty]: payload.value,
          note: payload.note,
          type:
            payload.type ||
            MATERIEL_INVENTORY_CHANGE_TYPES.UNRESERVED_NON_SPOILED
        }
      })
      await refetchOrder()
    } catch (e) {
      handleError(e as AxiosError)
    } finally {
      loading.value = false
    }
  }

  const extendExpiration = async (payload: ExtendOrderExpirationPayload) => {
    loading.value = true
    try {
      await sendPapiRequest({
        path: `${basePath}/expiration`,
        method: 'PATCH',
        data: {
          materiel_order_id: payload.materielOrderId
        }
      })
      await refetchOrder()
    } catch (e) {
      handleError(e as AxiosError)
    } finally {
      loading.value = false
    }
  }

  return {
    error,
    loading,
    order,
    add,
    remove,
    transfer,
    update,
    extendExpiration
  }
}
