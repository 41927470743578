<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import CirclePlusIcon from '@/components/common/icons/CirclePlusIcon.vue'
import CircleMinusIcon from '@/components/common/icons/CircleMinusIcon.vue'
import OrderId from './OrderId.vue'
import OrderProperty from './OrderProperty.vue'
import Statement from './Statement.vue'
import Inventory from './Inventory.vue'
import { EnvelopeOrder, MaterielInventory, ORDER_TYPE } from './types'
import { useMaterielInventory } from './useMaterielInventory'
import AddPrinterModal from './AddPrinterModal.vue'
import TransferInventoryModal from './TransferInventoryModal.vue'
import AcceptRejectInventoryModal from './AcceptRejectInventoryModal.vue'
import MarkAsReadyModal from './MarkAsReadyModal.vue'

export default defineComponent({
  name: 'EnvelopeOrder',
  components: {
    CirclePlusIcon,
    CircleMinusIcon,
    OrderId,
    OrderProperty,
    Statement,
    Inventory,
    AddPrinterModal,
    TransferInventoryModal,
    AcceptRejectInventoryModal,
    MarkAsReadyModal
  },
  props: {
    order: {
      type: Object as PropType<EnvelopeOrder>,
      required: true
    }
  },
  emits: ['inventoryItemUpdated', 'inventoryItemDeleted', 'inventoryItemAdded'],
  setup(props) {
    const expanded = ref(false)
    const addPrinterModalVisible = ref<boolean>(false)
    const acceptRejectInventoryModalVisible = ref<boolean>(false)
    const markAsReadyModalVisible = ref<boolean>(false)
    const transferMaterielInventory = ref<MaterielInventory | null>(null)

    const {
      order,
      add,
      remove,
      transfer,
      update,
      extendExpiration,
      loading: updateLoading,
      error: updateError
    } = useMaterielInventory(
      props.order,
      props.order.envelope_id,
      ORDER_TYPE.ENVELOPES
    )

    const hideAddPrinterModal = () => {
      addPrinterModalVisible.value = false
    }

    const hideAcceptRejectInventoryModal = () => {
      acceptRejectInventoryModalVisible.value = false
    }

    const hideMarkAsReadyModal = () => {
      markAsReadyModalVisible.value = false
    }

    const hideTransferInventoryModal = () => {
      transferMaterielInventory.value = null
    }

    const showAddPrinterModal = () => {
      addPrinterModalVisible.value = true
    }

    const showAcceptRejectInventoryModal = () => {
      acceptRejectInventoryModalVisible.value = true
    }

    const showMarkAsReadyModal = () => {
      markAsReadyModalVisible.value = true
    }

    const showTransferInventoryModal = (
      materielInventory: MaterielInventory
    ) => {
      transferMaterielInventory.value = materielInventory
    }

    const toggleExpanded = () => {
      expanded.value = !expanded.value
    }

    return {
      add,
      addPrinterModalVisible,
      acceptRejectInventoryModalVisible,
      markAsReadyModalVisible,
      expanded,
      extendExpiration,
      hideAddPrinterModal,
      hideTransferInventoryModal,
      orderData: order as unknown as EnvelopeOrder,
      ORDER_TYPE,
      remove,
      showAddPrinterModal,
      showTransferInventoryModal,
      toggleExpanded,
      transfer,
      transferMaterielInventory,
      update,
      updateLoading,
      updateError,
      hideAcceptRejectInventoryModal,
      showAcceptRejectInventoryModal,
      showMarkAsReadyModal,
      hideMarkAsReadyModal
    }
  }
})
</script>

<template>
  <div
    :class="`px-2 py-2 ${
      expanded ? ' bg-white-300 border-b border-gray-100 ' : ''
    }`"
  >
    <div class="flex">
      <button @click.stop="toggleExpanded">
        <CirclePlusIcon
          v-if="expanded === false"
          class="w-7 h-7 text-gray-900"
          data-testid="expand-button"
        />
        <CircleMinusIcon
          v-else
          class="w-7 h-7 text-gray-900"
          data-testid="collapse-button"
        />
      </button>
      <div
        :class="`${
          expanded ? 'bg-white ' : 'bg-white-200'
        } rounded-lg px-3 py-3 ml-3 grid grid-cols-4 xl:grid-cols-12 gap-5 w-full`"
      >
        <OrderId
          :id="order.id"
          :status="order.status"
          :type="ORDER_TYPE.ENVELOPES"
          class="col-span-2"
        />
        <OrderProperty
          class="col-span-2"
          label="Qty. Ordered"
          :value="order.quantity_ordered"
          format="number"
        />
        <OrderProperty
          class="col-span-2 xl:col-span-1"
          label="Unit Price"
          :value="order.unit_cost"
          format="currency"
        />
        <OrderProperty
          class="col-span-2 xl:col-span-1"
          label="Final Price"
          :value="order.total_cost"
          format="currency"
        />
        <OrderProperty
          class="col-span-2"
          label="Order Date"
          :value="order.date_created"
          format="date"
        />
        <OrderProperty
          class="col-span-2"
          label="Delivery to Printer"
          :value="order.availability_date"
          format="datetime"
        />
        <div class="col-span-2">
          <div class="text-gray-700 text-xs px-2 py-1 text-center">
            Order actions
          </div>
          <div
            class="border rounded text-sm text-shadow-50 px-2 py-2 text-center bg-white"
          >
            <!-- 
              commenting out until endpoints are in
              <div v-if="order.status==='pending'" class="flex">
              <LobButton size="small" class="w-1/2" variant="success" @click="showAcceptRejectInventoryModal()">
                Accept
              </LobButton>
              <LobButton size="small" class="w-1/2" variant="error" @click="showAcceptRejectInventoryModal()">
                Reject
              </LobButton>
            </div>
            <LobButton 
              v-else-if="order.status==='printing'"
              size="small" 
              class="w-full" 
              @click="showMarkAsReadyModal()"
              >Mark as Ready</LobButton> -->
            <LobButton variant="none" class="w-full">Ready to Use</LobButton>
          </div>
        </div>
      </div>
    </div>
    <Statement v-if="expanded" :order="orderData" @extend="extendExpiration" />
    <Inventory
      v-if="expanded"
      :order="orderData"
      :loading="updateLoading"
      :update-error="updateError"
      @added="showAddPrinterModal"
      @deleted="remove"
      @showTransfer="showTransferInventoryModal"
      @updated="update"
    />

    <AddPrinterModal
      v-if="addPrinterModalVisible"
      :visible="true"
      :allocable-items="orderData.units_left_to_allocate"
      @close="hideAddPrinterModal"
      @add="add"
    />

    <TransferInventoryModal
      v-if="transferMaterielInventory"
      :materiel-inventory="transferMaterielInventory"
      :allocable-inventory="transferMaterielInventory.num_remaining"
      :materiel-inventory-id="transferMaterielInventory.id"
      :partner-id="transferMaterielInventory.partner_id"
      :partner-name="transferMaterielInventory.partner_name"
      @close="hideTransferInventoryModal"
      @transfer="transfer"
    />

    <AcceptRejectInventoryModal
      v-if="acceptRejectInventoryModalVisible"
      :is-open="acceptRejectInventoryModalVisible"
      :data="order"
      @close="hideAcceptRejectInventoryModal"
    />

    <MarkAsReadyModal
      v-if="markAsReadyModalVisible"
      :is-open="markAsReadyModalVisible"
      :data="order"
      @close="hideMarkAsReadyModal"
    />
  </div>
</template>
