<template>
  <div
    class="text-gray-500 border border-gray-100 rounded-sm flex justify-between mb-3 px-3 py-2 text-xs"
  >
    <div>
      <strong class="mr-2">Email:</strong>
      {{ user_email }}
    </div>
    <div class="px-1">
      <strong class="mr-2">Test ID:</strong>
      {{ id }}
    </div>
    <div class="px-1">
      <strong class="mr-2">Date Created:</strong>
      {{
        formatDate(date_created, {
          timeStyle: 'medium',
          dateStyle: 'medium'
        })
      }}
    </div>
    <div>
      <strong class="mr-2">Date Scheduled:</strong>
      {{
        formatDate(date_scheduled, {
          timeStyle: 'medium',
          dateStyle: 'medium'
        })
      }}
    </div>
  </div>
</template>

<script lang="ts">
import { formatDate } from '@/utils/formatters'
import { defineComponent, PropType } from 'vue'
import { Test } from './useTests'

export default defineComponent({
  props: {
    test: {
      type: Object as PropType<Test>,
      default: () => ({
        user_email: '',
        id: '',
        date_scheduled: '',
        date_created: ''
      })
    }
  },
  setup(props) {
    return {
      ...props.test,
      formatDate
    }
  }
})
</script>
