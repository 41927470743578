<template>
  <div class="flex flex-column">
    <div
      class="bg-mist shadow-md shadow-black rounded w-100 grid grid-cols-6 items-baseline justify-center p-2"
    >
      <div class="pl-3 col-span-4 content-baseline">
        {{ partner.display_name }}
      </div>
      <div class="capitalize flex items-baseline justify-end">
        <Badge class="justify-content-center p-1 text-sm">
          {{ partner.status }}
        </Badge>
      </div>
      <div class="flex justify-end pr-3">
        <button @click="openDetails()">
          <fa
            class="text-slate-300"
            :icon="isDetailOpen ? 'chevron-up' : 'chevron-down'"
          />
        </button>
      </div>
    </div>
    <div
      v-if="isDetailOpen"
      class="flex flex-column w-100 items-baseline justify-items-center text-sm pt-3 pl-2 pb-3 rounded shadow-sm shadow-black"
    >
      <div class="flex flex-col pl-4 my-2">
        <div class="flex flex-row text-xl my-1">Partner Information</div>
        <div class="pl-4 my-1">
          <span class="font-bold">Partner ID:</span>
          <span>{{ partner.id }}</span>
        </div>
        <div class="pl-4 my-1">
          <span class="font-bold">Partner:</span>
          <span>{{ partner.display_name }}</span>
        </div>
        <div class="pl-4 my-1">
          <span class="font-bold">Status:</span>
          <span class="capitalize">{{ partner.status }}</span>
        </div>
      </div>
      <div class="grid grid-cols-6 w-100">
        <div class="pl-4 flex flex-col justify-end">Facility</div>
        <div class="col-start-6 flex justify-end pr-3">
          <PackageButton :on-click="redirectToPartnerFacilities">
            Manage Facilities
          </PackageButton>
        </div>
      </div>
      <div
        v-for="partnerFacility in partnerFacilities"
        :key="partnerFacility.id"
        class="flex flex-column w-100 py-1"
      >
        <PartnersInnerTableRow
          class="flex items-baseline flex-row"
          :partner-facility="partnerFacility"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from '@/router'
import { defineComponent, PropType } from 'vue'
import PartnersInnerTableRow from './PartnersInnerTableRow.vue'
import PackageButton from '../customMail/common/PackageButton.vue'
import { PartnerFacility } from '@/store/partners'

export default defineComponent({
  name: 'PartnersTableRow',
  components: {
    PartnersInnerTableRow,
    PackageButton
  },
  props: {
    partner: {
      type: Object,
      required: true
    },
    partnerFacilities: {
      type: Array as PropType<PartnerFacility[]>,
      required: true
    }
  },
  data() {
    return {
      isDetailOpen: false
    }
  },
  methods: {
    openDetails() {
      this.isDetailOpen = !this.isDetailOpen
    },
    redirectToPartnerFacilities() {
      const currentRoute = this.$route.path
      router.push({
        path: `${currentRoute}/${this.partner.id}/facilities_list`
      })
    }
  }
})
</script>
