import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'

export default {
  path: 'routing',
  component: View,
  children: [
    {
      path: '/partner_ops/routing',
      component: View
    }
  ]
} as RouteRecordRaw
