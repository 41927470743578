<template>
  <div
    class="bg-mist grid grid-cols-6 justify-items-center shadow-md shadow-black"
  >
    <div class="w-100 p-2">
      {{ ability.name }}
    </div>
    <div class="w-100 p-2">
      {{ ability.order_field_name }}
    </div>
    <div class="w-100 p-2">
      {{ ability.operator }}
    </div>
    <div class="w-100 p-2">
      {{ JSON.stringify(ability.order_field_value) }}
    </div>
    <div class="w-100 p-2" />
    <div class="w-100 p-2">
      <PackageButton :on-click="addAbility">Add Ability</PackageButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Ability, setPartnerFacilityAbility } from '@/store/partners'
import PackageButton from '../../customMail/common/PackageButton.vue'

export default defineComponent({
  name: 'ProductsInnerTableRowUnavailable',
  components: {
    PackageButton
  },
  props: {
    partnerId: {
      type: String,
      required: true
    },
    partnerFacilityId: {
      type: String,
      required: true
    },
    ability: {
      type: Object as PropType<Ability>,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  methods: {
    async addAbility() {
      await setPartnerFacilityAbility({
        partnerId: this.partnerId,
        partnerFacilityId: this.partnerFacilityId,
        abilityId: this.ability.id,
        active: true
      })

      this.refreshData()
    }
  }
})
</script>
