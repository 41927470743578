<script lang="ts">
import { defineComponent, Prop } from 'vue'
import { DropdownItem } from '@/consts'
import { UmUser } from './View.vue'

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    } as Prop<UmUser>,
    old: {
      type: Object,
      required: true
    } as Prop<DropdownItem>,
    new: {
      type: Object,
      required: true
    } as Prop<DropdownItem>
  },
  emits: ['cancel', 'confirm'],
  computed: {
    oldPartner(): string {
      return this.old?.label || ''
    },
    newPartner(): string {
      return this.new?.label || ''
    }
  },
  methods: {
    onConfirm() {
      if (!this.user || !this.new) {
        this.$emit('cancel')
        return
      }

      this.$emit('confirm', this.user.id, this.new.id)
    }
  }
})
</script>

<template>
  <Modal width="600px" :visible="isVisible" @close="$emit('cancel')">
    <template #header>
      <div class="w-full text-center">
        <h5>Confirm Partner Change</h5>
      </div>
    </template>

    <div>
      <p>Are you sure you would like to change {{ user?.id }}’s partner?</p>
      <p class="text-sm pt-6 text-gray-500">OLD PARTNER</p>
      <p class="text-lg">{{ oldPartner }}</p>
      <p class="text-sm pt-6 text-mint-500">NEW PARTNER</p>
      <p class="text-lg">{{ newPartner }}</p>
    </div>

    <template #footer>
      <div class="flex self-end">
        <LobButton variant="secondary" @click="$emit('cancel')">
          Cancel
        </LobButton>
        <LobButton variant="primary" class="ml-2" @click="onConfirm">
          Confirm &amp; Change Partner
        </LobButton>
      </div>
    </template>
  </Modal>
</template>
