<template>
  <div class="content p-5">
    <h2>Physical Proofs</h2>
    <p class="subtitle mt-3">
      Send your physical proofs to the following addresses
    </p>
    <div class="row mt-4">
      <div
        v-for="address in addresses"
        :key="address.name"
        class="col-4 address"
      >
        <span class="name">
          {{ address.name }}
        </span>
        <br />
        {{ address.line1 }}
        <br />
        {{ address.line2 }}
      </div>
    </div>
    <div class="row">
      <div class="col-12">Physical Proof Status</div>
    </div>
    <div class="selectionbox">
      <div class="row">
        <div class="col-12">
          <div>
            <p class="mb-3">
              All Proofs
              <a
                class=""
                data-toggle="collapse"
                href="#proofstatusbutton"
                role="button"
                aria-expanded="false"
                aria-controls="proofstatusbutton"
              >
                <fa icon="chevron-down" />
              </a>
            </p>
          </div>
        </div>
      </div>
      <div id="proofstatusbutton" class="collapse row">
        <div v-for="type in types" :key="type.displayTitle" class="col-6 mt-3">
          <div>
            <p class="proofTitle">
              {{ type.displayTitle }}
            </p>
            <template
              v-for="item in type.items"
              :key="checkboxId(type.displayTitle, item.permutation)"
            >
              <p v-if="item.id" class="mb-4 proofStatusContainer">
                {{ item.displayName }}
                <Badge
                  :variant="badgeType(item.steps_completed)"
                  shape="square"
                >
                  {{ badgeText(item.steps_completed) }}
                </Badge>
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">Confirm sending physical proofs</div>
    </div>
    <form>
      <div class="selectionbox">
        <div class="row">
          <div class="col-12">
            <div>
              <p class="mb-3">
                <checkbox
                  v-model="allProofs"
                  label="All Proofs"
                  name="allProofs"
                  value="allProofs"
                  class="allProofs"
                />
                <a
                  class=""
                  data-toggle="collapse"
                  href="#proofbutton"
                  role="button"
                  aria-expanded="false"
                  aria-controls="proofbutton"
                >
                  <fa icon="chevron-down" />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div id="proofbutton" class="collapse">
          <div v-for="type in types" :key="type.displayTitle" class="row">
            <div class="col-12">
              <p class="proofTitle">
                {{ type.displayTitle }}
              </p>
            </div>
            <div class="col-12 checkboxContainer">
              <template
                v-for="item in type.items"
                :key="checkboxId(type.displayTitle, item.permutation)"
              >
                <checkbox
                  v-if="item.id"
                  v-model="selectedProofs"
                  :disabled="allProofs"
                  :label="item.displayName"
                  :name="item.id"
                  :value="item.id"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <Alert v-if="alertMessage" :variant="alertType" class="mt-2">
        {{ alertMessage }}
      </Alert>
      <LobButton class="mt-3" :disabled="pending" @click.prevent="submitProofs">
        Confirm
      </LobButton>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import * as proofing from '@/store/digitalProofing'
import * as partners from '@/store/partners'
import { permutationsToTypes } from '@/components/Onboarding/utils'

interface Type {
  id: string
  title: string
  items: Array<any>
}

export default defineComponent({
  name: 'PhysyicalProofs',
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      allProofs: false,
      selectedProofs: [] as Array<string>,
      alertMessage: null as string | null,
      alertType: 'error',
      pending: false,
      addresses: [
        {
          name: 'Tyler Stewart',
          line1: '5311 D St.',
          line2: 'Sacramento, CA 95819'
        },
        {
          name: 'Mike Mace',
          line1: '1008 Ocean View Ave',
          line2: 'Daly City, CA 94014'
        }
      ]
    }
  },
  computed: {
    types(): Array<Type> {
      const permutations = proofing.getCurrentPartnerPermutations()
      const types = permutationsToTypes(permutations)
      return types
    }
  },
  methods: {
    checkboxId(type, permutation) {
      return `${type}${permutation}`.replace(/ /g, '')
    },
    badgeType(steps) {
      if (steps !== null) {
        if (steps.includes('PHYSICAL_PROOF_APPROVED')) {
          return 'success'
        }
        if (steps.includes('SENT')) {
          return 'warning'
        }
      }
      return 'info'
    },
    badgeText(steps) {
      if (steps !== null) {
        if (steps.includes('PHYSICAL_PROOF_APPROVED')) {
          return 'Approved'
        }
        if (steps.includes('SENT')) {
          return 'Awaiting Approval'
        }
      }
      return 'Proof Not Sent'
    },
    async submitProofs() {
      this.pending = true
      this.alertType = 'error'
      this.alertMessage = null
      try {
        this.types.forEach((type) => {
          const validItems = type.items.filter((item) => {
            if (this.allProofs) {
              return item.id !== ''
            } else {
              return item.id !== '' && this.selectedProofs.includes(item.id)
            }
          })
          validItems.forEach(async (item) => {
            if (item.steps_completed.includes('SENT')) {
              return
            }
            const steps = [...item.steps_completed, 'SENT']
            const id = partners.getCurrentPartnerId()
            if (id == null) return
            await partners.setPermutationCompletedSteps({
              partner: id,
              data: {
                id: item.id,
                steps
              }
            })
          })
        })
        this.alertType = 'success'
        this.alertMessage = 'Save Successful'
      } catch (e) {
        const eResp = e.response
        this.alertType = 'error'
        this.alertMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
      this.pending = false
    }
  }
})
</script>

<style scoped lang="scss">
.address {
  background-color: $light-bluish-gray;
  font-weight: 200;
  margin: 20px;
  max-width: 300px;
  border-radius: 5px;
  border: 1px solid $light-bluish-gray-active;
  .name {
    font-weight: 500;
  }
}
.selectionbox {
  font-weight: 200;
  font-size: 14px;
  border: 1px solid $light-bluish-gray-active;
  padding: 20px;
}
.checkboxContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 20px;
}
.proofStatusContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.allProofs {
  display: inline;
}
.proofTitle {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
}
</style>
