<template>
  <div class="flex">
    <div class="text-2xl mb-5 my-2">Package Permissions</div>
    <div class="flex-1" />
  </div>
  <PackagePermissionsTable :data="data" />
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { PropType } from 'vue'
import { PackagePermission } from '@/store/customMail'
import PackagePermissionsTable from '../common/PackagePermissionsTable.vue'

export default defineComponent({
  name: 'PackagePermissions',
  components: {
    PackagePermissionsTable
  },
  props: {
    data: {
      type: Array as PropType<PackagePermission[]>,
      default: () => [] as PackagePermission[]
    }
  },
  data() {
    return {
      permissions: this.data
    }
  },
  watch: {
    data: function (newVal) {
      this.permissions = newVal
    }
  }
})
</script>

<style lang="scss" scoped></style>
