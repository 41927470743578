<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ResultBooleanBox',
  props: {
    label: { type: String, required: true },
    value: { type: Boolean, required: true }
  }
})
</script>

<template>
  <div
    class="rounded bg-white-100 border border-gray-300 p-2 flex justify-between items-center"
  >
    <div
      class="text-xs text-gray-300 uppercase w-14 font-bold leading-none w-32 mr-3"
    >
      {{ label }}
    </div>
    <div class="text-center flex flex-col items-center">
      <Check v-if="value" class="text-primary-500 w-6"></Check>
      <Close v-if="!value" class="text-primary-500 w-6"></Close>
    </div>
  </div>
</template>
