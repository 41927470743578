import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_P = _resolveComponent("P")!
  const _component_WizardEditor = _resolveComponent("WizardEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentTestSet === null || _ctx.schema === null)
      ? (_openBlock(), _createBlock(_component_P, {
          key: 0,
          "data-testid": "loading"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_loading_indicator)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_WizardEditor, {
          key: 1,
          schema: _ctx.schema,
          "test-set": _ctx.currentTestSet,
          "form-factors": _ctx.formFactors,
          "onUpdate:testSet": _ctx.updateTestSet,
          "onUpdate:formFactor": _ctx.updateFormFactor,
          "onUpdate:ffComponents": _ctx.updateFFComponents
        }, null, 8, ["schema", "test-set", "form-factors", "onUpdate:testSet", "onUpdate:formFactor", "onUpdate:ffComponents"]))
  ]))
}