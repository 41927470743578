import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_P = _resolveComponent("P")!

  return (_ctx.value)
    ? (_openBlock(), _createBlock(_component_P, { key: 0 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.value), 1)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_P, { key: 1 }, {
        default: _withCtx(() => [
          _createTextVNode("Omit")
        ]),
        _: 1
      }))
}