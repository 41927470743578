<template>
  <div v-for="field of formFactorFields" :key="field.id">
    <P class="font-bold">
      {{ field.name }}
    </P>
    <FieldSetRow>
      <SingleValueEditor
        :schema="schema"
        :type="field.type"
        :model-value="getField(field.id)"
        :form-factor="modelValue.form_factor"
        @update:modelValue="setField(field.id, $event)"
      />
    </FieldSetRow>
  </div>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'
import SingleValueEditor from '@/components/TestSetEditor/singleValueEditors/SingleValueEditor.vue'
import { getFieldInfos, FieldInfo } from './util'

export default defineComponent({
  components: {
    SingleValueEditor
  },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    modelValue: {
      validator: () => true,
      required: true
    } as Prop<Record<string, unknown>>
  },
  emits: ['update:modelValue'],
  computed: {
    formFactorFields(): Array<FieldInfo> {
      if (this.schema === undefined) throw new Error('schema is required')
      const desiredFormFactor = this.modelValue?.form_factor
      const ff = this.schema.form_factors.find(
        (x) => x.id === desiredFormFactor
      )
      if (ff === undefined)
        throw new Error(`illegal form factor id ${desiredFormFactor}`)
      return getFieldInfos(ff)
    }
  },
  methods: {
    getField(fieldName) {
      if (this.modelValue === undefined)
        throw new Error('modelValue is required')
      return this.modelValue[fieldName]
    },
    setField(fieldName, newValue) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        [fieldName]: newValue
      })
    }
  }
})
</script>

<style lang="scss" scoped>
#bottomThreeRow {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 1rem;
}
</style>
