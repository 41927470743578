import { Component, Plugin as VuePlugin } from 'vue'

import CenteredBox from './CenteredBox.vue'
import FilePicker from './FilePicker.vue'
import LobFileUpload from './LobFileUpload.vue'
import InlineFigureBox from './InlineFigureBox.vue'
import PortalMainNavigation from './PortalMainNavigation.vue'
import PartnersLogo from './PartnersLogo.vue'
import ShowChildRoutes from './ShowChildRoutes.vue'
import ComposedButton from './ComposedButton.vue'
import FieldSet from './FieldSet.vue'
import FieldSetRow from './FieldSetRow.vue'
import DateTime from './DateTime.vue'
import WizardBox from './WizardBox.vue'

export { default as ShowChildRoutes } from './ShowChildRoutes.vue'

const customComponents = {
  CenteredBox,
  FilePicker,
  LobFileUpload,
  InlineFigureBox,
  PortalMainNavigation,
  PartnersLogo,
  ShowChildRoutes,
  ComposedButton,
  FieldSet,
  FieldSetRow,
  DateTime,
  WizardBox
}

export const globalComponentPlugin: VuePlugin = {
  install(app) {
    // Register our custom components so that we do not have to manually import them every time.
    for (const pair of Object.entries<Component>(customComponents)) {
      const [componentName, component] = pair
      app.component(componentName, component)
    }
  }
}
