<template>
  <div v-if="isAdminOrPopsUser" class="content p-5">
    Component Page for ADMINS or POPS!
  </div>
  <div v-else class="content p-5">Component Page for PARTNERS!</div>
  <lob-table space="md">
    <TableHeader>
      <div>Name</div>
      <div>Description</div>
    </TableHeader>
    <TableBody>
      <TableRow v-for="item in items" :key="item.id">
        <div>{{ item.name }}</div>
        <div>{{ item.description }}</div>
      </TableRow>
      <TableRow :single-cell-row="true">
        <div>{{ item.fullLineDescription }}</div>
      </TableRow>
    </TableBody>
  </lob-table>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { isAdminOrPopsUser } from '@/store/session'

export default defineComponent({
  name: 'Components',
  data() {
    return {
      items: [
        {
          id: '1',
          name: 'name',
          description: 'description'
        },
        {
          id: '2',
          name: 'name',
          description: 'description'
        },
        {
          id: '3',
          name: 'name',
          description: 'description'
        }
      ]
    }
  },
  computed: {
    isAdminOrPopsUser() {
      return isAdminOrPopsUser()
    }
  }
})
</script>

<style lang="scss" scoped></style>
