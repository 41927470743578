<template>
  <div class="flex p-10">Report data moved to your Asana board!</div>
  <!-- <iframe v-if="src" class="embedReport" :src="src" :style="{ height }" />
  <CenteredBox v-else>
    <h3>Loading...</h3>
  </CenteredBox> -->
</template>

<script lang="ts">
// import { getCurrentLookerUrl, setCurrentLookerResource } from '@/store/partners'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReportsEmbed',
  props: {
    resource: { type: String, required: true },
    height: { type: String, default: '100vh' }
  }
  // commenting out until dashboards will be brought back
  // computed: {
  //   src() {
  //     return getCurrentLookerUrl()
  //   }
  // },
  // watch: {
  //   resource() {
  //     setCurrentLookerResource(this.resource)
  //   }
  // },
  // mounted() {
  //   setCurrentLookerResource(this.resource)
  // }
})
</script>

<style scoped>
.embedReport {
  width: 100%;
  border: none;
}
</style>
