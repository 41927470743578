<template>
  <div class="content p-5">
    <P v-if="currentTestSet === null || schema === null" data-testid="loading">
      <loading-indicator></loading-indicator>
    </P>

    <template v-else>
      <WizardEditor
        :schema="schema"
        :test-set="currentTestSet"
        :form-factors="formFactors"
        @update:testSet="updateTestSet"
        @update:formFactor="updateFormFactor"
        @update:ffComponents="updateFFComponents"
      />
    </template>
  </div>
</template>

<script lang="ts">
import WizardEditor from '@/components/TestSetEditor/WizardEditor/WizardEditor.vue'
import {
  getBaseTestSet,
  getSchema,
  getTestSets,
  // TestSet,
  newTestSet
} from '@/store/testSets'
import { backfillTestSet, SchemaField } from '@/store/testSets/schema'
import { defineComponent } from '@vue/runtime-core'
import { deepCopy } from '@/utils/objects'
import { FORM_FACTOR_COMPONENTS } from '@/consts'

function makeBaseTestSet() {
  const base = newTestSet()
  base.fields.from = [
    {
      address_city: 'San Fransisco',
      address_country: 'US',
      address_line1: '5678 Nowhere St',
      address_line2: '',
      address_state: 'CA',
      address_zip: '12345-6789',
      name: 'Luna Lobster'
    }
  ]
  base.fields.to = [
    {
      address_city: 'San Fransisco',
      address_country: 'US',
      address_line1: '1234 Nowhere St',
      address_line2: '',
      address_state: 'CA',
      address_zip: '12345-6789',
      name: 'Larry Lobster'
    }
  ]
  base.fields.fold = [null]
  base.fields.bleed = [null]
  base.fields.mail_date = [null]
  base.fields.target_delivery_date = [null]
  base.fields.return_envelope = [null]
  base.fields.perforated_page = [null]
  base.fields.custom_envelope = [null]
  base.fields.extra_service = [null]
  base.fields.cards = [[]]
  return base
}

export default defineComponent({
  name: 'CreateTestSetEditor',
  components: { WizardEditor },
  data() {
    return {
      formFactors: [
        {
          name: 'Checks',
          id: 'checks',
          factors: [] as SchemaField[],
          currentValues: new Map(),
          expanded: false,
          show: false
        },
        {
          name: 'Letters',
          id: 'letters',
          factors: [] as SchemaField[],
          currentValues: new Map(),
          expanded: false,
          show: false
        },
        {
          name: 'Postcards',
          id: 'postcards',
          factors: [] as SchemaField[],
          currentValues: new Map(),
          expanded: false,
          show: false
        },
        {
          name: 'Self Mailers',
          id: 'self_mailers',
          factors: [] as SchemaField[],
          currentValues: new Map(),
          expanded: false,
          show: false
        }
      ],
      currentTestSet: makeBaseTestSet(),
      savePending: false,
      pageUnloadListener: (e) => {
        if (this.hasChanges) {
          const message =
            'You have unsaved changes. Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      }
    }
  },
  computed: {
    // Unfortunately just doing schema: getSchema makes it unmockable in tests
    schema: () => getSchema(),
    relevantFields(): Array<SchemaField> {
      const schema = this.schema
      const fields = schema?.fields || []
      const formFactors = this.currentTestSet?.fields?.form_factor || []
      return fields.filter(
        (f) =>
          f.id === 'form_factor' ||
          f.form_factors.some((ff) => formFactors.indexOf(ff) !== -1)
      )
    },
    baseTestSet: () => getBaseTestSet(),
    nameCollision(): boolean {
      return getTestSets().some(
        (s) => s.name.toLowerCase() === this.currentTestSet?.name?.toLowerCase()
      )
    },
    hasChanges(): boolean {
      if (this.schema === null) return false
      return (
        JSON.stringify(this.currentTestSet) !==
        JSON.stringify(backfillTestSet(this.schema, this.baseTestSet))
      )
    }
  },
  watch: {
    baseTestSet: {
      handler() {
        this.onBaseDataChange()
      },
      immediate: true
    },
    schema: {
      handler() {
        this.onBaseDataChange()
      },
      immediate: true
    }
  },
  mounted() {
    this.loadFormFactor()
    window.addEventListener('beforeunload', this.pageUnloadListener)
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.pageUnloadListener)
  },
  methods: {
    onBaseDataChange() {
      if (this.schema === null) return
      this.currentTestSet = backfillTestSet(
        this.schema,
        deepCopy(this.baseTestSet)
      )
      this.loadFormFactor()
    },
    updateTestSet(updatedTestSet) {
      this.currentTestSet = updatedTestSet
    },
    updateFormFactor(field) {
      const ff = this.formFactors[field.index]
      if (field.type === FORM_FACTOR_COMPONENTS.CHECKBOX) {
        if (field.put) {
          if (!ff.currentValues.has(field.id))
            ff.currentValues.set(field.id, [])
          ff.currentValues.get(field.id).push(field.value)
        } else {
          const indexOfValue = ff.currentValues
            .get(field.id)
            .indexOf(field.value)
          ff.currentValues.get(field.id).splice(indexOfValue, 1)
          if (ff.currentValues.get(field.id).length === 0)
            ff.currentValues.delete(field.id)
        }
      } else {
        ff.currentValues.set(field.id, field.value)
        if (field.value.length === 0) ff.currentValues.delete(field.id)
      }
    },
    async loadFormFactor() {
      const schema = this.schema
      const fields = schema?.fields || []
      const ff = this.formFactors as any[]
      const hiddenFactors = new Set(['form_factor', 'to', 'from'])
      for (const factor of ff) {
        // seperate schema fields into form factors
        let currentFactors = fields.filter(
          (f) =>
            !hiddenFactors.has(f.id) &&
            f.form_factors.some((factorID) => factorID === factor.id)
        )
        currentFactors = currentFactors.map((formFactorField) => {
          const factorFieldType = formFactorField.type
          if (!factorFieldType || !Array.isArray(factorFieldType))
            return formFactorField

          // filtering nested fields to display only relevant field options
          const filteredFieldType = factorFieldType.filter((f) =>
            f.form_factors.some((factorID) => factorID === factor.id)
          )
          return {
            ...formFactorField,
            type: filteredFieldType
          }
        })
        factor.factors = currentFactors
        // loading in a duplicate/edit test set
        if (
          this.currentTestSet.fields.form_factor &&
          this.currentTestSet.fields.form_factor.includes(factor.id)
        ) {
          if (this.currentTestSet.fields[factor.id]) {
            const factorFields = this.currentTestSet.fields[
              factor.id
            ][0] as any[]
            for (const fieldID in factorFields) {
              if (!hiddenFactors.has(fieldID)) {
                factor.currentValues.set(fieldID, factorFields[fieldID])
              }
            }
            // test set uses the new form factor format
          } else {
            factor.currentValues = this.setCurrentFactors(
              factor.currentValues,
              factor
            )
          }
          factor.show = true
        }
        factor.currentValues.set('formFactor', factor.id)
      }
      this.formFactors = ff
    },
    setCurrentFactors(factorMap, formFactor) {
      for (const factor of formFactor.factors) {
        if (this.currentTestSet.fields[factor.id].length !== 0) {
          // add resources to their respective form factor
          if (factor.id === 'resource') {
            const resources = [] as any[]
            for (const resource of this.currentTestSet.fields.resource as any) {
              // if field is resource, separate by form factor
              if (resource.form_factor === formFactor.id) {
                resources.push(resource)
              }
            }
            factorMap.set(factor.id, resources)
          } else {
            factorMap.set(factor.id, this.currentTestSet.fields[factor.id])
          }
        }
      }
      return factorMap
    },
    updateFFComponents(ff) {
      this.formFactors = ff
    }
  }
})
</script>

<style lang="scss" scoped></style>
