<template>
  <tr class="drop-zone bg-mist table-row mt-20 shadow-md w-100">
    <td class="p-3 w-2/12" draggable="false">
      {{ item.account_id ? 'Account ID:' : 'Tiers:' }}
    </td>
    <td class="p-3 w-6/12" draggable="false">
      {{ item.account_id || item.tiers.join(', ') }}
    </td>
    <td class="p-3 w-2/12" draggable="false">{{ item.date_effective }}</td>
    <td class="p-3 w-2/12" draggable="false">{{ item.date_expired || '-' }}</td>
  </tr>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { PackagePermission } from '@/store/customMail'

export default defineComponent({
  name: 'PackagePermissionsTableRow',
  props: {
    item: { type: Object as PropType<PackagePermission>, required: true }
  }
})
</script>
