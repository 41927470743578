<template>
  <Modal :visible="visible" width="600px" @close="$emit('reset')">
    <template #header>
      <div class="text-3xl font-thin" data-testId="confirm-modal-title">
        {{ title }}
      </div>
    </template>

    <div class="px-2 max-w-xl">
      <div class="text-lg mb-2">{{ message }}</div>
      <div class="flex justify-end pt-3 px-2">
        <LobButton
          variant="secondary"
          class="w-28"
          data-testId="cancel-button"
          @click="$emit('reset')"
        >
          <span>Cancel</span>
        </LobButton>
        <LobButton
          variant="primary"
          class="w-28 ml-2"
          data-testId="validate-button"
          @click="onConfirm"
        >
          {{ confirmButtonLabel }}
        </LobButton>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfirmationModal',
  props: {
    confirmButtonLabel: { type: String, default: 'Update' },
    confirmArgs: { type: Array, default: () => [] },
    message: { type: String, required: true },
    title: { type: String, default: 'Confirm' },
    visible: { type: Boolean, default: false }
  },
  emits: ['close', 'reset', 'updated'],
  setup(props, { emit }) {
    const onConfirm = () => {
      emit('updated', ...props.confirmArgs)
      emit('close')
    }

    return { onConfirm }
  }
})
</script>
