<template title="Bulk Jobs">
  <div class="content p-5">
    <header class="mb-4">
      <h2>Bulk Jobs</h2>
      <p>Schedule and create Bulk Jobs to Flush</p>
    </header>
    <div class="body-container">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: isActive('newJob') }"
            href="#newJob"
            @click.prevent="setActive('newJob')"
          >
            New Job
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: isActive('jobQueue') }"
            href="#jobQueue"
            @click.prevent="setActive('jobQueue')"
          >
            Job Queue
          </a>
        </li>
      </ul>
      <div id="myTabContent" class="tab-content py-3">
        <div
          id="newJob"
          class="tab-pane fade"
          :class="{ 'active show': isActive('newJob') }"
        >
          <JobOptions />
        </div>
        <div
          id="jobQueue"
          class="tab-pane fade"
          :class="{ 'active show': isActive('jobQueue') }"
        >
          <JobsTable
            :jobs-loaded="jobsLoaded"
            :jobs="jobs"
            @loadJobs="loadJobs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getJobs, Job } from '@/store/jobs'
import JobsTable from './Table.vue'
import JobOptions from './BulkJobs.vue'
export default defineComponent({
  name: 'BulkJobs',
  components: { JobsTable, JobOptions },
  data() {
    return {
      jobQueueSelected: true,
      activeItem: 'newJob',
      jobs: [] as Job[],
      jobsLoaded: false
    }
  },
  methods: {
    toggleView(mode) {
      this.jobQueueSelected = mode !== 'new'
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    },
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    async loadJobs() {
      const jobs = await getJobs()
      this.jobs = jobs
      this.jobsLoaded = true
    }
  }
})
</script>
