import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a198300"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_ProductsTableRow = _resolveComponent("ProductsTableRow")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LoadingIndicator, null, {
      default: _withCtx(() => [
        (!_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.errorMessage !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_Alert, { variant: "error" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productAbilities, (product) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: product.id,
                        class: "mb-1 shadow-md shadow-black"
                      }, [
                        _createVNode(_component_ProductsTableRow, {
                          "partner-id": _ctx.partnerId,
                          "partner-facility-id": _ctx.partnerFacilityId,
                          product: product,
                          "available-abilities": 
                _ctx.abilitiesByProduct[product.order_field_value]?.available || []
              ,
                          "unavailable-abilities": 
                _ctx.abilitiesByProduct[product.order_field_value]?.unavailable || []
              ,
                          "refresh-data": _ctx.refreshData
                        }, null, 8, ["partner-id", "partner-facility-id", "product", "available-abilities", "unavailable-abilities", "refresh-data"])
                      ]))
                    }), 128))
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}