import { Store } from 'vuex'

class RegisteredWatcher<T> {
  /* eslint no-useless-constructor: "off" */
  constructor(
    public valueToWatch: () => T,
    public action: (value: T) => void
  ) {}
}

/* eslint "@typescript-eslint/no-explicit-any": "off" */
const watchers: Array<RegisteredWatcher<any>> = []
const startupListeners: Array<() => void> = []
const mockDataSetups: Array<() => void> = []

// This pushes a watcher to a queue to be registered when the actual store
// is created.
export function registerWatcher<T>(
  valueToWatch: () => T,
  action: (value: T) => void
): void {
  watchers.push(new RegisteredWatcher(valueToWatch, action))
}

// This pushes a function to a queue to be executed once the actual store
// is created. They will only be called after all queued watchers are
// registered, so you can count on them picking up any changes your
// listener makes.
export function registerStartupListener(listener: () => void): void {
  startupListeners.push(listener)
}

// This stores a function that should set up some mock data when called.
// It will be called if it is detected that we are running in a testing
// environment.
export function registerMockDataSetup(theSetup: () => void): void {
  mockDataSetups.push(theSetup)
}

// Applies all registered watchers, then all registered startup listeners,
// to the specified store object.
export function applyRegisteredItems<T>(store: Store<T>): void {
  for (const watcher of watchers) {
    store.watch(watcher.valueToWatch, watcher.action)
  }
  for (const startupListener of startupListeners) {
    startupListener()
  }
  if (process.env?.NODE_ENV === 'test') {
    for (const mockDataSetup of mockDataSetups) {
      mockDataSetup()
    }
  }
}
