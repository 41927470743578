import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'

export default {
  path: 'partners',
  component: View,
  children: [
    {
      path: '/partner_ops/partners',
      component: View
    }
    // Examples:
    /* childRoute */
    /* {
      path: 'variant1',
      component: View,
      props: {
        variant: 1
      }
    } */
  ]
} as RouteRecordRaw
