<template>
  <div id="onboarding-root">
    <!--
      Cannot set both x: shown and y: scroll
      https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
    -->
    <Checklist :list="list" title="Onboarding" />
    <main id="content" class="overflow-y-auto">
      <router-view />
    </main>
  </div>
</template>

<script lang="ts">
import Checklist from '@/components/Onboarding/Checklist.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Onboarding',
  components: {
    Checklist
  },
  computed: {
    list: () => [
      {
        title: 'General Onboarding',
        redirectToGitbook: true,
        items: [
          {
            text: 'Company Info',
            complete: false,
            href: '/partners/current/onboarding/company-info'
          },
          {
            text: 'Zero Production Day',
            complete: false,
            href: '/partners/current/onboarding/zero-production-day'
          },
          {
            text: 'Print Capabilities',
            complete: false,
            href: '/partners/current/onboarding/print-capabilities'
          }
        ]
      },
      {
        title: 'Tech Setup',
        items: [
          {
            text: 'SFTP Setup',
            complete: false,
            href: '/partners/current/onboarding/sftp-setup'
          }
        ]
      }
    ]
  }
})
</script>

<style lang="scss" scoped>
#onboarding-root {
  display: grid;
  grid-template-columns: auto 1fr;
}

#content {
  height: 100vh;
}
</style>
