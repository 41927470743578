import { sendSidRequest } from '../requests'
import { getUser } from '../session'
// import { getUser } from '../session'
import { defineVuexModule } from '../util'
import { registerWatcher } from '../watcherRegistry'
// import { registerWatcher } from '../watcherRegistry'
import { Schema } from './schema'

export interface TestSet {
  id: string
  name: string
  description: string
  fields: Record<string, Array<unknown>>
  error: boolean
  ready: boolean
  // eslint-disable-next-line camelcase
  status_message: string
  // eslint-disable-next-line camelcase
  csv_key: string
  isEditing: boolean
}

export interface UploadedArtwork {
  filename: string
  url: string
}

interface State {
  artworks: Array<UploadedArtwork>
  schema: Schema | null
  testSets: Array<TestSet>
  // Used as the starting point for creating a new test set.
  baseTestSet: TestSet
  refreshTimeout: number | null
}

export interface TestCorpusEnvelope {
  id: string
  letter_size: string
  size: string
  description: string
  design: string
  countries: string[]
}

export interface TestCorpusCard {
  id: string
  description: string
  orientation: string
  size: string
}

export interface TestCorpusReturnEnvelope {
  id: string
}

export const newTestSet = (): TestSet => ({
  id: '',
  name: 'New Test Set',
  description: 'new test set',
  fields: {},
  ready: false,
  error: false,
  status_message: 'Pending...',
  csv_key: '',
  isEditing: false
})

const initialState: State = {
  artworks: [],
  schema: null,
  testSets: [],
  baseTestSet: newTestSet(),
  refreshTimeout: null
}

const storeModule = defineVuexModule('testSets', initialState)

// GETTERS (exported functions that provide pieces of data from the state)

export const getSchema = storeModule.read((state) => state.schema, 'getSchema')

export const getTestSets = storeModule.read(
  (state) => state.testSets,
  'getTestSets'
)

export const getBaseTestSet = storeModule.read(
  (state) => state.baseTestSet,
  'getBaseTestSet'
)

export const getArtworks = storeModule.read(
  (state) => state.artworks,
  'getArtworks'
)

// MUTATORS (synchronous functions that change the state, each call to a mutator
// is logged in the Vue development tools)

const setSchema = storeModule.commit((state, schema: Schema | null) => {
  state.schema = schema
}, 'setSchema')

const setTestSets = storeModule.commit((state, testSets: Array<TestSet>) => {
  state.testSets = testSets
  state.testSets.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
}, 'setTestSets')

export const setBaseTestSet = storeModule.commit((state, testSet: TestSet) => {
  state.baseTestSet = testSet
}, 'setBaseTestSet')

const setArtworks = storeModule.commit(
  (state, artworks: Array<UploadedArtwork>) => {
    state.artworks = artworks
  },
  'setArtworks'
)

export const setRefreshTimeout = storeModule.commit(
  (state, timeout: number) => {
    if (state.refreshTimeout !== null) {
      clearTimeout(state.refreshTimeout)
    }
    state.refreshTimeout = setTimeout(refreshTestSets, timeout)
  },
  'setRefreshTimeout'
)

// ACTIONS (asynchronous functions which can call other actions as well as
// getters and setters, also logged in the Vue development tools)

const refreshSchema = storeModule.dispatch(async () => {
  const response = await sendSidRequest<Schema>({
    method: 'GET',
    path: '/testing/schema'
  })
  if (response.status === 200) {
    setSchema(response.data)
  }
}, 'refreshSchema')

const refreshTestSets = storeModule.dispatch(async () => {
  const response = await sendSidRequest<Array<TestSet>>({
    method: 'GET',
    path: '/testing/sets'
  })
  if (response.status === 200) {
    setTestSets(response.data)
    const hasPendingItems = response.data.some((x) => !x.ready && !x.error)
    if (hasPendingItems) {
      setRefreshTimeout(3_000)
    }
  }
}, 'refreshTestSets')

const refreshArtworks = storeModule.dispatch(async () => {
  const response = await sendSidRequest<Array<UploadedArtwork>>({
    method: 'GET',
    path: '/testing/artworks'
  })
  if (response.status === 200) {
    setArtworks(response.data)
  }
}, 'refreshArtworks')

export const createTestSet = storeModule.dispatch(async (_, ts: TestSet) => {
  const tsParams = {
    name: ts.name,
    description: ts.description,
    fields: ts.fields,
    is_editing: ts.isEditing
  }
  const response = await sendSidRequest({
    method: 'POST',
    path: '/testing/sets',
    data: tsParams
  })
  if (response.status < 400) {
    await refreshTestSets()
  }
}, 'createTestSet')

export const regenerateResource = storeModule.dispatch(
  async (_, ts: TestSet) => {
    const tsParams = {
      id: ts.id
    }
    const response = await sendSidRequest({
      method: 'POST',
      path: `/testing/sets/regenerate/${tsParams.id}`
    })
    if (response.status < 400) {
      await refreshTestSets()
    }
  },
  'regenerateResource'
)

export const uploadArtwork = storeModule.dispatch(
  async (
    ctx,
    params: {
      file: string | Blob
    }
  ) => {
    const data = new FormData()
    data.append('file', params.file)

    const result = await sendSidRequest<UploadedArtwork>({
      method: 'POST',
      path: '/testing/artworks',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })

    await refreshArtworks()

    return result.data
  },
  'uploadArtwork'
)

export const downloadPermutationCSV = storeModule.dispatch(
  async (
    ctx,
    params: {
      id: string
    }
  ) => {
    const response = await sendSidRequest({
      method: 'GET',
      path: `/testing/sets/${params.id}/csv`
    })
    if (response.status < 400) {
      return response.data
    }
  },
  'downloadPermutationCSV'
)

export const deleteTestSetID = storeModule.dispatch(
  async (
    ctx,
    params: {
      id: string
    }
  ) => {
    const response = await sendSidRequest({
      method: 'POST',
      path: `/testing/sets/${params.id}/delete`
    })
    if (response.status < 400) {
      await refreshTestSets()
      return response.data
    }
  },
  'deleteTestSetID'
)

export const retrieveTestCorpusEnvelopes = storeModule.dispatch(async () => {
  const response = await sendSidRequest({
    method: 'GET',
    path: `/testing/resources/envelopes`
  })
  if (response.status < 400) {
    return response.data
  }
}, 'retrieveTestCorpusEnvelopes')

export const retrieveTestCorpusCards = storeModule.dispatch(async () => {
  const response = await sendSidRequest({
    method: 'GET',
    path: `/testing/resources/cards`
  })
  if (response.status < 400) {
    return response.data
  }
}, 'retrieveTestCorpusCards')

export const retrieveTestCorpusReturnEnvelopes = storeModule.dispatch(
  async () => {
    const response = await sendSidRequest({
      method: 'GET',
      path: `/testing/resources/return_envelopes`
    })
    if (response.status < 400) {
      return response.data
    }
  },
  'retrieveTestCorpusReturnEnvelopes'
)

// WATCHERS (functions that are called when a value in the store changes,
// possibly one from a different module.)

registerWatcher(
  () => getUser(),
  (user) => {
    if (user !== null && user.role === 'lob-admin') {
      refreshSchema()
      refreshTestSets()
      refreshArtworks()
    }
  }
)
