import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow" }
const _hoisted_2 = { class: "rounded-t bg-gray-500 flex justify-between items-stretch" }
const _hoisted_3 = { class: "text-white text-xs px-2 py-1 font-light flex-grow overflow-hidden truncate" }
const _hoisted_4 = { class: "border bg-white rounded-b text-sm font-light px-2 py-2 overflow-hidden truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogsIcon = _resolveComponent("LogsIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.type === _ctx.ORDER_TYPE.CARDS ? 'Card Order ID' : 'Env Order ID'), 1),
      _createElementVNode("div", {
        class: _normalizeClass(`
           text-xs
          px-3
          py-1
          rounded-t
          ${_ctx.statusToColor(_ctx.status)}
          uppercase
          font-light
          flex-shrink
          trucate
          tracking-wider`)
      }, _toDisplayString(_ctx.status === 'printing' ? 'in production' : _ctx.status), 3)
    ]),
    _createElementVNode("h3", _hoisted_4, [
      _createTextVNode(_toDisplayString(_ctx.id) + " ", 1),
      _createVNode(_component_LogsIcon, {
        query: { materiel_order_id: _ctx.id }
      }, null, 8, ["query"])
    ])
  ]))
}