<template title="Spoilage">
  <div class="content p-5">
    <div
      class="flex align-middle text-center text-primary-300 cursor-pointer"
      @click="moveToPreviousPage"
    >
      <ArrowLeft class="w-5 h-5 mt-0.5" />
      Back to Inventory
    </div>
    <h2 class="mt-3 mb-5">
      Manage Spoilage {{ isGlobalSpoilage ? '' : `for ${accountName}` }}
    </h2>

    <Card
      v-for="(inventoryType, index) of inventoryTypes"
      :key="inventoryType.title"
      class="mb-3"
    >
      <h6 class="font-bold">
        {{ inventoryType.title }}
      </h6>
      <div
        id="tableHeaders"
        class="flex w-100 mt-3 border-b-2 border-b-grayDove pb-2"
      >
        <div class="w-1/4">Spoilage Quantity</div>
        <div class="w-1/5">Spoilage Percentage</div>
      </div>
      <div
        v-if="
          !inventoryType.isEditingValues && inventoryType.values.length !== 0
        "
        id="staticTableValues"
      >
        <div v-for="value of inventoryType.values" :key="value.id" class="flex">
          <div class="w-1/4">
            {{ value.min_volume }} - {{ value.max_volume }}
          </div>
          <div class="w-1/5">{{ value.spoilage_rate }}%</div>
        </div>
      </div>
      <div
        v-else-if="
          !inventoryType.isEditingValues && inventoryType.values.length === 0
        "
        id="emptyTable"
        class="italic mt-2"
      >
        No spoilage has been set.
      </div>
      <div v-else>
        <div
          v-for="(value, valueIndex) of inventoryType.values"
          :key="value.id"
        >
          <div v-if="!value.deleted" class="flex mt-2">
            <div class="w-1/4 flex pr-2">
              <TextInput
                id="min"
                v-model="value.min_volume"
                label=""
                size="small"
                placeholder="Min"
                class="mr-2"
              />
              <TextInput
                id="name"
                v-model="value.max_volume"
                label=""
                size="small"
                placeholder="Max"
              />
            </div>
            <div class="w-1/5">
              <TextInput
                id="name"
                v-model="value.spoilage_rate"
                label=""
                size="small"
                placeholder="Percentage"
              />
            </div>
            <Trash
              class="w-6 ml-2 text-chineseOrange-500 self-center cursor-pointer"
              @click="deleteQuantity(index, valueIndex)"
            />
          </div>
        </div>
      </div>

      <div
        v-if="inventoryType.isEditingValues"
        class="flex align-middle text-center text-primary-300 cursor-pointer mt-3 pb-2 hover:underline"
        @click="addQuantity(index)"
      >
        <Plus class="w-5 h-5 mt-0.5" />
        Add Quantity
      </div>

      <Alert v-if="inventoryType.errorMessage" variant="error" class="mt-2">
        {{ inventoryType.errorMessage }}
      </Alert>

      <div class="flex items-end mt-2">
        <div class="flex-1" />
        <div v-if="inventoryType.isEditingValues" class="flex">
          <LobButton
            class="mr-2"
            variant="secondary"
            size="small"
            @click="cancelEditing(index)"
          >
            Cancel
          </LobButton>
          <LobButton
            size="small"
            :disabled="continueDisabled"
            @click="save(index)"
          >
            Confirm
          </LobButton>
        </div>
        <LobButton
          v-else
          size="small"
          variant="secondary"
          @click="startEditing(index)"
        >
          Edit
        </LobButton>
      </div>
    </Card>
  </div>
</template>

<script lang="ts">
import router from '@/router'
import { fetchAccountName, getAccountNamesById } from '@/store/accounts'
import {
  getSpoilageRates,
  Spoilage,
  updateSpoilageRate
} from '@/store/inventory'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Spoilage',
  data() {
    return {
      inventoryTypes: [
        {
          id: 'custom_envelope',
          title: 'Custom Envelopes',
          values: [] as Spoilage[],
          savedValues: [] as Spoilage[],
          isEditingValues: false,
          errorMessage: null as string | null
        },
        {
          id: 'return_envelope',
          title: 'Return Envelopes',
          values: [] as Spoilage[],
          savedValues: [] as Spoilage[],
          isEditingValues: false,
          errorMessage: null as string | null
        },
        {
          id: 'card',
          title: 'Cards',
          values: [] as Spoilage[],
          savedValues: [] as Spoilage[],
          isEditingValues: false,
          errorMessage: null as string | null
        },
        {
          id: 'buckslip',
          title: 'Buckslips',
          values: [] as Spoilage[],
          savedValues: [] as Spoilage[],
          isEditingValues: false,
          errorMessage: null as string | null
        }
      ],
      accountName: '' as string | null,
      accountCrid: '' as string | null,
      isGlobalSpoilage: true
    }
  },
  async mounted() {
    const crid = this.$route.params.crid as string
    if (crid !== 'global') {
      await fetchAccountName(crid)
      this.accountName = getAccountNamesById()[crid]
      this.isGlobalSpoilage = false
      this.accountCrid = crid
    } else {
      this.accountName = null
    }
    const resp = await getSpoilageRates({ accountID: crid })
    this.setSpoilageRates(resp)
  },
  methods: {
    setSpoilageRates(rates: Spoilage[]) {
      rates.map((rate) => {
        const inventoryTypeIndex = this.inventoryTypes.findIndex(
          (componentType) => rate.component_type === componentType.id
        )
        // convert to string since Lob text inputs takes in strings
        rate = this.convertRateValuesToString(rate)
        this.inventoryTypes[inventoryTypeIndex].values.push(rate)
      })
    },
    convertRateValuesToString(rate: Spoilage): Spoilage {
      rate.max_volume = rate.max_volume.toString()
      rate.min_volume = rate.min_volume.toString()
      rate.spoilage_rate = rate.spoilage_rate.toString()
      return rate
    },
    addQuantity(index: number) {
      this.inventoryTypes[index].values.push({} as Spoilage)
    },
    startEditing(index: number) {
      // save the values in case the cancel button is clicked
      this.inventoryTypes[index].savedValues = JSON.parse(
        JSON.stringify(this.inventoryTypes[index].values)
      )
      this.inventoryTypes[index].isEditingValues = true
    },
    cancelEditing(index: number) {
      this.inventoryTypes[index].values = this.inventoryTypes[index].savedValues
      this.inventoryTypes[index].errorMessage = null
      this.inventoryTypes[index].isEditingValues = false
    },
    async save(index: number) {
      const errorMessage = this.valuesAreNotValid(
        this.inventoryTypes[index].values
      )
      if (errorMessage) {
        this.inventoryTypes[index].errorMessage = errorMessage
        return
      }
      try {
        await Promise.all(
          this.inventoryTypes[index].values.map(async (rate, rateIndex) => {
            const resp = await updateSpoilageRate({
              spoilageRate: rate,
              type: this.inventoryTypes[index].id,
              accountID: this.accountCrid
            })
            if (resp?.data.id) {
              rate = this.convertRateValuesToString(resp.data)
              this.inventoryTypes[index].values[rateIndex] = rate
            }
          })
        )
        // delete rates from index that are marked 'deleted'
        this.inventoryTypes[index].values = this.inventoryTypes[
          index
        ].values.filter((rate) => !rate.deleted)
        this.inventoryTypes[index].errorMessage = null
        this.inventoryTypes[index].isEditingValues = false
      } catch (e: any) {
        const err = e.response.data
        this.inventoryTypes[
          index
        ].errorMessage = `${err.statusCode} Error: ${err.error} - ${err.message}`
      }
    },
    valuesAreNotValid(values: Spoilage[]) {
      for (const [index, value] of values.entries()) {
        if (value.deleted) continue
        // check for missing values
        if (
          value.max_volume === '' ||
          !value.max_volume ||
          value.min_volume === '' ||
          !value.min_volume ||
          value.spoilage_rate === '' ||
          !value.spoilage_rate
        ) {
          return 'Some values are missing. Please correct.'
        }
        const min_volume = parseInt(value.min_volume as string)
        const max_volume = parseInt(value.max_volume as string)
        const spoilage_rate = parseFloat(value.spoilage_rate as string)
        // check if any min value is greater than max value
        if (min_volume > max_volume) {
          return 'There is a min value greater than a max value. Please correct.'
        }
        // check if any value is not a number
        if (isNaN(min_volume) || isNaN(max_volume) || isNaN(spoilage_rate)) {
          return 'There is a value that is not a number. Please correct.'
        }
        // check if min or max is in between another min/max in other quantities
        const quantities = values
          .slice(0, index)
          .concat(values.slice(index + 1))
        if (
          this.valueIsInbetween(min_volume, quantities) ||
          this.valueIsInbetween(max_volume, quantities)
        ) {
          return 'There is a quantity that is the range of another quantity. Please correct.'
        }
      }
      return null
    },
    valueIsInbetween(valueToCheck: number, values: Spoilage[]) {
      for (const value of values) {
        // if value is marked for deletion, continue
        if (value.deleted) continue
        if (
          valueToCheck >= parseInt(value.min_volume as string) &&
          valueToCheck <= parseInt(value.max_volume as string)
        ) {
          return true
        }
      }
      return false
    },
    deleteQuantity(index: number, valueIndex: number) {
      if (this.inventoryTypes[index].values[valueIndex].id) {
        // if it already exists mark it for deletion in backend
        this.inventoryTypes[index].values[valueIndex].deleted = true
      } else {
        this.inventoryTypes[index].values.splice(valueIndex, 1)
      }
    },
    moveToPreviousPage() {
      // moves back one page
      router.go(-1)
    }
  }
})
</script>
