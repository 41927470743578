<template>
  <LoadingIndicator>
    <div v-if="!isLoading" class="p-5">
      <TestingHeader />
      <Card class="min-w-min w-100">
        <div class="flex flex-row text-xl">
          <div class="flex-1" />
          <LobButton size="small" class="text-sm" @click="openTestSendModal">
            New Test Send
          </LobButton>
        </div>
        <LoadingIndicator>
          <Table
            v-if="!tableLoading"
            :headers="tableHeaders"
            :row-object="tableRowObject"
            :data="tableData"
            :row-expandable="true"
            :pagination="true"
            :data-length="tableLength"
            :current-page="page"
            @updatePage="updatePage"
            @updateSortedHeaders="updateSortedHeaders"
            @updateTableData="updateTableData"
          >
            <template #expandedContent="expandedProps">
              <div class="p-3">
                <Table
                  variant="secondary"
                  :headers="innerTableHeaders"
                  :row-object="innerTableRowObjects"
                  :data="tableData[expandedProps.index].innerTableData"
                  @updateSortedHeaders="updateSortedHeaders"
                  @updateTableData="updateTableData"
                />
              </div>
              <div class="p-3">
                <Table
                  variant="secondary"
                  :headers="testSendOrdersTableHeaders"
                  :row-object="testSendOrdersRowObjects"
                  :data="tableData[expandedProps.index].testSendOrders"
                />
              </div>
            </template>
          </Table>
        </LoadingIndicator>
      </Card>
      <TestSendModal :is-open="openNewTestSend" @close="closeNewTestSend" />
    </div>
  </LoadingIndicator>
</template>

<script lang="ts">
import {
  dateFormatter,
  getTestSends,
  PaginationLinks,
  TestSendList,
  getTestSendOrders,
  loadMoreTestResourcesByUrl
} from '@/store/customMail'
import { formatDate } from '@/utils/formatters'
import { defineComponent } from '@vue/runtime-core'
import Table from '../../../../components/common/table/Table.vue'
import TestSendModal from './Modals/TestSendModal.vue'
import TestingHeader from './TestingHeader.vue'

export default defineComponent({
  name: 'TestSends',
  components: {
    Table,
    TestingHeader,
    TestSendModal
  },
  data() {
    return {
      isLoading: false,
      tableLoading: false,
      openNewTestSend: false,
      tableHeaders: [
        { name: 'Status', span: 1, width: 'w-1/12' },
        { name: 'Partners', span: 1, width: 'w-3/12' },
        { name: 'Test Type', span: 1, width: 'w-2/12' },
        { name: 'Quantity', span: 1, width: 'w-1/12' },
        { name: 'Date Sent', span: 1, width: 'w-2/12' },
        { name: 'Tests', span: 1, width: 'w-3/12' }
      ],
      tableRowObject: [
        {
          fieldName: 'status',
          type: 'badge',
          calculateVariant: (fieldStatus) => {
            if (fieldStatus === 'failed') {
              return 'error'
            } else if (fieldStatus === 'delivered') {
              return 'success'
            } else {
              return 'info'
            }
          },
          width: 'w-1/12'
        },
        {
          fieldName: 'stringifiedPartners',
          type: 'string',
          width: 'w-3/12'
        },
        {
          fieldName: 'type',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'quantity',
          type: 'string',
          width: 'w-1/12'
        },
        {
          fieldName: 'date_created',
          type: 'string',
          width: 'w-2/12'
        },
        {
          children: [
            {
              fieldName: 'tests',
              type: 'button',
              buttonText: 'CSV',
              variant: 'primary',
              icon: 'download'
            },
            {
              fieldName: 'tests',
              type: 'button',
              buttonText: 'Send CSV',
              icon: 'link',
              variant: 'secondary'
            }
          ],
          width: 'w-3/12'
        }
      ],
      innerTableHeaders: [
        { name: 'Test Case/Group', span: 1, width: 'w-2/12' },
        { name: 'ProuctID', span: 1, width: 'w-1/12' },
        { name: 'Quantity', span: 1, width: 'w-1/12' },
        { name: 'Description', span: 1, width: 'w-8/12' }
        // { name: 'Creative', span: 1 },
        // { name: 'Address Group', span: 1 }
      ],
      innerTableRowObjects: [
        {
          fieldName: 'id',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'product_id',
          type: 'string',
          width: 'w-1/12'
        },
        {
          fieldName: 'quantity',
          type: 'string',
          width: 'w-1/12'
        },
        {
          fieldName: 'description',
          type: 'string',
          width: 'w-8/12'
        }
        // commenting out until preview images are ready
        // {
        //   fieldName: 'creative',
        //   type: 'string',
        // },
        // commenting out until address groups are added in
        // {
        //   fieldName: 'address_group',
        //   type: 'string'
        // },
      ],
      testSendOrdersTableHeaders: [
        { name: 'PartnerID', span: 1, width: 'w-2/12' },
        { name: 'Created', span: 1, width: 'w-2/12' },
        { name: 'Rendered', span: 1, width: 'w-2/12' },
        { name: 'Routing', span: 1, width: 'w-2/12' },
        { name: 'Completed', span: 1, width: 'w-2/12' },
        { name: 'Failed', span: 1, width: 'w-2/12' }
      ],
      testSendOrdersRowObjects: [
        {
          fieldName: 'partner_id',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'created',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'rendered',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'routing',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'completed',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'failed',
          type: 'string',
          width: 'w-2/12'
        }
      ],
      tableData: [] as any[],
      tableLength: 0,
      links: {} as PaginationLinks,
      sortBy: 'id',
      orderBy: 'DESC',
      page: 1,
      getTestSends: null as any,
      currentUrl: undefined as any,
      rowsCurrentlyOpened: [] as string[]
    }
  },
  async mounted() {
    await this.loadTestSends(undefined, true)
    this.getTestSends = setInterval(async () => {
      await this.loadTestSends(this.currentUrl, false)
    }, 20000)
  },
  async unmounted() {
    clearInterval(this.getTestSends)
  },
  methods: {
    async loadTestSends(url?: string, initialize?: boolean) {
      if (initialize) this.tableLoading = true
      let testSends
      if (url) {
        const strippedURL = url.substring(url.indexOf('/partner_testing'))
        testSends = (await loadMoreTestResourcesByUrl({
          url: strippedURL,
          sort_by: 'date_created'
        })) as TestSendList
      } else {
        testSends = (await getTestSends()) as TestSendList
      }
      this.tableLength = testSends.total_count
      this.links = testSends.links
      this.tableData = await Promise.all(
        testSends.data.map(async (testSend) => {
          const stringifiedPartners = testSend
            .test_send_partners!.map((partner) => partner.partner_id)
            .join(', ')
          // collect total quantity from test cases and test groups
          const quantity =
            testSend.test_send_cases!.reduce(
              (total, testCase) => total + testCase.quantity!,
              0
            ) +
            testSend.test_send_groups.reduce(
              (total, testGroup) => total + testGroup.quantity!,
              0
            )
          const formattedTestGroup = testSend.test_send_groups.map((tg) => {
            return {
              ...tg,
              // take product ids from test case, remove duplicates, and stringify resulting list
              product_id: [
                ...new Set(tg.test_cases?.map((tc) => tc.product_id))
              ].join(', ')
            }
          })

          const parsedRes = [] as any[]
          if (this.rowsCurrentlyOpened.includes(testSend.id)) {
            const res = await this.getAndFormatTestSendOrders(testSend.id)
            parsedRes.push(...res)
          } else {
            parsedRes.push({
              partner_id: 'loading...',
              created: '-',
              rendered: '-',
              routing: '-',
              completed: '-',
              failed: '-'
            })
          }

          return {
            id: testSend.id,
            expanded: this.rowsCurrentlyOpened.includes(testSend.id),
            status: testSend.status,
            stringifiedPartners,
            partners: testSend.test_send_partners,
            type: 'e2e', // currently all types will be e2e tests
            quantity,
            date_created: formatDate(
              testSend.date_created as string,
              dateFormatter
            ),
            innerTableData: testSend.test_send_cases.concat(formattedTestGroup),
            testSendOrders: parsedRes
          }
        })
      )
      this.tableLoading = false
    },
    async updatePage(newPage) {
      const pageDelta = newPage - this.page
      if (pageDelta === 1) {
        this.currentUrl = this.links.next
      } else if (pageDelta === -1) {
        this.currentUrl = this.links.previous
      } else if (pageDelta > 1) {
        this.currentUrl = this.links.last
      } else if (pageDelta < -1) {
        this.currentUrl = this.links.first
      }
      await this.loadTestSends(this.currentUrl, false)
      // clear opened rows when loading new page data
      this.rowsCurrentlyOpened = []
      this.page = newPage
    },
    updateSortedHeaders(headers) {
      this.tableHeaders = headers
    },
    updateTableData(data) {
      this.tableData = data.rows
      // keep track of opened rows for data refreshing
      if (data.updatedRow.expanded) {
        this.rowsCurrentlyOpened.push(data.updatedRow.id)
      } else {
        const removedIndex = this.rowsCurrentlyOpened.indexOf(
          data.updatedRow.id
        )
        this.rowsCurrentlyOpened.splice(removedIndex, 1)
      }
      this.loadTestSends(this.currentUrl, false)
    },
    async closeNewTestSend(options) {
      this.openNewTestSend = false
      if (options && options.refreshData) await this.loadTestSends()
    },
    openTestSendModal() {
      this.openNewTestSend = true
    },
    setPartnerStatusVariant(status) {
      switch (status) {
        case 'created':
          return 'info'
        case 'in_progress':
          return 'warning'
        case 'failed':
          return 'error'
      }
    },
    formatPartnerStatus(status) {
      return status
        .split('_')
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(' ')
    },
    async getAndFormatTestSendOrders(id: string) {
      const res = [] as any[]
      const response = await getTestSendOrders(id)
      // if there are no results, return a single row with filler text
      if (!Object.keys(response.results).length) {
        return [
          {
            partner_id: '-',
            created: '-',
            rendered: '-',
            routing: '-',
            completed: '-',
            failed: '-'
          }
        ]
      }
      for (const partner_id of Object.keys(response.results)) {
        const rowObj = {
          partner_id,
          created: 0,
          rendered: 0,
          routing: 0,
          completed: 0,
          failed: 0
        }
        for (const status of Object.keys(response.results[partner_id])) {
          rowObj[status] = response.results[partner_id][status].count
        }
        res.push(rowObj)
      }
      return res
    }
  }
})
</script>

<style lang="scss" scoped></style>
