<template>
  <div>
    {{ cellData }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

/* 
  tableRowObject for StringCell: 
  {
    fieldName,
    type
  }
*/

export default defineComponent({
  name: 'StringCell',
  props: {
    cellData: {
      type: String || Number,
      default: () => {
        return ''
      },
      required: true
    }
  }
})
</script>
