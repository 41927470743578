<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="checkbox-field-editor-root mb-3">
    <FieldHeader
      :schema="schema"
      :field="field"
      :test-set="testSet"
      supports-separation
      @update:testSet="$emit('update:testSet', $event)"
    />
    <div class="content">
      <Multiselect
        v-if="resourcesLoaded"
        id="default_select"
        :model-value="selectValues"
        label=""
        :options="selectOptions"
        placeholder="Pick a default"
        input-width-class="w-full"
        class="w-full"
        @update:modelValue="updateValues"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Prop } from '@vue/runtime-core'
import FieldHeader from './FieldHeader.vue'
import { arraysEqual } from '@/utils/arrays'
import {
  retrieveTestCorpusCards,
  retrieveTestCorpusEnvelopes,
  retrieveTestCorpusReturnEnvelopes,
  TestCorpusEnvelope,
  TestCorpusReturnEnvelope,
  TestSet
} from '@/store/testSets'
import { Schema, SchemaField } from '@/store/testSets/schema'
import { getShowBetaFeatureFlag } from '@/store/launchDarkly'
import { TEST_SET_CONSTS } from '@/consts'

export default defineComponent({
  name: 'MutliselectMVE',
  components: { FieldHeader },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    } as Prop<SchemaField>,
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    ffCurrentValues: {
      type: Map,
      default: () => {
        return {}
      }
    }
  },
  emits: ['update:testSet', 'update:formFactor'],
  data() {
    return {
      showBetaFeatureFlag: getShowBetaFeatureFlag(),
      selectOptions: [] as any[],
      selectValues: [] as any[],
      defaultEnvelopes: [],
      defaultCards: [],
      resourcesLoaded: false
    }
  },
  async mounted() {
    switch (this.field?.id) {
      case TEST_SET_CONSTS.DEFAULT_CUSTOM_ENVELOPES: {
        const envelopes =
          (await retrieveTestCorpusEnvelopes()) as TestCorpusEnvelope[]
        const formatedEnvelopes = this.loadAndFormatDefaultValues(
          TEST_SET_CONSTS.DEFAULT_CUSTOM_ENVELOPES,
          envelopes
        )
        this.selectOptions = formatedEnvelopes.selectOptions
        this.selectValues = formatedEnvelopes.selectValues
        break
      }
      case TEST_SET_CONSTS.DEFAULT_CUSTOM_CARDS: {
        const cards = (await retrieveTestCorpusCards()) as TestCorpusEnvelope[]
        const formattedCards = this.loadAndFormatDefaultValues(
          TEST_SET_CONSTS.DEFAULT_CUSTOM_CARDS,
          cards
        )
        this.selectOptions = formattedCards.selectOptions
        this.selectValues = formattedCards.selectValues
        break
      }
      case TEST_SET_CONSTS.DEFAULT_RETURN_ENVELOPES: {
        const returnEnvelopes =
          (await retrieveTestCorpusReturnEnvelopes()) as TestCorpusReturnEnvelope[]
        const formatedReturnEnvelopes = await this.loadAndFormatDefaultValues(
          TEST_SET_CONSTS.DEFAULT_RETURN_ENVELOPES,
          returnEnvelopes
        )
        this.selectOptions = formatedReturnEnvelopes.selectOptions
        this.selectValues = formatedReturnEnvelopes.selectValues
        break
      }
    }
    this.resourcesLoaded = true
  },
  methods: {
    loadAndFormatDefaultValues(ffKey: string, ffValues: any[]) {
      const currentValues = this.ffCurrentValues?.get(ffKey) as string[]
      const selectOptions = [] as any[]
      const selectValues = [] as any[]
      ffValues.forEach((ff) => {
        const val = this.formatValue(ffKey, ff)
        if (currentValues && currentValues.includes(ff.id)) {
          selectValues.push(val)
        } else {
          selectOptions.push(val)
        }
      })
      return { selectOptions, selectValues }
    },
    formatValue(ffKey: string, ffObject: any) {
      switch (this.field?.id) {
        case TEST_SET_CONSTS.DEFAULT_CUSTOM_ENVELOPES: {
          return {
            label: `${ffObject.id} - ${ffObject.size} for ${ffObject.letter_size} with ${ffObject.design} design`,
            value: ffObject.id
          }
        }
        case TEST_SET_CONSTS.DEFAULT_CUSTOM_CARDS: {
          return {
            label: `${ffObject.id} - ${ffObject.size} with ${ffObject.orientation} orientation`,
            value: ffObject.id
          }
        }
        case TEST_SET_CONSTS.DEFAULT_RETURN_ENVELOPES: {
          return {
            label: `${ffObject.id}`,
            value: `${ffObject.id}`
          }
        }
      }
    },
    updateValues(values: any[]) {
      const value = values.map((obj) => {
        return obj.value
      })
      // if it's empty, make sure an empty string is passed so a nil value won't be passed
      if (value.length === 0) value.push('')
      this.$emit('update:formFactor', {
        id: this.field?.id,
        value,
        put: true,
        type: 'defaultResources'
      })
      this.selectValues = values
    },
    arraysEqual
  }
})
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
}
.item {
  display: flex;
  margin-right: 1rem;
}
.item > :first-child {
  // And again, bootstrap tries to important a basic layout rule and we need to tell it to calm down.
  margin-right: 0 !important;
}
</style>
