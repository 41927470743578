import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6235930f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-field-editor-root mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "grid grid-cols-3 gap-2"
}
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "item-card" }
const _hoisted_5 = { class: "action-list" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldHeader = _resolveComponent("FieldHeader")!
  const _component_LobButton = _resolveComponent("LobButton")!
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FieldHeader, {
      schema: _ctx.schema,
      field: _ctx.field,
      "test-set": _ctx.testSet,
      "show-add-button": "",
      "onUpdate:testSet": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:testSet', $event))),
      onAdd: _ctx.handleAdd
    }, null, 8, ["schema", "field", "test-set", "onAdd"]),
    (_ctx.showAddHandlers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addHandlers, (addHandler, index) => {
            return (_openBlock(), _createBlock(_component_LobButton, {
              key: addHandler.label,
              variant: "tertiary",
              onClick: ($event: any) => (_ctx.handleAddIndex(index))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(addHandler.label), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "add-item-card",
        "aria-label": "Add element",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleAdd && _ctx.handleAdd(...args)))
      }, [
        _createVNode(_component_fa, { icon: "plus" })
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFields(), (value, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item"
        }, [
          _createElementVNode("div", _hoisted_4, [
            (index === _ctx.focused || _ctx.editOnly)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editorComponent), {
                  key: 0,
                  schema: _ctx.schema,
                  "model-value": value,
                  "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange(index, $event))
                }, null, 8, ["schema", "model-value", "onUpdate:modelValue"]))
              : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayComponent), {
                  key: 1,
                  schema: _ctx.schema,
                  value: value
                }, null, 8, ["schema", "value"]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            (index !== _ctx.focused && !_ctx.editOnly)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  title: "Edit",
                  onClick: ($event: any) => (_ctx.focused = index)
                }, [
                  _createVNode(_component_fa, { icon: "edit" })
                ], 8, _hoisted_6))
              : (!_ctx.editOnly)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    title: "Confirm",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focused = null))
                  }, [
                    _createVNode(_component_fa, { icon: "check" })
                  ]))
                : _createCommentVNode("", true),
            _createElementVNode("button", {
              title: "Duplicate",
              onClick: ($event: any) => (_ctx.handleDuplicate(index))
            }, [
              _createVNode(_component_fa, { icon: "copy" })
            ], 8, _hoisted_7),
            _createElementVNode("button", {
              title: "Delete",
              onClick: ($event: any) => (_ctx.handleDelete(index))
            }, [
              _createVNode(_component_fa, { icon: "trash" })
            ], 8, _hoisted_8)
          ])
        ]))
      }), 128))
    ])
  ]))
}