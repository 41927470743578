import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pr-10"
}
const _hoisted_2 = {
  class: "table",
  "data-testid": "table"
}
const _hoisted_3 = { class: "w-min" }
const _hoisted_4 = { "data-testid": "table-body" }
const _hoisted_5 = ["data-jobid"]
const _hoisted_6 = { class: "align-middle" }
const _hoisted_7 = { class: "font-medium capitalize" }
const _hoisted_8 = { class: "align-middle" }
const _hoisted_9 = { class: "align-middle" }
const _hoisted_10 = { class: "align-middle" }
const _hoisted_11 = { class: "align-middle" }
const _hoisted_12 = { class: "align-middle" }
const _hoisted_13 = { class: "align-middle" }
const _hoisted_14 = { class: "align-middle" }
const _hoisted_15 = { class: "align-middle" }
const _hoisted_16 = { class: "align-middle w-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_job_results_modal = _resolveComponent("job-results-modal")!
  const _component_Info = _resolveComponent("Info")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_LobButton = _resolveComponent("LobButton")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!

  return (_openBlock(), _createBlock(_component_LoadingIndicator, null, {
    default: _withCtx(() => [
      (_ctx.showModal)
        ? (_openBlock(), _createBlock(_component_job_results_modal, {
            key: 0,
            job: _ctx.selectedJob,
            onClose: _ctx.closeModal
          }, null, 8, ["job", "onClose"]))
        : _createCommentVNode("", true),
      (_ctx.jobsLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("table", _hoisted_2, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                { title: 'Status' },
                { title: 'Job ID' },
                { title: 'Flush Type' },
                { title: 'Flush Key' },
                { title: 'Created At' },
                { title: 'Update At' },
                { title: 'Count Processed' },
                {
                  title: 'Deduplicated Count',
                  tooltip: `Some redrive queues contain duplicate order IDs.
                    This field shows the true count of orders that will be redriven after deduplication.`
                },
                { title: 'Total Count' }
              ], (key) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: key,
                      class: "border-t-0 text-gray-900 font-light text-base"
                    }, [
                      _createTextVNode(_toDisplayString(key.title) + " ", 1),
                      (key.tooltip)
                        ? (_openBlock(), _createBlock(_component_Tooltip, {
                            key: 0,
                            position: "bottom"
                          }, {
                            trigger: _withCtx(() => [
                              _createVNode(_component_Info, { class: "ml-1 w-5 h-5" })
                            ]),
                            content: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, _toDisplayString(key.tooltip), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("tbody", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: job.id,
                    "data-jobid": `table-row-${job.id}`,
                    class: _normalizeClass([
                'table-row',
                'cursor-pointer',
                'relative',
                'font-light',
                'text-sm'
              ])
                  }, [
                    _createElementVNode("td", _hoisted_6, [
                      _createVNode(_component_Badge, {
                        variant: _ctx.statusToVariant(job.status)
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(job.status), 1)
                        ]),
                        _: 2
                      }, 1032, ["variant"])
                    ]),
                    _createElementVNode("td", _hoisted_8, _toDisplayString(job.id), 1),
                    _createElementVNode("td", _hoisted_9, _toDisplayString(job.flush_type), 1),
                    _createElementVNode("td", _hoisted_10, _toDisplayString(job.flush_key), 1),
                    _createElementVNode("td", _hoisted_11, _toDisplayString(job.created_at), 1),
                    _createElementVNode("td", _hoisted_12, _toDisplayString(job.updated_at), 1),
                    _createElementVNode("td", _hoisted_13, _toDisplayString(job.count_processed.toLocaleString()), 1),
                    _createElementVNode("td", _hoisted_14, _toDisplayString(job.deduplicated_count.toLocaleString()), 1),
                    _createElementVNode("td", _hoisted_15, _toDisplayString(job.total_count.toLocaleString()), 1),
                    _createElementVNode("td", _hoisted_16, [
                      (_ctx.resultsVisible(job))
                        ? (_openBlock(), _createBlock(_component_LobButton, {
                            key: 0,
                            class: "py-2 px-1 text-xs",
                            variant: "tertiary",
                            size: "small",
                            "data-testid": "csv-button",
                            onClick: _withModifiers(($event: any) => (_ctx.openModal(job)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Results CSV ")
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ], 8, _hoisted_5))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}