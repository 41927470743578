<script lang="ts">
import { formatDate } from '@/utils/formatters'
import { defineComponent, PropType } from 'vue'

type formats = 'date' | 'datetime' | 'number' | 'string' | 'currency'

const formatFunctions = {
  date: (value: string | number | Date) =>
    formatDate(value as string, {
      timeZone: 'America/Los_Angeles',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
  datetime: (value: string | number | Date) =>
    formatDate(value as string, {
      timeZone: 'America/Los_Angeles',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }),
  number: (value: string) => Number(value).toLocaleString(),
  currency: (value: string) => Number(value).toLocaleString(),
  string: (value: string) => value
} as const

export default defineComponent({
  name: 'OrderProperty',
  props: {
    label: { type: String, required: true },
    value: { type: [Number, String, Date], required: true },
    format: { type: String as PropType<formats>, required: true }
  },
  setup() {
    return { formatFunctions }
  }
})
</script>

<template>
  <div class="flex flex-col justify-center">
    <div
      class="text-gray-700 text-xs px-2 mb-1 py-1 text-center overflow-hidden truncate"
    >
      {{ label }}
    </div>
    <div
      class="border rounded text-sm text-shadow-50 px-2 p-1 text-center h-full bg-white flex items-center justify-center"
    >
      {{
        `${format === 'currency' ? '$' : ''}${formatFunctions[format](value)}`
      }}
    </div>
  </div>
</template>
