<template title="Reset Password">
  <H2>Enter a New Password</H2>
  <br />
  <form @submit.prevent="change">
    <TextInput
      id="password"
      v-model="password"
      :disabled="pending"
      label=""
      placeholder="Password"
      type="password"
    />
    <TextInput
      id="password-again"
      v-model="passwordAgain"
      class="mt-4"
      :disabled="pending"
      label=""
      placeholder="Confirm Password"
      type="password"
    />
    <div class="flex mt-4">
      <Checkbox v-model="tosAgreement" label="" name="tosAgreement" />
      <label class="text-sm font-light" htmlFor="tosAgreement">
        I have read and agree to Lob’s
        <a href="https://lob.com/legal#legal_terms_of_service" target="_blank">
          Terms&nbsp;of&nbsp;Service
        </a>
        and
        <a href="https://lob.com/privacy" target="_blank">
          Privacy&nbsp;Policy
        </a>
        . The information shared will be under the confidentiality terms of the
        Terms of Service
      </label>
    </div>
    <Alert v-if="errorMessage" variant="error">
      {{ errorMessage }}
    </Alert>
    <Alert v-if="success" variant="success">
      Success!
      <span style="width: 1rem" />
      <router-link class="underline" to="/login">
        Return to login page.
      </router-link>
    </Alert>
    <br />

    <LobButton :disabled="pending">Change</LobButton>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import * as session from '@/store/session'

export default defineComponent({
  name: 'ResetPasswor',
  metaInfo: {
    title: 'Reset Password'
  },
  components: {},
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      password: null as string | null,
      passwordAgain: null as string | null,
      pending: false,
      errorMessage: null as string | null,
      success: false,
      tosAgreement: false
    }
  },
  methods: {
    async change() {
      this.errorMessage = ''
      if (!this.password || !this.passwordAgain) {
        this.errorMessage = 'Password cannot be blank.'
        return
      } else if (this.password !== this.passwordAgain) {
        this.errorMessage = 'Passwords must match.'
        return
      }
      if (!this.tosAgreement) {
        this.errorMessage = 'Agreement with ToS and Privacy Policy is required'
        return
      }
      this.pending = true
      try {
        await session.resetPassword({
          token: this.token,
          password: this.password
        })
        this.success = true
      } catch (e) {
        const eResp = e.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
      this.pending = false
    }
  }
})
</script>
