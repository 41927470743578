<script lang="ts">
/* eslint-disable camelcase */
import { defineComponent, onMounted, ref } from 'vue'
import ResultValueBox from './ResultValueBox.vue'
import ResultBooleanBox from './ResultBooleanBox.vue'
import { sendPapiRequest } from '@/store/requests'
interface TestResults {
  time_to_download: number
  time_for_bcc: number
  gmc_impose_time: number
  files_received: boolean
  files_ingested: boolean
  correct_agent_dir: boolean
}

export default defineComponent({
  name: 'ResultsModal',
  components: { ResultValueBox, ResultBooleanBox },
  props: {
    testId: { type: String, required: true },
    isModalVisible: { type: Boolean, default: false }
  },
  emits: ['closeModal'],
  setup(props) {
    // call the api endpoint to take the results
    const results = ref<TestResults | null>(null)
    const testId = ref(props.testId)
    const error = ref<string | null>(null)
    const isLoading = ref(false)
    const loadData = async (t: string) => {
      try {
        isLoading.value = true
        error.value = null
        const response = await sendPapiRequest<TestResults>({
          method: 'GET',
          path: `/tests/${t}/results`
        })
        results.value = response.data
      } catch (e) {
        error.value = 'Error retrieving test results'
        results.value = null
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => {
      loadData(testId.value)
    })

    return { results, error, isLoading }
  }
})
</script>

<template>
  <Modal :visible="isModalVisible" @close="$emit('closeModal')">
    <template #header>
      <h4>Report result</h4>
    </template>

    <LoadingIndicator>
      <div v-if="!isLoading">
        <alert v-if="error" variant="error">
          {{ error }}
        </alert>

        <div v-if="results !== null" class="grid grid-cols-3 gap-4">
          <ResultValueBox
            label="Time to download artwork"
            :value="results?.time_to_download?.toString() || 'n/a'"
            sub-label="hours"
          />
          <ResultValueBox
            label="Time for BCC"
            :value="results?.time_for_bcc?.toString() || 'n/a'"
            sub-label="hours"
          />
          <ResultValueBox
            label="GMC impose time"
            :value="results?.gmc_impose_time?.toString() || 'n/a'"
            sub-label="hours"
          />
          <ResultBooleanBox
            label="File correctly received"
            :value="results?.files_received || false"
          />
          <ResultBooleanBox
            label="File correctly ingested"
            :value="results?.files_ingested || false"
          />
          <ResultBooleanBox
            label="Destination agent directory correct"
            :value="results?.correct_agent_dir || false"
          />
        </div>
      </div>
    </LoadingIndicator>

    <template #footer>
      <div class="flex self-end">
        <LobButton variant="secondary" @click.stop="$emit('closeModal')">
          Go back
        </LobButton>
      </div>
    </template>
  </Modal>
</template>
