<script lang="ts">
/* eslint-disable camelcase */
import { getCurrentPartnerId } from '@/store/partners'
import { defineComponent, PropType } from 'vue'
import { Test } from './useTests'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    test: {
      type: Object as PropType<Test>,
      default: () => ({
        id: '',
        status: '',
        date_created: '',
        date_scheduled: ''
      }),
      required: true
    }
  },
  emits: ['showReport'],
  setup(props, { emit }) {
    const pId = getCurrentPartnerId()
    const router = useRouter()
    if (['failed', 'completed'].includes(props.test.status)) {
      if (props.test.report_sent) {
        return {
          variant: 'tertiary',
          label: 'Show Report Results',
          isDisabled: false,
          icon: 'DocPage',
          onClick: () => emit('showReport', props.test.id)
        }
      }

      return {
        variant: 'primary',
        label: 'Send Report',
        icon: 'Send',
        isDisabled: false,
        onClick: () => {
          router.push(
            `/partners/${pId}/onboarding/end-to-end-testing/${props.test.id}/report`
          )
        }
      }
    }

    if (props.test.status === 'enqueued') {
      return {
        variant: 'secondary',
        label: 'E2E Test Scheduled',
        isDisabled: true,
        icon: 'Checkmark',
        onClick: () => null
      }
    }
    // Ready => no report to be sent yet
    return {
      variant: 'secondary',
      label: 'Not ready yet!',
      isDisabled: true,
      icon: 'Send',
      onClick: () => null
    }
  }
})
</script>

<template>
  <LobButton
    :variant="variant"
    :disabled="isDisabled"
    data-testid="send-report"
    class="w-2/3"
    size="small"
    @click.stop="onClick"
  >
    {{ label }}
    <component :is="icon" class="w-4 h-4 ml-2" />
  </LobButton>
</template>
