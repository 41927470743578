import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_ctx.partners.length && _ctx.modelValue)
    ? (_openBlock(), _createBlock(_component_Dropdown, {
        key: 0,
        id: _ctx.id,
        label: _ctx.label,
        options: _ctx.partners,
        placeholder: _ctx.placeholder,
        required: false,
        size: _ctx.size,
        "model-value": _ctx.modelValue,
        "sr-only-label": _ctx.srOnlyLabel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, null, 8, ["id", "label", "options", "placeholder", "size", "model-value", "sr-only-label"]))
    : _createCommentVNode("", true)
}