<script lang="ts">
import { defineComponent } from 'vue'
import { computed } from '@vue/reactivity'
import { RESOURCES, DropdownItem } from '@/consts'

interface Fields {
  perDay: number
  perMonth: number
  days: DropdownItem
}

const daysApplyingOptions: DropdownItem[] = [
  { id: '1', label: 'Tomorrow' },
  { id: '2', label: '2 days' },
  { id: '3', label: '3 days' }
]

export default defineComponent({
  name: 'BulkEditCapacityModal',
  props: {
    partner: { type: String, required: true },
    resource: { type: String, required: true },
    defaultDaily: { type: Number, required: true },
    oneDaySlaDaily: {
      type: Number,
      default: () => {
        return 0
      }
    },
    oneDaySlaMultiplier: {
      type: Number,
      default: () => {
        return 0
      }
    },
    monthly: { type: Number, required: true },
    isModalVisible: { type: Boolean, default: false }
  },
  emits: ['submit', 'close'],
  setup(props, { emit }) {
    const fields = computed<Fields>(() => ({
      perDay: props.defaultDaily,
      perMonth: props.monthly,
      oneDaySlaDaily: props.oneDaySlaDaily,
      oneDaySlaMultiplier: props.oneDaySlaMultiplier,
      days: daysApplyingOptions[0]
    }))

    const onSubmit = () => {
      emit('close')
    }

    return {
      RESOURCES,
      fields,
      daysApplyingOptions,
      onSubmit
    }
  }
})
</script>

<template>
  <Modal :visible="isModalVisible" @close="$emit('close')">
    <template #header>
      <h4>Edit {{ partner }}'s {{ RESOURCES[resource].label }} Capacity</h4>
    </template>

    <form>
      <div class="row py-3">
        <div class="col-12">
          <TextInput
            id="daily"
            v-model="fields.perDay"
            :label="
              `default sla - ${RESOURCES[resource].unit} per day`.toUpperCase()
            "
            :placeholder="`${RESOURCES[resource].unit} per day`"
            :required="true"
          />
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12">
          <TextInput
            id="oneDaySlaDaily"
            v-model="fields.oneDaySlaDaily"
            :label="
              `one day sla - ${RESOURCES[resource].unit} per day`.toUpperCase()
            "
            :placeholder="`${RESOURCES[resource].unit} per day`"
            :required="true"
          />
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12">
          <TextInput
            id="oneDaySlaMultiplier"
            v-model="fields.oneDaySlaMultiplier"
            :label="
              `one day sla - ${RESOURCES[resource].unit} multiplier`.toUpperCase()
            "
            :placeholder="`${RESOURCES[resource].unit} multiplier`"
            :required="true"
          />
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12">
          <Dropdown
            :model-value="fields.days"
            :options="daysApplyingOptions"
            :label="`Apply starting`.toUpperCase()"
            data-testid="dd-days"
          />
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12">
          <TextInput
            id="monthly"
            v-model="fields.perMonth"
            :label="`${RESOURCES[resource].unit} per month`.toUpperCase()"
            :placeholder="`${RESOURCES[resource].unit} per month`"
            :required="true"
          />
        </div>
      </div>
    </form>

    <template #footer>
      <div class="flex self-end items-center">
        <LobButton class="mr-4" variant="link" @click.stop="$emit('close')">
          Close
        </LobButton>
        <LobButton @click.prevent="onSubmit">Confirm</LobButton>
      </div>
    </template>
  </Modal>
</template>
