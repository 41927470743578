import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["draggable"]
const _hoisted_2 = {
  class: "p-3 w-2/12",
  draggable: "false"
}
const _hoisted_3 = {
  class: "p-3 w-6/12",
  draggable: "false"
}
const _hoisted_4 = {
  class: "p-3 w-2/12",
  draggable: "false"
}
const _hoisted_5 = {
  key: 0,
  class: "p-1 w-2/12"
}
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = {
  key: 1,
  class: "p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Check = _resolveComponent("Check")!
  const _component_Close = _resolveComponent("Close")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Trash = _resolveComponent("Trash")!
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("tr", {
    class: "drop-zone bg-mist py-4 px-3 table-row mt-20 shadow-md w-100 pb-10",
    draggable: _ctx.draggable,
    onDragstart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.startDrag($event, _ctx.item))),
    onDrop: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onDrop($event, _ctx.item))),
    onDragover: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.item.title), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.item.description), 1),
    _createElementVNode("td", _hoisted_4, [
      (_ctx.item.file_url)
        ? (_openBlock(), _createBlock(_component_Check, {
            key: 0,
            class: "w-6 h-6 opacity-40",
            icon: "check"
          }))
        : (_openBlock(), _createBlock(_component_Close, {
            key: 1,
            class: "w-6 h-6 opacity-40",
            icon: "times"
          }))
    ]),
    (_ctx.isAdminOrPopsUser)
      ? (_openBlock(), _createElementBlock("td", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Edit, {
              class: "w-6 h-6 opacity-40 cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openComponentsPage('edit', 'partner_ops', _ctx.item)))
            }),
            (!_ctx.isBasePackage)
              ? (_openBlock(), _createBlock(_component_Trash, {
                  key: 0,
                  class: "ml-3 w-6 h-6 opacity-40 cursor-pointer",
                  icon: "trash",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDeleteModal(_ctx.item)))
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isAdminOrPopsUser)
      ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
          _createVNode(_component_fa, {
            class: "opacity-40",
            icon: "eye",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openComponentsPage('view', 'partner', _ctx.item)))
          })
        ]))
      : _createCommentVNode("", true)
  ], 40, _hoisted_1))
}