<template>
  <ReportsEmbed :resource="resource" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ReportsEmbed from '@/components/Reports/ReportsEmbed.vue'

export default defineComponent({
  name: 'Reports',
  components: {
    ReportsEmbed
  },
  props: {
    resource: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped></style>
