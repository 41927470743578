<template>
  <TextInput
    id="name"
    label="ID (leave blank to omit)"
    :model-value="modelValue"
    @update:modelValue="
      $emit('update:modelValue', $event === '' ? null : $event)
    "
  />
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    modelValue: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null
    } as Prop<string | null>
  },
  emits: ['update:modelValue']
})
</script>
