import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GrayCard = _resolveComponent("GrayCard")!
  const _component_HomeAssistance = _resolveComponent("HomeAssistance")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GrayCard, {
      title: "Company info",
      value: _ctx.partner.display_name
    }, null, 8, ["value"]),
    _createVNode(_component_GrayCard, {
      title: "CRID",
      value: _ctx.partner.crid
    }, null, 8, ["value"]),
    _createVNode(_component_GrayCard, {
      title: "Timezone",
      value: _ctx.partner.timezone
    }, null, 8, ["value"]),
    _createVNode(_component_GrayCard, {
      title: "Zip Code",
      value: _ctx.partner.zipcode
    }, null, 8, ["value"]),
    _createVNode(_component_GrayCard, { title: "Holidays" }, {
      default: _withCtx(() => [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partner.holidays, (holiday) => {
            return (_openBlock(), _createElementBlock("li", {
              key: holiday.name
            }, _toDisplayString(_ctx.capitalize(holiday.name)), 1))
          }), 128))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_HomeAssistance)
  ], 64))
}