import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_FilePicker = _resolveComponent("FilePicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.uploadSuccessful || _ctx.uploadFailureMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.uploadSuccessful)
            ? (_openBlock(), _createBlock(_component_Alert, {
                key: 0,
                variant: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" File uploaded successfully ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.uploadFailureMessage)
            ? (_openBlock(), _createBlock(_component_Alert, {
                key: 1,
                variant: "error"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.uploadFailureMessage), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_FilePicker, {
      disabled: _ctx.uploadPending,
      "disabled-message": "Uploading...",
      onFilesPicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFilesPicked($event)))
    }, null, 8, ["disabled"])
  ], 64))
}