import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-mist grid grid-cols-6 shadow-md shadow-black px-2" }
const _hoisted_2 = { class: "w-100 p-3 col-span-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggle_button = _resolveComponent("toggle-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.product.name), 1),
    _createVNode(_component_toggle_button, {
      modelValue: _ctx.active,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
      class: "cursor-pointer",
      name: _ctx.product.name,
      label: _ctx.active ? 'Active' : 'Inactive',
      onClick: _ctx.toggleProductActive
    }, null, 8, ["modelValue", "name", "label", "onClick"])
  ]))
}