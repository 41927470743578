<template>
  <H2>Oops, something went wrong here!</H2>
  <P>{{ errorMessage }}</P>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ErrorPage',
  props: {
    type: {
      type: String,
      default: '404'
    }
  },
  computed: {
    errorMessage() {
      if (this.type === '500') {
        return 'Sorry about that, our team will get things back in order shortly.'
      }
      return 'The page you requested was not found.'
    }
  }
})
</script>
