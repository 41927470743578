<template>
  <Check v-if="cellData" class="w-6 h-6 opacity-40" icon="check" />
  <Close v-else class="w-6 h-6 opacity-40" icon="times" />
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

/* 
  tableRowObject for BooleanCell: 
  {
    fieldName,
    type
  }
*/

export default defineComponent({
  name: 'BooleanCell',
  props: {
    cellInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cellData: {
      type: String,
      default: () => {
        return ''
      },
      required: true
    }
  },
  computed: {
    cellVariant() {
      if (this.cellInfo && this.cellData) {
        return this.cellInfo.calculateVariant(this.cellData)
      } else {
        return 'info'
      }
    }
  }
})
</script>
