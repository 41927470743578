<template>
  <div class="p-8">
    <Alert variant="success">Form Successfully Submitted</Alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormsSuccess'
})
</script>
