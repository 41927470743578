<template>
  <div class="flex">
    <table class="w-full row-space">
      <thead>
        <tr>
          <th
            v-for="key in tableHeaders"
            :key="key.name"
            class="border-t-0 text-gray-900 font-light text-base p-3"
            :colspan="key.span"
          >
            {{ key.name }}
          </th>
        </tr>
      </thead>
      <tbody v-if="data.length" class="table-body">
        <PackagePermissionsTableRow
          v-for="item in data"
          :key="item.id"
          :item="item"
          class="m-10"
        />
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { PackagePermission } from '@/store/customMail'
import PackagePermissionsTableRow from './PackagePermissionsTableRow.vue'

export default defineComponent({
  name: 'ComponentsTable',
  components: {
    PackagePermissionsTableRow
  },
  props: {
    data: {
      type: Array as PropType<PackagePermission[]>,
      default: () => [] as PackagePermission[]
    }
  },
  emits: ['resortTableData', 'openComponentDeleteModal'],
  data() {
    return {
      tableHeaders: [
        { name: 'Account Access', span: 1 },
        { name: '', span: 1 },
        { name: 'Date Effective', span: 1 },
        { name: 'Date Expired', span: 1 }
      ]
    }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
