import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "content p-5" }
const _hoisted_2 = { class: "relative mt-1" }
const _hoisted_3 = {
  key: 0,
  class: "table"
}
const _hoisted_4 = ["data-testid"]
const _hoisted_5 = ["colSpan"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["colSpan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InventoryHeader = _resolveComponent("InventoryHeader")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_Orders = _resolveComponent("Orders")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InventoryHeader, { subject: "Account" }),
    (_ctx.errorMessage?.length)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 0,
          variant: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LoadingIndicator, null, {
        default: _withCtx(() => [
          (!_ctx.isLoading && _ctx.columns?.length > 0)
            ? (_openBlock(), _createElementBlock("table", _hoisted_3, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (key) => {
                      return (_openBlock(), _createElementBlock("th", {
                        key: key,
                        class: "!border-t-0 text-gray-900 font-normal text-base"
                      }, _toDisplayString(key), 1))
                    }), 128))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: item.id
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(
                  _ctx.route.params.resource === 'envelopes'
                    ? 'EnvelopeRow'
                    : _ctx.route.params.resource === 'cards'
                    ? 'CardRow'
                    : 'BuckslipRow'
                ), {
                        item: item,
                        "is-expanded": _ctx.expandedRows.has(item.id),
                        onExpand: _ctx.onRowExpand
                      }, null, 40, ["item", "is-expanded", "onExpand"])),
                      (_ctx.expandedRows.has(item.id))
                        ? (_openBlock(), _createElementBlock("tr", {
                            key: 0,
                            "data-testid": `expanded-row-${item.id}`
                          }, [
                            _createElementVNode("td", {
                              colSpan: _ctx.columns.length,
                              class: "p-0"
                            }, [
                              _createVNode(_component_Orders, {
                                id: item.id,
                                type: _ctx.route.params.resource
                              }, null, 8, ["id", "type"])
                            ], 8, _hoisted_5)
                          ], 8, _hoisted_4))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128)),
                  (!_ctx.items || _ctx.items?.length === 0)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                        _createElementVNode("td", {
                          colSpan: _ctx.columns.length,
                          class: "text-lg text-center"
                        }, " No data available ", 8, _hoisted_7)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}