import { captureException } from '@/plugins/sentry'
import { getCurrentPartnerId } from '../partners'
import { sendPapiRequest } from '../requests'
import { defineVuexModule } from '../util'
import { registerWatcher } from '../watcherRegistry'
/* eslint camelcase: "off" */
export interface Permutation {
  date_created: string
  date_modified: string
  id: string
  partner: string
  permutation: string
  resource: string
  steps_completed: Array<string>
  urls: Array<{ url: string }>
}

interface State {
  currentPartnerPermutations: Array<Permutation> | null
}

const initialState: State = {
  currentPartnerPermutations: null
}

const storeModule = defineVuexModule('digitalProofing', initialState)

// GETTERS (exported functions that provide pieces of data from the state)

// Returns null if the current partner's permutations have not been loaded yet.
export const getCurrentPartnerPermutations = storeModule.read(
  (state) => state.currentPartnerPermutations,
  'getCurrentPartnerPermutations'
)

// Returns null if the current partner's permutations have not been loaded yet,
// or if the current partner has no permutation with that id.
export const getCurrentPartnerPermutation = (
  id: string
): Permutation | null => {
  return (
    getCurrentPartnerPermutations()?.find(
      (permutation) => permutation.id === id
    ) || null
  )
}

// MUTATORS (synchronous functions that change the state, each call to a mutator
// is logged in the Vue development tools)

const setCurrentPartnerPermutations = storeModule.commit(
  (state, newValue: Array<Permutation> | null) => {
    state.currentPartnerPermutations = newValue
  },
  'setCurrentPartnerPermutations'
)

// ACTIONS (asynchronous functions which can call other actions as well as
// getters and setters, also logged in the Vue development tools)

/* eslint camelcase: "off" */
export interface PermutationCreationData {
  permutation: string
  resource: string
  steps_completed: Array<string>
}

export const refreshCurrentPartnerPermutations = storeModule.dispatch(
  async () => {
    const currentPartnerId = getCurrentPartnerId()
    const response = await sendPapiRequest<{ data: Array<Permutation> }>({
      method: 'GET',
      path: `/partners/${currentPartnerId}/permutations`
    })
    setCurrentPartnerPermutations(response.data.data)
  },
  'refreshCurrentPartnerPermutations'
)

export const setPermutationStatus = storeModule.dispatch(
  async (
    ctx,
    params: {
      permutation
      isActive
    }
  ) => {
    const { isActive } = params
    const { permutation, resource, id } = params.permutation
    const partnerId = getCurrentPartnerId()
    const data = { permutation, resource }
    if (isActive) {
      // create
      await sendPapiRequest({
        method: 'POST',
        path: `/partners/${partnerId}/permutations`,
        data
      })
    } else {
      // delete
      await sendPapiRequest({
        method: 'DELETE',
        path: `/partners/${partnerId}/permutations/${id}`
      })
    }

    await refreshCurrentPartnerPermutations()
  },
  'setPermutationStatus'
)

export const uploadProof = storeModule.dispatch(
  async (
    ctx,
    params: {
      file: string | Blob
      partnerId?: string
      permutationId: string
    }
  ) => {
    const partnerId = params.partnerId || getCurrentPartnerId()
    const permutationId = params.permutationId
    const data = new FormData()
    data.append('pdf_file', params.file)

    await sendPapiRequest({
      method: 'POST',
      path: `/partners/${partnerId}/permutations/${permutationId}/upload`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })

    await refreshCurrentPartnerPermutations()
  },
  'uploadProof'
)

export const updateStepsCompleted = storeModule.dispatch(
  async (
    ctx,
    params: {
      permutationId: string
      stepsCompleted: Array<string>
    }
  ) => {
    try {
      const partnerId = getCurrentPartnerId()
      await sendPapiRequest({
        method: 'PUT',
        path: `/partners/${partnerId}/permutations/${params.permutationId}/steps_completed`,
        data: {
          steps_completed: params.stepsCompleted
        }
      })
      await refreshCurrentPartnerPermutations()
    } catch (err) {
      captureException(err)
    }
  },
  'updateStepsCompleted'
)

// This is necessary for backwards compatibility.
export const getPermutationAsAdmin = storeModule.dispatch(
  async (
    ctx,
    params: {
      permutationId: string
    }
  ) => {
    return await sendPapiRequest<Permutation | null>({
      method: 'GET',
      path: `/permutations/${params.permutationId}`
    })
  },
  'getPermutationAsAdmin'
)

// WATCHERS (functions that are called when a value in the store changes,
// possibly one from a different module.)

// Refresh permutations when currentPartnerId is changed.
registerWatcher(
  () => getCurrentPartnerId(),
  () => {
    setCurrentPartnerPermutations(null)
    refreshCurrentPartnerPermutations()
  }
)
