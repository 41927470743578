<template>
  <div class="bg-mist grid grid-cols-6 shadow-md shadow-black px-2">
    <div class="w-100 p-3 col-span-4">
      {{ product.name }}
    </div>
    <toggle-button
      v-model="active"
      class="cursor-pointer"
      :name="product.name"
      :label="active ? 'Active' : 'Inactive'"
      @click="toggleProductActive"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  PartnerFacility,
  PartnerFacilityAbility,
  setPartnerFacilityAbility
} from '@/store/partners'

export default defineComponent({
  name: 'PartnerFacilitiesInnerTableRow',
  props: {
    partnerFacility: {
      type: Object as PropType<PartnerFacility>,
      required: true
    },
    product: {
      type: Object as PropType<PartnerFacilityAbility>,
      required: true
    }
  },
  data() {
    return {
      active: this.product.active
    }
  },
  methods: {
    async toggleProductActive() {
      await setPartnerFacilityAbility({
        partnerId: this.partnerFacility.partner_id,
        partnerFacilityId: this.product.partner_facility_id,
        abilityId: this.product.id,
        active: !this.active
      })
    }
  }
})
</script>
