import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "drop-zone bg-mist table-row mt-20 shadow-md w-100" }
const _hoisted_2 = {
  class: "p-3 w-2/12",
  draggable: "false"
}
const _hoisted_3 = {
  class: "p-3 w-6/12",
  draggable: "false"
}
const _hoisted_4 = {
  class: "p-3 w-2/12",
  draggable: "false"
}
const _hoisted_5 = {
  class: "p-3 w-2/12",
  draggable: "false"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.item.account_id ? 'Account ID:' : 'Tiers:'), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.item.account_id || _ctx.item.tiers.join(', ')), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.item.date_effective), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.item.date_expired || '-'), 1)
  ]))
}