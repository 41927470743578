<template>
  <MainNavigation id="checklist-root" list-class="overflow-y-scroll h-screen">
    <template #default="{ expanded }">
      <template v-for="sublist in list" :key="sublist.title">
        <MainNavigationItem
          v-if="sublist.title === 'General Onboarding'"
          class="mt-4"
          :expanded="expanded"
          @click="redirectToGitbook('getting-started/new-partner-onboarding')"
        >
          <template v-if="sublist.title">
            <div
              class="py-3 px-12 h-12 flex items-center align-middle w-full text-gray-500 text-sm cursor-pointer"
            >
              {{ sublist.title }} &nbsp;
              <fa class="opacity-40" icon="link" />
            </div>
          </template>
        </MainNavigationItem>
        <MainNavigationItem
          v-else
          class="mt-4"
          :title="sublist.title"
          :expanded="expanded"
        />
        <div
          v-for="item in sublist.items.filter((item) => !item.hidden)"
          :key="item.href"
        >
          <MainNavigationItem
            v-if="showGitbook && item.text === 'Order Transmission Integration'"
            :title="item.text"
            icon-alt-text="Check icon"
            :icon-src="
              item.complete
                ? require('@/assets/images/icon-checkmark.svg')
                : require('@/assets/images/icon-circle.svg')
            "
            class="checklist-item"
            @click="
              redirectToGitbook('integration/order-transmission-integration')
            "
          />
          <MainNavigationItem
            v-else
            :title="item.text"
            icon-alt-text="Check icon"
            :icon-src="
              item.complete
                ? require('@/assets/images/icon-checkmark.svg')
                : require('@/assets/images/icon-circle.svg')
            "
            :to="item.href"
            class="checklist-item"
          />
        </div>
      </template>
    </template>
  </MainNavigation>
</template>

<script lang="ts">
import { defineComponent, Prop } from 'vue'
import { getShowGitbookFlag } from '@/store/launchDarkly'
import { fetchGitbookToken } from '@/store/session'
import config from '../../../config'

export interface Item {
  text: string
  href: string
  complete: boolean
  hidden?: boolean
}

export interface Sublist {
  title: string
  items: Array<Item>
}

export default defineComponent({
  props: {
    list: {
      type: Array,
      required: true
    } as Prop<Array<Sublist>>,
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    showGitbook(): boolean {
      return getShowGitbookFlag()
    }
  },
  methods: {
    liClasses(item) {
      if (item.complete) {
        return 'step complete'
      }
      if (this.$route.path.endsWith(item.href)) {
        return 'step active'
      }
      return 'step inactive'
    },
    async redirectToGitbook(path) {
      const resp = await fetchGitbookToken()
      window.open(`${config.GITBOOK_BASE_URL}/${path}?jwt_token=${resp.token}`)
    }
  }
})
</script>

<!-- intentionally unscoped to tweak the width of the menu -->
<style lang="scss">
#checklist-root ul:not(.collapsed) {
  width: 250px !important;
}

#checklist-root > ul > li {
  ul,
  button {
    padding-left: 0;
  }
}

.checklist-item {
  a:hover {
    text-decoration: none;
  }
  a {
    img {
      height: 20px;
      filter: invert(37%) sepia(22%) saturate(830%) hue-rotate(164deg)
        brightness(95%) contrast(92%);
    }
  }
}
</style>
