import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert = _resolveComponent("alert")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!

  return (_openBlock(), _createBlock(_component_LoadingIndicator, null, {
    default: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(
          _ctx.type === _ctx.ORDER_TYPE.CARDS
            ? _ctx.CardOrder
            : _ctx.type === _ctx.ORDER_TYPE.ENVELOPES
            ? _ctx.EnvelopeOrder
            : _ctx.BuckslipOrder
        ), {
                key: order.id,
                order: order
              }, null, 8, ["order"]))
            }), 128)),
            (_ctx.orders.length === 0)
              ? (_openBlock(), _createBlock(_component_alert, {
                  key: 0,
                  class: "text-sm my-1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" No orders available ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.error)
              ? (_openBlock(), _createBlock(_component_alert, {
                  key: 1,
                  variant: "error",
                  class: "w-full text-sm my-1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.error), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}