<template>
  <LoadingIndicator>
    <template v-if="!submitPending">
      <Alert v-if="jobDone" variant="info">
        <p>Job submitted!</p>
      </Alert>
      <!-- commenting out for now as only bulk jobs is usuable at this stage -->
      <!-- <div
        v-if="!jobType"
        class="flex flex-shrink-0 items-center justify-evenly h-48 -mt-8"
      >
        <Tooltip
          v-for="job in jobTypes"
          :key="job.id"
          position="bottom"
          class="w-1/3 m-3"
        >
          <template #content class="btn">{{ job.description }}</template>
          <template #trigger>
            <LobButton
              variant="secondary"
              class="btn btn-blue-inverse text-3xl"
              :data-testid="`${job.type}-button`"
              @click="changeJobType(job.type)"
            >
              {{ job.name }}
            </LobButton>
          </template>
        </Tooltip>
      </div> -->
      <!-- <div v-else> -->
      <!-- <div else class="mb-3"> -->
      <!-- <Tooltip
            v-for="job in jobTypes"
            :key="job.id"
            position="bottom"
            class="!inline-block mr-2"
          >
            <template #content>{{ job.description }}</template>
            <template #trigger>
              <LobButton
                variant="secondary"
                :class="[
                  jobType === job.type && !jobQueueSelected
                    ? 'btn btn-blue'
                    : 'btn btn-blue-inverse'
                ]"
                size="small"
                @click="changeJobType(job.type)"
              >
                {{ job.name }}
              </LobButton>
            </template>
          </Tooltip>
        </div>
        <div>
          <h2 class="text-center">
            {{ title }}
          </h2>
          <div class="w-7/12 mt-0 m-auto job-form">
            <div v-if="jobType === 'enqueue' || jobType === 'delete'">
              <TextInput
                id="jobDescription"
                v-model="description"
                label="Job Description *"
                placeholder="NAC Unproduced 3/13"
                size="small"
              ></TextInput>
              <Checkbox
                v-if="jobType === 'delete'"
                v-model="nullEnvelopeIds"
                name="nullEnvelopeIdCheckbox"
                label="Null associated envelope IDs (Letters Only)"
                class="mt-3"
                @click="
                  title = !nullEnvelopeIds
                    ? 'Null envelope ID of letters'
                    : 'Delete a list of resources'
                "
              />
              <div v-if="resource_ids !== null" class="form-following mt-2">
                <label class="control-label count-label">
                  {{ resource_ids.length }} IDs Found
                </label>
                <a class="ml-2" @click="clearList">
                  <i>Try Again?</i>
                </a>
                <div
                  v-if="resource_ids.length > 0"
                  class="
                    rounded
                    overflow-scroll
                    p-3
                    bg-gray-lightest
                    border-solid border-2 border-gray-medium
                    max-h-40
                  "
                >
                  <ul>
                    <li
                      v-for="id in resource_ids"
                      :key="id"
                      class="monospace resource_ids-item"
                    >
                      {{ id }}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else-if="file !== null" class="mt-3">
                <label class="control-label count-label">File to process</label>
                <a class="job-id-results-label" @click="clearList">
                  <i>Try Again?</i>
                </a>
                <div
                  class="
                    rounded
                    overflow-scroll
                    p-3
                    bg-gray-lightest
                    border-solid border-2 border-gray-medium
                  "
                >
                  <ul>
                    {{
                      file.name
                    }}
                  </ul>
                </div>
              </div>
              <div v-else class="flex items-center justify-between h-64 mt-3">
                <div class="flex flex-col h-full w-5/12">
                  <LobLabel label="Upload a CSV" />
                  <LobFileUpload
                    class="dropzone-input"
                    :do-try-upload="(f) => tryUploadFile(f)"
                  />
                </div>
                <LobLabel label="Or" />
                <div class="flex flex-col h-full w-5/12">
                  <LobLabel label="Paste List" />
                  <textarea
                    v-model="resource_ids"
                    class="form-control monospace border-2"
                    style="height: 100% !important"
                    name="resource_ids"
                    placeholder="psc_bfafad5fc59d4a59&#10;psc_ed5c510d10506166&#10;psc_8e41f52a12869ee6&#10;psc_b4f07ed93f5c71a4"
                  />
                </div>
              </div>
              <div v-if="jobType === 'enqueue'">
                <div class="mt-3">
                  <Dropdown
                    id="product_selection"
                    v-model="productSelection"
                    label="Product"
                    :options="productSelectionOptions"
                    placeholder="Pick a Product"
                    :required="true"
                    size="small"
                  />
                </div>
                <div class="mt-3">
                  <Dropdown
                    id="job_partner_selection"
                    v-model="partnerSelection"
                    label="Partner"
                    :options="partners"
                    placeholder="Pick a Partner"
                    :required="true"
                    size="small"
                  />
                </div>
              </div>
            </div> -->
      <!-- <FlushView v-else/> -->
      <div class="w-7/12 mt-0 m-auto job-form">
        <FlushView />
      </div>
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
    </template>
  </LoadingIndicator>
</template>

<script lang="ts">
import { Job, createBulkJob } from '@/store/jobs'
import { defineComponent, PropType } from 'vue'
import Moment from 'moment-timezone'
import { getLivePartners } from '@/store/partners'
import { RESOURCES } from '@/consts'
import FlushView from './FlushView.vue'
const TIMEZONE = 'America/Los_Angeles'

export default defineComponent({
  name: 'JobOptions',
  components: {
    FlushView
  },
  props: {
    jobs: {
      type: Array,
      default: Array as PropType<Job[]>
    }
  },
  data() {
    return {
      jobTypes: [
        {
          name: 'Enqueue',
          type: 'enqueue',
          id: 'jobEnqueue',
          description: 'Enqueue a list of resources to a partner'
        },
        {
          name: 'Delete',
          type: 'delete',
          id: 'jobDelete',
          description: 'Delete a list of resources'
        },
        {
          name: 'Flush',
          type: 'flush',
          id: 'jobFlush',
          description: 'Flush paused or orphaned orders'
        }
      ],
      nullEnvelopeIds: false,
      selecting: true,
      jobType: null as any,
      product: 'postcard',
      partner: '-',
      jobDone: false,
      description: '',
      resource_ids: null as any,
      errorMessage: null as any,
      loading: false,
      allowAddressRewrite: false,
      rewriteAddress: false,
      file: null as any,
      title: 'Enqueue resources to a specific partner',
      descriptionPlaceholder: 'NAC Unproduced 3/13',
      productSelection: {} as any,
      partnerSelection: {} as any,
      flushDetailMessage: '',
      flushOptions: ['Orphaned Orders', 'Paused Orders'],
      flushStatus: 'paused',
      scheduledHour: 12,
      scheduledTimePeriod: 'PM',
      dateOptions: null as any,
      shouldSchedule: false,
      confirmed: false,
      flushSelectedOption: '',
      scheduleDate: Moment.tz(Moment.tz(TIMEZONE), TIMEZONE)
        .add(0, 'days')
        .format('YYYY-MM-DD'),
      jobQueueSelected: false,
      submitPending: false
    }
  },
  computed: {
    partners() {
      const filteredParnters = getLivePartners() ?? []
      return filteredParnters.map((partner) => ({
        label: partner.display_name,
        value: partner.id
      }))
    },
    productSelectionOptions() {
      return Object.values(RESOURCES).map((resource) => ({
        label: resource.label,
        value: resource.id
      }))
    }
  },
  watch: {
    resource_ids: function (val) {
      if (typeof val === 'string') {
        this.resource_ids = this.parseIDs(val.split('\n'))
      }
    },
    flushStatus: function (val) {
      switch (val) {
        case 'paused':
          this.flushStatus = 'paused'
          this.flushDetailMessage =
            'Flushing will re-route all paused orders based on currently available capacities. Be sure to increase capacity first if necessary!'
          break
        case 'Orphaned Orders':
          this.flushStatus = 'orders'
          this.flushDetailMessage =
            'Be sure to address any routing changes needed first!'
          break
      }
    }
  },
  async mounted() {
    this.setDate()
  },
  methods: {
    changeFlushStatus(e) {
      switch (e.target.innerText) {
        case 'Paused Orders':
          this.flushStatus = 'paused'
          this.flushDetailMessage =
            'Flushing will re-route all paused orders based on currently available capacities. Be sure to increase capacity first if necessary!'
          break
        case 'Orphaned Orders':
          this.flushStatus = 'orphaned'
          this.flushDetailMessage =
            'Be sure to address any routing changes needed first!'
          break
      }
    },
    parseIDs(array) {
      const regex = /[a-z]{3}_\w{16}$/
      const list: string[] = []

      array.forEach((id) => {
        if (regex.test(id.trim())) {
          list.push(id)
        }
      })

      return list
    },
    setDate() {
      const currentDate = Moment.tz(TIMEZONE)
      this.dateOptions = [...Array(5)]
        .map((_, i) => i)
        .map((numDays) => {
          const date = Moment.tz(currentDate, TIMEZONE)
            .add(numDays, 'days')
            .format('YYYY-MM-DD')
          return date
        })
    },
    changeJobType(jobType) {
      const selectedJobType = jobType
      this.jobType = selectedJobType
      this.selecting = false
      this.jobQueueSelected = false
      this.jobDone = false
      this.title =
        selectedJobType === 'enqueue'
          ? 'Enqueue resources to a specific partner'
          : selectedJobType === 'delete'
          ? 'Delete a list of resources'
          : 'Flush Orders'
      this.descriptionPlaceholder =
        selectedJobType === 'enqueue'
          ? 'NAC Unproduced 3/13'
          : 'Nakedwines Cancellation'
    },
    clearList() {
      this.resource_ids = null as any
      this.file = null as any
    },
    async handleSubmit() {
      this.submitPending = true
      await createBulkJob({
        file: this.file,
        jobType: this.jobType,
        resourceIds: this.resource_ids,
        productSelection: this.productSelection.value,
        description: this.description,
        partnerSelection: this.partnerSelection.value,
        flushStatus: this.flushStatus,
        nullEnvelopeIds: this.nullEnvelopeIds
      })
      this.submitPending = false
      this.clearFields()
      this.jobType = null as any
      this.jobDone = true
    },
    clearFields() {
      this.clearList()
      this.description = ''
      this.partnerSelection = '-'
      this.confirmed = false
    },
    async tryUploadFile(file: File) {
      this.file = file
    },
    submitDisabled() {
      const hasFileOrIds =
        // eslint-disable-next-line @typescript-eslint/no-extra-parens
        this.file || (this.resource_ids && this.resource_ids.length !== 0)
      if (this.jobType === 'delete') {
        return !hasFileOrIds || !this.description
      } else if (this.jobType === 'enqueue') {
        return (
          this.partnerSelection === '-' ||
          !this.partnerSelection ||
          !hasFileOrIds ||
          !this.description
        )
      } else {
        return !this.confirmed
      }
    },
    toggleFlushTiming() {
      this.shouldSchedule = !this.shouldSchedule
    },
    toggleJobQueue() {
      this.jobQueueSelected = !this.jobQueueSelected
    }
  }
})
</script>

<style lang="scss" scoped>
.btn {
  flex-basis: 25%;
  height: fit-content;
  width: 100%;
}

.btn-blue {
  color: #ffffff;
  background-color: $brand-primary;
  border-color: $brand-primary;

  &:hover {
    color: #ffffff;
    background-color: darken($brand-primary, 10%);
    border-color: darken($brand-primary, 10%);
  }

  &[disabled] {
    background-color: $brand-primary;
    border-color: $brand-primary;

    &:hover {
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}

.btn-blue-inverse {
  color: $brand-primary;
  background-color: transparent;
  border-color: $brand-primary;

  &:hover {
    color: #ffffff;
    background-color: $brand-primary;
    border-color: $brand-primary;
  }
}
</style>
