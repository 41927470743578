<template>
  <div
    class="bg-mist grid grid-cols-7 justify-items-center justify-items-center shadow-md shadow-black pl-2 pr-2 ml-1 mb-1"
  >
    <div class="w-100 p-3">
      {{ bid.order_quantity }}
    </div>
    <div class="w-100 p-3">
      {{ bid.total_us_dollars ?? '0.00' }}
    </div>
    <div class="w-100 p-3">
      {{ bid.lead_time_days ?? '0' }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PartnerBidsTableRow',
  props: {
    bid: {
      type: Object,
      required: true
    },
    allBiddingPartners: {
      type: Array,
      required: true
    },
    isAdminOrPopsUser: {
      type: Boolean,
      required: true
    }
  }
})
</script>
