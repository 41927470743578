<script lang="ts">
import * as sftp from '@/store/sftp'
import { defineComponent, computed, onMounted, ref } from 'vue'

const defaultFields = {
  report_type: '',
  report_directory: '',
  sftp_host: '',
  sftp_port: '',
  sftp_username: ''
}

export default defineComponent({
  name: 'SFTPConfig',
  setup() {
    const selectedReportType = ref('daily-completed')
    const reports = computed(sftp.getSFTPReports)
    const fields = computed(
      () =>
        reports.value.find(
          (item) => selectedReportType.value === item.report_type
        ) ?? defaultFields
    )
    onMounted(() => sftp.fetchSFTPReports())
    return {
      fields,
      reportTypeMap: sftp.reportTypeMap,
      inputClass: '!py-2 text-sm font-medium bg-white',
      selectedReportType
    }
  }
})
</script>

<template>
  <form class="p-4 border border-gray-100 rounded-md">
    <h3 class="text-base">SFTP Configurations</h3>
    <div class="row">
      <div class="col-6 flex align-items-end">
        <div class="flex w-full border rounded items-center p-1">
          <button
            v-for="(label, type) in reportTypeMap"
            :key="type"
            :class="[
              'w-1/2 py-1 text-center rounded text-sm mt-px',
              type === selectedReportType ? 'bg-primary text-white' : ''
            ]"
            @click.prevent="selectedReportType = type"
          >
            {{ label }}
          </button>
        </div>
      </div>

      <TextInput
        id="sftp_username"
        v-model="fields.sftp_username"
        class="col-6 input"
        label="Username"
        readonly
        :input-class="inputClass"
      />
    </div>

    <div class="row mt-2">
      <div class="col-6 flex gap-4">
        <TextInput
          id="sftp_host"
          v-model="fields.sftp_host"
          class="w-100"
          :input-class="inputClass"
          label="Host"
          readonly
        />

        <TextInput
          id="sftp_port"
          v-model="fields.sftp_port"
          class="w-1/3"
          label="Port"
          :input-class="inputClass"
          readonly
        />
      </div>

      <TextInput
        id="report_directory"
        v-model="fields.report_directory"
        class="col-6"
        :input-class="inputClass"
        label="Report Directory"
        readonly
      />
    </div>

    <div class="row">
      <div class="col-12 pt-3 text-sm text-right">
        <router-link to="/partners/current/onboarding/sftp-setup">
          Edit SFTP Configurations
        </router-link>
      </div>
    </div>
  </form>
</template>
