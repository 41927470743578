<template>
  <table class="test-cases-table w-100 border rounded-sm border-gray-100">
    <thead>
      <tr>
        <th
          v-for="key in ['Test case', 'Date', 'Qty.', 'Description']"
          :key="key"
          class="border-t-0 uppercase text-xs font-light text-gray-300 bg-white"
        >
          {{ key }}
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr
        v-for="testCase in testCases"
        :key="testCase.test_id"
        class="text-sm font-light bg-white-100 even:bg-white py-4 px-3"
      >
        <td>
          {{ testCase.case_id }}
        </td>
        <td>
          {{ formatDate(testCase.date_created) }}
        </td>
        <td>
          {{ formatNumber(testCase.count) }}
        </td>
        <td>
          {{ descriptionMap[testCase.case_id] ?? '' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
/* eslint-disable camelcase */
import { defineComponent, PropType } from 'vue'
import { formatNumber, formatDate } from '@/utils/formatters'
import { TestCase } from './useTests'

export default defineComponent({
  props: {
    testCases: {
      type: Array as PropType<TestCase[]>,
      default: () => []
    },
    descriptionMap: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({})
    }
  },
  setup(props) {
    return {
      ...props,
      formatNumber,
      formatDate
    }
  }
})
</script>
