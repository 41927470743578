import { sendPapiRequest } from '@/store/requests'
import {
  camelToSnakeCaseProperties,
  snakeCaseToCamelProperties
} from '@/utils/objects'
import { Ref, ref } from 'vue'

const PAGE_SIZE = 20
export interface AuditLogSearchParams {
  startDate?: string
  endDate?: string
  partnerId?: string
  text?: string
}

export interface EventPayloadDict {
  [key: string]: string | unknown
}

export interface AuditLogValue {
  value: string | number | boolean | EventPayloadDict
}

export interface AuditLogSearchResult {
  id: string
  userId: string
  dateCreated: string
  eventType: string
  eventMeta: EventPayloadDict
  oldValue: AuditLogValue
  newValue: AuditLogValue
  partnerName: string
}

export interface AuditLogSearchResponse {
  data: AuditLogSearchResult[]
  count: number
}

export interface UseAuditLogsReturnType {
  isLoading: Ref<boolean>
  data: Ref<AuditLogSearchResponse | null>
  PAGE_SIZE: number
  page: Ref<number>
  err: Ref<string | null>
  fetchAuditLog: (params: AuditLogSearchParams, offset: number) => void
  filters: Ref<AuditLogSearchParams>
}

export default function useAuditLogs(): UseAuditLogsReturnType {
  const isLoading = ref<boolean>(false)
  const filters = ref<AuditLogSearchParams>({})
  const err = ref<string | null>(null)
  const page = ref<number>(1)
  const data = ref<AuditLogSearchResponse | null>(null)
  const fetchAuditLog = async (
    searchParams: AuditLogSearchParams,
    offset: number
  ) => {
    isLoading.value = true
    err.value = null
    try {
      const sp = camelToSnakeCaseProperties<AuditLogSearchParams>(searchParams)
      const res = await sendPapiRequest({
        method: 'GET',
        path: '/auditlogs',
        query: {
          ...sp,
          limit: PAGE_SIZE,
          offset
        }
      })

      data.value = {
        data: res.data.data.map(snakeCaseToCamelProperties),
        count: res.data.count
      }
    } catch (e) {
      err.value = 'Error retrieving audit log'
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    data,
    PAGE_SIZE,
    page,
    err,
    fetchAuditLog,
    filters
  }
}
