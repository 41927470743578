import { getCurrentPartnerId } from '../partners'
import { sendPapiRequest } from '../requests'
import { defineVuexModule } from '../util'

/* eslint-disable camelcase */

export const reportTypes = <const>['daily-received', 'daily-completed']
type ReportTypes = typeof reportTypes[number]

export const reportTypeMap: Record<ReportTypes, string> = {
  'daily-completed': 'Daily Completed',
  'daily-received': 'Daily Received'
}

export interface SFTPSettings {
  id?: string
  sftp_host: string
  sftp_port: number | null
  sftp_username: string
  sftp_password: string
  sftp_private_key: string
  report_type: ReportTypes
  report_directory: string
  reporter_id: string
}

interface State {
  pending: boolean
  notificationMessage: string | null
  notificationType: 'error' | 'success'
  currentReportType: ReportTypes
  list: SFTPSettings[]
}

export const initialState: State = {
  pending: false,
  notificationMessage: null,
  notificationType: 'error',
  currentReportType: 'daily-received',
  list: []
}

const storeModule = defineVuexModule('sftp', initialState)

/**
 * Mutations
 */

export const setSFTPReports = storeModule.commit(
  (state, reports: SFTPSettings[] = []) => {
    state.list = reports
  },
  'setSFTPReports'
)

export const appendSFTPReport = storeModule.commit(
  (state, report: SFTPSettings) => {
    state.list.push(report)
  },
  'appendSFTPReport'
)

export const editSFTPReport = storeModule.commit(
  (state, editedReport: SFTPSettings) => {
    const index = state.list.findIndex(
      (report) => report.id === editedReport.id
    )
    state.list[index] = editedReport
  },
  'editSFTPReport'
)

export const setCurrentReportType = storeModule.commit(
  (state, type: ReportTypes) => {
    state.currentReportType = type
  },
  'setCurrentReportType'
)

export const setPending = storeModule.commit((state, isPending: boolean) => {
  state.pending = isPending
}, 'setPending')

export const setNotification = storeModule.commit(
  (
    state,
    {
      message,
      type
    }: {
      message: typeof state.notificationMessage
      type: typeof state.notificationType
    }
  ) => {
    state.notificationMessage = message
    state.notificationType = type
  },
  'setNotification'
)

/**
 * Getters
 */

export const getCurrentSettings = storeModule.read((state) => {
  return state.list.find(
    (report) => report.report_type === getCurrentReportType()
  )
}, 'getCurrentSettings')

export const getCurrentReportType = storeModule.read((state) => {
  return state.currentReportType
}, 'getCurrentReportType')

export const getNotification = storeModule.read((state) => {
  return { message: state.notificationMessage, type: state.notificationType }
}, 'getNotification')

export const getPendingState = storeModule.read((state) => {
  return state.pending
}, 'getPendingState')

export const getSFTPReports = storeModule.read(
  (state) => state.list,
  'getSFTPReports'
)

/**
 * Actions
 */

export const createSFTPReport = storeModule.dispatch(
  async (_, { data }: { data: SFTPSettings }) => {
    try {
      setPending(true)
      setNotification({ message: null, type: 'success' })

      const partnerId = getCurrentPartnerId()
      const {
        data: {
          sftp_reports: [newReport]
        }
      } = await sendPapiRequest<{ sftp_reports: SFTPSettings[] }>({
        method: 'POST',
        path: `/partners/${partnerId}/reports`,
        data: {
          sftp_reports: [data]
        }
      })

      appendSFTPReport(newReport)

      setNotification({
        message: 'Save Successful',
        type: 'success'
      })

      return newReport
    } catch (e) {
      // Format an error message if unsuccessful.
      setNotification({
        message: `${e?.response?.status} : ${e?.response?.data?.error?.message}`,
        type: 'error'
      })
    } finally {
      setPending(false)
    }
  },
  'createSFTPSettings'
)

export const updateSFTPReport = storeModule.dispatch(
  async (_, { id, data }: { id: string; data: SFTPSettings }) => {
    try {
      setPending(true)
      setNotification({ message: null, type: 'success' })

      const partnerId = getCurrentPartnerId()
      const { data: report } = await sendPapiRequest({
        method: 'PUT',
        path: `/partners/${partnerId}/reports/sftp/${id}`,
        data
      })

      editSFTPReport(report)

      setNotification({
        message: 'Update Successful',
        type: 'success'
      })

      return report
    } catch (e) {
      setNotification({
        message: `${e?.response?.status} : ${e?.response?.data?.error?.message}`,
        type: 'error'
      })
    } finally {
      setPending(false)
    }
  },
  'updateSFTPReport'
)

export const fetchSFTPReports = storeModule.dispatch(async () => {
  const partnerId = getCurrentPartnerId()
  const {
    data: { sftp_reports }
  } = await sendPapiRequest<{ sftp_reports: SFTPSettings[] }>({
    method: 'GET',
    path: `/partners/${partnerId}/reports`
  })
  setSFTPReports(sftp_reports)
}, 'getSFTPSettings')
