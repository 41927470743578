import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.cellComponent), {
    "table-i-d": _ctx.tableID,
    "cell-data": _ctx.cellData,
    "cell-info": _ctx.cellInfo,
    "row-data": _ctx.rowData,
    onHandleValueChange: _ctx.handleValueChange
  }, null, 40, ["table-i-d", "cell-data", "cell-info", "row-data", "onHandleValueChange"]))
}