<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h4>{{ modalTitle }}</h4>
      <Tooltip position="bottom">
        <template #trigger>
          <Info class="ml-1 w-5 h-5" />
        </template>
        <template #content>
          <div class="w-32">
            {{ attributeModalTooltip }}
          </div>
        </template>
      </Tooltip>
    </div>
    <PackageTextInput
      id="attribute-name"
      v-model:value="name"
      label="Name"
      placeholder="Name"
      :input-width="'w-1/2'"
      required
    />
    <PackageTextInput
      id="attribute-label"
      v-model:value="label"
      label="Label"
      placeholder="Label"
      :input-width="'w-1/2'"
      required
    />
    <PackageTextInput
      id="attribute-description"
      v-model:value="description"
      label="Description"
      placeholder="Short Description"
      :input-width="'w-1/2'"
      required
    />
    <PackageDropdown
      id="attribute-data-type"
      v-model:value="dataType"
      :options="dataTypes"
      label="Data Type"
      placeholder="Select"
      class="w-1/2"
      required
      @update:value="filterFieldTypes"
    />
    <PackageDropdown
      v-if="dataType.label.length > 0"
      id="attribute-field-type"
      v-model:value="fieldType"
      :options="fieldTypes"
      label="Field Type"
      placeholder="Select"
      class="w-1/2"
      required
      @update:value="formatValueOptions"
    />
    <PackageTextInput
      v-if="fieldType.label.length > 0 && !isBooleanDataType"
      id="attribute-value"
      v-model:value="defaultValue"
      label="Default Value"
      placeholder="Value"
      :input-width="'w-1/2'"
      required
    />
    <PackageDropdown
      v-if="dataType.label.length > 0 && isBooleanDataType"
      id="attribute-boolean-type"
      v-model:value="booleanValueType"
      :options="valueFieldBooleanTypes"
      label="Field Type"
      placeholder="Select"
      class="w-1/2"
      required
    />
    <div class="flex justify-center mt-2 -mb-4">
      <Badge v-if="triggeredError" variant="error" shape="square">
        {{ triggeredErrorMessage }}
      </Badge>
      <Badge v-if="hasApprovedProduct" variant="error" shape="square">
        {{ approvedProductWarning }}
      </Badge>
    </div>
    <div class="flex items-end -mb-10 mt-5">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton :disabled="createDisabled" @click="performPrimaryAction">
        {{ modalButtonAction }}
      </LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageTextInput from './PackageTextInput.vue'
import PackageDropdown from './PackageDropdown.vue'
import {
  createAttributeForComponent,
  updateAttribute
} from '@/store/customMail'

export default defineComponent({
  name: 'AttributeModal',
  components: {
    PackageTextInput,
    PackageDropdown
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['close', 'saved'],
  data() {
    return {
      name: this.data?.name ?? '',
      label: this.data?.label ?? '',
      description: this.data?.instructional_text ?? '',
      defaultValue: this.data?.default_value ?? '',
      attributeID: this.data?.id ?? '',
      componentID: this.data?.componentID ?? '',
      modalTitle: '',
      modalButtonAction: '',
      valueFieldBooleanTypes:
        CUSTOM_MAIL_CONSTS.ATTRIBUTE_VALUE_FIELD_BOOLEAN_VALUES,
      dataTypes: CUSTOM_MAIL_CONSTS.ATTRIBUTE_DATA_TYPE_VALUES,
      fieldTypes: CUSTOM_MAIL_CONSTS.ATTRIBUTE_FIELD_TYPE_VALUES,
      hasApprovedProduct: this.data?.hasApprovedProduct,
      approvedProductWarning: CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_WARNING,
      dataType: this.data?.data_type ?? { label: '', value: '' },
      fieldType: this.data?.field_type ?? { label: '', value: '' },
      booleanValueType: this.data?.field_type ?? { label: '', value: '' },
      triggeredError: false,
      triggeredErrorMessage: '',
      isBooleanDataType: false,
      attributeModalTooltip: ''
    }
  },
  computed: {
    createDisabled() {
      return false
    }
  },
  watch: {
    data: function (newVal) {
      this.hasApprovedProduct = newVal.hasApprovedProduct
      this.name = newVal.name ?? ''
      this.label = newVal.label ?? ''
      this.defaultValue = newVal.default_value ?? ''
      this.description = newVal.instructional_text ?? ''
      this.dataType = newVal.data_type ?? { label: '', value: '' }
      this.fieldType = newVal.field_type ?? { label: '', value: '' }
      this.attributeID = newVal.id ?? ''
    },
    mode: function (newVal) {
      this.configureMode(newVal)
    }
  },
  mounted() {
    this.configureMode(this.mode as string)
  },
  methods: {
    close() {
      this.$emit('close', {})
    },
    async performPrimaryAction() {
      switch (this.dataType.value) {
        case 'boolean':
          if (this.defaultValue !== 'true' && this.defaultValue !== 'false') {
            this.triggerError(
              CUSTOM_MAIL_CONSTS.ERRORS.ATTRIBUTE_WRONG_VALUE_TYPE,
              'Boolean'
            )
            return
          }
          break
        case 'number':
          if (isNaN(this.defaultValue)) {
            this.triggerError(
              CUSTOM_MAIL_CONSTS.ERRORS.ATTRIBUTE_WRONG_VALUE_TYPE,
              'Number'
            )
            return
          }
          break
      }
      const payload = {
        name: this.name,
        label: this.label,
        description: this.description,
        defaultValue: this.defaultValue,
        dataType: this.dataType.value,
        fieldType: this.fieldType.value,
        componentID: this.data?.componentID
      } as any
      if (this.mode === CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_CREATE_MODE) {
        await createAttributeForComponent(payload)
      } else {
        payload.id = this.attributeID
        await updateAttribute(payload)
      }
      this.$emit('close', { refreshComponent: true })
      this.triggeredError = false
    },
    triggerError(message: string, type?: string) {
      this.triggeredError = true
      this.triggeredErrorMessage = message + type
    },
    configureMode(mode: string) {
      if (mode === CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_CREATE_MODE) {
        this.modalTitle = CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_CREATE_TITLE
        this.modalButtonAction =
          CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_CREATE_BUTTON
        this.attributeModalTooltip =
          CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_NEW_TOOLTIP
      } else {
        this.modalTitle = CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_EDIT_TITLE
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_EDIT_BUTTON
        this.attributeModalTooltip =
          CUSTOM_MAIL_CONSTS.ATTRIBUTE_MODAL_EDIT_TOOLTIP
      }
    },
    filterFieldTypes(dataType: any) {
      this.fieldTypes = CUSTOM_MAIL_CONSTS.ATTRIBUTE_FIELD_TYPE_VALUES
      this.fieldType = { label: '', value: '' }
      this.isBooleanDataType = false
      if (dataType.value === 'boolean') {
        const checkboxVal = CUSTOM_MAIL_CONSTS.ATTRIBUTE_FIELD_TYPE_VALUES.find(
          (el) => el.value === 'checkbox'
        ) as any
        this.fieldTypes = [checkboxVal]
      }
    },
    formatValueOptions(fieldType: any) {
      if (this.dataType.value === 'boolean' && fieldType.value === 'checkbox') {
        this.isBooleanDataType = true
      }
    }
  }
})
</script>
