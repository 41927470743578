import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavigationItem = _resolveComponent("MainNavigationItem")!
  const _component_MainNavigationChildItem = _resolveComponent("MainNavigationChildItem")!
  const _component_MainNavigation = _resolveComponent("MainNavigation")!

  return (_openBlock(), _createBlock(_component_MainNavigation, { "list-class": "overflow-x-hidden overflow-y-scroll h-screen" }, {
    default: _withCtx(({ expanded }) => [
      _createVNode(_component_MainNavigationItem, {
        id: "guidelines",
        title: "Guidelines",
        expanded: expanded,
        to: "/onboarding/digital-proofing/guidelines"
      }, null, 8, ["expanded"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_MainNavigationItem, {
          key: item.resourceId,
          title: item.resourceName,
          expanded: expanded,
          "item-class": 'font-bold'
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.permutations, (permutation) => {
              return (_openBlock(), _createBlock(_component_MainNavigationChildItem, {
                key: permutation.id,
                title: permutation.name,
                to: `/partners/current/onboarding/digital-proofs/${
            permutation.id || 'missing'
          }`
              }, null, 8, ["title", "to"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["title", "expanded"]))
      }), 128))
    ]),
    _: 1
  }))
}