<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import InventoryCard from './InventoryCard.vue'
import { BuckslipOrder, CardOrder, EnvelopeOrder } from './types'
import { formatNumber } from '@/utils/formatters'
import { getEditInventoryFlag } from '@/store/launchDarkly'

export default defineComponent({
  name: 'Inventory',
  components: { InventoryCard },
  props: {
    order: {
      type: Object as PropType<
        Pick<
          CardOrder | EnvelopeOrder | BuckslipOrder,
          | 'id'
          | 'units_left_to_allocate'
          | 'materiel_inventories'
          | 'availability_date'
        >
      >,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    updateError: {
      type: null as unknown as PropType<string | null>,
      validator: (v: any) => typeof v === 'string' || v === null,
      required: true
    }
  },
  emits: ['deleted', 'updated', 'added', 'showTransfer'],
  setup: () => {
    const printerModalVisible = ref(false)

    const editInventoryFlag = computed(() => getEditInventoryFlag())

    const showModal = () => {
      printerModalVisible.value = true
    }

    return { editInventoryFlag, formatNumber, printerModalVisible, showModal }
  }
})
</script>

<template>
  <div class="ml-16 xl:ml-24 bg-white mt-2 rounded py-6 px-8">
    <Alert v-if="updateError" variant="error">
      {{ updateError }}
    </Alert>
    <div class="lg:flex items-center">
      <div class="text-gray-500 mr-2">Print Partners</div>
      <div
        class="border rounded text-xs text-shadow-50 px-5 p-2 text-center bg-white"
      >
        {{ formatNumber(order.units_left_to_allocate) }}
      </div>
      <div class="lg:ml-2 text-sm">units left to allocate</div>
      <div v-if="loading" class="ml-auto w-3 h-3">
        <loading-indicator></loading-indicator>
      </div>
    </div>
    <div class="lg:flex pt-4 items">
      <lob-button
        v-if="editInventoryFlag"
        variant="tertiary"
        class="mr-4 shadow-sm flex flex-col p-2 lg:h-32 border-0"
        @click="$emit('added')"
      >
        <div class="flex flex-col items-center h-24 justify-center">
          <img
            :src="require('@/assets/images/icon-printer.png')"
            class="w-8 h-8"
          />
          <span class="block text-xs">Add Printer</span>
        </div>
      </lob-button>
      <div
        class="flex-grow-1 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4"
      >
        <InventoryCard
          v-for="inventory in order.materiel_inventories"
          :key="
            inventory.id +
            '-' +
            inventory.status +
            '-' +
            inventory.num_remaining +
            '-' +
            inventory.total_cost
          "
          :inventory="inventory"
          :disabled="loading"
          :deletable="order.materiel_inventories?.length > 1"
          :update-error="updateError"
          @updated="$emit('updated', $event)"
          @deleted="$emit('deleted', $event)"
          @showTransfer="$emit('showTransfer', $event)"
        />
        <div
          v-if="!order.materiel_inventories.length"
          class="lg:col-span-2 xl:col-span-3 flex items-center justify-center"
        >
          <alert class="text-sm">No printer configured</alert>
        </div>
      </div>
    </div>
  </div>
</template>
