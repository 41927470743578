import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldHeader = _resolveComponent("FieldHeader")!
  const _component_P = _resolveComponent("P")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FieldHeader, {
      schema: _ctx.schema,
      field: _ctx.field,
      "test-set": _ctx.testSet,
      "onUpdate:testSet": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:testSet', $event)))
    }, null, 8, ["schema", "field", "test-set"]),
    _createVNode(_component_P, null, {
      default: _withCtx(() => [
        _createTextVNode(" ERROR: There is no editor implemented for a field of type \"" + _toDisplayString(_ctx.field.type) + "\" ", 1)
      ]),
      _: 1
    })
  ], 64))
}