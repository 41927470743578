<template>
  <div id="digital-proofing-root">
    <!-- 
      Cannot set both x: shown and y: scroll
      https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
    -->
    <SecondaryNavigationPanel id="sidebar" />
    <main id="content">
      <router-view />
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SecondaryNavigationPanel from '@/components/DigitalProofing/SecondaryNavigationPanel.vue'

export default defineComponent({
  name: 'DigitalProofing',
  components: { SecondaryNavigationPanel }
})
</script>

<style lang="scss" scoped>
#digital-proofing-root {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100vh;
}

#content {
  height: 100vh;
  overflow: scroll;
}
</style>
