<template>
  <form>
    <div class="row mt-5 contentSection">
      <div class="col-3">
        <p class="sectionTitle">Holidays</p>
      </div>
      <div class="col-9">
        <div class="row holidays">
          <div
            v-for="(column, index) in holidays"
            :key="index"
            class="col-auto"
          >
            <template v-for="holiday in column" :key="holidayId(holiday)">
              <checkbox
                v-model="computedHolidays"
                :label="holiday"
                :name="holidayId(holiday)"
                :value="holidayId(holiday)"
              />
              <br />
            </template>
          </div>
        </div>
        <Alert v-if="errorMessage" :variant="errorType" class="mt-2">
          {{ errorMessage }}
        </Alert>
        <LobButton
          class="mt-4"
          :disabled="pending"
          data-testid="holidayBtn"
          @click.prevent="holidaySubmit"
        >
          Save Data
        </LobButton>
      </div>
    </div>
  </form>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import * as session from '@/store/session'
import {
  fetchCurrentPartner,
  getCurrentPartner,
  getCurrentPartnerId
} from '@/store/partners'
export default defineComponent({
  name: 'HolidayForm',
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedHolidays: [] as string[],
      errorMessage: null as string | null,
      errorType: 'error',
      pending: false,
      holidays: [
        [
          'Boxing Day',
          'Christmas',
          'Christmas Eve',
          'Columbus Day',
          'Early May Bank Day',
          'Easter Monday',
          'Good Friday'
        ],
        [
          'Independence Day',
          'Labor Day',
          'Martin Luther King Day',
          'Memorial Day',
          'New Years Day',
          'New Years Day After',
          'New Years Eve'
        ],
        [
          'Pioneer Day',
          'Presidents Day',
          'Spring Bank Day',
          'Summer Bank Day',
          'Thanksgiving',
          'Thanksgiving Friday',
          'Veterans Day'
        ]
      ]
    }
  },
  computed: {
    computedHolidays: {
      get(): string[] {
        const partner = getCurrentPartner()
        if (partner) {
          const holidays = partner.holidays.map((val) => {
            return val.name.toLowerCase()
          })

          if (this.selectedHolidays.length === 0 && holidays.length !== 0) {
            return holidays
          } else {
            return this.selectedHolidays
          }
        } else {
          return []
        }
      },
      set(holidays) {
        this.selectedHolidays = holidays
      }
    }
  },
  methods: {
    holidayId(holiday) {
      return holiday.replace(/ /g, '_').toLowerCase()
    },
    async holidaySubmit() {
      this.pending = true
      this.errorMessage = null
      const partnerId = getCurrentPartnerId()
      if (partnerId === null) return
      const partner = await session.getPartner({
        partner: partnerId
      })
      const deleteHolidays = partner.holidays.filter((val) => {
        return !this.computedHolidays.includes(val.name.toLowerCase())
      })
      try {
        await session.deletePartnerHolidays({
          partner: partnerId,
          data: {
            holidays: deleteHolidays.map((val) => {
              return val.name
            })
          }
        })

        await session.updatePartnerHolidays({
          partner: partnerId,
          data: {
            holidays: Array.from(this.computedHolidays)
          }
        })
        this.errorMessage = 'Save Successful'
        this.errorType = 'success'
        await fetchCurrentPartner({ partnerId })
      } catch (e) {
        // Format an error message if unsuccessful.
        const eResp = e.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
      this.pending = false
    }
  }
})
</script>
<style scoped lang="scss">
.checkbox {
  font-size: 13px;
  margin-left: 4px;
  padding-top: 3px;
}
</style>
