<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { CardOrderStatus, ORDER_TYPE } from './types'
import LogsIcon from './LogsIcon.vue'

const STATUSES = {
  pending: 'bg-yellowOrange-500 text-white',
  printing: 'bg-turquoise-500 text-white',
  available: 'bg-success text-white',
  cancelled: 'bg-chineseOrange-500 text-white',
  depleted: 'bg-gray-100 text-gray-700'
}

const statusToColor = (s: string): string => STATUSES[s]

export default defineComponent({
  name: 'OrderId',
  components: { LogsIcon },
  props: {
    status: { type: String as PropType<CardOrderStatus>, required: true },
    id: { type: String, required: true },
    type: { type: String, required: true }
  },
  setup() {
    return { statusToColor, ORDER_TYPE }
  }
})
</script>

<template>
  <div class="flex-grow">
    <div class="rounded-t bg-gray-500 flex justify-between items-stretch">
      <div
        class="text-white text-xs px-2 py-1 font-light flex-grow overflow-hidden truncate"
      >
        {{ type === ORDER_TYPE.CARDS ? 'Card Order ID' : 'Env Order ID' }}
      </div>
      <div
        :class="`
           text-xs
          px-3
          py-1
          rounded-t
          ${statusToColor(status)}
          uppercase
          font-light
          flex-shrink
          trucate
          tracking-wider`"
      >
        {{ status === 'printing' ? 'in production' : status }}
      </div>
    </div>
    <h3
      class="border bg-white rounded-b text-sm font-light px-2 py-2 overflow-hidden truncate"
    >
      {{ id }}

      <LogsIcon :query="{ materiel_order_id: id }" />
    </h3>
  </div>
</template>
