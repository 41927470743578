<template>
  <TextInput
    id="name"
    label="Text"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    modelValue: {
      type: String,
      required: false,
      default: 'false'
    }
  },
  emits: ['update:modelValue']
})
</script>
