import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c2124b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-field-editor-root mb-3" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldHeader = _resolveComponent("FieldHeader")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FieldHeader, {
      schema: _ctx.schema,
      field: _ctx.field,
      "test-set": _ctx.testSet,
      "supports-separation": "",
      "onUpdate:testSet": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:testSet', $event)))
    }, null, 8, ["schema", "field", "test-set"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.resourcesLoaded)
        ? (_openBlock(), _createBlock(_component_Multiselect, {
            key: 0,
            id: "default_select",
            "model-value": _ctx.selectValues,
            label: "",
            options: _ctx.selectOptions,
            placeholder: "Pick a default",
            "input-width-class": "w-full",
            class: "w-full",
            "onUpdate:modelValue": _ctx.updateValues
          }, null, 8, ["model-value", "options", "onUpdate:modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}