<script lang="ts">
import { defineComponent, toRefs, PropType } from '@vue/runtime-core'
import { reactive, ref } from '@vue/reactivity'
import { formatNumber, underscoreToSpace } from '@/utils/formatters'
import { RESOURCES, Capacities } from '@/consts'

const STATUS_LIVE = 'live'
const STATUS_TESTING = 'testing'
const STATUS_PROSPECTING = 'prospecting'

const STATUS_COLOR_CLASS_MAP = {
  [STATUS_LIVE]: 'bg-mint-500',
  [STATUS_TESTING]: 'bg-warning',
  [STATUS_PROSPECTING]: 'bg-alert'
} as const

interface Props {
  url: string
  partnerStatus: string
  title: string
  liveCapacities: Capacities
  totalCapacities: Capacities
  resourceToEdit?: string
  partnerID: string
}

export default defineComponent({
  name: 'StatusLinkCard',
  props: {
    url: {
      type: String,
      required: true
    },
    partnerStatus: {
      type: String,
      required: true,
      validator(value: string) {
        return [STATUS_LIVE, STATUS_TESTING, STATUS_PROSPECTING].includes(value)
      }
    },
    partnerID: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    liveCapacities: {
      type: Object as PropType<Capacities>,
      required: true
    },
    totalCapacities: {
      type: Object as PropType<Capacities>,
      required: true
    },
    resourceToEdit: {
      type: String,
      default: ''
    },
    slaToEdit: {
      type: String,
      default: ''
    }
  },
  emits: [
    'edit',
    'update',
    'create',
    'bulkEdit',
    'transition',
    'openDeleteModal',
    'delete'
  ],
  setup(props: Props, { emit }) {
    const { liveCapacities, totalCapacities } = toRefs(props)
    const isMenuOpen = ref(false)
    const editInputsValue = reactive<{
      default_live: string
      default_total: string
      one_day_sla_live: string
      one_day_sla_total: string
      one_day_sla_multiplier: string
    }>({
      default_live: '',
      default_total: '',
      one_day_sla_live: '',
      one_day_sla_total: '',
      one_day_sla_multiplier: ''
    })
    const slaTypes = ref(['default', 'one_day_sla'])

    const onEdit = (resource: string, slaType: string) => {
      if (slaType === 'one_day_sla') {
        editInputsValue.one_day_sla_live =
          liveCapacities.value[resource][slaType]
        editInputsValue.one_day_sla_total =
          totalCapacities.value[resource][slaType]
        editInputsValue.one_day_sla_multiplier =
          totalCapacities.value[resource].one_day_sla_multiplier
      } else {
        editInputsValue.default_live =
          liveCapacities.value?.[resource]?.[slaType]
        editInputsValue.default_total =
          totalCapacities.value?.[resource]?.[slaType]
      }
      emit('edit', {
        resource,
        slaType
      })
    }

    const onBulkEdit = (resource: string, slaType: string) => {
      emit('bulkEdit', {
        resource,
        slaType
      })
    }

    const openDeleteModal = (
      resource: string,
      totalCapacity: any,
      liveCapacity: any
    ) => {
      emit('openDeleteModal', {
        partnerID: props.partnerID,
        partnerName: props.title,
        totalCapacityID: totalCapacity.id,
        liveCapacity,
        resource
      })
    }

    const onUpdate = (resource: string, slaType: string) => {
      const capacityToUpdate = { resource, slaType } as {
        resource: string
        slaType: string
        live: number
        total: number
        one_day_sla_multiplier?: number
      }

      if (slaType === 'one_day_sla') {
        capacityToUpdate.live = parseInt(editInputsValue.one_day_sla_live, 10)
        capacityToUpdate.total = parseInt(editInputsValue.one_day_sla_total, 10)
        capacityToUpdate.one_day_sla_multiplier = parseFloat(
          editInputsValue.one_day_sla_multiplier
        )
      } else {
        capacityToUpdate.live = parseInt(editInputsValue.default_live, 10)
        capacityToUpdate.total = parseInt(editInputsValue.default_total, 10)
      }
      emit('update', capacityToUpdate)
    }

    return {
      RESOURCES,
      STATUS_COLOR_CLASS_MAP,
      STATUS_LIVE,
      formatNumber,
      isMenuOpen,
      editInputsValue,
      onEdit,
      onUpdate,
      onBulkEdit,
      slaTypes,
      underscoreToSpace,
      openDeleteModal
    }
  }
})
</script>

<template>
  <div class="bg-white shadow-sm rounded-lg border-gray-100 flex items-stretch">
    <div :class="`w-2 ${STATUS_COLOR_CLASS_MAP[partnerStatus]}`">&nbsp;</div>
    <div class="p-3 w-full">
      <div class="flex justify-between align-items-center relative">
        <RouterLink :to="url">
          <h3 class="text-lg font-bold text-gray-300 leading-none">
            {{ title }}
          </h3>
        </RouterLink>

        <template v-if="partnerStatus != STATUS_LIVE">
          <MoreHorizontal
            data-testid="menu-toggle"
            class="w-7 h-7 opacity-30 cursor-pointer"
            role="button"
            aria-label="Open menu"
            @click="isMenuOpen = !isMenuOpen"
          />
          <div
            v-if="isMenuOpen"
            class="bg-white rounded-sm border border-gray-100 shadow-sm py-2 px-3 absolute right-0 top-6"
          >
            <LobButton
              variant="none"
              size="small"
              class="text-sm"
              data-testid="transition-button"
              @click.prevent="
                () => {
                  $emit('transition')
                  isMenuOpen = false
                }
              "
            >
              Transition
              <ChevronRight class="ml-1 w-3 h-3" />
            </LobButton>
            <LobButton
              variant="none"
              size="small"
              class="text-sm text-error"
              data-testid="delete-button"
              @click.prevent="
                () => {
                  $emit('delete')
                  isMenuOpen = false
                }
              "
            >
              Delete
            </LobButton>
          </div>
        </template>
      </div>
      <ul v-if="partnerStatus === STATUS_LIVE" class="mt-3 w-full">
        <li
          v-for="resource in Object.keys(RESOURCES)"
          :key="resource"
          class="rounded bg-white-100 px-2 py-1 flex flex-wrap items-center justify-between mb-2"
        >
          <LobButton
            v-if="
              totalCapacities[resource] === undefined ||
              totalCapacities[resource]['default'] === undefined
            "
            variant="link"
            size="small"
            class="text-sm"
            :data-testid="`btn-create-${resource}-${slaType}`"
            @click.prevent="$emit('create', resource)"
          >
            Add {{ RESOURCES[resource].label.toLowerCase() }} capacity
          </LobButton>
          <template v-else>
            <div
              class="text-xs uppercase text-gray-300 font-normal w-full pr/2 flex"
            >
              {{ RESOURCES[resource].label }}
              <Trash
                class="ml-1 h-4 opacity-80 cursor-pointer"
                icon="trash"
                @click="
                  openDeleteModal(
                    resource,
                    totalCapacities[resource],
                    liveCapacities[resource]
                  )
                "
              />
            </div>
            <template v-for="slaType in slaTypes" :key="slaType">
              <div class="flex align-items-center w-10/12 pl-3">
                <div class="text-xs uppercase text-gray-300 font-normal w-1/4">
                  {{ underscoreToSpace(String(slaType)) }}
                </div>
                <template
                  v-if="
                    partnerStatus === STATUS_LIVE &&
                    resource === resourceToEdit &&
                    slaType === slaToEdit
                  "
                >
                  <TextInput
                    :id="`edit-live-input-${slaType}`"
                    v-model="editInputsValue[`${slaType}_live`]"
                    size="small"
                    placeholder="capacity"
                    class="w-24 pr-2"
                    :data-testid="`edit-live-input-${slaType}`"
                  />
                  <span class="text-xs font-light">of</span>
                  <TextInput
                    :id="`edit-total-input-${slaType}`"
                    v-model="editInputsValue[`${slaType}_total`]"
                    size="small"
                    placeholder="capacity"
                    class="w-24 pl-2"
                    :data-testid="`edit-total-input-${slaType}`"
                  />
                </template>
                <span v-else class="text-xs font-light w-3/4">
                  <template
                    v-if="
                      partnerStatus === STATUS_LIVE &&
                      liveCapacities !== undefined
                    "
                  >
                    {{
                      liveCapacities?.[resource]?.[slaType]
                        ? formatNumber(liveCapacities[resource][slaType])
                        : 0
                    }}
                    of
                  </template>
                  <span class="font-bold">
                    {{ formatNumber(totalCapacities[resource][slaType]) }}
                  </span>
                  remaining
                  <!-- slaTypes[1] is one day sla -->
                  <template
                    v-if="
                      partnerStatus === STATUS_LIVE && slaType === slaTypes[1]
                    "
                  >
                    &nbsp;{{
                      `(${formatNumber(
                        totalCapacities[resource].one_day_sla_multiplier
                      )}x Multiplier)`
                    }}
                  </template>
                </span>
              </div>
              <div
                v-if="totalCapacities[resource] !== undefined"
                class="w-2/12"
              >
                <template v-if="resource != resourceToEdit">
                  <button
                    :id="`btn-edit-${resource}-${slaType}`"
                    class="focus:outline-none focus:ring-2 pr-2"
                    :data-testid="`btn-edit-${resource}-${slaType}`"
                    @click.prevent="() => onEdit(resource, slaType)"
                  >
                    <fa class="opacity-40" icon="edit" />
                  </button>
                </template>
                <button
                  v-else
                  :id="`btn-save-${resource}-${slaType}`"
                  class="focus:outline-none focus:ring-2"
                  :data-testid="`btn-save-${resource}-${slaType}`"
                  @click.prevent="() => onUpdate(resource, slaType)"
                >
                  <fa class="opacity-40" icon="save" />
                </button>
              </div>
              <div
                v-if="
                  partnerStatus === STATUS_LIVE &&
                  resource === resourceToEdit &&
                  slaType === slaToEdit &&
                  slaType !== 'default'
                "
                class="flex align-items-center mt-1 w-10/12 pl-3"
              >
                <div class="w-1/4 -mr-2" />
                <TextInput
                  :id="`edit-total-input-${slaType}`"
                  v-model="editInputsValue.one_day_sla_multiplier"
                  size="small"
                  placeholder="multiplier"
                  class="w-24 pl-2"
                  :data-testid="`edit-total-input-${slaType}`"
                />
                <div class="text-xs font-light ml-2">Multiplier</div>
              </div>
            </template>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>
