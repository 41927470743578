<template>
  <LoadingIndicator>
    <div v-if="!isLoading">
      <div class="text-3xl mb-10 ml-10 font-bold">Custom Mail</div>
      <Alert v-if="alertMsg" variant="error" class="w-full">
        <p>{{ alertMsg }}</p>
      </Alert>
      <div v-if="items.length === 0" class="text-center mt-60">
        <div class="text-4xl">You have no Custom Mailers</div>
        <div v-if="isAdminOrPopsUser" class="mt-2">
          <div class="text-xl mb-10">
            Start creating Custom Mailer packages.
          </div>
          <LobButton class="m-auto" @click="openNewPackageModal">
            Get Started
          </LobButton>
        </div>
      </div>
      <Card v-else class="min-w-min w-100">
        <div class="flex flex-row text-xl">
          Custom Mail List
          <Tooltip position="right">
            <template #trigger>
              <Info class="ml-1 w-5 h-5" />
            </template>
            <template #content>
              {{ packageListTooltip }}
            </template>
          </Tooltip>
          <div class="flex-1" />
          <PackageButton v-if="isAdminOrPopsUser" @click="openNewPackageModal">
            New Package
          </PackageButton>
        </div>
        <PackagesTable
          :data="items"
          :is-admin-or-pops-user="isAdminOrPopsUser"
          @openCloneModal="openClonePackageModal"
          @alertMsgEvent="handleAlert"
        />
        <PaginationNext
          :previous-key="pagination.previous"
          :next-key="pagination.next"
          @change="onPageChange"
        />
      </Card>
      <PackageModal
        v-if="openPackageModal"
        :mode="packageModelMode"
        :data="modalData"
        :is-open="openPackageModal"
        @close="closeModal"
      />
    </div>
  </LoadingIndicator>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { isAdminOrPopsUser } from '@/store/session'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackagesTable from '../common/PackagesTable.vue'
import PackageButton from '../common/PackageButton.vue'
import PackageModal from '../common/PackageModal.vue'
import { getMorePackages, getPackages } from '@/store/customMail'
import PaginationNext from '@/components/global/PaginationNext.vue'

export default defineComponent({
  name: 'PackageList',
  components: {
    PackagesTable,
    PackageButton,
    PackageModal,
    PaginationNext
  },
  data() {
    return {
      modalData: {},
      alertMsg: '',
      packageModelMode: '',
      openPackageModal: false,
      isLoading: true,
      items: [],
      packageListTooltip: CUSTOM_MAIL_CONSTS.PACKAGE_LIST_TOOLTIP,
      pagination: {
        previous: '',
        next: ''
      }
    }
  },
  computed: {
    isAdminOrPopsUser() {
      return isAdminOrPopsUser()
    }
  },
  async mounted() {
    const resp = await getPackages()
    this.loadPackages(resp)
  },
  methods: {
    loadPackages(resp) {
      this.items = resp.data
      this.pagination.previous = resp.previous_url ?? ''
      this.pagination.next = resp.next_url ?? ''
      this.isLoading = false
    },
    openClonePackageModal(item) {
      this.modalData = item
      this.packageModelMode = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CLONE_MODE
      this.openPackageModal = true
    },
    handleAlert(msg) {
      this.alertMsg = msg
    },
    openNewPackageModal() {
      this.modalData = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_DEFAULT_VALUES
      this.packageModelMode = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CREATE_MODE
      this.openPackageModal = true
    },
    closeModal() {
      this.openPackageModal = false
    },
    async onPageChange(url) {
      const strippedURL = url.substring(url.indexOf('/products'))
      const resp = await getMorePackages({ url: strippedURL })
      this.loadPackages(resp)
    }
  }
})
</script>

<style lang="scss" scoped></style>
