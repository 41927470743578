import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7645b3f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "main-page-root"
}
const _hoisted_2 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PortalMainNavigation = _resolveComponent("PortalMainNavigation")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.userAvailable)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PortalMainNavigation, { id: "sidebar" }),
        _createElementVNode("main", _hoisted_2, [
          _createVNode(_component_router_view)
        ])
      ]))
    : _createCommentVNode("", true)
}