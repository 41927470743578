<template>
  <button
    :class="[
      `w-full flex justify-between items-center hover:text-primary-300  focus:outline-none
    focus:ring-2 focus:ring-primary-100 focus:border-transparent rounded tertiary bg-white border border-gray-100
    text-gray-500 active:border-gray-300 disabled:border-white-300 px-3 py-2 text-base shadow-sm`,
      buttonClass
    ]"
  >
    <div class="flex items-center">
      <slot name="left" />
      {{ label }}
    </div>

    <div><slot name="right" /></div>
  </button>
</template>

<script>
export default {
  name: 'ComposedButton',

  props: {
    label: {
      type: String,
      required: true
    },
    buttonClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style></style>
