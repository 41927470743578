<script lang="ts">
import HomeHeader from '@/components/Home/Header.vue'
import HomeAside from '@/components/Home/Aside.vue'
import HomeCapacity from '@/components/Home/Capacity.vue'
// import ReportsEmbed from '@/components/Reports/ReportsEmbed.vue'
import SFTPConfig from '@/components/Home/SFTPConfig.vue'
import { getCurrentPartner, getPartnerLoading } from '@/store/partners'

import { computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'PartnerHome',
  components: {
    HomeHeader,
    HomeAside,
    HomeCapacity,
    /* ReportsEmbed , */ SFTPConfig
  },
  setup() {
    const isLoading = computed(getPartnerLoading)
    const partner = computed(getCurrentPartner)
    return { partner, isLoading }
  }
})
</script>

<template title="Home">
  <LoadingIndicator>
    <main v-if="!isLoading">
      <div v-if="partner" class="content p-4">
        <HomeHeader :partner-display-name="partner.display_name" />

        <div class="grid grid-cols-12 gap-10 mt-6">
          <div class="col-span-9 border-t border-t-gray-100">
            <HomeCapacity />
          </div>
          <div class="col-span-3">
            <HomeAside :partner="partner" />
          </div>
          <div class="col-span-12">
            <div class="border border-gray-300 rounded overflow-hidden">
              <!-- <ReportsEmbed resource="partner_home" height="600px" /> -->
              <div class="p-3 text-gray-300 text-sm">
                Report charts coming soon
              </div>
            </div>
          </div>
          <div class="col-span-9">
            <SFTPConfig />
          </div>
        </div>
      </div>
    </main>
  </LoadingIndicator>
</template>
