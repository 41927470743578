<template>
  <div class="flex flex-col mb-2">
    <div>
      <label>
        {{ label }}
      </label>
      <span v-if="required" class="text-error">*</span>
    </div>
    <Dropdown
      :id="id"
      :model-value="value"
      :options="options"
      label=""
      :placeholder="placeholder"
      @input="handleInput"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PackageDropdown',
  props: {
    id: {
      type: String,
      default: () => {
        return ''
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    label: {
      type: String,
      default: () => {
        return ''
      }
    },
    required: { type: Boolean },
    options: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  emits: ['update:value'],
  methods: {
    handleInput(e) {
      this.$emit('update:value', e)
    }
  }
})
</script>
