<template>
  <PortalBreadCrumb />
  <div v-if="isAdminOrPopsUser">
    <h2 class="text-3xl mb-20 ml-3 mt-10 font-bold">Manage Partner Bids</h2>
    <Card class="p-3 m-4">
      <div class="flex flex-row text-xl items-baseline p-3">
        Partner Bid List
        <Tooltip position="right">
          <template #trigger>
            <Info class="ml-1 w-5 h-5" />
          </template>
          <template #content>
            {{ bidsListTooltip }}
          </template>
        </Tooltip>
        <div class="flex-1" />
        <PackageButton :on-click="openNewBidModal">New Bid</PackageButton>
      </div>
      <ManageBidsTable
        :bids-by-partner="bidsByPartner"
        :all-bidding-partners="allBiddingPartners"
        :is-admin-or-pops-user="isAdminOrPopsUser"
        @openAdminEditModal="openAdminEditModal"
        @update-status="updateStatus"
      />
    </Card>
    <BidModal
      :product-id="packageID"
      :partner-id="currentPartner?.id"
      :current-partner="selectedPartner"
      :mode="bidModalMode"
      :data="modalData"
      :is-open="openBidModal"
      :is-admin-or-pops-user="isAdminOrPopsUser"
      @close="(e) => closeAdminModal(e)"
      @updateStatus="updateStatus"
    />
  </div>
  <div v-else class="ml-4 mr-3">
    <div class="text-3xl mb-20 ml-3 mt-10 font-bold">
      Manage Bids ({{ currentPartner?.display_name }})
    </div>
    <Card class="p-3">
      <div class="flex flex-between grid grid-cols-6">
        <div
          class="flex flex-row text-xl items-baseline col-span-4 pr-2 pt-1 pb-2"
        >
          Bid List
          <Tooltip position="right">
            <template #trigger>
              <Info class="ml-1 w-5 h-5" />
            </template>
            <template #content>
              {{ bidsListTooltip }}
            </template>
          </Tooltip>
        </div>
        <div class="flex justify-content-end items-baseline">
          <Badge
            v-if="partnerProductBids?.status"
            class="justify-content-center p-1 text-sm"
            :variant="configureStatusVariant(partnerProductBids?.status)"
          >
            {{ partnerProductBids?.status }}
          </Badge>
        </div>
        <div class="p-2 justify-self-center">
          {{ partnerProductBids?.date }}
        </div>
      </div>
      <div v-if="partnerProductBids" class="flex flex-column w-100">
        <PartnerBidsTable
          :partner="partnerProductBids"
          :bids-by-partner="partnerProductBids"
          :all-bidding-partners="allBiddingPartners"
          :is-admin-or-pops-user="isAdminOrPopsUser"
          @openPartnerEditModal="openPartnerEditModal"
        />
      </div>
      <Alert v-else class="flex flex-column w-100">
        There are currently no bids available for this product.
      </Alert>
    </Card>
    <PartnerBidModal
      :partner-bids="partnerProductBids?.bidsArray"
      :product-id="packageID"
      :partner-id="currentPartner?.id"
      :current-partner="selectedPartner"
      :mode="bidModalMode"
      :data="modalData"
      :is-open="openBidModal"
      :is-admin-or-pops-user="isAdminOrPopsUser"
      @close="(e) => closeAdminModal(e)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { isAdminOrPopsUser } from '@/store/session'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import {
  getProductBids,
  getCurrentUserAction,
  getPackage,
  PackagePageData,
  setPartnerPackageBids,
  PartnerPackageBids,
  editPartnerPackageStatus
} from '@/store/customMail'
import { getCurrentPartner } from '@/store/partners'
import PackageButton from '../common/PackageButton.vue'
import ManageBidsTable from '../common/ManageBidsTable.vue'
import BidModal from '../common/BidModal.vue'
import PartnerBidModal from '../common/PartnerBidModal.vue'
import PartnerBidsTable from '../common/PartnerBidsTable.vue'
import PortalBreadCrumb from '../../../../../components/common/PortalBreadcrumb.vue'

const PENDING = 'pending'
const SUBMITTED = 'submitted'
const ENABLED = 'enabled'

export default defineComponent({
  name: 'ManageBids',
  components: {
    PackageButton,
    ManageBidsTable,
    BidModal,
    PartnerBidsTable,
    PortalBreadCrumb,
    PartnerBidModal
  },
  data() {
    return {
      packageID: '',
      bidsByPartner: {},
      partnerProductBids: null as PartnerPackageBids | null,
      modalData: {},
      bidModalMode: '',
      openBidModal: false,
      bidsListTooltip: CUSTOM_MAIL_CONSTS.BID_LIST_TOOLTIP,
      selectedPartner: {},
      allBiddingPartners: [],
      updatedPartner: '',
      updatedStatus: '',
      toggleStatus: false,
      toggleDisabled: true
    }
  },
  computed: {
    isAdminOrPopsUser() {
      return isAdminOrPopsUser()
    },
    mode() {
      if (!getCurrentUserAction()) return 'Unauthorized'
      return getCurrentUserAction()?.split('-')[2]
    },
    currentPartner() {
      return getCurrentPartner()
    }
  },
  async mounted() {
    await this.loadProductBids()

    this.configureStatusVariant(this.updatedStatus)
    this.toggleStatus = false
    this.toggleDisabled = true

    if (this.updatedStatus === ENABLED) {
      this.toggleStatus = true
      this.toggleDisabled = false
    }

    if (this.updatedStatus === SUBMITTED) {
      this.toggleDisabled = false
    }
  },
  methods: {
    configureStatusVariant(rowStatus: string) {
      switch (rowStatus) {
        case SUBMITTED:
          return 'warning'
        case PENDING:
          return 'flint'
        case ENABLED:
          return 'success'
        default:
          return 'info'
      }
    },
    openNewBidModal() {
      this.openBidModal = true
      if (this.isAdminOrPopsUser) {
        // if adminorpopsuser, set bidmodalmode to create mode and set modaldata to default values
        this.bidModalMode = CUSTOM_MAIL_CONSTS.BID_MODAL_CREATE_MODE
        this.modalData = CUSTOM_MAIL_CONSTS.BID_MODAL_DEFAULT_POPS_VALUES
        this.selectedPartner = {}
      } else {
        // else set bidmodalmode to edit_mode and default values
        this.bidModalMode = CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_MODE
        this.modalData = CUSTOM_MAIL_CONSTS.BID_MODAL_DEFAULT_VALUES
      }
    },
    openAdminEditModal(partner) {
      this.bidModalMode = CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_POPS_MODE
      this.selectedPartner = partner
      this.openBidModal = true
    },
    closeAdminModal(e) {
      this.openBidModal = false
      if (e.reloadData) {
        // need a small timeout to reload products
        setTimeout(() => this.loadProductBids(), 1000)
      }
    },
    openPartnerEditModal(partner) {
      this.bidModalMode = CUSTOM_MAIL_CONSTS.BID_MODAL_EDIT_MODE
      this.selectedPartner = partner
      this.openBidModal = true
    },
    closePartnerModal(e) {
      this.openBidModal = false
      if (e.reloadData) {
        setTimeout(() => this.loadProductBids(), 500)
      }
    },
    async loadProductBids() {
      this.packageID = this.$route.params.packageID as string
      const packageData = (await getPackage({
        packageID: this.packageID
      })) as PackagePageData

      const productBids = await getProductBids({ packageID: this.packageID })
      const partnerBidsArray = productBids.data

      // sort bids by partner from partnerBidsArray
      const bidsByPartner: { [key: string]: PartnerPackageBids } = {}
      if (Array.isArray(partnerBidsArray)) {
        partnerBidsArray.forEach((bid) => {
          if (!bidsByPartner[bid.partner_id]) {
            const partnerPackage = packageData.partners
              ?.reverse()
              ?.find((pp) => pp.partner_id === bid.partner_id)
            if (partnerPackage) {
              bidsByPartner[bid.partner_id] = {
                partner_id: bid.partner_id,
                status: partnerPackage.status,
                date: partnerPackage.date_created.split('T')[0],
                bidsArray: []
              }
            } else {
              bidsByPartner[bid.partner_id] = {
                partner_id: bid.partner_id,
                status: 'pending',
                date: new Date().toISOString().split('T')[0],
                bidsArray: []
              }
            }
          }
          bidsByPartner[bid.partner_id].bidsArray.push(bid)
        })
        for (const partnerId in bidsByPartner) {
          bidsByPartner[partnerId].bidsArray.sort(
            (a, b) => a.order_quantity - b.order_quantity
          )
        }
        this.bidsByPartner = bidsByPartner
      }
      if (this.updatedPartner) {
        this.partnerProductBids = bidsByPartner[this.updatedPartner]
      } else {
        this.partnerProductBids =
          this.currentPartner && bidsByPartner[this.currentPartner.id]
      }
    },
    async updateStatus(partnerId, packageData) {
      const data = packageData as PartnerPackageBids

      const resp = (await editPartnerPackageStatus({
        partnerID: partnerId,
        packageID: packageData.packageID,
        partners: packageData.partnerID
          ? packageData.partnerID.bidsArray
          : packageData.bidsArray,
        status: packageData.status
      })) as PartnerPackageBids
      data.id = resp.id

      setPartnerPackageBids(data)

      this.updatedPartner = partnerId
      this.updatedStatus = data.status as string
      this.loadProductBids()
      this.configureStatusVariant(this.updatedStatus)
    }
  }
})
</script>

<style lang="scss" scoped></style>
