import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'

export default {
  path: 'attributes',
  component: View,
  meta: {
    displayName: 'Manage Attributes'
  }
} as RouteRecordRaw
