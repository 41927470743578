<template>
  <Modal :visible="visible" width="600px" @close="$emit('reset')">
    <template #header>
      <div class="text-3xl font-thin">Confirm Inventory Update</div>
    </template>

    <form @submit.prevent="onSubmit">
      <div class="px-2 max-w-xl">
        <div class="text-lg mb-2">{{ message }}</div>
        <div>
          <TextInput
            id="inventory-update-note"
            v-model="note"
            label="note"
            placeholder="reason for update"
            size="small"
            :required="true"
            @keydown.enter="onEnterKey"
          />
        </div>
        <div class="flex justify-end pt-3 px-2">
          <LobButton
            variant="secondary"
            class="w-28"
            data-testId="cancel-button"
            @click="$emit('reset')"
          >
            <span>Cancel</span>
          </LobButton>
          <LobButton
            variant="primary"
            class="w-28 ml-2"
            data-testId="validate-button"
            type="submit"
            :disabled="!Boolean(note)"
          >
            Update
          </LobButton>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script lang="ts">
import { ref } from 'vue'
import { UpdateMaterielInventoryPayload } from './useMaterielInventory'

export default {
  name: 'InventoryUpdateModal',
  props: {
    visible: { type: Boolean, default: false },
    updateMaterielInventoryId: { type: String, required: true },
    updateMessage: { type: String, required: true },
    updateProperty: { type: String, required: true },
    updateValue: { type: [String, Number], required: true }
  },
  emits: ['close', 'reset', 'updated'],
  setup(props, { emit }) {
    const message = ref(props.updateMessage)
    const note = ref('')

    const onEnterKey = () => {
      if (note.value) {
        onSubmit()
      }
    }

    const onSubmit = () => {
      emit('updated', {
        editProperty: props.updateProperty,
        editMaterielInventoryId: props.updateMaterielInventoryId,
        note: note.value,
        value: props.updateValue
      } as UpdateMaterielInventoryPayload)

      emit('close')
    }

    return { message, note, onEnterKey, onSubmit }
  }
}
</script>
