<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { formatDate, formatNumber } from '@/utils/formatters'
import { Card } from './types'
import Preview from './Preview.vue'
import LogsIcon from './LogsIcon.vue'

export default defineComponent({
  name: 'CardRow',
  components: { Preview, LogsIcon },
  props: {
    isExpanded: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object as PropType<Card>,
      required: true
    }
  },
  emits: ['expand'],
  setup() {
    return {
      cellClasses: 'border-0 py-3 align-middle',
      formatDate,
      formatNumber
    }
  }
})
</script>

<template>
  <tr
    :data-testid="`row-${item.id}`"
    :class="['table-row', 'relative', 'font-light', 'text-sm', 'border-bottom']"
  >
    <td :class="cellClasses + ' gap-4 flex'">
      <div class="rounded-full bg-primary-300 w-6 h-6">
        <ChevronRight
          data-testid="expand-row"
          aria-label="Expand row for more details"
          :class="[
            'cursor-pointer p-1 text-white w-6 h-6',
            isExpanded && 'is-rotated'
          ]"
          @click="$emit('expand', item.id)"
        />
      </div>
      <Preview :thumbnails="item.thumbnails" />
    </td>
    <td :class="cellClasses">
      {{ item.id }}
      <LogsIcon :query="{ materiel_id: item.id }" />
    </td>
    <td :class="cellClasses">
      {{ item.size }}
    </td>
    <td :class="cellClasses">
      {{ item.description }}
    </td>
    <td :class="cellClasses">
      {{ item.auto_reorder ? 'On' : 'Off' }}
    </td>
    <td :class="cellClasses">
      {{ item.mode }}
    </td>

    <td :class="cellClasses">
      {{
        formatDate(item.date_created, {
          timeZone: 'America/Los_Angeles',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })
      }}
    </td>
  </tr>
</template>

<style>
/* Tailwind default transform class always overrided the new values from .rotate-90  */
.is-rotated {
  transform: rotate(90deg);
}
</style>
