<template title="Login">
  <H2>Sign In</H2>
  <br />
  <form class="spaced">
    <TextInput
      id="email"
      v-model="email"
      label="Email"
      placeholder="john@doe.com"
    />
    <TextInput
      id="password"
      v-model="password"
      label="Password"
      placeholder="Password"
      type="password"
    />
    <Alert v-if="errorMessage" variant="error">
      {{ errorMessage }}
    </Alert>
    <br />
    <LobButton
      data-testid="submitBtn"
      :disabled="pending"
      @click.prevent="login"
    >
      {{ pending ? 'Submitting...' : 'Submit' }}
    </LobButton>
  </form>
  <br />
  <router-link to="/send_password_reset" class="underline">
    Forgot password?
  </router-link>
</template>

<script lang="ts">
import router from '@/router'
import * as session from '@/store/session'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Login',
  metaInfo: {
    title: 'Login'
  },
  props: {
    // Where to redirect the user to when they have successfully logged in.
    continue: {
      type: String,
      required: false,
      default: '/'
    }
  },
  data() {
    return {
      // Are we waiting for a response?
      pending: false,
      email: null as string | null,
      password: null as string | null,
      errorMessage: null as string | null
    }
  },
  methods: {
    async login() {
      if (this.email === null) {
        this.errorMessage = 'Email is required'
        return
      }
      if (this.password === null) {
        this.errorMessage = 'Password is required'
        return
      }
      // We are about to wait for the server.
      this.pending = true
      try {
        // Try to log in.
        await session.login({
          email: this.email,
          password: this.password
        })
        // Go to home page.
        router.push(this.continue)
      } catch (e) {
        // Format an error message if unsuccessful.
        const eResp = e.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
      // We are done waiting for the server.
      this.pending = false
    }
  }
})
</script>
