<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h4>{{ modalTitle }}</h4>
      <Tooltip position="bottom">
        <template #trigger>
          <Info class="ml-1 w-5 h-5" />
        </template>
        <template #content>
          <div class="w-32">
            {{ componentModalEditTooltip }}
          </div>
        </template>
      </Tooltip>
    </div>
    <PackageTextInput
      id="component-title"
      v-model:value="title"
      label="Title"
      placeholder="Title"
      :input-width="'w-1/2'"
      required
    />
    <PackageTextInput
      id="component-description"
      v-model:value="description"
      label="Description"
      placeholder="Short Description"
      :input-width="'w-1/2'"
      required
    />
    <PackageDropdown
      id="component-inventory-type"
      v-model:value="inventoryType"
      :options="inventoryTypes"
      label="Select an Inventory Type"
      placeholder="Select an Inventory Type"
      class="w-1/2"
      required
    />
    <PackageDropdown
      id="component-template-layouts"
      v-model:value="chosenLayoutOption"
      :options="layoutOptions"
      label="Select a Template ID"
      placeholder="Select a Template ID"
      class="w-1/2"
    />
    <PackageTextArea
      id="package-instructions"
      v-model:value="instructions"
      label="Instructions"
      placeholder="Type Instructions..."
    />
    <div v-if="filePreviouslyChosen">
      File Already Uploaded -
      <i
        class="cursor-pointer underline italic text-grayShadow"
        @click="reuploadFile"
      >
        Upload Again?
      </i>
    </div>
    <CustomMailFilePicker
      v-else
      class="mt-2"
      @updateChosenFile="updateChosenFile"
    />
    <div class="flex items-end -mb-10 mt-3">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton :disabled="createDisabled" @click="saveComponent">
        {{ modalButtonAction }}
      </LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import router from '@/router'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageTextInput from './PackageTextInput.vue'
import PackageTextArea from './PackageTextArea.vue'
import PackageDropdown from './PackageDropdown.vue'
import CustomMailFilePicker from './CustomMailFilePicker.vue'
import { updateComponent, getTemplateLayouts } from '@/store/customMail'

export default defineComponent({
  name: 'EditComponentModal',
  components: {
    PackageTextInput,
    PackageTextArea,
    PackageDropdown,
    CustomMailFilePicker
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['close', 'saved'],
  data() {
    return {
      title: this.data?.title ?? '',
      description: this.data?.description ?? '',
      instructions: this.data?.instructions ?? '',
      componentID: this.data?.id ?? '',
      filePreviouslyChosen: this.data?.file_url ?? false,
      modalTitle: '',
      modalButtonAction: '',
      chosenFile: null,
      layoutOptions: [],
      chosenLayoutOption: this.formatChosenLayout(this.data),
      fileIsChosen: false,
      inventoryType: this.formatInventoryType(this.data),
      inventoryTypes: CUSTOM_MAIL_CONSTS.COMPONENT_INVENTORY_TYPE_VALUES,
      componentModalEditTooltip: CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_EDIT_TOOLTIP
    }
  },
  computed: {
    createDisabled() {
      return (
        this.inventoryType.label.length === 0 ||
        this.title.length === 0 ||
        this.description.length === 0
      )
    }
  },
  watch: {
    data: async function (newVal) {
      this.componentID = newVal.id ?? ''
      this.title = newVal.title
      this.description = newVal.description
      this.fileIsChosen = newVal.file_url ?? true
      this.filePreviouslyChosen = newVal.file_url ?? false
      this.instructions = newVal.instructions ?? ''
      this.inventoryType = this.formatInventoryType(newVal)
      this.chosenLayoutOption = await this.formatChosenLayout(newVal)
    },
    mode: function (newVal) {
      this.configureMode(newVal)
    }
  },
  mounted() {
    this.configureMode(this.mode as string)
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    configureMode(mode: string) {
      if (mode === CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_CREATE_MODE) {
        this.modalTitle = CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_CREATE_TITLE
        this.modalButtonAction =
          CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_CREATE_BUTTON
      } else {
        this.modalTitle = CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_EDIT_TITLE
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_EDIT_BUTTON
      }
    },
    async saveComponent() {
      const data = {
        title: this.title,
        description: this.description,
        instructions: this.instructions,
        inventoryType: this.inventoryType.value,
        packageID: this.$route.params.packageID,
        id: this.componentID,
        templateID: this.chosenLayoutOption.value
      } as any
      if (this.chosenFile) {
        data.file = this.chosenFile
      }
      await updateComponent(data)
      this.$emit('close', {
        refreshComponent: true,
        refreshPreviewURL: this.chosenFile ?? false
      })
    },
    updateChosenFile(file) {
      this.chosenFile = file
    },
    reuploadFile() {
      this.filePreviouslyChosen = false
    },
    formatInventoryType(data) {
      const packageID = this.$route.params.packageID as string
      if (data.products && data.products.length > 0) {
        const inventoryTypes =
          CUSTOM_MAIL_CONSTS.COMPONENT_INVENTORY_TYPE_VALUES
        const existingInventoryType = data.products.find(
          (el) => el.product_id === packageID
        )
        const inventoryType = existingInventoryType
          ? existingInventoryType.inventory_type
          : null
        return inventoryType
          ? inventoryTypes.find((el) => el.value === inventoryType)
          : ({ label: '', value: '' } as any)
      } else {
        return { label: '', value: '' }
      }
    },
    async formatChosenLayout(data) {
      const layouts = await getTemplateLayouts()
      this.layoutOptions = layouts.data.map((layout) => {
        return {
          label: `${layout.component_id} (${layout.description})`,
          value: layout.component_id
        }
      })
      if (data.template_id && this.layoutOptions.length > 0) {
        const layout = this.layoutOptions.find(
          (el: any) => el.value === data.template_id
        ) as any
        // only return if the template ID still exists
        if (layout) return layout
      }
      return { label: '', value: '' }
    }
  }
})
</script>
