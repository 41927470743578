<template>
  <div>
    <router-link to="partner_user_invites/invite_partner_user" />
  </div>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PartnerUserInvites'
})
</script>
