<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTimeFormatOptions, formatDate } from '@/utils/formatters'

export default defineComponent({
  name: 'DateTime',
  props: {
    date: {
      type: String,
      required: true
    },
    dateStyle: {
      type: String as PropType<DateTimeFormatOptions['dateStyle']>,
      default: 'full'
    },
    timeStyle: {
      type: String as PropType<DateTimeFormatOptions['timeStyle']>,
      default: 'long'
    }
  },
  setup(props) {
    return {
      dateString: formatDate(props.date, {
        dateStyle: props.dateStyle,
        timeStyle: props.timeStyle
      })
    }
  }
})
</script>

<template>
  {{ dateString }}
</template>
