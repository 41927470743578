import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-mist grid grid-cols-7 justify-items-center justify-items-center shadow-md shadow-black pl-2 pr-2 ml-1" }
const _hoisted_2 = { class: "w-100 p-3" }
const _hoisted_3 = { class: "w-100 p-3" }
const _hoisted_4 = { class: "w-100 p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.bid.order_quantity), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.bid.total_us_dollars > 0 ? _ctx.bid.total_us_dollars : ''), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.bid.lead_time_days ?? ''), 1)
  ]))
}