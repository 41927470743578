import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85516bec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "dim"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  key: 3,
  class: "dim"
}
const _hoisted_5 = { key: 4 }
const _hoisted_6 = {
  key: 5,
  class: "dim"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_P = _resolveComponent("P")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.value.name)
      ? (_openBlock(), _createBlock(_component_P, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.value.name), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_P, {
          key: 1,
          class: "dim"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Empty Name")
          ]),
          _: 1
        })),
    (_ctx.value.address_line1)
      ? (_openBlock(), _createBlock(_component_P, { key: 2 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.value.address_line1), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_P, {
          key: 3,
          class: "dim"
        }, {
          default: _withCtx(() => [
            _createTextVNode("0000 Empty Street")
          ]),
          _: 1
        })),
    (_ctx.value.address_line2)
      ? (_openBlock(), _createBlock(_component_P, { key: 4 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.value.address_line2), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_P, null, {
      default: _withCtx(() => [
        (_ctx.value.address_city)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.value.address_city), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, "Nowheresville")),
        _createTextVNode("   "),
        (_ctx.value.address_state)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.value.address_state), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, "ST")),
        _createTextVNode("   "),
        (_ctx.value.address_zip)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.value.address_zip), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, "0000"))
      ]),
      _: 1
    }),
    _createVNode(_component_P, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.value.address_country), 1)
      ]),
      _: 1
    })
  ], 64))
}