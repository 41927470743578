<template>
  <ul>
    <li v-for="url of modelValue" :key="url">
      <a :href="url">{{ filenameOf(url) }}</a>
    </li>
  </ul>
  <LobFileUpload
    :do-try-upload="tryUploadFile"
    @uploadSuccess="onUploadSuccess($event)"
  />
</template>

<script lang="ts">
import { uploadArtwork, UploadedArtwork } from '@/store/testSets'
import { defineComponent } from 'vue'
import LobFileUpload from '../global/LobFileUpload.vue'

export default defineComponent({
  components: { LobFileUpload },
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    async tryUploadFile(file: File) {
      return await uploadArtwork({ file })
    },
    onUploadSuccess(uploaded: UploadedArtwork) {
      this.$emit('update:modelValue', [...this.modelValue, uploaded.url])
    },
    filenameOf(url: string) {
      return decodeURI(url.split('/').pop()?.split('?')[0] || '')
    }
  }
})
</script>
