<template>
  <div id="root">
    <div id="container">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style scoped lang="scss">
#root {
  width: 100%;
  padding: 1rem;
  background: rgba(#000, 0.01);
  border: 1px solid rgba(#000, 0.1);
  border-radius: 0.5rem;
}

#container {
  margin: auto;
  width: max-content;
}
</style>
