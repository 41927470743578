<template>
  <div class="flex">
    <table class="w-1/12 row-space">
      <thead>
        <tr>
          <th class="border-t-0 text-transparent font-light text-base p-3">
            {{ '\u2028' }}
            <!-- used to evenly space table rows -->
          </th>
        </tr>
      </thead>
      <tbody v-if="data.length" class="table-body">
        <tr
          v-for="(el, index) in data"
          :key="`${el.id}-${index}`"
          class="py-4 px-3 table-row mt-20 w-100 pb-10"
        >
          <td class="p-3">{{ index }}</td>
        </tr>
      </tbody>
    </table>
    <table class="w-11/12 row-space">
      <thead>
        <tr>
          <th
            v-for="key in tableHeaders"
            :key="key.name"
            class="border-t-0 text-gray-900 font-light text-base p-3"
            :colspan="key.span"
          >
            {{ key.name }}
          </th>
        </tr>
      </thead>
      <tbody v-if="data.length" class="table-body">
        <ComponentsTableRow
          v-for="(item, index) in data"
          :key="item.id"
          class="m-10"
          :is-base-package="index === 0"
          :item="item"
          :is-admin-or-pops-user="isAdminOrPopsUser"
          @openComponentDeleteModal="$emit('openComponentDeleteModal', $event)"
        />
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ComponentsTableRow from './ComponentsTableRow.vue'

export default defineComponent({
  name: 'ComponentsTable',
  components: {
    ComponentsTableRow
  },
  props: {
    isAdminOrPopsUser: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  emits: ['resortTableData', 'openComponentDeleteModal'],
  data() {
    return {
      tableHeaders: [
        { name: 'Title', span: 1 },
        { name: 'Description', span: 1 },
        { name: 'File(PDF)', span: 1 },
        { name: '', span: 1 }
      ]
    }
  },
  methods: {
    // commenting out until resorting post MVP
    // resortTableData(itemToSort){
    //   this.$emit('resortTableData', itemToSort)
    // }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
