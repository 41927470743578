<template>
  <TextInput
    id="name"
    label="ID (Leave blank for no card)"
    :model-value="textValue"
    @update:modelValue="updateValue($event)"
  />
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    modelValue: {
      type: Array,
      required: false,
      default: () => null
    } as Prop<Array<string> | null>
  },
  emits: ['update:modelValue'],
  computed: {
    textValue(): string {
      if (this.modelValue?.length) {
        return this.modelValue[0]
      } else {
        return ''
      }
    }
  },
  methods: {
    updateValue(newText: string) {
      if (newText.trim() === '') {
        this.$emit('update:modelValue', null)
      } else {
        this.$emit('update:modelValue', [newText])
      }
    }
  }
})
</script>
