import { sendPapiRequest } from '@/store/requests'
// import { createSendPapiRequest } from '@/utils/papiRequest'
import { AxiosError } from 'axios'
import { Ref, ref } from 'vue'
import { BuckslipOrder, CardOrder, EnvelopeOrder, OrderType } from './types'

export interface UseOrderResponse<T> {
  orders: Ref<T[]>
  loading: Ref<boolean>
  error: Ref<AxiosError | null | string>
  loadOrders: () => Promise<void>
}

export function useOrders<T extends CardOrder | EnvelopeOrder | BuckslipOrder>(
  id: string,
  type: OrderType
): UseOrderResponse<T> {
  const loading = ref(false)
  const error = ref<string>('')
  const orders = ref<T[]>([]) as Ref<T[]>

  const loadOrders = async () => {
    loading.value = true
    try {
      const data = await sendPapiRequest<{ data: T[] }>({
        path: `/inventory/${type}/${id}/orders`,
        method: 'GET'
      })

      orders.value = data.data.data
    } catch (e) {
      const eResp = (e as AxiosError).response
      error.value = `${eResp?.status} : ${eResp?.data?.error?.message}`
    } finally {
      loading.value = false
    }
  }

  return { orders, loadOrders, loading, error }
}
