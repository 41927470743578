import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "py-3 px-12 h-12 flex items-center align-middle w-full text-gray-500 text-sm cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_MainNavigationItem = _resolveComponent("MainNavigationItem")!
  const _component_MainNavigation = _resolveComponent("MainNavigation")!

  return (_openBlock(), _createBlock(_component_MainNavigation, {
    id: "checklist-root",
    "list-class": "overflow-y-scroll h-screen"
  }, {
    default: _withCtx(({ expanded }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (sublist) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: sublist.title
        }, [
          (sublist.title === 'General Onboarding')
            ? (_openBlock(), _createBlock(_component_MainNavigationItem, {
                key: 0,
                class: "mt-4",
                expanded: expanded,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToGitbook('getting-started/new-partner-onboarding')))
              }, {
                default: _withCtx(() => [
                  (sublist.title)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createTextVNode(_toDisplayString(sublist.title) + "   ", 1),
                        _createVNode(_component_fa, {
                          class: "opacity-40",
                          icon: "link"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["expanded"]))
            : (_openBlock(), _createBlock(_component_MainNavigationItem, {
                key: 1,
                class: "mt-4",
                title: sublist.title,
                expanded: expanded
              }, null, 8, ["title", "expanded"])),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublist.items.filter((item) => !item.hidden), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.href
            }, [
              (_ctx.showGitbook && item.text === 'Order Transmission Integration')
                ? (_openBlock(), _createBlock(_component_MainNavigationItem, {
                    key: 0,
                    title: item.text,
                    "icon-alt-text": "Check icon",
                    "icon-src": 
              item.complete
                ? require('@/assets/images/icon-checkmark.svg')
                : require('@/assets/images/icon-circle.svg')
            ,
                    class: "checklist-item",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (
              _ctx.redirectToGitbook('integration/order-transmission-integration')
            ))
                  }, null, 8, ["title", "icon-src"]))
                : (_openBlock(), _createBlock(_component_MainNavigationItem, {
                    key: 1,
                    title: item.text,
                    "icon-alt-text": "Check icon",
                    "icon-src": 
              item.complete
                ? require('@/assets/images/icon-checkmark.svg')
                : require('@/assets/images/icon-circle.svg')
            ,
                    to: item.href,
                    class: "checklist-item"
                  }, null, 8, ["title", "icon-src", "to"]))
            ]))
          }), 128))
        ], 64))
      }), 128))
    ]),
    _: 1
  }))
}