<template>
  <LoadingIndicator>
    <div v-if="!isLoading" class="p-5">
      <TestingHeader />
      <Card class="min-w-min w-100">
        <div class="flex flex-row text-xl">
          <div class="flex-1" />
          <LobButton size="small" class="text-sm" @click="openTestGroupModal">
            New Test Group
          </LobButton>
        </div>
        <LoadingIndicator>
          <Table
            v-if="!tableLoading"
            :headers="tableHeaders"
            :row-object="tableRowObject"
            :data="tableData"
            :row-expandable="true"
            :pagination="true"
            :data-length="tableLength"
            :current-page="page"
            @updatePage="updatePage"
            @updateSortedHeaders="updateSortedHeaders"
            @updateTableData="updateTableData"
          >
            <template #expandedContent="expandedProps">
              <div class="p-3">
                <Table
                  variant="secondary"
                  :headers="innerTableHeaders"
                  :row-object="innerTableRowObjects"
                  :data="tableData[expandedProps.index].innerTableData"
                  secondary-row-color="bg-offWhite"
                  @updateSortedHeaders="updateSortedHeaders"
                  @updateTableData="updateTableData"
                />
              </div>
            </template>
          </Table>
        </LoadingIndicator>
      </Card>
      <TestGroupModal :is-open="openNewTestGroup" @close="closeNewTestGroup" />
    </div>
  </LoadingIndicator>
</template>

<script lang="ts">
import {
  getTestGroups,
  PaginationLinks,
  TestGroupList,
  loadMoreTestResourcesByUrl,
  dateFormatter
} from '@/store/customMail'
import { formatDate } from '@/utils/formatters'
import { defineComponent } from '@vue/runtime-core'
import Table from '../../../../components/common/table/Table.vue'
import TestGroupModal from './Modals/TestGroupModal.vue'
import TestingHeader from './TestingHeader.vue'

export default defineComponent({
  name: 'TestGroups',
  components: {
    Table,
    TestingHeader,
    TestGroupModal
  },
  data() {
    return {
      isLoading: false,
      tableLoading: false,
      openNewTestGroup: false,
      tableHeaders: [
        {
          name: 'ID',
          span: 1,
          sortable: true,
          sortStatus: 'none',
          width: 'w-2/12'
        },
        {
          name: 'Name',
          span: 1,
          sortable: true,
          sortStatus: 'none',
          width: 'w-2/12'
        },
        { name: 'Description', span: 1, width: 'w-3/12' },
        {
          name: 'Date Created',
          id: 'Date Created',
          span: 1,
          sortable: false,
          sortStatus: 'none',
          width: 'w-2/12'
        },
        {
          name: 'Date Modified',
          id: 'Date Modified',
          span: 1,
          sortable: false,
          sortStatus: 'none',
          width: 'w-2/12'
        },
        { name: '', span: 1, width: 'w-1/12' }
      ],
      tableRowObject: [
        {
          fieldName: 'id',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'name',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'description',
          type: 'string',
          width: 'w-3/12'
        },
        {
          fieldName: 'date_created',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'date_modified',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: '',
          type: 'button',
          buttonText: 'Edit',
          icon: 'edit',
          variant: 'secondary',
          width: 'w-1/12'
        }
      ],
      innerTableHeaders: [
        { name: 'Test Case ID', span: 1, width: 'w-2/12' },
        { name: 'Test Case Name', span: 1, width: 'w-2/12' },
        { name: 'Test Case Description', span: 1, width: 'w-8/12' }
      ],
      innerTableRowObjects: [
        {
          fieldName: 'id',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'name',
          type: 'string',
          width: 'w-2/12'
        },
        {
          fieldName: 'description',
          type: 'string',
          width: 'w-2/12'
        }
      ],
      tableData: [] as any[],
      tableLength: 0,
      links: {} as PaginationLinks,
      sortBy: 'id',
      orderBy: 'DESC',
      page: 1
    }
  },
  async mounted() {
    await this.loadTestGroups()
  },
  methods: {
    async loadTestGroups(url?: string) {
      this.tableLoading = true
      let testGroups
      if (url) {
        const strippedURL = url.substring(url.indexOf('/partner_testing'))
        testGroups = (await loadMoreTestResourcesByUrl({
          url: strippedURL
        })) as TestGroupList
      } else {
        testGroups = (await getTestGroups({})) as TestGroupList
      }
      this.tableLength = testGroups.total_count
      this.links = testGroups.links
      this.tableData = testGroups.data.map((testGroup) => {
        return {
          ...testGroup,
          expanded: false,
          date_created: formatDate(
            testGroup.date_created as string,
            dateFormatter
          ),
          date_modified: formatDate(
            testGroup.date_modified as string,
            dateFormatter
          ),
          innerTableData: testGroup.test_cases.map((tc) => {
            return {
              id: tc.id,
              name: tc.name,
              description: tc.description
            }
          })
        }
      })
      this.tableLoading = false
    },
    async updatePage(newPage) {
      const pageDelta = newPage - this.page
      if (pageDelta === 1) {
        this.loadTestGroups(this.links.next)
      } else if (pageDelta === -1) {
        this.loadTestGroups(this.links.previous)
      } else if (pageDelta > 1) {
        this.loadTestGroups(this.links.last)
      } else if (pageDelta < -1) {
        this.loadTestGroups(this.links.first)
      }
      this.page = newPage
    },
    updateSortedHeaders(headers) {
      this.tableHeaders = headers
    },
    updateTableData(data) {
      this.tableData = data.rows
    },
    async closeNewTestGroup(options) {
      this.openNewTestGroup = false
      if (options && options.refreshData) await this.loadTestGroups()
    },
    openTestGroupModal() {
      this.openNewTestGroup = true
    }
  }
})
</script>

<style lang="scss" scoped></style>
