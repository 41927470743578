import { RouteRecordRaw } from 'vue-router'
import endToEndTestingScheduler from '../endToEndTesting/scheduler'
import endToEndTestingSubmitReport from '../endToEndTesting/submit-report'
import View from './progress-view/View.vue'
import { ShowChildRoutes } from '@/components/global'

export default {
  path: 'end-to-end-testing',
  component: ShowChildRoutes,
  children: [
    {
      path: '',
      component: View
    },
    endToEndTestingScheduler,
    endToEndTestingSubmitReport
  ]
} as RouteRecordRaw
