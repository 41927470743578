<template>
  <div class="flex">
    <div class="text-2xl mb-10">Component Information</div>
    <Tooltip position="right">
      <template #trigger>
        <Info class="ml-1 w-5 h-5" />
      </template>
      <template #content>
        {{ componentInformationTooltip }}
      </template>
    </Tooltip>
    <div class="flex-1" />
    <Edit
      v-if="isAdminOrPopsUser"
      class="ml-1 opacity-40 w-6 h-6 cursor-pointer"
      @click="openEditComponentModal"
    />
  </div>
  <InfoPanel :data="infoData" />
  <LoadingIndicator>
    <!-- this is needed since ref to previewContainer needs to exist and v-if would
      make it not exist when trying to load-->
    <div v-if="!isPreviewRendering" />
  </LoadingIndicator>
  <div
    ref="previewContainer"
    class="flex text-center justify-center align-middle"
  />
  <div v-if="previewUrl" class="w-full flex items-center justify-center mt-3">
    <PackageButton @click="downloadPDF">Preview</PackageButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import InfoPanel from '../common/InfoPanel.vue'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageButton from '../common/PackageButton.vue'

export default defineComponent({
  name: 'ComponentInformation',
  components: {
    InfoPanel,
    PackageButton
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isAdminOrPopsUser: { type: Boolean },
    previewUrl: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  emits: ['openEditComponentModal'],
  data() {
    return {
      headerTitle: '',
      infoData: CUSTOM_MAIL_CONSTS.COMPONENT_PAGE_INFO_PANEL,
      isPreviewRendering: false,
      renderingError: false,
      componentInformationTooltip:
        CUSTOM_MAIL_CONSTS.COMPONENT_INFORMATION_TOOLTIP,
      previousImgLoaded: false,
      imageID: 'pdf-container'
    }
  },
  watch: {
    data: function (newVal) {
      // update text information
      for (const key in this.infoData) {
        if (key === 'inventory_type') {
          this.formatInventoryType(newVal, key)
        } else {
          this.infoData[key].value = newVal[key] ?? 'No Value'
        }
      }
    }
  },
  mounted() {
    const infoData = this.data as any
    for (const key in this.infoData) {
      if (key === 'inventory_type') {
        this.formatInventoryType(infoData, key)
      } else {
        this.infoData[key].value = infoData[key] ?? 'No Value'
      }
    }
  },
  methods: {
    formatInventoryType(data, key) {
      const inventoryValues = CUSTOM_MAIL_CONSTS.COMPONENT_INVENTORY_TYPE_VALUES
      const packageID = this.$route.params.packageID as string
      if (data.products.length > 0) {
        const existingInventoryType = data.products.find(
          (el) => el.product_id === packageID
        )
        this.infoData[key].value = existingInventoryType
          ? inventoryValues.find(
              (el) => el.value === existingInventoryType.inventory_type
            )?.label
          : 'No Value'
      } else {
        this.infoData[key].value = 'No Value'
      }
    },
    openEditComponentModal() {
      this.$emit('openEditComponentModal')
    },
    downloadPDF() {
      window.open(this.previewUrl!, '_blank')
    }
  }
})
</script>

<style lang="scss" scoped></style>
