<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="field-header-root">
    <div style="grid-area: label">
      <div class="title text-sm">
        {{ field.name }}
      </div>
      <FormFactorNote
        v-if="field.id !== 'form_factor'"
        :schema="schema"
        :form-factors="field.form_factors"
      />
    </div>
    <button
      style="grid-area: add"
      title="Add Value"
      :class="{ addButton: true, hidden: !showAddButton }"
      @click="$emit('add', $event)"
    >
      <fa icon="plus" />
    </button>
  </div>
</template>

<script lang="ts">
import { TestSet } from '@/store/testSets'
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'
import FormFactorNote from '../FormFactorNote.vue'

export default defineComponent({
  components: { FormFactorNote },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    showAddButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['add', 'update:testSet']
})
</script>

<style lang="scss" scoped>
.field-header-root {
  display: grid;
  margin-bottom: 0.5rem;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'label add';
  align-items: end;
}
.title {
  display: inline;
  margin-top: 0;
  // Bootstrap makes this margin !important on the typography.
  margin-bottom: 0 !important;
}
.addButton {
  padding: 0.25rem;
}
</style>
