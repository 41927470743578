<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// This is a component which simply displays a child route without any
// additional elements added to it. This is useful for organizing a group of
// routes which make sense to be organized together without needing any shared UI
// elements added to them.
export default defineComponent({
  name: 'ShowChildRoutes'
})
</script>
