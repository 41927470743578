import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { Plugin as VuePlugin } from 'vue'
import router from '../router'
import config from '../../config'
import { Transport } from '@sentry/types'

interface SentryPluginOptions {
  enabled: boolean
  dsn: string
  tracesSampleRate: number
  environment: string
  release: string
  transport: ((transportOptions: any) => Transport) | undefined
}

const sentryPlugin: VuePlugin = {
  install(
    app,
    {
      enabled,
      dsn,
      tracesSampleRate,
      environment,
      release,
      transport
    }: SentryPluginOptions
  ) {
    if (enabled) {
      Sentry.init({
        app,
        dsn,
        environment,
        release,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
          })
        ],
        tracesSampleRate: tracesSampleRate ?? 0.2,
        transport
      })
    }
  }
}

export function captureException(error: Error): void {
  if (!config.SENTRY_LOGGING_DISABLED) {
    Sentry.captureException(error)
  }
}

export default sentryPlugin
