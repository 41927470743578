import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_H2 = _resolveComponent("H2")!
  const _component_P = _resolveComponent("P")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_H2, null, {
      default: _withCtx(() => [
        _createTextVNode("Oops, something went wrong here!")
      ]),
      _: 1
    }),
    _createVNode(_component_P, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
      ]),
      _: 1
    })
  ], 64))
}