import { captureException } from '@/plugins/sentry'
import { getCurrentPartnerId } from '@/store/partners'
import { sendPapiRequest } from '@/store/requests'
import { Ref, ref } from 'vue'
import { useRouter } from 'vue-router'

type StringBool = 'true' | 'false' | ''
export type ReportValues = {
  downloadTime: string
  bccTime: string
  gmcTime: string
  filesReceived: StringBool
  filesIngested: StringBool
  correctDirectory: StringBool
}
export type UseSendReportType = {
  isSubmitting: Ref<boolean>
  error: Ref<string>
  handleReportSubmit: (id: string, values: ReportValues) => Promise<void>
}

export function useSendReport(): UseSendReportType {
  const router = useRouter()
  const error = ref<string>('')
  const isSubmitting = ref<boolean>(false)

  const handleReportSubmit = async (
    testId: string,
    formValues: ReportValues
  ) => {
    isSubmitting.value = true
    try {
      const partnerId = getCurrentPartnerId()

      await sendPapiRequest({
        path: `/partners/${partnerId}/results/e2e`,
        method: 'POST',
        data: {
          test_id: testId,
          download_art_time: formValues.downloadTime,
          bcc_time: formValues.bccTime,
          impose_time: formValues.gmcTime,
          files_received: formValues.filesReceived === 'true',
          files_ingested: formValues.filesIngested === 'true',
          files_correct_dir: formValues.correctDirectory === 'true'
        }
      })
      router.push({
        path: `/partners/${partnerId}/onboarding/end-to-end-testing`
      })
    } catch (err) {
      captureException(err as Error)
      error.value = 'Failed to submit test results'
    } finally {
      isSubmitting.value = false
    }
  }

  return { error, isSubmitting, handleReportSubmit }
}
