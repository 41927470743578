<template>
  <button :class="variantClass" @click="onClick">
    <slot>Button</slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PackageButton',
  props: {
    onClick: {
      type: Function,
      required: true
    },
    variant: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  computed: {
    variantClass() {
      switch (this.variant) {
        case 'solid':
          return 'border-2 border-grayDove text-sm rounded-md py-2 px-3 text-grayShadow bg-grayDove'
        default:
          return 'border-2 border-grayDove text-sm rounded-md py-2 px-3 text-grayShadow'
      }
    }
  }
})
</script>
