<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'MetadataCell',
  props: {
    data: {
      type: Object as PropType<
        Record<string, unknown> | string | boolean | number
      >,
      required: true
    }
  },
  setup() {
    return {
      itemClass: [
        'rounded-r',
        'bg-gray-100',
        'text-gray-500',
        'px-1',
        'text-xs',
        'uppercase',
        'font-bold',
        'break-all'
      ]
    }
  }
})
</script>

<template>
  <ul class="flex flex-wrap">
    <li v-if="typeof data !== 'object' && typeof data !== 'undefined'">
      <span class="rounded-l bg-gray-300 text-white text-xs px-1 uppercase">
        Value
      </span>
      <span :class="itemClass">
        {{ data }}
      </span>
    </li>
    <li v-for="(item, index) in data" v-else :key="index" class="mr-1">
      <span
        class="rounded-l bg-gray-300 text-white text-xs px-1 uppercase break-all"
      >
        {{ index }}
      </span>
      <span :class="itemClass">
        {{ JSON.stringify(item) }}
      </span>
    </li>
  </ul>
</template>
