<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="cancel" />
    </div>
    <h2 class="text-center">Mark As Ready?</h2>
    <div class="w-100 text-center mb-3">
      This order has {{ data.quantity_ordered }} items to be associated with the
      following printers.
    </div>
    <div id="orderTableContainer">
      <div id="header" class="flex border-b-2 border-grayDove">
        <div class="w-4/12">Print Partner</div>
        <div class="w-3/12">Quantity</div>
        <div class="w-4/12">Total Cost</div>
      </div>
      <div v-for="info in orderInfo" id="row" :key="info.id" class="flex mt-2">
        <div class="w-4/12">
          <Checkbox
            v-model="info.partnerSelected"
            :label="info.partnerName"
            name="partnerSelected"
          />
        </div>
        <div class="w-3/12">
          {{ info.quantity }}
        </div>
        <div class="w-4/12">
          <TextInput
            id="name"
            v-model="info.totalCost"
            label=""
            size="small"
            placeholder="Enter a quantity"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-2">
      <LobButton class="mr-3" variant="secondary" @click="cancel">
        Cancel
      </LobButton>
      <!-- confirm === cancel until endpoints are made -->
      <LobButton :disabled="confirmDisabled" @click="cancel">Confirm</LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MarkAsReadyModal',
  props: {
    isOpen: { type: Boolean, required: true },
    data: { type: Object, required: true }
  },
  emits: ['close'],
  data() {
    return {
      orderInfo: [
        {
          partnerSelected: true,
          quantity: 0,
          totalCost: 0,
          partnerID: '',
          partnerName: ''
        }
      ]
    }
  },
  computed: {
    confirmDisabled(): boolean {
      const itemsNotComplete = false
      return itemsNotComplete
    }
  },
  watch: {
    data: function (newVal) {
      this.formatOrderInfo(newVal)
    }
  },
  mounted() {
    this.formatOrderInfo(this.data)
  },
  methods: {
    formatOrderInfo(order) {
      this.orderInfo = order.materiel_inventories.map((mi) => {
        return {
          partnerSelected: true,
          quantity: mi.initial_count,
          totalCost: mi.total_cost,
          partnerID: mi.partner_id,
          partnerName: mi.partner_name
        }
      })
    },
    cancel() {
      this.$emit('close')
    }
  }
})
</script>
