import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col mb-2" }
const _hoisted_2 = {
  key: 0,
  class: "text-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Dropdown, {
      id: _ctx.id,
      "model-value": _ctx.value,
      options: _ctx.options,
      label: "",
      placeholder: _ctx.placeholder,
      onInput: _ctx.handleInput
    }, null, 8, ["id", "model-value", "options", "placeholder", "onInput"])
  ]))
}