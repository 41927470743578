<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { EnvelopeOrder } from './types'
import { formatNumber, formatDate } from '@/utils/formatters'
import { ExtendOrderExpirationPayload } from './useMaterielInventory'
import ConfirmationModal from '@/components/global/ConfirmationModal.vue'

export default defineComponent({
  name: 'CardStatement',
  components: { ConfirmationModal },
  props: {
    order: {
      type: Object as PropType<EnvelopeOrder>,
      required: true
    }
  },
  emits: ['extend'],
  setup(props, { emit }) {
    const isPending = computed(() => props.order.status === 'pending')
    const canExtendExpiration = computed(
      () =>
        props.order.materiel_inventories.length &&
        !props.order.materiel_inventories[0].expiration_updated
    )
    const confirmOpen = ref(false)

    const onClickExtend = () => {
      confirmOpen.value = true
    }
    const onClose = () => {
      confirmOpen.value = false
    }
    const onExtend = () => {
      emit('extend', {
        materielOrderId: props.order.id
      } as ExtendOrderExpirationPayload)
    }
    return {
      canExtendExpiration,
      confirmOpen,
      formatNumber,
      formatDate,
      isPending,
      onClickExtend,
      onClose,
      onExtend
    }
  }
})
</script>

<template>
  <div
    class="ml-16 xl:ml-24 my-2 bg-white text-gray-700 rounded p-3 lg:flex justify-between text-sm"
  >
    <div class="flex items-center">
      <span class="font-bold mr-1">{{ `Statement ID:` }}</span>
      {{ order.statement_id }}
    </div>
    <div class="flex items-center">
      <span class="font-bold mr-1">Expected availability date:</span>
      {{
        order?.expected_availability_date &&
        formatDate(order.expected_availability_date, {
          timeZone: 'America/Los_Angeles',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      }}
    </div>
    <div v-if="order?.envelope_id" class="flex items-center">
      <span class="font-bold mr-1">
        {{ isPending ? 'Expected ' : '' }}Expiry Date:
      </span>
      {{
        order?.expiry_date &&
        formatDate(order.expiry_date, {
          timeZone: 'America/Los_Angeles',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      }}
      <lob-button
        v-if="!isPending && canExtendExpiration"
        class="px-2 py-1 ml-2 mr-1 small"
        small
        variant="secondary"
        @click="onClickExtend"
      >
        Extend 1 Month
      </lob-button>
    </div>
    <div class="flex items-center">
      <span class="font-bold mr-1">Unit Cost:</span>
      ${{ formatNumber(order.unit_cost ?? order.unit_price) }}
    </div>
    <div class="flex items-center">
      <span class="font-bold mr-1">Total Cost:</span>
      ${{ formatNumber(order.total_cost) }}
    </div>
  </div>

  <ConfirmationModal
    v-if="confirmOpen"
    :confirm-args="[order.id]"
    message="Extend expiration date for this order by 1 month?"
    :visible="confirmOpen"
    @close="onClose"
    @reset="onClose"
    @updated="onExtend"
  />
</template>
