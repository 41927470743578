import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin":"0 2rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_H3 = _resolveComponent("H3")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_P = _resolveComponent("P")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_H3, null, {
      default: _withCtx(() => [
        _createTextVNode("This permutation is not currently enabled for your account.")
      ]),
      _: 1
    }),
    _createVNode(_component_P, null, {
      default: _withCtx(() => [
        _createTextVNode(" To turn on this permutation, please navigate to "),
        _createVNode(_component_router_link, { to: "/partners/current/onboarding/print-capabilities" }, {
          default: _withCtx(() => [
            _createTextVNode(" Print Capabilities ")
          ]),
          _: 1
        }),
        _createTextVNode(" page ")
      ]),
      _: 1
    })
  ]))
}