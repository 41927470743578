import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "content p-5" }
const _hoisted_2 = {
  key: 1,
  class: "text-lg font-normal mt-4"
}
const _hoisted_3 = { class: "relative overflow-x-auto pt-4" }
const _hoisted_4 = {
  key: 0,
  class: "table"
}
const _hoisted_5 = { class: "text-sm text-gray-300" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["colSpan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InventoryHeader = _resolveComponent("InventoryHeader")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InventoryHeader, { subject: "Change Log" }),
    (_ctx.errorMessage?.length)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 0,
          variant: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.route.query) && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(" Showing " + _toDisplayString(_ctx.changelogs.count) + " results where ", 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.route.query, (value, key) => {
            return (_openBlock(), _createElementBlock("span", {
              key: key,
              class: "text-gray-300"
            }, [
              _createTextVNode(_toDisplayString(key) + " = ", 1),
              _createElementVNode("span", null, "\"" + _toDisplayString(value) + "\"", 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_LoadingIndicator, null, {
        default: _withCtx(() => [
          (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (key) => {
                      return (_openBlock(), _createElementBlock("th", {
                        key: key,
                        class: "!border-t-0 text-gray-900 font-normal text-base"
                      }, _toDisplayString(key), 1))
                    }), 128))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.changelogs.data, (item) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: item.created_at
                    }, [
                      _createElementVNode("td", null, [
                        _createTextVNode(_toDisplayString(item.user_id) + " ", 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(item.user_email), 1)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(item.partner_id), 1),
                      _createElementVNode("td", null, [
                        _createVNode(_component_Tooltip, { position: "top" }, {
                          trigger: _withCtx(() => [
                            _createVNode(_component_RouterLink, {
                              to: `./?resource_id=${item.materiel_id}`
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.materiel_id), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]),
                          content: _withCtx(() => [
                            _createElementVNode("div", null, " Materiel Inventory ID: " + _toDisplayString(item.materiel_inventory_id), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(item.materiel_order_id), 1),
                      _createElementVNode("td", null, _toDisplayString(item.change_type), 1),
                      _createElementVNode("td", null, _toDisplayString(item.num_remaining), 1),
                      _createElementVNode("td", null, _toDisplayString(item.note), 1),
                      _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(item.created_at, {
                    timeZone: 'America/Los_Angeles',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  })), 1)
                    ]))
                  }), 128)),
                  (_ctx.changelogs.count === 0 || _ctx.changelogs.data?.length === 0)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                        _createElementVNode("td", {
                          colSpan: _ctx.columns.length,
                          class: "text-lg text-center"
                        }, " No data available ", 8, _hoisted_7)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_Pagination, {
        "data-testid": "pagination",
        collection: _ctx.changelogs.data,
        page: _ctx.page,
        limit: _ctx.PAGE_SIZE,
        total: _ctx.changelogs.count,
        onChange: _ctx.onChangePage
      }, null, 8, ["collection", "page", "limit", "total", "onChange"])
    ])
  ]))
}