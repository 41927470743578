<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="checkbox-field-editor-root">
    <ListMVE
      :schema="schema"
      :field="field"
      :test-set="testSet"
      :add-handlers="addHandlers"
      :display-component="staticData.AddressDisplay"
      :editor-component="staticData.AddressEditor"
      @update:testSet="$emit('update:testSet', $event)"
    />
  </div>
</template>

<script lang="ts">
import { TestSet } from '@/store/testSets'
import { Schema } from '@/store/testSets/schema'
import { markRaw } from '@vue/reactivity'
import { LOCATIONS } from '@/consts'
import { defineComponent, Prop } from '@vue/runtime-core'
import ListMVE from '../ListMVE.vue'
import AddressDisplay from './AddressDisplay.vue'
import AddressEditor from './AddressEditor.vue'
import realAddresses from './realAddresses.json'
import realAddressesInternational from './realAddressesInternational.json'

export function randomRealAddress(area) {
  return area === LOCATIONS.INTERNATIONAL
    ? realAddressesInternational[
        Math.floor(Math.random() * realAddressesInternational.length)
      ]
    : realAddresses[Math.floor(Math.random() * realAddresses.length)]
}

export function randomRealAddresses(quantity, area) {
  const result: Array<unknown> = []
  for (let i = 0; i < quantity; i++) {
    result.push(randomRealAddress(area))
  }
  return result
}

export default defineComponent({
  name: 'AddressMVE',
  components: { ListMVE },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>
  },
  emits: ['update:testSet'],
  data() {
    return {
      staticData: markRaw({ AddressDisplay, AddressEditor }),
      addHandlers: [
        {
          label: 'Address',
          create: () => [
            {
              name: '',
              address_line1: '',
              address_line2: '',
              address_city: '',
              address_state: '',
              address_zip: '',
              address_country: 'US'
            }
          ]
        },
        {
          label: '20 Random Real Address',
          create: () => randomRealAddresses(20, LOCATIONS.DOMESTIC)
        },
        {
          label: '100 Random Real Address',
          create: () => randomRealAddresses(100, LOCATIONS.DOMESTIC)
        },
        {
          label: '500 Random Real Address',
          create: () => randomRealAddresses(500, LOCATIONS.DOMESTIC)
        },
        {
          label: '1000 Random Real Address',
          create: () => randomRealAddresses(1000, LOCATIONS.DOMESTIC)
        }
      ]
    }
  }
})
</script>
