<template>
  <LobButton
    :variant="cellInfo.variant"
    size="small"
    class="text-sm"
    @click="($event) => cellInfo.onClick($event, rowData)"
  >
    {{ cellInfo.buttonText }}
    <!-- add space inbetween text and icon -->
    &nbsp;
    <fa v-if="cellInfo.icon" :icon="cellInfo.icon" />
  </LobButton>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

/* 
  tableRowObject for ButtonCell: 
  {
    fieldName,
    type,
    variant,
    icon, (icon after text, currently only supports fa icons)
    onClick(event, rowData)
  }
*/

export default defineComponent({
  name: 'ButtonCell',
  props: {
    cellInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
})
</script>
