<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h4>{{ modalTitle }}</h4>
      <Info class="ml-1 w-5 h-5" />
    </div>
    <PackageTextInput
      id="component-title"
      v-model:value="title"
      label="Title"
      placeholder="Title"
      :input-width="'w-1/2'"
      required
    />
    <PackageTextInput
      id="component-description"
      v-model:value="description"
      label="Description"
      placeholder="Short Description"
      :input-width="'w-1/2'"
      required
    />
    <PackageDropdown
      id="component-inventory-type"
      v-model:value="inventoryType"
      :options="inventoryTypes"
      label="Select an Inventory Type"
      placeholder="Select an Inventory Type"
      class="w-1/2"
      required
    />
    <PackageDropdown
      id="component-template-layouts"
      v-model:value="chosenLayoutOption"
      :options="layoutOptions"
      label="Select a Template ID"
      placeholder="Select a Template ID"
      class="w-1/2"
    />
    <PackageTextArea
      id="package-instructions"
      v-model:value="instructions"
      label="Instructions"
      placeholder="Type Instructions..."
    />
    <CustomMailFilePicker class="mt-2" @updateChosenFile="updateChosenFile" />
    <div class="flex items-end -mb-10 mt-3">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton :disabled="createDisabled" @click="manageComponent">
        {{ modalButtonAction }}
      </LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import router from '@/router'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageTextInput from './PackageTextInput.vue'
import PackageTextArea from './PackageTextArea.vue'
import CustomMailFilePicker from './CustomMailFilePicker.vue'
import PackageDropdown from './PackageDropdown.vue'
import {
  PackagePageData,
  createNewComponent,
  getTemplateLayouts,
  setComponentPageID
} from '@/store/customMail'

export default defineComponent({
  name: 'NewComponentModal',
  components: {
    PackageTextInput,
    PackageTextArea,
    CustomMailFilePicker,
    PackageDropdown
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object as PropType<PackagePageData>,
      default: null
    }
  },
  emits: ['close', 'saved'],
  data() {
    return {
      title: '',
      description: '',
      instructions: '',
      packageID: this.data?.id ?? '',
      modalTitle: '',
      modalButtonAction: '',
      inventoryType: { label: '', value: '' },
      inventoryTypes: CUSTOM_MAIL_CONSTS.COMPONENT_INVENTORY_TYPE_VALUES,
      chosenFile: null,
      layoutOptions: [],
      chosenLayoutOption: { label: '', value: '' }
    }
  },
  computed: {
    createDisabled() {
      return (
        this.inventoryType.label.length === 0 ||
        this.title.length === 0 ||
        this.description.length === 0
      )
    }
  },
  watch: {
    data: function (newVal) {
      this.packageID = newVal.id ?? ''
    },
    mode: function (newVal) {
      this.configureMode(newVal)
    }
  },
  async mounted() {
    const layouts = await getTemplateLayouts()
    this.layoutOptions = layouts.data.map((layout) => {
      return {
        label: `${layout.title} (${layout.description})`,
        value: layout.component_id
      }
    })
    this.configureMode(this.mode as string)
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    configureMode(mode: string) {
      if (mode === CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_CREATE_MODE) {
        this.modalTitle = CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_CREATE_TITLE
        this.modalButtonAction =
          CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_CREATE_BUTTON
      } else {
        this.modalTitle = CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_EDIT_TITLE
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.COMPONENT_MODAL_EDIT_BUTTON
      }
    },
    async manageComponent() {
      const component = (await createNewComponent({
        title: this.title,
        description: this.description,
        instructions: this.instructions,
        inventoryType: this.inventoryType.value,
        file: this.chosenFile as any,
        packageID: this.packageID,
        templateID: this.chosenLayoutOption.value
      })) as any
      setComponentPageID(component.data.id)
      const currentPath = this.$route.path
      router.push({
        path: `${currentPath}/${component.data.id}`,
        params: {
          ...this.$route.params,
          componentID: component.data.id
        }
      })
    },
    updateChosenFile(file) {
      this.chosenFile = file
    }
  }
})
</script>
