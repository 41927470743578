<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h4>{{ modalTitle }}</h4>
      <Tooltip v-if="isEditing" position="bottom">
        <template #trigger>
          <Info class="ml-1 w-5 h-5" />
        </template>
        <template #content>
          <div class="w-32">
            {{ packageModalEditTooltip }}
          </div>
        </template>
      </Tooltip>
    </div>
    <div>
      <label class="m-0">Account Access</label>
      <span class="text-error">*</span>
    </div>
    <RadioGroup v-if="!isEditing">
      <RadioButton
        v-for="option in accountAccessTypeOptions"
        :id="option.value"
        :key="option.value"
        v-model="accountAccessType"
        :name="option.name"
        :value="option.value"
        :label="option.lable"
        @change="handleAccountAccessUpdate"
      />
    </RadioGroup>

    <div
      v-if="
        !isEditing && accountAccessType === accountAccessTypes.SUBSCRIPTION_TIER
      "
      class="mb-2"
    >
      <div>
        <label>Tiers</label>
        <span class="text-error">*</span>
      </div>
      <Checkbox
        v-for="option in tierOptions"
        :id="option.value"
        :key="option.value"
        v-model="tiers"
        :name="option.name"
        :value="option.value"
        :label="option.lable"
        same-line
      />
    </div>

    <PackageTextInput
      v-if="!isEditing && accountAccessType === accountAccessTypes.ACCOUNT_ID"
      id="package-customer-account"
      v-model:value="account_id"
      label="Customer Account ID"
      placeholder="Customer Account ID"
      :input-width="'w-1/2'"
      required
    />
    <PackageTextInput
      id="package-title"
      v-model:value="title"
      label="Title"
      placeholder="Title"
      :input-width="'w-1/2'"
      required
    />
    <PackageTextInput
      id="package-description"
      v-model:value="description"
      label="Description"
      placeholder="Short Description"
      :input-width="'w-1/2'"
      required
    />
    <PackageDropdown
      id="package-mail-type"
      v-model:value="mailType"
      :options="mailTypes"
      label="Mail Type"
      placeholder="Select"
      class="w-1/2"
      required
    />
    <PackageDropdown
      id="package-product-type"
      v-model:value="objectType"
      :options="objectTypes"
      label="Object Type"
      placeholder="Select"
      class="w-1/2"
      required
    />
    <PackageTextArea
      id="package-instructions"
      v-model:value="instructions"
      label="Instructions"
      placeholder="Type Instructions..."
    />
    <Alert v-if="errorMessage" variant="error" class="mt-2">
      {{ errorMessage }}
    </Alert>
    <div class="flex items-end -mb-10 mt-3">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton :disabled="createDisabled" @click="moveToPackagePage">
        {{ modalButtonAction }}
      </LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import router from '@/router'
import { ACCOUNT_ACCESS_TYPE, CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageTextInput from './PackageTextInput.vue'
import PackageTextArea from './PackageTextArea.vue'
import PackageDropdown from './PackageDropdown.vue'
import {
  setCurrentUserAction,
  setPackagePageData,
  PackagePageData,
  createNewPackage,
  editPackageDetails,
  clonePackage,
  PackageCreate
} from '@/store/customMail'
import { getUser, isAdminOrPopsUser } from '@/store/session'

export default defineComponent({
  name: 'PackageModal',
  components: {
    PackageTextInput,
    PackageTextArea,
    PackageDropdown
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object as PropType<PackagePageData>,
      default: null
    }
  },
  emits: ['close', 'saved'],
  data() {
    return {
      isLoading: false,
      isEditing: false,
      account_id: this.data?.account_id ?? '',
      title: this.data?.title ?? '',
      description: this.data?.description ?? '',
      instructions: this.data?.instructions ?? '',
      packageID: this.data?.id ?? '',
      modalTitle: '',
      modalButtonAction: '',
      mailType: this.data?.mail_type
        ? this.configureMailType(this.data?.mail_type)
        : {
            label: '',
            value: ''
          },
      objectType: this.data?.object_type
        ? this.configureObjectType(this.data?.object_type)
        : {
            label: '',
            value: ''
          },
      mailTypes: CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_MAIL_TYPE_VALUES,
      objectTypes: CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_OBJECT_TYPE_VALUES,
      errorMessage: '',
      packageModalEditTooltip: CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_EDIT_TOOLTIP,
      // package/product permission can be based on account id or account subscription tier
      accountAccessType: ACCOUNT_ACCESS_TYPE.ACCOUNT_ID,
      accountAccessTypes: ACCOUNT_ACCESS_TYPE,
      accountAccessTypeOptions:
        CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_ACCOUNT_ACCESS_VALUES,
      tiers: [],
      tierOptions: CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_TIER_OPTIONS
    }
  },
  computed: {
    createDisabled() {
      return (
        // eslint-disable-next-line
        (this.account_id.length === 0 && this.tiers.length === 0) ||
        this.title.length === 0 ||
        this.description.length === 0 ||
        this.isLoading
      )
    }
  },
  watch: {
    data: function (newVal) {
      this.packageID = newVal.id ?? ''
      this.account_id = newVal.account_id ?? ''
      this.title = newVal.title
      this.description = newVal.description
      this.instructions = newVal.instructions
      this.mailType = newVal.mail_type
        ? this.configureMailType(newVal.mail_type)
        : {
            label: '',
            value: ''
          }
      this.objectType = newVal.object_type
        ? this.configureObjectType(newVal.object_type)
        : {
            label: '',
            value: ''
          }
      this.accountAccessType = newVal.account_id
        ? ACCOUNT_ACCESS_TYPE.ACCOUNT_ID
        : ACCOUNT_ACCESS_TYPE.SUBSCRIPTION_TIER
      this.tiers = newVal.tiers
    },
    mode: function (newVal) {
      this.configureMode(newVal)
    }
  },
  mounted() {
    this.configureMode(this.mode as string)
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    async moveToPackagePage() {
      const adminAccountId = isAdminOrPopsUser()
        ? getUser()?.account_id
        : undefined
      const data: PackageCreate = {
        // when a product/package is created, a product permission will be associated with the product
        // product permission is based on a customer's account_id (private, only available to the customer)
        // or customer account tier (public, available for all customers with an account in one of the selected tiers)
        // the admin account id is saved on "public" products based on account tier
        account_id:
          this.accountAccessType === ACCOUNT_ACCESS_TYPE.ACCOUNT_ID
            ? this.account_id
            : adminAccountId,
        tiers:
          this.accountAccessType === ACCOUNT_ACCESS_TYPE.SUBSCRIPTION_TIER
            ? this.tiers
            : undefined,
        title: this.title,
        description: this.description,
        instructions: this.instructions,
        mail_type: this.mailType.value,
        object_type: this.objectType.value
      }

      // TODO: add api endpoint to set the data

      let updatedPackage
      if (this.mode === CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_EDIT_MODE) {
        // if this is an edit action, just close modal since we're already on package-page
        try {
          const resp = (await editPackageDetails({
            packageID: this.packageID,
            title: this.title,
            description: this.description,
            instructions: this.instructions,
            mail_type: this.mailType.value,
            object_type: this.objectType.value
          })) as any
          updatedPackage = resp
          this.$emit('close', false)
        } catch (e) {
          this.errorMessage = e as string
        }
      } else if (this.mode === CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CLONE_MODE) {
        this.isLoading = true
        try {
          const resp = (await clonePackage({
            ...data,
            original_id: this.packageID
          })) as any
          updatedPackage = resp
          setCurrentUserAction(`package-page-${this.mode}`)
          setTimeout(() => {
            // wait for BE to load before switching pages
            router.push('/partner_ops/custom-mail/package-page')
            this.isLoading = false
            this.$emit('close', false)
          }, 2000)
        } catch (e) {
          this.isLoading = false
          this.errorMessage = e as string
        }
      } else {
        try {
          const resp = (await createNewPackage(data)) as any
          updatedPackage = resp
          setCurrentUserAction(`package-page-${this.mode}`)
          router.push('/partner_ops/custom-mail/package-page')
        } catch (e) {
          this.errorMessage = e as string
        }
      }
      setPackagePageData(updatedPackage)
    },
    configureMode(mode: string) {
      if (mode === CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CREATE_MODE) {
        this.isEditing = false
        this.modalTitle = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CREATE_TITLE
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CREATE_BUTTON
      } else if (mode === CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CLONE_MODE) {
        this.isEditing = false
        this.modalTitle = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CLONE_TITLE
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CLONE_BUTTON
      } else {
        this.isEditing = true
        this.modalTitle = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_EDIT_TITLE
        this.modalButtonAction = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_EDIT_BUTTON
      }
    },
    configureMailType(mailTypeValue) {
      const mailTypes = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_MAIL_TYPE_VALUES
      if (!mailTypeValue)
        return {
          label: '',
          value: ''
        }
      return mailTypes.find((mailType) => mailType.value === mailTypeValue)
    },
    configureObjectType(objectTypeValue) {
      const objectTypes = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_OBJECT_TYPE_VALUES
      if (!objectTypeValue)
        return {
          label: '',
          value: ''
        }
      return objectTypes.find((mailType) => mailType.value === objectTypeValue)
    },
    handleAccountAccessUpdate() {
      if (this.accountAccessType === ACCOUNT_ACCESS_TYPE.ACCOUNT_ID) {
        this.tiers = []
      } else {
        this.account_id = ''
      }
    }
  }
})
</script>

<!-- these style changes are to override UI component styling with partners portal styling -->
<style scoped>
:deep(fieldset legend) {
  font-size: inherit;
  color: inherit;
}
:deep(fieldset div) {
  margin-top: 0 !important;
}
:deep(fieldset input) {
  margin-top: 0 !important;
}
</style>
