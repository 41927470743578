import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_P = _resolveComponent("P")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TextInput, {
      id: "name",
      label: "Text, true, or false",
      "model-value": _ctx.textValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event)))
    }, null, 8, ["model-value"]),
    _createVNode(_component_P, null, {
      default: _withCtx(() => [
        _createTextVNode("Use false to indicate an omission")
      ]),
      _: 1
    })
  ], 64))
}