<template>
  <div class="p-8 mt-4">
    <h4>SLA Miss/Quality Reasons</h4>
    <br />
    <hr color="#EBF0F6" />
    <br />
    <form @submit.prevent="submitForm()">
      <div
        class="bg-white-300 rounded-tl-sm rounded-tr-sm pl-4 text-black h-12 text-l flex items-center"
        @click="toggleFormShow()"
      >
        Report Submission Form
        <div class="ml-auto flex h-6 align-middle">
          <Checkmark
            v-if="isCompleted()"
            :data-testid="`${name}-checkmark`"
            class="mr-2 text-success"
          />
          <Warning
            v-else
            :data-testid="`${name}-warning`"
            class="mr-2 text-warning"
          />
          <div class="mr-3">
            {{ isCompleted() ? 'Completed' : 'Incomplete' }}
          </div>
          <ChevronDown v-if="expanded" class="mt-0.5 mr-2" />
          <ChevronRight v-else class="mt-0.5 mr-2" />
        </div>
      </div>
      <div v-if="expanded" class="pl-4 pb-4">
        <h6 class="mt-3">Info</h6>
        <hr class="mt-2 mb-2" color="#EBF0F6" />
        <div class="grid grid-cols-2 pl-4 pr-4">
          <TextInput
            id="created_by"
            v-model="form.created_by"
            class="pr-4 mb-3"
            label="Reported by (Email):"
            required
            placeholder="first.last@lob.com"
          />
          <div class="mb-2">
            <input v-model="form.created_at" class="invisible" type="date" />
          </div>
          <Dropdown
            id="partner"
            v-model="partner"
            :options="partnerOptions"
            label="Partner:"
            placeholder="Select A Partner"
            class="combobox pr-4"
            required
            @update:modelValue="handlePartnerValue"
          />
        </div>
        <h6 class="mt-3">Affected Resources</h6>
        <hr class="mt-2 mb-2" color="#EBF0F6" />
        <div class="pl-4 pr-4 text-sm text-sm text-turquoise-900">
          Save a list of resource_ids in a csv file named miss_quality.csv (See
          <a
            href="https://docs.google.com/spreadsheets/d/1GyAzvFMiLRdP4UklpR3ZpnrxVeEGFu_6wG1vWWICcG0/edit#gid=1963779592"
            target="_blank"
          >
            Template
          </a>
          )
          <div class="font-bold">
            The maximum number of Resource IDs cannot exceed 40k per submission.
          </div>
        </div>
        <div class="flex">
          <div class="flex flex-col pl-4 pr-4 mt-1 text-sm text-turquoise-900">
            <label>Upload CSV: *</label>
            <FileUpload
              id="csv-file-upload"
              label="Choose File"
              required
              accept=".csv"
              @fileUpload="upload"
            />
          </div>
        </div>
        <div class="mb-3 pl-4 pr-4">
          <RadioGroup
            legend="Was the issue communicated proactively by the Partner?"
            required
          >
            <RadioButton
              id="notCommunicated"
              v-model="form.has_proactive_communication"
              name="communicated"
              label="No"
              :value="false"
            />
            <RadioButton
              id="yesCommunicated"
              v-model.boolean="form.has_proactive_communication"
              name="communicated"
              label="Yes"
              :value="true"
            />
          </RadioGroup>
        </div>
        <div>
          <div class="text-sm text-sm text-turquoise-900 mt-3 mb-1">
            Choose one reason
          </div>
          <div>
            <subnavigation class="mb-2">
              <subnavigation-item
                id="missNav"
                title="Miss"
                class="text-lg"
                to="/partner_ops/sla-miss-quality/miss"
              />
              <subnavigation-item
                id="qualNav"
                title="Quality"
                class="text-lg"
                :disabled="form.is_miss"
                to="/partner_ops/sla-miss-quality/quality"
              />
            </subnavigation>
            <router-view
              :form="form"
              :handle-dropdown-value="handleDropdownValue"
            />
          </div>
        </div>
      </div>
      <div class="flex place-content-end pt-4">
        <LobButton :disabled="!isComplete" @submit="submitForm()">
          Submit
        </LobButton>
      </div>
    </form>
    <Alert
      v-if="isComplete && isError"
      variant="error"
      class="mt-2 col-12 item"
    >
      {{ errorMessage }}
    </Alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  getLivePartners,
  createSLAMissQuality,
  getCurrentPartnerId
} from '@/store/partners'

export default defineComponent({
  name: 'SlaMissQuality',
  emits: ['update:dateModelValue', 'update:is_miss'],
  data() {
    return {
      name: 'slaMissQuality',
      expanded: true,
      partner: {},
      form: {
        user_partner: '',
        created_at: new Date().toISOString(),
        vendor_id: '',
        created_by: '',
        has_quality_issue: false,
        is_miss: false,
        miss_category: '',
        miss_sub_category: '',
        quality_category: '',
        quality_sub_category: '',
        has_proactive_communication: false,
        csv_data: null as any
      },
      isError: false,
      errorMessage: '',
      uploadError: '',
      isComplete: false
    }
  },
  computed: {
    partnerOptions(): any {
      const partners = getLivePartners()
      if (partners === null) return null
      const mappedPartners = partners.map((partner) => ({
        label: `${partner.display_name} (${partner.id})`,
        value: partner.id
      }))
      mappedPartners.sort((a, b) => {
        const al = a.label.toUpperCase()
        const bl = b.label.toUpperCase()
        if (al < bl) {
          return -1
        } else if (al > bl) {
          return 1
        } else {
          return 0
        }
      })
      return mappedPartners
    }
  },
  mounted() {
    // TODO: implement more rigorous logic for null values
    // for now, this works because only vex users should have access to partner ops features like submitting an SLA issue
    const userPartner = getCurrentPartnerId()
    this.form.user_partner = userPartner ?? 'vex'
  },
  methods: {
    async submitForm() {
      try {
        await createSLAMissQuality({
          user_partner: this.form.user_partner,
          created_at: this.form.created_at,
          vendor_id: this.form.vendor_id,
          created_by: this.form.created_by,
          has_quality_issue: this.form.has_quality_issue,
          is_miss: this.form.is_miss,
          miss_category: this.form.miss_category,
          miss_sub_category: this.form.miss_sub_category,
          quality_category: this.form.quality_category,
          quality_sub_category: this.form.quality_sub_category,
          has_proactive_communication: this.form.has_proactive_communication,
          csv_data: this.form.csv_data
        })
        this.$router.push({ path: '/partner_ops/sla-status/success' })
      } catch (error: any) {
        this.isError = true
        const eResp = error.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
    },
    toggleFormShow() {
      this.expanded = !this.expanded
    },
    isCompleted() {
      let completed = false
      for (const field in this.form) {
        if (
          field === 'created_at' ||
          field === 'has_proactive_communication' ||
          field === 'csv_data'
        ) {
          if (this.form[field] === null) {
            return completed
          }
        }
        if (field === 'vendor_id' || field === 'created_by') {
          if (this.form[field].length === 0) {
            return completed
          }
        }

        if (
          field === 'is_miss' &&
          this.form[field] === this.form.has_quality_issue
        ) {
          if (this.form[field] === false) {
            return completed
          }
        }

        if (
          field === 'is_miss' &&
          this.form[field] !== this.form.has_quality_issue
        ) {
          if (field === 'is_miss' && this.form[field] === true) {
            if (
              this.form.miss_category === '' ||
              this.form.miss_sub_category === ''
            ) {
              if (
                this.form.miss_category === '' ||
                this.form.miss_sub_category === ''
              ) {
                return completed
              }
            }
          }
        }

        if (
          field === 'has_quality_issue' &&
          this.form[field] !== this.form.is_miss
        ) {
          if (field === 'has_quality_issue' && this.form[field] === true) {
            if (
              this.form.quality_category === '' ||
              this.form.quality_sub_category === ''
            ) {
              if (
                this.form.quality_category === '' ||
                this.form.quality_sub_category === ''
              ) {
                return completed
              }
            }
          }
        }
      }
      completed = true
      this.isComplete = completed
      return completed
    },
    showReasonField(reason) {
      if (reason === 'miss') {
        this.form.is_miss = true
        this.form.has_quality_issue = false
        this.form.quality_category = ''
        this.form.quality_sub_category = ''
      } else if (reason === 'quality') {
        this.form.has_quality_issue = true
        this.form.is_miss = false
        this.form.miss_category = ''
        this.form.miss_sub_category = ''
      }
    },
    upload(e) {
      const file = e.target.files[0]
      this.form.csv_data = file
    },
    handlePartnerValue(e) {
      this.form.vendor_id = e.value
    },
    handleDropdownValue(dropdownVal) {
      this.form = { ...this.form, ...dropdownVal }
    }
  }
})
</script>

<style lang="scss" scoped></style>
