<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'

type FilterTypes = 'materiel_id' | 'materiel_order_id' | 'partner_id'

export default defineComponent({
  name: 'LogsIcon',
  props: {
    query: {
      // eslint-disable-next-line no-unused-vars
      type: Object as PropType<{ [key in FilterTypes]?: string }>,
      required: true
    }
  },
  setup(props) {
    const route = useRoute()
    const url = computed(
      () =>
        `/partner_ops/accounts/${route.params.crid}/${
          route.params.resource
        }/changelogs?${new URLSearchParams(
          props.query as Record<string, string>
        ).toString()}`
    )
    return {
      url
    }
  }
})
</script>

<template>
  <RouterLink class="leading-3" title="See related change logs" :to="url">
    <Book
      class="w-4 h-4 text-gray-100 hover:text-gray-300 align-text-bottom inline-block ml-1"
    />
  </RouterLink>
</template>
