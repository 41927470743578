<template>
  <div class="content p-5">
    <PortalBreadCrumb />
    <div class="flex">
      <div class="text-3xl mb-20 ml-10 font-bold">
        {{ mainTitle }} ({{ packageData?.title }})
      </div>
      <div class="flex-1" />
      <PackageButton
        v-if="isEditing"
        class="h-12 mr-3"
        :on-click="openCloneModal"
      >
        Clone
      </PackageButton>
      <PackageButton class="h-12" :on-click="primaryAction" variant="solid">
        {{ primaryActionButton }}
      </PackageButton>
    </div>
    <div>
      <Card>
        <PackageInformation
          :data="packageData"
          :is-admin-or-pops-user="isAdminOrPopsUser"
          @openEditPackageModal="openEditPackageModal"
        />
        <div class="border-t-2 w-full h-1 border-grayDove my-2" />
        <ComponentInformation
          :is-admin-or-pops-user="isAdminOrPopsUser"
          :data="packageComponents"
          @openComponentDeleteModal="openComponentDeleteModal"
          @createNewComponent="createNewComponent"
        />
        <!-- <ComponentInformation :productId= "productID" :isAdminOrPopsUser="isAdminOrPopsUser"/> -->
        <div class="border-t-2 w-full h-1 border-grayDove my-2" />
        <div class="flex">
          <div class="text-2xl mb-10 mt-2">Manage Bids</div>
          <Tooltip position="right">
            <template #trigger>
              <Info class="ml-1 w-5 h-5" />
            </template>
            <template #content>
              <div class="w-16">
                {{ manageBidsInformationTooltip }}
              </div>
            </template>
          </Tooltip>
          <div class="flex-1" />
          <PackageButton class="h-12" :on-click="toManageBids">
            Manage Bids
          </PackageButton>
        </div>
        <div class="border-t-2 w-full h-1 border-grayDove my-2" />
        <PackagePermissions v-if="isAdminOrPopsUser" :data="permissions" />
      </Card>
    </div>
  </div>
  <PackageModal
    :mode="modalMode"
    :data="packageData"
    :is-open="isPackageModalOpen"
    @close="closeCloneModal"
  />
  <PackageDeleteModal
    :is-open="isDeleteModalOpen"
    :data="deleteData"
    :item-type="deleteType"
    @close="closeDeleteModal"
    @deleteItem="deleteItem"
  />
  <NewComponentModal
    mode="create_component"
    :data="packageData"
    :is-open="isNewComponentModalOpen"
    @close="closeComponentModal"
  />
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { isAdminOrPopsUser } from '@/store/session'
import {
  getCurrentUserAction,
  getPackagePageData,
  PackagePageData,
  deletePackageID,
  getPackageComponents,
  deleteComponentID,
  getPackage,
  setPackagePageData,
  PackagePermission
} from '@/store/customMail'
import router from '@/router'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageButton from '../common/PackageButton.vue'
import PackageInformation from './PackageInformation.vue'
import PackageModal from '../common/PackageModal.vue'
import PackageDeleteModal from '../common/PackageDeleteModal.vue'
import PackagePermissions from './PackagePermissions.vue'
import ComponentInformation from './ComponentInformation.vue'
import NewComponentModal from '../common/NewComponentModal.vue'
import PortalBreadCrumb from '../../../../../components/common/PortalBreadcrumb.vue'

export default defineComponent({
  components: {
    PackageButton,
    PackageInformation,
    ComponentInformation,
    PackageModal,
    PackageDeleteModal,
    PackagePermissions,
    NewComponentModal,
    PortalBreadCrumb
  },
  data() {
    return {
      headerTitle: '',
      isPackageModalOpen: false,
      isDeleteModalOpen: false,
      isNewComponentModalOpen: false,
      modalMode: '',
      deleteType: '',
      deleteData: {},
      packageComponents: [],
      manageBidsInformationTooltip:
        CUSTOM_MAIL_CONSTS.MANAGE_BIDS_INFORMATION_TOOLTIP,
      selectedPackageID: '',
      packageData: undefined as PackagePageData | undefined,
      permissions: [] as PackagePermission[]
    }
  },
  computed: {
    isAdminOrPopsUser() {
      return isAdminOrPopsUser()
    },
    mode() {
      if (!getCurrentUserAction()) return 'Unauthorized'
      return getCurrentUserAction()?.split('-')[2]
    },
    mainTitle() {
      const currentMode = this.mode as string
      return currentMode === CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CLONE_MODE
        ? CUSTOM_MAIL_CONSTS.PACKAGE_PAGE_CLONE_TITLE
        : CUSTOM_MAIL_CONSTS.PACKAGE_PAGE_EDIT_TITLE
    },
    primaryActionButton() {
      const adminOrPops = this.isAdminOrPopsUser as boolean
      return adminOrPops
        ? CUSTOM_MAIL_CONSTS.PACKAGE_PAGE_POPS_DELETE_BUTTON
        : CUSTOM_MAIL_CONSTS.PACKAGE_PAGE_PARTNER_EXPORT_BUTTON
    },
    isEditing() {
      const currentMode = this.mode as string
      return currentMode === CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_EDIT_MODE
    }
  },
  async mounted() {
    this.$route.meta.displayName = 'test'
    const packageID =
      (this.packageData?.id as string) ||
      (this.$route.params.packageID as string)
    const resp = await getPackageComponents({ packageID })
    this.packageComponents = resp.data
    await this.fetchPackage()
  },
  methods: {
    primaryAction() {
      if (this.isAdminOrPopsUser) {
        // will always be delete for admins/pops
        this.deleteData = this.packageData || {}
        this.openDeleteModal(CUSTOM_MAIL_CONSTS.DELETE_MODAL_TYPE_PACKAGE)
      }
    },
    openCloneModal() {
      this.modalMode = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_CLONE_MODE
      this.isPackageModalOpen = true
    },
    closeCloneModal() {
      this.isPackageModalOpen = false
    },
    createNewComponent() {
      this.isNewComponentModalOpen = true
    },
    async closeComponentModal() {
      this.isNewComponentModalOpen = false
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false
    },
    openDeleteModal(type: string) {
      this.deleteType = type
      this.isDeleteModalOpen = true
    },
    openComponentDeleteModal(item: any) {
      this.deleteData = item
      this.openDeleteModal(item.type)
    },
    async deleteItem(data) {
      if (data.type === CUSTOM_MAIL_CONSTS.DELETE_MODAL_TYPE_PACKAGE) {
        // if delete type is Package
        deletePackageID({ packageID: data.id })
        this.isDeleteModalOpen = false
        router.push('/partner_ops/custom-mail')
      } else {
        // if delete type is Component
        await deleteComponentID({ componentID: data.id })
        const packageID = this.packageData?.id as string
        const components = await getPackageComponents({ packageID })
        this.packageComponents = components.data
        this.isDeleteModalOpen = false
      }
    },
    openEditPackageModal() {
      this.modalMode = CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_EDIT_MODE
      this.isPackageModalOpen = true
    },
    toManageBids() {
      const currentRoute = this.$route.path
      router.push(`${currentRoute}/bids/`)
    },
    async fetchPackage() {
      let packageData: PackagePageData | null
      packageData = getPackagePageData()
      if (!packageData) {
        packageData = await getPackage({
          packageID: this.$route.params.packageID as string
        })
        setPackagePageData(packageData as PackagePageData)
      }
      this.packageData = packageData || undefined
      this.permissions = packageData?.permissions || []
    }
  }
})
</script>

<style lang="scss" scoped></style>
