<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import AuditLogFilters from './Filters.vue'
import AuditLogTable from './Table.vue'
import useAuditLogs from './useAuditLogs'

export default defineComponent({
  name: 'AuditLog',
  components: {
    AuditLogFilters,
    AuditLogTable
  },
  setup() {
    const { isLoading, data, PAGE_SIZE, page, err, fetchAuditLog, filters } =
      useAuditLogs()

    onMounted(() => {
      fetchAuditLog(filters.value, 0)
    })

    const search = (e) => {
      filters.value = e
      fetchAuditLog(e, 0)
    }

    const changePage = (e) => {
      fetchAuditLog(filters.value, (Number(e.page) - 1) * PAGE_SIZE)
      page.value = Number(e.page)
    }

    return { isLoading, data, PAGE_SIZE, page, search, changePage, err }
  }
})
</script>

<template title="Invite Partner User">
  <div class="content p-5">
    <header class="mb-4">
      <h2 class="mb-5">Audit Log</h2>
      <AuditLogFilters @search="search" />
      <LoadingIndicator>
        <div v-if="!isLoading" class="pr-10">
          <alert v-if="err" variant="error" class="my-2">{{ err }}</alert>
          <AuditLogTable class="mt-4" :data="data?.data" />
          <Pagination
            data-testid="pagination"
            :collection="data?.data"
            :page="page"
            :limit="PAGE_SIZE"
            :total="data?.count"
            @change="changePage"
          />
        </div>
      </LoadingIndicator>
    </header>
  </div>
</template>
