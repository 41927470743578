import axios, { AxiosError, AxiosResponse } from 'axios'
import config from '@/../config'
import * as session from '../session'
import { defineVuexModule } from '../util'
import * as errors from '../../../src/errors'

type EmptyState = Record<string, never>

const storeModule = defineVuexModule('requests', {} as EmptyState)

const rejectedMasqueradeMethods = ['POST', 'PUT', 'PATCH', 'DELETE']

export interface PartnersRequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS'
  path: string
  /* eslint "@typescript-eslint/no-explicit-any": "off" */
  query?: any
  data?: any
  headers?: Record<string, string>
  admin?: boolean
  v2PublicRoute?: boolean
}

export type RequestOptions = PartnersRequestOptions & {
  lobApiV2?: true
  sid?: true
  partners?: true
  authenticate?: true
  ffb?: true
  routingService?: true
}

// GETTERS (exported functions that provide pieces of data from the state)

// MUTATORS (synchronous functions that change the state, each call to a mutator
// is logged in the Vue development tools)

// ACTIONS (asynchronous functions which can call other actions as well as
// getters and setters, also logged in the Vue development tools)

const sendDevRequest = storeModule.dispatch(
  async <T = any>(
    _: any,
    options: RequestOptions
  ): Promise<AxiosResponse<T>> => {
    let baseUrl
    const apiKey = 'Basic a2V5X2FkbWluOg=='
    if (options.partners) baseUrl = config.PARTNERS_API_BASEURL
    if (options.sid) baseUrl = config.SID_API_BASEURL
    if (options.ffb) baseUrl = config.FORM_FACTOR_BASEURL

    const url = baseUrl + options.path
    const basicHeaders: Record<string, string> = {
      'x-user-agent': 'Partners_Portal Lob_Dashboard'
    }
    basicHeaders.Authorization = apiKey
    // eslint-disable-next-line
    const headers = { ...basicHeaders, ...(options.headers || {}) }

    const method = options.method
    const data = options.data
    const params = options.query || {}
    return await axios.request({ method, url, headers, data, params })
  },
  'sendDevRequest'
)

export const sendRequest = storeModule.dispatch(
  async <T = any>(
    _: any,
    options: RequestOptions
  ): Promise<AxiosResponse<T>> => {
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
      // if in dev env, hit local endpoints instead of staging
      return await sendDevRequest(options)
    }
    let baseUrl
    let service = ''
    let addPartnerHeader = false

    if (options.partners) service = '/partners-api'
    if (options.sid) service = '/sid'
    if (options.routingService) service = '/routing-service'

    if (options.lobApiV2 && options.ffb) {
      // For admin routes, the user will need to be marked as an admin in lob-api. To do so,
      // or to check, log into lens and look up the user id. Open the Properties area and toggle
      // the Admin flag.
      if (session.isAdminOrPopsUser()) {
        // if user is an admin but accessing non-admin route, do not add admin in route
        if (!options.v2PublicRoute) service = '/admin'
      } else {
        addPartnerHeader = true
      }
      baseUrl = config.LOB_API_V2_BASEURL + service
    } else {
      baseUrl = config.LOB_API_BASEURL + service
    }
    const url = baseUrl + options.path

    // @todo: add exception for Lob_Partners_Portal on lob-api and make the change here
    const basicHeaders: Record<string, string> = {
      'x-user-agent': 'Partners_Portal Lob_Dashboard'
    }

    if (options.authenticate) {
      const token = session.getToken()
      basicHeaders.Authorization = `Bearer ${token}`
    }

    if (options.lobApiV2) {
      const user = session.getUser()
      if (addPartnerHeader) {
        // only add this header if the user is a non-admin partner
        basicHeaders['x-lob-on-behalf-of-partner'] = user?.partner as string
      }

      // only allow partner users to send write requests to the /bids routes
      if (
        user?.role === 'partner' &&
        options.method !== 'GET' &&
        options.path.slice(0, 5) !== '/bids'
      ) {
        throw errors.UNAUTHORIZED_LOB_API_V2_ERROR()
      }
    }
    // currently commenting out as header is causing CORS policy issues
    // const currentUser = session.getUser()
    // if (currentUser?.masquerade_info && currentUser?.masquerade_info.masquerade_user_id.length !== 0) {
    //   basicHeaders['X-Lob-On-Behalf-Of'] = currentUser?.id as string
    // }

    // eslint-disable-next-line prettier/prettier
    const headers = { ...basicHeaders, ...(options.headers || {}) }

    try {
      const method = options.method
      const data = options.data
      const params = options.query || {}
      const result = await axios.request({ method, url, headers, data, params })
      return result
    } catch (e) {
      if ((e as AxiosError).response?.status === 401) {
        // The cleared token will be detected and the user will be routed to the login page.
        session.clearSession()
      }
      throw e
    }
  },
  'sendRequest'
)

export const sendPapiRequest = storeModule.dispatch(
  async <T = any>(
    _: any,
    options: PartnersRequestOptions
  ): Promise<AxiosResponse<T>> => {
    if (
      session.isUserMasquerading() &&
      !options.admin &&
      rejectedMasqueradeMethods.includes(options.method)
    ) {
      throw errors.MASQUERADE_ATTEMPT_ERROR()
    } else {
      return await sendRequest({
        partners: true,
        authenticate: true,
        ...options
      })
    }
  },
  'sendPapiRequest'
)

export const sendSidRequest = storeModule.dispatch(
  async <T = any>(
    _: any,
    options: PartnersRequestOptions
  ): Promise<AxiosResponse<T>> => {
    return await sendRequest({
      sid: true,
      authenticate: true,
      ...options
    })
  },
  'sendSidRequest'
)

export const sendLobRequest = storeModule.dispatch(
  async <T = any>(
    _: any,
    options: PartnersRequestOptions
  ): Promise<AxiosResponse<T>> => {
    return await sendRequest({
      authenticate: true,
      ...options
    })
  },
  'sendLobRequest'
)

export const sendFFBRequest = storeModule.dispatch(
  async <T = any>(
    _: any,
    options: PartnersRequestOptions
  ): Promise<AxiosResponse<T>> => {
    return await sendRequest({
      ffb: true,
      lobApiV2: true,
      authenticate: true,
      ...options
    })
  },
  'sendFFBRequest'
)

export const sendRoutingServiceRequest = storeModule.dispatch(
  async <T = any>(
    _: any,
    options: PartnersRequestOptions
  ): Promise<AxiosResponse<T>> => {
    return await sendRequest({
      routingService: true,
      authenticate: true,
      ...options
    })
  },
  'sendRoutingServiceRequest'
)
// WATCHERS (functions that are called when a value in the store changes,
// possibly one from a different module.)
