import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row py-3" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row py-3" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row py-3" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "row py-3" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "row py-3" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "flex self-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_LobButton = _resolveComponent("LobButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.isModalVisible,
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h4", null, "Edit " + _toDisplayString(_ctx.partner) + "'s " + _toDisplayString(_ctx.RESOURCES[_ctx.resource].label) + " Capacity", 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_LobButton, {
          class: "mr-4",
          variant: "link",
          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["stop"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Close ")
          ]),
          _: 1
        }),
        _createVNode(_component_LobButton, {
          onClick: _withModifiers(_ctx.onSubmit, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode("Confirm")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_TextInput, {
              id: "daily",
              modelValue: _ctx.fields.perDay,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fields.perDay) = $event)),
              label: 
              `default sla - ${_ctx.RESOURCES[_ctx.resource].unit} per day`.toUpperCase()
            ,
              placeholder: `${_ctx.RESOURCES[_ctx.resource].unit} per day`,
              required: true
            }, null, 8, ["modelValue", "label", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_TextInput, {
              id: "oneDaySlaDaily",
              modelValue: _ctx.fields.oneDaySlaDaily,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fields.oneDaySlaDaily) = $event)),
              label: 
              `one day sla - ${_ctx.RESOURCES[_ctx.resource].unit} per day`.toUpperCase()
            ,
              placeholder: `${_ctx.RESOURCES[_ctx.resource].unit} per day`,
              required: true
            }, null, 8, ["modelValue", "label", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_TextInput, {
              id: "oneDaySlaMultiplier",
              modelValue: _ctx.fields.oneDaySlaMultiplier,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fields.oneDaySlaMultiplier) = $event)),
              label: 
              `one day sla - ${_ctx.RESOURCES[_ctx.resource].unit} multiplier`.toUpperCase()
            ,
              placeholder: `${_ctx.RESOURCES[_ctx.resource].unit} multiplier`,
              required: true
            }, null, 8, ["modelValue", "label", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Dropdown, {
              "model-value": _ctx.fields.days,
              options: _ctx.daysApplyingOptions,
              label: `Apply starting`.toUpperCase(),
              "data-testid": "dd-days"
            }, null, 8, ["model-value", "options", "label"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_TextInput, {
              id: "monthly",
              modelValue: _ctx.fields.perMonth,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fields.perMonth) = $event)),
              label: `${_ctx.RESOURCES[_ctx.resource].unit} per month`.toUpperCase(),
              placeholder: `${_ctx.RESOURCES[_ctx.resource].unit} per month`,
              required: true
            }, null, 8, ["modelValue", "label", "placeholder"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}