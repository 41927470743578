<template>
  <div style="margin: 0 2rem">
    <H3>This permutation is not currently enabled for your account.</H3>
    <P>
      To turn on this permutation, please navigate to
      <router-link to="/partners/current/onboarding/print-capabilities">
        Print Capabilities
      </router-link>
      page
    </P>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MissingPermutation'
})
</script>

<style lang="scss" scoped></style>
