<script lang="ts">
import { computed, defineComponent, onUnmounted, PropType, ref } from 'vue'
import InventoryCardEip from './InventoryCardEip.vue'
import LogsIcon from './LogsIcon.vue'
import { MaterielInventory } from './types'
import { formatCurrency, formatDate } from '@/utils/formatters'
import { MATERIEL_INVENTORY_STATUS } from '@/consts'
import { getEditInventoryFlag } from '@/store/launchDarkly'

export default defineComponent({
  name: 'InventoryCard',
  components: { InventoryCardEip, LogsIcon },
  props: {
    inventory: {
      type: Object as PropType<MaterielInventory>,
      required: true
    },
    disabled: { type: Boolean, required: true },
    deletable: {
      type: Boolean,
      required: true
    },
    updateError: { type: String, default: null }
  },
  emits: ['deleted', 'showTransfer', 'updated'],
  setup(props) {
    const isDeletedActive = ref(false)
    const allowTransfer = computed(
      () => props.inventory.status === MATERIEL_INVENTORY_STATUS.DELIVERED
    )
    const editInventoryFlag = computed(() => getEditInventoryFlag())
    let timeout

    const activateAndRestoreAfter = () => {
      isDeletedActive.value = true
      timeout = setTimeout(() => {
        isDeletedActive.value = false
      }, 2000)
    }

    onUnmounted(() => {
      clearTimeout(timeout)
    })

    return {
      allowTransfer,
      editInventoryFlag,
      formatCurrency,
      formatDate,
      materielInventory: props.inventory,
      isDeletedActive,
      activateAndRestoreAfter
    }
  }
})
</script>

<template>
  <div class="bg-white shadow-md p-4 relative rounded">
    <div class="h-full w-1 bg-gray-100 absolute top-0 bottom-0 left-0"></div>
    <div class="flex items-center mb-2">
      <h4
        class="text-base p-0 m-0 text-gray-500 font-normal flex-grow-1 flex-shrink-1"
      >
        {{ inventory.partner_name }}
      </h4>
      <lob-button
        v-if="allowTransfer && editInventoryFlag"
        class="px-2 py-1 mr-3 small"
        variant="secondary"
        small
        @click="$emit('showTransfer', materielInventory)"
      >
        Transfer Inventory
      </lob-button>
      <LogsIcon :query="{ partner_id: inventory.partner_id }" />
      <lob-button
        v-if="isDeletedActive === false && deletable && editInventoryFlag"
        variant="none"
        size="small"
        class="p-1 border-0 mr-2 ml-3"
        data-testid="delete-button"
        @click="activateAndRestoreAfter"
      >
        <Trash class="w-4 h-4 text-gray-100" />
      </lob-button>
      <lob-button
        v-if="isDeletedActive === true && deletable"
        variant="none"
        size="small"
        class="mr-2 text-xs text-error ml-2"
        data-testid="confirm-button"
        @click="$emit('deleted', { id: inventory.id })"
      >
        Delete Printer
        <Trash class="w-4 h-4 text-error" />
      </lob-button>
    </div>
    <InventoryCardEip
      v-if="inventory.status"
      label="status"
      edit-property="status"
      :edit-materiel-inventory-id="inventory.id"
      :value="inventory.status"
      :can-edit="!disabled && editInventoryFlag"
      :update-error="updateError"
      @updated="$emit('updated', $event)"
    />
    <InventoryCardEip
      label="Quantity"
      :value="inventory.num_remaining"
      edit-property="num_remaining"
      :edit-materiel-inventory-id="inventory.id"
      :can-edit="!disabled && editInventoryFlag"
      :update-error="updateError"
      @updated="$emit('updated', $event)"
    />
    <InventoryCardEip
      label="Total cost"
      edit-property="total_cost"
      :edit-materiel-inventory-id="inventory.id"
      :value="inventory.total_cost"
      :can-edit="!disabled && editInventoryFlag"
      :update-error="updateError"
      @updated="$emit('updated', $event)"
    />
    <InventoryCardEip
      label="Unit Cost"
      :value="formatCurrency(inventory.total_cost / inventory.initial_count)"
      :can-edit="false"
    />
  </div>
</template>
