import { RouteRecordRaw } from 'vue-router'
// import View from './View.vue'
import TestSends from './TestSends.vue'
import TestCases from './TestCases.vue'
import TestGroups from './TestGroups.vue'
import View from './View.vue'
import { ShowChildRoutes } from '@/components/global'

export default {
  path: 'testing',
  component: ShowChildRoutes,
  children: [
    {
      path: '',
      component: View
    },
    {
      path: 'test_sends',
      component: TestSends
    },
    {
      path: 'test_cases',
      component: TestCases
    },
    {
      path: 'test_groups',
      component: TestGroups
    }
  ],
  meta: {
    isStartingCrumb: true,
    displayName: 'Custom Mail'
  }
} as RouteRecordRaw
