<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="cancel" />
    </div>
    <h2 class="text-center">Accept Order</h2>
    <div class="w-100 text-center mb-3">
      This order has {{ data.quantity_ordered }} items associate with the
      following printers.
    </div>
    <div id="orderTableContainer">
      <div id="header" class="flex border-b-2 border-grayDove">
        <div class="w-6/12">Print Partner</div>
        <div class="w-5/12">Quantity</div>
        <div class="w-1/12"></div>
      </div>
      <div
        v-for="(info, index) in orderInfo"
        id="row"
        :key="info.id"
        class="flex mt-2"
      >
        <div class="w-6/12">
          <Dropdown
            id="searchBy"
            v-model="info.partner"
            label=""
            data-testid="orderInfo"
            class="mr-3"
            size="small"
            placeholder="Choose a partner"
            :options="partnerList"
            :required="false"
          />
        </div>
        <div class="w-5/12">
          <TextInput
            id="name"
            v-model="info.quantity"
            label=""
            size="small"
            placeholder="Enter a quantity"
          />
        </div>
        <div class="w-1/12 mt-2 flex justify-center cursor-pointer">
          <CircleXMark @click="deletePartner(index)" />
        </div>
      </div>
    </div>
    <div class="flex justify-start mt-2">
      <LobButton
        data-testId="submit-search-button"
        variant="secondary"
        size="small"
        @click="addPartner"
      >
        + Add another print partner
      </LobButton>
    </div>
    <div class="flex justify-end mt-2">
      <LobButton class="mr-3" variant="secondary" @click="cancel">
        Cancel
      </LobButton>
      <!-- confirm === cancel until endpoints are made -->
      <LobButton :disabled="confirmDisabled" @click="cancel">Confirm</LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { PARTNER_STATUS } from '@/consts'
import { defineComponent } from 'vue'
import { getPartnersByStatus } from '@/store/partners'
import CircleXMark from '@/components/common/icons/CircleXMark.vue'

export default defineComponent({
  name: 'AcceptRejectInventoryModal',
  components: { CircleXMark },
  props: {
    isOpen: { type: Boolean, required: true },
    data: { type: Object, required: true }
  },
  emits: ['close'],
  data() {
    return {
      orderInfo: [{ partner: { label: '', value: '' }, quantity: '' }]
    }
  },
  computed: {
    partnerList() {
      const partners = getPartnersByStatus(PARTNER_STATUS.LIVE)().map(
        (partner) => ({
          label: partner.display_name,
          value: partner.id
        })
      )
      return partners
    },
    confirmDisabled(): boolean {
      const itemsNotComplete =
        this.orderInfo.findIndex(
          (info) => info.partner.label === '' || info.quantity === ''
        ) !== -1

      const quantitiesNotMatching =
        this.orderInfo.reduce(
          (prev, currInfo) => prev + parseInt(currInfo.quantity),
          0
        ) !== this.data.quantity_ordered

      return itemsNotComplete || quantitiesNotMatching
    }
  },
  methods: {
    addPartner() {
      this.orderInfo.push({ partner: { label: '', value: '' }, quantity: '' })
    },
    deletePartner(index: number) {
      this.orderInfo.splice(index, 1)
    },
    cancel() {
      this.$emit('close')
    }
  }
})
</script>
