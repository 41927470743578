<template>
  <div class="content p-5">
    <PortalBreadCrumb />
    <h2 class="text-3xl mb-8 font-bold">
      {{
        partner &&
        `${partner.display_name} (Location ${partnerFacility.zipcode}) Capabilities`
      }}
    </h2>
    <Card>
      <div class="flex flex-row text-xl p-2 pt-4 pb-4">
        Capabilities
        <Tooltip position="right">
          <template #trigger>
            <Info class="ml-1 w-5 h-5" />
          </template>
          <template #content>
            <div class="w-max">
              Types of mail our print partners are capable of producing (e.g.,
              postcards, letters, checks).
            </div>
          </template>
        </Tooltip>
        <div class="flex-1" />
      </div>
      <ProductsTable
        :partner-id="partnerId"
        :partner-facility-id="partnerFacilityId"
      />
    </Card>
    <Card>
      <div class="flex flex-row text-xl p-2 pt-4 pb-4">
        Ability Exclusions (Read Only)
        <Tooltip position="right">
          <template #trigger>
            <Info class="ml-1 w-5 h-5" />
          </template>
          <template #content>
            <div class="w-max">
              Ability exclusions are groups of abilities that a partner facility
              is not capable of when they're part of the same order.
            </div>
          </template>
        </Tooltip>
        <div class="flex-1" />
      </div>
      <AbilityExclusionsTable
        :partner-id="partnerId"
        :partner-facility-id="partnerFacilityId"
      />
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import ProductsTable from './ProductsTable.vue'
import AbilityExclusionsTable from './AbilityExclusionsTable.vue'
import PortalBreadCrumb from '../../../../../components/common/PortalBreadcrumb.vue'
import {
  Partner,
  PartnerFacility,
  getAllPartners,
  getAllPartnerFacilities,
  refreshAllAbilities,
  refreshAllPartnerFacilities,
  refreshAllPartners
} from '@/store/partners'

export default defineComponent({
  name: 'ProductsList',
  components: {
    ProductsTable,
    AbilityExclusionsTable,
    PortalBreadCrumb
  },
  props: {
    partnerId: {
      type: String,
      required: true
    },
    partnerFacilityId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      partner: null as Partner | null,
      partnerFacility: null as PartnerFacility | null
    }
  },
  async created() {
    await Promise.all([
      refreshAllAbilities(),
      refreshAllPartners,
      refreshAllPartnerFacilities()
    ])

    const allPartners = getAllPartners()
    const allPartnerFacilities = getAllPartnerFacilities()
    const [partner] = allPartners.filter((p) => p.id === this.partnerId)
    const [partnerFacility] = allPartnerFacilities[this.partnerId]?.filter(
      (pf) => pf.id === this.partnerFacilityId
    )

    this.partner = partner
    this.partnerFacility = partnerFacility
  }
})
</script>
<style lang="scss" scoped></style>
