import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-mist grid grid-cols-6 justify-items-center shadow-md shadow-black" }
const _hoisted_2 = { class: "w-100 p-2" }
const _hoisted_3 = { class: "w-100 p-2" }
const _hoisted_4 = { class: "w-100 p-2" }
const _hoisted_5 = { class: "w-100 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.ability?.name), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.ability?.order_field_name), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.ability?.operator), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(JSON.stringify(_ctx.ability?.order_field_value)), 1)
  ]))
}