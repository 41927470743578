export function deepCopy<T>(thingToCopy: T): T {
  return JSON.parse(JSON.stringify(thingToCopy))
}

export type CamelToSnakeCase<S extends string> =
  S extends `${infer T}${infer U}`
    ? `${T extends Capitalize<T>
        ? '_'
        : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
    : S

// eslint-disable-next-line @typescript-eslint/ban-types
export type CamelToSnakeCaseNested<T> = T extends object
  ? {
      [K in keyof T as CamelToSnakeCase<K & string>]: CamelToSnakeCaseNested<
        T[K]
      >
    }
  : T

const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const snakeToCamelCase = (str: string): string => {
  return str
    .replaceAll(/[-_][a-z]/gi, (letter) => `${letter.toUpperCase()}`)
    .replaceAll('_', '')
}

export function camelToSnakeCaseProperties<T>(
  object: T
): Record<string, unknown> | T {
  if (!object) return object
  return Object.keys(object).reduce((acc, k) => {
    const value =
      typeof object[k] === 'object' && !(object[k] instanceof Date)
        ? camelToSnakeCaseProperties(object[k])
        : object[k]
    acc[camelToSnakeCase(k)] = value
    return acc
  }, {})
}

export function snakeCaseToCamelProperties<T, Z>(object: T): Z | T {
  if (!object) return object
  return Object.keys(object).reduce((acc, k) => {
    const value =
      typeof object[k] === 'object' && !(object[k] instanceof Date)
        ? snakeCaseToCamelProperties(object[k])
        : object[k]
    acc[snakeToCamelCase(k)] = value
    return acc
  }, {}) as Z
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isEmpty<T extends object>(obj: unknown): boolean {
  if (obj === null || obj === undefined) return true
  return Object.keys(obj as T).length === 0
}
