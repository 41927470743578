import { getCurrentPartnerId } from '../partners'
import { sendPapiRequest } from '../requests'
import { getUser } from '../session'
// import { getUser } from '../session'
import { defineVuexModule } from '../util'
import { registerWatcher } from '../watcherRegistry'

export interface TestCase {
  id: string
  deleted: boolean
  description: string
  resource: string
}

interface State {
  testCases: Array<TestCase>
}

const initialState: State = {
  testCases: []
}

const storeModule = defineVuexModule('testing', initialState)

// GETTERS (exported functions that provide pieces of data from the state)

export const getTestCases = storeModule.read(
  (state) => state.testCases,
  'getTestCases'
)

// MUTATORS (synchronous functions that change the state, each call to a mutator
// is logged in the Vue development tools)

const setTestCases = storeModule.commit((state, testCases: Array<TestCase>) => {
  state.testCases = testCases
}, 'setTestCases')

// ACTIONS (asynchronous functions which can call other actions as well as
// getters and setters, also logged in the Vue development tools)

interface TestCaseResponse {
  Payload: {
    count: number
    data: Array<TestCase>
  }
}

export const refreshTestCases = storeModule.dispatch(async () => {
  const response = await sendPapiRequest<TestCaseResponse>({
    method: 'GET',
    path: '/tests/cases'
  })
  if (response.status === 200) {
    setTestCases(response.data?.Payload?.data)
  }
}, 'refreshTestCases')

export const _requestTests = async (
  _: unknown,
  params: {
    date: Date
    testSets: { resource: string; count: number; id: string }[]
  }
): Promise<void> => {
  const { date, testSets } = params
  const resource = testSets[0].resource

  const testCases = {}
  for (const ts of testSets) {
    testCases[ts.id] = ts.count
  }

  const data = {
    date_scheduled: date.toISOString(),
    description: 'new end to end test',
    partner: getCurrentPartnerId(),
    resource,
    type: 'e2e',
    test_cases: testCases,
    user_email: getUser()?.email || 'partner-managers@lob.com'
  }
  const response = await sendPapiRequest<TestCaseResponse>({
    method: 'POST',
    path: '/tests',
    data
  })
  if (response.status !== 200) {
    throw Error('Error occurred when requesting E2E testing.')
  }
}

export const requestTests = storeModule.dispatch(_requestTests, 'requestTests')
// WATCHERS (functions that are called when a value in the store changes,
// possibly one from a different module.)

registerWatcher(
  () => getCurrentPartnerId(),
  () => {
    setTestCases([])
    refreshTestCases()
  }
)
