<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="list-field-editor-root mb-3">
    <FieldHeader
      :schema="schema"
      :field="field"
      :test-set="testSet"
      show-add-button
      @update:testSet="$emit('update:testSet', $event)"
      @add="handleAdd"
    />
    <div v-if="showAddHandlers" class="grid grid-cols-3 gap-2">
      <LobButton
        v-for="(addHandler, index) in addHandlers"
        :key="addHandler.label"
        variant="tertiary"
        @click="handleAddIndex(index)"
      >
        {{ addHandler.label }}
      </LobButton>
    </div>
    <div class="content">
      <button class="add-item-card" aria-label="Add element" @click="handleAdd">
        <fa icon="plus" />
      </button>

      <div v-for="(value, index) in getFields()" :key="index" class="item">
        <div class="item-card">
          <component
            :is="editorComponent"
            v-if="index === focused || editOnly"
            :schema="schema"
            :model-value="value"
            @update:modelValue="handleValueChange(index, $event)"
          />
          <component
            :is="displayComponent"
            v-else
            :schema="schema"
            :value="value"
          />
        </div>
        <div class="action-list">
          <button
            v-if="index !== focused && !editOnly"
            title="Edit"
            @click="focused = index"
          >
            <fa icon="edit" />
          </button>
          <button v-else-if="!editOnly" title="Confirm" @click="focused = null">
            <fa icon="check" />
          </button>
          <button title="Duplicate" @click="handleDuplicate(index)">
            <fa icon="copy" />
          </button>
          <button title="Delete" @click="handleDelete(index)">
            <fa icon="trash" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TestSet } from '@/store/testSets'
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'
import { AddHandler } from './util'
import FieldHeader from './FieldHeader.vue'
import { getShowBetaFeatureFlag } from '@/store/launchDarkly'
import { FORM_FACTOR_TYPES } from '@/consts'

export default defineComponent({
  components: { FieldHeader },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    displayComponent: {
      type: Object,
      required: true
    },
    editorComponent: {
      type: Object,
      required: true
    },
    // Multiple add handlers can be used to allow the user to pick between
    // multiple different templates for a new value.
    addHandlers: {
      type: Array,
      required: true
    } as Prop<Array<AddHandler>>,
    editOnly: {
      type: Boolean,
      default: false
    },
    ffCurrentValues: {
      type: Map,
      default: () => {
        return {}
      }
    }
  },
  emits: ['update:testSet', 'update:formFactor'],
  data() {
    return {
      showAddHandlers: false,
      focused: null,
      showBetaFeatureFlag: getShowBetaFeatureFlag()
    }
  },
  methods: {
    getFormFactor() {
      return this.ffCurrentValues?.get('formFactor')
    },
    handleAdd() {
      if (this.addHandlers === undefined)
        throw new Error('property add-handlers is required')
      if (this.addHandlers.length === 1) {
        this.handleAddIndex(0)
      } else if (this.showBetaFeatureFlag) {
        this.handleAddIndex(
          this.filterFormFactor(this.ffCurrentValues?.get('formFactor'))
        )
      } else {
        this.showAddHandlers = true
      }
    },
    filterFormFactor(factorType) {
      switch (factorType) {
        case FORM_FACTOR_TYPES.CHECKS:
          return 0
        case FORM_FACTOR_TYPES.LETTERS:
          return 1
        case FORM_FACTOR_TYPES.POSTCARDS:
          return 2
        case FORM_FACTOR_TYPES.SELF_MAILERS:
          return 3
      }
    },
    handleAddIndex(handlerIndex) {
      if (!this.testSet || !this.addHandlers) return // These props are required by Vue.
      if (this.showBetaFeatureFlag && this.addHandlers.length !== 1)
        handlerIndex = this.filterFormFactor(
          this.ffCurrentValues?.get('formFactor')
        )
      const newValues = this.addHandlers[handlerIndex].create()
      let existingValues
      if (this.showBetaFeatureFlag) {
        existingValues = this.ffCurrentValues?.get(this.field.id)
          ? (this.ffCurrentValues?.get(this.field.id) as string[])
          : []
      } else {
        existingValues = this.testSet.fields[this.field.id]
      }

      this.handleFieldChange(existingValues.concat(newValues))
      this.showAddHandlers = false
    },
    handleDuplicate(index) {
      if (this.testSet === undefined)
        throw new Error('property test-set is required')
      let values
      if (this.showBetaFeatureFlag) {
        const existingValues = this.ffCurrentValues?.get(
          this.field.id
        ) as string[]
        values = [...existingValues]
      } else {
        values = [...this.testSet.fields[this.field.id]]
      }
      // This makes me cry but it works for primitives, objects, arrays, literally any data we could ever want.
      const duplicated = JSON.parse(JSON.stringify(values[index]))
      const newValues = values
      // Insert [duplicated] after the original item.
      newValues.splice(index + 1, 0, duplicated)
      this.handleFieldChange(newValues)
    },
    handleDelete(index) {
      if (this.testSet === undefined)
        throw new Error('property test-set is required')
      if (this.field === undefined)
        throw new Error('property field is required')
      // Copy existing values...
      let newValues
      if (this.showBetaFeatureFlag) {
        const existingValues = this.ffCurrentValues?.get(
          this.field.id
        ) as string[]
        newValues = [...existingValues]
      } else {
        newValues = [...this.testSet.fields[this.field.id]]
      }
      // ...and remove the value at the requested index.
      newValues.splice(index, 1)
      this.handleFieldChange(newValues)
    },
    handleValueChange(index, newValue) {
      if (this.testSet === undefined)
        throw new Error('property test-set is required')
      let copy
      if (this.showBetaFeatureFlag) {
        copy = this.ffCurrentValues?.get(this.field.id) as any[]
      } else {
        copy = [...this.testSet.fields[this.field.id]]
      }
      copy[index] = newValue
      this.handleFieldChange(copy)
    },
    handleFieldChange(newValues) {
      if (this.testSet === undefined)
        throw new Error('property test-set is required')
      if (this.showBetaFeatureFlag) {
        this.$emit('update:formFactor', {
          id: this.field.id,
          value: newValues,
          type: 'list'
        })
      } else {
        this.$emit('update:testSet', {
          ...this.testSet,
          fields: {
            ...this.testSet.fields,
            [this.field.id]: newValues
          }
        })
      }
    },
    getFields() {
      if (this.showBetaFeatureFlag) {
        return this.ffCurrentValues?.get(this.field.id) as any[]
      } else {
        return this.testSet?.fields[this.field.id]
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.5rem;
  margin: 0.5rem 0;
}
.action-list {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
}
.item-card {
  background: rgba(black, 0.05);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  max-width: 100%;
  overflow: auto;
}
.add-item-card {
  border: dashed 2px rgba(black, 0.1);
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  width: 100%;
}
</style>
