<template>
  <div id="container" class="relative">
    <label>
      {{ label }}
    </label>
    <div id="searchBar" class="border-2 rounded border-grayDove">
      <div class="flex align-middle">
        <Search class="w-5 h-5 mx-1.5 self-center" />
        <input
          id="myInput"
          :value="searchTerm"
          class="h-full w-full disabled:bg-offWhite bg-mi p-1.5 outline-none"
          type="text"
          placeholder="Search.."
          @input="handleSearch"
          @focus="handleFocus"
          @blur="handleBlur"
        />
      </div>
      <Badge
        v-for="(option, index) in selectedResults"
        :key="option.label || option"
        class="ml-1.5 my-1.5"
      >
        <div class="flex items-center">
          {{ option.label || option }}
          <fa
            class="cursor-pointer ml-2 -mr-1"
            icon="times"
            @click="handleOptionDeselect(index)"
          />
        </div>
      </Badge>
    </div>
    <Card
      v-if="isSearching && searchTerm.length > 0"
      id="results"
      class="p-0 mb-2 bg-white absolute w-full z-40"
    >
      <div
        v-if="isSearching && searchTerm.length > 0 && awaitingSearch"
        id="loading"
        class="self-center italic p-2"
      >
        Loading Results...
      </div>
      <div
        v-else-if="searchResults.length === 0"
        id="noResults"
        class="self-center font-bold p-2"
      >
        No Results.
      </div>
      <div v-else id="results">
        <div
          v-for="(result, index) in searchResults"
          :key="`search-${index}`"
          class="hover:bg-skyBlue cursor-pointer p-2"
          @click="handleSelect(result)"
        >
          <slot :result="result" />
        </div>
      </div>
    </Card>
  </div>
</template>

<script lang="ts">
import { SearchLabel } from '@/store/customMail'
import { defineComponent } from '@vue/runtime-core'
import { PropType } from 'vue'

export default defineComponent({
  name: 'SearchAndMultiSelect',
  props: {
    searchFunction: { type: Function, required: true },
    selectedItems: {
      type: Array as PropType<SearchLabel[]>,
      default: () => {
        return []
      }
    },
    label: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  emits: ['handleSearch', 'onListChange'],
  data() {
    return {
      searchTerm: '',
      isSearching: false,
      awaitingSearch: false,
      searchCheckTimer: undefined as any,
      searchResults: [],
      selectedResults: [] as SearchLabel[],
      resultChosen: false
    }
  },
  watch: {
    selectedItems: function (newVal) {
      this.selectedResults = newVal
      this.resultChosen = this.selectedResults.length > 0
    }
  },
  mounted() {
    if (this.selectedItems) {
      this.selectedResults = this.selectedItems
      this.resultChosen = this.selectedResults.length > 0
    }
  },
  methods: {
    handleFocus() {
      this.isSearching = true
    },
    handleBlur() {
      setTimeout(() => {
        this.isSearching = false
      }, 300)
    },
    handleSearch(e) {
      this.searchTerm = e.target.value
      this.awaitingSearch = true
      clearTimeout(this.searchCheckTimer)
      this.searchCheckTimer = setTimeout(() => {
        this.search(this.searchTerm)
      }, 300)
    },
    search(val) {
      this.searchResults = []
      this.searchFunction!(val)
        .then((results) => {
          this.searchResults = results
        })
        .finally(() => {
          this.awaitingSearch = false
        })
    },
    clearSearch() {
      this.searchTerm = ''
      this.searchResults = []
    },
    handleSelect(result) {
      if (!this.selectedResults.includes(result)) {
        this.selectedResults.push(result)
        this.resultChosen = true
        this.$emit('onListChange', this.selectedResults)
      }
    },
    handleOptionDeselect(index) {
      this.selectedResults.splice(index, 1)
      this.$emit('onListChange', this.selectedResults)
    }
  }
})
</script>
