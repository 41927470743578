<template>
  <div class="grid grid-cols-2 pl-4 pr-4">
    <Dropdown
      id="qualCategory"
      v-model="selectedCat"
      class="ml-1 mb-3 mt-2 pr-4"
      :options="qualCats"
      label="Quality Category:"
      placeholder="Select..."
      @update:modelValue="handleQualityCategory"
    />
    <Dropdown
      v-if="qualSubcats.length !== 0"
      id="qualSubcategory"
      v-model="selectedSubCat"
      class="ml-1 mb-3 mt-2"
      :options="qualSubcats"
      label="Quality Subcategory:"
      placeholder="Select..."
      @update:modelValue="handleQualitySubCategory"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { SLA_CONSTS } from '@/consts'

export default defineComponent({
  name: 'QualityFormField',
  props: {
    qualityCategory: {
      type: [String],
      default: ''
    },
    qualitySubCategory: {
      type: [String],
      default: ''
    },
    handleDropdownValue: {
      type: [Function],
      default: () => null
    }
  },
  emits: ['update:qualityCategory', 'update:qualitySubCategory'],
  data() {
    return {
      qualCats: SLA_CONSTS.qualityCategories,
      qualSubcats: [],
      selectedCat: '',
      selectedSubCat: ''
    }
  },
  methods: {
    handleQualityCategory(e: any) {
      const category = this.optionToSnakeCase(e)
      this.qualSubcats = e === 'N/A' ? [] : SLA_CONSTS[`quality_${category}`]
      this.$emit('update:qualityCategory', category)

      // if selected option is 'N/A', also make subcat = 'N/A' so isComplete = true on slaMissQuality component
      if (e === 'N/A') {
        this.handleDropdownValue({
          miss_category: category,
          miss_sub_category: 'N/A',
          quality_category: '',
          quality_sub_category: '',
          is_miss: true,
          has_quality_issue: false
        })
      } else {
        this.handleDropdownValue({
          quality_category: category,
          quality_sub_category: '',
          miss_category: '',
          miss_sub_category: '',
          has_quality_issue: true,
          is_miss: false
        })
      }
    },
    handleQualitySubCategory(e: any) {
      const subcategory = this.optionToSnakeCase(e)
      this.$emit('update:qualitySubCategory', subcategory)

      this.handleDropdownValue({
        quality_category: this.selectedCat,
        quality_sub_category: subcategory,
        miss_category: '',
        miss_sub_category: '',
        has_quality_issue: true,
        is_miss: false
      })
    },
    optionToSnakeCase(s: string) {
      return s
        .toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll('-', '_')
        .replaceAll('/', '_')
    }
  }
})
</script>

<style lang="scss" scoped></style>
