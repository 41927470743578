import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_FieldSetRow = _resolveComponent("FieldSetRow")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Caption = _resolveComponent("Caption")!
  const _component_LobFileUpload = _resolveComponent("LobFileUpload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FieldSetRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_Textarea, {
          id: "artwork",
          label: "Artwork",
          "model-value": _ctx.modelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
        }, null, 8, ["model-value"])
      ]),
      _: 1
    }),
    _createVNode(_component_FieldSetRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_Dropdown, {
          id: "existing",
          "model-value": "",
          options: _ctx.artworkOptions,
          label: "Use an existing artwork",
          placeholder: "Existing Artworks",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.value)))
        }, null, 8, ["options"])
      ]),
      _: 1
    }),
    _createVNode(_component_FieldSetRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_Caption, null, {
          default: _withCtx(() => [
            _createTextVNode("Or upload something new")
          ]),
          _: 1
        }),
        _createVNode(_component_LobFileUpload, {
          "do-try-upload": (f) => _ctx.tryUploadFile(f),
          onUploadSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onUploadSuccess($event)))
        }, null, 8, ["do-try-upload"])
      ]),
      _: 1
    })
  ], 64))
}