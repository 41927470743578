<template>
  <table :class="tableStyle">
    <thead>
      <tr class="w-100">
        <th
          v-for="(header, index) in headers"
          :key="header.name"
          :class="headerClass(header)"
          :colspan="header.span"
          @click="() => sortOnColumn(index, headers)"
        >
          <div class="flex">
            {{ header.name }} &nbsp;
            <fa
              v-if="header.sortStatus === 'ASC'"
              icon="arrow-up"
              class="mt-1"
            />
            <fa
              v-if="header.sortStatus === 'DESC'"
              icon="arrow-down"
              class="mt-1"
            />
            <div
              v-if="header.sortStatus === 'none' && header.sortable"
              class="font-bold"
            >
              -
            </div>
          </div>
        </th>
        <!-- if rows are expandable, make room for expand icon -->
        <td v-if="rowExpandable" class="border-t-0 border-b border-gray-100" />
      </tr>
    </thead>
    <tbody v-if="data.length" class="table-body">
      <TableRow
        v-for="(rowData, index) in data"
        :key="rowData.id"
        :table-i-d="id"
        :variant="variant"
        :row-object="rowObject"
        :data="{ ...rowData, index }"
        :expandable="rowExpandable"
        :secondary-row-color="index % 2 ? secondaryRowColor : ''"
        :compact="compact"
        :with-border="withBorder"
        @updateRow="(e) => updateRows(e, index, data)"
        @handleValueChange="handleValueChange"
      >
        <template #expandedContent>
          <slot :index="index" name="expandedContent"></slot>
        </template>
      </TableRow>
    </tbody>
  </table>
  <Pagination
    v-if="pagination"
    class="mt-3"
    :collection="data"
    :page="currentPage"
    :limit="pageLimit"
    :total="paginationTotal"
    @change="changePage"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TableRow from './TableRow.vue'
import { SORTED_STATUS } from '@/consts'

export default defineComponent({
  name: 'Table',
  components: {
    TableRow
  },
  props: {
    id: {
      type: String,
      default: () => {
        return ''
      }
    },
    compact: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    withBorder: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    headers: {
      type: Array,
      default: () => {
        return []
      }
    },
    rowObject: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    rowExpandable: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    pagination: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    variant: {
      type: String,
      default: () => {
        return 'primary'
      }
    },
    secondaryRowColor: {
      type: String,
      default: () => {
        return ''
      }
    },
    dataLength: {
      type: Number,
      default: () => {
        return 0
      }
    },
    currentPage: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },
  emits: [
    'handleValueChange',
    ' updateSortedHeaders',
    'updateTableData',
    'updatePage',
    'updateSortedHeaders'
  ],
  data() {
    return {
      sortedOn: '',
      pageLimit: 10
    }
  },
  computed: {
    paginationTotal() {
      return this.dataLength ?? this.data.length
    },
    tableStyle() {
      let baseClass = 'row-space min-w-fit bg-white'
      if (this.variant === 'secondary')
        baseClass = baseClass.concat(' border-solid border-1 border-borderGray')
      if (this.compact) {
        baseClass = baseClass.concat('w-25')
      } else {
        baseClass = baseClass.concat(' w-100')
      }
      return baseClass
    }
  },
  methods: {
    headerClass(header) {
      let baseClass =
        'border-t-0 text-gray-900 text-base font-medium border-b border-gray-100 pl-3'
      if (header.sortable) baseClass = baseClass.concat(' cursor-pointer')
      if (this.variant === 'secondary') {
        baseClass = baseClass.replace('font-medium', 'font-light')
        baseClass = baseClass.replace('text-gray-900', 'text-titleBlue')
      }
      if (header.width) {
        baseClass = baseClass.concat(` ${header.width}`)
      }
      if (!this.compact) baseClass = baseClass.concat(' p-3')
      return baseClass
    },
    sortOnColumn(headerIndex: string, headers: any[]) {
      const headerToSort = headers[headerIndex]
      if (!headerToSort.sortable) return
      if (this.sortedOn !== headerToSort.name) {
        // clear sort on other headers
        headers.map((header) => {
          header.sortStatus = SORTED_STATUS.NONE
        })
        this.sortedOn = ''
      }
      // flip through sort of current header
      switch (headerToSort.sortStatus) {
        case SORTED_STATUS.NONE:
          headers[headerIndex].sortStatus = SORTED_STATUS.ASCENDING
          this.sortedOn = headerToSort.name
          break
        case SORTED_STATUS.ASCENDING:
          headers[headerIndex].sortStatus = SORTED_STATUS.DESCENDING
          break
        case SORTED_STATUS.DESCENDING:
          headers[headerIndex].sortStatus = SORTED_STATUS.NONE
          this.sortedOn = ''
          break
      }
      this.$emit('updateSortedHeaders', headers)
    },
    updateRows(updatedRow, index, rows) {
      rows[index] = updatedRow
      this.$emit('updateTableData', {
        rows,
        updatedRow
      })
    },
    changePage(e) {
      this.$emit('updatePage', e.page)
    },
    handleValueChange(e) {
      this.$emit('handleValueChange', e)
    }
  }
})
/* 
    example usage - what the props coming in table should look like:
    tableHeaders: [
        { name: 'Status', span: 1, sortable: true, sortStatus: 'none' },
        { name: 'Partners', span: 1 },
        { name: 'Resource', span: 1 },
        { name: 'Test Type', span: 1 },
        { name: 'Quantity', span: 1 },
        { name: 'Tests', span: 1 }
      ],
      tableRowObject: [
        {
          fieldName: 'status',
          type: 'badge',
          calculateVariant: (fieldStatus) => {
            if (fieldStatus === 'completed') {
              return 'info'
            } else {
              return 'error'
            }
          }
        },
        {
          fieldName: 'partners',
          type: 'string',
        },
        {
          fieldName: 'resource',
          type: 'string',
        },
        {
          fieldName: 'type',
          type: 'string'
        },
        {
          fieldName: 'quantity',
          type: 'string',
        },
        {
          children: [
            {
              fieldName: 'tests',
              type: 'button',
              buttonText: 'Download CSV',
              variant: 'primary',
              onClick: (e, data) => {
                console.log('button pressed', e, data)
              }
            },
            {
              fieldName: 'tests',
              type: 'button',
              buttonText: 'Send CSV',
              icon: 'link',
              variant: 'primary',
              onClick: () => {
                console.log('button pressed')
              }
            },
          ]
        },
      ],
  */
</script>
