import { RouteRecordRaw } from 'vue-router'
import resendPartnerUserInvite from './resendPartnerUserInvite'
import invitePartnerUser from './invitePartnerUser'
import View from './View.vue'

export default {
  path: 'invite_partner_user',
  component: View,
  children: [resendPartnerUserInvite, invitePartnerUser]
} as RouteRecordRaw
