import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LobFileUpload = _resolveComponent("LobFileUpload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (url) => {
        return (_openBlock(), _createElementBlock("li", { key: url }, [
          _createElementVNode("a", { href: url }, _toDisplayString(_ctx.filenameOf(url)), 9, _hoisted_1)
        ]))
      }), 128))
    ]),
    _createVNode(_component_LobFileUpload, {
      "do-try-upload": _ctx.tryUploadFile,
      onUploadSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUploadSuccess($event)))
    }, null, 8, ["do-try-upload"])
  ], 64))
}