<template>
  <div class="flex">
    <div class="text-2xl mb-10">Attributes Information</div>
    <Tooltip position="right">
      <template #trigger>
        <Info class="ml-1 w-5 h-5" />
      </template>
      <template #content>
        {{ attributesInformationTooltip }}
      </template>
    </Tooltip>
    <div class="flex-1" />
    <PackageButton
      v-if="isAdminOrPopsUser"
      class="h-12"
      @click="moveToAttributesPage"
    >
      Global Attributes
    </PackageButton>
  </div>
  <div class="w-1/2">
    <div class="flex flex-row p-3">
      <div class="w-6/12">Attribute</div>
      <div class="w-5/12">Value</div>
      <div class="flex-1" />
      <Plus
        v-if="isAdminOrPopsUser"
        class="ml-1 opacity-40 w-6 h-6 cursor-pointer"
        @click="$emit('addAttribute')"
      />
    </div>
    <div v-for="(attribute, index) in data" :key="attribute.id">
      <div
        v-if="!attribute.isEditing && !attribute.deleted"
        class="flex flex-row bg-mist mt-1.5 p-3 shadow-md w-100 pb-10"
      >
        <div class="w-6/12">{{ attribute.label }}</div>
        <div class="w-5/12">{{ attribute.value }}</div>
        <div class="flex-1" />
        <Edit
          v-if="isAdminOrPopsUser"
          class="ml-1 opacity-40 w-6 h-6 cursor-pointer"
          @click="attribute.isEditing = true"
        />
      </div>
      <div
        v-else-if="!attribute.deleted"
        class="flex flex-row items-center bg-mist mt-1.5 px-3 py-2 shadow-md w-100 pb-10"
      >
        <SearchAndSelect
          :search-function="filterAttributes"
          selected-item-display-property="label"
          :selected-item="attribute"
          class="w-6/12 mr-3"
          @onSelect="(e) => handleSelect(e, index)"
          @onDeselect="$emit('onDeselect', index)"
        >
          <template #default="{ result }">
            <td>{{ result.label }} ({{ result.name }})</td>
          </template>
        </SearchAndSelect>
        <PackageTextInput
          id="component-value"
          class="w-5/12 h-12 mt-2"
          :value="attribute.value"
          placeholder="Value"
          label=""
          @input="(e) => $emit('updateAttributeValue', { index, e })"
        />
        <div class="flex-1" />
        <Check
          class="ml-1 opacity-40 w-6 h-6 cursor-pointer"
          @click="() => $emit('saveAttribute', { index })"
        />
        <Trash
          class="ml-2 opacity-40 w-6 h-6 cursor-pointer"
          @click="() => $emit('disassociateAttributeFromComponent', { index })"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import router from '@/router'
import { getAttributes } from '@/store/customMail'
import { defineComponent } from '@vue/runtime-core'
import PackageButton from '../common/PackageButton.vue'
import PackageTextInput from '../common/PackageTextInput.vue'
import SearchAndSelect from '../common/SearchAndSelect.vue'

export default defineComponent({
  name: 'AttributesInformation',
  components: {
    PackageButton,
    PackageTextInput,
    SearchAndSelect
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isAdminOrPopsUser: { type: Boolean }
  },
  emits: [
    'addAttribute',
    'updateAttributeLabel',
    'updateAttributeValue',
    'saveAttribute',
    'disassociateAttributeFromComponent',
    'onDeselect'
  ],
  data() {
    return {
      headerTitle: '',
      attributesInformationTooltip:
        CUSTOM_MAIL_CONSTS.ATTRIBUTES_INFORMATION_TOOLTIP,
      attributes: [],
      filteredAttributes: []
    }
  },
  async mounted() {
    const attributesList = await getAttributes()
    this.attributes = attributesList.data
  },
  methods: {
    moveToAttributesPage() {
      const currentRoute = this.$route.path
      router.push(`${currentRoute}/attributes`)
    },
    async filterAttributes(term) {
      return this.attributes.filter((attribute: any) =>
        attribute.label.toLowerCase().includes(term.toLowerCase())
      )
    },
    handleSelect(e, index: number) {
      this.$emit('updateAttributeLabel', {
        attribute: {
          ...e,
          isEditing: true
        },
        index
      })
    }
  }
})
</script>

<style lang="scss" scoped></style>
