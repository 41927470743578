<template>
  <component
    :is="editorComponent"
    :schema="schema"
    :field="field"
    :test-set="testSet"
    :ff-current-values="ffCurrentValues"
    @update:testSet="$emit('update:testSet', $event)"
    @update:formFactor="$emit('update:formFactor', $event)"
  />
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import AddressMVE from './addresses/AddressMVE.vue'
import ResourceMVE from './resources/ResourceMVE.vue'
import CheckboxMVE from './CheckboxMVE.vue'
import FormFactorMVE from './FormFactorMVE.vue'
import UnimplementedMVE from './UnimplementedMVE.vue'
import MaybeDateMVE from './maybe_date/MaybeDateMVE.vue'
import StringOrBooleanMVE from './string_or_boolean/StringOrBooleanMVE.vue'
import StringMVE from './string/StringMVE.vue'
import CardsMVE from './cards/CardsMVE.vue'
import MaybeStringMVE from './maybe_string/MaybeStringMVE.vue'
import { getShowBetaFeatureFlag } from '@/store/launchDarkly'
import MultiselectMVEVue from './MultiselectMVE.vue'

export default defineComponent({
  name: 'MultiValueEditor',
  props: {
    schema: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    },
    ffCurrentValues: {
      type: Map,
      default: () => {
        return {}
      }
    }
  },
  emits: ['update:testSet', 'update:formFactor'],
  computed: {
    editorComponent() {
      if (Array.isArray(this.field.type)) {
        return CheckboxMVE
      } else if (this.field.type === 'form_factor') {
        return FormFactorMVE
      } else if (this.field.type === 'address') {
        return AddressMVE
      } else if (this.field.type === 'cards') {
        return CardsMVE
      } else if (this.field.type === 'maybe_date') {
        return MaybeDateMVE
      } else if (this.field.type === 'resource') {
        return ResourceMVE
      } else if (this.field.type === 'string') {
        return StringMVE
      } else if (this.field.type === 'string_or_boolean') {
        return StringOrBooleanMVE
      } else if (
        this.field.type === 'custom_envelope_type' ||
        this.field.type === 'maybe_string'
      ) {
        return MaybeStringMVE
      } else if (this.field.type === 'multi_string_type') {
        return MultiselectMVEVue
      } else {
        return UnimplementedMVE
      }
    },
    showBetaFeature() {
      return getShowBetaFeatureFlag()
    }
  }
})
</script>
