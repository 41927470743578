<script lang="ts">
import { defineComponent, ref, computed, watch, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { sendPapiRequest } from '@/store/requests'
import { Card, Envelope, Buckslip } from './types'
import EnvelopeRow from './EnvelopeRow.vue'
import CardRow from './CardRow.vue'
import BuckslipRow from './BuckslipRow.vue'
import Orders from './Orders.vue'
import { AxiosError } from 'axios'
import InventoryHeader from '../common/InventoryHeader.vue'

const columnsMap = {
  envelopes: [
    'Preview',
    'Env ID',
    'Design Type',
    'Size',
    'Available Inventory',
    'Pending Inventory',
    'Countries',
    'Description',
    'Auto Reorder',
    'Date Created'
  ],
  cards: [
    'Preview',
    'Card ID',
    'Size',
    'Description',
    'Auto Reorder',
    'Mode',
    'Date Created'
  ],
  buckslips: [
    'Preview',
    'Buckslip ID',
    'Size',
    'Description',
    'Auto Reorder',
    'Date Created'
  ]
}

type Resource = Card | Envelope | Buckslip

export default defineComponent({
  name: 'AccountOverview',
  components: { EnvelopeRow, CardRow, BuckslipRow, Orders, InventoryHeader },
  setup() {
    const route = useRoute()
    const expandedRows = reactive(new Set())
    const isLoading = ref(false)
    const items = ref<Resource[]>([])
    const errorMessage = ref('')
    const columns = computed(() => columnsMap[route.params.resource as string])

    const fetchData = async (resource?: string, accountCrid?: string) => {
      try {
        if (!accountCrid || !resource) return
        if (
          resource !== 'envelopes' &&
          resource !== 'cards' &&
          resource !== 'buckslips'
        ) {
          throw new Error('Only envelopes,cards, and buckslips are available')
        }

        expandedRows.clear()
        isLoading.value = true

        const {
          data: { data }
        } = await sendPapiRequest({
          path: `/inventory/accounts/${accountCrid}/${resource}`,
          method: 'GET'
        })
        items.value = data
        errorMessage.value = ''
      } catch (e) {
        errorMessage.value =
          (e as Error).message ||
          (e as AxiosError)?.response?.data?.error?.messsage
      } finally {
        const resourceId = route?.query?.resource_id as string
        if (resourceId) expandedRows.add(resourceId)
        isLoading.value = false
      }
    }

    const onRowExpand = (id: string) => {
      if (expandedRows.has(id)) expandedRows.delete(id)
      else expandedRows.add(id)
    }

    onMounted(() => {
      watch(
        route,
        ({ params }) =>
          fetchData(params?.resource as string, params?.crid as string),
        { immediate: true }
      )
    })

    return {
      expandedRows,
      errorMessage,
      columns,
      onRowExpand,
      isLoading,
      items,
      route
    }
  }
})
</script>

<template>
  <div class="content p-5">
    <InventoryHeader subject="Account" />

    <Alert v-if="errorMessage?.length" variant="error">
      {{ errorMessage }}
    </Alert>

    <div class="relative mt-1">
      <LoadingIndicator>
        <table v-if="!isLoading && columns?.length > 0" class="table">
          <thead>
            <tr>
              <th
                v-for="key in columns"
                :key="key"
                class="!border-t-0 text-gray-900 font-normal text-base"
              >
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in items" :key="item.id">
              <component
                :is="
                  route.params.resource === 'envelopes'
                    ? 'EnvelopeRow'
                    : route.params.resource === 'cards'
                    ? 'CardRow'
                    : 'BuckslipRow'
                "
                :item="item"
                :is-expanded="expandedRows.has(item.id)"
                @expand="onRowExpand"
              />
              <tr
                v-if="expandedRows.has(item.id)"
                :data-testid="`expanded-row-${item.id}`"
              >
                <td :colSpan="columns.length" class="p-0">
                  <Orders :id="item.id" :type="route.params.resource" />
                </td>
              </tr>
            </template>
            <tr v-if="!items || items?.length === 0">
              <td :colSpan="columns.length" class="text-lg text-center">
                No data available
              </td>
            </tr>
          </tbody>
        </table>
      </LoadingIndicator>
    </div>
  </div>
</template>
