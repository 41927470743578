<script lang="ts">
import Table from '@/components/common/table/Table.vue'
import { useRoutingPrediction } from './useRoutingPrediction'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'PredictRoute',
  components: { Table },
  setup() {
    const orderId = ref('')
    const { error, loading, routingPrediction, getRoutingPrediction } =
      useRoutingPrediction()
    return {
      error,
      loading,
      orderId,
      routingPrediction,
      getRoutingPrediction
    }
  }
})
</script>

<template>
  <div class="content p-5">
    <header class="mb-4">
      <h2>Predict Route</h2>
      <p>Analyze how a mail piece would be routed to a partner</p>
    </header>

    <div class="flex">
      <TextInput
        id="order-id-input"
        v-model="orderId"
        size="small"
        placeholder="Enter resource ID here"
        class="w-1/5 pr-4"
        label=""
      />
      <LobButton
        id="routing-prediction-submit"
        data-testId="routing-prediction-submit"
        :disabled="loading"
        variant="primary"
        size="small"
        @click="getRoutingPrediction(orderId)"
      >
        Predict Route
      </LobButton>
    </div>

    <LoadingIndicator>
      <template v-if="!loading">
        <div v-if="routingPrediction?.debugInfo.strategy_type" class="py-3">
          <h6 class="mb-2 font-medium">Summary:</h6>
          <p class="ml-2 mb-2">
            <span
              :class="
                routingPrediction.routingResult
                  ? 'font-bold text-success'
                  : 'font-bold text-error'
              "
            >
              {{ routingPrediction.order.id }}
            </span>

            <span v-if="routingPrediction.routingResult">
              will be routed to
              <span class="font-bold" data-testId="routing-result">
                {{ routingPrediction.routingResult }}
              </span>
              using the
              <span class="font-bold" data-testId="strategy-type">
                {{ routingPrediction.debugInfo.strategy_type }}
              </span>
              routing method
            </span>

            <span v-else data-testId="not-routed">
              &nbsp;was
              <span class="font-bold" data-testId="strategy-type">
                {{ routingPrediction.debugInfo.routing_status }}
              </span>
              using the
              <span class="font-bold" data-testId="strategy-type">
                {{ routingPrediction.debugInfo.strategy_type }}
              </span>
              routing method because
              <span class="font-bold" data-testId="strategy-type">
                "{{ routingPrediction.debugInfo.reason }}"
              </span>
            </span>
          </p>

          <h6 class="mb-2 font-medium">Matching Preference Rule:</h6>
          <p class="ml-2 mb-2">
            {{
              routingPrediction.debugInfo.matching_preference_rule?.note ||
              'N/A'
            }}
          </p>

          <h6 class="mb-2 font-medium">Details:</h6>
          <div class="grid gap-4 grid-cols-3">
            <div
              class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
            >
              <h6 class="font-bold mb-2">Capable Partners:</h6>
              <Table
                id="editTestCaseModal"
                :compact="true"
                :with-border="true"
                :headers="[{ name: 'Partners' }]"
                :row-object="[{ fieldName: 'partner', type: 'string' }]"
                :data="
                  routingPrediction.debugInfo.all_capable_partners?.map((p) => {
                    return { partner: p }
                  }) || []
                "
                :secondary-row-color="'bg-offWhite'"
              />
            </div>

            <div
              class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
            >
              <h6 class="font-bold mb-2">
                Preferred Partners (with capacities):
              </h6>
              <Table
                id="editTestCaseModal"
                :compact="true"
                :with-border="true"
                :headers="[
                  { name: 'Partners' },
                  { name: 'Weight' },
                  { name: 'Capacity' }
                ]"
                :row-object="[
                  { fieldName: 'partner', type: 'string' },
                  { fieldName: 'weight', type: 'number' },
                  { fieldName: 'capacity', type: 'number' }
                ]"
                :data="
                  routingPrediction?.debugInfo.matching_preference_rule
                    ?.yields || []
                "
                :secondary-row-color="'bg-offWhite'"
              />
            </div>

            <div
              class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
            >
              <h6 class="font-bold mb-2">Final Partners:</h6>
              <Table
                id="editTestCaseModal"
                :compact="true"
                :with-border="true"
                :headers="[
                  { name: 'Partners' },
                  { name: 'Weight' },
                  { name: 'Capacity' }
                ]"
                :row-object="[
                  { fieldName: 'partner', type: 'string' },
                  { fieldName: 'weight', type: 'number' },
                  { fieldName: 'capacity', type: 'number' }
                ]"
                :data="routingPrediction?.debugInfo.partner_capacity"
                :secondary-row-color="'bg-offWhite'"
              />
            </div>
          </div>

          <div class="grid gap-4 grid-cols-2">
            <div
              class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
            >
              <h6 class="font-bold mb-2">Debug Info:</h6>
              <pre id="debug-json">{{ routingPrediction?.debugInfo }}</pre>
            </div>
            <div
              class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
            >
              <h6 class="font-bold mb-2">Order Details:</h6>
              <pre id="order-json">{{ routingPrediction?.order }}</pre>
            </div>
          </div>
        </div>

        <div v-else class="py-3" data-testId="error-message">
          {{ error }}
        </div>
      </template>
    </LoadingIndicator>
  </div>
</template>
