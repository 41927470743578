<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { isAdminUser } from '@/store/session'
import { usePartners } from '@/composables/usePartners'
import { getCurrentPartnerId } from '@/store/partners'

export default defineComponent({
  name: 'AuditLogFilters',
  emits: ['search'],
  setup(props, { emit }) {
    const startDate = ref<string | null>(null)
    const startDateOpen = ref<boolean>(false)
    const endDate = ref<string | null>(null)
    const partnerOptions = usePartners()
    const currentPartner = computed(() => getCurrentPartnerId())

    const selectedPartner = ref<string | null>(currentPartner.value)
    const selectedPartnerObj = computed(() => {
      const partner = partnerOptions.value.find(
        (p) => p.id === selectedPartner.value
      )
      return partner ? partner.label : ''
    })

    const endDateOpen = ref<boolean>(false)
    const text = ref<string>('')
    const isAdmin = computed(isAdminUser)

    const changePartner = (partner) => {
      selectedPartner.value = partner.id
    }

    const search = () => {
      emit('search', {
        startDate: startDate.value,
        endDate: endDate.value,
        partnerId: selectedPartner.value,
        text: text.value
      })
    }

    return {
      startDate,
      startDateOpen,
      endDate,
      endDateOpen,
      isAdmin,
      partnerOptions,
      changePartner,
      selectedPartner,
      selectedPartnerObj,
      search,
      text
    }
  }
})
</script>

<template>
  <div class="grid grid-cols-2 xl:grid-cols-5 gap-4 items-end">
    <Dropdown
      v-if="isAdmin"
      id="partners-dropdown"
      :model-value="selectedPartnerObj"
      :options="partnerOptions"
      label="Partner"
      placeholder="Pick a partner"
      data-testid="dropdown"
      container-class="h-18"
      @update:modelValue="changePartner"
    />

    <TextInput id="search-term" v-model="text" label="Search terms">
      <template #iconLeft>
        <Search class="w-5 h-5 text-gray-700" />
      </template>
    </TextInput>

    <date-input
      id="startDate"
      v-model="startDate"
      v-model:open="startDateOpen"
      label="Start date"
    />

    <date-input
      id="endDate"
      v-model="endDate"
      v-model:open="endDateOpen"
      label="End date"
      :min="startDate"
    />

    <LobButton @click.stop="search">search</LobButton>
  </div>
</template>
