<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h4>Create New Test Group</h4>
    </div>
    <PackageTextInput
      id="test-case-group"
      v-model:value="name"
      label="Name: "
      size="small"
      placeholder="Input Name..."
    />
    <PackageTextInput
      id="description"
      v-model:value="testCaseDescription"
      label="Description:"
      input-class="w-full"
      placeholder="Add a description..."
    />
    <SearchAndMultiSelect
      id="test-case-name"
      label="Test Cases:"
      :selected-items="selectedCases"
      size="small"
      class="mb-1"
      :search-function="handleMultiSelectTestCaseSearch"
      @onListChange="handleMultiSelect"
    >
      <template #default="{ result }">
        <td>
          {{ result.label }}
        </td>
      </template>
    </SearchAndMultiSelect>
    <!-- <div class="flex">
      <div class="w-1/2">
        Name:
      </div>
      <div class="w-5/12 ml-1">
        Quantity:
      </div>
    </div> -->
    <!-- <div 
      v-for="testCase,index in selectedCases"
      :key="`case-${index}`"
      class="flex mt-2"
    >
      <SearchAndSelect
        class="w-1/2"
        :searchFunction="handleSelectSearch"
        :selectedItemDisplayProperty="'label'"
        :selectedItem="testCase"
        @onSelect="e => handleSelect(e, index)"
        @onDeselect="e => handleDeselect(index)"
      >
        <template
          #default="{ result }"
        >
          <td>
            {{ result.label }} 
          </td>
        </template>
      </SearchAndSelect>
      <PackageTextInput
        size="small"
        class="w-5/12 ml-2 h-12"
        input-class="w-full"
        placeholder="Add a quantity"
        :value="testCase.quantity"
        @update:value="e => handleUpdateQuantity(e, index)"
      />
      <div class="w-1/12 mt-3.5 flex justify-center cursor-pointer">
        <CircleXMark @click="removeTestCase(index)"/>
      </div>
    </div>

    <button class="border-2 cursor-pointer border-dashed rounded-md p-1 w-full border-grayShadow" 
      aria-label="Add element" 
      @click="addNewTestCase"
    >
        <fa icon="plus" />
    </button> -->

    <div class="flex items-end -mb-10 mt-3">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton @click="handleCreateTestGroup">Create</LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  createNewTestGroup,
  getTestCases,
  SearchLabel,
  TestCaseList
} from '@/store/customMail'
import PackageTextInput from '../../customMail/common/PackageTextInput.vue'
import SearchAndMultiSelect from '@/components/common/SearchAndMultiSelect.vue'

export default defineComponent({
  name: 'TestGroupModal',
  components: {
    PackageTextInput,
    SearchAndMultiSelect
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['close'],
  data() {
    return {
      selectedCases: [] as { label: string; value: string }[],
      casesList: [] as any[],
      testCaseDescription: '',
      name: ''
    }
  },
  async mounted() {
    const cases = (await getTestCases({})) as TestCaseList
    this.casesList = cases.data.map((tc) => {
      return {
        label: `${tc.id} : ${tc.name}`,
        value: tc.id
      }
    })
  },
  methods: {
    close() {
      this.$emit('close', {})
    },
    async handleMultiSelectTestCaseSearch(term) {
      return this.casesList.filter((tc: SearchLabel) => tc.value.includes(term))
    },
    handleMultiSelect(result) {
      this.selectedCases = result
    },
    // commenting out until decision is made whether to store quantites here or in test send
    // handleSelect(result, index) {
    //   this.selectedCases[index].label = result.label
    //   this.selectedCases[index].value = result.value
    // },
    // addNewTestCase() {
    //   this.selectedCases.push(
    //     { label: '', value: '', quantity: '' }
    //   )
    // },
    // removeTestCase(index){
    //   this.selectedCases.splice(index,1)
    // },
    // handleDeselect(index){
    //   this.selectedCases[index].label = ''
    //   this.selectedCases[index].value = ''
    // },
    // handleUpdateQuantity(result, index){
    //   this.selectedCases[index].quantity = result
    // },
    async handleCreateTestGroup() {
      const formattedCases = this.selectedCases.map((tc) => tc.value)
      await createNewTestGroup({
        name: this.name,
        description: this.testCaseDescription,
        test_cases: formattedCases
      })
      this.$emit('close', { refreshData: true })
    }
  }
})
</script>
