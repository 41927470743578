<template>
  <div class="flex flex-column">
    <div
      class="bg-mist shadow-md shadow-black rounded w-100 grid grid-cols-6 items-baseline justify-center p-2"
    >
      <div class="pl-3 col-span-4 content-baseline">
        {{ partner.partner_id }}
      </div>
      <div class="capitalize flex flex-row items-baseline">
        <toggle-button
          v-if="isAdminOrPopsUser"
          v-model="toggleStatus"
          :selected-div-id="partner"
          name=""
          label=""
          :disabled="toggleDisabled"
          @click="updateStatusToggle"
        />
        <Badge
          class="justify-content-center p-1 text-sm"
          :variant="configureStatusVariant(partner.status)"
        >
          {{ partner.status }}
        </Badge>
      </div>
      <div class="flex flex-row items-baseline justify-between">
        <div class="pl-3 pr-3">
          {{ partner.date }}
        </div>
        <div class="pl-3 pr-3">
          <button @click="openBidDetails()">
            <fa class="text-slate-300" :icon="direction" />
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="toggleShowInfo"
      class="flex flex-column w-100 items-baseline justify-items-center text-sm pt-3 pl-2 pb-3 rounded shadow-sm shadow-black"
    >
      <div class="grid grid-cols-7 w-100">
        <div
          v-for="key in tableHeaders"
          :key="key.name"
          class="items-baseline pl-4 pb-3 pt-2"
        >
          {{ key.name }}
        </div>
        <Edit
          class="h-5 mr-4 mt-2 flex justify-self-end self-baseline text-gray-100"
          @click="openAdminEditModal"
        />
      </div>
      <div
        v-for="bid in bidsOfPartner.bidsArray"
        :key="bid.id"
        class="flex flex-column w-100 pl-2 pr-4 pb-1"
      >
        <ManageBidsInnerTable
          class="flex items-baseline flex-row"
          :bid="bid"
          :partner="partner"
          :is-admin-or-pops-user="false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ManageBidsInnerTable from './ManageBidsInnerTable.vue'

const PENDING = 'pending'
const SUBMITTED = 'submitted'
const ENABLED = 'enabled'

export default defineComponent({
  name: 'ManageBidsTableRow',
  components: {
    ManageBidsInnerTable
  },
  props: {
    bidsOfPartner: {
      type: Object,
      required: true
    },
    isAdminOrPopsUser: {
      type: Boolean,
      required: true
    },
    partner: {
      type: Object,
      required: true
    }
  },
  emits: ['openAdminEditModal', 'updateStatus'],
  data() {
    return {
      direction: 'chevron-down',
      toggleShowInfo: false,
      toggleStatus: false,
      toggleDisabled: false,
      partnerStatus: this.partner.status,
      tableHeaders: [
        { name: 'Quantity', span: 1 },
        { name: 'Total Price (USD)', span: 1 },
        { name: 'Lead Time (Days)', span: 1 },
        { name: '', span: 1 },
        { name: '', span: 1 },
        { name: '', span: 1 }
      ],
      selectedDivId: '',
      packageID: this.$route.params.packageID as string
    }
  },
  async mounted() {
    // this.configureStatusVariant(this.partner.status)
    this.toggleStatus = false
    this.toggleDisabled = true

    if (this.partner.status === ENABLED && this.isCompleteBids()) {
      this.toggleStatus = true
      this.toggleDisabled = false
    }

    if (this.partner.status === SUBMITTED && this.isCompleteBids()) {
      this.toggleDisabled = false
    }
  },
  methods: {
    isCompleteBids() {
      // if no bid.total_us_dollars or no bid.lead_time_days, bid is not completed
      const isValid = !this.partner.bidsArray.some((bid) => {
        return !bid.total_us_dollars || !bid.lead_time_days
      })

      return isValid
    },
    configureStatusVariant(rowStatus: string) {
      switch (rowStatus) {
        case SUBMITTED:
          return 'warning'
        case PENDING:
          return 'flint'
        case ENABLED:
          return 'success'
        default:
          return 'info'
      }
    },
    openBidDetails() {
      if (this.direction === 'chevron-down') {
        this.direction = 'chevron-up'
        this.toggleShowInfo = true
      } else if (this.direction === 'chevron-up') {
        this.direction = 'chevron-down'
        this.toggleShowInfo = false
      }
    },
    openAdminEditModal() {
      this.$emit('openAdminEditModal', { partner: this.partner })
    },
    updateStatusToggle() {
      this.selectedDivId = this.partner.partner_id
      // if admin/pops, can toggle statuses
      if (this.isAdminOrPopsUser) {
        if (this.partnerStatus === SUBMITTED) {
          this.partnerStatus = ENABLED
          this.toggleStatus = true
          this.toggleDisabled = false
        } else if (this.partnerStatus === ENABLED) {
          this.partnerStatus = SUBMITTED
          this.toggleStatus = false
          this.toggleDisabled = false
        } else if (this.partnerStatus === PENDING && this.isCompleteBids()) {
          this.partnerStatus = SUBMITTED
          this.toggleStatus = false
          this.toggleDisabled = false
        } else {
          this.toggleDisabled = true
        }
      }

      const packageData = {
        packageID: this.$route.params.packageID,
        partners: this.bidsOfPartner.bidsArray,
        partnerID: this.partner,
        status: this.partnerStatus
      }

      this.$emit('updateStatus', this.selectedDivId, packageData)
    }
  }
})
</script>
