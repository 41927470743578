<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="checkbox-field-editor-root">
    <ListMVE
      :schema="schema"
      :field="field"
      :test-set="testSet"
      :add-handlers="addHandlers"
      :display-component="staticData.ResourceDisplay"
      :editor-component="staticData.ResourceEditor"
      :ff-current-values="ffCurrentValues"
      @update:testSet="$emit('update:testSet', $event)"
      @update:formFactor="$emit('update:formFactor', $event)"
    />
  </div>
</template>

<script lang="ts">
import { markRaw } from '@vue/reactivity'
import { defineComponent, Prop } from '@vue/runtime-core'
import ListMVE from '../ListMVE.vue'
import ResourceDisplay from './ResourceDisplay.vue'
import ResourceEditor from './ResourceEditor.vue'
import {
  defaultValueOfType,
  Schema,
  SchemaFormFactor
} from '@/store/testSets/schema'
import { TestSet } from '@/store/testSets'
import { AddHandler } from '../util'

function createResource(
  schema: Schema,
  formFactor: SchemaFormFactor
): Record<string, unknown> {
  const obj = { form_factor: formFactor.id }
  for (const resourceFieldName in formFactor.resource_fields) {
    const type = formFactor.resource_fields[resourceFieldName]
    obj[resourceFieldName] = defaultValueOfType(type, schema)
  }
  return obj
}

export default defineComponent({
  name: 'ResourceMVE',
  components: { ListMVE },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    ffCurrentValues: {
      type: Map,
      default: () => {
        return {}
      }
    }
  },
  emits: ['update:testSet', 'update:formFactor'],
  data() {
    return {
      staticData: markRaw({ ResourceDisplay, ResourceEditor })
    }
  },
  computed: {
    addHandlers(): Array<AddHandler> {
      if (this.schema === undefined) {
        return []
      }
      const schema = this.schema
      return schema.form_factors.map((ff) => ({
        create: () => {
          return createResource(schema, ff)
        },
        label: ff.name
      }))
    }
  }
})
</script>
