<template>
  <div class="content p-5">
    <h2>Zero Production Day</h2>
    <p class="subtitle mt-3">
      Please enter this information about your agency.
    </p>
    <div class="row mt-5 contentSection">
      <div class="col-3">
        <p class="sectionTitle">Add Zero Production Day</p>
      </div>
      <div class="col-9">
        <form>
          <div class="row">
            <div class="col-3">
              <TextInput
                id="year"
                v-model="year"
                label="Year - UTC"
                placeholder="YYYY"
              />
            </div>
            <div class="col-3">
              <TextInput
                id="month"
                v-model="month"
                label="Month"
                placeholder="MM"
              />
            </div>
            <div class="col-3">
              <TextInput id="day" v-model="day" label="Day" placeholder="DD" />
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <TextInput
                id="description"
                v-model="description"
                label
                placeholder="Eg. Closed for snow"
              />
            </div>
            <div class="col-3">
              <LobButton
                class="mt-2"
                data-testid="zeroProdAddBtn"
                :disabled="pending"
                @click.prevent="addZeroProdDay"
              >
                Add
              </LobButton>
            </div>
          </div>
        </form>
        <Alert v-if="alertMessage" :variant="alertType" class="mt-2">
          {{ alertMessage }}
        </Alert>
        <div v-if="loadingEntries" class="manualEntriesContainer">
          Loading...
        </div>
        <div v-else class="manualEntriesContainer">
          <p class="manualEntriesTitle mt-3">Manual Entries</p>
          <ul class="mt-2">
            <li v-for="entry in manualEntries" :key="entry.date" class="mt-2">
              <div class="row">
                <div class="col-1">
                  <fa icon="calendar" />
                </div>
                <div class="col-4">{{ formatDate(entry) }}</div>
                <div class="col-1">
                  <fa
                    icon="trash-alt"
                    class="deleteButton"
                    @click.prevent="deleteZeroProdDay(entry.id)"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5 contentSection">
      <div class="col-3">
        <p class="mt-2 sectionTitle">Days generated by holidays</p>
      </div>
      <div class="col-9">
        <ul v-if="!loadingEntries">
          <li
            v-for="entry in generatedEntries"
            :key="entry.description"
            class="mt-2"
          >
            <div class="row">
              <div class="col-1">
                <fa icon="calendar" />
              </div>
              <div class="col-4">{{ formatDate(entry) }}</div>
              <div class="col-1">
                <fa
                  icon="trash-alt"
                  class="deleteButton"
                  @click.prevent="deleteHoliday(entry.description)"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import * as session from '@/store/session'
import {
  fetchCurrentPartner,
  getCurrentPartnerId,
  Partner
} from '@/store/partners'

interface IGeneratedEntries {
  date: string
  description: string
}

export default defineComponent({
  name: 'ZeroProductionDay',
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      manualEntries: [] as any,
      generatedEntries: [] as IGeneratedEntries[],
      alertMessage: null as string | null,
      alertType: 'error',
      year: null as string | null,
      month: null as string | null,
      day: null as string | null,
      description: undefined as string | undefined,
      loadingEntries: false,
      pending: false
    }
  },
  async mounted() {
    await this.getPartnerInfo()
  },
  methods: {
    titleCase(title) {
      return title
        .split('_')
        .map((val) => {
          return val.toLowerCase().replace(/^\w/, (character) => {
            return character.toUpperCase()
          })
        })
        .join(' ')
    },
    formatDate(entry: any): string {
      return entry.date
        ? `${entry.date} (${entry.description})`
        : this.titleCase(entry.description)
    },
    flagForDeletion(index) {
      this.manualEntries[index].delete = !this.manualEntries[index].delete
    },
    async deleteZeroProdDay(id) {
      try {
        const partnerId = getCurrentPartnerId()
        if (partnerId === null) return
        await session.deleteZeroProdDay({
          partner: partnerId,
          id
        })
        this.alertMessage = 'Zero Prod Day Deleted'
        this.alertType = 'success'
        await this.getPartnerInfo()
      } catch (e) {
        const eResp = e.response
        this.alertMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
    },
    async deleteHoliday(holidayDescription) {
      try {
        const partnerId = getCurrentPartnerId()
        if (partnerId === null) return

        await session.deletePartnerHolidays({
          partner: partnerId,
          data: { holidays: [holidayDescription] }
        })

        this.alertMessage = 'Holiday Deleted'
        this.alertType = 'success'

        await fetchCurrentPartner({
          partnerId
        })

        await this.getPartnerInfo()
      } catch (e) {
        const eResp = e.response
        this.alertMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
    },
    async getPartnerInfo() {
      this.loadingEntries = true
      const partnerId = getCurrentPartnerId()
      if (partnerId == null) return

      const partnerInfo: Partner = await session.getPartner({
        partner: partnerId
      })

      const holidays = partnerInfo.holidays

      const zeroProdDays = partnerInfo.zero_prod_days
      this.generatedEntries = holidays.map((val) => {
        return {
          date: '',
          description: val.name
        }
      })
      this.manualEntries = zeroProdDays.filter((val) => {
        return val.description !== 'generated from holiday'
      })
      this.loadingEntries = false
    },
    async addZeroProdDay() {
      const partnerId = getCurrentPartnerId()
      if (partnerId === null) return
      if (!this.year) {
        this.alertType = 'error'
        this.alertMessage = 'Year is required'
        return
      } else if (!this.month) {
        this.alertType = 'error'
        this.alertMessage = 'Month is required'
        return
      } else if (!this.day) {
        this.alertType = 'error'
        this.alertMessage = 'Day is required'
        return
      } else if (!this.description) {
        this.alertType = 'error'
        this.alertMessage = 'description is required'
        return
      }
      this.pending = true
      this.alertMessage = null
      try {
        await session.addZeroProdDay({
          partner: partnerId,
          data: {
            date: `${this.year}-${this.month}-${this.day}`,
            description: this.description
          }
        })
        this.alertType = 'success'
        this.alertMessage = 'Save Successful'
        await this.getPartnerInfo()

        this.year = ''
        this.month = ''
        this.day = ''
        this.description = ''
      } catch (e) {
        // Format an error message if unsuccessful.
        const eResp = e.response
        this.alertMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
      this.pending = false
    }
  }
})
</script>
<style scoped lang="scss">
.manualEntriesTitle {
  color: $shadow-50;
}

.deleteButton {
  cursor: pointer;
}
</style>
