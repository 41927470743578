import { AxiosError } from 'axios'
import { sendPapiRequest } from '../requests'
import { defineVuexModule } from '../util'

interface State {
  namesById: Record<string, string>
  errorMessage: string
}

const initialState: State = {
  namesById: {},
  errorMessage: ''
}

const storeModule = defineVuexModule('accounts', initialState)

// GETTERS (exported functions that provide pieces of data from the state)

export const getAccountNamesById = storeModule.read(
  (state) => state.namesById,
  'getAccountNamesById'
)

export const getAccountsError = storeModule.read(
  (state) => state.errorMessage,
  'getAccountsError'
)

type AccountResponse = { id: string; company: string }

// MUTATORS (synchronous functions that change the state, each call to a mutator
// is logged in the Vue development tools)

const setAccountName = storeModule.commit(
  (state: State, { id, company }: AccountResponse) => {
    state.namesById[id] = company
  },
  'setAccountName'
)

const setErrorMessage = storeModule.commit((state: State, message: string) => {
  state.errorMessage = message
}, 'setErrorMessage')

// ACTIONS (asynchronous functions which can call other actions as well as
// getters and setters, also logged in the Vue development tools)

export const fetchAccountName = storeModule.dispatch(
  async (ctx, crid: string) => {
    try {
      const names = getAccountNamesById()
      if (names[crid]) return

      const response = await sendPapiRequest<AccountResponse>({
        method: 'GET',
        path: `/inventory/accounts/${crid}`
      })
      setAccountName(response.data)
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 404) {
        setErrorMessage('Account with given CRID not found')
      }
    }
  },
  'fetchAccountName'
)
