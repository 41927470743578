import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-100 mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "table-body"
}
const _hoisted_3 = {
  key: 1,
  class: "table-body"
}
const _hoisted_4 = { colspan: "5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_alert = _resolveComponent("alert")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['User ID', 'Partner', 'Event', 'Timestamp', ''], (key) => {
          return _createElementVNode("th", {
            key: key,
            class: "border-t-0 text-gray-900 font-light text-base p-3"
          }, _toDisplayString(key), 1)
        }), 64))
      ])
    ]),
    (_ctx.data.length)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
            return (_openBlock(), _createBlock(_component_TableRow, {
              key: item.id,
              item: item
            }, null, 8, ["item"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("tbody", _hoisted_3, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_4, [
              _createVNode(_component_alert, { variant: "info" }, {
                default: _withCtx(() => [
                  _createTextVNode("No audit logs found")
                ]),
                _: 1
              })
            ])
          ])
        ]))
  ]))
}