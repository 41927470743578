import { RouteRecordRaw } from 'vue-router'
import companyInfo from './companyInfo'
import digitalProofs from './digitalProofs'
import physicalProofs from './physicalProofs'
import printCapabilities from './printCapabilities'
import throughputTesting from './throughputTesting'
import View from './View.vue'
import zeroProductionDay from './zeroProductionDay'
import sftpSetup from './sftpSetup'

export default [
  {
    path: '/onboarding',
    redirect: '/partners/current/onboarding/company-info'
  },
  {
    path: 'onboarding',
    component: View,
    children: [
      companyInfo,
      zeroProductionDay,
      printCapabilities,
      physicalProofs,
      digitalProofs,
      throughputTesting,
      sftpSetup
    ]
  }
] as Array<RouteRecordRaw>
