<template>
  <img
    v-if="cellData && cellData.linkTo"
    :src="getSmallestImageURL()"
    class="w-"
    @click="openInNewTab()"
  />
  <fa
    v-else-if="cellData && cellData.actionRequired && cellData.actionCompleted"
    class="text-success mt-1"
    icon="check"
  />
  <div v-else-if="cellData && cellData.isInput" class="w-3/12 h-3/12">
    <label :for="`${tableID}-${rowData.index}`" class="custom-file-input-label">
      <fa class="tertiary-bluebird cursor-pointer" :icon="cellData.icon" />
    </label>
    <input
      v-if="cellInfo.inputEnabled"
      :id="`${tableID}-${rowData.index}`"
      :ref="`${tableID}-${rowData.index}`"
      type="file"
      class="hidden"
      @change="handleFileChange"
    />
  </div>
  <div v-if="cellData && cellData.isRefresh" class="w-3/12 h-3/12 ml-1">
    <label
      :for="`${tableID}-${rowData.index}-refresh`"
      class="custom-file-input-label"
    >
      <fa class="tertiary-bluebird cursor-pointer" :icon="cellData.icon" />
    </label>
    <input
      v-if="cellInfo.inputEnabled"
      :id="`${tableID}-${rowData.index}-refresh`"
      :ref="`${tableID}-${rowData.index}-refresh`"
      type="file"
      class="hidden"
      @change="handleFileChange"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

/* 
  tableRowObject for ButtonCell: 
  {
    fieldName,
    type
  }
*/

export default defineComponent({
  name: 'ImageCell',
  props: {
    tableID: {
      type: String,
      default: () => {
        return ''
      }
    },
    cellInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cellData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['handleValueChange'],
  data() {
    return {
      selectedFile: null,
      generatedID: null
    }
  },
  methods: {
    handleFileChange(e) {
      const file = e.target.files[0]
      if (file) {
        this.selectedFile = file
        this.$emit('handleValueChange', this.selectedFile)
      }
    },
    openInNewTab: function () {
      window.open(this.cellData.linkTo || this.getLargestImageURL(), '_blank')
    },
    getSmallestImageURL: function () {
      return this.cellData.small || this.cellData.medium || this.cellData.large
    },
    getLargestImageURL: function () {
      return this.cellData.large || this.cellData.medium || this.cellData.small
    }
  }
})
</script>

<style scoped lang="scss">
img {
  max-height: 48px;
  border-width: 0;
  background-color: $background-tint;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  max-width: 40px;
  text-align: left;
  cursor: pointer;
}
span {
  margin-left: 16px;
  color: $gray-midnight;
}
</style>
