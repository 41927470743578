<script lang="ts">
import { computed, defineComponent, reactive, ref } from 'vue'
import { MaterielInventory } from './types'
import PartnerDropdown from '@/components/global/PartnerDropdown.vue'
import { getAllPartners } from '@/store/partners'

export default defineComponent({
  name: 'AddPrinterModal',
  components: { PartnerDropdown },
  props: {
    visible: { type: Boolean, required: true },
    allocableItems: { type: Number, required: true }
  },
  emits: ['close', 'add'],
  setup(props, { emit }) {
    const firstPartner = computed(() => getAllPartners()?.[0])
    const fields = ref({ partner: firstPartner.value?.id })
    const items = ref<Partial<MaterielInventory>[]>([])
    const errors = reactive<Record<string, string | undefined>>({})

    const residualItems = computed(() => {
      return (
        props.allocableItems -
        items.value.reduce((acc, item) => {
          return acc + (item?.num_remaining || 0)
        }, 0)
      )
    })
    const addEmptyRow = () => {
      items.value.push({
        id: '',
        partner_id: firstPartner.value.id,
        partner_name: firstPartner.value.display_name,
        num_remaining: 0,
        total_cost: 0
      })
    }

    const add = () => {
      if (!validateAllFields()) return

      emit(
        'add',
        items.value.map((e) => ({ ...e, initial_count: e.num_remaining }))
      )
      emit('close')
    }

    const deleteItem = (index) => {
      items.value.splice(index, 1)
    }

    const validateAllFields = () =>
      items.value
        .flatMap((item, index) =>
          ['total_cost', 'num_remaining'].map((key) =>
            onValidate(key, item[key]?.toString() ?? '0', index)
          )
        )
        .every((t) => t)

    const onValidate = (
      field: string,
      value: string,
      index: string | number
    ): boolean => {
      const isValid = Number(value) > 0
      errors[`${index}_${field}`] = isValid
        ? undefined
        : `Must be higher than 0`
      return isValid
    }

    return {
      errors,
      fields,
      add,
      items,
      addEmptyRow,
      residualItems,
      deleteItem,
      onValidate
    }
  }
})
</script>

<template>
  <div
    class="bg-75 fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50"
    @click.self="$emit('close')"
  >
    <div class="bg-white rounded-lg shadow p-5 modalbox flex flex-col relative">
      <LobButton
        variant="none"
        class="absolute top-3 right-3"
        @click="$emit('close')"
      >
        <Close class="w-10 h-10 text-gray-500"></Close>
      </LobButton>
      <div class="p-3">
        <h4
          class="text-center !text-gray-300 flex-grow font-bold flex items-center justify-center"
        >
          <div class="rounded-full bg-success w-6 h-6 mr-2">
            <Check
              data-testid="expand-row"
              aria-label="Expand row for more details"
              class="cursor-pointer p-1 text-white"
            />
          </div>
          Envelopes Ready
        </h4>
        <h5 class="text-base text-center pt-4">
          This order has
          <span :class="{ 'font-bold': true, 'text-error': residualItems < 0 }">
            {{ residualItems }}
          </span>
          items to be associated to the following print partners
        </h5>
      </div>

      <div class="my-4 border-b border-t border-white-300 flex-grow">
        <table class="w-full table border-0 mt-3 overflow-y-auto">
          <thead>
            <tr class="h-12">
              <th class="font-normal border-0">Print Partner</th>
              <th class="font-normal border-0">Quantity</th>
              <th class="font-normal border-0">Total Cost</th>
              <th class="border-0 w-8"></th>
            </tr>
          </thead>
          <tbody class="overflow-y-scroll w-full">
            <tr v-for="(item, index) in items" :key="index" class="h-12 w-full">
              <td class="border-0 py-0 px-2">
                <PartnerDropdown
                  id="partner"
                  :model-value="{
                    value: item.partner_id ?? '',
                    label: item.partner_name ?? ''
                  }"
                  :disable-all="true"
                  :initial-emit="false"
                  class="w-48 mr-3"
                  label="Partner"
                  data-testid=""
                  placeholder="Select Partner"
                  size="default"
                  sr-only-label
                  @update:model-value="
                    (e) => {
                      item.partner_id = e.value
                      item.partner_name = e.label
                    }
                  "
                />
              </td>
              <td class="border-0 py-0 px-2">
                <TextInput
                  id="num_remaining"
                  v-model.number="item.num_remaining"
                  label="num-remaining"
                  input-class="!p-3"
                  sr-only-label
                  type="number"
                  @change="
                    (e) => onValidate('num_remaining', e.target.value, index)
                  "
                />
                <small
                  v-if="errors[`${index}_num_remaining`]"
                  class="text-error"
                >
                  {{ errors[`${index}_num_remaining`] }}
                </small>
              </td>
              <td class="border-0 py-0 px-2">
                <TextInput
                  id="total_cost"
                  v-model.number="item.total_cost"
                  label="total-cost"
                  input-class="!p-3"
                  sr-only-label
                  type="number"
                  @change="
                    (e) => onValidate('total_cost', e.target.value, index)
                  "
                />
                <small v-if="errors[`${index}_total_cost`]" class="text-error">
                  {{ errors[`${index}_total_cost`] }}
                </small>
              </td>
              <td class="border-0 px-0 py-0 w-8">
                <LobButton
                  variant="none"
                  class="h-full"
                  @click="() => deleteItem(index)"
                >
                  <Close class="w-5 h-5 text-gray-500 my-2"></Close>
                </LobButton>
              </td>
            </tr>
          </tbody>
        </table>
        <LobButton
          data-testid="add-printer"
          variant="secondary"
          :disabled="residualItems < 1"
          class="px-4 py-1 my-2 bg-white border-primary-700"
          @click="addEmptyRow"
        >
          <Plus class="w-4 h-4 text-primary-700"></Plus>
          Add another print partner
        </LobButton>
      </div>

      <div class="flex justify-end w-full">
        <LobButton variant="none" class="w-32" @click="$emit('close')">
          Close
        </LobButton>
        <LobButton
          data-testid="confirm-btn"
          variant="primary"
          class="w-52"
          :disabled="residualItems < 0 || items.length < 1"
          @click="add"
        >
          Confirm
        </LobButton>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-75 {
  background-color: rgba(0, 0, 0, 0.75);
}

.modalbox {
  min-height: 600px;
  width: 700px;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: auto; /* even columns width , fix width of table too*/
}

tbody {
  width: 100%;
  display: block;
  height: 200px;
}
</style>
