<template>
  <form class="p-8 max-w-lg">
    <div class="flex flex-column mb-12 gap-y-4">
      <H1>Failed PDF Notification Form</H1>
      <P>
        Form for print partners to easily share out IDs and reasons for rejected
        mailpieces
      </P>
      <TextInput
        id="companyName"
        v-model="companyName"
        label="Print Partner Company Name"
      />
      <ChecklistWithOther
        v-model="managerName"
        name="Partner Manager Name"
        :options="managers"
      />
      <ChecklistWithOther
        v-model="formFactor"
        name="Rejected Form Factor"
        :options="formFactors"
      />
      <ChecklistWithOther
        v-model="location"
        name="Location of Rejected Form Factor"
        :options="locations"
      />
      <ChecklistWithOther
        v-model="reason"
        name="Reason for Rejecting Mail Pieces"
        :options="reasons"
      />
      <Textarea
        id="explanation"
        v-model="explanation"
        input-class="min-w-full"
        label="Explanation of File Rejection"
      />
      <div>
        <Caption>
          Upload CSVs of Impacted IDs (do not include headers or column titles)
        </Caption>
        <MultiFileUpload v-model="csvs" />
      </div>
      <div>
        <Caption>Upload Relevant Screenshots (if appropriate)</Caption>
        <MultiFileUpload v-model="screenshots" />
      </div>
      <LobButton v-if="submitting" disabled>Submitting...</LobButton>
      <LobButton v-else @click.prevent="submit">Submit</LobButton>
    </div>
  </form>
</template>

<script lang="ts">
import ChecklistWithOther from '@/components/formUtils/ChecklistWithOther.vue'
import MultiFileUpload from '@/components/formUtils/MultiFileUpload.vue'
import { sendPapiRequest } from '@/store/requests'
import { defineComponent } from 'vue'

function readableList(input) {
  // So that we don't modify the original list
  const list = [...input]
  const last = list.pop()
  if (last === undefined) {
    return ''
  }
  if (list.length === 0) {
    return last
  } else {
    return `${list.join(', ')} and ${last}`
  }
}

export default defineComponent({
  name: 'RejectedPdf',
  components: { ChecklistWithOther, MultiFileUpload },
  data() {
    return {
      companyName: '',
      managerName: [] as Array<string>,
      formFactor: [] as Array<string>,
      location: [] as Array<string>,
      reason: [] as Array<string>,
      explanation: '',
      csvs: [] as Array<string>,
      screenshots: [] as Array<string>,
      submitting: false
    }
  },
  computed: {
    managers() {
      return [
        'Tyler Stewart',
        'Mariella Torres',
        'Virginia Barton',
        'Alex Shikhman',
        'Robin Singh',
        'Samira Sierra',
        'Vanessa Barsness'
      ]
    },
    formFactors() {
      return ['Letters', 'Postcards', 'Self Mailers', 'Checks']
    },
    locations() {
      return ['Ingestion', 'Preflight', 'RIP', 'Composition', 'Print']
    },
    reasons() {
      return ['Unflattened transparencies', 'Un-embedded fonts', 'File size']
    }
  },
  methods: {
    async submit() {
      this.submitting = true
      const message = `A partner has rejected one or more files.
Company Name: ${this.companyName}
Manager Name(s): ${readableList(this.managerName)}
Form Factor(s): ${readableList(this.formFactor)}
Location(s): ${readableList(this.location)}
Reason(s): ${readableList(this.reason)}
Explanation: ${this.explanation}
${this.csvs.map((x) => `IDs: ${x}`).join('\n')}
${this.screenshots.map((x) => `Screenshot: ${x}`).join('\n')}`
      await sendPapiRequest({
        method: 'POST',
        path: '/asana',
        data: {
          name: 'PDF Rejection',
          message
        }
      })
      this.$router.push('success')
    }
  }
})
</script>
