export const capitalize = (str: string): string => {
  const frags = str.split('_')
  for (let i = 0; i < frags.length; i++) {
    frags[i] =
      frags[i].charAt(0).toUpperCase() + frags[i].toLowerCase().slice(1)
  }
  return frags.join(' ')
}

export const underscoreToSpace = (str: string): string =>
  str.split('_').join(' ')

export const trimLastLetter = (str: string): string => str.slice(0, -1)

export interface DateTimeFormatOptions {
  formatMatcher?: 'basic' | 'best fit' | 'best fit' | undefined
  dateStyle?: 'full' | 'long' | 'medium' | 'short' | undefined
  timeStyle?: 'full' | 'long' | 'medium' | 'short' | undefined
  dayPeriod?: 'narrow' | 'short' | 'long' | undefined
  fractionalSecondDigits?: 0 | 1 | 2 | 3 | undefined
}

export const formatDate = (str: string, options = {}): string => {
  const date = new Date(str)
  return new Intl.DateTimeFormat('en-us', options).format(date)
}

export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat('en-us', {}).format(num)
}

export const formatCurrency = (num: number): string => {
  return new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD'
  }).format(num)
}
