import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d356b01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "abbreviation" }
const _hoisted_2 = { class: "tooltip2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createBlock(_component_Tooltip, {
    class: "formFactorNoteRoot",
    position: "right"
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.abbreviated), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, "Applies to " + _toDisplayString(_ctx.humanReadableList), 1)
    ]),
    _: 1
  }))
}