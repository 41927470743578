<script lang="ts">
import { defineComponent } from 'vue'
import { RESOURCES } from '@/consts'

export default defineComponent({
  name: 'CapacityCard',
  props: {
    resource: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    total: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup() {
    return {
      RESOURCES
    }
  }
})
</script>

<template>
  <div
    class="rouded bg-white-200 border border-gray-100 rounded w-full flex flex-wrap items-center justify-between py-3"
  >
    <div class="mx-3">
      <div class="text-gray-500 text-sm">{{ title }}</div>
      <div class="font-bold">{{ subtitle }}</div>
    </div>
    <div class="mx-3 text-primary-500 text-4xl font-thin text-right">
      <div>{{ value }}</div>
      <div class="text-gray-300 text-xs font-bold">
        of {{ total }} {{ RESOURCES[resource].unit.toLowerCase() }} remaining
      </div>
    </div>
  </div>
</template>
