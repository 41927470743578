<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <Tooltip class="formFactorNoteRoot" position="right">
    <template #trigger>
      <span class="abbreviation">{{ abbreviated }}</span>
    </template>
    <template #content>
      <div class="tooltip2">Applies to {{ humanReadableList }}</div>
    </template>
  </Tooltip>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  name: 'FormFactorNote',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    formFactors: {
      type: Array,
      default: () => []
    } as Prop<Array<string>>
  },
  computed: {
    formFactorNames(): string[] {
      if (this.formFactors === undefined || this.schema === undefined) return []
      const schema = this.schema
      return this.formFactors
        .map((ffid) => schema.form_factors.find((ff) => ff.id === ffid))
        .map((ff) => ff?.name || 'Unexpected Error')
    },
    abbreviated(): string {
      return this.formFactorNames
        .map((ffname) => ffname.substring(0, 1))
        .join('')
        .toUpperCase()
    },
    humanReadableList(): string {
      if (this.formFactorNames.length === 0) {
        return 'Unexpected empty list'
      } else if (this.formFactorNames.length === 1) {
        return this.formFactorNames[0]
      }
      const beforeLast = this.formFactorNames.slice(0, -1).join(', ')
      const last = this.formFactorNames[this.formFactorNames.length - 1]
      return `${beforeLast} and ${last}`
    }
  }
})
</script>

<style lang="scss" scoped>
.formFactorNoteRoot {
  display: inline-block;
}
.abbreviation {
  color: $cobalt;
  font-size: 0.7rem;
  vertical-align: super;
  padding-left: 0.3rem;
}
.tooltip2 {
  width: max-content;
}
</style>
