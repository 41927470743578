import { sendPapiRequest } from '@/store/requests'
import { ref, Ref } from 'vue'

export interface RoutingPrediction {
  order: {
    id: string
  }
  routingResult: string
  debugInfo?: {
    routing_status: string
    reason?: string
    strategy_enablements: any
    strategy_type: string
    suppression_fields: string[]
    all_capable_partners: string[]
    matching_preference_rule: {
      note: string
      yields: {
        weight: number
        partner: string
      }
      expressions: [
        {
          lhs: string
          operator: string
          rhs: string
        }
      ]
    }
    partner_capability?: [
      {
        weight: number
        partner: string
        capacity: number
        inventpry: number
      }
    ]
  }
}

export interface UseRoutingPredictionResponse {
  error: Ref<string | null>
  loading: Ref<boolean>
  routingPrediction: Ref<RoutingPrediction | null>
  getRoutingPrediction(orderId: string): void
}

export function useRoutingPrediction(): UseRoutingPredictionResponse {
  const loading = ref(false)
  const error = ref<string>('')
  const routingPrediction = ref<RoutingPrediction | null>(null)

  const getRoutingPrediction = async (orderId: string) => {
    loading.value = true
    const path = `/routing_predictions/${orderId}`
    try {
      const response = await sendPapiRequest({
        path,
        method: 'GET'
      })
      routingPrediction.value = response.data
    } catch (e) {
      routingPrediction.value = null
      error.value =
        'Failed to retrieve routing prediction. Please check resource ID exists or notify Partner Experience team.'
    } finally {
      loading.value = false
    }
  }

  return {
    error,
    loading,
    getRoutingPrediction,
    routingPrediction
  }
}
