<template>
  <div
    class="bg-mist grid grid-cols-6 justify-items-center shadow-md shadow-black"
  >
    <div class="w-100 p-2">
      {{ ability?.name }}
    </div>
    <div class="w-100 p-2">
      {{ ability?.order_field_name }}
    </div>
    <div class="w-100 p-2">
      {{ ability?.operator }}
    </div>
    <div class="w-100 p-2">
      {{ JSON.stringify(ability?.order_field_value) }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getAllAbilities } from '@/store/partners'

export default defineComponent({
  name: 'AbilityExclusionsInnerTableRow',
  props: {
    abilityId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const allAbilities = getAllAbilities()
    const [ability] = allAbilities.filter((a) => a.id === props.abilityId)
    return { ability }
  }
})
</script>
