<template>
  <P>
    <FieldSetRow>
      <TextInput
        id="name"
        label="Name"
        :model-value="getField('name')"
        @update:modelValue="setField('name', $event)"
      />
    </FieldSetRow>
    <FieldSetRow>
      <TextInput
        id="line1"
        label="Line 1"
        :model-value="getField('address_line1')"
        @update:modelValue="setField('address_line1', $event)"
      />
    </FieldSetRow>
    <FieldSetRow>
      <TextInput
        id="line2"
        label="Line 2"
        :model-value="getField('address_line2')"
        @update:modelValue="setField('address_line2', $event)"
      />
    </FieldSetRow>
    <FieldSetRow>
      <TextInput
        id="city"
        label="City"
        :model-value="getField('address_city')"
        @update:modelValue="setField('address_city', $event)"
      />
    </FieldSetRow>
    <FieldSetRow>
      <div id="bottomThreeRow">
        <TextInput
          id="state"
          label="State/Province"
          :model-value="getField('address_state')"
          @update:modelValue="setField('address_state', $event)"
        />
        <TextInput
          id="zip"
          label="Zip Code"
          :model-value="getField('address_zip')"
          @update:modelValue="setField('address_zip', $event)"
        />

        <TextInput
          id="country"
          label="Country Code"
          :model-value="getField('address_country')"
          @update:modelValue="setField('address_country', $event)"
        />
      </div>
    </FieldSetRow>
  </P>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    getField(fieldName) {
      return this.modelValue[fieldName]
    },
    setField(fieldName, newValue) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        [fieldName]: newValue
      })
    }
  }
})
</script>

<style lang="scss" scoped>
#bottomThreeRow {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 1rem;
}
</style>
