<template>
  <TextInput
    id="text"
    label="Text"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  name: 'FloatSVE',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    type: {
      type: String,
      required: true
    } as Prop<'float'>,
    modelValue: {
      type: String,
      required: true
    } as Prop<string>,
    formFactor: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue']
})
</script>
