<template>
  <component
    :is="cellComponent"
    :table-i-d="tableID"
    :cell-data="cellData"
    :cell-info="cellInfo"
    :row-data="rowData"
    @handleValueChange="handleValueChange"
  ></component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import StringCell from './StringCell.vue'
import ButtonCell from './ButtonCell.vue'
import BadgeCell from './BadgeCell.vue'
import ImageCell from './ImageCell.vue'
import BooleanCell from './BooleanCell.vue'

export default defineComponent({
  name: 'TableCell',
  components: {
    StringCell,
    ButtonCell,
    BadgeCell,
    ImageCell,
    BooleanCell
  },
  props: {
    tableID: {
      type: String,
      default: () => {
        return ''
      }
    },
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cellInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cellData: {
      type: Object,
      required: true
    }
  },
  emits: ['handleValueChange'],
  computed: {
    cellComponent() {
      if (this.cellInfo.type === 'badge') {
        return BadgeCell
      } else if (this.cellInfo.type === 'button') {
        return ButtonCell
      } else if (this.cellInfo.type === 'image') {
        return ImageCell
      } else if (this.cellInfo.type === 'boolean') {
        return BooleanCell
      } else {
        return StringCell
      }
    }
  },
  methods: {
    handleValueChange(e) {
      this.$emit('handleValueChange', e)
    }
  }
})
</script>
