<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GrayCard',
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    }
  }
})
</script>

<template>
  <section class="border border-gray-100 bg-white-100 rounded p-3 mb-4">
    <h3 class="text-base text-gray-700 mb-1">{{ title }}</h3>
    <p class="text-sm text-gray-300">
      {{ value }}
      <slot></slot>
    </p>
  </section>
</template>
