<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="checkbox-field-editor-root">
    <ListMVE
      :schema="schema"
      :field="field"
      :test-set="testSet"
      :add-handlers="addHandlers"
      :display-component="staticData.MaybeDateDisplay"
      :editor-component="staticData.MaybeDateEditor"
      :ff-current-values="ffCurrentValues"
      @update:testSet="$emit('update:testSet', $event)"
      @update:formFactor="$emit('update:formFactor', $event)"
    />
  </div>
</template>

<script lang="ts">
import { TestSet } from '@/store/testSets'
import { Schema } from '@/store/testSets/schema'
import { markRaw } from '@vue/reactivity'
import { defineComponent, Prop } from '@vue/runtime-core'
import ListMVE from '../ListMVE.vue'
import MaybeDateDisplay from './MaybeDateDisplay.vue'
import MaybeDateEditor from './MaybeDateEditor.vue'

export default defineComponent({
  name: 'MaybeDateMVE',
  components: { ListMVE },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    ffCurrentValues: {
      type: Map,
      default: () => {
        return {}
      }
    }
  },
  emits: ['update:testSet', 'update:formFactor'],
  data() {
    return {
      staticData: markRaw({ MaybeDateDisplay, MaybeDateEditor }),
      addHandlers: [
        {
          label: 'MaybeDate',
          create: () => [null]
        }
      ]
    }
  }
})
</script>
