/* eslint-disable camelcase */
import { sendPapiRequest } from '../requests'
import { getUser } from '../session'
// import { getUser } from '../session'
import { defineVuexModule } from '../util'

import { getJobs as rsGetJobs } from '@/store/routingService'

export interface User {
  partner: string
  role: string
}

interface State {
  token: string | null
  user: User | null
}

export const initialState: State = {
  token: null,
  user: null
}

export interface UploadedResources {
  filename: string
  url: string
}

export interface CreatedJob {
  id: string
}

export interface Job {
  status: string
  id: string
  flush_type: string
  flush_key: string
  created_at: string
  updated_at: string
  count_processed: number
  total_count: number
  deduplicated_count: number
  prioritized: boolean
}

export interface JobResponse {
  count: number
  data: Job[]
}

const storeModule = defineVuexModule('session', initialState)

export const getJobResources = storeModule.dispatch(
  async (
    _,
    params: {
      jobId: string | Blob
    }
  ) => {
    const response = await sendPapiRequest({
      method: 'GET',
      path: `/job_resources/${params.jobId}`
    })
    return response.data
  },
  'getJobResources'
)

export const getJobs = storeModule.dispatch(async () => {
  const response = await rsGetJobs()
  return response.data
}, 'getJobs')

export const createBulkJob = storeModule.dispatch(
  async (
    ctx,
    params: {
      file: string | Blob
      jobType: string
      resourceIds: string
      productSelection: string
      description: string
      partnerSelection: string
      flushStatus: string
      nullEnvelopeIds: boolean
    }
  ) => {
    const data = new FormData()
    if (params.jobType === 'flush') {
      data.append('flushArgs', params.flushStatus)
      if (params.productSelection) {
        data.append('product', params.productSelection)
      }
      if (params.partnerSelection) {
        data.append('partner', params.partnerSelection)
      }
    } else {
      if (params.file) {
        data.append('file', params.file)
      } else if (params.resourceIds) {
        data.append('resourceIds', JSON.stringify({ data: params.resourceIds }))
      }
      data.append('description', params.description)
      data.append('product', params.productSelection)
      if (params.jobType === 'enqueue') {
        data.append('partner', params.partnerSelection)
      } else if (params.jobType === 'delete' && params.nullEnvelopeIds) {
        data.append('nullEnvelopeIds', params.nullEnvelopeIds.toString())
      }
    }
    data.append('jobType', params.jobType)
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const user = <any>getUser()
    if (user) {
      data.append('userId', user.id)
      data.append('userEmail', user.email ?? '')
    } else return { err: 'Error: No logged in data' }

    const result = await sendPapiRequest<UploadedResources>({
      method: 'POST',
      path: '/jobs',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })

    return result.data
  },
  'createBulkJob'
)
