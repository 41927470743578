<template>
  <LoadingIndicator>
    <div v-if="!isLoading" class="content p-5">
      <PortalBreadCrumb />
      <div class="flex">
        <div class="text-3xl mb-20 ml-10 font-bold">
          {{ mainTitle }} ({{ componentData.title }})
        </div>
      </div>
      <Card>
        <ComponentInformation
          :data="componentData"
          :is-admin-or-pops-user="isAdminOrPopsUser"
          :preview-url="componentData.file_url"
          @openEditComponentModal="openEditComponentModal"
        />
        <div class="border-t-2 w-full h-1 border-grayDove my-2" />
        <AttributesInformation
          :data="attributeFields"
          :is-admin-or-pops-user="isAdminOrPopsUser"
          @saveAttribute="saveAttribute"
          @disassociateAttributeFromComponent="disassociateAttribute"
          @updateAttributeLabel="updateAttributeLabel"
          @updateAttributeValue="updateAttributeValue"
          @onDeselect="handleAttributeDeselect"
          @addAttribute="addAttribute"
        />
      </Card>
    </div>
    <AttributeModal
      :data="{ componentID }"
      :mode="'create_attribute'"
      :is-open="attributeModalIsOpen"
      @close="closeAttributeModal"
    />
    <EditComponentModal
      :data="componentData"
      :mode="'edit_component'"
      :is-open="componentModalIsOpen"
      @close="closeComponentModal"
    />
  </LoadingIndicator>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { isAdminOrPopsUser } from '@/store/session'
import {
  getCurrentUserAction,
  getComponentPageID,
  getComponentDetails,
  disassociateAttributeFromComponent,
  linkAttributeToComponent,
  getComponentAttributes
} from '@/store/customMail'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import ComponentInformation from './ComponentInformation.vue'
import AttributesInformation from './AttributesInformation.vue'
import AttributeModal from '../common/AttributeModal.vue'
import EditComponentModal from '../common/EditComponentModal.vue'
import PortalBreadCrumb from '../../../../../components/common/PortalBreadcrumb.vue'

export default defineComponent({
  name: 'ComponentPage',
  components: {
    ComponentInformation,
    AttributesInformation,
    AttributeModal,
    EditComponentModal,
    PortalBreadCrumb
  },
  data() {
    return {
      modalData: {},
      packageModelMode: '',
      attributeModalIsOpen: false,
      componentModalIsOpen: false,
      isLoading: true,
      items: [],
      componentData: {
        attribute_fields: [] as any[],
        refreshPreviewURL: false
      },
      attributeFields: [] as any[]
    }
  },
  computed: {
    componentID() {
      return getComponentPageID() as string
    },
    isAdminOrPopsUser() {
      return isAdminOrPopsUser()
    },
    mode() {
      if (!getCurrentUserAction()) return 'Unauthorized'
      return getCurrentUserAction()?.split('-')[2]
    },
    mainTitle() {
      const currentMode = this.mode as string
      return currentMode === CUSTOM_MAIL_CONSTS.COMPONENT_PAGE_EDIT_MODE
        ? CUSTOM_MAIL_CONSTS.COMPONENT_PAGE_CREATE_TITLE
        : CUSTOM_MAIL_CONSTS.COMPONENT_PAGE_VIEW_TITLE
    }
  },
  async mounted() {
    const componentID = this.componentID
    this.componentData = await getComponentDetails({
      componentID
    })
    this.formatAttributes(this.componentData.attribute_fields)
    this.isLoading = false
  },
  methods: {
    openAttributeModal() {
      this.attributeModalIsOpen = true
    },
    async closeAttributeModal(data: any) {
      this.attributeModalIsOpen = false
      if (data.refreshComponent) {
        this.componentData = await getComponentDetails({
          componentID: this.componentID
        })
        this.formatAttributes(this.componentData.attribute_fields)
      }
    },
    formatAttributes(attributeFields: Array<any>) {
      this.attributeFields = attributeFields.map((attribute) => {
        if (!attribute.isEditing) {
          attribute.isEditing = false
        }
        attribute.initialAttributeID = attribute.id
        return attribute
      })
    },
    handleAttributeDeselect(index: number) {
      this.attributeFields[index].value = ''
    },
    updateAttributeLabel(data: any) {
      // keep track of the initial attribute for deletion later, or if update is cancelled
      data.attribute.initialAttributeID =
        this.attributeFields[data.index].initialAttributeID
      this.attributeFields[data.index] = data.attribute
      this.attributeFields[data.index].value = data.attribute.default_value
    },
    updateAttributeValue(data: any) {
      this.attributeFields[data.index].value = data.e.target.value
    },
    async saveAttribute(data: any) {
      const attribute = this.attributeFields[data.index]
      if (
        attribute.initialAttributeID &&
        attribute.id !== attribute.initialAttributeID
      ) {
        await disassociateAttributeFromComponent({
          attributeID: attribute.initialAttributeID,
          componentID: this.componentID
        })
      }
      await linkAttributeToComponent({
        componentID: this.componentID,
        fieldID: attribute.id,
        value: attribute.value
      })
      this.componentData = await getComponentDetails({
        componentID: this.componentID
      })
      this.formatAttributes(this.componentData.attribute_fields)
    },
    async disassociateAttribute(data: any) {
      const attribute = this.attributeFields[data.index]
      await disassociateAttributeFromComponent({
        attributeID: attribute.id,
        componentID: this.componentID
      })
      this.componentData.attribute_fields = await getComponentAttributes({
        componentID: this.componentID
      })
      this.formatAttributes(this.componentData.attribute_fields)
    },
    async closeComponentModal(data: any) {
      if (data.refreshComponent) {
        this.componentData = await getComponentDetails({
          componentID: this.componentID
        })
        if (data.refreshPreviewURL) this.componentData.refreshPreviewURL = true
      }
      this.componentModalIsOpen = false
    },
    openEditComponentModal() {
      this.componentModalIsOpen = true
    },
    addAttribute() {
      this.attributeFields.push({
        label: '',
        name: '',
        default_value: '',
        value: '',
        isEditing: true
      })
    }
  }
})
</script>

<style lang="scss" scoped></style>
