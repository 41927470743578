// import { ShowChildRoutes } from '@/components/global'
import { RouteRecordRaw } from 'vue-router'
// import formFactors from '../../../../../components/TestSetEditor/multiValueEditors/FormFactors'
import View from './View.vue'

export default {
  path: 'create-test-sets',
  props: true,
  component: View,
  children: [
    {
      path: '',
      component: View
    }
  ]
} as RouteRecordRaw
