import { RouteRecordRaw } from 'vue-router'
import home from './home'
import partnerOps from './partnerOps'
import partners from './partners'
import View from './View.vue'

export default {
  path: '/main',
  component: View,
  children: [home, partnerOps, partners]
} as RouteRecordRaw
