import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between border px-8 py-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_search = _resolveComponent("search")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_LobButton = _resolveComponent("LobButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      id: "partners",
      "model-value": (_ctx.partnerOptions || []).find((r) => r.id === _ctx.partner),
      options: _ctx.partnerOptions,
      label: "partner",
      "sr-only-label": "",
      size: "small",
      class: "w-2/5",
      placeholder: "Select Print Partner",
      "data-testid": "dd-partner",
      "onUpdate:modelValue": _ctx.onPartnerChange
    }, null, 8, ["model-value", "options", "onUpdate:modelValue"]),
    _createVNode(_component_TextInput, {
      id: "search",
      modelValue: _ctx.searchTerm,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
      size: "small",
      placeholder: "Enter search term here",
      class: "w-2/5 px-4",
      "data-testid": "search-input"
    }, {
      iconRight: _withCtx(() => [
        _createVNode(_component_search, { class: "w-6 h-6" })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_LobButton, {
      variant: "primary",
      size: "small",
      class: "w-1/5",
      "data-testid": "search-button",
      onClick: _ctx.onSearch
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Search ")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}