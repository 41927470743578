import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7148d70a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sessionless-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnersLogo = _resolveComponent("PartnersLogo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CenteredBox = _resolveComponent("CenteredBox")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_PartnersLogo, { class: "logo" }),
    _createVNode(_component_CenteredBox, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ]))
}