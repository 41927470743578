<template>
  <div class="content p-5">
    <LoadingIndicator>
      <div v-if="!isLoading">
        <PortalBreadCrumb />
        <h2 class="text-3xl mb-8 font-bold">Print Partners</h2>
        <Card>
          <div class="flex flex-row text-xl mb-4">
            Partner List
            <Tooltip position="right">
              <template #trigger>
                <Info class="ml-1 w-5 h-5" />
              </template>
              <template #content>
                <div class="w-max">List of all live Lob print partners.</div>
              </template>
            </Tooltip>
          </div>
          <div v-if="errorMessage !== null">
            <Alert variant="error">{{ errorMessage }}</Alert>
          </div>
          <div v-else>
            <PartnersTable
              :partners="livePartners"
              :partner-facilities="allPartnerFacilities"
            />
          </div>
        </Card>
      </div>
    </LoadingIndicator>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import {
  Partner,
  getPartnersGroupedByStatus,
  getAllPartnerFacilities,
  refreshAllPartners,
  refreshAllPartnerFacilities,
  AllPartnerFacilities
} from '@/store/partners'
import PartnersTable from './PartnersTable.vue'
import PortalBreadCrumb from '../../../../components/common/PortalBreadcrumb.vue'

export default defineComponent({
  name: 'PartnersList',
  components: {
    PartnersTable,
    PortalBreadCrumb
  },
  data() {
    return {
      isLoading: false,
      livePartners: [] as Partial<Partner>[],
      allPartnerFacilities: {} as AllPartnerFacilities,
      errorMessage: null as string | null
    }
  },
  async mounted() {
    this.isLoading = true

    try {
      await refreshAllPartners()
      await refreshAllPartnerFacilities()
      const allPartnerFacilities = getAllPartnerFacilities()
      const groupedPartners = getPartnersGroupedByStatus()
      const livePartners = groupedPartners?.live

      this.isLoading = false
      this.allPartnerFacilities = allPartnerFacilities
      this.livePartners = livePartners || []
    } catch (e) {
      this.errorMessage = String(e)
    }

    this.isLoading = false
  }
})
</script>
<style lang="scss" scoped></style>
