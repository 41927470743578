<template>
  <component
    :is="editorComponent"
    :schema="schema"
    :type="type"
    :model-value="modelValue"
    :form-factor="formFactor"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<script lang="ts">
import { FieldType, Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'
import ArtworkSVE from './ArtworkSVE.vue'
import FloatSVE from './FloatSVE.vue'
import JsonSVE from './JsonSVE.vue'
import StringSVE from './StringSVE.vue'
import UnimplementedSVE from './UnimplementedSVE.vue'

export default defineComponent({
  name: 'SingleValueEditor',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    type: {
      validator: () => true,
      required: true
    } as Prop<FieldType>,
    modelValue: {
      validator: () => true,
      required: true
    } as Prop<unknown>,
    formFactor: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    editorComponent() {
      if (this.type === 'artwork') {
        return ArtworkSVE
      } else if (this.type === 'json') {
        return JsonSVE
      } else if (this.type === 'float') {
        return FloatSVE
      } else if (this.type === 'string') {
        return StringSVE
      } else {
        return UnimplementedSVE
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.field-header-root {
  display: grid;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  grid-template-columns: 1fr auto auto;
  align-items: end;
  border-bottom: 1px solid rgba(black, 0.3);
}
.title {
  display: inline;
  margin-top: 0;
  // Bootstrap makes this margin !important on the typography.
  margin-bottom: 0 !important;
}
.addButton {
  padding: 0.25rem;
}
</style>
