<template>
  <Modal v-if="isOpen" :visible="isOpen" width="700px" @close="close">
    <div class="flex justify-end -mt-10">
      <fa class="cursor-pointer" icon="times" @click="close" />
    </div>
    <div class="flex justify-center mb-6">
      <h4>Send Test</h4>
    </div>
    <SearchAndMultiSelect
      id="partners-name"
      label="Partners:"
      :selected-items="selectedPartners"
      size="small"
      class="mb-1"
      :search-function="handleMultiSelectPartnerSearch"
      @onListChange="handleMultiSelect"
    >
      <template #default="{ result }">
        <td>
          {{ result.label }}
        </td>
      </template>
    </SearchAndMultiSelect>

    <PackageTextInput
      id="test-send-name"
      v-model:value="name"
      label="Name"
      placeholder="Name"
      required
    />

    <PackageTextInput
      id="test-send-description"
      v-model:value="testSendDescription"
      label="Description:"
      input-class="w-full"
      placeholder="Add a description..."
    />

    <div class="my-2 -ml-1">
      <Checkbox
        v-model="includeSecureDestructionStid"
        name="Include Secure Destruction STID"
        label="Include Secure Destruction STID"
      />
    </div>

    <div>
      <div class="flex align-bottom mb-2">
        <div class="w-6/12">Test Case or Group:</div>
        <div class="w-5/12 ml-1">Quantity:</div>
      </div>

      <div
        v-for="(testCase, index) in selectedCases"
        :key="testCase"
        class="flex"
      >
        <SearchAndSelect
          class="mb-2 w-6/12"
          :search-function="handleTestCaseSearch"
          :selected-item-display-property="'label'"
          :selected-item="testCase"
          @onSelect="(e) => handleTestCaseSelect(e, index)"
          @onDeselect="(e) => handleDeselect(index)"
        >
          <template #default="{ result }">
            <td>
              {{ result.label }}
            </td>
          </template>
        </SearchAndSelect>

        <PackageTextInput
          v-model:value="testCase.quantity"
          class="w-5/12 h-12 ml-2 mb-2"
          placeholder="Quantity"
          required
        />

        <div class="w-1/12 mt-3.5 flex justify-center cursor-pointer">
          <CircleXMark @click="removeTestCase(index)" />
        </div>
      </div>

      <button
        class="border-2 border-dashed rounded-md p-1 w-full border-grayShadow"
        aria-label="Add element"
        @click="handleAdd"
      >
        <fa icon="plus" />
      </button>
    </div>

    <div class="flex items-end -mb-10 mt-3">
      <div class="flex-1" />
      <LobButton class="mr-3" variant="secondary" @click="close">
        Cancel
      </LobButton>
      <LobButton @click="handleNewTestSend">Create</LobButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SearchAndSelect from '@/components/common/SearchAndSelect.vue'
import SearchAndMultiSelect from '@/components/common/SearchAndMultiSelect.vue'
import {
  getTestGroups,
  SearchLabel,
  TestGroup,
  createNewTestSend,
  getSimpleTestCases,
  SimpleTestCaseList
} from '@/store/customMail'
import PackageTextInput from '../../customMail/common/PackageTextInput.vue'
import CircleXMark from '@/components/common/icons/CircleXMark.vue'
import { getLivePartners } from '@/store/partners'

export default defineComponent({
  name: 'TestSendModal',
  components: {
    SearchAndSelect,
    SearchAndMultiSelect,
    PackageTextInput,
    CircleXMark
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['close'],
  data() {
    return {
      selectedPartners: [] as SearchLabel[],
      partnersList: [] as SearchLabel[],
      selectedCases: [{ label: '', value: '', quantity: '' }] as {
        label: string
        value: string
        quantity: string
      }[],
      casesAndGroupsList: [] as SearchLabel[],
      testSendDescription: '',
      includeSecureDestructionStid: false,
      name: ''
    }
  },
  async mounted() {
    const partners = await getLivePartners()
    this.partnersList = partners.map((partner) => {
      return {
        label: partner.display_name,
        value: partner.id
      }
    })
    const testCases = (await getSimpleTestCases({})) as SimpleTestCaseList
    this.casesAndGroupsList = testCases.data.map((tc) => {
      return {
        label: `${tc.id} : ${tc.name}`,
        value: tc.id || ''
      }
    })
    const testGroups = (await getTestGroups({})) as {
      count: number
      data: TestGroup[]
    }
    const formattedTestGroups = testGroups.data.map((tg) => {
      return {
        label: `${tg.id} : ${tg.name}`,
        value: tg.id || ''
      }
    })
    this.casesAndGroupsList =
      this.casesAndGroupsList.concat(formattedTestGroups)
  },
  methods: {
    close() {
      this.$emit('close', {})
    },
    async handleTestCaseSearch(term) {
      const testCases = await getSimpleTestCases({ search: term })
      let casesAndGroupsList = [] as SearchLabel[]
      casesAndGroupsList = testCases.data.map((tc) => {
        return {
          label: `${tc.id} : ${tc.name}`,
          value: tc.id || ''
        }
      })
      const testGroups = (await getTestGroups({ search: term })) as {
        count: number
        data: TestGroup[]
      }
      const formattedTestGroups = testGroups.data.map((tg) => {
        return {
          label: `${tg.id} : ${tg.name}`,
          value: tg.id || ''
        }
      })
      return casesAndGroupsList.concat(formattedTestGroups)
    },
    async handleMultiSelectPartnerSearch(term) {
      return this.partnersList.filter(
        (partner: SearchLabel) =>
          partner.value.includes(term) || partner.label.includes(term)
      )
    },
    handleAdd() {
      this.selectedCases.push({ label: '', value: '', quantity: '' })
    },
    removeTestCase(index) {
      this.selectedCases.splice(index, 1)
    },
    handleMultiSelect(result) {
      this.selectedPartners = result
    },
    handleTestCaseSelect(result, index) {
      this.selectedCases[index].label = result.label
      this.selectedCases[index].value = result.value
    },
    handleDeselect(index) {
      this.selectedCases[index].label = ''
      this.selectedCases[index].value = ''
    },
    async handleNewTestSend() {
      let stid: string | null = null
      const formattedPartnerIds = this.selectedPartners.map(
        (partner) => partner.value
      )
      const formattedTestCasesGroups = this.selectedCases.map((tcg) => {
        return {
          id: tcg.value,
          quantity: parseInt(tcg.quantity)
        }
      })
      if (this.includeSecureDestructionStid) {
        stid = '331'
      }
      await createNewTestSend({
        name: this.name,
        description: this.testSendDescription,
        partner_ids: formattedPartnerIds,
        test_case_ids: formattedTestCasesGroups,
        overrides: {
          stid
        }
      })
      this.$emit('close', { refreshData: true })
    }
  }
})
</script>
