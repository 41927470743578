<script lang="ts">
import { ref, computed, PropType, reactive } from 'vue'
import { defineComponent, watch } from '@vue/runtime-core'
import { AxiosResponse } from 'axios'
import { addBusinessDays, formatDuration, intervalToDuration } from 'date-fns'
import { captureException } from '@/plugins/sentry'
import {
  getIsPrivilegedUserFlag,
  getShowBetaFeatureFlag
} from '@/store/launchDarkly'
import { sendPapiRequest, sendRoutingServiceRequest } from '@/store/requests'
import {
  getPartnersGroupedByStatus,
  refreshAllPartners,
  Partner,
  PartnerCapacity,
  LiveCapacity
} from '@/store/partners'
import Pane from '@/components/common/Pane.vue'
import StatusLinkCard from '@/components/common/StatusLinkCard.vue'
import InvitePartnerModal from '@/components/partners/InvitePartnerModal.vue'
import { PartnersCapacities } from '@/consts'
import { DateObj, setTimeInTimezone, format } from '@/utils/date'
import BulkEditCapacityModal from './BulkEditCapacityModal.vue'
import ConfirmationModal from '@/components/global/ConfirmationModal.vue'
import DeleteCapacityModal from './DeleteCapacityModal.vue'

const CUT_OFF_HOURS = 12

const LEGACY_CAPACITY_MODELS = [
  'letters',
  'checks',
  'postcards',
  'self_mailers'
]

interface Tab {
  key: string
  days: number
  flags: {
    betaFeatures: boolean
    privilegedUser: boolean
  }
}

interface ComputedTab extends Omit<Tab, 'days' | 'flags'> {
  class: any[]
  queryDate: string
  displayDate: string
  cutOff: string
  remaining: string
  producing: string
}

type GenericCapacityUpdate = {
  resource_type: string
  regular_daily_capacity?: number
  one_day_sla_daily_capacity?: number
  one_day_sla_multiplier?: number
}

const TABS: Tab[] = [
  {
    key: 'T0',
    days: -1,
    flags: {
      betaFeatures: true,
      privilegedUser: false
    }
  },
  {
    key: 'T1',
    days: 0,
    flags: {
      betaFeatures: false,
      privilegedUser: true
    }
  },
  {
    key: 'T2',
    days: 1,
    flags: {
      betaFeatures: true,
      privilegedUser: false
    }
  },
  {
    key: 'T3',
    days: 2,
    flags: {
      betaFeatures: true,
      privilegedUser: false
    }
  },
  {
    key: 'T4',
    days: 3,
    flags: {
      betaFeatures: true,
      privilegedUser: false
    }
  }
]

const getTabs = (
  activeTab: string,
  enableBetaFeature: boolean,
  isPrivilegedUser: boolean
): ComputedTab[] =>
  TABS.filter(
    (t) =>
      enableBetaFeature ||
      !t.flags.betaFeatures ||
      // eslint-disable-next-line prettier/prettier
      (isPrivilegedUser && t.flags.privilegedUser)
  ).map(({ key, days }) => {
    const timeZone = 'America/Los_Angeles'
    const date = addBusinessDays(DateObj(), days) // skip weekends

    const cutoffDate = setTimeInTimezone(
      addBusinessDays(date, 1),
      CUT_OFF_HOURS,
      0,
      0,
      0,
      timeZone
    )

    const productionDate = addBusinessDays(cutoffDate, 1)

    const remainingTime = formatDuration(
      intervalToDuration({
        start: DateObj(),
        end: cutoffDate.getTime()
      }),
      { format: ['days', 'hours', 'minutes'] }
    )

    return {
      key,
      class: [
        'px-3 py-3 font-light focus:outline-none focus:ring-2 focus:ring-primary-100 focus:border-transparent',
        { 'font-normal border-b border-primary': activeTab === key }
      ],
      cutOff: `${format(cutoffDate, 'M/d/Y HH:mm')} PST`,
      remaining: remainingTime,
      producing: `2 Day SLA - ${format(productionDate, 'M/d/Y')}`,
      queryDate: format(date, 'yyyy-MM-dd'),
      displayDate: `${format(date, 'MM/dd/yyyy')}`
    }
  })

const transformLiveCapacity = (item: LiveCapacity) => {
  const finalObj = {
    checks: {
      default: item.checks?.num_sheets_remaining ?? 0,
      one_day_sla: item.checks?.one_day_sla_num_sheets_remaining ?? 0
    },
    postcards: {
      default: item.postcards?.num_postcards_remaining ?? 0,
      one_day_sla: item.postcards?.one_day_sla_num_postcards_remaining ?? 0
    },
    letters: {
      default: item.letters?.num_sheets_remaining ?? 0,
      one_day_sla: item.letters?.one_day_sla_num_sheets_remaining ?? 0
    },
    self_mailers: {
      default: item.self_mailers?.num_self_mailers_remaining ?? 0,
      one_day_sla:
        item.self_mailers?.one_day_sla_num_self_mailers_remaining ?? 0
    }
  }
  if (item.generic) {
    item.generic.forEach((g) => {
      finalObj[g.resource_type] = {
        default: g.num_units_remaining,
        one_day_sla: g.one_day_sla_num_units_remaining
      }
    })
  }
  return finalObj
}

const transformCapacity = (item: PartnerCapacity) => {
  const finalObj = {
    checks: {
      id: item.checks?.id,
      default: item.checks?.sheets_per_day,
      one_day_sla: item.checks?.one_day_sla_daily_capacity,
      one_day_sla_multiplier: item.checks?.one_day_sla_multiplier
    },
    postcards: {
      id: item.postcards?.id,
      default: item.postcards?.postcards_per_day,
      one_day_sla: item.postcards?.one_day_sla_daily_capacity,
      one_day_sla_multiplier: item.postcards?.one_day_sla_multiplier
    },
    letters: {
      id: item.letters?.id,
      default: item.letters?.sheets_per_day,
      one_day_sla: item.letters?.one_day_sla_daily_capacity,
      one_day_sla_multiplier: item.letters?.one_day_sla_multiplier
    },
    self_mailers: {
      id: item.self_mailers?.id,
      default: item.self_mailers?.self_mailers_per_day,
      one_day_sla: item.self_mailers?.one_day_sla_daily_capacity,
      one_day_sla_multiplier: item.self_mailers?.one_day_sla_multiplier
    }
  }

  item?.generic?.forEach((g) => {
    finalObj[g.resource_type] = {
      id: g.id,
      default: g.units_per_day,
      one_day_sla: g.one_day_sla_daily_capacity,
      one_day_sla_multiplier: g.one_day_sla_multiplier
    }
  })
  return finalObj
}

const fetchCapacities = async (
  date: string
): Promise<[PartnersCapacities, PartnersCapacities]> => {
  const [liveCap, cap] = await Promise.all([
    sendPapiRequest({ path: `/capacities/live?date=${date}`, method: 'GET' }),
    sendPapiRequest({ path: `/capacities?date=${date}`, method: 'GET' })
  ])

  return [
    liveCap.data.data.reduce((acc, item) => {
      acc[item.partner] = transformLiveCapacity(item.capacity)
      return acc
    }, {}),
    cap.data.data.reduce((acc, item) => {
      acc[item.partner_id] = transformCapacity(item)
      return acc
    }, {})
  ]
}

const updateStatus = (partner: Partner): Promise<AxiosResponse<void>> =>
  sendPapiRequest({
    path: `/partners/${partner.id}/transition`,
    method: 'POST',
    data: {
      status: {
        prospecting: 'testing',
        testing: 'live'
      }[partner.status]
    }
  })

const deletePartner = (partner: Partner): Promise<AxiosResponse<void>> =>
  sendPapiRequest({
    path: `/partners/${partner.id}`,
    method: 'DELETE'
  })

const updateLiveCapacities = async (partnerId: string, data) => {
  let ok: boolean
  try {
    const result = await sendPapiRequest({
      path: `/partners/${partnerId}/capacities/live`,
      method: 'PUT',
      data: Object.assign(
        {
          checks: {
            quantity: 0,
            sla: 2
          },
          letters: {
            quantity: 0,
            sla: 2
          },
          postcards: {
            quantity: 0,
            sla: 2
          },
          self_mailers: {
            quantity: 0,
            sla: 2
          }
        },
        data
      )
    })
    ok = result.status === 204
  } catch (err) {
    captureException(err as Error)
    ok = false
  }

  return ok
}

const updateTotalCapacities = async (partnerId: string, data) => {
  let ok: boolean
  try {
    const result = await sendPapiRequest({
      path: `/partners/${partnerId}/capacities`,
      method: 'PUT',
      data
    })
    ok = result.status === 204
  } catch (err) {
    captureException(err as Error)
    ok = false
  }

  return ok
}

export default defineComponent({
  components: {
    Pane,
    StatusLinkCard,
    InvitePartnerModal,
    BulkEditCapacityModal,
    ConfirmationModal,
    DeleteCapacityModal
  },
  props: {
    panesOpen: {
      type: Array as PropType<string[]>,
      default: () => ['live']
    }
  },
  setup() {
    const capacityBulkEditProps = reactive({
      partner: '',
      resource: '',
      daily: 0,
      monthly: 0,
      isModalVisible: false
    })
    const currentEdit = reactive({
      id: '',
      resource: '',
      slaType: ''
    })
    const editError = ref<string>('')
    const activeTab = ref('T1')
    const focusPane = ref('')
    const groupedPartners = computed(getPartnersGroupedByStatus)
    const showBetaFeatureFlag = computed<boolean>(getShowBetaFeatureFlag)
    const isPrivilegedUserFlag = computed<boolean>(getIsPrivilegedUserFlag)
    const tabs = computed<ComputedTab[]>(() =>
      getTabs(
        activeTab.value,
        showBetaFeatureFlag.value,
        isPrivilegedUserFlag.value
      )
    )
    const liveCapacities = ref<PartnersCapacities>({})
    const capacities = ref<PartnersCapacities>({})
    const isLoading = ref(true)
    const isInviteModalOpen = ref(false)
    const isDeleteModalVisible = ref(false)
    const partnerToDelete = ref<Partner | null>(null)
    const error = ref('')
    const transitionError = reactive({ partnerId: '', message: '' })
    const deleteError = ref<string>('')
    const titles = computed(() => ({
      live: `Live Partners (${groupedPartners.value?.live?.length})`,
      prospecting: `Prospecting Partners (${groupedPartners.value?.prospecting?.length})`,
      testing: `Testing Partners (${groupedPartners.value?.testing?.length})`
    }))
    const isDeleteCapacityModalVisible = ref(false)
    const deleteCapacityPartnerID = ref('')
    const deleteCapacityResource = ref('')
    const deleteCapacityTotalCapacityID = ref('')
    const deleteCapacityPartnerName = ref('')

    const loadData = async () => {
      try {
        const tab = tabs.value.find(({ key }) => key === activeTab.value)
        if (!tab) {
          throw new Error('Tab not found')
        }
        isLoading.value = true
        error.value = ''
        const [liveCap, cap] = await fetchCapacities(tab.queryDate)
        liveCapacities.value = liveCap
        capacities.value = cap
      } catch (err) {
        error.value = 'Capacities failed to fetch'
      } finally {
        isLoading.value = false
      }
    }

    const handleTransition = async (partner: Partner) => {
      try {
        transitionError.message = ''
        await updateStatus(partner)
        await loadData()
      } catch (e) {
        const resp = e.response?.data?.error
        transitionError.partnerId = partner.id
        transitionError.message = `${partner.display_name} - ${resp?.message}.`
        captureException(resp)
      }
    }

    const handleDelete = async () => {
      try {
        deleteError.value = ''
        await deletePartner(partnerToDelete.value as Partner)
        await refreshAllPartners()
        await loadData()
        toggleDeleteModal(false, null)
      } catch (e) {
        const resp = e.response?.data?.error
        deleteError.value = `${partnerToDelete.value!.display_name} - ${
          resp?.message
        }.`
        captureException(resp)
      }
    }

    const toggleDeleteModal = (state: boolean, partner: Partner | null) => {
      isDeleteModalVisible.value = state
      partnerToDelete.value = partner
    }

    const handleCreatePartner = async () => {
      isInviteModalOpen.value = false
      isLoading.value = true
      await Promise.all([await refreshAllPartners(), await loadData()])

      focusPane.value = 'prospecting'
    }

    watch(activeTab, loadData, {
      immediate: Boolean(tabs.value.find((t) => t.key === activeTab.value))
    })

    const onCapacityEdit = (
      partnerId: string,
      event: { resource: string; slaType: string }
    ) => {
      return Object.assign(currentEdit, {
        id: partnerId,
        resource: event.resource,
        slaType: event.slaType
      })
    }

    const createCapacity = async (partnerId: string, resource: string) => {
      const defaults = {
        partner_id: partnerId,
        one_day_sla_daily_capacity: 0,
        one_day_sla_multiplier: 1
      }
      let formFactorCapacities: any = {}
      if (resource === 'letters' || resource === 'checks') {
        formFactorCapacities = {
          ...formFactorCapacities,
          [resource]: {
            ...defaults,
            sheets_per_day: 0,
            min_sheets_per_month: 0
          }
        }
      } else if (resource === 'postcards') {
        formFactorCapacities = {
          ...formFactorCapacities,
          postcards: {
            ...defaults,
            postcards_per_day: 0,
            min_postcards_per_month: 0
          }
        }
      } else if (resource === 'self_mailers') {
        formFactorCapacities = {
          ...formFactorCapacities,
          self_mailers: {
            ...defaults,
            self_mailers_per_day: 0,
            min_self_mailers_per_month: 0
          }
        }
      } else {
        formFactorCapacities = {
          ...formFactorCapacities,
          generic: [
            {
              ...defaults,
              resource_type: resource,
              units_per_day: 0,
              min_units_per_month: 0
            }
          ]
        }
      }

      const result = await sendPapiRequest({
        path: `/partners/${partnerId}/capacities`,
        method: 'POST',
        data: {
          partner_id: partnerId,
          ...formFactorCapacities
        }
      })

      const totalCapacity = {
        id: result.data[resource]?.id || result.data.generic[0]?.id,
        default: 0,
        one_day_sla: 0,
        one_day_sla_multiplier: 1
      }

      const liveCapacity = {
        default: 0,
        one_day_sla: 0
      }

      capacities.value = {
        ...capacities.value,
        [partnerId]: {
          ...capacities.value[partnerId],
          [resource]: totalCapacity
        }
      }

      liveCapacities.value = {
        ...capacities.value,
        [partnerId]: {
          ...liveCapacities.value[partnerId],
          [resource]: liveCapacity
        }
      }
    }

    const openDeleteCapacityModal = (event) => {
      deleteCapacityPartnerID.value = event.partnerID
      deleteCapacityPartnerName.value = event.partnerName
      deleteCapacityTotalCapacityID.value = event.totalCapacityID
      deleteCapacityResource.value = event.resource
      isDeleteCapacityModalVisible.value = true
    }

    const closeDeleteCapacityModal = () => {
      isDeleteCapacityModalVisible.value = false
    }

    const deletePartnerCapacity = async ({
      partner,
      totalCapacityID,
      resource
    }: {
      partner: string
      totalCapacityID: string
      resource: string
    }) => {
      // soft total capacity from partners-api
      await sendPapiRequest({
        path: `/partners/${partner}/capacities/${totalCapacityID}`,
        method: 'DELETE'
      })

      // set live capacity to 0 in routing service
      const routingServiceResourceString = resource.substring(
        0,
        resource.length - 1
      )
      await sendRoutingServiceRequest({
        path: `/capacities/set`,
        method: 'POST',
        data: {
          partner,
          capacities: [
            {
              type: routingServiceResourceString,
              quantity: 0,
              sla: 1
            },
            {
              type: routingServiceResourceString,
              quantity: 0,
              sla: 2
            }
          ]
        }
      })
      capacities.value = {
        ...capacities.value,
        [partner]: {
          ...capacities.value[partner],
          [resource]: undefined
        }
      }

      liveCapacities.value = {
        ...capacities.value,
        [partner]: {
          ...liveCapacities.value[partner],
          [resource]: undefined
        }
      }
      isDeleteCapacityModalVisible.value = false
    }

    const onCapacityUpdate = async (
      partnerId: string,
      {
        resource,
        slaType,
        live,
        total,
        one_day_sla_multiplier
      }: {
        resource: string
        slaType: string
        live: number
        total: number
        one_day_sla_multiplier?: number
      }
    ) => {
      const isOneDaySLA = slaType === 'one_day_sla'
      editError.value = ''
      if (!partnerId || !capacities.value) {
        Object.assign(currentEdit, { id: '', resource: '', slaType: '' })
      }

      const partnerLiveCapacity = { generic: [] as any[] }
      for (const capacity in liveCapacities.value[partnerId]) {
        if (LEGACY_CAPACITY_MODELS.includes(capacity)) {
          if (isOneDaySLA) {
            partnerLiveCapacity[capacity] = {
              quantity:
                capacity === resource
                  ? live
                  : liveCapacities.value[partnerId][capacity].one_day_sla,
              sla: 1
            }
          } else {
            partnerLiveCapacity[capacity] = {
              quantity:
                capacity === resource
                  ? live
                  : liveCapacities.value[partnerId][capacity].default,
              sla: 2
            }
          }
        } else {
          const valueKey = isOneDaySLA ? 'one_day_sla' : 'default'
          const sla = isOneDaySLA ? 1 : 2
          partnerLiveCapacity.generic.push({
            resource_type: resource,
            quantity:
              capacity === resource
                ? live
                : liveCapacities.value[partnerId][capacity][valueKey],
            sla
          })
        }
      }

      const partnerTotalCapacity: Record<string, any> = {}

      if (LEGACY_CAPACITY_MODELS.includes(resource)) {
        if (isOneDaySLA) {
          partnerTotalCapacity.one_day_sla = {}
          partnerTotalCapacity.one_day_sla[resource] = {
            daily_capacity: total,
            multiplier: one_day_sla_multiplier
          }
        } else {
          partnerTotalCapacity[resource] = total
        }
      } else {
        const capObj = { resource_type: resource } as GenericCapacityUpdate
        if (isOneDaySLA) {
          capObj.one_day_sla_daily_capacity = total
          capObj.one_day_sla_multiplier =
            one_day_sla_multiplier ||
            capacities.value[partnerId][resource].one_day_sla_multiplier
        } else {
          capObj.regular_daily_capacity = total
        }

        partnerTotalCapacity.generic = [capObj]
      }

      const [okLive, okTotal] = await Promise.all([
        updateLiveCapacities(partnerId, partnerLiveCapacity),
        updateTotalCapacities(partnerId, partnerTotalCapacity)
      ])

      if (okLive) {
        const previousPartnerLiveCapacity =
          liveCapacities.value[partnerId][resource]
        const updatedPartnerLiveCapacity = {
          ...liveCapacities.value[partnerId],
          [resource]: {
            default: isOneDaySLA ? previousPartnerLiveCapacity.default : live,
            one_day_sla: isOneDaySLA
              ? live
              : previousPartnerLiveCapacity.one_day_sla
          }
        }
        liveCapacities.value = {
          ...capacities.value,
          [partnerId]: updatedPartnerLiveCapacity
        }
      } else {
        editError.value = 'There was an error updating live capacities'
      }

      if (okTotal) {
        const previousPartnerCapacity = capacities.value[partnerId][resource]
        const updatedPartnerCapacity = {
          ...capacities.value[partnerId],
          [resource]: {
            default: isOneDaySLA ? previousPartnerCapacity.default : total,
            one_day_sla: isOneDaySLA
              ? total
              : previousPartnerCapacity.one_day_sla,
            one_day_sla_multiplier: isOneDaySLA
              ? one_day_sla_multiplier
              : previousPartnerCapacity.one_day_sla_multiplier
          }
        }
        capacities.value = {
          ...capacities.value,
          [partnerId]: {
            ...capacities.value[partnerId],
            ...updatedPartnerCapacity
          }
        }
      } else {
        editError.value = editError.value
          ? 'There was an error updating capacities'
          : 'There was an error updating total capacities'
      }

      Object.assign(currentEdit, { id: '', resource: '', slaType: '' })
    }

    const onCapacityBulkEdit = (
      partnerId: string,
      { resource, slaType }: { resource: string; slaType: string }
    ) => {
      const partner = groupedPartners.value?.live.find(
        (p) => p.id === partnerId
      )
      if (!partner) {
        return
      }

      Object.assign(capacityBulkEditProps, {
        partnerId,
        partner: partner.display_name,
        resource,
        defaultDaily: capacities.value[partnerId][resource].default,
        oneDaySLADaily: capacities.value[partnerId][resource].one_day_sla,
        oneDaySLAMultiplier:
          capacities.value[partnerId][resource].one_day_sla_multiplier,
        monthly: 0,
        isModalVisible: true,
        slaType
      })
    }

    return {
      activeTab,
      capacities,
      currentEdit,
      editError,
      error,
      focusPane,
      groupedPartners,
      handleCreatePartner,
      handleTransition,
      handleDelete,
      toggleDeleteModal,
      isInviteModalOpen,
      isDeleteModalVisible,
      isLoading,
      liveCapacities,
      onCapacityEdit,
      onCapacityUpdate,
      capacityBulkEditProps,
      onCapacityBulkEdit,
      showBetaFeatureFlag,
      isPrivilegedUserFlag,
      tabs,
      titles,
      transitionError,
      deleteError,
      createCapacity,
      openDeleteCapacityModal,
      closeDeleteCapacityModal,
      isDeleteCapacityModalVisible,
      deleteCapacityPartnerID,
      deleteCapacityPartnerName,
      deleteCapacityResource,
      deleteCapacityTotalCapacityID,
      deletePartnerCapacity
    }
  }
})
</script>

<template title="Partners">
  <BulkEditCapacityModal
    v-if="capacityBulkEditProps.isModalVisible"
    :partner="capacityBulkEditProps.partner"
    :resource="capacityBulkEditProps.resource"
    :default-daily="capacityBulkEditProps.defaultDaily"
    :one-day-sla-daily="capacityBulkEditProps.oneDaySLADaily"
    :one-day-sla-multiplier="capacityBulkEditProps.oneDaySLAMultiplier"
    :monthly="capacityBulkEditProps.monthly"
    :is-modal-visible="true"
    @close="capacityBulkEditProps.isModalVisible = false"
  />
  <div class="content p-5 bg-white-100 h-min-content min-h-screen">
    <h2 class="text-3xl mb-8 font-bold">Print Partners</h2>
    <div
      class="mb-12 flex w-full justify-between align-items-end border-b border-gray-100"
    >
      <nav v-if="tabs.length">
        <button
          v-for="tab in tabs"
          :key="tab.key"
          :class="tab.class"
          :data-testId="tab.key"
          @click="activeTab = tab.key"
        >
          <template v-if="tab.cutOff">
            <p class="font-bold">{{ tab.displayDate }}</p>
            <p class="text-xs font-light">Cutoff: {{ tab.cutOff }}</p>
            <p class="text-xs font-light">Remaining: {{ tab.remaining }}</p>
            <p class="text-xs font-light">Producing: {{ tab.producing }}</p>
          </template>
        </button>
      </nav>
      <span v-else>Coming soon</span>
      <LobButton
        v-if="showBetaFeatureFlag"
        class="mb-3"
        @click="isInviteModalOpen = true"
      >
        Invite new partner
        <Plus class="w-6 h-6" />
      </LobButton>
      <InvitePartnerModal
        :is-open="isInviteModalOpen"
        @close="isInviteModalOpen = false"
        @saved="handleCreatePartner"
      />
    </div>

    <Alert v-if="error" variant="error">
      {{ error }}
    </Alert>
    <Alert v-if="transitionError.message" variant="error">
      {{ transitionError.message }}
      &nbsp;
      <RouterLink
        v-if="transitionError.message.includes('sftp_reports')"
        :to="`/partners/${transitionError.partnerId}/onboarding/sftp-setup`"
      >
        Please update SFTP Reports here.
      </RouterLink>
    </Alert>
    <Alert v-if="editError" variant="error">{{ editError }}</Alert>
    <Alert v-if="deleteError" variant="error">{{ deleteError.message }}</Alert>
    <ConfirmationModal
      :visible="isDeleteModalVisible"
      :title="'Confirm partner deletion?'"
      :confirm-button-label="'Delete'"
      @updated="handleDelete()"
      @reset="() => toggleDeleteModal(false, null)"
    />
    <DeleteCapacityModal
      :partner="deleteCapacityPartnerID"
      :partner-display-name="deleteCapacityPartnerName"
      :resource="deleteCapacityResource"
      :total-capacity-i-d="deleteCapacityTotalCapacityID"
      :is-modal-visible="isDeleteCapacityModalVisible"
      @deletePartnerCapacity="deletePartnerCapacity"
      @close="closeDeleteCapacityModal"
    />
    <LoadingIndicator v-if="tabs.length">
      <main v-if="!isLoading">
        <Pane
          v-for="(partners, status) in groupedPartners"
          :key="status"
          :data-testid="`${status}-pane`"
          :subtitle="titles[status]"
          :shadow="false"
          :force-open="panesOpen?.includes(status) || focusPane === status"
          :has-aside="false"
          class="my-4"
        >
          <Alert v-if="!partners?.length">No partners found</Alert>
          <div class="grid grid-cols-3 gap-8 py-3">
            <StatusLinkCard
              v-for="partner in partners"
              :key="partner.id"
              :partner-i-d="partner.id"
              :title="partner.display_name"
              :data-testid="`card-${partner.id}`"
              :partner-status="status"
              :url="`/partners/${partner.id}`"
              :live-capacities="liveCapacities[partner.id]"
              :total-capacities="capacities[partner.id]"
              :resource-to-edit="
                currentEdit.id === partner.id ? currentEdit.resource : ''
              "
              :sla-to-edit="
                currentEdit.id === partner.id ? currentEdit.slaType : ''
              "
              :show-bulk-edit="showBetaFeatureFlag || isPrivilegedUserFlag"
              @transition="() => handleTransition(partner)"
              @delete="() => toggleDeleteModal(true, partner)"
              @bulkEdit="onCapacityBulkEdit(partner.id, $event)"
              @edit="onCapacityEdit(partner.id, $event)"
              @update="onCapacityUpdate(partner.id, $event)"
              @create="createCapacity(partner.id, $event)"
              @openDeleteModal="openDeleteCapacityModal($event)"
            />
          </div>
        </Pane>
      </main>
    </LoadingIndicator>
  </div>
</template>
