/* eslint-disable */
import config from '../../config'

const init = () => {
  try {
    !(function () {
      var analytics = window.analytics = window.analytics || [];if (!analytics.initialize) {
        if (analytics.invoked) {
          // eslint-disable-next-line no-console
          window.console && console.error && console.error('Segment snippet included twice.');
        } else {
          analytics.invoked = !0;analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'];analytics.factory = function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics;
            };
          };for (var e = 0;e < analytics.methods.length;e++) {
            var key = analytics.methods[e];analytics[key] = analytics.factory(key);
          }analytics.load = function (key, e) {
            var t = document.createElement('script');t.type = 'text/javascript';t.async = !0;t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';var n = document.getElementsByTagName('script')[0];n.parentNode.insertBefore(t, n);analytics._loadOptions = e;
          };analytics._writeKey = config.SEGMENT_KEY;analytics.SNIPPET_VERSION = '4.13.2';
          analytics.load(config.SEGMENT_KEY);
        }
      }
    }());
  } catch (error) {
    console.error('Error initializing Segment', { error });
  }
};

export const $segment = {
  alias: (userId, previousId = undefined, options = undefined, callback = undefined) => {
    window.analytics.ready(() => {
      window.analytics.alias(userId, previousId, options, callback);
    });
  },
  identify: (userId, traits, options = undefined, callback = undefined) => {
    window.analytics.ready(() => {
      window.analytics.identify(userId, traits, options, callback);
    });
  },
  track: (event, properties = undefined, options = undefined, callback = undefined) => {
    window.analytics.ready(() => {
      window.analytics.track(event, properties, options, callback);
    });
  },
  page: (category = undefined, name, properties = undefined, options = undefined, callback = undefined) => {
    window.analytics.ready(() => {
      window.analytics.page(category, name, properties, options, callback);
    });
  }
};

export default {
  install: (app) => {
    init();
    app.config.globalProperties.$segment = $segment;
    app.provide('$segment', $segment);
  }
};
