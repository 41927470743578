import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'

export default {
  path: 'account-overview',
  component: View,
  children: [
    {
      path: '/partner_ops/accounts/:crid/:resource',
      component: View
    }
  ]
} as RouteRecordRaw
