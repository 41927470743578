<template>
  <tr class="bg-mist py-4 px-3 table-row mt-20 shadow-md w-100 pb-10">
    <td class="p-3">{{ item.id }}</td>
    <td v-if="isAdminOrPopsUser" class="p-3">{{ item.account_id }}</td>
    <td v-if="isAdminOrPopsUser" class="p-3">
      {{ item.customerName ?? 'N/A' }}
    </td>
    <td class="p-3">{{ item.title }}</td>
    <td class="p-3" colspan="2">{{ item.description }}</td>
    <td class="p-3 capitalize flex items-center">
      <ToggleButton
        v-if="isStatusEnabled(item)"
        class="mt-2"
        label=""
        :disabled="hasNoBids(item)"
        :name="item.id"
        :model-value="formateToggleValue(item)"
        @update:modelValue="updatePackageStatus(item)"
      />
      <Badge :variant="configureStatus(item.status)">
        {{ formatStatus(item.status) }}
      </Badge>
    </td>
    <td class="p-3">{{ formatDate(item.date_modified) }}</td>
    <td class="p-3">
      <div class="flex">
        <Tooltip position="top">
          <template #trigger>
            <fa
              class="tertiary-bluebird cursor-pointer ml-1 w-5 h-5"
              :class="{ 'cursor-not-allowed': printManifestDownloadError }"
              icon="fa-file-lines"
              @click="downloadManifest()"
            />
          </template>
          <template #content>{{ printManifestTooltipText }}</template>
        </Tooltip>
        <Tooltip position="top">
          <template #trigger>
            <fa
              class="tertiary-bluebird cursor-not-allowed ml-1 w-5 h-5"
              icon="fa-image"
            />
          </template>
          <template #content>{{ creativeDownloadTooltipText }}</template>
        </Tooltip>
      </div>
    </td>
    <td v-if="isAdminOrPopsUser" class="p-3">
      <div class="flex">
        <Edit
          class="mr-2 opacity-40 w-6 h-6 cursor-pointer"
          @click="openPackagePage('edit', item)"
        />
        <Copy
          class="opacity-40 w-6 h-6 cursor-pointer"
          icon="clone"
          @click="openCloneModal(item)"
        />
      </div>
    </td>
    <td v-if="!isAdminOrPopsUser" class="p-3">
      <fa
        class="opacity-40"
        icon="eye"
        @click="openPackagePage('view', item)"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  getBasePath,
  PackagePageData,
  setCurrentUserAction,
  setPackagePageData,
  updatePackageStatus,
  getPrintingManifest
} from '@/store/customMail'
import router from '@/router'
import { CUSTOM_MAIL_CONSTS } from '@/consts'

export default defineComponent({
  name: 'PackagesTableRow',
  props: {
    item: { type: Object, required: true },
    isAdminOrPopsUser: { type: Boolean, required: true }
  },
  emits: ['openCloneModal', 'alertMsgEvent'],
  data() {
    return {
      printManifestTooltipText: 'Download Printing Manifest',
      printManifestDownloadError: false,
      creativeDownloadTooltipText: 'NYI: Download Creative'
    }
  },
  methods: {
    configureStatus(rowStatus: string) {
      switch (rowStatus) {
        case 'created':
          return 'warning'
        case 'lob_approved':
          return 'success'
        default:
          return 'info'
      }
    },
    formatStatus(rowStatus: string) {
      if (rowStatus === 'lob_approved') {
        return 'Lob Approved'
      }
      return rowStatus
    },
    openPackagePage(type: string, item: PackagePageData) {
      setCurrentUserAction(`package-page-${type}`)
      setPackagePageData(item)
      const basePath = getBasePath()
      router.push({
        path: `/${basePath}/${item.id}`,
        params: {
          packageID: item.id
        }
      })
    },
    openCloneModal(item: any) {
      this.$emit('openCloneModal', item)
    },
    formatDate(rawDate: string) {
      if (!rawDate) return rawDate
      const dayDate = rawDate.split('T')[0] // 1000-01-01
      const isolatedDate = dayDate.split('-') // [1000, 01, 01]
      return `${isolatedDate[1]}/${isolatedDate[2]}/${isolatedDate[0]}`
    },
    isStatusEnabled(item: any) {
      return (
        item.status === CUSTOM_MAIL_CONSTS.PACKAGE_PARTNER_APPROVED_STATUS ||
        item.status === CUSTOM_MAIL_CONSTS.PACKAGE_CUSTOMER_APPROVED_STATUS
      )
    },
    hasNoBids(item: any) {
      return (
        item.partners.findIndex(
          (partnerBid) =>
            partnerBid.status === CUSTOM_MAIL_CONSTS.BID_PARTNER_STATUS_ENABLED
        ) === -1 &&
        item.status !== CUSTOM_MAIL_CONSTS.PACKAGE_CUSTOMER_APPROVED_STATUS
      )
    },
    async updatePackageStatus(item: any) {
      if (item.status === CUSTOM_MAIL_CONSTS.PACKAGE_PARTNER_APPROVED_STATUS) {
        await updatePackageStatus({
          packageID: item.id,
          status: CUSTOM_MAIL_CONSTS.PACKAGE_CUSTOMER_APPROVED_STATUS
        })
        item.status = CUSTOM_MAIL_CONSTS.PACKAGE_CUSTOMER_APPROVED_STATUS
      } else if (
        item.status === CUSTOM_MAIL_CONSTS.PACKAGE_CUSTOMER_APPROVED_STATUS
      ) {
        await updatePackageStatus({
          packageID: item.id,
          status: CUSTOM_MAIL_CONSTS.PACKAGE_PARTNER_APPROVED_STATUS
        })
        item.status = CUSTOM_MAIL_CONSTS.PACKAGE_PARTNER_APPROVED_STATUS
      }
    },
    formateToggleValue(item: any) {
      return item.status === CUSTOM_MAIL_CONSTS.PACKAGE_CUSTOMER_APPROVED_STATUS
    },
    /**
     * downloadManifest queries CMB for a product's manifest, and generates
     * a file on the fly with the json contents of that query result.
     * NOTE: as of right now, non-existent manifests are returned as 200's,
     * so there's no way of validating the contents of the response
     */
    async downloadManifest() {
      if (this.printManifestDownloadError) return
      // Shamelessly stolen from https://stackoverflow.com/a/18197341
      try {
        const res = await getPrintingManifest({ packageID: this.item.id })
        const element = document.createElement('a')
        element.setAttribute(
          'href',
          `data:text/plain;charset=utf-8,${encodeURIComponent(
            JSON.stringify(res, undefined, 2)
          )}`
        )
        element.setAttribute('download', `${this.item.id}.json`)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
        // emit an empty string as alert message so that any old alerts on the parent get cleared
        this.$emit('alertMsgEvent', '')
      } catch (e) {
        this.$emit(
          'alertMsgEvent',
          `Unable to download manifest for ${this.item.id}: ${e}`
        )
        // if there's an error, block out downloading again & update tooltip
        this.printManifestTooltipText = `Unable to download manifest: ${e}`
        this.printManifestDownloadError = true
      }
    }
  }
})
</script>
