import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = { class: "rounded-full bg-primary-300 w-6 h-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronRight = _resolveComponent("ChevronRight")!
  const _component_Preview = _resolveComponent("Preview")!
  const _component_LogsIcon = _resolveComponent("LogsIcon")!

  return (_openBlock(), _createElementBlock("tr", {
    "data-testid": `row-${_ctx.item.id}`,
    class: _normalizeClass(['table-row', 'relative', 'font-light', 'text-sm', 'border-bottom'])
  }, [
    _createElementVNode("td", {
      class: _normalizeClass(_ctx.cellClasses + ' gap-4 flex')
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ChevronRight, {
          "data-testid": "expand-row",
          "aria-label": "Expand row for more details",
          class: _normalizeClass([
            'cursor-pointer p-1 text-white w-6 h-6',
            _ctx.isExpanded && 'is-rotated'
          ]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('expand', _ctx.item.id)))
        }, null, 8, ["class"])
      ]),
      _createVNode(_component_Preview, {
        thumbnails: _ctx.item.thumbnails
      }, null, 8, ["thumbnails"])
    ], 2),
    _createElementVNode("td", {
      class: _normalizeClass(_ctx.cellClasses)
    }, [
      _createTextVNode(_toDisplayString(_ctx.item.id) + " ", 1),
      _createVNode(_component_LogsIcon, {
        query: { materiel_id: _ctx.item.id }
      }, null, 8, ["query"])
    ], 2),
    _createElementVNode("td", {
      class: _normalizeClass(_ctx.cellClasses)
    }, _toDisplayString(_ctx.item.size), 3),
    _createElementVNode("td", {
      class: _normalizeClass(_ctx.cellClasses)
    }, _toDisplayString(_ctx.item.description), 3),
    _createElementVNode("td", {
      class: _normalizeClass(_ctx.cellClasses)
    }, _toDisplayString(_ctx.item.auto_reorder ? 'On' : 'Off'), 3),
    _createElementVNode("td", {
      class: _normalizeClass(_ctx.cellClasses)
    }, _toDisplayString(_ctx.formatDate(_ctx.item.date_created, {
          timeZone: 'America/Los_Angeles',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })), 3)
  ], 8, _hoisted_1))
}