import { RouteRecordRaw } from 'vue-router'
import list from './list'
import rejectedPdf from './rejectedPdf'
import success from './success'
import View from './View.vue'

export default {
  path: 'forms',
  component: View,
  children: [list, rejectedPdf, success]
} as RouteRecordRaw
