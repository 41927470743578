import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, [
        _createVNode(_component_fa, {
          icon: "chevron-right",
          class: "mr-2"
        }),
        _createVNode(_component_router_link, { to: "forms/rejectedPdf" }, {
          default: _withCtx(() => [
            _createTextVNode("Rejected PDF")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}