<template>
  <component
    :is="editorComponent"
    :test-set="testSet"
    :form-factors="formFactors"
    @update:testSet="$emit('update:testSet', $event)"
    @update:formFactor="$emit('update:formFactor', $event)"
    @update:ffComponents="$emit('update:ffComponents', $event)"
    @updateCurrentPage="updateCurrentPage"
  />
</template>

<script lang="ts">
import { defineComponent, Prop } from '@vue/runtime-core'
import CreateTestSetHome from './CreateTestSet.vue'
import FormFactorSelection from './FormFactorSelection.vue'
import CreateConfirmation from './CreateConfirmation.vue'
import { TestSet } from './../../../store/testSets'
import TestCorpusManagement from './../../../pages/main/partnerOps/testSets/View.vue'
import { TEST_SET_WIZARD_PAGE } from '@/consts'

export default defineComponent({
  name: 'WizardEditor',
  props: {
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    formFactors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  emits: ['update:testSet', 'update:formFactor', 'update:ffComponents'],
  data() {
    return {
      currentPage: TEST_SET_WIZARD_PAGE.CREATE_TEST_SET_HOME
    }
  },
  computed: {
    editorComponent() {
      switch (this.currentPage) {
        case TEST_SET_WIZARD_PAGE.CREATE_TEST_SET_HOME:
          return CreateTestSetHome
        case TEST_SET_WIZARD_PAGE.FORM_FACTOR_SELECTION:
          return FormFactorSelection
        case TEST_SET_WIZARD_PAGE.CREATE_CONFIRMATION:
          return CreateConfirmation
        default:
          return TestCorpusManagement
      }
    }
  },
  methods: {
    updateCurrentPage(page) {
      this.currentPage = page
    }
  }
})
</script>
