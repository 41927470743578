<template>
  <div />
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'Testing',
  mounted() {
    // redict on landing in order to keep side navigation highlighted
    this.$router.push('/partner_ops/testing/test_sends')
  }
})
</script>

<style lang="scss" scoped></style>
