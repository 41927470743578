import lobComponents from '@lob/ui-components'
import { Plugin as VuePlugin } from 'vue'
import VueHighlightJS from 'vue3-highlightjs'
import router from './router'
import { globalComponentPlugin } from './components/global'
import store from './store'

export default [
  store,
  router,
  lobComponents,
  VueHighlightJS,
  globalComponentPlugin
] as Array<VuePlugin>
