import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f38ee31"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-header-root" }
const _hoisted_2 = { style: {"grid-area":"label"} }
const _hoisted_3 = { class: "title text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFactorNote = _resolveComponent("FormFactorNote")!
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.field.name), 1),
      (_ctx.field.id !== 'form_factor')
        ? (_openBlock(), _createBlock(_component_FormFactorNote, {
            key: 0,
            schema: _ctx.schema,
            "form-factors": _ctx.field.form_factors
          }, null, 8, ["schema", "form-factors"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("button", {
      style: {"grid-area":"add"},
      title: "Add Value",
      class: _normalizeClass({ addButton: true, hidden: !_ctx.showAddButton }),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add', $event)))
    }, [
      _createVNode(_component_fa, { icon: "plus" })
    ], 2)
  ]))
}