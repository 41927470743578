import { RouteRecordRaw } from 'vue-router'
import reports from './reports'
import onboarding from './onboarding'
import home from './home'
import View from './View.vue'
import forms from './forms'
import customMail from '../partnerOps/customMail'
import productComponents from '../partnerOps/customMail/packageComponents'
import packageList from '../partnerOps/customMail/packageList'
import endToEndTesting from './endToEndTesting'

export default {
  path: '/partners/:partnerId',
  props: true,
  component: View,
  children: [
    reports,
    home,
    forms,
    customMail,
    productComponents,
    packageList,
    endToEndTesting,
    ...onboarding
  ]
} as RouteRecordRaw
