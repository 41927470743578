<template>
  <div class="content p-5">
    <P
      v-if="currentTestSet === null || schema === null || formFactors === null"
      data-testid="loading"
    >
      <loading-indicator></loading-indicator>
    </P>

    <template v-else>
      <div class="w-full mb-1">
        <h3>Create New Test Set</h3>
      </div>
      <div
        class="rounded-tl-sm rounded-tr-sm text-black h-12 text-l flex items-center"
      >
        Customize your test sets
      </div>
      <br />
      <Stepper class="relative">
        <StepperItem
          position="first"
          :active="true"
          :finished="true"
          class="progressBar"
        >
          <div class="text-center">1) General Info</div>
        </StepperItem>
        <StepperItem
          position="middle"
          :active="true"
          :finished="false"
          class="progressBar"
        >
          <div class="text-center">2) Form Factor Info</div>
        </StepperItem>
        <StepperItem
          position="last"
          color="#C5D6E6"
          :active="false"
          class="progressBar"
        >
          <div class="text-center">3) Confirm</div>
        </StepperItem>
      </Stepper>
      <div
        v-for="(formFactor, index) of formFactors"
        :key="formFactor.name"
        class="w-full mb-1"
      >
        <div
          v-if="formFactor.show"
          class="bg-white-300 rounded-tl-sm rounded-tr-sm pl-6 text-black h-12 text-l flex items-center"
          @click="toggleFormFactor(index)"
        >
          <div class="uppercase">
            {{ formFactor.name }}
          </div>
          <div class="ml-auto flex h-6 align-middle">
            <Checkmark
              v-if="isCompleted(index)"
              :data-testid="`${formFactor.name}-checkmark`"
              class="mr-2 text-success"
            />
            <Warning
              v-else
              :data-testid="`${formFactor.name}-warning`"
              class="mr-2 text-warning"
            />
            <div class="mr-3">
              {{ isCompleted(index) ? 'Completed' : 'Incomplete' }}
            </div>
            <ChevronDown v-if="formFactor.expanded" class="mt-0.5 mr-2" />
            <ChevronRight v-else class="mt-0.5 mr-2" />
          </div>
        </div>
        <div
          v-if="formFactor.expanded"
          class="pl-20 pr-20 pt-10 pb-10 bg-white-200"
        >
          <MultiValueEditor
            v-for="field of formFactor.factors"
            :key="`${field.id}-${formFactor.id}`"
            v-model:testSet="currentTestSet"
            :schema="schema"
            :field="field"
            :ff-current-values="formFactor.currentValues"
            @update:formFactor="
              $emit('update:formFactor', { ...$event, index })
            "
          />
        </div>
      </div>
      <div class="flex justify-between mt-2">
        <LobButton
          variant="secondary"
          class="flex justify-end"
          @click="cancel()"
        >
          Cancel
        </LobButton>
        <div class="flex justify-end">
          <LobButton
            variant="secondary"
            class="mr-2"
            @click="switchPage('back')"
          >
            <ArrowLeft class="w-5 h-5 text-primary mr-1" />
            BACK
          </LobButton>
          <LobButton :disabled="continueDisabled" @click="switchPage('next')">
            CONTINUE
            <ArrowRight class="w-5 h-5 text-white ml-1" />
          </LobButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, Prop } from '@vue/runtime-core'
import router from '@/router'
import {
  createTestSet,
  getBaseTestSet,
  getSchema,
  getTestSets,
  TestSet
} from '@/store/testSets'
import { backfillTestSet, SchemaField } from '@/store/testSets/schema'
import { deepCopy } from '@/utils/objects'
import MultiValueEditor from '@/components/TestSetEditor/multiValueEditors/MultiValueEditor.vue'
import { TEST_SET_WIZARD_PAGE } from '@/consts'

export default defineComponent({
  name: 'FormFactors',
  components: { MultiValueEditor },
  props: {
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    formFactors: {
      type: Array,
      default: () => {
        return []
      }
    } as Prop<Array<any>>
  },
  emits: [
    'update:testSet',
    'updateCurrentPage',
    'update:formFactor',
    'update:ffComponents'
  ],
  data() {
    return {
      currentTestSet: null as TestSet | null,
      currentpage: TEST_SET_WIZARD_PAGE.FORM_FACTOR_SELECTION,
      savePending: false,
      pageUnloadListener: (e) => {
        if (this.hasChanges) {
          const message =
            'You have unsaved changes. Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      }
    }
  },
  computed: {
    // Unfortunately just doing schema: getSchema makes it unmockable in tests
    schema: () => getSchema(),
    relevantFields(): Array<SchemaField> {
      const schema = this.schema
      const fields = schema?.fields || []
      const formFactors = this.currentTestSet?.fields?.form_factor || []
      return fields.filter(
        (f) =>
          f.id === 'form_factor' ||
          f.form_factors.some((ff) => formFactors.indexOf(ff) !== -1)
      )
    },
    baseTestSet: () => getBaseTestSet(),
    nameCollision(): boolean {
      return getTestSets().some(
        (s) => s.name.toLowerCase() === this.currentTestSet?.name?.toLowerCase()
      )
    },
    hasChanges(): boolean {
      if (this.schema === null) return false
      return (
        JSON.stringify(this.currentTestSet) !==
        JSON.stringify(backfillTestSet(this.schema, this.baseTestSet))
      )
    },
    continueDisabled() {
      let continueDisabled = false
      this.formFactors?.forEach((ff, index) => {
        if (!this.isCompleted(index) && ff.show) continueDisabled = true
      })
      return continueDisabled
    }
  },
  watch: {
    baseTestSet: {
      handler() {
        this.onBaseDataChange()
      },
      immediate: true
    },
    schema: {
      handler() {
        this.onBaseDataChange()
      },
      immediate: true
    }
  },
  mounted() {
    this.currentTestSet = this.testSet as TestSet
    window.addEventListener('beforeunload', this.pageUnloadListener)
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.pageUnloadListener)
  },
  methods: {
    async create() {
      if (this.currentTestSet !== null) {
        this.savePending = true
        await createTestSet(this.currentTestSet)
        router.push('/partner_ops/test-sets')
      }
    },
    onBaseDataChange() {
      if (this.schema === null) return
      this.currentTestSet = backfillTestSet(
        this.schema,
        deepCopy(this.baseTestSet)
      )
    },
    toggleFormFactor(index) {
      if (this.formFactors) {
        const ff = this.formFactors
        if (ff[index].expanded) {
          ff[index].expanded = false
          return
        }
        for (let i = 0; i < this.formFactors.length; i++) {
          ff[i].expanded = i === index
        }
        this.$emit('update:ffComponents', ff)
      }
    },
    isCompleted(index) {
      if (this.formFactors) {
        const ff = this.formFactors[index]
        // -1 due to adding in formfactor type for now
        return ff.currentValues.size - 1 === ff.factors.length
      }
      return false
    },
    switchPage(direction) {
      if (direction === 'back') {
        this.$emit(
          'updateCurrentPage',
          TEST_SET_WIZARD_PAGE.CREATE_TEST_SET_HOME
        )
      } else if (direction === 'next') {
        this.formatFormFactors()
        this.$emit(
          'updateCurrentPage',
          TEST_SET_WIZARD_PAGE.CREATE_CONFIRMATION
        )
      }
    },
    cancel() {
      if (this.currentTestSet !== null) {
        this.currentTestSet = null as TestSet | null
        router.push('/partner_ops/test-sets')
      }
    },
    formatFormFactors() {
      const testSetFields = {} as any
      testSetFields.to = this.currentTestSet?.fields.to
      testSetFields.from = this.currentTestSet?.fields.from
      testSetFields.form_factor = this.currentTestSet?.fields.form_factor
      for (const factor of this.formFactors as any[]) {
        if (factor.show) {
          const fields = {}
          factor.currentValues.forEach((val, key) => {
            if (key === 'formFactor') return
            if (key === 'fold') {
              // TODO: bad implementation of fold, refactor with better solution
              if (val.includes('bi_fold') || val.includes('c_fold')) {
                val = val.includes('z_fold') ? ['z_fold', null] : [null]
                factor.currentValues.set(key, val)
              }
            }
            fields[key] = factor.currentValues.get(key)
          })
          testSetFields[factor.id] = [fields]
        }
      }
      this.$emit('update:testSet', {
        ...this.testSet,
        fields: {
          ...testSetFields
        }
      })
    },
    deleteOldValue(arr, index) {
      arr.splice(index, 1)
      return arr
    }
  }
})
</script>

<style lang="scss" scoped>
.content {
  width: 1000px;
}
.progressBar {
  width: 33%;
  min-width: 100px;
}
</style>
