import { getStoreBuilder } from 'vuex-typex'
import { applyRegisteredItems } from './watcherRegistry'

// These are only imported for their side effects.
import './digitalProofing'
import './launchDarkly'
import './partners'
import './requests'
import './session'

type EmptyObject = Record<string, never>
export type RootStoreState = EmptyObject

const store = getStoreBuilder<RootStoreState>().vuexStore()
// Apply watchers and startup listeners specified in each of the module files.
// This allows modules to trigger actions based on values changing in other
// modules.
applyRegisteredItems(store)
export default store
