<template>
  <div class="content p-5">
    <P v-if="currentTestSet === null || schema === null" data-testid="loading">
      <loading-indicator></loading-indicator>
    </P>

    <template v-else>
      <div class="w-full mb-1">
        <h3>Create New Test Set</h3>
      </div>
      <div
        class="rounded-tl-sm rounded-tr-sm text-black h-12 text-l flex items-center"
      >
        Customize your test sets
      </div>
      <br />
      <Stepper class="relative">
        <StepperItem position="first" :finished="false" class="progressBar">
          <div class="text-center">1) General Info</div>
        </StepperItem>
        <StepperItem
          position="middle"
          color="#C5D6E6"
          :active="true"
          :finished="false"
          class="progressBar"
        >
          <div class="text-center">2) Form Factor Info</div>
        </StepperItem>
        <StepperItem position="last" color="#C5D6E6" class="progressBar">
          <div class="text-center">3) Confirm</div>
        </StepperItem>
      </Stepper>
      <div
        class="bg-white-300 rounded-tl-sm rounded-tr-sm pl-6 text-black h-12 text-l flex items-center"
      >
        GENERAL INFORMATION
      </div>
      <div class="bg-white-200">
        <div class="pl-20 pr-20 pt-10 pb-10">
          <TextInput
            id="name"
            v-model="currentTestSet.name"
            label="Test Set Name:"
            placeholder="Enter a title for test set"
          />
          <Alert v-if="nameCollision" variant="error" class="mt-1">
            A test set with that name already exists.
          </Alert>
        </div>
        <div class="pl-20 pr-20 pt-10 pb-10">
          <Textarea
            id="description"
            v-model="currentTestSet.description"
            label="Description:"
            input-class="w-full"
            placeholder="Add a description..."
          />
        </div>
        <div class="mt-2 mb-2 text-sm font-normal text-gray-500 pl-20 pr-20">
          Select Form Factors for Test Set
          <div class="inline-flex item mr-4 mt-2 mb-2 border-b-0 pt-3 pb-3">
            <span v-for="ff of formFactors" :key="ff.name">
              <Checkbox
                :model-value="isChecked(ff.id)"
                :name="ff.name"
                :label="ff.name"
                @update:modelValue="handleFieldChange(ff.id, $event)"
              />
            </span>
          </div>
        </div>
        <div class="pl-20 pr-20 pt-3 pb-3">
          <RadioGroup legend="Address Location" name="recipient">
            <RadioButton
              v-for="location in locationGroup"
              :id="location.key"
              :key="location.key"
              name="Location"
              :label="location.label"
              :value="location.label"
              :model-value="locationValue"
              @click="() => (locationValue = location.value)"
            />
          </RadioGroup>
        </div>
        <div v-if="locationValue === 'Domestic'" class="pl-20 pr-20 pt-6 pb-3">
          <RadioGroup legend="Select Number of Sender Addresses" name="sender">
            <RadioButton
              v-for="senderAmount in senderAmounts"
              :id="senderAmount.key"
              :key="senderAmount.key"
              name="Sender Addresses"
              :label="senderAmount.label"
              :value="senderAmount.label"
              :model-value="selectedSenderAmount.toString()"
              @click="() => (selectedSenderAmount = senderAmount.value)"
            />
          </RadioGroup>
        </div>
        <div class="pl-20 pr-20 pt-3 pb-3">
          <RadioGroup
            legend="Select Number of Recipient Addresses"
            name="recipient"
          >
            <RadioButton
              v-for="recipientAmount in recipientAmounts"
              :id="recipientAmount.key"
              :key="recipientAmount.key"
              name="Recipient Addresses"
              :label="recipientAmount.label"
              :value="recipientAmount.label"
              :model-value="selectedRecipientAmount.toString()"
              @click="() => (selectedRecipientAmount = recipientAmount.value)"
            />
          </RadioGroup>
        </div>
      </div>

      <div class="flex justify-between mt-2">
        <LobButton
          class="flex justify-end"
          variant="secondary"
          @click="cancel()"
        >
          Cancel
        </LobButton>
        <div class="flex justify-end">
          <LobButton :disabled="continueDisabled" @click="switchPage('next')">
            CONTINUE
            <ArrowRight class="w-5 h-5 text-white ml-1" />
          </LobButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, Prop } from '@vue/runtime-core'
import {
  getBaseTestSet,
  getSchema,
  getTestSets,
  TestSet
} from '@/store/testSets'
import { backfillTestSet, loadDefaultTestSet } from '@/store/testSets/schema'
import { deepCopy } from '@/utils/objects'
import { randomRealAddresses } from '@/components/TestSetEditor/multiValueEditors/addresses/AddressMVE.vue'
import { TEST_SET_WIZARD_PAGE, LOCATIONS } from '@/consts'
import router from '@/router'

export default defineComponent({
  name: 'CreateTestSetHome',
  props: {
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>,
    formFactors: {
      type: Array,
      default: () => {
        return []
      }
    } as Prop<Array<any>>
  },
  emits: ['update:testSet', 'updateCurrentPage', 'update:ffComponents'],
  data() {
    return {
      currentTestSet: null as TestSet | null,
      savePending: false,
      pageUnloadListener: (e) => {
        if (this.hasChanges) {
          const message =
            'You have unsaved changes. Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      },
      senderAmounts: [
        { key: '1 sender', label: '1', value: 1 },
        { key: '20 sender', label: '20', value: 20 },
        { key: '100 sender', label: '100', value: 100 },
        { key: '500 sender', label: '500', value: 500 },
        { key: '1000 sender', label: '1000', value: 1000 }
      ],
      recipientAmounts: [
        { key: '1 recipient', label: '1', value: 1 },
        { key: '20 recipient', label: '20', value: 20 },
        { key: '100 recipient', label: '100', value: 100 },
        { key: '500 recipient', label: '500', value: 500 },
        { key: '1000 recipient', label: '1000', value: 1000 }
      ],
      selectedSenderAmount: 1,
      selectedRecipientAmount: 1,
      currentPage: TEST_SET_WIZARD_PAGE.CREATE_TEST_SET_HOME,
      locationValue: LOCATIONS.DOMESTIC,
      locationGroup: [
        {
          key: LOCATIONS.DOMESTIC,
          label: LOCATIONS.DOMESTIC,
          value: LOCATIONS.DOMESTIC
        },
        {
          key: LOCATIONS.INTERNATIONAL,
          label: LOCATIONS.INTERNATIONAL,
          value: LOCATIONS.INTERNATIONAL
        }
      ]
    }
  },
  computed: {
    // Unfortunately just doing schema: getSchema makes it unmockable in tests
    schema: () => getSchema(),
    baseTestSet: () => getBaseTestSet(),
    nameCollision(): boolean {
      return (
        getTestSets().some(
          (s) =>
            s.name.toLowerCase() === this.currentTestSet?.name?.toLowerCase()
        ) && !this.baseTestSet.isEditing
      )
    },
    hasChanges(): boolean {
      if (this.schema === null) return false
      return (
        JSON.stringify(this.currentTestSet) !==
        JSON.stringify(backfillTestSet(this.schema, this.baseTestSet))
      )
    },
    continueDisabled() {
      return (
        this.nameCollision ||
        this.currentTestSet?.description === '' ||
        this.currentTestSet?.fields.form_factor.length === 0 ||
        this.selectedSenderAmount === null ||
        this.selectedRecipientAmount === null
      )
      // return false
    }
  },
  watch: {
    baseTestSet: {
      handler() {
        this.onBaseDataChange()
      },
      immediate: true
    },
    schema: {
      handler() {
        this.onBaseDataChange()
      },
      immediate: true
    }
  },
  mounted() {
    this.currentTestSet = this.testSet as TestSet
    this.selectedSenderAmount = this.currentTestSet?.fields?.from?.length || 1
    this.selectedRecipientAmount = this.currentTestSet?.fields?.to?.length || 1
    window.addEventListener('beforeunload', this.pageUnloadListener)
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.pageUnloadListener)
  },
  methods: {
    onBaseDataChange() {
      if (this.schema === null) return
      this.currentTestSet = backfillTestSet(
        this.schema,
        deepCopy(this.baseTestSet)
      )
    },
    isChecked(value): any {
      return this.currentTestSet?.fields.form_factor.includes(value)
    },
    deleteOldValue(arr, index) {
      arr.splice(index, 1)
      return arr
    },
    handleFieldChange(value, put) {
      const old = this.currentTestSet?.fields.form_factor || []
      const newValues = put
        ? old.concat([value])
        : this.deleteOldValue(old, old.indexOf(value))
      const ff = this.formFactors as any[]
      const ffIndex = ff.findIndex((el) => el.id === value)
      ff[ffIndex].show = put
      ff[ffIndex].expanded = false
      ff[ffIndex].currentValues = new Map()
      ff[ffIndex].currentValues = loadDefaultTestSet(ff[ffIndex])
      ff[ffIndex].currentValues.set('formFactor', value)

      this.currentTestSet!.fields.form_factor = newValues
      this.$emit('update:testSet', {
        ...this.currentTestSet,
        fields: {
          ...this.currentTestSet?.fields,
          form_factor: newValues
        }
      })
      this.$emit('update:ffComponents', ff)
    },
    switchPage(direction) {
      if (direction === 'next') {
        if (
          this.currentPage === TEST_SET_WIZARD_PAGE.CREATE_TEST_SET_HOME &&
          this.currentTestSet?.fields !== undefined
        ) {
          this.populateAddresses()
        }
        this.$emit(
          'updateCurrentPage',
          TEST_SET_WIZARD_PAGE.FORM_FACTOR_SELECTION
        )
      }
    },
    populateAddresses() {
      // could only find 1 international lob address for now
      const senderAddresses = randomRealAddresses(
        this.selectedSenderAmount,
        LOCATIONS.DOMESTIC
      )
      const recipientAddresses = randomRealAddresses(
        this.selectedRecipientAmount,
        this.locationValue
      )
      if (this.currentTestSet && this.currentTestSet !== undefined) {
        this.currentTestSet.fields.to = recipientAddresses
        this.currentTestSet.fields.from = senderAddresses
        this.$emit('update:testSet', this.currentTestSet)
      }
    },
    cancel() {
      if (this.currentTestSet !== null) {
        this.currentTestSet = null as TestSet | null
        router.push('/partner_ops/test-sets')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.content {
  flex-wrap: wrap;
  width: 1000px;
}
.item {
  display: flex;
  margin-right: 1rem;
}
.item > :first-child {
  margin-right: 0 !important;
}
.progressBar {
  width: 33%;
  min-width: 100px;
}
</style>
