import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'
import componentPage from '../componentPage'
import { ShowChildRoutes } from '@/components/global'
import manageBids from '../manageBids'
import { isAdminOrPopsUser } from '@/store/session'

export default {
  path: ':packageID',
  component: ShowChildRoutes,
  children: [
    {
      path: '',
      component: View
    },
    componentPage,
    manageBids
  ],
  meta: {
    displayName: () => {
      if (isAdminOrPopsUser()) {
        return 'Manage Package'
      } else {
        return 'View Package'
      }
    },
    useParam: 'packageID'
  }
} as RouteRecordRaw
