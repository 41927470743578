<template>
  <div />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getPermutationAsAdmin } from '@/store/digitalProofing'
import { isLoggedIn } from '@/store/session'
import router from '@/router'

export default defineComponent({
  name: 'ApproveDigitalProof',
  props: {
    permutationId: {
      type: String,
      required: true
    }
  },
  computed: {
    isLoggedIn() {
      return isLoggedIn()
    }
  },
  watch: {
    isLoggedIn: {
      handler(loggedIn) {
        if (loggedIn) {
          this.doRedirect()
        }
      },
      immediate: true
    }
  },
  methods: {
    async doRedirect() {
      const permutationData = (
        await getPermutationAsAdmin({ permutationId: this.permutationId })
      ).data
      if (permutationData === null) throw new Error('Permutation should exist!')
      const path = `/partners/${permutationData.partner}/onboarding/digital-proofs/${permutationData.id}`
      router.replace(path)
    }
  }
})
</script>

<style scoped lang="scss"></style>
