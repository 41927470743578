<template>
  <FieldSetRow>
    <Textarea
      id="artwork"
      label="Artwork"
      :model-value="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </FieldSetRow>
  <FieldSetRow>
    <Dropdown
      id="existing"
      model-value=""
      :options="artworkOptions"
      label="Use an existing artwork"
      placeholder="Existing Artworks"
      @update:modelValue="$emit('update:modelValue', $event.value)"
    />
  </FieldSetRow>
  <FieldSetRow>
    <Caption>Or upload something new</Caption>
    <LobFileUpload
      :do-try-upload="(f) => tryUploadFile(f)"
      @uploadSuccess="onUploadSuccess($event)"
    />
  </FieldSetRow>
</template>

<script lang="ts">
import { getArtworks, uploadArtwork, UploadedArtwork } from '@/store/testSets'
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'
import {
  DROPDOWN_INDENT,
  FORM_FACTOR_TYPES,
  DEFAULT_CHECK_ARTWORK,
  DEFAULT_LETTER_ARTWORK,
  DEFAULT_POSTCARD_ARTWORK,
  DEFAULT_SELF_MAILER_ARTWORK
} from '@/consts'

export default defineComponent({
  name: 'ArtworkSVE',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    type: {
      type: String,
      required: true
    } as Prop<'artwork'>,
    modelValue: {
      type: String,
      required: true
    } as Prop<string>,
    formFactor: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    artworkOptions() {
      const customArtwork = this.filterDefaultArtwork(getArtworks())
      const allArtwork = [
        { label: 'Default Artwork', value: '', disabled: true }
      ]
      const defaultArtwork = this.currentFormFactorArtwork().map((a) => ({
        label: `${DROPDOWN_INDENT}${a}`,
        value: a,
        disabled: false
      }))
      allArtwork.push(...defaultArtwork)
      allArtwork.push({ label: 'Custom Artwork', value: '', disabled: true })
      allArtwork.push(...customArtwork)
      return allArtwork
    }
  },
  methods: {
    async tryUploadFile(file: File) {
      return await uploadArtwork({ file })
    },
    onUploadSuccess(uploaded: UploadedArtwork) {
      this.$emit('update:modelValue', uploaded.url)
    },
    filterDefaultArtwork(artwork) {
      const defaultArtwork = new Set([
        ...DEFAULT_CHECK_ARTWORK,
        ...DEFAULT_LETTER_ARTWORK,
        ...DEFAULT_POSTCARD_ARTWORK,
        ...DEFAULT_SELF_MAILER_ARTWORK
      ])
      const filteredArtwork = artwork.filter(
        (a) => !defaultArtwork.has(a.filename)
      )
      return filteredArtwork.map((a) => ({
        label: `${DROPDOWN_INDENT}${a.filename}`,
        value: a.url
      }))
    },
    currentFormFactorArtwork() {
      switch (this.formFactor) {
        case FORM_FACTOR_TYPES.CHECKS:
          return DEFAULT_CHECK_ARTWORK
        case FORM_FACTOR_TYPES.LETTERS:
          return DEFAULT_LETTER_ARTWORK
        case FORM_FACTOR_TYPES.POSTCARDS:
          return DEFAULT_POSTCARD_ARTWORK
        case FORM_FACTOR_TYPES.SELF_MAILERS:
          return DEFAULT_SELF_MAILER_ARTWORK
        default:
          return []
      }
    }
  }
})
</script>

<style>
#artwork {
  width: 100%;
}
</style>
