/* eslint-disable camelcase */
import { computed, watch } from '@vue/runtime-core'
import { Ref, ref } from '@vue/reactivity'
import {
  UserRole,
  getUser,
  isAdminUser,
  PartnerMasquerade
} from '@/store/session'
import { sendPapiRequest } from '@/store/requests'
import { captureException } from '@/plugins/sentry'

export const PAGE_SIZE = 10

export type User = {
  id: string
  partner: string
  role: UserRole
  email: string
  date_created: string
  date_modified: string
  masquerade_info: PartnerMasquerade
}

export type UserFilter = {
  partner: string | null
  searchTerm: string
}

type UseUsersReturnType = {
  isReady: Ref<boolean>
  isLoading: Ref<boolean>
  errorMessage: Ref<string>
  users: Ref<User[]>
  totalCount: Ref<number>
  loadUsers: (filter: UserFilter, offset?: number) => void
  reloadUsers: (filter: UserFilter) => void
}

const fetchUsers = (partner: string, search: string, offset = 0) =>
  sendPapiRequest({
    method: 'GET',
    path: '/users',
    query: {
      partner,
      search,
      offset,
      limit: PAGE_SIZE
    }
  })

export function useUsers({ loadMore = true } = {}): UseUsersReturnType {
  const isReady = ref<boolean>(false)
  const isLoading = ref<boolean>(true)
  const errorMessage = ref('')
  const currentUser = computed(getUser)
  const isAdmin = computed(isAdminUser)
  const users = ref<User[]>([])
  const totalCount = ref<number>(0)

  const loadUsers = async (filter: UserFilter, offset?: number) => {
    try {
      isLoading.value = true
      errorMessage.value = ''

      const usersResp = await fetchUsers(
        filter.partner || '',
        filter.searchTerm,
        offset
      )
      users.value = loadMore
        ? usersResp.data.data.concat(users.value)
        : usersResp.data.dataF
      totalCount.value = usersResp.data.count
    } catch (err) {
      captureException(err as Error)
      errorMessage.value = 'Data failed to load'
    } finally {
      isLoading.value = false
    }
  }

  const reloadUsers = async (filter: UserFilter) => {
    users.value = []
    totalCount.value = 0
    loadUsers(filter, 0)
  }

  watch(
    currentUser,
    async () => {
      if (currentUser.value && isAdmin.value && !isReady.value) {
        await loadUsers({ partner: null, searchTerm: '' })
        isReady.value = true
      }
    },
    { immediate: true }
  )

  return {
    isReady,
    isLoading,
    errorMessage,
    users,
    totalCount,
    loadUsers,
    reloadUsers
  }
}
