<template>
  <div class="flex flex-col">
    <div v-if="label">
      <label v-if="label.length > 0">
        {{ label }}
      </label>
      <span v-if="required" class="text-error">*</span>
    </div>
    <input
      :id="id"
      type="text"
      :value="value"
      :placeholder="placeholder"
      :class="calculatedClass"
      @input="handleInput"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PackageTextInput',
  props: {
    id: {
      type: String,
      default: () => {
        return ''
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    value: { type: String, required: true },
    label: {
      type: String,
      default: () => {
        return ''
      }
    },
    required: { type: Boolean },
    inputHeight: { type: String, default: 'h-12' },
    inputWidth: { type: String, default: 'w-full' }
  },
  emits: ['update:value'],
  computed: {
    calculatedClass() {
      return `
        p-2
        border 
        border-grayDove 
        placeholder-grayDove 
        hover:shadow-md
        rounded-md 
        mb-2
        ${this.inputHeight} 
        ${this.inputWidth}
      `
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('update:value', e.target.value)
    }
  }
})
</script>
