<template>
  <Textarea
    id="artwork"
    key="artwork"
    label="JSON"
    :model-value="textValue"
    @update:modelValue="updateValue($event)"
  />
  <Alert v-if="syntaxError" variant="error">
    {{ syntaxError }}
  </Alert>
</template>

<script lang="ts">
import { Schema } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'

export default defineComponent({
  name: 'JsonSVE',
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    type: {
      type: String,
      required: true
    } as Prop<'json'>,
    modelValue: {
      type: Object,
      required: true
    } as Prop<unknown>,
    formFactor: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      textValue: '',
      syntaxError: null as string | null
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.textValue = JSON.stringify(this.modelValue)
        this.syntaxError = null
      },
      immediate: true
    }
  },
  methods: {
    updateValue(newText: string) {
      this.textValue = newText
      try {
        this.$emit('update:modelValue', JSON.parse(newText))
        this.syntaxError = null
      } catch (e) {
        this.syntaxError = `${e}`
      }
    }
  }
})
</script>
