<template>
  <form>
    <div class="row mt-5 contentSection">
      <div class="col-3 sectionTitle">
        <p>CRID:</p>
        <p>Customer Registration ID</p>
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-9">
            <TextInput
              id="crid"
              v-model="computedCrid"
              label="Enter Your CRID"
              placeholder="Value"
            />
          </div>
          <Alert v-if="alertMessage" :variant="alertType" class="mt-2">
            {{ alertMessage }}
          </Alert>
          <div class="col-3">
            <LobButton
              class="mt-4 ml-3"
              data-testid="submitBtn"
              :disabled="pending"
              @click.prevent="submit"
            >
              Save
            </LobButton>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import * as session from '@/store/session'
import { getCurrentPartner, getCurrentPartnerId } from '@/store/partners'
export default defineComponent({
  name: 'CridForm',
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      crid: null as string | null,
      alertMessage: null as string | null,
      alertType: 'error',
      pending: false
    }
  },
  computed: {
    computedCrid: {
      get() {
        const partner = getCurrentPartner()
        return partner ? partner.crid : null
      },
      set(newCrid) {
        this.crid = newCrid
      }
    }
  },
  methods: {
    async submit() {
      if (this.crid === null) {
        return
      }
      const id = getCurrentPartnerId()
      if (id == null) return
      this.pending = true
      this.alertMessage = null
      try {
        await session.updatePartner({
          partner: id,
          data: {
            crid: this.crid
          }
        })
        this.alertMessage = 'Save Successful'
        this.alertType = 'success'
        this.crid = null
      } catch (e) {
        // Format an error message if unsuccessful.
        const eResp = e.response
        this.alertMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
      this.pending = false
    }
  }
})
</script>
<style scoped lang="scss">
.pst {
  font-size: 16px;
  padding-top: 40px;
}
</style>
