// import { Partner } from '../partners'
import axios from 'axios'
import { sendFFBRequest } from '../requests'
import { defineVuexModule } from '../util'

export interface SearchLabel {
  label: string
  value: string
}

export const dateFormatter = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
}

export interface PartnerPackageData {
  partner_id: string
  status: string
  date_created: string
  date_modified: string
}

/* eslint-disable no-shadow */
export enum SubscriptionTier {
  /* eslint-disable no-unused-vars */
  DEVELOPER = 'developer',
  ENTERPRISE = 'enterprise',
  GROWTH = 'growth',
  MID_MARKET = 'mid-market',
  STARTUP = 'startup'
}

export interface PackagePermission {
  id: string
  product_id: string
  account_id?: string
  tiers: SubscriptionTier[]
  date_created: string
  date_modified: string
  date_effective: string
  date_expired?: string
}

export interface PackagePageData {
  id?: string
  account_id: string
  title: string
  description: string
  instructions: string
  mail_type: string
  object_type: string
  date_created?: string // these fields should not be optional but putting them in so that PackageModal doesn't break
  date_modified?: string
  deleted?: boolean
  partners?: PartnerPackageData[]
  permissions?: PackagePermission[]
}

export interface PackageCreate {
  account_id?: string
  tiers?: SubscriptionTier[]
  title: string
  description: string
  instructions: string
  mail_type: string
  object_type: string
}

export interface BiddingPartner {
  id: string
  partnerID: string
  productID: string
  status: string
  date: string
  hasBidValues: boolean
}

export interface BidData {
  id: string
  date_created?: string
  date_modified?: string
  deleted?: boolean
  product_id: string
  partner_id: string
  status: string
  order_quantity: number
  total_us_dollars?: number
  lead_time_days?: number
}

export interface PartnerPackageBids {
  id?: string
  product_id?: string
  partner_id: string
  status?: string
  date?: string
  bidsArray: BidData[]
}

export interface BidQuantity {
  id: string
  order_quantity: string
  total_us_dollars: string
}

export interface TestCaseComponent {
  id: string
  title?: string
  description?: string
  rendering_details?: any
  inventory_type: string
  overloaded_file_url: string | null
  thumbnails: {
    small: string | null
    medium: string | null
    large: string | null
  } | null
}

export interface TestCase {
  id: string
  product_id: string
  name: string
  description?: string
  date_created: string
  date_modified: string
  deleted?: boolean
  quantity?: number
  allComponentsRendered: boolean
  components: TestCaseComponent[]
}

export interface SimpleTestCase {
  id: string
  description: string
  name: string
}

export interface TestGroup {
  id?: string
  name: string
  description?: string
  date_created?: string
  date_modified?: string
  quantity?: number
  test_cases: TestCase[]
}

export interface TestSendPartner {
  status?: string
  partner_id?: string
  date_processed: string
}

export interface TestSend {
  id?: string
  date_created?: string
  date_modified?: string
  status?: string
  test_send_groups: TestGroup[]
  test_send_cases: TestCase[]
  test_send_partners: TestSendPartner[]
}

export interface PaginationLinks {
  first: string
  next: string
  last: string
  previous: string
}

export interface SimpleTestCaseList {
  data: SimpleTestCase[]
}

export interface TestCaseList {
  total_count: number
  data: TestCase[]
  links: PaginationLinks
}

export interface TestSendList {
  total_count: number
  data: TestSend[]
  links: PaginationLinks
}

export interface TestGroupList {
  total_count: number
  data: TestGroup[]
  links: PaginationLinks
}

interface State {
  currentUserAction: string | null
  packagePageData: PackagePageData | null
  partnerPackageBids: PartnerPackageBids | null
  bidData: BidData | null
  componentPageID: string | null
  basePath: string | null
}

const initialState: State = {
  currentUserAction: null,
  packagePageData: null,
  partnerPackageBids: null,
  bidData: null,
  componentPageID: null,
  basePath: ''
}

const storeModule = defineVuexModule('customMail', initialState)

export const getBasePath = storeModule.read(
  (state) => state.basePath,
  'getBasePath'
)

export const setBasePath = storeModule.commit((state, action: string) => {
  state.basePath = action
}, 'setBasePath')

export const getCurrentUserAction = storeModule.read(
  (state) => state.currentUserAction,
  'getCurrentUserAction'
)

export const setCurrentUserAction = storeModule.commit(
  (state, action: string) => {
    state.currentUserAction = action
  },
  'setCurrentUserAction'
)

export const getPackagePageData = storeModule.read(
  (state) => state.packagePageData,
  'getPackagePageData'
)

export const setPackagePageData = storeModule.commit(
  (state, data: PackagePageData) => {
    state.packagePageData = data
  },
  'setPackagePageData'
)

export const getPartnerPackageBids = storeModule.read(
  (state) => state.partnerPackageBids,
  'getPartnerPackageBids'
)

export const setPartnerPackageBids = storeModule.commit(
  (state, data: PartnerPackageBids) => {
    state.partnerPackageBids = data
  },
  'setPartnerPackageBids'
)

export const getComponentPageID = storeModule.read(
  (state) => state.componentPageID,
  'getComponentPageID'
)
export const setComponentPageID = storeModule.commit((state, data: string) => {
  state.componentPageID = data
}, 'setComponentPageID')

export const getPrintingManifest = storeModule.dispatch(
  async (_, params: { packageID: string }) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: `/manifests/printing/${params.packageID}`
    })
    return response.data
  },
  'getPrintingManifest'
)

export const getPackages = storeModule.dispatch(async () => {
  const response = await sendFFBRequest({
    method: 'GET',
    path: '/products',
    query: {
      include: 'total_count'
    }
  })
  return response.data
}, 'getPackages')

export const getMorePackages = storeModule.dispatch(
  async (
    _,
    params: {
      url: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: params.url
    })
    return response.data
  },
  'getMorePackages'
)

export const getPackage = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: `/products/${params.packageID}`
    })
    return response.data
  },
  'getPackage'
)

export const getPackagePermissions = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: `/products/${params.packageID}/permissions`
    })
    return response.data
  },
  'getPackagePermissions'
)

export const createNewPackage = storeModule.dispatch(
  async (
    ctx,
    params: {
      account_id?: string
      tiers?: SubscriptionTier[]
      title: string
      description: string
      instructions: string
      mail_type: string
      object_type: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'POST',
      path: '/products',
      data: {
        account_id: params.account_id,
        tiers: params.tiers,
        title: params.title,
        description: params.description,
        instructions: params.instructions,
        mail_type: params.mail_type,
        object_type: params.object_type
      }
    })
    return response.data
  },
  'createNewPackage'
)

export const clonePackage = storeModule.dispatch(
  async (
    ctx,
    params: {
      account_id?: string
      tiers?: SubscriptionTier[]
      title: string
      description: string
      instructions: string
      mail_type: string
      object_type: string
      original_id: string
    }
  ) => {
    // create new package with parameters and grab ID
    const createProductResp = await sendFFBRequest({
      method: 'POST',
      path: '/products',
      data: {
        account_id: params.account_id,
        title: params.title,
        description: params.description,
        instructions: params.instructions,
        mail_type: params.mail_type,
        object_type: params.object_type
      }
    })
    const newPackageID = createProductResp.data.id

    // get all associated components from original package
    // create new components and associate them with new package
    const getComponentsResp = await getPackageComponents({
      packageID: params.original_id
    })
    const createdComponents = [] as any[]
    getComponentsResp.data.map(async (component) => {
      const originalPackageInventoryType = component.products.filter(
        (product) => product.product_id === params.original_id
      )[0].inventory_type

      let componentFile: File | null = null
      let createComponentResp: any = null
      if (component.file_url) {
        // if there's an associated file, download it and use it in the payload
        componentFile = await axios
          .get(component.file_url, { responseType: 'blob' })
          .then((response) => {
            return new File([response.data], 'temp.pdf')
          })
      }
      try {
        createComponentResp = await createNewComponent({
          title: component.title,
          description: component.description,
          instructions: component.instructions,
          inventoryType: originalPackageInventoryType,
          packageID: newPackageID,
          file: componentFile,
          templateID: component.template_id ?? ''
        })
      } catch (e) {
        // if component creation failed, delete cloned package and previous cloned components
        await deletePackageID({ packageID: newPackageID })
        createdComponents.map(
          async (compID) => await deleteComponentID({ componentID: compID })
        )
        throw e
      }
      const newComponentID = createComponentResp.data.id
      createdComponents.push(newComponentID)

      // grab all associated fields from original component
      // and link them to new component
      component.attribute_fields.map(async (field) => {
        await linkAttributeToComponent({
          componentID: newComponentID,
          fieldID: field.id,
          value: field.value
        })
      })
    })
    return createProductResp.data
  },
  'clonePackage'
)

export const editPackageDetails = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
      title: string
      description: string
      instructions: string
      mail_type: string
      object_type: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'PATCH',
      path: `/products/${params.packageID}`,
      data: {
        title: params.title,
        description: params.description,
        instructions: params.instructions,
        mail_type: params.mail_type,
        object_type: params.object_type
      }
    })
    return response.data
  },
  'editPackageDetails'
)

export const updatePackageStatus = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
      status: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'PATCH',
      path: `/products/${params.packageID}`,
      data: {
        status: params.status
      }
    })
    return response.data
  },
  'updatePackageStatus'
)

export const deletePackageID = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'DELETE',
      path: `/products/${params.packageID}`
    })
    return response.data
  },
  'deletePackageID'
)

const linkComponentToPackage = async (data) => {
  const response = await sendFFBRequest({
    method: 'PUT',
    path: `/products/${data.packageID}/components/${data.id}`,
    data: {
      inventory_type: data.inventoryType
    }
  })
  return response
}

export const editPartnerPackageStatus = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
      partners: Array<PartnerPackageData>
      partnerID: string
      status: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'PUT',
      path: `/products/${params.packageID}/partners/${params.partnerID}`,
      data: {
        status: params.status
      }
    })
    return response.data
  },
  'editPartnerPackageStatus'
)

export const createNewComponent = storeModule.dispatch(
  async (
    ctx,
    params: {
      title: string
      description: string
      instructions: string
      inventoryType: string
      packageID: string
      file: File | null
      templateID: string
    }
  ) => {
    const data = new FormData()
    data.append('title', params.title)
    data.append('description', params.description)
    data.append('instructions', params.instructions)
    if (params.templateID.length !== 0)
      data.append('template_id', params.templateID)
    if (params.file) data.append('file', params.file)
    const response = await sendFFBRequest({
      method: 'POST',
      path: '/components',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
    const linkData = response.data
    linkData.packageID = params.packageID
    linkData.inventoryType = params.inventoryType
    const linkResp = await linkComponentToPackage(linkData)
    linkResp.data.id = linkData.id
    return linkResp
  },
  'createNewComponent'
)

export const getPackageComponents = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: '/components',
      query: {
        product_id: params.packageID
      }
    })
    return response.data
  },
  'getPackageComponents'
)

export const deleteComponentID = storeModule.dispatch(
  async (
    ctx,
    params: {
      componentID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'DELETE',
      path: `/components/${params.componentID}`
    })
    return response.data
  },
  'deleteComponentID'
)

export const updateAttribute = storeModule.dispatch(
  async (
    ctx,
    params: {
      name: string
      label: string
      description: string
      defaultValue: string
      dataType: string
      fieldType: string
      componentID: string
      id: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'PATCH',
      path: `/fields/${params.id}`,
      data: {
        name: params.name,
        label: params.label,
        instructional_text: params.description,
        data_type: params.dataType,
        field_type: params.fieldType,
        default_value: params.defaultValue
      }
    })
    return response
  },
  'updateAttribute'
)

export const getComponentDetails = storeModule.dispatch(
  async (
    ctx,
    params: {
      componentID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: `/components/${params.componentID}`
    })
    return response.data
  },
  'getComponentDetails'
)

export const getComponentAttributes = storeModule.dispatch(
  async (
    ctx,
    params: {
      componentID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: `/components/${params.componentID}`
    })
    return response.data.attribute_fields
  },
  'getComponentAttributes'
)

export const linkAttributeToComponent = storeModule.dispatch(
  async (
    ctx,
    params: {
      componentID: string
      fieldID: string
      value: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'PUT',
      path: `/components/${params.componentID}/fields/${params.fieldID}`,
      data: {
        value: params.value
      }
    })
    return response
  },
  'linkAttributeToComponent'
)

const linkNewAttributeToComponent = async (data) => {
  const response = await sendFFBRequest({
    method: 'PUT',
    path: `/components/${data.componentID}/fields/${data.fieldID}`,
    data: {
      value: data.defaultValue
    }
  })
  return response
}

export const createAttributeForComponent = storeModule.dispatch(
  async (
    ctx,
    params: {
      name: string
      label: string
      description: string
      defaultValue: string
      dataType: string
      fieldType: string
      componentID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'POST',
      path: '/fields',
      data: {
        name: params.name,
        label: params.label,
        instructional_text: params.description,
        data_type: params.dataType,
        field_type: params.fieldType,
        default_value: params.defaultValue
      }
    })
    const linkData = {
      componentID: params.componentID,
      fieldID: response.data.id,
      defaultValue: params.defaultValue
    }
    const linkResponse = await linkNewAttributeToComponent(linkData)
    return linkResponse.data
  },
  'createAttributeForComponent'
)

export const deleteAttribute = storeModule.dispatch(
  async (
    ctx,
    params: {
      id: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'DELETE',
      path: `/fields/${params.id}`
    })
    return response
  },
  'deleteAttribute'
)

export const disassociateAttributeFromComponent = storeModule.dispatch(
  async (
    _,
    params: {
      attributeID: string
      componentID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'DELETE',
      path: `/components/${params.componentID}/fields/${params.attributeID}`
    })
    return response
  },
  'disassociateAttributeFromComponent'
)

export const updateComponent = storeModule.dispatch(
  async (
    _,
    params: {
      title: string
      description: string
      instructions: string
      inventoryType: string
      file?: File
      id: string
      packageID: string
      templateID: string
    }
  ) => {
    const data = new FormData()
    data.append('title', params.title)
    data.append('description', params.description)
    data.append('instructions', params.instructions)
    data.append('inventory_type', params.inventoryType)
    if (params.file) data.append('file', params.file)
    data.append('template_id', params.templateID)
    const response = await sendFFBRequest({
      method: 'PATCH',
      path: `/components/${params.id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
    if (params.inventoryType)
      await linkComponentToPackage({
        packageID: params.packageID,
        id: params.id,
        inventoryType: params.inventoryType
      })
    return response
  },
  'updateComponent'
)

export const getBids = storeModule.dispatch(async () => {
  const response = await sendFFBRequest({
    method: 'GET',
    path: '/bids'
  })
  return response.data
}, 'getBids')

export const createNewBids = storeModule.dispatch(
  async (
    ctx,
    params: {
      partnerID: string
      productID: string
      bids: Array<BidData>
    }
  ) => {
    const apiResponses = [] as any[]
    params.bids.forEach(async (bid) => {
      const postResponse = await sendFFBRequest({
        method: 'POST',
        path: '/bids',
        data: {
          product_id: params.productID,
          partner_id: params.partnerID,
          order_quantity: bid.order_quantity,
          total_us_dollars: bid.total_us_dollars,
          lead_time_days: bid.lead_time_days
        }
      })
      apiResponses.push(postResponse)
      return apiResponses
    })
  },
  'createNewBids'
)

export const getProductBids = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: `/bids`,
      query: {
        product_id: params.packageID
      }
    })
    return response.data as { data: BidData[] }
  },
  'getProductBids'
)

export const getPartnerBids = storeModule.dispatch(
  async (
    ctx,
    params: {
      packageID: string
      partnerID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: `/bids`,
      query: {
        product_id: params.packageID,
        partner_id: params.partnerID
      }
    })
    return response.data as { data: BidData[] }
  },
  'getPartnerBids'
)

export const editBidDetails = storeModule.dispatch(
  async (
    ctx,
    params: {
      productID: string
      partnerID: string
      bids: Array<BidData>
      deletedQuantities: Array<string>
    }
  ) => {
    const apiResponses = [] as any[]
    params.bids.forEach(async (bid) => {
      let response
      if (bid.id) {
        response = await sendFFBRequest({
          method: 'PATCH',
          path: `/bids/${bid.id}`,
          data: {
            partner_id: params.partnerID,
            order_quantity: bid.order_quantity,
            total_us_dollars: bid.total_us_dollars
              ? bid.total_us_dollars
              : null,
            lead_time_days: bid.lead_time_days ? bid.lead_time_days : null
          }
        })
      } else {
        response = await sendFFBRequest({
          method: 'POST',
          path: '/bids',
          data: {
            product_id: params.productID,
            partner_id: params.partnerID,
            order_quantity: bid.order_quantity
          }
        })
      }
      // error handling here
      apiResponses.push(response)
    })
    params.deletedQuantities.forEach(async (id) => {
      const response = await sendFFBRequest({
        method: 'DELETE',
        path: `/bids/${id}`
      })
      // error handling here
      apiResponses.push(response)
    })
    return apiResponses
  },
  'editBidDetails'
)

export const getTemplateLayouts = storeModule.dispatch(async () => {
  const response = await sendFFBRequest({
    method: 'GET',
    path: `/layouts`
  })
  return response.data
}, 'getTemplateLayouts')

export const getAttributes = storeModule.dispatch(async () => {
  const response = await sendFFBRequest({
    method: 'GET',
    path: `/fields`
  })
  return response.data
}, 'getAttributes')

export const attributeHasApprovedProduct = storeModule.dispatch(
  async (
    ctx,
    params: {
      fieldID: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      path: `/fields/${params.fieldID}/hasApprovedProduct`
    })
    return response.data
  },
  'attributeHasApprovedProduct'
)

export const getTestCases = storeModule.dispatch(
  async (
    ctx,
    params: {
      sortBy?: string
      order?: string
      page?: number
      limit?: number
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      v2PublicRoute: true,
      path: `/partner_testing/test_cases`,
      query: {
        sort_by: params.sortBy,
        order: params.order,
        page: params.page,
        limit: params.limit
      }
    })
    return {
      data: response.data.data,
      total_count: response.data.total_count,
      links: response.data.links
    }
  },
  'getTestCases'
)

export const getSimpleTestCases = storeModule.dispatch(
  async (
    ctx,
    params: {
      search?: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      v2PublicRoute: true,
      path: `/partner_testing/test_case_ids`,
      query: {
        search: params.search
      }
    })
    return response
  },
  'getSimpleTestCases'
)

export const createNewTestCase = storeModule.dispatch(
  async (
    ctx,
    params: {
      name: string
      description: string
      product_id: string
      components: string
      files: any[]
    }
  ) => {
    const data = new FormData()
    data.append('name', params.name)
    data.append('description', params.description)
    data.append('product_id', params.product_id)

    data.append('components', params.components)
    params.files.map((file) => {
      data.append('files', file)
    })
    const response = await sendFFBRequest({
      method: 'POST',
      path: '/partner_testing/test_cases',
      v2PublicRoute: true,
      data
    })
    return response.data
  },
  'createNewTestCase'
)

export const updateTestCase = storeModule.dispatch(
  async (
    ctx,
    params: {
      id: string
      name: string
      description: string
      product_id: string
      components: string
      files: any[]
    }
  ) => {
    const data = new FormData()
    data.append('name', params.name)
    data.append('description', params.description)
    data.append('product_id', params.product_id)
    data.append('components', params.components)
    params.files.map((file) => {
      data.append('files', file)
    })
    const response = await sendFFBRequest({
      method: 'PATCH',
      path: `/partner_testing/test_cases/${params.id}`,
      v2PublicRoute: true,
      data
    })
    return response.data
  },
  'updateTestCase'
)

export const getTestGroups = storeModule.dispatch(
  async (
    ctx,
    params: {
      search?: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      v2PublicRoute: true,
      path: `/partner_testing/test_groups`,
      query: {
        search: params.search
      }
    })
    return response.data
  },
  'getTestGroups'
)

export const createNewTestGroup = storeModule.dispatch(
  async (
    ctx,
    params: {
      name: string
      description: string
      test_cases: string[]
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'POST',
      path: '/partner_testing/test_groups',
      v2PublicRoute: true,
      data: {
        name: params.name,
        description: params.description,
        test_cases: params.test_cases
      }
    })
    return response.data
  },
  'createNewTestGroup'
)

export const getTestSends = storeModule.dispatch(async () => {
  const response = await sendFFBRequest({
    method: 'GET',
    v2PublicRoute: true,
    path: `/partner_testing/test_sends`,
    query: {
      sort_by: 'date_created',
      order: 'DESC'
    }
  })
  return response.data
}, 'getTestSends')

export const getTestSendOrders = storeModule.dispatch(
  async (ctx, testSendID: string) => {
    const response = await sendFFBRequest({
      method: 'GET',
      v2PublicRoute: true,
      path: `/partner_testing/test_sends/${testSendID}/results`
    })
    return response.data
  },
  'getTestSendOrders'
)

export const createNewTestSend = storeModule.dispatch(
  async (
    ctx,
    params: {
      name: string
      description: string
      partner_ids: string[]
      test_case_ids: { id: string; quantity: number }[]
      overrides: {
        stid: string | null
      }
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'POST',
      path: '/partner_testing/test_sends',
      v2PublicRoute: true,
      data: {
        name: params.name,
        description: params.description,
        partner_ids: params.partner_ids,
        test_case_ids: params.test_case_ids,
        overrides: params.overrides
      }
    })
    return response.data
  },
  'createNewTestSend'
)

export const loadMoreTestResourcesByUrl = storeModule.dispatch(
  async (
    ctx,
    params: {
      url: string
      sort_by?: string
    }
  ) => {
    const response = await sendFFBRequest({
      method: 'GET',
      v2PublicRoute: true,
      path: params.url,
      query: {
        sort_by: params.sort_by ? params.sort_by : 'date_created',
        order: 'DESC'
      }
    })
    return {
      data: response.data.data,
      total_count: response.data.total_count,
      links: response.data.links
    }
  },
  'loadMoreTestResourcesByUrl'
)
