import { ShowChildRoutes } from '@/components/global'
import { RouteRecordRaw } from 'vue-router'
import editor from './create'
import createEditor from './createTestSet'
import View from './View.vue'

export default {
  path: 'test-sets',
  component: ShowChildRoutes,
  children: [
    {
      path: '',
      component: View
    },
    editor,
    createEditor
  ]
} as RouteRecordRaw
