<template>
  <FieldHeader
    :schema="schema"
    :field="field"
    :test-set="testSet"
    @update:testSet="$emit('update:testSet', $event)"
  />
  <P>
    ERROR: There is no editor implemented for a field of type "{{ field.type }}"
  </P>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import FieldHeader from './FieldHeader.vue'

export default defineComponent({
  name: 'UnimplementedMVE',
  components: { FieldHeader },
  props: {
    schema: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    },
    formFactor: {
      type: String,
      required: true
    }
  },
  emits: ['update:testSet']
})
</script>
