import { RouteRecordRaw } from 'vue-router'
import { isAdminOrPopsUser } from '@/store/session'
import View from './View.vue'

export default {
  path: 'bids',
  component: View,
  meta: {
    displayName: () => {
      if (isAdminOrPopsUser()) {
        return 'Manage Bids'
      } else {
        return 'View Bids'
      }
    }
  }
} as RouteRecordRaw
