import { toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LobButton = _resolveComponent("LobButton")!

  return (_openBlock(), _createBlock(_component_LobButton, {
    variant: _ctx.variant,
    disabled: _ctx.isDisabled,
    "data-testid": "send-report",
    class: "w-2/3",
    size: "small",
    onClick: _withModifiers(_ctx.onClick, ["stop"])
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "w-4 h-4 ml-2" }))
    ]),
    _: 1
  }, 8, ["variant", "disabled", "onClick"]))
}