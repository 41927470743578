import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58623019"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "w-full row-space" }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = {
  key: 0,
  class: "table-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PackagePermissionsTableRow = _resolveComponent("PackagePermissionsTableRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (key) => {
            return (_openBlock(), _createElementBlock("th", {
              key: key.name,
              class: "border-t-0 text-gray-900 font-light text-base p-3",
              colspan: key.span
            }, _toDisplayString(key.name), 9, _hoisted_3))
          }), 128))
        ])
      ]),
      (_ctx.data.length)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
              return (_openBlock(), _createBlock(_component_PackagePermissionsTableRow, {
                key: item.id,
                item: item,
                class: "m-10"
              }, null, 8, ["item"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}