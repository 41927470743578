import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f058a4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "onboarding-root" }
const _hoisted_2 = {
  id: "content",
  class: "overflow-y-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Checklist, {
      list: _ctx.list,
      title: "Onboarding"
    }, null, 8, ["list"]),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}