import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "text-error"
}
const _hoisted_5 = ["id", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.label.length > 0)
            ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      id: _ctx.id,
      type: "text",
      value: _ctx.value,
      placeholder: _ctx.placeholder,
      class: _normalizeClass(_ctx.calculatedClass),
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42, _hoisted_5)
  ]))
}