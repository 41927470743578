<script lang="ts">
import { computed, defineComponent, reactive } from 'vue'

const searchByOpts = [
  { value: 'company', label: 'Company' },
  { value: 'account_id', label: 'Account ID' },
  { value: 'id', label: 'ID' }
]

const filterTests = {
  id: /^card_[a-z0-9]{15}|^env_[a-z0-9]{17}/,
  account_id: /^[a-z0-9]{20}$/
} as const
export default defineComponent({
  name: 'InventorySearchForm',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['search', 'update:filter'],
  setup(_, { emit }) {
    const searchQuery = reactive({
      by: searchByOpts[0],
      query: ''
    })
    const placeholder = computed(
      () =>
        ({
          company: `Search for a Company Name`,
          account_id: `Search for an Account ID`,
          id: `Search for a Materiel ID`
        }[searchQuery.by.value])
    )

    const setSelect = (x) => {
      searchQuery.query = x
      const key = Object.keys(filterTests).filter((k) => {
        return filterTests[k].test(x)
      })[0]

      searchQuery.by =
        searchByOpts.find((e) => e.value === key) || searchByOpts[0]

      if (x.startsWith('card_')) {
        emit('update:filter', { value: 'cards', label: 'Card' })
      }

      if (x.startsWith('env_')) {
        emit('update:filter', { value: 'envelopes', label: 'Envelope' })
      }
    }

    return {
      searchQuery,
      searchByOpts,
      placeholder,
      setSelect
    }
  }
})
</script>

<template title="Invite Partner User">
  <form
    data-testid="searchForm"
    class="p-4 rounded-lg shadow-sm border border-light flex align-items-center gap-8"
    @submit.prevent
  >
    <Dropdown
      id="searchBy"
      v-model="searchQuery.by"
      label=""
      data-testid="searchByDropdown"
      class="w-64 m-0"
      :options="searchByOpts"
      :required="false"
    />
    <TextInput
      id="searchInput"
      :model-value="searchQuery.query"
      label=""
      data-testid="searchInput"
      :placeholder="placeholder"
      class="flex-1"
      name="search"
      @update:modelValue="setSelect"
    >
      <template #iconLeft>
        <Search class="w-6 h-6 mr-2" />
      </template>
    </TextInput>
    <LobButton
      :disabled="isLoading"
      data-testid="searchButton"
      class="w-64"
      @click="
        () =>
          $emit('search', {
            query: searchQuery.query,
            by: searchQuery.by.value
          })
      "
    >
      Search
    </LobButton>
  </form>
</template>
