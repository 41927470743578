export interface Config {
  LOB_API_BASEURL: string
  LOB_API_V2_BASEURL: string
  FORM_FACTOR_BASEURL: string
  PARTNERS_API_BASEURL: string
  SID_API_BASEURL: string
  PORT: number
  SENTRY_LOGGING_DISABLED: boolean
  SENTRY_DSN: string
  APP_RELEASE: string
  LD_CLIENT_ID: string
  S3_PARTNER_DOCS_BUCKET: string
  SEGMENT_KEY: string
  GITBOOK_BASE_URL: string
}

const DEFAULT: Config = {
  LOB_API_BASEURL: 'https://api.lob-staging.com/v1',
  LOB_API_V2_BASEURL: 'https://api.lob-staging.com/v2',
  FORM_FACTOR_BASEURL: 'https://form-factor-service.lob-staging.com/v2',
  PARTNERS_API_BASEURL: 'https://partners-api.lob-staging.com/v1',
  SID_API_BASEURL: 'https://sid.lob-staging.com/v1',
  PORT: 7674,
  SENTRY_LOGGING_DISABLED:
    process.env.VUE_APP_SENTRY_LOGGING_DISABLED === 'true',
  SENTRY_DSN:
    'https://d3ab02315de4448d81010c3e76a9a9aa@o66861.ingest.sentry.io/6021507',
  APP_RELEASE: '0',
  LD_CLIENT_ID: '60be5b38b7f2ce0e5958537f',
  S3_PARTNER_DOCS_BUCKET:
    'https://s3.us-west-2.amazonaws.com/partners-api.lob-staging.com/partner_documentation/',
  SEGMENT_KEY: 'no_segment_in_dev_staging',
  GITBOOK_BASE_URL: 'https://lob.gitbook.io/lob-print-partner-docs'
}

const environmentSpecificOverrides: Record<string, Partial<Config>> = {
  production: {
    LOB_API_BASEURL: 'https://api.lob.com/v1',
    LOB_API_V2_BASEURL: 'https://api.lob.com/v2',
    LD_CLIENT_ID: '60b16bd966bc420e95fbfd57',
    S3_PARTNER_DOCS_BUCKET:
      'https://s3.us-west-2.amazonaws.com/partners-api.lob.com/partner_documentation/',
    SEGMENT_KEY: 'eBGGO92tbDslCGdYcTvPaqC1CWhaigKf'
  },
  development: {
    LOB_API_BASEURL: 'http://api.lob-local.com:8443/v1',
    PARTNERS_API_BASEURL: 'http://0.0.0.0:4321/v1',
    SID_API_BASEURL: 'http://0.0.0.0:5678/v1',
    FORM_FACTOR_BASEURL: 'http://0.0.0.0:8081/v2'
  },
  staging: {},
  test: {}
}

const environment = (process.env.NODE_ENV as string) || 'development'
export default Object.assign(
  DEFAULT,
  environmentSpecificOverrides[environment] || {},
  { ENVIRONMENT: environment }
)
