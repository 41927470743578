import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnersTableRow = _resolveComponent("PartnersTableRow")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, (partner) => {
      return (_openBlock(), _createElementBlock("div", {
        key: partner.id,
        class: "mb-1 shadow-md shadow-black"
      }, [
        _createVNode(_component_PartnersTableRow, {
          partner: partner,
          "partner-facilities": _ctx.partnerFacilities[partner.id] || []
        }, null, 8, ["partner", "partner-facilities"])
      ]))
    }), 128))
  ]))
}