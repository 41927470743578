import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Textarea, {
      id: "artwork",
      key: "artwork",
      label: "JSON",
      "model-value": _ctx.textValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event)))
    }, null, 8, ["model-value"]),
    (_ctx.syntaxError)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 0,
          variant: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.syntaxError), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}