<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'

import { Thumbnails } from './types'
import EnvelopeIcon from './EnvelopeIcon.vue'

export default defineComponent({
  name: 'Preview',
  components: { EnvelopeIcon },
  props: {
    thumbnails: {
      type: Array as PropType<Thumbnails>,
      default: () => []
    }
  },
  emits: ['expand'],
  setup() {
    const thumbHidden = ref(false)

    const hideThumb = () => {
      thumbHidden.value = true
    }
    return {
      thumbHidden,
      iconVariants: ['front', 'back'],
      imgClass: 'fullsize bg-white p-3 border mt-2 rounded-sm',
      hideThumb
    }
  }
})
</script>

<template>
  <div class="flex gap-3">
    <template v-for="index in [0, 1]" :key="index">
      <div v-if="thumbnails?.[index] && !thumbHidden">
        <img class="thumb" :src="thumbnails[index].small" @error="hideThumb" />
        <img :class="imgClass" :src="thumbnails[index].large" />
      </div>
      <EnvelopeIcon v-else :variant="iconVariants[index]" />
    </template>
  </div>
</template>

<style>
.thumb {
  width: 44px;
  height: 20px;
}
.fullsize {
  display: none;
  position: absolute;
  z-index: 9;
  max-width: 70vw;
}
.thumb:hover + .fullsize {
  display: block;
}
</style>
