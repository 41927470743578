<template>
  <div class="flex flex-column">
    <div
      class="bg-mist shadow-md shadow-black rounded w-100 grid grid-cols-6 items-baseline justify-center p-2"
    >
      <div class="pl-3 col-span-5 content-baseline">
        {{ `Location ${partnerFacility.zipcode}` }}
      </div>
      <div class="flex justify-end pr-3">
        <button @click="openDetails()">
          <fa
            class="text-slate-300"
            :icon="isDetailOpen ? 'chevron-up' : 'chevron-down'"
          />
        </button>
      </div>
    </div>
    <div
      v-if="isDetailOpen"
      class="flex flex-column w-100 items-baseline justify-items-center text-sm pt-3 pl-2 pb-3 rounded shadow-sm shadow-black"
    >
      <div class="grid grid-cols-6 w-100">
        <div class="flex flex-col pl-4 my-2">
          <div class="flex flex-row text-xl my-1">Facility Information</div>
          <div class="pl-4 my-1">
            <span class="font-bold">Zipcode:</span>
            <span>{{ partnerFacility.zipcode }}</span>
          </div>
          <div class="pl-4 my-1">
            <span class="font-bold">Timezone:</span>
            {{ partnerFacility.timezone }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-6 w-100">
        <div class="pl-4 flex flex-col justify-end col-span-4">Product</div>
        <div class="flex flex-col justify-end">Status</div>
        <div class="col-start-6 flex justify-end pr-3">
          <PackageButton :on-click="redirectToProductsList">
            Manage Products
          </PackageButton>
        </div>
      </div>
      <div
        v-for="product in products"
        :key="product.id"
        class="flex flex-column w-100 py-1"
      >
        <PartnerFacilitiesInnerTableRow
          class="flex items-baseline flex-row"
          :partner-facility="partnerFacility"
          :product="product"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import router from '@/router'
import { PartnerFacility, PartnerFacilityAbility } from '@/store/partners'
import PartnerFacilitiesInnerTableRow from './PartnerFacilitiesInnerTableRow.vue'
import PackageButton from '../../customMail/common/PackageButton.vue'

export default defineComponent({
  name: 'PartnerFacilitiesTableRow',
  components: {
    PartnerFacilitiesInnerTableRow,
    PackageButton
  },
  props: {
    partnerFacility: {
      type: Object as PropType<PartnerFacility>,
      required: true
    },
    products: {
      type: Array as PropType<Array<PartnerFacilityAbility>>,
      required: true
    }
  },
  data() {
    return {
      isDetailOpen: true
    }
  },
  methods: {
    openDetails() {
      this.isDetailOpen = !this.isDetailOpen
    },
    redirectToProductsList() {
      const currentRoute = this.$route.path
      router.push({
        path: `${currentRoute}/${this.partnerFacility.id}/products_list`
      })
    }
  }
})
</script>
