import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editorComponent), {
    "test-set": _ctx.testSet,
    "form-factors": _ctx.formFactors,
    "onUpdate:testSet": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:testSet', $event))),
    "onUpdate:formFactor": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:formFactor', $event))),
    "onUpdate:ffComponents": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:ffComponents', $event))),
    onUpdateCurrentPage: _ctx.updateCurrentPage
  }, null, 40, ["test-set", "form-factors", "onUpdateCurrentPage"]))
}