<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ResultValueBox',
  props: {
    label: { type: String, required: true },
    value: { type: String, required: true },
    subLabel: { type: String, required: true }
  }
})
</script>

<template>
  <div
    class="rounded bg-white-100 border border-gray-300 p-2 flex justify-between items-center"
  >
    <div
      class="text-xs text-gray-300 uppercase w-14 font-bold leading-none w-32 mr-3"
    >
      {{ label }}
    </div>
    <div class="text-center">
      <div class="text-5xl font-light text-primary-500">{{ value }}</div>
      <div class="text-xs font-bold text-gray-500 uppercase">
        {{ subLabel }}
      </div>
    </div>
  </div>
</template>
