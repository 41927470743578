import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b940bc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_P = _resolveComponent("P")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_P, { class: "form-factor-title" }, {
      default: _withCtx(() => [
        _createTextVNode("(" + _toDisplayString(_ctx.formFactorTitle) + " resource)", 1)
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formFactorFields, (field) => {
      return (_openBlock(), _createElementBlock("div", {
        key: field.id
      }, [
        _createVNode(_component_P, null, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(field.name) + ":", 1),
            _createTextVNode(" " + _toDisplayString(_ctx.getFieldText(field.id)), 1)
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ], 64))
}