<script lang="ts">
import { defineComponent } from 'vue'

const SUPPORT_ADDRESS = 'mailto:partnerops@lob.com'

export default defineComponent({
  name: 'HomeAssistance',
  setup() {
    // function redirect to
    const redirectTo = (url: string) => {
      window.location.href = url
    }
    return { redirectTo, SUPPORT_ADDRESS }
  }
})
</script>

<template>
  <div>
    <p class="text-gray-300 mb-3 pt-3 font-light">Need some assistance?</p>
    <LobButton
      as="link"
      variant="tertiary"
      class="w-full"
      @click="redirectTo(SUPPORT_ADDRESS)"
    >
      Contact Lob Support
    </LobButton>
  </div>
</template>
