import { RouteRecordRaw } from 'vue-router'
import guidelines from './guidelines'
import missing from './missing'
import permutation from './permutation'
import View from './View.vue'

export default {
  path: 'digital-proofs',
  component: View,
  children: [
    guidelines,
    missing,
    permutation,
    {
      path: '/onboarding/digital-proofing/',
      redirect: '/partners/current/onboarding/digital-proofs'
    },
    {
      path: '/onboarding/digital-proofing/:part',
      redirect: (from) =>
        `/partners/current/onboarding/digital-proofs/${from.params.part}`
    }
  ]
} as RouteRecordRaw
