<script lang="ts">
import {
  formatDate,
  underscoreToSpace,
  capitalize,
  formatNumber
} from '@/utils/formatters'
import { defineComponent, onMounted, ref } from 'vue'
import Pane from '@/components/common/Pane.vue'
import CapacityCard from './CapacityCard.vue'
import {
  getCurrentPartnerId,
  getPartnerCapacity,
  getPartnerLiveCapacity,
  LiveCapacity,
  PartnerCapacity
} from '@/store/partners'

const LIVE_CAPACITY_VALUE_MAP = {
  checks: {
    default: 'num_sheets_remaining',
    one_day_sla: 'one_day_sla_num_sheets_remaining'
  },
  letters: {
    default: 'num_sheets_remaining',
    one_day_sla: 'one_day_sla_num_sheets_remaining'
  },
  postcards: {
    default: 'num_postcards_remaining',
    one_day_sla: 'one_day_sla_num_postcards_remaining'
  },
  self_mailers: {
    default: 'num_self_mailers_remaining',
    one_day_sla: 'one_day_sla_num_self_mailers_remaining'
  }
} as const

const CAPACITY_VALUE_MAP = {
  checks: {
    default: 'sheets_per_day',
    one_day_sla: 'one_day_sla_daily_capacity'
  },
  letters: {
    default: 'sheets_per_day',
    one_day_sla: 'one_day_sla_daily_capacity'
  },
  postcards: {
    default: 'postcards_per_day',
    one_day_sla: 'one_day_sla_daily_capacity'
  },
  self_mailers: {
    default: 'self_mailers_per_day',
    one_day_sla: 'one_day_sla_daily_capacity'
  }
} as const

export default defineComponent({
  name: 'HomeCapacity',
  components: { Pane, CapacityCard },
  setup() {
    const partner = getCurrentPartnerId()
    const liveCapacity = ref<LiveCapacity | null>(null)
    const partnerCapacity = ref<PartnerCapacity | null>(null)
    const defaultSLA = ref('default')
    const oneDaySLA = ref('one_day_sla')

    onMounted(async () => {
      if (partner === null) return
      const [lc, tc] = await Promise.all([
        getPartnerLiveCapacity({ partner }),
        getPartnerCapacity({ partner })
      ])

      liveCapacity.value = lc
      partnerCapacity.value = tc
    })

    const formatLiveValue = (key: number, value: string, slaType: string) => {
      return formatNumber(Number(value[LIVE_CAPACITY_VALUE_MAP[key][slaType]]))
    }
    const formatTotalValue = (key: number, value, slaType: string) => {
      const val = value?.[key]?.[CAPACITY_VALUE_MAP[key][slaType]] || 0
      return formatNumber(Number(val))
    }

    return {
      formatDate,
      underscoreToSpace,
      capitalize,
      liveCapacity,
      partnerCapacity,
      formatLiveValue,
      formatTotalValue,
      defaultSLA,
      oneDaySLA
    }
  }
})
</script>

<template>
  <section>
    <h3 class="text-gray-300 font-thin text-xl my-4">
      Today's Live Capacities
      <span class="font-normal text-base underline ml-2">
        {{ formatDate(new Date().toString(), { dateStyle: 'long' }) }}
      </span>
    </h3>
    <div class="text-m text-gray-700 font-italic">
      Capacities will be set to 0 (zero) for printers on their Zero Production
      Days (ZPD)
    </div>
    <div>
      <Pane
        v-for="(value, key) in liveCapacity"
        :key="key"
        :subtitle="capitalize(underscoreToSpace(String(key)))"
        class="my-4"
        :shadow="false"
        :force-open="String(key) === 'checks'"
        :has-aside="false"
      >
        <div class="grid xl:grid-cols-2 2xl:grid-cols-3 gap-4 w-full">
          <CapacityCard
            :resource="key"
            :title="underscoreToSpace(String(`${key} (${defaultSLA})`))"
            subtitle="&nbsp;"
            :value="formatLiveValue(key, value, defaultSLA)"
            :total="formatTotalValue(key, partnerCapacity, defaultSLA)"
          />
          <CapacityCard
            :resource="key"
            :title="underscoreToSpace(String(`${key} (${oneDaySLA})`))"
            subtitle="&nbsp;"
            :value="formatLiveValue(key, value, oneDaySLA)"
            :total="formatTotalValue(key, partnerCapacity, oneDaySLA)"
          />
        </div>
      </Pane>
    </div>
  </section>
</template>
