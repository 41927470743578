import { ShowChildRoutes } from '@/components/global'
import { RouteRecordRaw } from 'vue-router'
import approveDigitalProof from './approveDigitalProof'
import partnerUserInvites from './partnerUserInvites'
import resendPartnerUserInvite from './partnerUserInvites/resendPartnerUserInvite'
import invitePartnerUser from './partnerUserInvites/invitePartnerUser'
import userManagement from './userManagement'
import partners from './partners'
import partnersList from './partnersList'
import testSets from './testSets'
import auditLog from './auditLog'
import bulkJobs from './bulkJobs'
import sendMail from './sendMail'
import accountOverview from './inventory/account-overview'
import inventorySearch from './inventory/search'
import inventorySpoilage from './inventory/spoilage'
import changelogs from './inventory/changelogs'
import slaMissQuality from './slaMissQuality'
import SLAStatus from './SLAStatus'
import customMail from './customMail'
import v2Testing from './v2Testing'
import packageComponents from './customMail/packageComponents'
import { isAdminUser, waitForUserToUpdate } from '@/store/session'
import packageList from './customMail/packageList'
import routing from './routing'

export default {
  path: '/partner_ops',
  component: ShowChildRoutes,
  children: [
    approveDigitalProof,
    partnerUserInvites,
    invitePartnerUser,
    resendPartnerUserInvite,
    userManagement,
    partners,
    partnersList,
    testSets,
    auditLog,
    bulkJobs,
    sendMail,
    inventorySearch,
    changelogs,
    accountOverview,
    inventorySpoilage,
    slaMissQuality,
    SLAStatus,
    customMail,
    v2Testing,
    packageComponents,
    packageList,
    routing
  ],
  beforeEnter: async () => {
    await waitForUserToUpdate()
    if (!isAdminUser()) {
      return { path: '/' }
    }
  }
} as RouteRecordRaw
