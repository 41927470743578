<template>
  <MainNavigation list-class="overflow-x-hidden overflow-y-scroll h-screen">
    <template #default="{ expanded }">
      <MainNavigationItem
        id="guidelines"
        title="Guidelines"
        :expanded="expanded"
        to="/onboarding/digital-proofing/guidelines"
      />
      <MainNavigationItem
        v-for="item in items"
        :key="item.resourceId"
        :title="item.resourceName"
        :expanded="expanded"
        :item-class="'font-bold'"
      >
        <MainNavigationChildItem
          v-for="permutation in item.permutations"
          :key="permutation.id"
          :title="permutation.name"
          :to="`/partners/current/onboarding/digital-proofs/${
            permutation.id || 'missing'
          }`"
        />
      </MainNavigationItem>
    </template>
  </MainNavigation>
</template>

<script lang="ts">
import {
  getCurrentPartnerPermutations,
  Permutation
} from '@/store/digitalProofing'
import { defineComponent } from 'vue'

interface Item {
  resourceId: string
  resourceName: string
  permutations: Array<{
    id: string
    name: string
  }>
}

export default defineComponent({
  name: 'SecondaryNavigationPanel',
  computed: {
    items(): Array<Item> {
      return this.sortedResourceIds.map((resourceId) => {
        return {
          resourceId,
          resourceName: this.snakeCaseToHumanCase(resourceId),
          permutations: this.menuItemsForResource(resourceId)
        }
      })
    },
    permutations(): Array<Permutation> {
      const permutations = getCurrentPartnerPermutations()
      if (permutations === null) return []
      return permutations
    },
    sortedResourceIds(): Array<string> {
      const allResourceIds = new Set<string>()
      this.permutations.forEach((perm) => allResourceIds.add(perm.resource))
      return [...allResourceIds.values()].sort()
    }
  },
  methods: {
    permutationsForResource(resourceId: string): Array<Permutation> {
      return this.permutations.filter((perm) => perm.resource === resourceId)
    },
    menuItemsForResource(
      resourceId: string
    ): Array<{ id: string; name: string }> {
      return this.permutationsForResource(resourceId).map((perm) => ({
        id: perm.id,
        name: this.snakeCaseToHumanCase(perm.permutation)
      }))
    },
    snakeCaseToHumanCase(string: string): string {
      return string
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }
  }
})
</script>
