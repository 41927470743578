<template>
  <table class="w-100 row-space min-w-fit">
    <thead>
      <tr class="w-100">
        <th
          v-for="key in tableHeaders"
          :key="key.name"
          class="border-t-0 text-gray-900 font-light text-base p-3"
          :colspan="key.span"
        >
          {{ key.name }}
        </th>
      </tr>
    </thead>
    <tbody v-if="data.length" class="table-body">
      <PackagesTableRow
        v-for="item in data"
        :key="item.id"
        class="m-10"
        :item="item"
        :is-admin-or-pops-user="isAdminOrPopsUser"
        @openCloneModal="openCloneModal"
        @alertMsgEvent="alertMsgEvent"
      />
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PackagesTableRow from './PackagesTableRow.vue'

export default defineComponent({
  name: 'PackagesTable',
  components: {
    PackagesTableRow
  },
  props: {
    isAdminOrPopsUser: {
      type: Boolean,
      default() {
        return false
      }
    },
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['openCloneModal', 'alertMsgEvent'],
  computed: {
    tableHeaders(): Array<any> {
      const headers = [
        { name: 'Package ID', span: 1 },
        { name: 'Title', span: 1 },
        { name: 'Description', span: 2 },
        { name: 'Status', span: 1 },
        { name: 'Date', span: 1 },
        { name: 'Downloads', span: 1 },
        { name: 'Edit/Clone', span: 1 }
      ]
      // add admin headers if necessary
      if (this.isAdminOrPopsUser) {
        headers.splice(
          1, // insert into array index 1 (after 'Package ID')
          0, // but don't remove any elements
          ...[
            { name: 'Customer Account ID', span: 1 },
            { name: 'Customer', span: 1 }
          ]
        )
      }
      return headers
    }
  },
  methods: {
    openCloneModal(item: any) {
      this.$emit('openCloneModal', item)
    },
    alertMsgEvent(msg: string) {
      this.$emit('alertMsgEvent', msg)
    }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
