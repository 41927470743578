<template>
  <div>
    <LoadingIndicator>
      <div v-if="!isLoading">
        <div v-if="errorMessage !== null">
          <Alert variant="error">{{ errorMessage }}</Alert>
        </div>
        <div v-else>
          <div
            v-for="abilityExclusion in abilityExclusions"
            :key="abilityExclusion.id"
            class="mb-1 shadow-md shadow-black"
          >
            <AbilityExclusionsTableRow :ability-exclusion="abilityExclusion" />
          </div>
        </div>
      </div>
    </LoadingIndicator>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  AbilityExclusion,
  getPartnerFacilityAbilityExclusions
} from '@/store/partners'
import AbilityExclusionsTableRow from './AbilityExclusionsTableRow.vue'

export default defineComponent({
  name: 'AbilityExclusionsTable',
  components: {
    AbilityExclusionsTableRow
  },
  props: {
    partnerId: {
      type: String,
      required: true
    },
    partnerFacilityId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      abilityExclusions: [] as AbilityExclusion[],
      isLoading: false,
      errorMessage: null as string | null
    }
  },
  async created() {
    this.isLoading = true

    try {
      const abilityExclusions = await getPartnerFacilityAbilityExclusions({
        partnerFacilityId: this.partnerFacilityId
      })
      this.abilityExclusions = abilityExclusions
    } catch (e) {
      this.errorMessage = String(e)
    }

    this.isLoading = false
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
