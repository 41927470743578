import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'
import { ShowChildRoutes } from '@/components/global'
import attributesPage from '../attributesPage'
import { isAdminOrPopsUser } from '@/store/session'

export default {
  path: ':componentID',
  component: ShowChildRoutes,
  children: [
    {
      path: '',
      component: View
    },
    attributesPage
  ],
  meta: {
    displayName: () => {
      if (isAdminOrPopsUser()) {
        return 'Manage Component'
      } else {
        return 'View Component'
      }
    },
    useParam: 'componentID'
  }
} as RouteRecordRaw
