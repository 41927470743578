<template>
  <div
    v-if="isMasquerading"
    class="flex flex-row justify-center items-center text-bannerText bg-bannerBack py-1"
  >
    <span class="font-bold">User:</span>
    <span>{{ `&nbsp; ${userDisplayName} /&nbsp; ` }}</span>
    <span class="font-bold">Partner View:</span>
    <span>{{ `&nbsp; ${masqueradePartnerDisplayName} / &nbsp;` }}</span>
    <span class="font-bold">Login Time:</span>
    <span>{{ `&nbsp; ${masqueradeLoginTime}` }}</span>
    <LobButton variant="link" class="ml-3" @click="logoutOfMasquerade">
      Sign Out
    </LobButton>
  </div>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getUser, logoutMasquerade } from '@/store/session'
import router from '@/router'

export default defineComponent({
  name: 'App',
  computed: {
    currentUser(): any {
      return getUser() || null
    },
    userDisplayName(): string {
      return this.currentUser ? this.currentUser.email : null
    },
    masqueradePartnerDisplayName(): string {
      return this.currentUser
        ? this.currentUser.masquerade_info.masquerade_partner_id
        : null
    },
    masqueradeLoginTime(): string {
      if (this.currentUser) {
        const loginTime = this.currentUser.masquerade_info.masquerade_login_time
        const convertedTime = loginTime.substring(0, loginTime.lastIndexOf('.'))
        return convertedTime
      }
      return ''
    },
    isMasquerading(): boolean {
      return this.currentUser
        ? this.currentUser.masquerade_info.masquerade_partner_id.length !== 0
        : false
    }
  },
  methods: {
    logoutOfMasquerade() {
      logoutMasquerade()
      router.push('/partner_ops/user_management')
    }
  }
})
</script>

<style lang="scss">
.combobox .cursor-default,
.combobox .py-1 {
  padding-left: 1rem !important;
}
.combobox .max-h-96 {
  z-index: 1;
}
</style>
