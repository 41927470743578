import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'
import packagePage from './packagePage'
import { ShowChildRoutes } from '@/components/global'

export default {
  path: 'custom-mail',
  component: ShowChildRoutes,
  children: [
    {
      path: '',
      component: View
    },
    packagePage
  ],
  meta: {
    isStartingCrumb: true,
    displayName: 'Custom Mail'
  }
} as RouteRecordRaw
