import config from '../config'
import { SubscriptionTier } from './store/customMail'

export type ResorceType =
  | 'checks'
  | 'letters'
  | 'postcards'
  | 'self_mailers'
  | 'booklets'
  | 'snap_packs'

export type Capacities = Record<ResorceType, number>
export type PartnersCapacities = Record<string, Capacities>

export const RESOURCES: Record<
  ResorceType,
  { id: ResorceType; label: string; unit: string; routingQueueUnit?: string }
> = {
  checks: {
    id: 'checks',
    label: 'Checks',
    unit: 'Sheets',
    routingQueueUnit: 'check'
  },
  letters: {
    id: 'letters',
    label: 'Letters',
    unit: 'Sheets',
    routingQueueUnit: 'letter'
  },
  postcards: {
    id: 'postcards',
    label: 'Postcards',
    unit: 'Postcards',
    routingQueueUnit: 'postcard'
  },
  self_mailers: {
    id: 'self_mailers',
    label: 'Self Mailers',
    unit: 'Self mailers',
    routingQueueUnit: 'self_mailer'
  },
  booklets: {
    id: 'booklets',
    label: 'Booklets',
    unit: 'Booklets',
    routingQueueUnit: 'boklet'
  },
  snap_packs: {
    id: 'snap_packs',
    label: 'Snap Packs',
    unit: 'Snap Packs',
    routingQueueUnit: 'snap_pack'
  }
}

const routingResourcesMap = Object.values(RESOURCES).map((resource) => ({
  label: resource.label,
  value: resource.routingQueueUnit
}))

export const POSTCARD_SIZES = [
  {
    label: '4x6',
    value: '4x6'
  },
  {
    label: '5x7',
    value: '5x7'
  },
  {
    label: '5.5x8.5',
    value: '5.5x8.5'
  },
  {
    label: '6x9',
    value: '6x9'
  },
  {
    label: '6x11',
    value: '6x11'
  },
  {
    label: 'A5',
    value: 'a5'
  }
]

export const LETTER_SIZES = [
  {
    label: 'A4',
    value: 'a4'
  },
  {
    label: 'US Letter',
    value: 'us_letter'
  }
]

export const SELF_MAILER_SIZES = [
  {
    label: '6x18 Bifold',
    value: '6x18_bifold'
  },
  {
    label: '12x9 Bifold',
    value: '12x9_bifold'
  },
  {
    label: '11x9 Bifold',
    value: '11x9_bifold'
  }
]

export const BULK_JOBS = {
  ORPHANED_STATUS: 'orphaned',
  PAUSED_STATUS: 'paused',
  FLUSH_OPTIONS: ['Orphaned Orders', 'Paused Orders'],
  PRODUCT_SELECTION_OPTIONS: Object.values(RESOURCES).map((resource) => ({
    label: resource.label,
    value: resource.id
  })),
  FORM_FACTOR_OPTIONS: [
    'letter',
    'postcard',
    'check',
    'self_mailer',
    'snap_pack',
    'booklet'
  ],
  FLUSH_DROPDOWNS: [
    {
      id: 'selected_flush_status',
      queryKey: 'status',
      label: 'Orders to Flush',
      placeholder: 'Pick a flush option',
      selectedValue: {},
      options: [
        {
          label: 'Orphaned Orders',
          value: 'orphaned'
        },
        {
          label: 'Paused Orders',
          value: 'paused'
        }
      ]
    },
    {
      id: 'form_factor',
      queryKey: 'form_factor',
      label: 'Form Factor',
      placeholder: 'Pick a Form Factor',
      selectedValue: {},
      options: routingResourcesMap
    }
  ],
  SIZE_DROPDOWN: {
    id: 'size',
    queryKey: 'size',
    label: 'Size',
    placeholder: 'Pick a option',
    selectedValue: {},
    options: [...POSTCARD_SIZES, ...LETTER_SIZES, ...SELF_MAILER_SIZES]
  },
  FLUSH_INPUTS: [
    {
      id: 'account_id',
      queryKey: 'account_id',
      label: 'Account ID',
      includePlaceholder: 'Include IDs',
      excludePlaceholder: 'Exclude IDs'
    },
    {
      id: 'envelope_id',
      queryKey: 'envelope_id',
      label: 'Envelope ID',
      includePlaceholder: 'Include IDs',
      excludePlaceholder: 'Exclude IDs'
    },
    {
      id: 'inventory_id',
      queryKey: 'inventory_id',
      label: 'Inventory ID',
      includePlaceholder: 'Include IDs',
      excludePlaceholder: 'Exclude IDs'
    },
    {
      id: 'billing_group_id',
      queryKey: 'billing_group_id',
      label: 'Billing Group ID',
      includePlaceholder: 'Include IDs',
      excludePlaceholder: 'Exclude IDs'
    }
  ],
  REDRIVE_SUCCESS_MESSAGE:
    'Flush job submitted successfully! View its status in the Job Queue.'
}

// knock off the 's' in resource for form factor service
const ffResourcesMap = Object.values(RESOURCES).map((resource) => ({
  label: resource.label.substring(0, resource.label.length - 1),
  value: resource.id.substring(0, resource.id.length - 1)
}))

export type DropdownItem = {
  id: string | null
  label: string
}

// Partner
export const PARTNER_STATUS = {
  LIVE: 'live',
  TESTING: 'testing',
  PROSPECTING: 'prospecting',
  ALL: '*'
} as const

export type PartnerStatusType =
  typeof PARTNER_STATUS[keyof typeof PARTNER_STATUS]

export const MATERIEL_INVENTORY_CHANGE_TYPES = {
  DELETION: 'deletion',
  DEPLETION: 'depletion',
  EXPIRATION: 'expiration',
  PARNER_RECONCILIATION: 'partner_reconciliation',
  RESERVED_FOR_SPOILAGE: 'reserved_for_spoilage',
  TRANSFER: 'transfer',
  UNEXPECTED_SPOILAGE: 'unexpected_spoilage',
  UNRESERVED_NON_SPOILED: 'unreserved_non_spoiled'
} as const

export const MATERIEL_INVENTORY_STATUS = {
  DELIVERED: 'delivered',
  DEPLETED: 'depleted',
  EXPIRED: 'expired',
  IN_TRANSIT: 'in_transit',
  IN_TRANSIT_TRANSFER: 'in_transit_transfer',
  PRINTING: 'printing',
  TRANSFERRED: 'transferred'
} as const

export const MATERIEL_INVENTORY_STATUS_OPTIONS = [
  MATERIEL_INVENTORY_STATUS.DELIVERED,
  MATERIEL_INVENTORY_STATUS.IN_TRANSIT,
  MATERIEL_INVENTORY_STATUS.PRINTING
]

export const TEST_SET_WIZARD_PAGE = {
  CREATE_TEST_SET_HOME: 'create_test_set_home',
  FORM_FACTOR_SELECTION: 'form_factor_selection',
  CREATE_CONFIRMATION: 'create_confirmation',
  TEST_SET_HOME: 'test_set_home'
}

export const TEST_SET_CONSTS = {
  DEFAULT_CUSTOM_ENVELOPES: 'default_custom_envelopes',
  DEFAULT_CUSTOM_CARDS: 'default_custom_cards',
  DEFAULT_RETURN_ENVELOPES: 'default_return_envelopes'
}

export const FORM_FACTOR_COMPONENTS = {
  CHECKBOX: 'checkbox',
  LIST: 'list'
}

export const FORM_FACTOR_TYPES = {
  CHECKS: 'checks',
  LETTERS: 'letters',
  POSTCARDS: 'postcards',
  SELF_MAILERS: 'self_mailers'
}

export const ERRORS = {
  NULL_TESTSET: 'Error: Null test set'
}

export const LOCATIONS = {
  DOMESTIC: 'Domestic',
  INTERNATIONAL: 'International'
}

export const INTERNATIONAL_FROM_ADDRESS = {
  name: 'Larry International',
  company: 'Lob.com',
  address_line1: 'Chequers Lane',
  address_line2: 'Unit 11 Thames Gateway Park',
  address_city: 'Dagenham',
  address_zip: 'RM9 6FB',
  address_country: 'GB'
}

export const DEFAULT_BANK_ACCOUNT =
  config.ENVIRONMENT === 'production'
    ? 'bank_b1a83848f69163c'
    : 'bank_4de0d3896e2dbf0'

export const DEFAULT_CHECK_ARTWORK = [
  'check_01.pdf',
  'check_02.pdf',
  'check_03.pdf'
]

export const DEFAULT_LETTER_ARTWORK = [
  '1page_bw_cardaffix_bifold_letter.pdf',
  '1page_bw_cardaffix_cfold_letter.pdf',
  '1page_bw_perf.pdf',
  '1page_color_letter_raw.pdf',
  '1page_color_perf_letter.pdf',
  '2page_bw_cardaffix_bifold_letters.pdf',
  '2page_color_cardaffix_cfold_letters.pdf',
  '2page_color_raw_letter.pdf',
  '2page_color_zfold_letter_02.pdf',
  '2page_color_zfold_letter.pdf',
  '6page_bw_perf_letter.pdf',
  '3pg_bw_letter_raw.pdf',
  '7page_bw_letter_raw.pdf',
  '7page_perf_letter.pdf',
  '8page_color_letter_raw.pdf'
]

export const DEFAULT_POSTCARD_ARTWORK = [
  '4x6_postcard_01_back.pdf',
  '4x6_postcard_01_front.pdf',
  '4x6_postcard_02_back.pdf',
  '4x6_postcard_02_front.pdf',
  '4x6_postcard_03_back.pdf',
  '4x6_postcard_03_front.pdf',
  '5x7_postcard_01_back.pdf',
  '5x7_postcard_01_front.pdf',
  '6x9_postcard_01_back.pdf',
  '6x9_postcard_01_front.pdf',
  '6x9_postcard_02_back.pdf',
  '6x9_postcard_02_front.pdf',
  '6x9_postcard_03_back.pdf',
  '6x9_postcard_03_front.pdf',
  '6x11_postcard_01_back.pdf',
  '6x11_postcard_01_front.pdf',
  '6x11_postcard_02_back.pdf',
  '6x11_postcard_02_front.pdf',
  '6x11_postcard_03_back.pdf',
  '6x11_postcard_03_front.pdf'
]

export const DEFAULT_SELF_MAILER_ARTWORK = [
  '11x9_self-mailer_inside.pdf',
  '6x18_self-mailer_01_inside.pdf',
  '6x18_self-mailer_01_outside.pdf',
  '6x18_self-mailer_02_inside.pdf',
  '6x18_self-mailer_02_outside.pdf',
  '6x18_self-mailer_03_inside.pdf',
  '6x18_self-mailer_03_outside.pdf',
  '11x9_self-mailer_02_inside.pdf',
  '11x9_self-mailer_02_outside.pdf',
  '11x9_self-mailer_outside.pdf',
  '12x9_self-mailer_01_inside.pdf',
  '12x9_self-mailer_01_outside.pdf',
  '12x9_self-mailer_02_inside.pdf',
  '12x9_self-mailer_02_outside.pdf'
]
export const SLA_CONSTS = {
  missCategories: [
    'N/A',
    'Customer',
    'Lob',
    'Mail Report Errors',
    'Operations',
    'Staffing/Facility Issues',
    'Technology',
    'USPS'
  ],
  qualityCategories: [
    'N/A',
    'Customer',
    'Lob',
    'Operations',
    'Pre-flight',
    'Staffing',
    'Technology'
  ],
  miss_operations: [
    'Machinery Maintenance/Downtime',
    'Capacity',
    'Paper shortage',
    'Envelope Issues',
    'Trucking/Freight',
    'Reprints',
    'Bindery'
  ],
  miss_technology: [
    'Download Speed',
    'Preflight',
    'Cancellation Request',
    'Transmission Time',
    'JSON Errors',
    'Emergency Server Maintenance',
    'Network Issues',
    'Unforeseen Programming Needs',
    'Custom Envelope Integration',
    'CRID Issues'
  ],
  miss_staffing_facility_issues: [
    'Pandemic',
    'Severe Weather',
    'Strike',
    'Errors in Staff Training',
    'Power Outage',
    'Building Damage'
  ],
  miss_lob: [
    'Cancellation Request',
    'Incorrectly Enqueued',
    'Over Capacity',
    'TOC',
    'CE Inventory',
    'Custom Envelope Not Available'
  ],
  miss_usps: ['USPS Delays - Partner Mailed on Time', 'CRID Issues'],
  miss_customer: ['Cancellation Request', 'Incorrect Postage Class'],
  miss_mail_report_errors: [
    'IMB Construction',
    'CRID errors',
    'STID errors',
    'MID errors',
    'Partner mis-entering information'
  ],
  quality_operations: [
    'Trimming',
    'Ink/Toner',
    'Paper Specs',
    'Damage/Smudges',
    'Coating',
    'Reprints',
    'Envelopes/Insertion'
  ],
  quality_pre_flight: [
    'Transparency',
    'File Size',
    'Font Embedding',
    'File Profiles',
    'Color'
  ],
  quality_technology: ['Corrupt File', 'Ingeration Errors', 'RIP Issue'],
  quality_staffing: ['Errors in Training', 'Strike'],
  quality_lob: ['HTML rendering error'],
  quality_customer: ['File Construction']
}

export const ACCOUNT_ACCESS_TYPE = {
  ACCOUNT_ID: 'account_id',
  SUBSCRIPTION_TIER: 'subscription_tier'
}

export const CUSTOM_MAIL_CONSTS = {
  PACKAGE_MODAL_CLONE_MODE: 'clone',
  PACKAGE_MODAL_CLONE_TITLE: 'Clone Package Information',
  PACKAGE_MODAL_CLONE_BUTTON: 'Save',
  PACKAGE_MODAL_CREATE_MODE: 'create',
  PACKAGE_MODAL_CREATE_TITLE: 'Create New Custom Mail Package',
  PACKAGE_MODAL_CREATE_BUTTON: 'Create',
  PACKAGE_MODAL_EDIT_MODE: 'edit',
  PACKAGE_MODAL_EDIT_TITLE: 'Edit Package Information',
  PACKAGE_MODAL_EDIT_BUTTON: 'Save',
  PACKAGE_PAGE_CREATE_TITLE: 'Manage Package',
  PACKAGE_PAGE_CLONE_TITLE: 'Clone Package',
  PACKAGE_PAGE_EDIT_TITLE: 'Manage Package',
  PACKAGE_PAGE_POPS_DELETE_BUTTON: 'Delete',
  PACKAGE_PAGE_PARTNER_EXPORT_BUTTON: 'Export',
  PACKAGE_PAGE_INFO_PANEL: {
    id: {
      displayName: 'Package ID',
      value: ''
    },
    account_id: {
      displayName: 'Customer Account ID',
      value: ''
    },
    customer: {
      displayName: 'Customer',
      value: ''
    },
    title: {
      displayName: 'Title',
      value: ''
    },
    mail_type: {
      displayName: 'Mail Type',
      value: ''
    },
    object_type: {
      displayName: 'Object Type',
      value: ''
    },
    description: {
      displayName: 'Description',
      value: ''
    },
    instructions: {
      displayName: 'Instructions',
      value: ''
    }
  },
  PACKAGE_PARTNER_APPROVED_STATUS: 'partner_approved',
  PACKAGE_CUSTOMER_APPROVED_STATUS: 'customer_approved',
  PACKAGE_MODAL_DEFAULT_VALUES: {
    account_id: '',
    title: '',
    description: '',
    instructions: ''
  },
  PACKAGE_MODAL_MAIL_TYPES_DISPLAY_VALUES: {
    usps_standard: 'USPS Standard',
    usps_first_class: 'USPS First Class'
  },
  PACKAGE_MODAL_MAIL_TYPE_VALUES: [
    {
      label: 'First Class',
      value: 'usps_first_class'
    },
    {
      label: 'Standard',
      value: 'usps_standard'
    }
  ],
  PACKAGE_MODAL_OBJECT_TYPE_VALUES: ffResourcesMap,
  PACKAGE_MODAL_ACCOUNT_ACCESS_VALUES: [
    {
      lable: 'Account ID',
      name: 'account-access',
      value: ACCOUNT_ACCESS_TYPE.ACCOUNT_ID
    },
    {
      lable: 'Account Subscription Tier',
      name: 'account-access',
      value: ACCOUNT_ACCESS_TYPE.SUBSCRIPTION_TIER
    }
  ],
  PACKAGE_MODAL_TIER_OPTIONS: [
    {
      lable: 'Developer',
      name: 'developer',
      value: SubscriptionTier.DEVELOPER
    },
    {
      lable: 'Startup',
      name: 'startup',
      value: SubscriptionTier.STARTUP
    },
    {
      lable: 'Growth',
      name: 'growth',
      value: SubscriptionTier.GROWTH
    },
    {
      lable: 'Mid-Market',
      name: 'mid-market',
      value: SubscriptionTier.MID_MARKET
    },
    {
      lable: 'Enterprise',
      name: 'enterprise',
      value: SubscriptionTier.ENTERPRISE
    }
  ],
  DELETE_MODAL_TYPE_PACKAGE: 'Package',
  DELETE_MODAL_TYPE_COMPONENT: 'Component',
  COMPONENT_MODAL_CREATE_MODE: 'create_component',
  COMPONENT_MODAL_CREATE_TITLE: 'Create New Component',
  COMPONENT_MODAL_CREATE_BUTTON: 'Create',
  COMPONENT_MODAL_EDIT_MODE: 'edit_component',
  COMPONENT_MODAL_EDIT_TITLE: 'Edit Component',
  COMPONENT_PAGE_EDIT_MODE: 'edit',
  COMPONENT_MODAL_EDIT_BUTTON: 'Save',
  COMPONENT_PAGE_CREATE_TITLE: 'Manage Component',
  COMPONENT_PAGE_EDIT_TITLE: 'Manage Component',
  COMPONENT_PAGE_VIEW_TITLE: 'View Component',
  COMPONENT_INVENTORY_TYPE_VALUES: [
    {
      label: 'Standard',
      value: 'standard'
    },
    // {
    //   // disabled until inventory is supported post MVP
    //   label: 'Inventory',
    //   value: 'inventory',
    //   disabled: true
    // },
    {
      label: 'Just In Time',
      value: 'just_in_time'
    }
  ],
  BID_MODAL_CREATE_MODE: 'create',
  // BID_MODAL_CREATE_POPS_MODE: 'createPops',
  BID_MODAL_CREATE_POPS_TITLE: 'Create New Partner Bid',
  BID_MODAL_CREATE_POPS_BUTTON: 'Create',
  BID_MODAL_CREATE_TITLE: 'Create Bid',
  BID_MODAL_EDIT_MODE: 'edit',
  BID_MODAL_EDIT_POPS_MODE: 'editPops',
  BID_MODAL_EDIT_POPS_TITLE: 'Edit Partner Bid',
  BID_MODAL_EDIT_TITLE: 'Edit Bid',
  BID_MODAL_EDIT_POPS_BUTTON: 'Save',
  BID_MODAL_EDIT_BUTTON: 'Submit',
  BID_PARTNER_STATUS_ENABLED: 'enabled',
  BID_MODAL_DEFAULT_POPS_VALUES: {
    partnerID: '',
    productID: '',
    orderQuantity: Number(null),
    totalUSD: Number(null),
    leadTime: Number(null)
    // customerID: '',
    // customerName: ''
  },
  BID_MODAL_DEFAULT_VALUES: {
    partnerID: '',
    productID: '',
    orderQuantity: Number(null),
    totalUSD: Number(null),
    leadTime: Number(null)
    // customerID: '',
    // customerName: ''
  },
  COMPONENT_PAGE_INFO_PANEL: {
    title: {
      displayName: 'Title',
      value: ''
    },
    description: {
      displayName: 'Description',
      value: ''
    },
    inventory_type: {
      displayName: 'Inventory Type',
      value: ''
    },
    instructions: {
      displayName: 'Instructions',
      value: ''
    },
    template_id: {
      displayName: 'Template ID',
      value: ''
    }
  },
  ATTRIBUTE_MODAL_CREATE_MODE: 'create_attribute',
  ATTRIBUTE_MODAL_CREATE_TITLE: 'Create New Attribute',
  ATTRIBUTE_MODAL_CREATE_BUTTON: 'Create',
  ATTRIBUTE_MODAL_EDIT_MODE: 'edit_attribute',
  ATTRIBUTE_MODAL_EDIT_TITLE: 'Edit Attribute',
  ATTRIBUTE_MODAL_EDIT_BUTTON: 'Save',
  ATTRIBUTE_MODAL_WARNING:
    'WARNING: This field is linked to a customer approved product!',
  ATTRIBUTE_DATA_TYPE_VALUES: [
    {
      label: 'String',
      value: 'string'
    },
    {
      label: 'Boolean',
      value: 'boolean'
    },
    {
      label: 'Number',
      value: 'number'
    }
  ],
  ATTRIBUTE_FIELD_TYPE_VALUES: [
    {
      label: 'Checkbox',
      value: 'checkbox'
    },
    {
      label: 'Text Box',
      value: 'textbox'
    }
  ],
  ATTRIBUTE_VALUE_FIELD_BOOLEAN_VALUES: [
    {
      label: 'True',
      value: 'true'
    },
    {
      label: 'False',
      value: 'false'
    }
  ],
  ATTRIBUTE_FIELD_INFO_PANEL: {
    name: {
      displayName: 'Name',
      value: ''
    },
    label: {
      displayName: 'Label',
      value: ''
    },
    instructional_text: {
      displayName: 'Instructions',
      value: ''
    },
    data_type: {
      displayName: 'Data Type',
      value: ''
    },
    field_type: {
      displayName: 'Field Type',
      value: ''
    },
    default_value: {
      displayName: 'Default Value',
      value: ''
    }
  },
  ERRORS: {
    ATTRIBUTE_WRONG_VALUE_TYPE: 'Default value must be a '
  },
  BID_INFO_PANEL: {
    partnerID: {
      displayName: 'Partner ID',
      value: ''
    },
    productID: {
      displayName: 'Product ID',
      value: ''
    },
    orderQuantity: {
      displayName: 'Order Quantity',
      value: ''
    },
    totalUSD: {
      displayName: 'Total USD',
      value: ''
    },
    leadTime: {
      displayName: 'Lead Time',
      value: ''
    }
  },
  PACKAGE_LIST_TOOLTIP: 'Info for Package List',
  PACKAGE_INFORMATION_TOOLTIP: 'Info for Package Information',
  COMPONENT_INFORMATION_TOOLTIP: 'Info for Component Information',
  MANAGE_BIDS_INFORMATION_TOOLTIP: 'Info for Manage Bids Information',
  ATTRIBUTES_INFORMATION_TOOLTIP: 'Info for Attributes Information',
  ATTRIBUTES_LIST_TOOLTIP: 'Info for Attributes List',
  BID_LIST_TOOLTIP: 'Info for Bids List',
  PACKAGE_MODAL_EDIT_TOOLTIP:
    'Changes to Title and Description will reflect in Campaigns. Mail Type should not be edited after the package is enabled for a customer unless to rectify an error.',
  COMPONENT_MODAL_EDIT_TOOLTIP:
    'Changes to Title and Description will reflect in Campaigns. Inventory Type and Template ID should not be edited after package is enabled for a customer.',
  ATTRIBUTE_MODAL_NEW_TOOLTIP:
    'Verify that the attribute does not already exist before creating a new one. Once created, the global attribute will be available for selection in any component in any package.',
  ATTRIBUTE_MODAL_EDIT_TOOLTIP:
    'Handle with extreme care. Editing an attribute could impact customer campaigns in production. Any packages that uses this attribute will be updated. Ensure that packages that are currently in production use will not be impacted by updates to the attribute.',
  NEW_BID_TOOLTIP:
    'Lowest quantity entered must meet CMB minimum unless an exception has been granted by the Partner Team.'
}

export const SORTED_STATUS = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
  NONE: 'none'
}

export const DROPDOWN_INDENT = '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'
