<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue'
import CardOrder from './CardOrder.vue'
import EnvelopeOrder from './EnvelopeOrder.vue'
import BuckslipOrder from './BuckslipOrder.vue'
import {
  CardOrder as CardOrderT,
  EnvelopeOrder as EnvelopeOrderT,
  BuckslipOrder as BuckslipOrderT,
  OrderType,
  ORDER_TYPE
} from './types'
import { useOrders } from './useOrder'

export default defineComponent({
  name: 'Orders',
  components: {
    CardOrder,
    EnvelopeOrder,
    BuckslipOrder
  },
  props: {
    id: { type: String, required: true },
    type: { type: String as PropType<OrderType>, required: true }
  },
  setup(props) {
    const { orders, loading, error, loadOrders } = useOrders<
      CardOrderT | EnvelopeOrderT | BuckslipOrderT
    >(props.id, props.type)

    onMounted(async () => {
      await loadOrders()

      if (props.type === ORDER_TYPE.BUCKSLIPS) {
        // buckslips don't have total_cost or final_price included from api
        orders.value = orders.value.map(
          (bo: BuckslipOrderT | EnvelopeOrderT | CardOrderT) => {
            bo.total_cost = bo.materiel_inventories.reduce(
              (acc, val) => acc + val.total_cost,
              0
            )
            return bo
          }
        ) as BuckslipOrderT[]
      }
    })

    return {
      orders,
      loading,
      error,
      CardOrder,
      EnvelopeOrder,
      BuckslipOrder,
      ORDER_TYPE
    }
  }
})
</script>

<template>
  <LoadingIndicator>
    <template v-if="!loading">
      <component
        :is="
          type === ORDER_TYPE.CARDS
            ? CardOrder
            : type === ORDER_TYPE.ENVELOPES
            ? EnvelopeOrder
            : BuckslipOrder
        "
        v-for="order in orders"
        :key="order.id"
        :order="order"
      />
      <alert v-if="orders.length === 0" class="text-sm my-1">
        No orders available
      </alert>
      <alert v-if="error" variant="error" class="w-full text-sm my-1">
        {{ error }}
      </alert>
    </template>
  </LoadingIndicator>
</template>
