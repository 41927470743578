<template>
  <form>
    <div class="row mt-4 contentSection">
      <div class="col-3">
        <p class="sectionTitle">Zipcode</p>
      </div>
      <div clas="col-9">
        <TextInput
          id="zipcode"
          v-model="computedZip"
          label="Enter Zipcode"
          placeholder="Enter Zipcode"
        />
        <Alert v-if="alertMessage" :variant="alertType">
          {{ alertMessage }}
        </Alert>
        <LobButton
          title="Save Data"
          class="mt-4 ml-3"
          data-testid="zipcodeSubmitBtn"
          :disabled="pending"
          @click.prevent="zipcodeSubmit"
        >
          Save Data
        </LobButton>
      </div>
    </div>
  </form>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import * as session from '@/store/session'
import { getCurrentPartner, getCurrentPartnerId } from '@/store/partners'
// import { AxiosError } from 'axios'

export default defineComponent({
  name: 'Zipcode',
  props: {
    partnerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      alertMessage: null as null | string,
      alertType: 'error',
      pending: false,
      zipcode: null as string | null
    }
  },
  computed: {
    computedZip: {
      get() {
        const partner = getCurrentPartner()
        return partner ? partner.zipcode : null
      },
      set(newZipcode) {
        this.zipcode = newZipcode
      }
    }
  },
  methods: {
    async zipcodeSubmit() {
      const regexp = /^[0-9]{5}(?:-[0-9]{4})?$/
      if (this.zipcode !== null && regexp.test(this.zipcode) !== true) {
        this.pending = false
        this.alertType = 'error'
        this.alertMessage = 'Must enter a valid zipcode'
        return
      }
      const partner = getCurrentPartnerId()
      if (partner == null) return
      this.pending = true
      this.alertMessage = null
      try {
        await session.updatePartner({
          partner,
          data: {
            zipcode: this.zipcode as string
          }
        })
        this.alertMessage = 'Save Successful'
        this.alertType = 'success'
        this.zipcode = ''
      } catch (e) {
        const eResp = e.response
        if (!eResp) {
          this.alertMessage = 'An unknown server error has occured'
        } else {
          this.alertMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
        }
      }
      this.pending = false
    }
  }
})
</script>
<style scoped lang="scss">
.pst {
  font-size: 16px;
  padding-top: 40px;
}
</style>
