<template>
  <div class="flex">
    <div class="text-2xl mb-10">Package Information</div>
    <Tooltip position="right">
      <template #trigger>
        <Info class="ml-1 w-5 h-5" />
      </template>
      <template #content>
        {{ packageInformationTooltip }}
      </template>
    </Tooltip>
    <div class="flex-1" />
    <Edit
      v-if="isAdminOrPopsUser"
      class="ml-1 opacity-40 w-6 h-6 cursor-pointer"
      @click="openEditPackageModal"
    />
  </div>
  <InfoPanel :data="infoData" />
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import InfoPanel from '../common/InfoPanel.vue'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import { PackagePageData } from '@/store/customMail'
import { PropType } from 'vue'

export default defineComponent({
  name: 'PackageInformation',
  components: {
    InfoPanel
  },
  props: {
    data: { type: Object as PropType<PackagePageData>, default: () => ({}) },
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isAdminOrPopsUser: { type: Boolean }
  },
  emits: ['openEditPackageModal'],
  data() {
    return {
      headerTitle: '',
      infoData: CUSTOM_MAIL_CONSTS.PACKAGE_PAGE_INFO_PANEL,
      packageInformationTooltip: CUSTOM_MAIL_CONSTS.PACKAGE_INFORMATION_TOOLTIP
    }
  },
  watch: {
    data: function (newVal) {
      if (!newVal) {
        return
      }
      for (const key in CUSTOM_MAIL_CONSTS.PACKAGE_PAGE_INFO_PANEL) {
        if (key === 'mail_type') {
          this.infoData[key].value =
            CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_MAIL_TYPES_DISPLAY_VALUES[
              newVal[key]
            ]
        } else {
          this.infoData[key].value = newVal[key] ?? 'No Value'
        }
      }
    }
  },
  mounted() {
    const infoData = this.data as any
    for (const key in this.infoData) {
      if (key === 'mail_type') {
        this.infoData[key].value =
          CUSTOM_MAIL_CONSTS.PACKAGE_MODAL_MAIL_TYPES_DISPLAY_VALUES[
            infoData[key]
          ]
      } else {
        this.infoData[key].value = infoData[key] ?? 'No Value'
      }
    }
  },
  methods: {
    openEditPackageModal() {
      this.$emit('openEditPackageModal')
    }
  }
})
</script>

<style lang="scss" scoped></style>
