<template>
  <div class="flex flex-column items-baseline justify-items-center p-2 w-100">
    <div class="grid grid-cols-7 w-100">
      <div
        v-for="key in tableHeaders"
        :key="key.name"
        class="items-baseline text-sm pl-3 pb-3"
      >
        {{ key.name }}
      </div>
      <Edit
        class="h-5 mt-1 mr-2 flex justify-self-end col-span-4 self-baseline text-gray-100"
        @click="openPartnerEditModal"
      />
    </div>
    <div v-if="bidsByPartner" class="w-100 bg-mist">
      <div v-for="bid in bidsByPartner.bidsArray" :key="bid.partner_id">
        <PartnerBidsTableRow
          class="items-baseline grid grid-cols-7"
          :bid="bid"
          :all-bidding-partners="allBiddingPartners"
          :is-admin-or-pops-user="isAdminOrPopsUser"
          @openPartnerEditModal="openPartnerEditModal"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { PartnerPackageBids } from '@/store/customMail'
import PartnerBidsTableRow from './PartnerBidsTableRow.vue'

export default defineComponent({
  name: 'PartnerBidsTable',
  components: {
    PartnerBidsTableRow
  },
  props: {
    partner: {
      type: Object,
      required: true
    },
    isAdminOrPopsUser: {
      type: Boolean,
      required: true
    },
    bidsByPartner: {
      type: Object as PropType<PartnerPackageBids>,
      default: null
    },
    allBiddingPartners: {
      type: Array,
      required: true
    }
  },
  emits: ['openPartnerEditModal'],
  data() {
    return {
      currentPartner: ''
    }
  },
  computed: {
    tableHeaders(): { name: string; span: number }[] {
      return this.isAdminOrPopsUser
        ? [
            { name: 'Partner ID', span: 1 },
            { name: 'Status', span: 1 },
            { name: 'Date', span: 1 }
          ]
        : [
            { name: 'Quantity', span: 1 },
            { name: 'Total Price (USD)', span: 1 },
            { name: 'Lead Time (Days)', span: 1 }
          ]
    }
  },
  methods: {
    openPartnerEditModal() {
      this.$emit('openPartnerEditModal', this.partner)
    }
  }
})
</script>

<style scoped lang="css">
.row-space {
  border-collapse: separate;
  border-spacing: 0 15px;
}
</style>
