import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "test-cases-table w-100 border rounded-sm border-gray-100" }
const _hoisted_2 = { class: "table-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(['Test case', 'Date', 'Qty.', 'Description'], (key) => {
          return (_openBlock(), _createElementBlock("th", {
            key: key,
            class: "border-t-0 uppercase text-xs font-light text-gray-300 bg-white"
          }, _toDisplayString(key), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.testCases, (testCase) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: testCase.test_id,
          class: "text-sm font-light bg-white-100 even:bg-white py-4 px-3"
        }, [
          _createElementVNode("td", null, _toDisplayString(testCase.case_id), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(testCase.date_created)), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.formatNumber(testCase.count)), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.descriptionMap[testCase.case_id] ?? ''), 1)
        ]))
      }), 128))
    ])
  ]))
}