import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import jwtEncode from 'jwt-encode'

import { sendPapiRequest, sendRequest } from '../requests'
import { defineVuexModule } from '../util'
import { registerStartupListener } from '../watcherRegistry'
import { fetchCurrentPartner, setCurrentPartnerId } from '../partners'
import { $segment } from '@/plugins/segment'
import { AxiosResponse } from 'axios'

const TOKEN_COOKIE_NAME = 'partner_test_token'
const ADMIN_ROLES = ['lob-admin', 'pops']

export interface PartnerMasquerade {
  masquerade_partner_id: string
  masquerade_login_time: string
  masquerade_user_id: string
}

export type UserRole = 'lob-admin' | 'partner' | 'pops'

export interface User {
  id: string
  partner: string
  role: UserRole
  email?: string
  masquerade_info: PartnerMasquerade
  account_id?: string
}

interface State {
  token: string | null
  user: User | null
  masqueradedUser: User | null
  updatingUser: boolean
}

const initialState: State = {
  token: null,
  user: null,
  masqueradedUser: null,
  updatingUser: false
}

const storeModule = defineVuexModule('session', initialState)

export interface ApiToken {
  id: string
  mode: 'test'
  token: string
  iat: number
}

export interface LoginData {
  email: string
  password: string
}

interface LobApiUser {
  id: string
  account: {
    id: string
  }
  role: string
}

// GETTERS (exported functions that provide pieces of data from the state)

// Returns the current session token, or null if it does not exist.
export const getToken = storeModule.read((state) => state.token, 'getToken')

// Returns the current session token, decoded from JWT format, or null if it
// does not exist.
export const getTokenData = storeModule.read((state) => {
  if (state.token === null) {
    return null
  } else {
    return jwtDecode<ApiToken>(state.token)
  }
}, 'getTokenData')

export const isUserUpdating = storeModule.read(
  (state) => state.updatingUser,
  'isUserUpdating'
)

// eslint-disable-next-line
export const waitForUserToUpdate = async (maxWaitMilliseconds = 5_000) => {
  const sleepFor = async (delay: number) => {
    return new Promise(function (resolve) {
      setTimeout(() => resolve(delay), delay)
    })
  }

  const start = Date.now()
  while (Date.now() - start < maxWaitMilliseconds) {
    if (!isUserUpdating()) break
    await sleepFor(100)
  }
}

export const getPartnerFromCookie = async (): Promise<string | null> => {
  // eslint-disable-line
  if (!Cookies.get(TOKEN_COOKIE_NAME)) {
    return null
  }

  const token = jwtDecode<ApiToken>(Cookies.get(TOKEN_COOKIE_NAME) as string)
  const path =
    process.env.NODE_ENV === 'development'
      ? `/users/${token.id}`
      : `/users/${token.id}/`
  const result = await sendPapiRequest<User>({
    method: 'GET',
    path
  })
  const user = result.data
  if (user.masquerade_info.masquerade_partner_id.length !== 0) {
    return user.masquerade_info.masquerade_partner_id
  }
  return user.partner
}

// This will always be true if we have some kind of session token, including
// if the page was just loaded and we are still waiting on a response from the
// server to get the user data the token is attached to.
export const isLoggedIn = storeModule.read(
  (state) => state.token !== null,
  'isLoggedIn'
)

// Returns the user currently signed in, or null if the user is not signed in.
export const getUser = storeModule.read((state) => state.user, 'getUser')

// Returns true if the user is currently logged in with an administrator account
// and not masquerading as a partner
export const isAdminUser = storeModule.read(
  (state) => state.user?.role === 'lob-admin' && !isUserMasquerading(),
  'isAdminUser'
)

// Returns true if the user is currently logged in with an administrator account
// and not masquerading as a partner
export const isAdminOrPopsUser = storeModule.read(
  (state) =>
    // eslint-disable-next-line
    (state.user?.role === 'lob-admin' && !isUserMasquerading()) ||
    // eslint-disable-next-line
    (state.user?.role === 'pops' && !isUserMasquerading()),
  'isAdminOrPopsUser'
)

// Returns the user currently signed in, or null if the user is not signed in.
export const getMasqueradedUser = storeModule.read(
  (state) => state.masqueradedUser,
  'getMasqueradedUser'
)

// Checks if the user is currently masquerading as a partner
export const isUserMasquerading = storeModule.read(
  (state) =>
    state.user?.masquerade_info &&
    state.user?.masquerade_info.masquerade_user_id.length !== 0,
  'getMasqueradeConfirmation'
)

// MUTATORS (synchronous functions that change the state, each call to a mutator
// is logged in the Vue development tools)

const setToken = storeModule.commit((state, value: string) => {
  state.token = value
  Cookies.set(TOKEN_COOKIE_NAME, value)
}, 'setToken')

const setUser = storeModule.commit((state, value: User) => {
  const user = value
  if (user.masquerade_info.masquerade_partner_id.length !== 0) {
    user.partner = user.masquerade_info.masquerade_partner_id
  }
  state.user = user

  $segment.alias(user?.id)
  $segment.identify(user?.id, {
    partner: user?.partner,
    is_masquerade: user.masquerade_info.masquerade_partner_id.length !== 0
  })
}, 'setUser')

export const setUserMasquerade = storeModule.commit(
  async (state, value: User | null) => {
    const partner = value?.partner as any
    await sendPapiRequest({
      method: 'POST',
      path: '/users/masquerade',
      data: {
        user_id: state.user?.id,
        masquerade_id: partner.id
      }
    })
    updateUser()
    setCurrentPartnerId(partner.id)
  },
  'setUserMasquerade'
)

export const logoutMasquerade = storeModule.commit(async (state) => {
  await sendPapiRequest({
    method: 'POST',
    path: '/users/masquerade',
    data: {
      user_id: state.user?.id,
      masquerade_id: 'logout'
    },
    admin: true
  })
  const partner = state.user?.id as string
  updateUser()
  setCurrentPartnerId(partner)
}, 'logoutMasquerade')

export const clearSession = storeModule.commit((state) => {
  state.token = null
  state.user = null
  state.masqueradedUser = null
  Cookies.remove(TOKEN_COOKIE_NAME)
}, 'clearSession')

// Tries to resume a session stored in a cookie. Succeeds if the cookie
// exists. TODO: discard the session if it is expired.
const tryRestoringSessionFromCookie = storeModule.commit((state) => {
  state.updatingUser = true
  const token = Cookies.get(TOKEN_COOKIE_NAME)
  if (token !== undefined) {
    state.token = token
    updateUser()
  } else {
    // updateUser will toggle state.updatingUser for us,
    // so keep this inside the else as we don't want to do it
    // unless updateUser is not getting called
    state.updatingUser = false
  }
}, 'tryRestoringSessionFromCookie')

// ACTIONS (asynchronous functions which can call other actions as well as
// getters and setters, also logged in the Vue development tools)

export const updateUser = storeModule.dispatch(async (bareAction) => {
  bareAction.state.updatingUser = true
  const userId = getTokenData()?.id
  if (userId === null) return
  const path =
    process.env.NODE_ENV === 'development'
      ? `/users/${userId}`
      : `/users/${userId}/`
  const partnersApiUserData = await sendPapiRequest<User>({
    method: 'GET',
    path
  })

  // fetch the account id from lob-api if user is an admin
  let lobApiUserData: AxiosResponse<LobApiUser> | undefined
  if (ADMIN_ROLES.includes(partnersApiUserData.data.role)) {
    lobApiUserData = await sendRequest<LobApiUser>({
      method: 'GET',
      path,
      authenticate: true
    })
  }
  const user = {
    ...partnersApiUserData.data,
    account_id: lobApiUserData?.data.account.id
  }
  setUser(user)
  if (!isUserMasquerading()) {
    setCurrentPartnerId(partnersApiUserData.data.partner)
  } else {
    setCurrentPartnerId(
      partnersApiUserData.data.masquerade_info.masquerade_partner_id
    )
  }
  bareAction.state.updatingUser = false
}, 'updateUser')

interface LoginResponse {
  tokens: {
    test: string
  }
}

// Promise is resolved when the login finishes and the user role is updated.
export const login = storeModule.dispatch(async (_, data: LoginData) => {
  if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
    const result = await sendRequest<LoginResponse>({
      method: 'POST',
      path: '/sessions',
      data
    })
    setToken(result.data.tokens.test)
  } else {
    // used for development. data.email should equal the id of user instead of
    // an email since we use it to bypass lob-api's session
    // example users - active_admin, active_partner
    const loginData: ApiToken = {
      id: data.email,
      mode: 'test',
      token: '',
      iat: 0
    }
    const devToken = jwtEncode(loginData, '')
    setToken(devToken)
  }
  await updateUser()
  $segment.track('Partner Dashboard Logged In')
}, 'login')

export const logout = storeModule.dispatch(async () => {
  try {
    await sendRequest<LoginResponse>({
      method: 'DELETE',
      path: '/sessions',
      authenticate: true,
      admin: true
    })
  } catch (e) {
    // We still need to delete the session cookie.
  }
  clearSession()
  $segment.track('Partner Dashboard Logged Out')
}, 'logout')

// Sends a password reset link to the given email.
export const sendResetPasswordLink = storeModule.dispatch(
  async (
    _,
    params: {
      email: string
    }
  ) => {
    const response = await sendRequest({
      method: 'POST',
      path: '/reset_password/',
      data: { email: params.email }
    })
    return response.data
  },
  'sendResetPasswordLink'
)

// Resets a user's password
export const resetPassword = storeModule.dispatch(
  async (
    _,
    params: {
      token: string
      password: string
    }
  ) => {
    const response = await sendRequest({
      method: 'POST',
      path: `/reset_password/${params.token}`,
      data: { password: params.password }
    })
    return response.data
  },
  'resetPassword'
)

// Gets a Partner
export const getPartner = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string | undefined
    }
  ) => {
    const response = await sendPapiRequest({
      method: 'GET',
      path: `/partners/${params.partner}`
    })
    return response.data
  },
  'getPartner'
)

// Hits Update a Partner api endpoint
export const updatePartner = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string
      data: {
        crid?: string
        // eslint-disable-next-line camelcase
        local_cutoff?: number
        timezone?: string
        zipcode?: string
      }
    }
  ) => {
    const response = await sendPapiRequest({
      method: 'POST',
      path: `/partners/${params.partner}`,
      data: params.data
    })
    await fetchCurrentPartner({ partnerId: params.partner })
    return response.data
  },
  'updatePartner'
)

// Hits Partner Holidays endpoint
export const updatePartnerHolidays = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string
      data: {
        holidays: string[]
      }
    }
  ) => {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    const promiseResponse = params.data.holidays.map(async (holiday) => {
      const response = await sendPapiRequest({
        method: 'POST',
        path: `/partners/${params.partner}/holidays`,
        data: {
          name: holiday.toUpperCase()
        }
      })

      return response
    })
    return await Promise.all(promiseResponse)
  },
  'updatePartnerHolidays'
)

export const deletePartnerHolidays = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string | undefined
      data: {
        holidays: string[]
      }
    }
  ) => {
    // create array of promises
    const promiseResponse = params.data.holidays.map(async (holiday) => {
      const response = await sendPapiRequest({
        method: 'DELETE',
        path: `/partners/${params.partner}/holidays/${holiday}`
      })
      return response
    })
    // wait for promises to resolve before returning
    return await Promise.all(promiseResponse)
  },
  'deletePartnerHolidays'
)

export const addZeroProdDay = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string | undefined
      data: {
        date: string | undefined
        description: string | undefined
      }
    }
  ) => {
    const response = await sendPapiRequest({
      method: 'POST',
      path: `/partners/${params.partner}/zero_production_days`,
      data: params.data
    })
    return response
  },
  'addZeroProdDay'
)

export const deleteZeroProdDay = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string | undefined
      id: string | undefined
    }
  ) => {
    const response = await sendPapiRequest({
      method: 'DELETE',
      path: `/partners/${params.partner}/zero_production_days/${params.id}`
    })
    return response
  },
  'deleteZeroProdDay'
)

export const fetchGitbookToken = storeModule.dispatch(async () => {
  const currentUser = getUser()
  const resp = await sendPapiRequest({
    method: 'GET',
    path: `/users/${currentUser?.id}/gitbook_token`
  })
  return resp.data
}, 'fetchGitbookToken')

// WATCHERS (functions that are called when a value in the store changes,
// possibly one from a different module.)

registerStartupListener(() => {
  tryRestoringSessionFromCookie()
})
