<template>
  <div class="flex items-center justify-between border px-8 py-6">
    <Dropdown
      id="partners"
      :model-value="(partnerOptions || []).find((r) => r.id === partner)"
      :options="partnerOptions"
      label="partner"
      sr-only-label
      size="small"
      class="w-2/5"
      placeholder="Select Print Partner"
      data-testid="dd-partner"
      @update:modelValue="onPartnerChange"
    />
    <TextInput
      id="search"
      v-model="searchTerm"
      size="small"
      placeholder="Enter search term here"
      class="w-2/5 px-4"
      data-testid="search-input"
    >
      <template #iconRight>
        <search class="w-6 h-6" />
      </template>
    </TextInput>
    <LobButton
      variant="primary"
      size="small"
      class="w-1/5"
      data-testid="search-button"
      @click="onSearch"
    >
      Search
    </LobButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, Prop } from 'vue'
import { UserFilter } from './useUsers'
import { DropdownItem } from '@/consts'

export default defineComponent({
  props: {
    partnerOptions: {
      type: Array,
      required: true
    } as Prop<DropdownItem[]>
  },
  emits: ['search'],
  data: function (): UserFilter {
    return {
      partner: null,
      searchTerm: ''
    }
  },
  methods: {
    onPartnerChange(item: DropdownItem) {
      this.partner = item.id
    },
    onSearch() {
      this.$emit('search', {
        partner: this.partner,
        searchTerm: this.searchTerm.toLowerCase()
      })
    }
  }
})
</script>
