import { FieldType, SchemaFormFactor } from '@/store/testSets/schema'

export interface FieldInfo {
  id: string
  name: string
  type: FieldType
}

function capitalizeWord(word: string): string {
  return word.substr(0, 1).toUpperCase() + word.substr(1)
}

export function snakeCaseToHumanReadable(phrase: string): string {
  return phrase.split('_').map(capitalizeWord).join(' ')
}

function getFieldInfo(id: string, type: FieldType): FieldInfo {
  return {
    id,
    name: snakeCaseToHumanReadable(id),
    type
  }
}

export function getFieldInfos(ff: SchemaFormFactor): Array<FieldInfo> {
  const result = [] as Array<FieldInfo>
  for (const fieldName in ff.resource_fields) {
    const type = ff.resource_fields[fieldName]
    const info = getFieldInfo(fieldName, type)
    result.push(info)
  }
  return result
}
