import { RouteRecordRaw } from 'vue-router'
import View from './View.vue'

export default {
  path: ':partnerFacilityId/products_list',
  component: View,
  props: true,
  meta: {
    displayName: 'Facility Capabilities'
  }
} as RouteRecordRaw
