<script lang="ts">
import { PartnerStatusType, PARTNER_STATUS } from '@/consts'
import { getPartnersByStatus } from '@/store/partners'
import { computed, defineComponent, PropType } from 'vue'
import { MaterielInventory } from '../../pages/main/partnerOps/inventory/account-overview/types'

const ALL = {
  label: 'All',
  value: ''
}

export default defineComponent({
  name: 'PartnerDropdown',
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'small'
    },
    partnerGroup: {
      type: String as PropType<PartnerStatusType>,
      default: PARTNER_STATUS.ALL
    },
    modelValue: {
      type: Object as PropType<{
        label: string
        value: string
      }>,
      required: false,
      default: null
    },
    disableAll: {
      type: Boolean,
      required: false,
      default: false
    },
    srOnlyLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    initialEmit: {
      type: Boolean,
      required: false,
      default: true
    },
    selectedPartners: {
      type: Array as PropType<Partial<MaterielInventory>[]>,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const partners = computed(() => {
      let partnerList = getPartnersByStatus(props.partnerGroup)().map(
        (partner) => ({
          label: partner.display_name,
          value: partner.id
        })
      )
      if (partnerList.length > 0) {
        if (!props.disableAll) {
          partnerList = [ALL, ...partnerList]
        }
        if (props.initialEmit) {
          emit('update:modelValue', partnerList[0])
        }
        if (props.selectedPartners?.length) {
          partnerList = partnerList.filter(
            (p) =>
              !props.selectedPartners.find((sp) => sp.partner_id === p.value)
          )
        }
        return partnerList
      } else {
        return []
      }
    })
    return {
      partners
    }
  }
})
</script>

<template>
  <Dropdown
    v-if="partners.length && modelValue"
    :id="id"
    :label="label"
    :options="partners"
    :placeholder="placeholder"
    :required="false"
    :size="size"
    :model-value="modelValue"
    :sr-only-label="srOnlyLabel"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>
