import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2337219b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-factor-field-editor-root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxMVE = _resolveComponent("CheckboxMVE")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CheckboxMVE, {
      schema: _ctx.schema,
      field: _ctx.explicitlyEnumeratedField,
      "test-set": _ctx.testSet,
      "onUpdate:testSet": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:testSet', $event)))
    }, null, 8, ["schema", "field", "test-set"])
  ]))
}