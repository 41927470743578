<template>
  <div>
    <div class="flex align-items-end gap-3">
      <h2 class="font-medium text-2xl m-0">{{ accountName }} {{ subject }}</h2>
      <div class="rounded-full px-2 mb-1 bg-primary-300 text-white text-sm">
        {{ route.params.crid }}
      </div>
      <button
        class="w-4 h-4 rounded appearance-none focus-within:outline-none focus-within:text-primary-500 transition duration-100 ease-in"
      >
        <Copy :variant="copyCurrentCRID()" />
      </button>
      <div class="flex-1" />
      <LobButton
        size="small"
        variant="secondary"
        class="w-48 h-12"
        @click="moveToSpoilagePage"
      >
        Manage Spoilage
      </LobButton>
    </div>
    <div class="flex justify-between my-4">
      <Subnavigation ul-class="font-light text-lg">
        <SubnavigationItem
          title="Envelopes"
          :to="`/partner_ops/accounts/${route.params.crid}/envelopes`"
        />
        <SubnavigationItem
          title="Cards"
          :to="`/partner_ops/accounts/${route.params.crid}/cards`"
        />
        <SubnavigationItem
          title="Buckslips"
          :to="`/partner_ops/accounts/${route.params.crid}/buckslips`"
        />
      </Subnavigation>
      <RouterLink
        :to="`/partner_ops/accounts/${route.params.crid}/${route.params.resource}/changelogs`"
      >
        <LobButton
          :variant="
            route.path?.endsWith('changelogs') ? 'secondary' : 'primary'
          "
          size="small"
        >
          Inventory Change Log
          <Book class="w-4 h-4 ml-2" />
        </LobButton>
      </RouterLink>
    </div>

    <Alert v-if="accountErrorMessage?.length" variant="error">
      {{ accountErrorMessage }}
    </Alert>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, watch } from 'vue'
import { useRoute } from 'vue-router'

import {
  fetchAccountName,
  getAccountNamesById,
  getAccountsError
} from '@/store/accounts'
import router from '@/router'

export default defineComponent({
  name: 'InventoryHeader',
  props: {
    subject: {
      type: String,
      required: true
    }
  },
  setup() {
    const route = useRoute()
    const accountName = computed(
      () => getAccountNamesById()[route.params?.crid as string]
    )
    const accountErrorMessage = computed(getAccountsError)

    watch(route, () => fetchAccountName(route.params.crid as string), {
      immediate: true
    })

    return {
      accountErrorMessage,
      accountName,
      route
    }
  },
  data() {
    return {
      currentCRID: ''
    }
  },
  methods: {
    copyCurrentCRID() {
      this.currentCRID = this.route?.params?.crid as string
      navigator.clipboard?.writeText(this.currentCRID)
    },
    moveToSpoilagePage() {
      router.push(
        `/partner_ops/accounts/inventory/${this.route?.params?.crid}/spoilage`
      )
    }
  }
})
</script>
