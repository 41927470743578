<template>
  <div v-if="uploadSuccessful || uploadFailureMessage" class="mb-3">
    <Alert v-if="uploadSuccessful" variant="success">
      File uploaded successfully
    </Alert>
    <Alert v-if="uploadFailureMessage" variant="error">
      {{ uploadFailureMessage }}
    </Alert>
  </div>
  <FilePicker
    :disabled="uploadPending"
    disabled-message="Uploading..."
    @filesPicked="onFilesPicked($event)"
  />
</template>

<script lang="ts">
import { PropType } from 'vue'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'LobFileUpload',
  props: {
    doTryUpload: {
      type: Function as PropType<(file: File) => void>,
      required: true
    },
    maxSize: {
      type: Number,
      default: null
    }
  },
  emits: ['uploadSuccess'],
  data() {
    return {
      uploadPending: false,
      uploadSuccessful: false,
      uploadFailureMessage: null as string | null
    }
  },
  methods: {
    async onFilesPicked(files: Array<File>) {
      this.uploadSuccessful = false
      this.uploadFailureMessage = null

      if (this.isFileSizeValid(files)) {
        const maxSizeInMb = this.maxSize / Math.pow(10, 6)
        this.uploadFailureMessage = `File exceeds maximum size of: ${maxSizeInMb} MB`
        return
      }

      this.uploadPending = true
      await this.tryUploadFiles(files)
      this.uploadPending = false
    },
    isFileSizeValid(files: Array<File>) {
      return this.maxSize > 0 && files.some((file) => file.size > this.maxSize)
    },
    async tryUploadFiles(files: Array<File>) {
      if (files.length === 0) {
        this.uploadFailureMessage = 'No file specified'
        return
      }
      try {
        for (const file of files) {
          const result = await this.doTryUpload(file)
          this.$emit('uploadSuccess', result)
        }
        this.uploadSuccessful = true
        setTimeout(() => {
          this.uploadSuccessful = false
        }, 2500)
      } catch (e) {
        const eResp = e.response
        this.uploadFailureMessage = `${eResp?.status} → ${eResp?.data?.error?.message}`
      }
    }
  }
})
</script>
