import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withKeys as _withKeys, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white-100 rounded py-1 px-2 flex items-center mb-1" }
const _hoisted_2 = { class: "uppercase text-gray-500 text-xs w-24 flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_lob_button = _resolveComponent("lob-button")!
  const _component_Save = _resolveComponent("Save")!
  const _component_Close = _resolveComponent("Close")!
  const _component_InventoryUpdateModal = _resolveComponent("InventoryUpdateModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
      (!_ctx.inEditing)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(`text-xs font-bold text-left text-gray-700 w-full ${
        !_ctx.canEdit ? 'mr-3' : ''
      }`)
          }, [
            _renderSlot(_ctx.$slots, "default"),
            _createTextVNode(" " + _toDisplayString(_ctx.editValue), 1)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.inEditing)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(`flex text-xs font-bold text-left text-gray-700 w-full ${
        !_ctx.canEdit ? 'mr-3' : ''
      }`)
          }, [
            (_ctx.editStatus)
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: 0,
                  id: "status",
                  modelValue: _ctx.editValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editValue) = $event)),
                  class: "w-40",
                  label: "",
                  options: _ctx.statusOptions,
                  size: "small"
                }, null, 8, ["modelValue", "options"]))
              : (_openBlock(), _createBlock(_component_TextInput, {
                  key: 1,
                  id: _ctx.editMaterielInventoryId,
                  modelValue: _ctx.editValue,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editValue) = $event)),
                  modelModifiers: { number: true },
                  size: "small",
                  class: "w-16 !p-0",
                  "input-class": "h-4 !p-0 !m-0 text-xs no-p text-center",
                  type: "number",
                  label: "edit",
                  "sr-only-label": "",
                  onKeyup: _withKeys(_ctx.edit, ["enter"])
                }, null, 8, ["id", "modelValue", "onKeyup"]))
          ], 2))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_lob_button, {
        variant: "none",
        size: "small",
        class: _normalizeClass(`p-1 border-0`),
        "data-testid": "edit-button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.inEditing = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Edit, { class: "w-4 text-gray-100" })
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.canEdit && !_ctx.inEditing]
      ]),
      _withDirectives(_createVNode(_component_lob_button, {
        variant: "none",
        size: "small",
        class: _normalizeClass(`p-1 border-0 mr-1`),
        "data-testid": "save-button",
        onClick: _ctx.edit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Save, { class: "w-4 text-gray-100" })
        ]),
        _: 1
      }, 8, ["onClick"]), [
        [_vShow, _ctx.canEdit && _ctx.inEditing]
      ]),
      _withDirectives(_createVNode(_component_lob_button, {
        variant: "none",
        size: "small",
        class: _normalizeClass(`p-1 border-0`),
        onClick: _ctx.onReset
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Close, { class: "w-4 text-gray-100" })
        ]),
        _: 1
      }, 8, ["onClick"]), [
        [_vShow, _ctx.canEdit && _ctx.inEditing]
      ])
    ]),
    (_ctx.canEdit && _ctx.inventoryUpdateModalVisible)
      ? (_openBlock(), _createBlock(_component_InventoryUpdateModal, {
          key: 0,
          visible: _ctx.inventoryUpdateModalVisible,
          "update-materiel-inventory-id": _ctx.editMaterielInventoryId,
          "update-message": `Updating ${_ctx.label} from ${_ctx.value} to ${_ctx.editValue}`,
          "update-property": _ctx.editProperty,
          "update-value": _ctx.editValue,
          onClose: _ctx.onClose,
          onReset: _ctx.onReset,
          onUpdated: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updated', $event)))
        }, null, 8, ["visible", "update-materiel-inventory-id", "update-message", "update-property", "update-value", "onClose", "onReset"]))
      : _createCommentVNode("", true)
  ], 64))
}