import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_P = _resolveComponent("P")!
  const _component_SingleValueEditor = _resolveComponent("SingleValueEditor")!
  const _component_FieldSetRow = _resolveComponent("FieldSetRow")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formFactorFields, (field) => {
    return (_openBlock(), _createElementBlock("div", {
      key: field.id
    }, [
      _createVNode(_component_P, { class: "font-bold" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(field.name), 1)
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_FieldSetRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_SingleValueEditor, {
            schema: _ctx.schema,
            type: field.type,
            "model-value": _ctx.getField(field.id),
            "form-factor": _ctx.modelValue.form_factor,
            "onUpdate:modelValue": ($event: any) => (_ctx.setField(field.id, $event))
          }, null, 8, ["schema", "type", "model-value", "form-factor", "onUpdate:modelValue"])
        ]),
        _: 2
      }, 1024)
    ]))
  }), 128))
}