<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="test-corpus-root">
    <H2>Test Sets</H2>
    <br />
    <LobButton @click="duplicateTestSet(undefined)">Create Test Set</LobButton>
    <br />
    <div v-for="testSet in testSets" :key="testSet.id" class="testSetCard">
      <Card>
        <H3>{{ testSet.name }}</H3>
        <P>{{ testSet.description }}</P>
        <Alert :variant="statusVariant(testSet)">
          <div class="truncate">
            {{ testSet.status_message }}
          </div>
          <button
            v-if="testSet.ready"
            class="helpModalButton"
            @click="isModalVisible = true"
          >
            <img src="@/assets/images/icon-help.svg" class="help-svg ml-1" />
          </button>
          <Modal
            class="helpModal"
            width="500px"
            :visible="isModalVisible"
            @close="isModalVisible = false"
          >
            <template #header>
              <h4>Not getting 100% permutated objects?</h4>
            </template>
            <div class="p-0 m-0">
              If the test set displays less than 100% permutated objects ready,
              you likely have missed adding a creative resource for at least one
              form factor permutation (e.g. postcards 4x6 is missing a creative
              resource). You can edit this existing test set and add the
              relevant creative resources to produce 100% of the permutated
              objects.
            </div>
          </Modal>
        </Alert>
        <br />
        <Modal
          :visible="deleteModalVisible"
          header="Delete Test Set"
          close-button-aria-label="Close Modal"
          @close="deleteModalVisible = false"
        >
          Are you sure you would like to delete the test set
          {{ testSetToDelete.name }}
          <template #footer>
            <div class="flex self-end">
              <LobButton
                variant="secondary"
                @click="deleteModalVisible = false"
              >
                Go back
              </LobButton>
              <LobButton
                variant="primary"
                class="ml-2"
                @click="deleteTestSet(testSetToDelete)"
              >
                Submit
              </LobButton>
            </div>
          </template>
        </Modal>
        <div class="flex justify-start mt-2 w-full">
          <LobButton
            size="small"
            data-testid="duplicate-button"
            @click="duplicateTestSet(testSet.id)"
          >
            Duplicate
          </LobButton>
          <LobButton
            size="small"
            data-testid="edit-button"
            class="ml-2"
            @click="editTestSet(testSet.id)"
          >
            Edit
          </LobButton>
          <LobButton
            size="small"
            class="ml-2"
            @click="regenerateResources(testSet)"
          >
            Regenerate Resources
          </LobButton>
          <LobButton
            v-if="testSet.csv_key"
            size="small"
            class="ml-2"
            @click="downloadCSV(testSet)"
          >
            Download CSV
          </LobButton>
          <LobButton
            variant="error"
            size="small"
            class="ml-auto"
            @click="openDeleteModal(testSet)"
          >
            Delete
          </LobButton>
        </div>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import router from '@/router'
import {
  getTestSets,
  newTestSet,
  setBaseTestSet,
  TestSet,
  regenerateResource,
  downloadPermutationCSV,
  deleteTestSetID
} from '@/store/testSets'
import { downloadString } from '@/utils/browser'
import { defineComponent } from '@vue/runtime-core'
import { getShowBetaFeatureFlag } from '@/store/launchDarkly'
import { ERRORS } from '@/consts'

function makeBaseTestSet(isEditing) {
  const base = newTestSet()
  base.fields.from = [
    {
      address_city: 'San Fransisco',
      address_country: 'US',
      address_line1: '5678 Nowhere St',
      address_line2: '',
      address_state: 'CA',
      address_zip: '12345-6789',
      name: 'Luna Lobster'
    }
  ]
  base.fields.to = [
    {
      address_city: 'San Fransisco',
      address_country: 'US',
      address_line1: '1234 Nowhere St',
      address_line2: '',
      address_state: 'CA',
      address_zip: '12345-6789',
      name: 'Larry Lobster'
    }
  ]
  base.fields.fold = [null]
  base.fields.bleed = [null]
  base.fields.mail_date = [null]
  base.fields.target_delivery_date = [null]
  base.fields.return_envelope = [null]
  base.fields.perforated_page = [null]
  base.fields.custom_envelope = [null]
  base.fields.extra_service = [null]
  base.fields.cards = [[]]
  base.isEditing = isEditing
  return base
}

export default defineComponent({
  name: 'TestCorpusManagement',
  data() {
    return {
      showBetaFeatureFlag: getShowBetaFeatureFlag(),
      isModalVisible: false,
      deleteModalVisible: false,
      isError: false,
      errorMessage: null as any,
      testSetToDelete: {}
    }
  },
  computed: {
    testSets() {
      const sortedTestSets = getTestSets().sort((a, b) => {
        const fa = a.id.toLowerCase()
        const fb = b.id.toLowerCase()
        if (fa > fb) {
          return -1
        }
        if (fa < fb) {
          return 1
        }
        return 0
      })
      return sortedTestSets
    }
  },
  methods: {
    duplicateTestSet(id) {
      const baseTestSet = this.testSets.find((s) => s.id === id)
      if (baseTestSet === undefined) {
        setBaseTestSet(makeBaseTestSet(false))
      } else {
        baseTestSet!.isEditing = false
        setBaseTestSet(baseTestSet)
      }
      router.push('/partner_ops/test-sets/create-test-sets/')
    },
    editTestSet(id) {
      const baseTestSet = this.testSets.find((s) => s.id === id)
      if (baseTestSet === undefined) {
        setBaseTestSet(makeBaseTestSet(true))
      } else {
        baseTestSet!.isEditing = true
        setBaseTestSet(baseTestSet)
      }
      // if (this.showBetaFeatureFlag) {
      router.push('/partner_ops/test-sets/create-test-sets/')
      // } else {
      //   router.push('/partner_ops/test-sets/create')
      // }
    },
    statusVariant(testSet: TestSet) {
      if (testSet.error) {
        return 'error'
      } else if (testSet.ready) {
        return 'success'
      } else {
        return 'info'
      }
    },
    openDeleteModal(testSet: TestSet) {
      this.testSetToDelete = testSet
      this.deleteModalVisible = true
    },
    async regenerateResources(testSet: TestSet) {
      try {
        if (testSet !== null) {
          await regenerateResource(testSet)
          router.push(`/partner_ops/test-sets/`)
        }
      } catch (error: any) {
        this.isError = true
        const eResp = error.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
    },
    async downloadCSV(testSet: TestSet) {
      try {
        if (testSet !== null) {
          const file = await downloadPermutationCSV({ id: testSet.id })
          downloadString(file, testSet.csv_key)
        } else {
          this.isError = true
          this.errorMessage = ERRORS.NULL_TESTSET
        }
      } catch (error: any) {
        this.isError = true
        const eResp = error.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
    },
    async deleteTestSet(testSet: TestSet) {
      try {
        if (testSet !== null) {
          this.deleteModalVisible = false
          deleteTestSetID({ id: testSet.id })
        } else {
          this.isError = true
          this.errorMessage = ERRORS.NULL_TESTSET
        }
      } catch (error: any) {
        this.isError = true
        const eResp = error.response
        this.errorMessage = `${eResp?.status} : ${eResp?.data?.error?.message}`
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.test-corpus-root {
  padding: 10%;
}
.test-corpus-root :second-child {
  margin-top: 10px;
}
.helpModalButton :hover {
  cursor: pointer;
}
.help-svg {
  width: 18px;
}
</style>
