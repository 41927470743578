<script lang="ts">
import { defineComponent } from 'vue'
import { getTestCases, requestTests, TestCase } from '@/store/testing'
import { isAdminUser } from '@/store/session'
import SchedulePicker from '@/components/Onboarding/EndToEndTesting/SchedulePicker.vue'
import { date } from './utils'
import { addMinutes, addHours } from 'date-fns'
import { format } from 'date-fns-tz'
import enGB from 'date-fns/locale/en-GB'
import {
  getPartnerCapabilities,
  getPartnerLoading,
  PartnerCapabilities
} from '@/store/partners'
import { RESOURCES } from '@/consts'
import { isEmpty } from '@/utils/objects'

type BasicResource = { label: string; value: string }
type EmptyObject = Record<string, never>

const HIGH_VOLUME_LIMIT = 5000

const timezoneOptions = {
  timeZone: 'America/Los_Angeles',
  locale: enGB
}
export default defineComponent({
  name: 'EndToEndTesting',
  components: { SchedulePicker },
  data() {
    const testCounts = {} as Record<string, string>
    return {
      stage: 'scheduling',
      formats: {
        fourBySix: false,
        fiveBySeven: false,
        sixByEighteen: false
      },
      mode: 'later',
      testDate: addMinutes(date(), 10),

      chosenResource: {} as BasicResource | EmptyObject,
      selectedTests: [],
      dateToSubmit: null as Date | null,
      testCasesToSubmit: [] as Array<TestCase>,
      HIGH_VOLUME_LIMIT,
      testCounts
    }
  },
  computed: {
    tests(): Array<TestCase> {
      if (!this.chosenResource?.value) {
        return []
      }
      return this.testCases.filter(
        (c) => c.resource === this.chosenResource?.value && !c.deleted
      )
    },
    testCases() {
      return getTestCases()
    },
    isAdmin() {
      return isAdminUser()
    },
    partnerCapabilities() {
      const capabilities = getPartnerCapabilities()
      if (isEmpty(capabilities)) {
        return null
      }
      const activeCap = Object.keys(capabilities as PartnerCapabilities)
      return Object.values(RESOURCES).filter((c) => activeCap.includes(c.id))
    },
    partnerLoading() {
      return getPartnerLoading()
    },
    cantBeScheduled(): boolean {
      return (
        !this.isAdmin &&
        this.tests.filter(
          (el) => this.testCountAsNumber(el.id) > HIGH_VOLUME_LIMIT
        ).length > 0
      )
    },
    resourceOptions(): Array<BasicResource> {
      return (
        this.partnerCapabilities?.map((c) => ({
          label: c.label,
          value: c.id
        })) ?? []
      )
    },
    formattedTestDate() {
      const endTime = addHours(this.testDate as Date, 2)
      const startTimeString = format(
        this.testDate as Date,
        'kk:mm',
        timezoneOptions
      )
      const endTimeString = format(endTime, 'kk:mm', timezoneOptions)
      const dateString = format(this.testDate as Date, 'PPPP', timezoneOptions)
      return `${startTimeString} - ${endTimeString} / ${dateString}`
    },
    formattedTestTimeZone(): string {
      return format(this.testDate as Date, 'zzzz', timezoneOptions)
    },
    selectedTestCases(): Array<TestCase> {
      return this.tests.filter(
        (t) => this.testCounts[t.id] && this.testCountAsNumber(t.id) > 0
      )
    },
    formattedTestCases(): string {
      return this.selectedTestCases
        .map((t) => `${t.description} x ${this.testCounts[t.id]}`)
        .join(', ')
    }
  },
  methods: {
    testCountAsNumber(id) {
      return parseInt(this.testCounts[id])
    },
    countLabel(t) {
      return `${t.description} count`
    },
    submitTests() {
      this.dateToSubmit = this.testDate
      this.testCasesToSubmit = this.selectedTestCases
      this.stage = 'confirming'
    },
    async confirmTests() {
      await requestTests({
        date: this.testDate as Date,
        testSets: this.testCasesToSubmit.map(({ resource, id }) => ({
          resource,
          id,
          count: this.testCountAsNumber(id)
        }))
      })
      this.stage = 'confirmed'
    }
  }
})
</script>

<template>
  <div class="content p-5">
    <div class="intro">
      <h2>End to End Testing</h2>
      <p class="my-3">
        We’re running E2E testings to ensure you can successfuly recieve and
        ingest the files Lob provides you
      </p>
    </div>
    <loading-indicator>
      <template v-if="!partnerLoading">
        <Alert v-if="partnerCapabilities == null" variant="error" class="mt-3">
          There aren't enabled capabilities, you are not allowed to run a test
          if you haven't at least one. Contact Lob support to enable it
        </Alert>

        <form
          v-if="stage === 'scheduling' && partnerCapabilities != null"
          id="scheduleForm"
          class="mb-4"
        >
          <Dropdown
            id="resourceDropdown"
            v-model="chosenResource"
            label="Resource type"
            :options="resourceOptions"
            placeholder="Choose a Resource"
          />

          <Alert v-if="!chosenResource?.value" variant="info" class="mt-3">
            Please choose a resource type above
          </Alert>

          <div
            v-if="chosenResource?.value"
            class="mb-5 flex flex-column justify-items-start align-content-left gap-y-5"
          >
            <H3>Choose tests to run</H3>
            <div class="overflow-auto h-80">
              <table>
                <thead>
                  <tr>
                    <th>Quantity</th>
                    <th>ID</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="test in tests" :key="`${test.id}-count`">
                    <tr>
                      <td class="pr-2 relative">
                        <TextInput
                          :id="`${test.id}-count`"
                          v-model.lazy="testCounts[test.id]"
                          :label="`${test.description} count`"
                          type="number"
                          placeholder="0"
                          sr-only-label
                        />
                      </td>
                      <td class="pr-2">
                        {{ test.id }}
                      </td>
                      <td class="pr-2">
                        {{ test.description }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        !isAdmin &&
                        testCountAsNumber(test.id) > HIGH_VOLUME_LIMIT
                      "
                    >
                      <td colspan="3" class="mt-1 pb-1">
                        <alert variant="error">
                          <p>
                            You are trying to create too high a count in a test.
                          </p>
                        </alert>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>

            <div>
              <Caption>
                Please find a free slot that works for your technical team.
              </Caption>
              <P>
                <Clock class="inline-block h-5 w-5" />
                Testing length: ~ 2 hours
              </P>
            </div>

            <div class="switch">
              <SwitchGroup legend="Choose when to test" sr-only-legend>
                <SwitchItem
                  v-model="mode"
                  name="mode"
                  label="Start the test now"
                  value="now"
                />
                <SwitchItem
                  v-model="mode"
                  name="mode"
                  label="Schedule it for later"
                  value="later"
                />
              </SwitchGroup>

              <Caption>
                If you choose to schedule the test to start now, it will execute
                immediately and should be fully ready for review in no more than
                10 minutes.
              </Caption>
            </div>

            <!-- TODO: make sure when toggling back to now that the correct date is reset -->
            <div v-if="mode === 'now'">
              <Caption>
                <Mail class="inline-block h-5 w-5" />
                partner-managers@lob.com
              </Caption>
              <Caption>
                <Image class="inline-block h-5 w-5" />
                Test Cases:
                {{ formattedTestCases }}
              </Caption>
              <Caption>
                <Board class="inline-block h-5 w-5" />
                {{ formattedTestDate }}
              </Caption>
              <Caption>
                <History class="inline-block h-5 w-5" />
                {{ formattedTestTimeZone }}
              </Caption>
            </div>

            <div v-if="mode === 'later'">
              <SchedulePicker v-model="testDate" />
            </div>
            <div>
              <LobButton v-if="!cantBeScheduled" @click.prevent="submitTests">
                Schedule Test
              </LobButton>
              <alert v-if="cantBeScheduled" variant="error" class="mt-3">
                <p data-testid="cant-be-scheduled-alert">
                  You cannot schedule this test because it would exceed the
                  maximum number of tests allowed per day.
                </p>
              </alert>
            </div>
            <!-- not sure why the alert isn't styling @submit="" correctly -->
            <alert variant="info">
              Notes: In order to successfuly pass testing your monitoring team
              need to make sure you aren’t getting influx of production volume
              elsewhere when testing is happening.
            </alert>
          </div>
        </form>

        <form
          v-if="stage === 'confirming'"
          id="confirmForm"
          class="mb-5 flex flex-column gap-y-5"
        >
          <!-- TODO: refactor this repeated code -->
          <div>
            <Caption>
              <Mail class="inline-block h-5 w-5" />
              partner-managers@lob.com
            </Caption>
            <Caption>
              <Image class="inline-block h-5 w-5" />
              Test Cases:
              {{ formattedTestCases }}
            </Caption>
            <Caption>
              <Board class="inline-block h-5 w-5" />
              {{ formattedTestDate }}
            </Caption>
            <Caption>
              <History class="inline-block h-5 w-5" />
              {{ formattedTestTimeZone }}
            </Caption>
          </div>

          <LobButton @click.prevent="confirmTests">
            Schedule E2E testing
          </LobButton>
        </form>

        <div v-if="stage === 'confirmed'" class="mb-5 flex flex-column gap-y-5">
          <P class="text-lg">
            <Checkmark class="h-8 w-8 success inline-block" />
            Confirmed! You have successfully scheduled End to End testing
          </P>

          <!-- TODO: refactor this repeated code -->
          <div>
            <Caption>
              <Mail class="inline-block h-5 w-5" />
              partner-managers@lob.com
            </Caption>
            <Caption>
              <Image class="inline-block h-5 w-5" />
              Test Cases:
              {{ formattedTestCases }}
            </Caption>
            <Caption>
              <Board class="inline-block h-5 w-5" />
              {{ formattedTestDate }}
            </Caption>
            <Caption>
              <History class="inline-block h-5 w-5" />
              {{ formattedTestTimeZone }}
            </Caption>
          </div>
        </div>
      </template>
    </loading-indicator>
  </div>
</template>
