import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = ["colspan", "onClick"]
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  key: 2,
  class: "font-bold"
}
const _hoisted_5 = {
  key: 0,
  class: "border-t-0 border-b border-gray-100"
}
const _hoisted_6 = {
  key: 0,
  class: "table-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("table", {
      class: _normalizeClass(_ctx.tableStyle)
    }, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
            return (_openBlock(), _createElementBlock("th", {
              key: header.name,
              class: _normalizeClass(_ctx.headerClass(header)),
              colspan: header.span,
              onClick: () => _ctx.sortOnColumn(index, _ctx.headers)
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(header.name) + "   ", 1),
                (header.sortStatus === 'ASC')
                  ? (_openBlock(), _createBlock(_component_fa, {
                      key: 0,
                      icon: "arrow-up",
                      class: "mt-1"
                    }))
                  : _createCommentVNode("", true),
                (header.sortStatus === 'DESC')
                  ? (_openBlock(), _createBlock(_component_fa, {
                      key: 1,
                      icon: "arrow-down",
                      class: "mt-1"
                    }))
                  : _createCommentVNode("", true),
                (header.sortStatus === 'none' && header.sortable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, " - "))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_2))
          }), 128)),
          (_ctx.rowExpandable)
            ? (_openBlock(), _createElementBlock("td", _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.data.length)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (rowData, index) => {
              return (_openBlock(), _createBlock(_component_TableRow, {
                key: rowData.id,
                "table-i-d": _ctx.id,
                variant: _ctx.variant,
                "row-object": _ctx.rowObject,
                data: { ...rowData, index },
                expandable: _ctx.rowExpandable,
                "secondary-row-color": index % 2 ? _ctx.secondaryRowColor : '',
                compact: _ctx.compact,
                "with-border": _ctx.withBorder,
                onUpdateRow: (e) => _ctx.updateRows(e, index, _ctx.data),
                onHandleValueChange: _ctx.handleValueChange
              }, {
                expandedContent: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "expandedContent", { index: index })
                ]),
                _: 2
              }, 1032, ["table-i-d", "variant", "row-object", "data", "expandable", "secondary-row-color", "compact", "with-border", "onUpdateRow", "onHandleValueChange"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.pagination)
      ? (_openBlock(), _createBlock(_component_Pagination, {
          key: 0,
          class: "mt-3",
          collection: _ctx.data,
          page: _ctx.currentPage,
          limit: _ctx.pageLimit,
          total: _ctx.paginationTotal,
          onChange: _ctx.changePage
        }, null, 8, ["collection", "page", "limit", "total", "onChange"]))
      : _createCommentVNode("", true)
  ], 64))
}