<template>
  <div class="flex">
    <div class="text-2xl mb-10 mt-2">Component Information</div>
    <Tooltip position="right">
      <template #trigger>
        <Info class="ml-1 w-5 h-5" />
      </template>
      <template #content>
        {{ componentInformationTooltip }}
      </template>
    </Tooltip>
    <div class="flex-1" />
    <PackageButton
      v-if="isAdminOrPopsUser"
      class="h-12"
      :on-click="createNewComponent"
    >
      New Component
    </PackageButton>
  </div>
  <ComponentsTable
    :data="data"
    :is-admin-or-pops-user="isAdminOrPopsUser"
    @openComponentDeleteModal="$emit('openComponentDeleteModal', $event)"
  />
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { CUSTOM_MAIL_CONSTS } from '@/consts'
import PackageButton from '../common/PackageButton.vue'
import ComponentsTable from '../common/ComponentsTable.vue'

export default defineComponent({
  name: 'ComponentInformation',
  components: {
    PackageButton,
    ComponentsTable
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    mode: {
      type: String,
      default: () => {
        return ''
      }
    },
    isAdminOrPopsUser: { type: Boolean }
  },
  emits: ['createNewComponent', 'openComponentDeleteModal'],
  data() {
    return {
      headerTitle: '',
      infoData: CUSTOM_MAIL_CONSTS.PACKAGE_PAGE_INFO_PANEL,
      componentInformationTooltip:
        CUSTOM_MAIL_CONSTS.COMPONENT_INFORMATION_TOOLTIP
    }
  },
  methods: {
    createNewComponent() {
      this.$emit('createNewComponent')
    }
    // commented out for now until reordering is introduced post MVP
    // resortTableData(itemToSort){
    //   const sourceItem = JSON.parse(itemToSort.source)
    //   // TODO: Is this going to use an endpoint to permanently modify table data? Or is it only temporary
    //   const sourceIndex = this.tableData.findIndex(component => component.id === sourceItem.id)
    //   const targetIndex = this.tableData.findIndex(component => component.id === itemToSort.target)
    //   this.tableData.splice(sourceIndex, 1)
    //   this.tableData.splice(targetIndex, 0, sourceItem)
    // }
  }
})
</script>

<style lang="scss" scoped></style>
