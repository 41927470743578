<template>
  <MainNavigation list-class="h-screen overflow-y-scroll">
    <template #default="{ expanded }">
      <li>
        <PartnersLogo small :class="{ padded: expanded }" />
      </li>
      <MainNavigationItem
        v-if="showGitbook"
        id="docs"
        :icon-src="docsIcon"
        icon-alt-text="Docs Icon"
        @click="redirectToGitbook"
      >
        <template v-if="true">
          <div
            class="px-4 -mt-12 flex items-center align-middle w-full text-gray-500 text-sm cursor-pointer"
          >
            Docs &nbsp;
            <fa class="opacity-40" icon="link" />
          </div>
        </template>
      </MainNavigationItem>
      <MainNavigationItem
        v-for="item in items"
        :id="item.id"
        :key="item.id"
        :title="item.title"
        :to="item.to"
        :icon-src="item.iconSrc"
        :icon-alt-text="item.iconAltText"
        :expanded="expanded"
        :collapsible="item.children.length > 0"
      >
        <MainNavigationChildItem
          v-for="child in item.children"
          :id="child.id"
          :key="child.id"
          :title="child.title"
          :to="child.to"
        />
      </MainNavigationItem>
      <li v-if="expanded" id="logout-container" class="padded">
        <button @click="logout">Log Out</button>
      </li>
    </template>
  </MainNavigation>
</template>

<script lang="ts">
import {
  getLaunchDarklyFlags,
  getShowBetaFeatureFlag,
  getShowAbilitiesUIFlag,
  getShowGitbookFlag
} from '@/store/launchDarkly'
import {
  isAdminUser,
  logout,
  getPartnerFromCookie,
  fetchGitbookToken
} from '@/store/session'
import { defineComponent } from 'vue'
import MenuItem from '@/utils/menuItem'
import config from '../../../config'

const adminItems: Array<MenuItem> = [
  {
    id: 'pops',
    title: 'Partner Ops',
    iconSrc: require('@/assets/images/icon-settings.svg'),
    iconAltText: 'Partner Ops icon',
    children: [
      {
        id: 'pops-inventory',
        title: 'Inventory',
        to: '/partner_ops/accounts/inventory'
      },
      {
        id: 'pops-invite-user',
        title: 'Invite User',
        to: '/partner_ops/invite_partner_user'
      },
      {
        id: 'pops-user-management',
        title: 'User Management',
        to: '/partner_ops/user_management'
      },
      {
        id: 'pops-partner-list',
        title: 'Partners',
        to: '/partner_ops/partners/'
      },
      {
        id: 'pops-partners-list', // new partner list item separate from above built for cap/cap
        title: 'Print Partners',
        to: '/partner_ops/partners_list'
      },
      {
        id: 'pops-test-sets',
        title: 'Test Sets',
        to: '/partner_ops/test-sets/'
      },
      {
        id: 'pops-audit-log',
        title: 'Audit Logs',
        to: '/partner_ops/audit-logs/'
      },
      {
        id: 'pops-bulk-jobs',
        title: 'Bulk Jobs',
        to: '/partner_ops/bulk_jobs'
      },
      {
        id: 'send-mail',
        title: 'Send Email',
        to: '/partner_ops/send-email'
      },
      {
        id: 'sla-miss-quality',
        title: 'SLA Miss/Quality',
        to: '/partner_ops/sla-miss-quality/miss'
      },
      {
        id: 'pops-custom-mail',
        title: 'Custom Mail',
        to: '/partner_ops/custom-mail'
      },
      {
        id: 'pops-v2-testing',
        title: 'V2 Testing',
        to: '/partner_ops/testing'
      },
      {
        id: 'routing',
        title: 'Routing',
        to: '/partner_ops/routing'
      }
    ]
  }
]

export default defineComponent({
  data() {
    return {
      docsIcon: require('@/assets/images/icon-docs.svg'),
      onboardingItems: [
        {
          id: 'onboarding',
          title: 'Onboarding',
          iconSrc: require('@/assets/images/icon-onboarding.svg'),
          iconAltText: 'Onboarding icon',
          to: '/partners/current/onboarding/company-info',
          children: []
        }
      ] as Array<MenuItem>,
      nonAdminOnboardingItems: [
        {
          id: 'onboarding',
          title: 'Onboarding',
          iconSrc: require('@/assets/images/icon-onboarding.svg'),
          iconAltText: 'Onboarding icon',
          children: [
            {
              id: 'general-onboarding',
              title: 'General Onboarding',
              to: ''
            }
          ]
        }
      ] as Array<MenuItem>,
      sharedItems: [
        {
          id: 'testing',
          title: 'End To End Testing',
          iconSrc: require('@/assets/images/icon-analytics.svg'),
          iconAltText: 'Onboarding icon',
          to: '/end-to-end-testing',
          children: []
        }
        // commenting out until dashboards will be brought back
        // {
        //   id: 'reports',
        //   title: 'Reports',
        //   iconSrc: require('@/assets/images/icon-analytics.svg'),
        //   iconAltText: 'Reports icon',
        //   children: [
        //     {
        //       id: 'reports-checks',
        //       title: 'US Checks',
        //       to: '/partners/current/reports/checks'
        //     },
        //     {
        //       id: 'reports-letters',
        //       title: 'US Letters',
        //       to: '/partners/current/reports/letters'
        //     },
        //     {
        //       id: 'reports-postcards',
        //       title: 'US Postcards',
        //       to: '/partners/current/reports/postcards'
        //     },
        //     {
        //       id: 'reports-self-mailers',
        //       title: 'US Self Mailers',
        //       to: '/partners/current/reports/self_mailers'
        //     }
        //   ]
        // },
      ] as Array<MenuItem>
    }
  },
  computed: {
    items(): Array<any> {
      const sharedItemsFiltered = this.sharedItems.filter((item) => {
        return !(item.betaFeature && !this.showBetaFeature)
      })

      const onboardingItems = this.showDigitalProofing
        ? this.onboardingItems
        : this.nonAdminOnboardingItems

      const items = [...sharedItemsFiltered, ...onboardingItems]

      if (this.showAdminItems) {
        const adminItemsFiltered = adminItems.map((item) => {
          const children = item.children.filter((child) => {
            if (
              // exclude items where applicable LD flag is false
              // eslint-disable-next-line
              (child.betaFeature && !this.showBetaFeature) ||
              // eslint-disable-next-line
              (child.id === 'pops-partners-list' && !this.showAbilitiesUI)
            ) {
              return false
            }

            return true
          })

          return { ...item, children }
        })

        items.push(...adminItemsFiltered)
      }

      return items
    },
    showAdminItems(): boolean {
      return isAdminUser()
    },
    showGitbook(): boolean {
      return getShowGitbookFlag()
    },
    showBetaFeature(): boolean {
      return getShowBetaFeatureFlag()
    },
    showDigitalProofing(): boolean {
      return (
        getLaunchDarklyFlags()['show-digital-proofing'] || this.showAdminItems
      )
    },
    showAbilitiesUI(): boolean {
      return getShowAbilitiesUIFlag()
    }
  },
  async mounted() {
    const partner = await getPartnerFromCookie()
    this.nonAdminOnboardingItems[0].children[0].to = `/partners/${partner}/onboarding/company-info`
    this.sharedItems[0].to = `/partners/${partner}/end-to-end-testing`
  },
  methods: {
    async logout() {
      await logout()
    },
    async redirectToGitbook() {
      const resp = await fetchGitbookToken()
      window.open(`${config.GITBOOK_BASE_URL}/?jwt_token=${resp.token}`)
    }
  }
})
</script>

<style scoped lang="scss">
.padded {
  display: flex;
  flex-flow: column;
}

#logout-container {
  margin-top: 2em;
}
</style>
