<template>
  <!-- https://github.com/vuejs/vue-loader/issues/957 -->
  <div class="form-factor-field-editor-root">
    <CheckboxMVE
      :schema="schema"
      :field="explicitlyEnumeratedField"
      :test-set="testSet"
      @update:testSet="$emit('update:testSet', $event)"
    />
  </div>
</template>

<script lang="ts">
import { TestSet } from '@/store/testSets'
import { Schema, SchemaField } from '@/store/testSets/schema'
import { defineComponent, Prop } from '@vue/runtime-core'
import CheckboxMVE from './CheckboxMVE.vue'

export default defineComponent({
  name: 'FormFactorMVE',
  components: { CheckboxMVE },
  props: {
    schema: {
      type: Object,
      required: true
    } as Prop<Schema>,
    field: {
      type: Object,
      required: true
    },
    testSet: {
      type: Object,
      required: true
    } as Prop<TestSet>
  },
  emits: ['update:testSet'],
  computed: {
    explicitlyEnumeratedField(): SchemaField {
      if (this.schema === undefined)
        throw new Error('property schema is required')
      if (this.field === undefined)
        throw new Error('property field is required')
      if (this.field.type !== 'form_factor')
        throw new Error('expected field.type === "form_factor"')
      return {
        form_factors: this.field.form_factors,
        name: this.field.name,
        id: this.field.id,
        type: this.schema.form_factors.map((ff) => ({
          form_factors: this.field.form_factors,
          name: ff.name,
          value: ff.id
        }))
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
}
.item {
  display: flex;
  margin-right: 1rem;
}
.item > :first-child {
  // And again, bootstrap tries to important a basic layout rule and we need to tell it to calm down.
  margin-right: 0 !important;
}
</style>
