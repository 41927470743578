<template>
  <div class="text-3xl mb-10 ml-10 font-bold">Custom Mail Tests</div>
  <Subnavigation ul-class="font-light text-lg mb-12">
    <SubnavigationItem
      title="Test Sends"
      :to="`/partner_ops/testing/test_sends`"
    />
    <SubnavigationItem
      title="Test Cases"
      :to="`/partner_ops/testing/test_cases`"
    />
    <SubnavigationItem
      title="Test Groups"
      :to="`/partner_ops/testing/test_groups`"
    />
  </Subnavigation>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'

/* 
  tableRowObject for StringCell: 
  {
    fieldName,
    type
  }
*/

export default defineComponent({
  name: 'TestingHeader'
})
</script>
