import { createApp } from 'vue'
import 'tailwindcss/tailwind.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faGripVertical } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import '@lob/ui-components/dist/ui-components.css'
import 'highlight.js/styles/solarized-dark.css'
import App from './App.vue'
import plugins from './plugins'
import sentry from './plugins/sentry'
import segment from './plugins/segment'
import config from '../config'

library.add(far, fas, faGripVertical)

// Register component library, router, and FontAwesome component.
const app = createApp(App)
// Add fontawesome
app.component('Fa', FontAwesomeIcon)
// Add in the plugins.
for (const plugin of plugins) {
  app.use(plugin)
}

app.use(sentry, {
  enabled: !config.SENTRY_LOGGING_DISABLED,
  dsn: config.SENTRY_DSN,
  environment: config.ENVIRONMENT,
  release: config.APP_RELEASE
})

app.use(segment)

// Attach Vue to the #app element in the DOM.
app.mount('#app')
