import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center" }
const _hoisted_2 = { class: "text-gray-700 text-xs px-2 mb-1 py-1 text-center overflow-hidden truncate" }
const _hoisted_3 = { class: "border rounded text-sm text-shadow-50 px-2 p-1 text-center h-full bg-white flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(`${_ctx.format === 'currency' ? '$' : ''}${_ctx.formatFunctions[_ctx.format](_ctx.value)}`), 1)
  ]))
}