import { RouteRecordRaw } from 'vue-router'
import SLAStatus from '../SLAStatus'
import MissFormField from './MissFormField'
import QualityFormField from './QualityFormField'
import slaMissQuality from './View.vue'

export default {
  path: 'sla-miss-quality',
  component: slaMissQuality,
  children: [MissFormField, QualityFormField, SLAStatus]
} as RouteRecordRaw
