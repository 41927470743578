<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="table-cell align-middle">
        <div class="flex flex-col">
          <div
            class="bg-white rounded-sm border-2 my-0 mx-auto py-3 px-8 shadow-xl border-brand-primary w-5/12"
          >
            <div
              class="flex items-center justify-center m-0 px-8 pt-10 text-center"
            >
              <h2 class="m-0">Job {{ job.id }} Result</h2>
            </div>
            <LoadingIndicator>
              <div v-if="!isLoading" class="px-0 py-10 text-center">
                <div class="modal-spinner">
                  <i class="fa fa-cog fa-spin" />
                </div>
                <table align="center" class="job-result">
                  <tbody>
                    <tr>
                      <td key="description" align="right">Description:</td>
                      <td align="left">{{ job.description }}</td>
                    </tr>
                    <tr>
                      <td key="partner" align="right">Partner:</td>
                      <td align="left">
                        <code>{{ job.arguments?.partner ?? '-' }}</code>
                      </td>
                    </tr>
                    <tr>
                      <td key="date" align="right">Date:</td>
                      <td align="left">{{ job.date_modified }}</td>
                    </tr>
                    <tr>
                      <td key="createdBy" align="right">Created By:</td>
                      <td align="left">{{ job.user_email }}</td>
                    </tr>
                    <tr class="bg-white h-10" />
                    <tr>
                      <td>{{ jobSuccesses.length }} Successes</td>
                      <td>{{ jobResources.count }} IDs</td>
                      <td>{{ jobFailures.length }} Failures</td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          id="succeeded"
                          class="btn btn-white"
                          @click="downloadResources('succeeded')"
                        >
                          Download CSV
                        </button>
                      </td>
                      <td>
                        <button
                          id="all"
                          class="btn btn-white"
                          @click="downloadResources('all')"
                        >
                          Download Full Results
                        </button>
                      </td>
                      <td>
                        <button
                          id="failed"
                          class="btn btn-white"
                          @click="downloadResources('failed')"
                        >
                          Download CSV
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </LoadingIndicator>
            <div class="float-right px-5 pt-0 pb-5 relative">
              <button
                class="btn btn-blue float-right relative"
                @click="closeModal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
/* eslint-disable camelcase */
import { PropType } from 'vue'
import { defineComponent } from '@vue/runtime-core'
import { getJobResources, Job } from '@/store/jobs'
import { downloadURI } from '@/utils/browser'

const statuses = {
  SUCCESS: 'succeeded',
  FAILURE: 'failed',
  ALL: 'all'
}

export default defineComponent({
  name: 'JobResultsModal',
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      jobResources: [] as any,
      jobSuccesses: [] as any,
      jobFailures: [],
      isLoading: false
    }
  },
  async mounted() {
    this.isLoading = true
    this.jobResources = await getJobResources({ jobId: this.job.id })
    this.jobSuccesses = this.jobResources.data.filter(
      (j) => j.status === statuses.SUCCESS
    )
    this.jobFailures = this.jobResources.data.filter(
      (j) => j.status === statuses.FAILURE
    )
    this.isLoading = false
  },
  methods: {
    closeModal() {
      this.$emit('close', false)
    },
    downloadResources(resStatus) {
      const blob = new Blob([this.csvify(resStatus)])
      const fileDownloadUrl = URL.createObjectURL(blob)
      const fName = `${this.job.flush_type}_job_${this.job.id}_${resStatus}.csv`
      downloadURI(fileDownloadUrl, fName)
    },
    csvify(resStatus) {
      let resources = this.jobResources.data ? this.jobResources.data : []
      switch (resStatus) {
        case statuses.SUCCESS:
          resources = this.jobSuccesses
          break
        case statuses.FAILURE:
          resources = this.jobFailures
          break
        default:
          break
      }
      return resources.reduce((csv, resource) => {
        // Although these shouldn't be null, I have seen this in test env data
        // This has been done replace those "null" values with blank strings in the csv
        const rStatus = !resource.status ? '' : resource.status
        const rResult = !resource.results
          ? ''
          : JSON.stringify(resource.results).replace(/\"/g, '""') // eslint-disable-line
        return `${csv}${resource.resource_id},${rStatus},"${rResult}"\n`
      }, 'resource_id,status,result\n')
    }
  }
})
</script>

<style lang="scss" scoped>
.btn-white {
  &,
  &:hover {
    border: 1px solid $gray;
    color: $gray;
    text-transform: none;
  }
}

.btn-blue {
  color: #ffffff;
  background-color: $brand-primary;
  border-color: $brand-primary;

  &:hover {
    color: #ffffff;
    background-color: darken($brand-primary, 10%);
    border-color: darken($brand-primary, 10%);
  }

  &[disabled] {
    background-color: $brand-primary;
    border-color: $brand-primary;

    &:hover {
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.job-result {
  tbody {
    tr:nth-child(-n + 5) {
      td:first-child {
        font-weight: bold;
        padding-right: 10px;
      }
    }
    tr:nth-child(6) {
      td {
        font-weight: bold;
        padding-right: 10px;
      }
    }
    tr:nth-last-child(2) {
      td {
        padding: 5px 20px 0px 20px;
      }
    }
  }
}
</style>
