import { PartnerStatusType, PARTNER_STATUS, ResorceType } from './../../consts'
import { sendPapiRequest, sendLobRequest } from '../requests'
import { getUser, isAdminUser } from '../session'
import { defineVuexModule } from '../util'
import { registerMockDataSetup, registerWatcher } from '../watcherRegistry'
import mockData from './mockData.json'

/* eslint camelcase: "off" */
export interface Partner {
  id: string
  crid: string
  display_name: string
  orders_sqs_url: string
  timezone: string
  local_cutoff: number
  zipcode: string
  holidays: Array<{
    name: string
    partner_id: string
    date_created: string
    date_modified: string
  }>
  zero_prod_days: Array<{
    id: string
    partner_id: string
    date: string
    description: string
    date_created: string
    date_modified: string
  }>
  upcoming_prod_days: Array<{
    id: string
    partner_id: string
    date: string
    date_created: string
    date_modified: string
  }>
  status: string
  woody_account_id: string
  date_created: string
  date_modified: string
}

export interface PartnerFacility {
  id: string
  partner_id: string
  zipcode: string
  timezone: string
}

export interface AllPartnerFacilities {
  [partner_id: string]: Array<PartnerFacility>
}

export interface Ability {
  id: string
  name: string
  order_field_name: string
  operator: string
  order_field_value: string
  tags: string[]
}

export interface PartnerFacilityAbility extends Ability {
  partner_facility_id: string
  active: boolean
}

export interface AbilityExclusion {
  id: string
  description: string
  ability_ids: string[]
}

export interface LiveCapacity {
  checks: {
    num_sheets_remaining: number
    one_day_sla_num_sheets_remaining: number
  }
  letters: {
    num_sheets_remaining: number
    one_day_sla_num_sheets_remaining: number
  }
  postcards: {
    num_postcards_remaining: number
    one_day_sla_num_postcards_remaining: number
  }
  self_mailers: {
    num_self_mailers_remaining: number
    one_day_sla_num_self_mailers_remaining: number
  }
  generic: [
    {
      resource_type: string
      num_units_remaining: number
      one_day_sla_num_units_remaining: number
    }
  ]
}

export type PartnerCapabilities = Partial<
  Record<
    ResorceType,
    {
      date_created: string
      date_modified: string
      id: string
      partner_id: string
    }
  >
>

export interface PartnerCapacity {
  checks: {
    dateCreated: string
    dateModified: string
    id: string
    min_sheets_per_month: number
    sheets_per_day: number
    partner_id: string
    one_day_sla_daily_capacity: number
    one_day_sla_multiplier: number
  }
  letters: {
    dateCreated: string
    dateModified: string
    id: string
    min_sheets_per_month: number
    sheets_per_day: number
    partner_id: string
    one_day_sla_daily_capacity: number
    one_day_sla_multiplier: number
  }
  postcards: {
    date_created: string
    date_modified: string
    id: string
    min_postcards_per_month: number
    partner_id: string
    postcards_per_day: number
    one_day_sla_daily_capacity: number
    one_day_sla_multiplier: number
  }
  self_mailers: {
    date_created: string
    date_modified: string
    id: string
    min_self_mailers_per_month: number
    partner_id: string
    self_mailers_per_day: number
    one_day_sla_daily_capacity: number
    one_day_sla_multiplier: number
  }
  generic: [
    {
      id: string
      partner_id: string
      resource_type: string
      units_per_day: number
      min_units_per_month: number
      one_day_sla_daily_capacity: number
      one_day_sla_multiplier: number
      date_created: string
      date_modified: string
    }
  ]
  partner: string
}

interface State {
  currentPartner: Partner | null
  currentPartnerId: string | null
  currentPartnerCapabilities: PartnerCapabilities | null
  currentLookerResource: string | null
  currentLookerUrl: string | null
  allPartners: Array<Partner> | null
  allPartnerFacilities: AllPartnerFacilities | null
  allAbilities: Array<Ability> | null
  loading: boolean
}

export const initialState: State = {
  currentPartner: null,
  currentPartnerId: null,
  currentPartnerCapabilities: null,
  currentLookerResource: null,
  currentLookerUrl: null,
  allPartners: null,
  allPartnerFacilities: null,
  allAbilities: null,
  loading: false
}

export interface User {
  id: string
  partner: string
  role: string
  deleted: boolean
  date_created: string
  date_modified: string
  email: string
}

export interface Form {
  created_at: Date
  vendor_id: string
  created_by: string
  has_quality_issue: boolean
  is_miss: boolean
  miss_category: string
  miss_sub_category: string
  quality_category: string
  quality_sub_category: string
  has_proactive_communication: boolean
  csv_data: Blob
}

const storeModule = defineVuexModule('partners', initialState)

// GETTERS (exported functions that provide pieces of data from the state)

export const getCurrentPartnerId = storeModule.read(
  (state) => state.currentPartnerId,
  'getCurrentPartnerId'
)

// export const getCurrentLookerResource = storeModule.read(
//   (state) => state.currentLookerResource,
//   'getCurrentLookerResource'
// )

// export const getCurrentLookerUrl = storeModule.read(
//   (state) => state.currentLookerUrl,
//   'getCurrentLookerUrl'
// )

// Returns null if the list of all partners hasn't finished loading yet.
export const getAllPartners = storeModule.read((state) => {
  return state.allPartners ?? []
}, 'getAllPartners')

export const getLivePartners = storeModule.read((state) => {
  return (
    state.allPartners?.filter(
      (partner) => partner.status === PARTNER_STATUS.LIVE
    ) ?? []
  )
}, 'getLivePartners')

export const getProspectingPartners = storeModule.read((state) => {
  return (
    state.allPartners?.filter(
      (partner) => partner.status === PARTNER_STATUS.PROSPECTING
    ) ?? []
  )
}, 'getProspectingPartners')

export const getTestingPartners = storeModule.read((state) => {
  return (
    state.allPartners?.filter(
      (partner) => partner.status === PARTNER_STATUS.TESTING
    ) ?? []
  )
}, 'getTestingPartners')

export const getPartnersByStatus = (
  statusFilter: PartnerStatusType
): (() => Partner[]) => {
  const gettersMap = {
    [PARTNER_STATUS.LIVE]: getLivePartners,
    [PARTNER_STATUS.PROSPECTING]: getProspectingPartners,
    [PARTNER_STATUS.TESTING]: getTestingPartners,
    [PARTNER_STATUS.ALL]: getAllPartners
  } as const
  return gettersMap[statusFilter]
}

export const getAllPartnerFacilities = storeModule.read((state) => {
  return state.allPartnerFacilities ?? {}
}, 'getAllPartnerFacilities')

// Returns null if the list of all partners hasn't finished loading yet.
export const getPartnersGroupedByStatus: () =>
  | {
      live: Partial<Partner>[]
      prospecting: Partial<Partner>[]
      testing: Partial<Partner>[]
    }
  | undefined = storeModule.read(
  (state) =>
    state.allPartners?.reduce(
      (acc, partner) => {
        acc[partner.status]?.push?.(partner)
        return acc
      },
      { live: [], testing: [], prospecting: [] }
    ),
  'getPartnersGroupedByStatus'
)

export const getCurrentPartner = storeModule.read(
  (state) => state.currentPartner,
  'getCurrentPartner'
)

export const getPartnerLoading = storeModule.read(
  (state) => state.loading,
  'getParnerLoading'
)

export const getPartnerCapabilities = storeModule.read(
  (state) => state.currentPartnerCapabilities,
  'getPartnerCapabilities'
)

// MUTATORS (synchronous functions that change the state, each call to a mutator
// is logged in the Vue development tools)

const loadMockData = storeModule.commit((state) => {
  for (const key of Object.keys(mockData)) {
    /* eslint "no-extra-parens": "off" */
    /* eslint "@typescript-eslint/no-explicit-any": "off" */
    ;(state as any)[key as keyof State] = mockData[key as keyof typeof mockData]
  }
}, 'loadMockData')
registerMockDataSetup(loadMockData)

export const setCurrentPartnerId = storeModule.commit(
  (state, value: string) => {
    if (value === 'current') return
    state.currentPartnerId = value
  },
  'setCurrentPartnerId'
)

const setAllPartners = storeModule.commit(
  (state, value: Array<Partner> | null) => {
    state.allPartners = value
  },
  'setAllPartners'
)

const setCurrentPartner = storeModule.commit((state, partner: Partner) => {
  state.currentPartner = partner
}, 'setCurrentPartner')

const setCurrentPartnerCapabilities = storeModule.commit(
  (state, capabilities: PartnerCapabilities) => {
    state.currentPartnerCapabilities = capabilities
  },
  'setCurrentPartnerCapabilities'
)

const setLoadingPartner = storeModule.commit((state, loading: boolean) => {
  state.loading = loading
}, 'setLoadingPartner')

// export const setCurrentLookerResource = storeModule.commit(
//   (state, value: string) => {
//     state.currentLookerResource = value
//     refreshCurrentLookerUrl()
//   },
//   'setCurrentLookerResource'
// )

// const setCurrentLookerUrl = storeModule.commit(
//   (state, value: string | null) => {
//     state.currentLookerUrl = value
//   },
//   'setCurrentLookerUrl'
// )

const setAllPartnerFacilities = storeModule.commit(
  (state, value: AllPartnerFacilities | null) => {
    state.allPartnerFacilities = value
  },
  'setAllPartnerFacilities'
)

const setAllAbilities = storeModule.commit(
  (state, value: Array<Ability> | null) => {
    state.allAbilities = value
  },
  'setAllAbilities'
)

// ACTIONS (asynchronous functions which can call other actions as well as
// getters and setters, also logged in the Vue development tools)

export const fetchCurrentPartner = storeModule.dispatch(
  async (
    _,
    params: {
      partnerId: string
    }
  ) => {
    setLoadingPartner(true)
    const partner = await sendPapiRequest({
      method: 'GET',
      path: `/partners/${params.partnerId}`
    })
    setCurrentPartner(partner.data)
    const capabilities = await sendPapiRequest({
      method: 'GET',
      path: `/partners/${params.partnerId}/capabilities`
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { partner_id, ...cleanedCapabilities } = capabilities.data
    setCurrentPartnerCapabilities(cleanedCapabilities)
    setLoadingPartner(false)
  },
  'fetchCurrentPartner'
)

export const refreshAllPartners = storeModule.dispatch(async () => {
  setAllPartners(null)
  if (!isAdminUser()) return
  const response = await sendPapiRequest<{ data: Array<Partner> }>({
    method: 'GET',
    path: '/partners',
    query: {
      status: PARTNER_STATUS.ALL
    }
  })
  setAllPartners(response.data.data)
}, 'refreshAllPartners')

export const refreshAllPartnerFacilities = storeModule.dispatch(async () => {
  setAllPartnerFacilities(null)
  if (!isAdminUser()) return
  const allPartners = getAllPartners()
  const allPartnerFacilities: AllPartnerFacilities = {}

  // TODO: add get all facilities endpoint and replace this Promise.all
  await Promise.all(
    allPartners.map(async (partner) => {
      const response = await sendPapiRequest({
        method: 'GET',
        path: `/partners/${partner.id}/facilities`
      })

      allPartnerFacilities[partner.id] = response?.data?.data
    })
  )

  setAllPartnerFacilities(allPartnerFacilities)
}, 'refreshAllPartnerFacilities')

export const refreshAllAbilities = storeModule.dispatch(async () => {
  if (!isAdminUser()) {
    setAllAbilities(null)
    return
  }

  const response = await sendPapiRequest<{ data: Array<Ability> }>({
    method: 'GET',
    path: '/abilities'
  })

  setAllAbilities(response.data.data)
}, 'refreshAllAbilities')

export const getPartnerUsers = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string
    }
  ) => {
    const response = await sendPapiRequest<{ data: any }>({
      method: 'GET',
      path: '/users',
      query: {
        partner: params.partner
      }
    })
    return response.data
  },
  'getPartnerUsers'
)

export const createPartnerUser = storeModule.dispatch(
  async (
    _,
    params: {
      email: string
      partner: string
    }
  ) => {
    await sendPapiRequest({
      method: 'POST',
      path: '/users',
      data: {
        ...params,
        role: 'partner'
      }
    })
  },
  'createPartnerUser'
)

export const resendUserInvite = storeModule.dispatch(
  async (
    _,
    params: {
      userId: string
    }
  ) => {
    await sendLobRequest({
      method: 'POST',
      path: `/users/${params.userId}/resend_invite`,
      data: {
        ...params,
        partner_portal_user: true
      }
    })
  },
  'resendUserInvite'
)

export const createPartner = storeModule.dispatch(
  async (
    _,
    params: {
      display_name: string
      id: string
      timezone: string
      platform?: string
      zipcode?: string
    }
  ) => {
    return await sendPapiRequest({
      method: 'POST',
      path: '/partners',
      data: {
        ...params
      }
    })
  },
  'createPartner'
)

export const getPartnerCapacity = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string
    }
  ) => {
    const response = await sendPapiRequest({
      method: 'GET',
      path: `/partners/${params.partner}/capacities`
    })
    return response.data
  },
  'getPartnerCapacity'
)

export const getPartnerLiveCapacity = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string
    }
  ) => {
    const response = await sendPapiRequest<LiveCapacity>({
      method: 'GET',
      path: `/partners/${params.partner}/capacities/live`
    })
    return response.data
  },
  'getPartnerLiveCapacity'
)

export const getPartnerFacilities = storeModule.dispatch(
  async (
    _,
    params: {
      partnerId: string
    }
  ): Promise<PartnerFacility[]> => {
    const response = await sendPapiRequest({
      method: 'GET',
      path: `/partners/${params.partnerId}/facilities`
    })
    return response?.data?.data
  },
  'getPartnerFacilities'
)

export const getPartnerFacilityAbilities = storeModule.dispatch(
  async (
    _,
    params: {
      partnerId: string
      partnerFacilityId: string
    }
  ): Promise<PartnerFacilityAbility[]> => {
    const response = await sendPapiRequest({
      method: 'GET',
      path: `/partners/${params.partnerId}/facilities/${params.partnerFacilityId}/abilities`
    })
    return response?.data?.data
  },
  'getPartnerFacilityAbilities'
)

export const getPartnerFacilityAbilityExclusions = storeModule.dispatch(
  async (
    _,
    params: {
      partnerFacilityId: string
    }
  ): Promise<AbilityExclusion[]> => {
    const response = await sendPapiRequest({
      method: 'GET',
      path: '/ability_exclusions',
      query: { partner_facility_id: params.partnerFacilityId }
    })

    return response?.data?.data
  },
  'getPartnerFacilityAbilityExclusions'
)

export const getAllAbilities = storeModule.read((state) => {
  return state.allAbilities ?? []
}, 'getAllAbilities')

export const setPartnerFacilityAbility = storeModule.dispatch(
  async (
    _,
    params: {
      partnerId: string
      partnerFacilityId: string
      abilityId: string
      active: boolean
    }
  ) => {
    await sendPapiRequest({
      method: 'PATCH',
      path: `/partners/${params.partnerId}/facilities/${params.partnerFacilityId}/abilities`,
      data: { ability_id: params.abilityId, active: params.active },
      admin: true
    })
  },
  'setPartnerFacilityAbility'
)

export const removePartnerFacilityAbility = storeModule.dispatch(
  async (
    _,
    params: {
      partnerId: string
      partnerFacilityId: string
      abilityId: string
    }
  ) => {
    await sendPapiRequest({
      method: 'DELETE',
      path: `/partners/${params.partnerId}/facilities/${params.partnerFacilityId}/abilities/${params.abilityId}`
    })
  },
  'removePartnerFacilityAbility'
)

export const setPartnerCapacity = storeModule.dispatch(
  async (
    _,
    params: {
      partner: string
      data: {
        type: string
        quantity: number
      }
    }
  ) => {
    const data = {}
    if (params.data.type === 'postcards') {
      data[params.data.type] = {
        min_postcards_per_month: 0,
        postcards_per_day: Number(params.data.quantity)
      }
    } else if (params.data.type === 'self_mailers') {
      data[params.data.type] = {
        min_self_mailers_per_month: 0,
        self_mailers_per_day: Number(params.data.quantity)
      }
    } else {
      data[params.data.type] = {
        min_sheets_per_month: 0,
        sheets_per_day: Number(params.data.quantity)
      }
    }
    await sendPapiRequest({
      method: 'POST',
      path: `/partners/${params.partner}/capacities`,
      data
    })
  },
  'setPartnerCapacity'
)

export const setPermutationCompletedSteps = storeModule.dispatch(
  async (
    ctx,
    params: {
      partner: string
      data: {
        id: string
        steps: string[]
      }
    }
  ) => {
    await sendPapiRequest({
      method: 'PUT',
      path: `/partners/${params.partner}/permutations/${params.data.id}/steps_completed`,
      data: {
        steps_completed: params.data.steps
      }
    })
  },
  'setPermutationCompletedSteps'
)

// const refreshCurrentLookerUrl = storeModule.dispatch(async () => {
//   setCurrentLookerUrl(null)
//   const dashboardId = getCurrentLookerResource()
//   const partnerId = getCurrentPartnerId()
//   if (dashboardId === null || partnerId === null) {
//     return
//   }
//   const response = await sendPapiRequest<{ url: string }>({
//     method: 'GET',
//     path: `/partners/${partnerId}/looker_integrate/${dashboardId}`
//   })
//   setCurrentLookerUrl(response.data.url)
// }, 'refreshCurrentLookerUrl')

export const createSLAMissQuality = storeModule.dispatch(
  async (
    ctx,
    params: {
      user_partner: string
      created_at: string
      vendor_id: string
      created_by: string
      has_quality_issue: boolean
      is_miss: boolean
      miss_category: string
      miss_sub_category: string
      quality_category: string
      quality_sub_category: string
      has_proactive_communication: boolean
      csv_data: Blob | string
    }
  ) => {
    const data = new FormData()
    if (params.csv_data) {
      data.append('csv_data', params.csv_data)
    }
    data.append('vendor_id', params.vendor_id)
    data.append('created_by', params.created_by)
    data.append('created_at', params.created_at)
    data.append('has_quality_issue', JSON.stringify(params.has_quality_issue))
    data.append('is_miss', JSON.stringify(params.is_miss))
    data.append('miss_category', params.miss_category)
    data.append('miss_sub_category', params.miss_sub_category)
    data.append('quality_category', params.quality_category)
    data.append('quality_sub_category', params.quality_sub_category)
    data.append(
      'has_proactive_communication',
      JSON.stringify(params.has_proactive_communication)
    )

    const response = await sendPapiRequest({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'POST',
      path: `/partners/${params.user_partner}/sla_miss_quality`,
      data
    })
    if (response.status < 400) {
      return response.data
    }
  },
  'createSLAMissQuality'
)
// WATCHERS (functions that are called when a value in the store changes,
// possibly one from a different module.)

registerWatcher(
  () => getUser(),
  (user) => {
    if (user !== null) {
      if (getCurrentPartnerId() === null) {
        if (
          user.masquerade_info &&
          user.masquerade_info.masquerade_partner_id.length !== 0
        ) {
          setCurrentPartnerId(user.masquerade_info.masquerade_partner_id)
        } else {
          setCurrentPartnerId(user.partner)
        }
      }
      // Different users have different access to this endpoint.
      refreshAllPartners()
      // refreshCurrentLookerUrl()
      refreshAllPartnerFacilities()
    }
  }
)

registerWatcher(
  () => getCurrentPartnerId(),
  async () => {
    await fetchCurrentPartner({ partnerId: getCurrentPartnerId() as string })
  }
)
