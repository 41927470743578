import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/lob-partners-logo.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: "/" }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _imports_0,
        class: _normalizeClass(['home-logo', { small: _ctx.small }]),
        alt: "Lob Partners Logo"
      }, null, 2)
    ]),
    _: 1
  }))
}