import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { colspan: "12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableCell = _resolveComponent("TableCell")!
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", {
      class: _normalizeClass(_ctx.rowContainerStyle)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rowObject, (cell) => {
        return (_openBlock(), _createElementBlock("td", {
          key: cell.key,
          class: _normalizeClass(_ctx.cellContainerStyle(cell))
        }, [
          (cell.children)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cell.children, (childCell) => {
                  return (_openBlock(), _createBlock(_component_TableCell, {
                    key: childCell.key,
                    "table-i-d": _ctx.tableID,
                    "cell-data": _ctx.data[childCell.fieldName],
                    "cell-info": childCell,
                    "row-data": _ctx.data,
                    class: "mx-1",
                    onHandleValueChange: _ctx.handleValueChange
                  }, null, 8, ["table-i-d", "cell-data", "cell-info", "row-data", "onHandleValueChange"]))
                }), 128))
              ]))
            : (_openBlock(), _createBlock(_component_TableCell, {
                key: 1,
                "table-i-d": _ctx.tableID,
                "cell-data": _ctx.data[cell.fieldName],
                "cell-info": cell,
                "row-data": _ctx.data,
                onHandleValueChange: _ctx.handleValueChange
              }, null, 8, ["table-i-d", "cell-data", "cell-info", "row-data", "onHandleValueChange"]))
        ], 2))
      }), 128)),
      (_ctx.expandable)
        ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
            (_ctx.data.expanded)
              ? (_openBlock(), _createBlock(_component_fa, {
                  key: 0,
                  class: "cursor-pointer",
                  icon: "chevron-up",
                  onClick: _ctx.toggleExpandedRow
                }, null, 8, ["onClick"]))
              : (_openBlock(), _createBlock(_component_fa, {
                  key: 1,
                  class: "cursor-pointer",
                  icon: "chevron-down",
                  onClick: _ctx.toggleExpandedRow
                }, null, 8, ["onClick"]))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("tr", {
      class: _normalizeClass(_ctx.expandableContentStyle)
    }, [
      _createElementVNode("td", _hoisted_3, [
        _renderSlot(_ctx.$slots, "expandedContent")
      ])
    ], 2)
  ], 64))
}