import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ffe0c01c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-4 -mt-12 flex items-center align-middle w-full text-gray-500 text-sm cursor-pointer"
}
const _hoisted_2 = {
  key: 1,
  id: "logout-container",
  class: "padded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnersLogo = _resolveComponent("PartnersLogo")!
  const _component_fa = _resolveComponent("fa")!
  const _component_MainNavigationItem = _resolveComponent("MainNavigationItem")!
  const _component_MainNavigationChildItem = _resolveComponent("MainNavigationChildItem")!
  const _component_MainNavigation = _resolveComponent("MainNavigation")!

  return (_openBlock(), _createBlock(_component_MainNavigation, { "list-class": "h-screen overflow-y-scroll" }, {
    default: _withCtx(({ expanded }) => [
      _createElementVNode("li", null, [
        _createVNode(_component_PartnersLogo, {
          small: "",
          class: _normalizeClass({ padded: expanded })
        }, null, 8, ["class"])
      ]),
      (_ctx.showGitbook)
        ? (_openBlock(), _createBlock(_component_MainNavigationItem, {
            key: 0,
            id: "docs",
            "icon-src": _ctx.docsIcon,
            "icon-alt-text": "Docs Icon",
            onClick: _ctx.redirectToGitbook
          }, {
            default: _withCtx(() => [
              true
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createTextVNode(" Docs   "),
                    _createVNode(_component_fa, {
                      class: "opacity-40",
                      icon: "link"
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["icon-src", "onClick"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_MainNavigationItem, {
          id: item.id,
          key: item.id,
          title: item.title,
          to: item.to,
          "icon-src": item.iconSrc,
          "icon-alt-text": item.iconAltText,
          expanded: expanded,
          collapsible: item.children.length > 0
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
              return (_openBlock(), _createBlock(_component_MainNavigationChildItem, {
                id: child.id,
                key: child.id,
                title: child.title,
                to: child.to
              }, null, 8, ["id", "title", "to"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["id", "title", "to", "icon-src", "icon-alt-text", "expanded", "collapsible"]))
      }), 128)),
      expanded
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
            }, "Log Out")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}